import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import SuccessCheck from "../../assets/images/successCheck.svg";
import UserService from "../../services/user.service";
import moment from "moment";
import Loading from "../../common/Loading";
import { NotificationDetailsCardBasic } from "../../stories/NotificationDetailsCard.stories";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import layer from "../../assets/images/layer1.svg";

const ViewNotificationDetails = (props) => {
  let { uuid, userUUID } = useParams();
  const [familyDetails, setFamilyDetails] = useState([]);
  const [parentUser, setParentUser] = useState();
  const [Filternewval, setFilternewval] = useState();
  const [sortFilter, setsortFilter] = useState();
  const [title, setTitle] = useState("Event Logs");
  const [subTitle, setSubTitle] = useState(
    "You can view all the event logs in this section."
  );
  const [valueUser, setValueUser] = useState("");
  const [backText, setBackText] = useState("Back to Family Dashboard");
  const [backUrl, setBackUrl] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [familyNotificationList, setFamilyNotificationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Loadingfilterby, setLoadingfilterby] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [totalVal, setTotalVal] = useState();
  const [familyId, setFamilyId] = useState();
  const [familyUserId, setFamilyUserId] = useState();
  const [orderBy, setOrderBy] = useState("DESC");
  const [usersDetail, setUsersDetail] = useState([]);
  const [usersId, setUsersId] = useState("all");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  var IdValue = parseInt(localStorage.getItem("userUUIDIdVal"));
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
      "padding-left": "0.5rem",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };

  useEffect(() => {
    const userArr = [{ value: "all", label: "All" }];
    UserService.getFamilyAllAu(uuid).then(
      (resp) => {
        console.log(resp);
        for (let i = 0; i < resp.data.length; i++) {
          userArr.push({
            value: resp.data[i].id,
            label: resp.data[i].User.email,
          });
        }
        setUsersDetail(userArr);
      },
      (error) => {}
    );
  }, []);

  var PrimaryIdval = localStorage.getItem("PrimaryIdval");
  const filterOption = [
    { value: "all", label: "All" },
    { value: "Subscriptions", label: "Outgoings" },
    { value: "Warranties", label: "Warranties" },
    { value: "Giftcards", label: "Giftcards" },
    { value: "Tasks", label: "To Do's" },
  ];

  const sortOption = [
    { value: "ASC", label: "Ascending" },
    { value: "DESC", label: "Descending" },
  ];

  // const usersDetail = [
  //   { value: "all", label: "All" },
  //   { value: "Subscriptions", label: "Outgoings" },
  // ];

  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const myRef = useRef(null);
  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        // setParentUser(response.userData);
        setTitle(pUser.firstName + " " + pUser.lastName + "'s Event logs");
        setSubTitle(
          "You can view all the actionable event logs of " +
            pUser.firstName +
            " " +
            pUser.lastName +
            " here"
        );
        setValueUser("useraccount");
      });
    } else {
      getFamilyDetails(UserDetails.id);
    }
  }, []);

  // Get Family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setLoadingfilterby(true);
        setFamilyDetails(response);
        let familyUserId = response.FamilyData.id;
        let familyId = response.FamilyData.Family.uuid;
        //set all the value
        setFamilyId(familyId);
        setFamilyUserId(familyUserId);
        if (response.FamilyData) {
          if (userType === "AO" || "AO + SU" || "AO + AU" || "AO + AU + SU") {
            let params = {
              fuuid: familyId,
              userId: "all",
              limit: 10,
              offset: 0,
              filterVal: "",
              sortVal: sortFilter,
            };
            if (sortFilter === "ASC") {
              setOrderBy("ASC");
              params.sortVal = "ASC";
            } else {
              setOrderBy("DESC");
              params.sortVal = "DESC";
            }
            UserService.getUserFamilyNotification3(params).then(
              (res) => {
                setLoadingfilterby(false);
                setFamilyNotificationList(res.data);
                setLoading(false);
                let nextOffset = Number(offset) + Number(limit);
                if (res.data.length === 0) {
                  setIsEmpty(true);
                  setOffset(0);
                  setHasMore(false);
                } else {
                  setIsEmpty(false);
                  setOffset(nextOffset);
                  setHasMore(true);
                }
              },
              (error) => {
                setLoading(false);
              }
            );
          } else {
            let params = {
              fuuid: familyId,
              userId:
                props.match.path === "/user_notification/:uuid/:userUUID"
                  ? IdValue
                  : userType === "AU + SU"
                  ? PrimaryIdval
                  : userType === "AU"
                  ? PrimaryIdval
                  : usersId,
              limit: 10,
              offset: 0,
              filterVal: "",
              sortVal: sortFilter,
            };
            if (sortFilter === "ASC") {
              setOrderBy("ASC");
              params.sortVal = "ASC";
            } else {
              setOrderBy("DESC");
              params.sortVal = "DESC";
            }
            UserService.getUserFamilyNotification3(params).then(
              (res) => {
                setLoadingfilterby(false);
                setFamilyNotificationList(res.data);
                setLoading(false);
                let nextOffset = Number(offset) + Number(limit);
                if (res.data.length === 0) {
                  setIsEmpty(true);
                  setOffset(0);
                  setHasMore(false);
                } else {
                  setIsEmpty(false);
                  setOffset(nextOffset);
                  setHasMore(true);
                }
              },
              (error) => {
                setLoading(false);
              }
            );
          }
        }
      },
      (error) => {
        setLoadingfilterby(false);
      }
    );
  };

  const newBtnfn = (
    FamilyIdVal,
    SubscriptionIdVal,
    WarrantyIdVal,
    TaskIdVal,
    GiftcardIdVal
  ) => {
    if (TaskIdVal) {
      UserService.getTaskDetailsById(TaskIdVal).then((response) => {
        history.push({
          pathname: `/view_todos_details/${uuid}/${response.data.ParentId}`,
          state: TaskIdVal,
        });
      });
    }
    if (SubscriptionIdVal) {
      history.push(`/view_outgoing_details/${uuid}/${SubscriptionIdVal}`);
    }
    if (WarrantyIdVal) {
      history.push(`/view_warranty_details/${uuid}/${WarrantyIdVal}`);
    }
    if (GiftcardIdVal) {
      history.push(`/view__vouchers_&_gift_cards/${uuid}/${GiftcardIdVal}`);
    }
  };

  const [filterValue, setFilterValue] = useState({
    value: "all",
    label: "All",
  });

  const [sortValue, setSortValue] = useState({
    value: "DESC",
    label: "Descending",
  });

  //filter by user
  const EventUserDetails = (e) => {
    setFilterValue({ value: "all", label: "All" });
    setSortValue({ value: "DESC", label: "Descending" });
    setUsersId(e.value);
    setLoadingfilterby(true);
    let params = {
      fuuid: familyId,
      userId: e.value,
      limit: 10,
      offset: 0,
      filterVal: "",
      sortVal: sortFilter,
    };
    if (sortFilter === "ASC") {
      setOrderBy("ASC");
      params.sortVal = "ASC";
    } else {
      setOrderBy("DESC");
      params.sortVal = "DESC";
    }

    UserService.getUserFamilyNotification3(params).then(
      (res) => {
        setFamilyNotificationList(res.data);
        setLoadingfilterby(false);
        let nextOffset = Number(offset) + Number(limit);
        if (res.data.length === 0) {
          setIsEmpty(true);
          setOffset(0);
          setHasMore(false);
        } else {
          setIsEmpty(false);
          setOffset(nextOffset);
          setHasMore(true);
        }
      },
      (error) => {
        setLoadingfilterby(false);
      }
    );
  };
  //filter by in Event Log
  const EventLogFilter = (e) => {
    let filterVal = e.value;
    setFilterValue(e);
    setFilternewval(filterVal);
    setLoadingfilterby(true);
    let params = {
      fuuid: familyId,
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      limit: 10,
      offset: 0,
      filterVal: filterVal,
      sortVal: sortFilter,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = filterVal);
    }
    if (sortFilter === "ASC") {
      setOrderBy("ASC");
      params.sortVal = "ASC";
    } else {
      setOrderBy("DESC");
      params.sortVal = "DESC";
    }

    UserService.getUserFamilyNotification3(params).then(
      (res) => {
        setFamilyNotificationList(res.data);
        setLoadingfilterby(false);
        let nextOffset = Number(offset) + Number(limit);
        if (res.data.length === 0) {
          setIsEmpty(true);
          setOffset(0);
          setHasMore(false);
        } else {
          setIsEmpty(false);
          setOffset(nextOffset);
          setHasMore(true);
        }
      },
      (error) => {
        setLoadingfilterby(false);
      }
    );
  };
  //sort by in Event Log
  const EventLogSort = (e) => {
    setSortValue(e);
    let sortFil = e.value;
    setsortFilter(sortFil);
    setLoadingfilterby(true);
    setLoading(true);
    // setLimit(10);
    // setOffset(0);
    let params = {
      fuuid: familyId,
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      limit: 10,
      offset: 0,
      filterVal: Filternewval,
      sortVal: sortFil,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = Filternewval);
    }
    if (sortFil === "ASC") {
      setOrderBy("ASC");
      params.sortVal = "ASC";
    } else {
      setOrderBy("DESC");
      params.sortVal = "DESC";
    }
    UserService.getUserFamilyNotification3(params).then(
      (res) => {
        setLoadingfilterby(false);
        setFamilyNotificationList(res.data);
        setLoading(false);
        let nextOffset = Number(offset) + Number(limit);
        if (res.data.length === 0) {
          setIsEmpty(true);
          setOffset(0);
          setHasMore(false);
        } else {
          setIsEmpty(false);
          setOffset(nextOffset);
          setHasMore(true);
        }
      },
      (error) => {
        setLoadingfilterby(false);
        setLoading(false);
      }
    );
  };

  //for more notification in Infinite Scroll
  const moreSubscriptions = () => {
    let params = {
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      fuuid: familyId,
      filterVal: Filternewval,
      limit: 10,
      offset: offset,
      sortVal: sortFilter,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = Filternewval);
    }
    if (sortFilter === "ASC") {
      setOrderBy("ASC");
      params.sortVal = "ASC";
    } else {
      setOrderBy("DESC");
      params.sortVal = "DESC";
    }
    UserService.getUserFamilyNotification3(params).then(
      (res) => {
        setDataLength(familyNotificationList.length);
        let loadmore = [];
        let result = res.data;
        Array.from(result).forEach((item) => loadmore.push(item));
        setFamilyNotificationList([...familyNotificationList, ...loadmore]);
        let nextOffset = Number(offset) + Number(limit);
        if (res.data.length === 0) {
          setHasMore(false);
          setOffset(0);
        } else {
          setHasMore(true);
          setOffset(nextOffset);
        }
      },
      (error) => {}
    );
  };
  var userType = localStorage.getItem("userType");
  var uuidDetails = localStorage.getItem("uuid");
  var userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));

  const dashboardRed = () => {
    if (valueUser === "useraccount") {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/family_dashboard/${uuidDetails}`,
      });
    }
  };

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  return (
    <div className="page-content warranty-pg">
      <Container fluid>
        <>
          <Row
            className="viewnotification-label"
            style={{ "margin-bottom": "10px" }}
          >
            <Col md="4" lg="4">
              <div
                className="Reminder-backBtn-1"
                onClick={() => dashboardRed()}
                style={{ "margin-top": "1rem" }}
              >
                {" "}
                <img
                  className=""
                  src={layer}
                  alt="Profile Avatar"
                  style={{ "margin-right": "15px" }}
                />{" "}
                {valueUser === "useraccount" ? (
                  <span>Back to Main User Dashboard</span>
                ) : (
                  <span>Back to Family Dashboard</span>
                )}
              </div>
              <h3>{title}</h3>
            </Col>

            {props.match.path != "/user_notification/:uuid/:userUUID" &&
            userType != "AU" &&
            userType != "AU + SU" &&
            userType != "SU" ? (
              <Col md="4" lg="4">
                <div
                  className="filter-label w-100"
                  style={{ "margin-top": "1rem" }}
                >
                  <div
                    className="p-0 filter-select d-flex align-items-center justify-content-between viewnotification"
                    style={{ "margin-right": "10px" }}
                  >
                    <span style={{ "margin-right": "1rem" }}>
                      Filter by Users :
                    </span>
                    <div className="notification-users" style={{ flex: 1 }}>
                      <Select
                        styles={customStyles}
                        options={usersDetail}
                        // className="flex-1"
                        defaultValue={{ label: "All", value: "all" }}
                        onChange={EventUserDetails}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <Col md="4" lg="4"></Col>
            )}

            <Col md="2" lg="2">
              <div
                className="filter-label w-100"
                style={{ "margin-top": "1rem" }}
              >
                <div
                  className="p-0 filter-select d-flex align-items-center justify-content-between viewnotification"
                  style={{ "margin-right": "10px" }}
                >
                  <span style={{ "margin-right": "1rem" }}>Filter by :</span>
                  <Select
                    styles={customStyles}
                    options={filterOption}
                    className="flex-1"
                    defaultValue={{ label: "All", value: "all" }}
                    value={filterValue}
                    onChange={EventLogFilter}
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
            </Col>

            <Col md="2" lg="2">
              <div
                className="filter-label w-100"
                style={{ "margin-top": "1rem" }}
              >
                <div className="p-0 filter-select d-flex align-items-center justify-content-between viewnotification">
                  <span style={{ "margin-right": "1rem" }}>Sort by :</span>

                  <Select
                    styles={customStyles}
                    options={sortOption}
                    defaultValue={{ label: "Descending", value: "DESC" }}
                    onChange={EventLogSort}
                    value={sortValue}
                    classNamePrefix="react-select"
                    className="flex-1"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <p className="sub-title" style={{ "margin-bottom": "1.5rem" }}>
            {subTitle}
          </p>
          <Row className="viewNotification-row">
            {Loadingfilterby ? (
              <div style={{ "margin-top": "4.4rem" }}>
                <Loading />
              </div>
            ) : (
              <>
                {familyNotificationList.length != 0 ? (
                  <InfiniteScroll
                    dataLength={dataLength}
                    next={moreSubscriptions}
                    hasMore={hasMore}
                    loader={<Loading />}
                    style={{
                      overflow: "inherit",
                      display: "flex",
                      "flex-wrap": "wrap",
                    }}
                  >
                    {familyNotificationList.map((notifyItem, index) => (
                      <Col md="3" lg="4" key={index}>
                        <NotificationDetailsCardBasic
                          title={notifyItem.title}
                          description={notifyItem.message}
                          categoryName={
                            notifyItem.title === "Voucher created"
                              ? "Vouchers"
                              : notifyItem.module
                          }
                          date={moment(notifyItem.createdAt).format(
                            "MMM Do, YYYY"
                          )}
                          variant="basic"
                          viewPage={() =>
                            newBtnfn(
                              notifyItem.FamilyId,
                              notifyItem.SubscriptionId,
                              notifyItem.WarrantyId,
                              notifyItem.TaskId,
                              notifyItem.GiftcardId
                            )
                          }
                        />
                      </Col>
                    ))}
                  </InfiniteScroll>
                ) : (
                  <div style={{ padding: "8rem", textAlign: "center" }}>
                    <p>
                      Your have no notifications to catch up in this Family.
                    </p>
                  </div>
                )}
                {/* </InfiniteScroll> */}
              </>
            )}
          </Row>
        </>
        {/* )} */}
      </Container>
    </div>
  );
};
export default ViewNotificationDetails;
