import React, { useState, useEffect } from "react";
import { Col, Image, Card } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Link } from "react-router-dom";
import Envelop from "../../assets/images/envelop.svg";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import { Invite } from "../../stories/InvitationCards.stories";
import CheckGreen from "../../assets/images/check-green.svg";
import Loading from "../../common/Loading";

const MyInvites = (props) => {
  const [loading, setLoading] = useState(true);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var initialFamilyList = props.location.familyList
    ? props.location.familyList
    : [];
  const [familyList, setFamilyList] = useState(initialFamilyList);
  var initialFamilyInviteList = props.location.inviteList
    ? props.location.inviteList
    : [];
  const [inviteList, setInviteList] = useState(initialFamilyInviteList);
  const [showInviteModel, setShowInviteModel] = useState(false);

  const [showInviteModel1, setShowInviteModel1] = useState(false);
  const [familyDashLink, setFamilyDashLink] = useState("");
  const handleShowInviteModelClose = () => setShowInviteModel(false);

  const [accountPlanList, setAccountPlanList] = useState([]);

  var RedFamDash = localStorage.getItem("RedFamDash");

  useEffect(() => {
    // invite list
    UserService.getUserFamilyInvite(UserDetails.uuid).then((response) => {
      setInviteList(response.InviteData);
      setLoading(false);
    });

    UserService.getUserAccountPlan(UserDetails.uuid).then((response) => {
      var newData = [];
      for (var i = 0; i < response.usersFamilyData.length; i++) {
        if (response.usersFamilyData[i].Family.status !== "InActive") {
          newData.push(response.usersFamilyData[i]);
        }
      }
      // newData.forEach((row, ind) => (row.id = ind));
      setAccountPlanList(newData);
    });
  }, []);

  const handleInviteStatus = (inviteId, status, familyUUID, userInvitedAs) => {
    let updateData = {
      status: status,
    };
    if (userInvitedAs === true) {
      UserService.updateFamilyInviteStatus(inviteId, updateData).then(
        (response) => {
          //refresh family list
          setLoading(true);
          UserService.getUserFamily(UserDetails.uuid).then(
            (response) => {
              setFamilyList(response.usersFamilyData);

              setLoading(false);
            },
            (error) => {
              setLoading(false);
            }
          );
          //refresh family invite list
          UserService.getUserFamilyInvite(UserDetails.uuid).then(
            (response) => {
              setInviteList(response.InviteData);

              setLoading(false);
            },
            (error) => {
              setLoading(false);
            }
          );
          if (status === "Onboarded") {
            if (RedFamDash !== "RedFamDash" && inviteList.length === 1) {
              setShowInviteModel1(true);
            } else {
              setShowInviteModel(true);
            }
            setFamilyDashLink(`/family_dashboard/` + familyUUID);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else {
      UserService.update_SU_FamilyInviteStatus(inviteId, updateData).then(
        (response) => {
          //refresh family list
          setLoading(true);
          UserService.getUserFamily(UserDetails.uuid).then(
            (response) => {
              setFamilyList(response.usersFamilyData);

              setLoading(false);
            },
            (error) => {
              setLoading(false);
            }
          );
          //refresh family invite list
          UserService.getUserFamilyInvite(UserDetails.uuid).then(
            (response) => {
              setInviteList(response.InviteData);

              setLoading(false);
            },
            (error) => {
              setLoading(false);
            }
          );
          if (status === "Onboarded") {
            if (RedFamDash !== "RedFamDash" && inviteList.length === 1) {
              setShowInviteModel1(true);
            } else {
              setShowInviteModel(true);
            }
            setFamilyDashLink(`/family_dashboard/` + familyUUID);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  return (
    <>
      {loading ? (
        <div className="loadind-page">
          <Loading />
        </div>
      ) : (
        <>
          <Col md="12" lg="10" className="text-left" style={{ margin: "auto" }}>
            <Card className="family-invitation-card my-1 sizing-properly">
              <Card.Body className="p-4 family-invitation-cardBody">
                <p className="title-heading">Group Invitations</p>
                <p className="small-text">
                  Find all the onboarding invitations that you have received on
                  the Elderly Life platform
                </p>
                <div className="fmly-invite-div card-scroll-element">
                  {inviteList.length !== 0 &&
                  inviteList.some(
                    (el) => el.inviteStatus === "Acceptance Pending"
                  ) ? (
                    inviteList.map((userInvite, index) =>
                      userInvite.inviteStatus === "Acceptance Pending" ? (
                        <div className="mb-4" key={index}>
                          <Invite
                            familyName={userInvite.Family.name}
                            userRole={
                              userInvite.isAu === true
                                ? "Main user"
                                : "Support User"
                            }
                            variant="invite"
                            invitedBy={
                              userInvite.invitedBy
                                ? userInvite.invitedBy.firstName +
                                  " " +
                                  userInvite.invitedBy.lastName
                                : ""
                            }
                            acceptClick={() =>
                              handleInviteStatus(
                                userInvite.id,
                                "Onboarded",
                                userInvite.Family.uuid,
                                userInvite.isAu
                              )
                            }
                            rejectClick={() =>
                              handleInviteStatus(
                                userInvite.id,
                                "Cancelled",
                                userInvite.Family.uuid,
                                userInvite.isAu
                              )
                            }
                          />
                        </div>
                      ) : (
                        " "
                      )
                    )
                  ) : (
                    <div className="text-center mt-3">
                      <Image
                        className="envelop"
                        src={Envelop}
                        alt="Envelop image"
                      />
                      <p className="small-blue-text">
                        you have no invitations to act upon
                      </p>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Modal
            show={showInviteModel}
            onHide={handleShowInviteModelClose}
            className="invite_accpt_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <h3>Congratulations !!</h3>
              <Image
                className="hello-text-img my-4"
                src={CheckGreen}
                alt="Check mark"
                width="18%"
              />
              <p>You are Successfully Onboarded into the Group</p>

              <Link to={familyDashLink}>
                <Large
                  children="Visit your group page"
                  size="largebtn"
                  variant="primary"
                  type=""
                  buttonWidth="260px"
                  font_Weight="600"
                />
              </Link>
            </Modal.Body>
          </Modal>

          <Modal show={showInviteModel1} className="invite_accpt_modal">
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <h3>Congratulations !!</h3>
              <Image
                className="hello-text-img my-4"
                src={CheckGreen}
                alt="Check mark"
                width="18%"
              />
              <p>You are Successfully Onboarded into the Group</p>

              <Link to={familyDashLink}>
                <Large
                  children="Visit your Group page"
                  size="largebtn"
                  variant="primary"
                  type=""
                  buttonWidth="260px"
                  font_Weight="600"
                />
              </Link>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default MyInvites;
