import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Danger } from "../../stories/Button.stories";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import { Primary } from "../../stories/Button.stories";
import { BlueTertiary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import { Info } from "../../stories/Button.stories";
import moment from "moment";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";

const ViewTaskDetails = (props) => {
  var idValue = localStorage.getItem("id");
  var PrimaryIdval = localStorage.getItem("PrimaryIdval");
  let history = useHistory();
  const myRef = useRef(null);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [taskDetails, setTaskDetails] = useState([]);
  const [taskEmail, setTaskEmail] = useState("");
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [isEmpty, setIsEmpty] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showConf, setShowConf] = useState(false);
  let { uuid, ParentId, taskId, userUUID } = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [taskUrl, setTaskUrl] = useState("");
  const [taskEditUrl, setTaskEditUrl] = useState("");
  const [backText, setBackText] = useState("Back to My To Do's");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [familyId, setFamilyId] = useState();
  const [userId, setUserId] = useState();
  const [allValue, setAllValue] = useState([]);
  const [clickVal, setClickVal] = useState({});
  const [newArrayVal, setnewArrayVal] = useState([]);
  const date = new Date();
  const [highlightBox, setHighlightBox] = useState("");
  const abc = parseInt(localStorage.getItem("taskID"));
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    // get family details
    setLoader(true);
    if (userUUID) {
      setTaskUrl("/user_todos/" + uuid + "/" + userUUID);
      UserService.userProfile(userUUID).then((response) => {
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      setTaskUrl("/todos/" + uuid);
    }
    // get single warranty details by ID

    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        console.log(response);
        setFamilyDetails(response);
        if (userUUID) {
          UserService.getTaskDetailsById(taskId).then((response) => {
            UserService.getTaskDetailsByParentId(
              response.data.ParentId,
              response.data.FamilyUserId,
              response.data.FamilyId
            ).then((res) => {
              setAllValue(res.data);
              var newArray = [];
              for (var i = 0; i < res.data.length; i++) {
                const dateToday = new Date();
                const dateVal = new Date(Date.parse(res.data[i].eta));

                if (dateToday === dateVal) {
                  newArray.push(res.data[i]);
                } else if (dateToday > dateVal) {
                  newArray.push(res.data[i]);
                }
              }

              setnewArrayVal(newArray);
            });
            setHighlightBox("highlightBox");
            setClickVal(response.data);
            setTaskDetails(response.data);
            setTaskEmail(response.data.assigneeId.User.email);
            setTaskEditUrl(
              "/edit_user_todos/" + uuid + "/" + taskId + "/" + userUUID
            );
          });
        } else {
          UserService.getTaskDetailsByParentId(
            taskId,
            response.FamilyData.id,
            response.FamilyData.FamilyId
          ).then(
            (resp) => {
              console.log(resp);
              setAllValue(resp.data);
              setClickVal(resp.data[0]);
              var newArray = [];
              for (var i = 0; i < resp.data.length; i++) {
                const dateToday = new Date();
                const dateVal = new Date(Date.parse(resp.data[i].eta));

                if (dateToday === dateVal) {
                  newArray.push(resp.data[i]);
                } else if (dateToday > dateVal) {
                  newArray.push(resp.data[i]);
                }
              }

              setnewArrayVal(newArray);
              var array = [];
              for (let i = 0; i < resp.data.length; i++) {
                if (
                  props.location.state
                    ? props.location.state === resp.data[i].id
                    : abc === resp.data[i].id
                ) {
                  setHighlightBox("highlightBox");
                  array.push(resp.data[i]);
                  setClickVal(array[0]);
                  setTaskDetails(array[0]);
                  setTaskEmail(array[0].assigneeId.User.email);
                  if (resp.data[i]) {
                    if (userUUID) {
                      setTaskEditUrl(
                        "/edit_user_todos/" +
                          uuid +
                          "/" +
                          resp.data[i].id +
                          "/" +
                          userUUID
                      );
                    } else {
                      if (props.location.state) {
                        setTaskEditUrl(
                          "/edit_todos/" + uuid + "/" + props.location.state //+ response.data.rows[0].id
                        );
                      } else {
                        setTaskEditUrl(
                          "/edit_todos/" + uuid + "/" + abc //+ response.data.rows[0].id
                        );
                      }
                    }
                  } else {
                    setTaskEditUrl("#");
                  }
                }
              }
            },
            (error) => {}
          );
        }
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      },
      (error) => {}
    );
  }, []);

  // Delete confirmation
  const handleShowDeleteShow = (aaa) => {
    setShowDelete(true);
    //setGiftId(id);
  };

  // Close delete confirmation
  const handleShowDeleteClose = () => {
    setShowDelete(false);
  };

  // Delete task
  const handleDeleteSubscription = (aaa) => {
    setShowDelete(false);

    UserService.deleteTask(aaa).then(
      (response) => {
        setShowToast(true);
        setSuccessToast(true);
        setToastMessage("To Do deleted successfully");
        setTimeout(() => {
          history.push(taskUrl);
        }, 2000);
      },
      (error) => {}
    );
  };

  // Delete confirmation
  const handleshowConf = (aaa) => {
    setShowConf(true);
    //setGiftId(id);
  };

  // Close delete confirmation
  const handleshowConfClose = () => {
    setShowConf(false);
  };
  // update task status to complete
  const updateTaskStatus = (aaa) => {
    setShowConf(false);

    let data = {
      status: "Completed",
    };
    UserService.updateTaskAsComplete(data, aaa).then(
      (response) => {
        if (response.status === 400) {
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(response.data.message);
        } else {
          setShowToast(true);
          setSuccessToast(true);
          setToastMessage("To Do mark as complete successfully");
          setTimeout(() => {
            history.push(taskUrl);
          }, 2000);
        }
      },
      (error) => {}
    );
  };

  //
  //
  return (
    <div className="page-content view-warranty-pg">
      <Container fluid>
        {/* toast */}
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        {loader ? (
          <div style={{ "margin-top": "12.3rem" }}>
            <Loading />
          </div>
        ) : (
          <>
            <span className="nav-back">
              <p className="mb-3">
                <Link to={taskUrl}>
                  <img src={leftArrow} alt="arrow" className="mr-2" />
                  {userUUID ? `Back to ${backText}’s To Do's` : `${backText}`}
                </Link>
              </p>
            </span>
            <Row className="mt-4 taskView-row">
              <Col md="4" lg="4"></Col>
              <Col md="8" lg="8">
                {taskDetails && taskDetails.status == "Active" ? (
                  <span className="align-right mx-4">
                    <Danger
                      children="Delete"
                      size="mediumbtn"
                      variant="danger"
                      type=""
                      buttonWidth="120%"
                      font_Weight="500"
                      onClick={() =>
                        props.location.state
                          ? handleShowDeleteShow(props.location.state)
                          : handleShowDeleteShow(abc)
                      }
                    />
                  </span>
                ) : (
                  ""
                )}
                {taskDetails && taskDetails.status === "Active" ? (
                  taskDetails.taskRecurrence ? (
                    ""
                  ) : (
                    <span className="align-right mx-4">
                      <Link to={taskEditUrl} className="">
                        <Primary
                          children="Edit To Do"
                          size="mediumbtn"
                          variant="primary"
                          type=""
                          buttonWidth="120%"
                          font_Weight="500"
                        />
                      </Link>
                    </span>
                  )
                ) : (
                  ""
                )}
                {taskDetails &&
                taskDetails.status === "Active" &&
                UserDetails.email === clickVal.assigneeId.User.email &&
                (newArrayVal.length > 0
                  ? clickVal.id === newArrayVal[0].id
                  : newArrayVal.length === 0) ? (
                  <span className="align-right mx-5">
                    <BlueTertiary
                      children="Mark To Do as Complete"
                      size="mediumbtn"
                      variant="blue_tertiary"
                      type=""
                      buttonWidth="120%"
                      font_Weight="500"
                      onClick={() =>
                        props.location.state
                          ? handleshowConf(props.location.state)
                          : handleshowConf(abc)
                      }
                    />
                  </span>
                ) : (
                    newArrayVal.length > 0
                      ? clickVal.id === newArrayVal[0].id
                      : ""
                  ) ? (
                  ""
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row className="mt-4 details-content">
              <Col md="4" lg="4">
                <h4>To Do's details</h4>
                <small>To Do's Name</small>
                <p className="view-txt">
                  {clickVal ? (clickVal.name ? clickVal.name : "") : ""}
                </p>
                <small>To Do's Details</small>
                <p className="view-txt">
                  {clickVal ? (clickVal.details ? clickVal.details : "--") : ""}
                </p>
                <small>ETA</small>
                <p className="view-txt">
                  {clickVal ? moment(clickVal.eta).format("DD-MM-YYYY") : ""}
                </p>
                <small>To Do's Recurrance</small>
                <p className="view-txt">
                  {clickVal && clickVal.taskRecurrence ? "Yes" : "No"}
                </p>
                {clickVal && clickVal.taskRecurrence ? (
                  <>
                    <small>To Do's Frequency</small>
                    <p className="view-txt">
                      {clickVal ? clickVal.taskFrequency : ""}
                    </p>
                  </>
                ) : (
                  ""
                )}
                {clickVal && clickVal.taskRecurrence ? (
                  <>
                    <small>Number of Occurrences</small>
                    <p className="view-txt">
                      {allValue ? allValue.length : ""}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </Col>
              <Col md="4" lg="4">
                <h4>Assigned to</h4>
                <p className="reminder-switch-on task-assignee-name">
                  {clickVal.assigneeId
                    ? clickVal.assigneeId.User.firstName === "null"
                      ? clickVal.assigneeId.User.email
                      : clickVal.assigneeId.User.firstName +
                        " " +
                        clickVal.assigneeId.User.lastName
                    : ""}
                </p>
                {clickVal && clickVal.taskRecurrence ? (
                  <>
                    <h4 className="occcurance-box">Recurrence details</h4>
                    <p className="new-ETA-box task-assignee-name">
                      <div className="ETA-box">
                        <span>ETA</span>
                        <span>Status</span>
                      </div>

                      <div
                        className={
                          allValue.length > 3
                            ? "new-val-table"
                            : allValue.length === 3
                            ? "new-val-table-3"
                            : allValue.length === 2
                            ? "new-val-table-2"
                            : "new-val-table-1"
                        }
                      >
                        {allValue.map((allVal, index) => (
                          <div
                            key={index}
                            className={
                              props.location.state
                                ? allVal.id === props.location.state
                                  ? "high-map-ETA-box"
                                  : "map-ETA-box"
                                : allVal.id === abc
                                ? "high-map-ETA-box"
                                : "map-ETA-box"
                            }
                          >
                            <span>
                              {allVal
                                ? moment(allVal.eta).format("DD-MM-YYYY")
                                : ""}
                            </span>
                            <span>
                              {allVal
                                ? new Date() > new Date(allVal.eta)
                                  ? "Completed"
                                  : allVal.status === "Completed"
                                  ? "Completed"
                                  : "Pending"
                                : ""}
                            </span>
                          </div>
                        ))}
                      </div>
                    </p>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Modal
              show={showDelete}
              onHide={handleShowDeleteClose}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mx-1">
                  <h3 className="mb-0">Delete To Do</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mx-1">
                <p className="my-3 subtext">
                  All the to do's related details will be deleted from the
                  family
                </p>
                <p>Do you want to continue?</p>
                <div className="mb-2">
                  <span>
                    <Info
                      children="Cancel"
                      variant="info"
                      size="mediumbtn"
                      type=""
                      buttonWidth="120px"
                      onClick={handleShowDeleteClose}
                    />
                  </span>
                  <span className="ml-4">
                    <Primary
                      children="Yes"
                      variant="primary"
                      size="mediumbtn"
                      type=""
                      buttonWidth="120px"
                      onClick={() =>
                        props.location.state
                          ? handleDeleteSubscription(props.location.state)
                          : handleDeleteSubscription(abc)
                      }
                    />
                  </span>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={showConf}
              onHide={handleshowConfClose}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mx-1">
                  <h3 className="mb-0">Mark To Do as Complete</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mx-1">
                <p className="my-3 subtext">
                  All the to do's related details will be marked as completed
                  from the group
                </p>
                <p>Do you want to continue?</p>
                <div className="mb-2">
                  <span>
                    <Info
                      children="Cancel"
                      variant="info"
                      size="mediumbtn"
                      type=""
                      buttonWidth="120px"
                      onClick={handleshowConfClose}
                    />
                  </span>
                  <span className="ml-4">
                    <Primary
                      children="Yes"
                      variant="primary"
                      size="mediumbtn"
                      type=""
                      buttonWidth="120px"
                      onClick={() =>
                        props.location.state
                          ? updateTaskStatus(props.location.state)
                          : updateTaskStatus(abc)
                      }
                    />
                  </span>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </div>
  );
};
export default ViewTaskDetails;
