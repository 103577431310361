import React from "react"
import AddSupportUserCard from '../components/Cards/AddSupportUserCard'

export default {
    title: 'Cards/AddSupportUserCard',
    component: AddSupportUserCard,
    args: {
        message: 'ActionAlert message',
       
    },
}

const Template = args => <AddSupportUserCard {...args} />

export const AddSupportUserBasic = Template.bind({})
AddSupportUserBasic.args = {
    userName: 'Richard Parker',
    userEmail: 'Richpark@gmail.com',
    variant: "basic"
}