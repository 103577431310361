import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import HelloText from "../../assets/images/hello-text.svg";
import { Primary } from "../../stories/Button.stories";
import moment from "moment";
import layer from "../../assets/images/layer1.svg";
import editIcon from "../../assets/images/Edit.svg";
import VerticalDots from "../../assets/images/three-dots-vertical.svg";
import MagnifierIcon from "../../assets/images/magnifier.png";
import removeSearch from "../../assets/images/search_remove.png";
import Dropdown from "react-bootstrap/Dropdown";
import GiftCardsEmpty from "../../assets/images/gift-cards-empty.svg";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { Info } from "../../stories/Button.stories";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Delete from "../../assets/images/Delete.svg";
import FileViewer from "react-file-viewer";
import PDFViewer from "pdf-viewer-reactjs";

const GiftCards = (props) => {
  const myRef = useRef(null);
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const executeScroll = () => myRef.current.scrollIntoView();
  const filterOption = [
    { value: "all", label: "All" },
    { value: "expired", label: "Expired" },
    { value: "active", label: "Active" },
  ];
  const sortOption = [
    { value: "createdAt", label: "Created" },
    { value: "updatedAt", label: "Updated" },
    { value: "expiryDate", label: "Expiry Date" },
    { value: "totalAmount", label: "Total Amount" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
      height: "3.75rem",
      "padding-left": "0.5rem",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };
  var userType = localStorage.getItem("userType");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  const [familyDetails, setFamilyDetails] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [title, setTitle] = useState("My Vouchers & Gift Cards");
  const [subTitle, setSubTitle] = useState(
    "You can track all your Vouchers & Gift Cards on Elderly Life platform here."
  );
  const [backText, setBackText] = useState("Back to Group Dashboard");
  const [backUrl, setBackUrl] = useState("");
  const [ToAmount, setToAmount] = useState("");
  const [addGiftcardUrl, setAddGiftcardUrl] = useState("");
  const [parentUser, setParentUser] = useState();
  const [userGiftcardsList, setUserGiftcardsList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [showSearchText, setShowSearchText] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showCancelReminder, setShowCancelReminder] = useState(false);
  const [giftCardDetails, setGiftCardDetails] = useState([]);
  const [giftId, setGiftId] = useState(false);
  const [giftyppe, setgiftyppe] = useState(false);

  const [cardType1, setCardType1] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("createdAt");
  const [orderBy, setOrderBy] = useState("DESC");
  const [familyUserId, setFamilyUserId] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [dataLength, setDataLength] = useState(0);

  const [GiftCount, setGiftCount] = useState("0");
  const [GiftCost, setGiftCost] = useState("£ 0");
  const [VoucherCount, setVoucherCount] = useState("0");
  const [VoucherCost, setVoucherCost] = useState("£ 0");
  const [TotalCount, setTotalCount] = useState("0");
  const [TotalCost, setTotalCost] = useState("£ 0");

  const [showDocSub, setShowDocSub] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [isPdf, setIsPdf] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const [iframeLink, setIframeLink] = useState("");

  const [searchValue, setsearchValue] = useState("");
  const [filterValue, setfilterValue] = useState("all");
  const [sortValue, setsortValue] = useState("createdAt");
  const [orderValue, setorderValue] = useState("DESC");

  const viewDetailsRoute = (giftId) => {
    if (userUUID) {
      history.push(
        `/view_user_vouchers_&_gift_cards/${uuid}/${giftId}/${userUUID}`
      );
    } else {
      history.push(`/view__vouchers_&_gift_cards/${uuid}/${giftId}`);
    }
  };
  const editGiftcardRoute = (giftId) => {
    if (userUUID) {
      history.push(
        `/edit_user_vouchers_&_gift_cards/${uuid}/${giftId}/${userUUID}`
      );
    } else {
      history.push(`/edit_vouchers_&_gift_cards/${uuid}/${giftId}`);
    }
  };
  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        setParentUser(response.userData);
        setAddGiftcardUrl(
          "/add_user_vouchers_&_gift_cards/" + uuid + "/" + userUUID
        );
        setBackUrl("/supporting_user_dashboard/" + uuid + "/" + userUUID);

        const abc =
          pUser.firstName === "null"
            ? pUser.email
            : pUser.firstName + " " + pUser.lastName === "null"
            ? ""
            : pUser.lastName;
        setTitle(`${abc}'s Vouchers & GiftCards`);
        setSubTitle(
          `You can track all Vouchers & GiftCards of ${abc}
            here`
        );
        setBackText(
          "Back to " + pUser.firstName + " " + pUser.lastName + "’s EL overview"
        );
      });
    } else {
      setAddGiftcardUrl("/add_vouchers_&_gift_cards/" + uuid);
      getFamilyDetails(UserDetails.id);
      setBackText("Back to Group Dashboard");
      setBackUrl("/family_dashboard/" + uuid);
    }
  }, []);

  // Get Family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        let famUserId = response.FamilyData.id;

        // get giftcards list
        let params = {
          userId: famUserId,
          familyId: response.FamilyData.Family.id,
          searchStr: searchValue,
          sort: sortValue,
          order: orderValue,
          limit: 10,
          offset: 0,
          filBy: filterValue,
        };
        UserService.getUserGiftcardListNew(params).then(
          (response) => {
            setUserGiftcardsList(response.data);
            let nextOffset = 10;
            setDataLength(response.data.length);
            if (response.data.length === 0) {
              setIsEmpty(true);
              setLoadingspinner(false);
              setOffset(0);
              setHasMore(false);
            } else {
              setIsEmpty(false);
              setLoadingspinner(false);
              if (response.data.length >= nextOffset) {
                setOffset(nextOffset);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            }
            setLoading(false);
          },
          (error) => {}
        );

        setFamilyUserId(famUserId);
        getGiftcardListCount(params);
      },
      (error) => {}
    );
  };

  // Get Giftcard List
  const getGiftcardList = (params) => {
    setLoading(true);
    UserService.getUserGiftcardListNew(params).then(
      (response) => {
        setUserGiftcardsList(response.data);
        setLoading(false);

        let nextOffset = Number(Number(params.offset) + Number(params.limit));

        if (Number(response.data.length) >= nextOffset) {
          setOffset(nextOffset);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      },
      (error) => {}
    );
  };

  const [filterValueClick, setFilterValueClick] = useState("all");

  // Filter gift cards
  const giftcardFilter = (e) => {
    let filterVal = e.value;
    setfilterValue(filterVal);
    setFilterValueClick(e.value);
    setLoading(true);
    let params = {
      familyId: familyDetails.FamilyData.Family.id,
      userId: familyUserId,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: 10,
      offset: 0,
      filBy: filterVal,
    };
    UserService.getUserGiftcardListNew(params).then(
      (response) => {
        setUserGiftcardsList(response.data);
        let nextOffset = 10;
        setLoading(false);
        if (Number(response.data.length) >= nextOffset) {
          setOffset(nextOffset);
          setHasMore(true);
        } else {
          setHasMore(false);
          setOffset(0);
        }
      },
      (error) => {}
    );
  };

  // Get more gift card List
  const moreGiftCards = () => {
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limit,
      offset: offset,
      filBy: filterValue,
    };
    UserService.getUserGiftcardListNew(params).then(
      (response) => {
        setUserGiftcardsList([...userGiftcardsList, ...response.data]);
        setDataLength(userGiftcardsList.length + response.data.length);
        let nextOffset = Number(offset) + Number(limit);

        if (userGiftcardsList.length + response.data.length > nextOffset) {
          setOffset(nextOffset);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      },
      (error) => {}
    );
  };

  // Sort gift cards
  const giftcardSort = (e) => {
    setLoading(true);
    setOffset(0);
    setLimit(10);
    let sort = e.value;
    setsortValue(sort);
    setSortBy(sort);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: sort,
      order: orderValue,
      limit: 10,
      offset: 0,
      filBy: filterValue,
    };
    getGiftcardList(params);
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    setShowSearchText(false);
    setLoading(true);
    setSearchStr("");
    setsearchValue("");
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: "",
      sort: sortValue,
      order: orderValue,
      limit: 10,
      offset: 0,
      filBy: filterValue,
    };
    getGiftcardList(params);
  };

  //search box close
  useEffect(() => {
    if (familyDetails.FamilyData != undefined) {
      if (searchStr.length === 0) {
        // clearSearch();
        setShowSearchText(false);
        setLoading(true);
        setSearchStr("");
        setsearchValue("");
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: "",
          sort: sortValue,
          order: orderValue,
          limit: 10,
          offset: 0,
          filBy: filterValue,
        };
        getGiftcardList(params);
      }
    }
  }, [searchStr]);

  // Search gift cards
  const giftcardSearch = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setShowSearchText(true);
      setLoading(true);
      let srchStr = e.target.value;
      setSearchStr(e.target.value);
      setsearchValue(e.target.value);
      if (srchStr === "") {
        clearSearch(e);
      } else {
        setOffset(0);
        setLimit(10);
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: srchStr,
          sort: sortValue,
          order: orderValue,
          limit: 10,
          offset: 0,
          filBy: filterValue,
        };
        getGiftcardList(params);
      }
    }
  };

  // Cancel reminder confirmation
  const handleShowCancelReminderShow = (id, type) => {
    setShowCancelReminder(true);
    setGiftId(id);
    setgiftyppe(type);
  };

  // Close cancel reminder
  const handleShowCancelReminderClose = () => {
    setShowCancelReminder(false);
    setLoadingspinner(true);
    setTimeout(() => {
      setLoadingspinner(false);
    }, 500);
  };

  // Cancel reminder giftcard
  const handleCancelReminder = (giftId, type) => {
    setShowCancelReminder(false);
    UserService.cancelReminderGiftcard(giftId, false).then(
      (response) => {
        let giftList = userGiftcardsList;
        const selectedSubIndex = giftList.findIndex(
          (x) => Number(x.id) === Number(giftId)
        );
        giftList[selectedSubIndex].reminder = false;
        setUserGiftcardsList(giftList);
        setShowToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setSuccessToast(true);
        setToastMessage(`${type} reminder turned off`);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  // true reminder giftcard
  const handleCancelReminder1 = (giftId, type) => {
    UserService.cancelReminderGiftcard(giftId, true).then(
      (response) => {
        let giftList = userGiftcardsList;
        const selectedSubIndex = giftList.findIndex(
          (x) => Number(x.id) === Number(giftId)
        );
        giftList[selectedSubIndex].reminder = true;
        setUserGiftcardsList(giftList);
        setShowToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setSuccessToast(true);
        setToastMessage(`${type} reminder turned on`);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  // Delete confirmation
  const handleShowDeleteShow = (id, cardType) => {
    setShowDelete(true);
    setGiftId(id);
    setCardType1(cardType);
  };

  // Close delete confirmation
  const handleShowDeleteClose = () => {
    setShowDelete(false);
  };

  // Delete giftcard
  const handleDeleteSubscription = (giftId, cardType) => {
    setShowDelete(false);
    setCardType1(cardType);
    UserService.deleteGiftcard(giftId, cardType1).then(
      (response) => {
        let giftList = userGiftcardsList;
        const selectedSubIndex = giftList.findIndex(
          (x) => Number(x.id) === Number(giftId)
        );
        giftList.splice(selectedSubIndex, 1);
        setUserGiftcardsList(giftList);
        if (giftList.length === 0) {
          setIsEmpty(true);
          setLoadingspinner(false);
        } else {
          setIsEmpty(false);
          setLoadingspinner(false);
        }
        setShowToast(true);
        setSuccessToast(true);
        executeScroll();
        window.scrollTo(0, 0);

        // setToastMessage("Giftcard deleted successfully");
        setToastMessage(
          cardType1 === "Gift Card"
            ? "Gift Card deleted successfully"
            : "Voucher deleted successfully"
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  const validateDate = (date) => {
    //get start of day using moment.js
    const now = new Date(); //moment().startOf('day').toDate();
    var pickDate = new Date(date);
    if (pickDate < now) {
      return true; //date is before today's date
    } else {
      return false; //date is today or some day forward
    }
  };

  //giftcard count list
  const getGiftcardListCount = (params) => {
    setLoading(true);
    UserService.getUserGiftcardCount(params).then(
      (response) => {
        setGiftCount(response.GiftcardsCount);
        setGiftCost("£ " + parseFloat(response.GiftcardsTotalPrice).toFixed(2));
        setVoucherCount(response.VouchersCount);
        setVoucherCost(
          "£ " + parseFloat(response.VouchersTotalPrice).toFixed(2)
        );
        setTotalCount(response.GiftcardsCount + response.VouchersCount);
        setTotalCost(
          "£ " +
            parseFloat(
              parseFloat(response.VouchersTotalPrice) +
                parseFloat(response.GiftcardsTotalPrice)
            ).toFixed(2)
        );
      },
      (error) => {}
    );
  };

  // show doc model
  const handleShowDocSubShow = (docUrl) => {
    setShowDocSub(true);
    const file_ext = get_url_extension(docUrl);
    setDocLink(docUrl);

    if (file_ext === "pdf") {
      setIsPdf(true);
      setIsImage(false);
      setIsDocument(false);
    } else if (
      file_ext === "png" ||
      file_ext === "jpg" ||
      file_ext === "jpeg"
    ) {
      setIsPdf(false);
      setIsImage(true);
      setIsDocument(false);
    } else if (file_ext === "doc" || file_ext === "docx") {
      setIsPdf(false);
      setIsImage(false);
      setIsDocument(true);
      const docs = [{ uri: docUrl }];
      setIframeLink(docs);
    }
  };

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };

  var uuidDetails = localStorage.getItem("uuid");
  var userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));

  const dashboardRed = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/family_dashboard/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  return (
    <div className="page-content warranty-pg">
      <Container fluid ref={myRef}>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        {loadingspinner ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            <div className="Reminder-backBtn" onClick={() => dashboardRed()}>
              {" "}
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              {parentUserUUId === null ? (
                <span>Back to Group Dashboard</span>
              ) : (
                <span>Back to Main User Dashboard</span>
              )}
            </div>
            <Row>
              <Col md="8" lg="8">
                <h3>{title}</h3>
                <p className="sub-title">{subTitle}</p>
              </Col>
              <Col md="4" lg="4">
                {isEmpty ? (
                  ""
                ) : (
                  <span className="align-right">
                    <Link to={addGiftcardUrl} className="">
                      <Primary
                        children="+ Add New Vouchers & Gift Cards"
                        size="mediumbtn"
                        variant="primary"
                        type=""
                        //buttonWidth="220px"
                        font_Weight="500"
                      />
                    </Link>
                  </span>
                )}
              </Col>
            </Row>
            {isEmpty ? (
              <>
                <Row>
                  <Col md="6" lg="6" className="text-left my-4">
                    <p className="sub-text mb-4">
                      You currently have no Vouchers & Gift Cards set up!
                    </p>
                    <Link to={addGiftcardUrl} className="">
                      <Large
                        children="+ Add New Vouchers & Gift Cards"
                        size="largebtn"
                        variant="primary"
                        type=""
                        buttonWidth="330px"
                        font_Weight="500"
                      />
                    </Link>
                  </Col>
                  <Col md="6" lg="6">
                    <Image
                      className="align-right empty-src-img"
                      src={GiftCardsEmpty}
                      alt="Vouchers & Gift Cards empty icon"
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row
                  className="subStats-card"
                  style={{ "margin-bottom": "1rem" }}
                >
                  <Col md="4" lg="2" xl="4" xxl="2">
                    <div className="main-div-dsn">
                      <div className="text-dsn-outgoing-kpi"> Vouchers </div>
                      <div className="sub-div-dsn">
                        <div>
                          <div className="text-dsn-outgoing-kpi-section">
                            Total Items
                          </div>
                          <div className="cost-dsn-outgoing-kpi">
                            {VoucherCount}
                          </div>
                        </div>
                        <div>
                          <div className="text-dsn-outgoing-kpi-section">
                            Items value
                          </div>
                          <div className="cost-dsn-outgoing-kpi">
                            {VoucherCost}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="4" lg="2" xl="4" xxl="2">
                    <div className="main-div-dsn">
                      <div className="text-dsn-outgoing-kpi"> Gift Cards </div>
                      <div className="sub-div-dsn">
                        <div>
                          <div className="text-dsn-outgoing-kpi-section">
                            Total Items
                          </div>
                          <div className="cost-dsn-outgoing-kpi">
                            {GiftCount}
                          </div>
                        </div>
                        <div>
                          <div className="text-dsn-outgoing-kpi-section">
                            Items value
                          </div>
                          <div className="cost-dsn-outgoing-kpi">
                            {GiftCost}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="4" lg="2" xl="4" xxl="2">
                    <div className="main-div-dsn">
                      <div className="text-dsn-outgoing-kpi"> Totals </div>
                      <div className="sub-div-dsn">
                        <div>
                          <div className="text-dsn-outgoing-kpi-section">
                            Total Items
                          </div>
                          <div className="cost-dsn-outgoing-kpi">
                            {TotalCount}
                          </div>
                        </div>
                        <div>
                          <div className="text-dsn-outgoing-kpi-section">
                            Items value
                          </div>
                          <div className="cost-dsn-outgoing-kpi">
                            {TotalCost}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-5 subSearch-row">
                  <Col md="5" lg="6">
                    <div className="input-group search-input-group">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search Vouchers & Gift Cards"
                        value={searchStr}
                        onChange={(e) => setSearchStr(e.target.value)}
                        onKeyDown={giftcardSearch}
                      />
                      <div className="input-group-btn">
                        {!searchStr && (
                          <button
                            className="btn btn-default search-input-btn"
                            type="submit"
                          >
                            <Image src={MagnifierIcon} alt="magnifier icon" />
                          </button>
                        )}
                        {searchStr && (
                          <button
                            className="btn btn-default clear-search"
                            type="submit"
                            onClick={clearSearch}
                          >
                            <Image src={removeSearch} alt="Clear Search" />
                          </button>
                        )}
                      </div>
                    </div>
                    {showSearchText && searchStr && (
                      <div className="search-text mt-3">
                        Search Results for : <span>{searchStr}</span>
                      </div>
                    )}
                  </Col>
                  <Col md="1" lg="2"></Col>
                  <Col md="1" lg="4">
                    <div className="d-flex justify-content-end">
                      <div className="d-flex">
                        <div className="filter-label pl-2 border-radius-rignt-none">
                          Filter by :
                        </div>
                        <div
                          className="p-0 filter-select"
                          style={{ minWidth: "150px" }}
                        >
                          <Select
                            styles={customStyles}
                            options={filterOption}
                            defaultValue={{ label: "All", value: "all" }}
                            onChange={giftcardFilter}
                          />
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="filter-label ml-2 pl-2 border-radius-rignt-none">
                          Sort by :
                        </div>
                        <div
                          className="p-0 filter-select"
                          style={{ minWidth: "150px" }}
                        >
                          <Select
                            styles={customStyles}
                            options={sortOption}
                            defaultValue={{
                              label: "Created",
                              value: "createdAt",
                            }}
                            onChange={giftcardSort}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {userGiftcardsList.length > 0 && !loading && (
                  <div className="subscription-list">
                    <InfiniteScroll
                      dataLength={dataLength}
                      next={moreGiftCards}
                      hasMore={hasMore}
                      loader={<Loading />}
                      style={{ overflow: "inherit" }}
                    >
                      {userGiftcardsList.map((giftcard, index) => (
                        <>
                          <span key={index}>
                            <Card className="mb-4 warrantList-cards">
                              <Card.Body>
                                <div className="d-flex align-elements align-items-start">
                                  <div
                                    onClick={() =>
                                      viewDetailsRoute(giftcard.id)
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="logo-box"
                                  >
                                    <img
                                      src={
                                        giftcard.GiftcardsSupplier.logo !==
                                          null &&
                                        giftcard.GiftcardsSupplier.logo !== ""
                                          ? giftcard.GiftcardsSupplier.logo
                                          : "https://el-backenddata.s3.eu-west-2.amazonaws.com/adminassets/Default_logo.jpg"
                                      }
                                      alt="gift card product logo"
                                      className="category-logo"
                                    />
                                  </div>
                                  <div
                                    onClick={() =>
                                      viewDetailsRoute(giftcard.id)
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="Product-type"
                                  >
                                    <small
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 expired"
                                          : "mb-0"
                                      }
                                    >
                                      Type
                                    </small>
                                    <p
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 tab-txt expired"
                                          : "mb-0 tab-txt"
                                      }
                                    >
                                      {giftcard.cardType
                                        ? giftcard.cardType
                                        : "-"}
                                    </p>
                                  </div>
                                  <div
                                    onClick={() =>
                                      viewDetailsRoute(giftcard.id)
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="supplier-box"
                                  >
                                    <small
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 expired"
                                          : "mb-0"
                                      }
                                    >
                                      Supplier
                                    </small>
                                    <p
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 tab-txt expired"
                                          : "mb-0 tab-txt"
                                      }
                                    >
                                      {giftcard.GiftcardsSupplier.name}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      "margin-right": "5px",
                                      "margin-left": "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewDetailsRoute(giftcard.id)
                                    }
                                  >
                                    <small
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 expired"
                                          : "mb-0"
                                      }
                                    >
                                      Total Amount
                                    </small>
                                    <p
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 tab-txt expired"
                                          : "mb-0 tab-txt"
                                      }
                                    >
                                      {giftcard.totalAmount
                                        ? "£" + giftcard.totalAmount
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      "margin-right": "5px",
                                      "margin-left": "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewDetailsRoute(giftcard.id)
                                    }
                                  >
                                    <small
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 expired"
                                          : "mb-0"
                                      }
                                    >
                                      Used Amount
                                    </small>
                                    <p
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 tab-txt expired"
                                          : "mb-0 tab-txt"
                                      }
                                    >
                                      {/* {giftcard.usedAmount
      ? "£" + giftcard.usedAmount
      : "N/A"} */}
                                      {giftcard.cardType === "Voucher"
                                        ? "--"
                                        : "£" + giftcard.usedAmount}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      "margin-right": "35px",
                                      "margin-left": "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewDetailsRoute(giftcard.id)
                                    }
                                  >
                                    <small
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 expired"
                                          : "mb-0"
                                      }
                                    >
                                      Remaining Amount
                                    </small>
                                    <p
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 tab-txt expired"
                                          : "mb-0 tab-txt"
                                      }
                                    >
                                      {/* £{" "}
    {parseFloat(
      giftcard.totalAmount - giftcard.usedAmount
    ).toFixed(2)} */}

                                      {giftcard.cardType === "Voucher"
                                        ? "£" +
                                          parseFloat(
                                            giftcard.totalAmount
                                          ).toFixed(2)
                                        : "£" +
                                          parseFloat(
                                            giftcard.totalAmount -
                                              giftcard.usedAmount
                                          ).toFixed(2)}
                                    </p>
                                  </div>
                                  <div
                                    onClick={() =>
                                      viewDetailsRoute(giftcard.id)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <small
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 expired-red"
                                          : "mb-0"
                                      }
                                    >
                                      Expires on
                                    </small>
                                    <p
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 tab-txt expired-red"
                                          : "mb-0 tab-txt"
                                      }
                                    >
                                      {moment(giftcard.expiryDate).format(
                                        "MMM Do, YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div
                                  // onClick={() => viewDetailsRoute(subData.id)}
                                  // style={{ cursor: "pointer" }}
                                  >
                                    <small
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 expired"
                                          : "mb-0"
                                      }
                                    >
                                      Reminder
                                    </small>
                                    <p
                                      className={
                                        validateDate(giftcard.expiryDate)
                                          ? "mb-0 tab-txt expired d-flex"
                                          : "mb-0 tab-txt d-flex"
                                      }
                                    >
                                      <label class="switch m-0">
                                        <input
                                          type="checkbox"
                                          id="togBtn"
                                          defaultChecked={
                                            giftcard.reminder === true
                                              ? true
                                              : false
                                          }
                                          onClick={(e) =>
                                            e.target.checked === false
                                              ? handleShowCancelReminderShow(
                                                  giftcard.id,
                                                  giftcard.cardType
                                                )
                                              : handleCancelReminder1(
                                                  giftcard.id,
                                                  giftcard.cardType
                                                )
                                          }
                                          // onChange={onSwitchAction}
                                        />
                                        <div class="slider round"></div>
                                      </label>
                                    </p>
                                  </div>
                                  <div className="call2actionbtns">
                                    <span className="list-btn my-1 d-flex justify-content-between">
                                      {/* <Dropdown className="profile-dropdwn align-right">
                                    <Dropdown.Toggle
                                      variant=""
                                      className="m-0 user-dropdown"
                                      id=""
                                    >
                                      <Image
                                        className="m-1 mt-3 edit-icon"
                                        src={editIcon}
                                        alt="Vertical Dots"
                                        width="8%"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          viewDetailsRoute(giftcard.id)
                                        }
                                      >
                                        View Details
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          editGiftcardRoute(giftcard.id)
                                        }
                                      >
                                        {giftcard.cardType === "Voucher" ? (
                                          <span>Edit Voucher</span>
                                        ) : (
                                          <span>Edit Gift Card</span>
                                        )}
                                        
                                      </Dropdown.Item>
                                      {giftcard.reminder && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleShowCancelReminderShow(
                                              giftcard.id
                                            )
                                          }
                                        >
                                          Cancel Reminder
                                        </Dropdown.Item>
                                      )}
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleShowDeleteShow(
                                            giftcard.id,
                                            giftcard.cardType
                                          )
                                        }
                                      >
                                        {giftcard.cardType === "Voucher" ? (
                                          <span>Delete Voucher</span>
                                        ) : (
                                          <span>Delete Gift Card</span>
                                        )}
                                        
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown> */}
                                      <div className="doc-box">
                                        {giftcard.documentLink ? (
                                          // <Link
                                          //   to={{
                                          //     pathname: giftcard.documentLink
                                          //       ? giftcard.documentLink
                                          //       : "#",
                                          //   }}
                                          //   target="_blank"
                                          // >
                                          <svg
                                            id="surface1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            // width="20.383"
                                            // height="22.405"
                                            viewBox="0 0 20.383 22.405"
                                            onClick={() =>
                                              handleShowDocSubShow(
                                                giftcard.documentLink
                                              )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              // position: "absolute",
                                              // left: "3.2rem",
                                              top: " 1.4rem",
                                              width: "1.5rem",
                                            }}
                                          >
                                            <path
                                              id="Path_16605"
                                              data-name="Path 16605"
                                              d="M15.27,9.7V5.459a.491.491,0,0,0-.135-.322L10.381.145A.474.474,0,0,0,10.04,0H2.5A2.511,2.511,0,0,0,0,2.526V16.778a2.5,2.5,0,0,0,2.5,2.508H8.452A6.422,6.422,0,1,0,15.27,9.7Zm-4.763-8.06L13.7,5H11.627a1.128,1.128,0,0,1-1.121-1.125Zm-8,16.712A1.564,1.564,0,0,1,.934,16.778V2.526A1.577,1.577,0,0,1,2.5.934h7.07V3.871A2.059,2.059,0,0,0,11.627,5.93h2.708V9.577c-.14,0-.252-.019-.374-.019A6.516,6.516,0,0,0,9.7,11.165H3.773a.467.467,0,1,0,0,.934H8.835a7.43,7.43,0,0,0-.822,1.448H3.773a.467.467,0,0,0,0,.934H7.71a6.409,6.409,0,0,0,.28,3.876H2.5Zm11.455,3.124a5.487,5.487,0,1,1,5.487-5.487A5.493,5.493,0,0,1,13.958,21.476Zm0,0"
                                              fill="#003a6f"
                                            />
                                            <path
                                              id="Path_16606"
                                              data-name="Path 16606"
                                              d="M76.018,202.618h4.73a.467.467,0,1,0,0-.934h-4.73a.467.467,0,1,0,0,.934Zm0,0"
                                              transform="translate(-72.245 -192.858)"
                                              fill="#003a6f"
                                            />
                                            <path
                                              id="Path_16607"
                                              data-name="Path 16607"
                                              d="M251.264,286.709l-1.919,2.069v-5.1a.467.467,0,1,0-.934,0v5.1l-1.933-2.069a.471.471,0,0,0-.663-.023.465.465,0,0,0-.023.658l2.732,2.937a.463.463,0,0,0,.682,0l2.736-2.937a.464.464,0,1,0-.677-.635Zm0,0"
                                              transform="translate(-234.916 -270.818)"
                                              fill="#003a6f"
                                            />
                                          </svg>
                                        ) : (
                                          // </Link>
                                          // </p>
                                          //</Link>
                                          ""
                                        )}
                                      </div>

                                      <Image
                                        className="m-1 edit-icon mx-5"
                                        src={editIcon}
                                        alt="editIcon"
                                        // width="8%"
                                        onClick={() =>
                                          editGiftcardRoute(giftcard.id)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          top: " 1.2rem",
                                          width: "1.5rem",
                                        }}
                                      />
                                      <Image
                                        className="m-1 edit-icon"
                                        src={Delete}
                                        alt="Delete"
                                        // width="8%"
                                        onClick={() =>
                                          handleShowDeleteShow(
                                            giftcard.id,
                                            giftcard.cardType
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          top: " 1.2rem",
                                          width: "1.5rem",
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <Modal
                              show={showDelete}
                              onHide={handleShowDeleteClose}
                              className="invite_au_modal"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title className="mx-1">
                                  <h3 className="mb-0">
                                    {/* Delete Voucher & Gift Card */}
                                    {cardType1 === "Voucher" ? (
                                      <span>Delete Voucher</span>
                                    ) : (
                                      <span>Delete Gift Card</span>
                                    )}
                                  </h3>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="mx-1">
                                <p className="my-3 subtext">
                                  {/* All the Voucher & Gift Card related details will
                              be deleted from the family */}
                                  {cardType1 === "Voucher" ? (
                                    <span>
                                      All the Voucher related details will be
                                      deleted from the family
                                    </span>
                                  ) : (
                                    <span>
                                      All the Gift Card related details will be
                                      deleted from the family
                                    </span>
                                  )}
                                </p>
                                <p>Do you want to continue?</p>

                                <div className="mb-2">
                                  <span>
                                    <Info
                                      children="Cancel"
                                      variant="info"
                                      size="mediumbtn"
                                      type=""
                                      buttonWidth="120px"
                                      onClick={handleShowDeleteClose}
                                    />
                                  </span>
                                  <span className="ml-4">
                                    <Primary
                                      children="Yes"
                                      variant="primary"
                                      size="mediumbtn"
                                      type=""
                                      buttonWidth="120px"
                                      onClick={() =>
                                        handleDeleteSubscription(giftId)
                                      }
                                    />
                                  </span>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </span>
                        </>
                      ))}
                    </InfiniteScroll>
                  </div>
                )}

                {loading && <Loading />}

                {userGiftcardsList.length === 0 && !loading && !showSearchText && (
                  <Row>
                    <Col
                      md="12"
                      lg="12"
                      className="mt-5 pt-5 small-text text-center"
                    >
                      No data found
                    </Col>
                  </Row>
                )}
                {userGiftcardsList.length === 0 &&
                  !loading &&
                  showSearchText &&
                  searchStr && (
                    <Row>
                      <Col
                        md="12"
                        lg="12"
                        className="mt-5 pt-5 small-text text-center"
                      >
                        <div className="empty-search-text-title">
                          There are no results for the search query
                        </div>
                        <div className="empty-search-text-sub-title">
                          Try searching Vouchers & Gift Cards with modified
                          terms
                        </div>
                      </Col>
                    </Row>
                  )}
              </>
            )}
          </>
        )}

        {/* // cancel giftcard reminder */}
        <Modal
          show={showCancelReminder}
          onHide={handleShowCancelReminderClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">Cancel Reminder</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <p className="my-3 subtext">
              {giftyppe} reminder will be turned off
            </p>
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowCancelReminderClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleCancelReminder(giftId, giftyppe)}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>

        {/* View document model */}
        <Modal
          show={showDocSub}
          onHide={handleShowDocSubClose}
          className="invite_au_modal doc_modal"
          size={isDocument ? "xl" : "lg"}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">View Document</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="mx-1"
            style={{
              "overflow-y": "auto",
              /* height: fit-content; */
              height: "200rem",
            }}
          >
            {isPdf ? (
              <PDFViewer
                document={{
                  url: docLink,
                }}
              />
            ) : (
              // <>
              //   <Document file={docLink} onLoadSuccess={onDocumentLoadSuccess}>
              //     <Page pageNumber={pageNumber} />
              //   </Document>
              //   <p>
              //     Page {pageNumber} of {numPages}
              //   </p>
              // </>
              ""
            )}
            {isImage ? (
              <img
                src={docLink}
                alt="Contract Document"
                className="image-document-size"
              />
            ) : (
              ""
            )}
            {isDocument ? (
              <FileViewer fileType="docx" filePath={docLink} />
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Link
              to={{
                pathname: docLink,
              }}
              target="_blank"
            >
              Download
            </Link>
          </Modal.Footer>
        </Modal>

        {/* // Delete giftcard */}
        {/* <Modal
          show={showDelete}
          onHide={handleShowDeleteClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">Delete Voucher & Gift Card</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <p className="my-3 subtext">
              All the Voucher & Gift Card related details will be deleted from
              the family
            </p>
            <p>Do you want to continue?</p>
            
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowDeleteClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleDeleteSubscription(giftId)}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal> */}
      </Container>
    </div>
  );
};
export default GiftCards;
