import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import EditIcon from "../../assets/images/Edit.svg";
import DeleteIcon from "../../assets/images/Delete.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import { clickFn } from "../Cards/SeatCard";
import { SeatOccupied } from "../../stories/SeatCard.stories";
import { AddSupportUserBasic } from "../../stories/AddSupportUserCard.stories";
import Modal from "react-bootstrap/Modal";
import { Primary } from "../../stories/Button.stories";
import { Small } from "../../stories/Input.stories";
import { Info } from "../../stories/Button.stories";
import ProfileAvatar from "../../assets/images/profile_avatar.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import SuAUEmptyImage from "../../assets/images/SuAUEmpty.svg";
import { Medium } from "../../stories/Input.stories";
import SubscriptionsRoundIcon from "../../assets/images/subscriptions_round_icon.svg";
import TasksRoundIcon from "../../assets/images/Tasks_round_icon.svg";
import WarrantiesRoundIcon from "../../assets/images/Warranties_round_icon.svg";
import GiftcardsRoundIcon from "../../assets/images/Giftcards_round_icon.svg";
import Group_12893 from "../../assets/images/Group 12893.svg";
import Group_12891 from "../../assets/images/Group 12891.svg";
import BlueArrow from "../../assets/images/right_arrow_blue.svg";
import VerticalDots from "../../assets/images/three-dots-vertical.svg";
import setting from "../../assets/images/settings (4).svg";
// import Ellipse1 from "../../assets/images/Ellipse 376.svg";
// import Ellipse2 from "../../assets/images/Ellipse 377.svg";
import notif from "../../assets/images/notification.svg";
import Loading from "../../common/Loading";

const FamilyDashboard = (props) => {
  const [mainUser, setMainUser] = useState(false);
  const [supportUser, setSupportUser] = useState(false);
  localStorage.setItem("RedFamDash", "RedFamDash");
  const [loadVal, setloadVal] = useState(true);
  localStorage.removeItem("parentUser.uuid");
  const [loader, setLoader] = useState(true);
  let history = useHistory();
  const myRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [showChangeNameToAo, setShowChangeNameToAo] = useState(false);
  const [isChooseInviteDisabled, setIsChooseInviteDisabled] = useState(true);
  const [userType, setUserType] = useState("");
  const [removeUserModelTitle, setRemoveUserModelTitle] = useState("");
  const [removeUserModelTitleSu, setRemoveUserModelTitleSu] = useState("");
  const initialFamilyDetails = props.location.singleFamilyDetails
    ? props.location.singleFamilyDetails
    : [];
  const [familyDetails, setFamilyDetails] = useState(initialFamilyDetails);
  const [planDetails, setPlanDetails] = useState([]);
  const [familyUsersList, setFamilyUsersList] = useState([]);
  const [familyNotificationList, setFamilyNotificationList] = useState([]);
  const [showInviteAu, setShowInviteAu] = useState(false);
  const [ValueRem, setValueRem] = useState("all");
  const handleShowInviteAuClose = () => setShowInviteAu(false);
  const [familyRemDetails1, setFamilyRemDetails1] = useState([]);
  const handleShowInviteAuShow = () => {
    setShowInviteAu(true);
    setFormValues(initialValues);
    setFormErrors({});
    setIsAuSubmit(false);
  };
  // Choose Su
  const [showChooseSu, setShowChooseSu] = useState(false);
  const handleShowChooseSuClose = () => setShowChooseSu(false);
  const handleShowChooseSuShow = () => {
    setIsChooseInviteDisabled(true);
    setShowChooseSu(true);
  };
  // Su invite
  const [showInviteSu, setShowInviteSu] = useState(false);
  const handleShowInviteSuClose = () => setShowInviteSu(false);
  const handleShowInviteSuShow = () => {
    setShowInviteSu(true);
    setFormValues(initialValues);
    setFormErrors({});
    setIsSuSubmit(false);
  };
  // remove account user
  const [showRemoveAuUser, setShowRemoveAuUser] = useState(false);
  const [showRemoveSuUser, setShowRemoveSuUser] = useState(false);
  const [auSeatsCount, setAuSeatsCount] = useState(0);
  const [suSeatsCount, setSuSeatsCount] = useState(0);
  const [remainingAuSeatCount, setRemainingAuSeatCount] = useState(0);
  const [remainingSuSeatCount, setRemainingSuSeatCount] = useState(0);
  const [occupiedAuSeatCount, setOccupiedAuSeatCount] = useState(0);
  const [occupiedSuSeatCount, setOccupiedSuSeatCount] = useState(0);
  const [auInviteName, setAuInviteName] = useState("");
  const [auInviteName1, setAuInviteName1] = useState("");
  const [auInviteEmail, setAuInviteEmail] = useState("");
  const [suInviteName, setSuInviteName] = useState("");
  const [suInviteName1, setSuInviteName1] = useState("");
  const [suInviteEmail, setSuInviteEmail] = useState("");
  const [auInviteId, setAuInviteId] = useState("");
  const [auInviteImage, setAuInviteImage] = useState("");
  const [suInviteId, setSuInviteId] = useState("");
  const [suInviteImage, setSuInviteImage] = useState("");
  const handleShowRemoveAuUserClose = () => setShowRemoveAuUser(false);
  const handleShowRemoveSuUserClose = () => setShowRemoveSuUser(false);
  const executeScroll = () => myRef.current.scrollIntoView();

  //update family name
  const initialFmlyNameValues = {
    family_name: "",
  };
  const [updateFmlyFormValues, setUpdateFmlyFormValues] = useState(
    initialFmlyNameValues
  );
  const [updateFmlyFormErrors, setUpdateFmlyFormErrors] = useState("");
  const [showUpdateFmlyModal, setShowUpdateFmlyModal] = useState(false);
  const [isUpdateFmlySubmit, setIsUpdateFmlySubmit] = useState(false);
  const handleUpdateFmlyClose = () => setShowUpdateFmlyModal(false);

  const handleUpdateFmlyChange = (e) => {
    const { name, value } = e.target;
    setUpdateFmlyFormValues({ ...updateFmlyFormValues, [name]: value });
  };
  // handle family name submit
  const handleFamilyNameSubmit = (e) => {
    e.preventDefault();
    setUpdateFmlyFormErrors(validateFamilyName(updateFmlyFormValues));
    setIsUpdateFmlySubmit(true);
  };
  const validateFamilyName = (values) => {
    const errors = {};
    if (!values.family_name) {
      errors.msg = "Please enter family name";
      errors.family_name = "Yes";
    }
    return errors;
  };

  const [suAO, setSuAO] = useState({});
  const [suAU, setSuAU] = useState([]);
  const [suAUSU, setSuAUSU] = useState([]);

  const handleShowRemoveAuUserShow = (userList, type) => {
    setShowRemoveAuUser(true);
    setRemoveUserModelTitle(type);
    setAuInviteName(
      userList.User.firstName === "null" ? "" : userList.User.firstName
    );
    setAuInviteName1(
      userList.User.lastName === "null" ? "" : userList.User.lastName
    );
    setAuInviteEmail(userList.User.email);
    setAuInviteImage(userList.User.avatar);
    setAuInviteId(userList.id);
  };

  const handleShowRemoveSuUserShow = (userList, type) => {
    console.log(userList, type);
    setShowRemoveSuUser(true);
    setMainUser(false);

    setRemoveUserModelTitleSu(type);
    setSuInviteName(
      userList.User
        ? userList.User.firstName === "null"
          ? ""
          : userList.User.firstName
        : userList.Family_User.User.firstName
    );
    setSuInviteName1(
      userList.User
        ? userList.User.lastName === "null"
          ? ""
          : userList.User.lastName
        : userList.Family_User.User.lastName
    );
    setSuInviteEmail(
      userList.User ? userList.User.email : userList.Family_User.User.email
    );
    setSuInviteImage(
      userList.User ? userList.User.avatar : userList.Family_User.User.avatar
    );
    setSuInviteId(userList.id);
  };

  const handleCancelAuInvite = (inviteId, type) => {
    console.log(inviteId);
    console.log(type);
    if (type === "Revoke Main") {
      let updateData = {
        fuuid: uuid,
        id: inviteId,
        pu: true,
      };
      // revoke family invite
      UserService.revokeFamilyInviteStatus(updateData).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Invitation revoked successfully");
            UserService.getFamilyInviteList(uuid, "").then((res1) => {
              setFamilyUsersList(res1.result.rows);
              setOccupiedAuSeatCount(res1.AuOcuupiedCount);
              // remaining AU seat
              let remainingSeatCount =
                parseInt(auSeatsCount) - parseInt(res1.AuOcuupiedCount);
              setRemainingAuSeatCount(remainingSeatCount);

              setOccupiedSuSeatCount(res1.SuOccupiedCount);
              let su_remainingSeatCount =
                parseInt(suSeatsCount) - parseInt(res1.SuOccupiedCount);
              setRemainingSuSeatCount(su_remainingSeatCount);
            });
            setTimeout(function () {
              //window.location.reload();
            }, 2000);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove Main") {
      UserService.removeFamilyUser(uuid, [inviteId], []).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveAuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            executeScroll();
            window.scrollTo(0, 0);
            setToastMessage("User removed successfully");
            setTimeout(() => {
              //window.location.reload();
            }, 2000);
            // refresh fmly details
            UserService.getFamilyInviteList(uuid, "").then((res1) => {
              setFamilyUsersList(res1.result.rows);
              setOccupiedAuSeatCount(res1.AuOcuupiedCount);
              // // remaining AU seat
              let remainingSeatCount =
                parseInt(auSeatsCount) - parseInt(res1.AuOcuupiedCount);
              setRemainingAuSeatCount(remainingSeatCount);
              setOccupiedSuSeatCount(res1.SuOccupiedCount);
              let su_remainingSeatCount =
                parseInt(suSeatsCount) - parseInt(res1.SuOccupiedCount);
              setRemainingSuSeatCount(su_remainingSeatCount);
            });
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove Support") {
      UserService.removeFamilyUser(uuid, [], [inviteId]).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User removed successfully");
            // refresh fmly details
            UserService.getFamilyInviteList(uuid, "").then((res1) => {
              setFamilyUsersList(res1.result.rows);
              setOccupiedAuSeatCount(res1.AuOcuupiedCount);
              // // remaining AU seat
              let remainingSeatCount =
                parseInt(auSeatsCount) - parseInt(res1.AuOcuupiedCount);
              setRemainingAuSeatCount(remainingSeatCount);
              setOccupiedSuSeatCount(res1.SuOccupiedCount);
              let su_remainingSeatCount =
                parseInt(suSeatsCount) - parseInt(res1.SuOccupiedCount);
              setRemainingSuSeatCount(su_remainingSeatCount);
            });
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove this user as Support") {
      UserService.removeSUFromAuFamily(
        uuid,
        familyDetails.FamilyData.id,
        inviteId
      ).then(
        (response) => {
          if (response.status === 400 || response.status === 404) {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User removed as SU successfully");
            // refresh fmly details
            setTimeout(function () {
              //window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Stop Supporting this") {
      UserService.removeSUFromAuFamily(
        uuid,
        inviteId,
        familyDetails.FamilyData.id
      ).then(
        (response) => {
          if (response.status === 400 || response.status === 404) {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveAuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);

            setToastMessage("Stop supporting this user successfully");

            // refresh fmly details
            setTimeout(function () {
              //window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };
  console.log(familyDetails);
  const handleCancelSuInvite = (inviteId, type) => {
    if (type === "Revoke Support") {
      let updateData = {
        //status: "Removed",
        fuuid: uuid,
        id: inviteId,
        pu: false,
      };
      // revoke family invite
      UserService.revokeFamilyInviteStatus(updateData).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveSuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveSuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Invitation revoked successfully");
            UserService.getFamilyInviteList(uuid, "").then((res1) => {
              setFamilyUsersList(res1.result.rows);
              setOccupiedAuSeatCount(res1.AuOcuupiedCount);
              // remaining AU seat
              let remainingSeatCount =
                parseInt(auSeatsCount) - parseInt(res1.AuOcuupiedCount);
              setRemainingAuSeatCount(remainingSeatCount);

              setOccupiedSuSeatCount(res1.SuOccupiedCount);
              let su_remainingSeatCount =
                parseInt(suSeatsCount) - parseInt(res1.SuOccupiedCount);
              setRemainingSuSeatCount(su_remainingSeatCount);
            });
            setTimeout(function () {
              //window.location.reload();
            }, 2000);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove Support") {
      UserService.removeFamilyUser(uuid, [], [inviteId]).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveSuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveSuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            executeScroll();
            window.scrollTo(0, 0);
            setToastMessage("User removed successfully");
            setTimeout(() => {
              //window.location.reload();
            }, 2000);
            // refresh fmly details
            UserService.getFamilyInviteList(uuid, "").then((res1) => {
              setFamilyUsersList(res1.result.rows);
              setOccupiedAuSeatCount(res1.AuOcuupiedCount);
              // // remaining AU seat
              let remainingSeatCount =
                parseInt(auSeatsCount) - parseInt(res1.AuOcuupiedCount);
              setRemainingAuSeatCount(remainingSeatCount);
              setOccupiedSuSeatCount(res1.SuOccupiedCount);
              let su_remainingSeatCount =
                parseInt(suSeatsCount) - parseInt(res1.SuOccupiedCount);
              setRemainingSuSeatCount(su_remainingSeatCount);
            });
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove this user as Support") {
      UserService.removeSUFromAuFamily(
        uuid,
        familyDetails.FamilyData.id,
        inviteId
      ).then(
        (response) => {
          if (response.status === 400 || response.status === 404) {
            setShowRemoveSuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveSuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);

            setToastMessage("User removed as SU successfully");

            // refresh fmly details
            setTimeout(function () {
              //window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Stop Supporting this") {
      UserService.removeSUFromAuFamily(
        uuid,
        inviteId,
        familyDetails.FamilyData.id
      ).then(
        (response) => {
          if (response.status === 400 || response.status === 404) {
            setShowRemoveSuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
          } else {
            setShowRemoveSuUser(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);

            setToastMessage("Stop supporting this user successfully");

            // refresh fmly details
            setTimeout(function () {
              //window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };
  // view support user details

  const [showSupportUserDetail, setShowSupportUserDetail] = useState(false);
  const [suDetail, setSuDetail] = useState([]);
  const [userListingForSuModel, setUserListingForSuModel] = useState([]);
  const [inviteStat, setInviteStat] = useState("");

  const handleShowSupportUserDetailClose = () =>
    setShowSupportUserDetail(false);
  const handleShowSupportUserDetailShow = (userDetails, userList) => {
    setloadVal(true);

    setInviteStat(userList.inviteStatus);
    setShowSupportUserDetail(true);
    setSuDetail(userDetails.User);
    console.log(userDetails);
    console.log(userList);
    UserService.getOnBoardedFamilyUserList(
      uuid,
      userDetails.User.id,
      "SU"
    ).then((suRes) => {
      if (suRes.usersData) {
        setUserListingForSuModel(suRes.usersData);
      }
    });
    setTimeout(() => {
      setloadVal(false);
    }, 1000);
  };
  // Get login user details from localStorage
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  let { uuid } = useParams();
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
  };
  const [formValues, setFormValues] = useState(initialValues);

  const initialSuFmlyValues = {
    su_id: "",
  };
  const [suFmlyFormValues, setSuFmlyFormValues] = useState(initialSuFmlyValues);

  const [formErrors, setFormErrors] = useState({});
  const [suFmlyFormErrors, setSuFmlyFormErrors] = useState({});
  const [isAuSubmit, setIsAuSubmit] = useState(false);
  const [isSuSubmit, setIsSuSubmit] = useState(false);
  const [isSuFromFmlySubmit, setIsSuFromFmlySubmit] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [chooseSuFormLoading, setChooseSuFormLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [familySuList, setFamilySuList] = useState([]);
  const [familyAuList, setFamilyAuList] = useState([]);
  // stats count
  const [subscriptionsCount, setSubscriptionsCount] = useState("0");
  const [warrantiesCount, setWarrantiesCount] = useState("0");
  const [giftCardsCount, setGiftCardsCount] = useState("0");
  const [tasksCount, setTasksCount] = useState("0");

  const [onBoardedUserListAU, setOnBoardedUserListAU] = useState([]);
  const [onBoardedUserListSU, setOnBoardedUserListSU] = useState([]);

  const [leftAUSUUsers, setLeftAUSUUsers] = useState([]);

  const [allRelData, setallRelData] = useState([]);
  //handle myself(Ao) as Au invite
  const handleInviteMyselfAsAU = () => {
    let data = {
      isAU: true,
    };
    // api call
    UserService.sendFamilyInviteToAoAsAu(uuid, data).then(
      (res) => {
        setFormLoading(false);
        //get whole family data
        getWholeFamilyData();
        //close invite model
        setShowInviteAu(false);
        executeScroll();
        window.scrollTo(0, 0);
        setSuccessToast(true);
        setShowToast(true);
        setToastMessage("User have been successfully invited");
        setTimeout(function () {
          //window.location.reload();
        }, 2000);
      },
      (error) => {
        setFormLoading(false);
      }
    );
  };

  // Assign myself(AO) as SU
  const assignMyselfAOAsSU = (userId) => {
    UserService.sendFamilyInviteToAoAsSu(uuid, userId, {}).then((response) => {
      if (response.status === 400) {
        executeScroll();
        window.scrollTo(0, 0);
        setSuccessToast(false);
        setShowToast(true);
        setToastMessage(response.data.message);
      } else {
        //let famUserId = response.FamilyData.id;
        getWholeFamilyData();
        executeScroll();
        window.scrollTo(0, 0);
        setSuccessToast(true);
        setShowToast(true);
        setToastMessage("You have been assigned successfully as Support User");
        setTimeout(function () {
          //window.location.reload();
        }, 1000);
      }
    });
  };

  // handle Au add invite
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (formValues.email === UserDetails.email) {
      handleInviteMyselfAsAU();
    } else {
      setIsAuSubmit(true);
    }
  };
  // handle Su add invite
  const handleSuInviteChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSuInviteSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSuSubmit(true);
  };
  // handle Choose Su from fmly
  const handleChooseSuChange = (e) => {
    const { name, value } = e.target;
    setSuFmlyFormValues({ ...suFmlyFormValues, [name]: value });
    setIsChooseInviteDisabled(false);
  };
  const handleChooseSuSubmit = (e) => {
    e.preventDefault();
    setSuFmlyFormErrors(validateChooseSu(suFmlyFormValues));
    setIsSuFromFmlySubmit(true);
  };
  const validateChooseSu = (values) => {
    const errors = {};
    if (!values.su_id) {
      errors.msg = "Please enter family name";
      errors.su_id = "Yes";
    }
    return errors;
  };

  const getBoardedUsersListing = (userId, isAU, isSU) => {
    //userTypeData

    UserService.updateSUAURel(uuid).then((response) => {
      setallRelData(response.data);
    });
    // get Au / Su user list
    if (isAU === "AU") {
      UserService.getOnBoardedFamilyUserList(uuid, userId, isAU).then(
        (userlistres) => {
          if (userlistres.usersData && userlistres.usersData.length !== 0) {
            setOnBoardedUserListAU(userlistres.usersData);
            var SupportUserArr = [];
            for (var i = 0; i < userlistres.usersData.length; i++) {
              SupportUserArr.push(
                userlistres.usersData[i].Family_User.User.email
              );
            }
            setSuppUserarr(SupportUserArr);
          }
        }
      );
    }
    if (isSU === "SU") {
      UserService.getOnBoardedFamilyUserList(uuid, userId, isSU).then(
        (userlistres) => {
          if (userlistres.usersData.length !== 0) {
            setOnBoardedUserListSU(userlistres.usersData);
          }
        }
      );
    }
  };

  const [newAr, setNewAr] = useState([]);
  const [newArVal, setNewArVal] = useState([]);
  const [suppUserarr, setSuppUserarr] = useState([]);
  useEffect(() => {
    UserService.relationData(uuid).then((response) => {
      setSuAO(response.data.ao);
      setSuAU(response.data.au);

      var newArra = [];
      for (var i = 0; i < response.data.au.length; i++) {
        newArra.push({
          auEmail: response.data.au[i].User.email,
          UserId: response.data.au[i].UserId,
          id: response.data.au[i].id,
        });
      }
      newArra.forEach((row, ind) => (row.id = ind));
      setSuAUSU(newArra);

      var newArraySU = [];
      for (var i = 0; i < response.data.au.length; i++) {
        for (var j = 0; j < response.data.au[i].su.length; j++) {
          if (
            response.data.au[i].su[j].Family_User.User.email ===
            UserDetails.email
          ) {
            newArraySU.push({
              auEmail: response.data.au[i].User.email,
              UserId: response.data.au[i].UserId,
              id: response.data.au[i].id,
              suId: response.data.au[i].su[j].FamilyUserId,
              suIdEmail: response.data.au[i].su[j].Family_User.User.email,
            });
          }
        }
      }
      newArraySU.forEach((row, ind) => (row.id = ind));
      setNewAr(newArraySU);
      console.log(newArraySU);

      var newArrayForListing = [];
      for (var i = 0; i < response.data.au.length; i++) {
        for (var j = 0; j < response.data.au[i].su.length; j++) {
          if (
            response.data.au[i].su[j].Family_User.User.email ===
            UserDetails.email
          ) {
            newArrayForListing.push(response.data.au[i].User.email);
          }
        }
      }
      setNewArVal(newArrayForListing);
    });
  }, [uuid]);

  const [suAUSU1, setSuAUSU1] = useState([]);
  useEffect(() => {
    UserService.relationData(uuid).then((response) => {
      setSuAO(response.data.ao);
      setSuAU(response.data.au);

      var newArray = [];
      for (var i = 0; i < response.data.au.length; i++) {
        for (var j = 0; j < response.data.au[i].su.length; j++) {
          newArray.push({
            auEmail: response.data.au[i].User.email,
            suId:
              response.data.au[i].su.length > 0
                ? response.data.au[i].su[j].FamilyUserId
                : "",
            suIdEmail:
              response.data.au[i].su.length > 0
                ? response.data.au[i].su[j].Family_User.User.email
                : "",
          });
        }
      }

      newArray.forEach((row, ind) => (row.id = ind));
      setSuAUSU1(newArray);
    });
  }, [uuid]);

  const [uuidValue, setuuidValue] = useState("");
  const [nameFam, setNameFam] = useState("");
  // get whole family details
  const getWholeFamilyData = () => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        localStorage.setItem("FamilyId", response.FamilyData.FamilyId);
        localStorage.setItem("id", response.FamilyData.id);
        localStorage.setItem("PrimaryIdval", response.FamilyData.id);
        localStorage.setItem("uuid", response.FamilyData.Family.uuid);
        const initialFmlyNameValues = {
          family_name: response.FamilyData.Family.name,
        };
        setUpdateFmlyFormValues(initialFmlyNameValues);
        setFamilyDetails(response);
        setNameFam(response.FamilyData.Family.name);

        setuuidValue(response.FamilyData.Family.uuid);
        if (response.FamilyData) {
          if (
            response.FamilyData.isAo &&
            response.FamilyData.isAu &&
            response.FamilyData.isSu
          ) {
            setUserType("AO + AU + SU");
            localStorage.setItem("userType", "AO + AU + SU");
            setShowChangeNameToAo(true);
            getBoardedUsersListing(response.FamilyData.UserId, "AU", "SU");
          } else if (response.FamilyData.isAo && response.FamilyData.isAu) {
            setUserType("AO + AU");
            localStorage.setItem("userType", "AO + AU");
            setShowChangeNameToAo(true);
            getBoardedUsersListing(response.FamilyData.UserId, "AU", null);
          } else if (response.FamilyData.isAo && response.FamilyData.isSu) {
            setUserType("AO + SU");
            localStorage.setItem("userType", "AO + SU");
            setShowChangeNameToAo(true);
            getBoardedUsersListing(response.FamilyData.UserId, null, "SU");
          } else if (response.FamilyData.isAu && response.FamilyData.isSu) {
            setUserType("AU + SU");
            localStorage.setItem("userType", "AU + SU");
            setShowChangeNameToAo(false);
            getBoardedUsersListing(response.FamilyData.UserId, "AU", "SU");
          } else if (response.FamilyData.isAo) {
            setUserType("AO");
            localStorage.setItem("userType", "AO");
            setShowChangeNameToAo(true);
          } else if (response.FamilyData.isAu) {
            setUserType("AU");
            localStorage.setItem("userType", "AU");
            setShowChangeNameToAo(false);
            getBoardedUsersListing(response.FamilyData.UserId, "AU", null);
          } else if (response.FamilyData.isSu) {
            setUserType("SU");
            localStorage.setItem("userType", "SU");
            setShowChangeNameToAo(false);
            getBoardedUsersListing(response.FamilyData.UserId, null, "SU");
          }
        }
        var userType = localStorage.getItem("userType");
        var idDet = localStorage.getItem("id");

        if (userType !== "AU" && userType !== "AU + SU" && userType !== "SU") {
          let params = {
            fid: response.FamilyData.FamilyId,
            fuserId: idDet,
            valClick: ValueRem,
          };
          UserService.allReminderDetails(params).then(
            (res) => {
              console.log(res.data);
              // const array1 = [
              //   ...res.data.giftcardsReminders,
              //   ...res.data.subscriptionReminders,
              //   ...res.data.todosReminders,
              //   ...res.data.vouchersReminders,
              //   ...res.data.warrantiesReminders,
              // ];
              const array1 = [];
              for (let i = 0; i < res.data.giftcardsReminders.length; i++) {
                res.data.giftcardsReminders[i].nameType = "Gift Cards";
                res.data.giftcardsReminders[i].expiredDateVal = new Date(
                  res.data.giftcardsReminders[i].expiryDate
                );

                array1.push(res.data.giftcardsReminders[i]);
              }
              for (let i = 0; i < res.data.subscriptionReminders.length; i++) {
                res.data.subscriptionReminders[i].nameType = "Outgoings";
                res.data.subscriptionReminders[i].expiredDateVal = new Date(
                  res.data.subscriptionReminders[i].endDate
                );

                array1.push(res.data.subscriptionReminders[i]);
              }
              for (let i = 0; i < res.data.todosReminders.length; i++) {
                res.data.todosReminders[i].nameType = "To Do’s";
                res.data.todosReminders[i].expiredDateVal = new Date(
                  res.data.todosReminders[i].eta
                );

                array1.push(res.data.todosReminders[i]);
              }
              for (let i = 0; i < res.data.vouchersReminders.length; i++) {
                res.data.vouchersReminders[i].nameType = "Vouchers";
                res.data.vouchersReminders[i].expiredDateVal = new Date(
                  res.data.vouchersReminders[i].expiryDate
                );

                array1.push(res.data.vouchersReminders[i]);
              }
              for (let i = 0; i < res.data.warrantiesReminders.length; i++) {
                res.data.warrantiesReminders[i].nameType = "Warranties";
                res.data.warrantiesReminders[i].expiredDateVal = new Date(
                  res.data.warrantiesReminders[i].expiryDate
                );

                array1.push(res.data.warrantiesReminders[i]);
              }

              array1.sort((a, b) => a.expiredDateVal - b.expiredDateVal);
              console.log(array1);
              const newArray1 = array1.slice(0, 10);
              setFamilyRemDetails1(newArray1);
              setLoader(false);
            },
            (error) => {}
          );
        } else {
          let params = {
            fid: response.FamilyData.FamilyId,
            fuserId: idDet,
            valClick: response.FamilyData.id,
          };
          UserService.allReminderDetails(params).then(
            (res) => {
              console.log(res.data);
              // const array2 = [
              //   ...res.data.giftcardsReminders,
              //   ...res.data.subscriptionReminders,
              //   ...res.data.todosReminders,
              //   ...res.data.vouchersReminders,
              //   ...res.data.warrantiesReminders,
              // ];
              const array2 = [];
              for (let i = 0; i < res.data.giftcardsReminders.length; i++) {
                res.data.giftcardsReminders[i].nameType = "Gift Cards";
                res.data.giftcardsReminders[i].expiredDateVal = new Date(
                  res.data.giftcardsReminders[i].expiryDate
                );
                array2.push(res.data.giftcardsReminders[i]);
              }
              for (let i = 0; i < res.data.subscriptionReminders.length; i++) {
                res.data.subscriptionReminders[i].nameType = "Outgoings";
                res.data.subscriptionReminders[i].expiredDateVal = new Date(
                  res.data.subscriptionReminders[i].endDate
                );
                array2.push(res.data.subscriptionReminders[i]);
              }
              for (let i = 0; i < res.data.todosReminders.length; i++) {
                res.data.todosReminders[i].nameType = "To Do’s";
                res.data.todosReminders[i].expiredDateVal = new Date(
                  res.data.todosReminders[i].eta
                );
                array2.push(res.data.todosReminders[i]);
              }
              for (let i = 0; i < res.data.vouchersReminders.length; i++) {
                res.data.vouchersReminders[i].nameType = "Vouchers";
                res.data.vouchersReminders[i].expiredDateVal = new Date(
                  res.data.vouchersReminders[i].expiryDate
                );
                array2.push(res.data.vouchersReminders[i]);
              }
              for (let i = 0; i < res.data.warrantiesReminders.length; i++) {
                res.data.warrantiesReminders[i].nameType = "Warranties";
                res.data.warrantiesReminders[i].expiredDateVal = new Date(
                  res.data.warrantiesReminders[i].expiryDate
                );
                array2.push(res.data.warrantiesReminders[i]);
              }

              array2.sort((a, b) => a.expiredDateVal - b.expiredDateVal);
              console.log(array2);
              const newArray2 = array2.slice(0, 10);
              setFamilyRemDetails1(newArray2);
              setLoader(false);
            },
            (error) => {}
          );
        }

        if (response.FamilyData.Family.Family_Subscription) {
          // plan details
          let auCount = parseInt(
            response.FamilyData.Family.Family_Subscription
              .Account_Subscription_Plan.availableS
          );
          setAuSeatsCount(auCount);
          let suCount = parseInt(
            response.FamilyData.Family.Family_Subscription
              .Account_Subscription_Plan.availableS
          );
          setSuSeatsCount(suCount);
          // get single plan details
          setPlanDetails(
            response.FamilyData.Family.Family_Subscription
              .Account_Subscription_Plan
          );
        }

        setLoading(false);
        // get account user list of the family
        UserService.getFamilyInviteList(uuid, "").then((listResponse) => {
          setFamilyUsersList(listResponse.result.rows);
          getPlanUserProfileArr(listResponse.result.rows);
          setOccupiedAuSeatCount(listResponse.AuOcuupiedCount);

          if (response.FamilyData.Family.Family_Subscription) {
            // // remaining AU seat
            let remainingSeatCount =
              parseInt(
                response.FamilyData.Family.Family_Subscription
                  .Account_Subscription_Plan.availableS
              ) - parseInt(listResponse.AuOcuupiedCount);
            setRemainingAuSeatCount(remainingSeatCount);
            // // occupied AU seat
            setOccupiedSuSeatCount(listResponse.SuOccupiedCount);
            let su_remainingSeatCount =
              parseInt(
                response.FamilyData.Family.Family_Subscription
                  .Account_Subscription_Plan.availableS
              ) - parseInt(listResponse.SuOccupiedCount);
            if (su_remainingSeatCount > 0)
              setRemainingSuSeatCount(su_remainingSeatCount);
          }
        });
        //list all su of the family
        UserService.getFamilyAllSu(uuid).then(
          (su_res) => {
            setFamilySuList(su_res.data);
          },
          (error) => {
            setFormLoading(false);
          }
        );
        //list all au of the family
        UserService.getFamilyAllAu(uuid).then(
          (au_res) => {
            //setFormLoading(false);
            setFamilyAuList(au_res.data);
          },
          (error) => {
            setFormLoading(false);
          }
        );
        // get stats
        getStats(response.FamilyData);
        // get notification
        getNotifications(response.FamilyData);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  console.log("familyRemDetails1:::", familyRemDetails1);

  const [auAvatarArr, setAuAvatarArr] = useState([]);
  const [suAvatarArr, setSuAvatarArr] = useState([]);
  const getPlanUserProfileArr = (arr) => {
    var valuesAUObj = [];
    var valuesSUObj = [];
    arr.map(function (arrList, index) {
      if (arrList.isAu === true && arrList.inviteStatus === "Onboarded") {
        valuesAUObj.push({ img: arrList.User.avatar });
      }
      if (arrList.isSu === true && arrList.inviteStatus === "Onboarded") {
        valuesSUObj.push({ img: arrList.User.avatar });
      }
    });
    // Set state
    setAuAvatarArr(valuesAUObj);
    setSuAvatarArr(valuesSUObj);
  };

  useEffect(() => {
    setLoading(true);
    getWholeFamilyData();

    // invite au users
    if (Object.keys(formErrors).length === 0 && isAuSubmit) {
      setFormLoading(true);
      let data = {
        firstName: formValues.firstname,
        lastName: formValues.lastname,
        to: formValues.email,
        from: UserDetails.uuid,
        for: uuid,
        isAU: true,
      };

      setShowInviteAu(false);
      window.scrollTo(0, 0);
      // api call
      UserService.sendFamilyInvite(data).then(
        (res) => {
          if (res.status === 400) {
            if (formValues.email === UserDetails.email) {
              let data = {
                isAU: true,
              };
              // api call
              UserService.sendFamilyInviteToAoAsAu(uuid, data).then(
                (res) => {
                  setFormLoading(false);
                  getWholeFamilyData();
                  setShowInviteAu(false);
                  executeScroll();
                  window.scrollTo(0, 0);
                  setSuccessToast(true);
                  setShowToast(true);
                  setToastMessage("AO has been successfully invited as PU");
                  setTimeout(function () {
                    //window.location.reload();
                  }, 1000);
                },
                (error) => {
                  setFormLoading(false);
                }
              );
            } else {
              setFormLoading(false);
              executeScroll();
              window.scrollTo(0, 0);
              setShowInviteAu(false);
              setShowToast(true);
              setSuccessToast(false);
              setToastMessage(res.data.message);
            }
          } else {
            setFormLoading(false);
            UserService.getFamilyInviteList(uuid, "").then((res1) => {
              setFamilyUsersList(res1.result.rows);
              setOccupiedAuSeatCount(res1.AuOcuupiedCount);
              let remainingSeatCount =
                parseInt(auSeatsCount) - parseInt(res1.AuOcuupiedCount);
              setRemainingAuSeatCount(remainingSeatCount);
              setOccupiedSuSeatCount(res1.SuOccupiedCount);
              let su_remainingSeatCount =
                parseInt(suSeatsCount) - parseInt(res1.SuOccupiedCount);
              setRemainingSuSeatCount(su_remainingSeatCount);
            });
            setShowInviteAu(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User have been successfully invited");
            setTimeout(function () {
              //window.location.reload();
            }, 2000);
          }
        },
        (error) => {
          setFormLoading(false);
        }
      );
      // end api call
      setFormValues(initialValues);
    } else {
      setLoading(false);
    }
    // invite Su users
    if (Object.keys(formErrors).length === 0 && isSuSubmit) {
      setFormLoading(true);
      let data = {
        firstName: formValues.firstname,
        lastName: formValues.lastname,
        to: formValues.email,
        from: UserDetails.uuid,
        for: uuid,
        isAU: false,
      };
      setShowInviteSu(false);
      window.scrollTo(0, 0);
      // api call
      UserService.sendFamilySuInvite(data).then(
        (res) => {
          if (res.status === 400) {
            setFormLoading(false);
            setShowInviteSu(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
          } else {
            setFormLoading(false);
            setShowInviteSu(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User have been successfully invited");
            setTimeout(function () {
              //window.location.reload();
            }, 2000);
          }
        },
        (error) => {
          setFormLoading(false);
        }
      );
      // end api call
      setFormValues(initialValues);
    } else {
      setLoading(false);
    }
    // invite su user from fmly
    if (Object.keys(suFmlyFormErrors).length === 0 && isSuFromFmlySubmit) {
      setChooseSuFormLoading(true);
      let data = {
        uuid: uuid, //family uuuid
        iuid: UserDetails.id, //iniviting user
        suid: suFmlyFormValues.su_id, //su id
      };
      // api call
      UserService.sendFamilyInviteToSufromfamily(data).then(
        (res) => {
          if (res.status === 400) {
            setChooseSuFormLoading(false);
            executeScroll();
            window.scrollTo(0, 0);
            setShowChooseSu(false);
            setShowToast(true);
            setToastMessage(res.data.message);
          } else {
            setChooseSuFormLoading(false);
            setShowChooseSu(false);
            executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User have been successfully invited");
            setTimeout(function () {
              //window.location.reload();
            }, 2000);
          }
        },
        (error) => {
          setChooseSuFormLoading(false);
        }
      );
      // end api call
      setSuFmlyFormValues(initialSuFmlyValues);
    } else {
      setLoading(false);
    }
    if (Object.keys(updateFmlyFormErrors).length === 0 && isUpdateFmlySubmit) {
      setFormLoading(true);
      let data = {
        name: updateFmlyFormValues.family_name,
      };
      // api call
      UserService.updateFamilyName(uuid, data).then(
        (res) => {
          if (res.status === 400) {
            setFormLoading(false);
            setShowUpdateFmlyModal(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
          } else {
            setFormLoading(false);
            setShowUpdateFmlyModal(false);
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Family Name have been successfully updated");
            setTimeout(function () {
              //window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          setFormLoading(false);
        }
      );
    }
  }, [formErrors, suFmlyFormErrors, updateFmlyFormErrors]);
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstname) {
      errors.msg = "Please enter the required fields";
      errors.firstname = "Yes";
    }
    if (!values.lastname) {
      errors.msg = "Please enter the required fields";
      errors.lastname = "Yes";
    }
    if (!values.email) {
      errors.msg = "Please enter the required fields";
      errors.email = "Yes";
    } else if (!regex.test(values.email)) {
      errors.msg = "This is not a valid email format !";
      errors.email = "Yes";
    }
    return errors;
  };
  // Get Stats
  const getStats = (familyData) => {
    UserService.getUserStats(familyData.Family.id, familyData.id).then(
      (response) => {
        if (response.data.count > 0) {
          let res = response.data.rows[0];
          setSubscriptionsCount(res.totalSubscriptions);
          setWarrantiesCount(res.totalWarranties);
          setGiftCardsCount(res.totalGiftCards);
          setTasksCount(res.totalTasks);
        }
      },
      (error) => {}
    );
  };
  // Get Notifications
  const getNotifications = (familyData) => {
    UserService.getUserFamilyNotification(
      familyData.Family.uuid,
      familyData.id
    ).then(
      (response) => {
        if (response.status === 400) {
        } else {
          if (response.data.length !== 0) {
            const list = response.data;
            const size = 2;
            const items = list.slice(0, size);
            setFamilyNotificationList(items);
          }
        }
      },
      (error) => {}
    );
  };

  let arr1 = familySuList.map((ele) => ele.UserId);

  let arr2 = onBoardedUserListAU.map((ele) => ele.Family_User.UserId);

  let bigCities = arr1.filter((ele) => !arr2.includes(ele));

  let newSU = [];
  console.log(bigCities);
  bigCities.map((ele) => {
    familySuList.filter((ele1) => {
      if (ele1.UserId === ele && ele1.UserId !== UserDetails.id) {
        newSU.push(ele1);
      }
    });
  });

  const [valSupp, setValSupp] = useState([]);
  useEffect(() => {
    var newData10 = [];
    const arrayVal = [];
    for (var i = 0; i < familyUsersList.length; i++) {
      if (
        familyUsersList[i].isSu === true &&
        familyUsersList[i].inviteStatus === "Onboarded" &&
        familyUsersList[i].isAo === true
      ) {
        newData10.push({
          email: familyUsersList[i].User.email,
          userId: familyUsersList[i].UserId,
        });
      }

      if (
        familyUsersList[i].invitedBy &&
        familyUsersList[i].inviteStatus === "Acceptance Pending" &&
        familyUsersList[i].invitedBy.id === UserDetails.id
      ) {
        arrayVal.push(familyUsersList[i]);
      }
    }
    newData10.forEach((row, ind) => (row.id = ind));
    setValSupp(newData10);
    const value = [...onBoardedUserListAU, ...arrayVal];
    setLeftAUSUUsers(value);
  }, [familyUsersList]);

  const [valAdmin, setValAdmin] = useState([]);
  useEffect(() => {
    var newData11 = [];
    for (var i = 0; i < familyUsersList.length; i++) {
      if (
        familyUsersList[i].isAu === true &&
        familyUsersList[i].inviteStatus === "Onboarded"
      ) {
        newData11.push({
          email: familyUsersList[i].User.email,
          userId: familyUsersList[i].FamilyId,
          faMid: familyUsersList[i].id,
        });
      }
    }
    newData11.forEach((row, ind) => (row.id = ind));
    setValAdmin(newData11);
  }, [familyUsersList]);

  const [mainLeft, setMainLeft] = useState({});
  const [suppLeft, setSuppLeft] = useState({});
  const [mainRight, setMainRight] = useState([]);
  const [suppRight, setSuppRight] = useState([]);
  const [mainLoading, setmainLoading] = useState(false);
  const [suppLoading, setsuppLoading] = useState(false);

  const mainUserDtls = (userList) => {
    setMainLeft(userList);
    setMainUser(true);
    setmainLoading(true);
    const data1 = userList.UserId;
    UserService.getOnBoardedFamilyUserList(uuid, data1, `AU`).then((res) => {
      setMainRight(res.usersData);
      setTimeout(() => {
        setmainLoading(false);
      }, 250);
    });
  };

  const mainUserClose = () => {
    setMainUser(false);
  };

  const supportUserDtls = (userList) => {
    setSuppLeft(userList);
    setSupportUser(true);
    setsuppLoading(true);

    const data1 = userList.UserId;
    UserService.getOnBoardedFamilyUserList(uuid, data1, `SU`).then((res) => {
      setSuppRight(res.usersData);
      setTimeout(() => {
        setsuppLoading(false);
      }, 250);
    });
  };

  const supportUserClose = () => {
    setSupportUser(false);
  };

  const [auusersu, setauusersu] = useState([]);
  useEffect(() => {
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then((res) => {
      console.log(res);
      UserService.onboardedUserAU(uuid, res.FamilyData.UserId, `AU`).then(
        (response) => {
          console.log(response);
          setauusersu(response.data.usersData);
        }
      );
    });
  }, []);

  const redirectionLink = (value) => {
    console.log(value);
    if (value.nameType === "Outgoings") {
      history.push(`/view_outgoing_details/${uuid}/${value.id}`);
    }
    if (value.nameType === "Warranties") {
      history.push(`/view_warranty_details/${uuid}/${value.id}`);
    }
    if (value.nameType === "Vouchers") {
      history.push(`/view__vouchers_&_gift_cards/${uuid}/${value.id}`);
    }
    if (value.nameType === "Gift Cards") {
      history.push(`/view__vouchers_&_gift_cards/${uuid}/${value.id}`);
    }
    if (value.nameType === "To Do’s") {
      history.push(`/view_todos_details/${uuid}/${value.ParentId}`);
    }
  };

  console.log(familyDetails);

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <div className="page-content family-dashbrd">
      <Container fluid>
        {loader ? (
          <div className="loadind-page">
            <Loading />
          </div>
        ) : (
          <>
            <Row ref={myRef}>
              {/* toast */}
              <ToastBlockContainer
                successToast={successToast}
                showToast={showToast}
                setShowToast={setShowToast}
                toastMessage={toastMessage}
              />
              <div className="family-details-main-familyname mb-3">
                {console.log("familyDetails:::::", familyDetails)}
                <span style={{ fontWeight: "100" }}>
                  Welcome {userType === "AO" && <span>to the</span>}
                  {userType === "SU" && <span>to the</span>}
                </span>{" "}
                <span style={{ fontWeight: "700" }}>
                  {userType !== "SU"
                    ? UserDetails.firstname === "null"
                      ? UserDetails.email
                      : UserDetails.firstname
                    : nameFam}
                </span>
                {userType === "AO" && (
                  <span className="mx-2">Admin Screen</span>
                )}
                {userType === "SU" && (
                  <span className="mx-2">Support Screen</span>
                )}
              </div>
              {userType === "AO" && (
                <div
                  className="mb-3 font-weight-bold"
                  style={{ fontSize: "larger" }}
                >
                  All Users on your family Platform
                </div>
              )}
            </Row>
            <Row className="fmlyDash-firstRow">
              <Col lg="6" xl="6" xxl="7">
                <Row>
                  <Col md="12" lg="12" xl="12" xxl="12">
                    {userType === "AO + AU + SU" ||
                    userType === "AO + AU" ||
                    userType === "AU + SU" ||
                    userType === "AU" ? (
                      <Row className="module-list">
                        <Col md="6" lg="6">
                          <Link to={`/myoutgoings/${uuid}`}>
                            <div>
                              <Card
                                className={`story-card category-cards cards-height`}
                                // style={{ height: "11.7rem" }}
                              >
                                <Card.Body className="p-1">
                                  <div className="d-flex justify-content-center justify-content-xxl-between flex-column flex-xxl-row">
                                    <span className="default-plan-img">
                                      <Image
                                        src={
                                          SubscriptionsRoundIcon
                                            ? SubscriptionsRoundIcon
                                            : ""
                                        }
                                      />
                                    </span>
                                    {subscriptionsCount ? (
                                      <p className="action-right  number-txt ">
                                        {subscriptionsCount}
                                      </p>
                                    ) : (
                                      <p className="action-right  number-txt ">
                                        0
                                      </p>
                                    )}
                                  </div>

                                  <p
                                    className="mt-3 mb-0 count-heading"
                                    style={{ height: "25px" }}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          Outgoings
                                        </Tooltip>
                                      }
                                    >
                                      <span>Outgoings</span>
                                    </OverlayTrigger>
                                  </p>
                                </Card.Body>
                              </Card>
                            </div>
                          </Link>
                        </Col>
                        <Col md="6" lg="6">
                          <Link to={`/warranties/${uuid}`}>
                            <div>
                              <Card
                                className={`story-card category-cards cards-height`}
                                // style={{ height: "11.7rem" }}
                              >
                                <Card.Body className="p-1">
                                  <div className="d-flex justify-content-center justify-content-xxl-between flex-column flex-xxl-row">
                                    <span className="default-plan-img">
                                      <Image
                                        src={
                                          WarrantiesRoundIcon
                                            ? WarrantiesRoundIcon
                                            : ""
                                        }
                                      />
                                    </span>
                                    {warrantiesCount ? (
                                      <p className="action-right  number-txt ">
                                        {warrantiesCount}
                                      </p>
                                    ) : (
                                      <p className="action-right  number-txt ">
                                        0
                                      </p>
                                    )}
                                  </div>

                                  <p
                                    className="mt-3 mb-0 count-heading"
                                    style={{ height: "25px" }}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          Warranties
                                        </Tooltip>
                                      }
                                    >
                                      <span>Warranties</span>
                                    </OverlayTrigger>
                                  </p>
                                </Card.Body>
                              </Card>
                            </div>
                          </Link>
                        </Col>
                        <Col md="6" lg="6">
                          <Link to={`/vouchers_&_gift_cards/${uuid}`}>
                            <div>
                              <Card
                                className={`story-card category-cards cards-height`}
                                // style={{ height: "11.7rem" }}
                              >
                                <Card.Body className="p-1">
                                  <div className="d-flex justify-content-center justify-content-xxl-between flex-column flex-xxl-row">
                                    <span className="default-plan-img">
                                      <Image
                                        src={
                                          GiftcardsRoundIcon
                                            ? GiftcardsRoundIcon
                                            : ""
                                        }
                                      />
                                    </span>
                                    {giftCardsCount ? (
                                      <p className="action-right  number-txt ">
                                        {giftCardsCount}
                                      </p>
                                    ) : (
                                      <p className="action-right  number-txt ">
                                        0
                                      </p>
                                    )}
                                  </div>

                                  <p
                                    className="mt-3 mb-0 count-heading"
                                    style={{ height: "25px" }}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          Vouchers & Gift Cards
                                        </Tooltip>
                                      }
                                    >
                                      <span> Vouchers & Gift Cards</span>
                                    </OverlayTrigger>
                                  </p>
                                </Card.Body>
                              </Card>
                            </div>
                          </Link>
                        </Col>
                        <Col md="6" lg="6">
                          <Link to={`/todos/${uuid}`}>
                            <div>
                              <Card
                                className={`story-card category-cards cards-height`}
                                // style={{ height: "11.7rem" }}
                              >
                                <Card.Body className="p-1">
                                  <div className="d-flex justify-content-center justify-content-xxl-between flex-column flex-xxl-row">
                                    <span className="default-plan-img">
                                      <Image
                                        src={
                                          TasksRoundIcon ? TasksRoundIcon : ""
                                        }
                                      />
                                    </span>
                                    {tasksCount ? (
                                      <p className="action-right  number-txt ">
                                        {tasksCount}
                                      </p>
                                    ) : (
                                      <p className="action-right  number-txt ">
                                        0
                                      </p>
                                    )}
                                  </div>

                                  <p
                                    className="mt-3 mb-0 count-heading"
                                    style={{ height: "25px" }}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          To Do's
                                        </Tooltip>
                                      }
                                    >
                                      <span>To Do's</span>
                                    </OverlayTrigger>
                                  </p>
                                </Card.Body>
                              </Card>
                            </div>
                          </Link>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Col>
              {console.log(userType)}
              <Col lg="6" xl="6" xxl="5" className="new-Btn-position">
                {userType === "AU" ? (
                  <>
                    <div className="AU-Rem-1">
                      <div className="AU-Rem-1-sub-1">
                        <span>Reminders </span>
                        <span className="AU-Rem-1-sub-img">
                          <img
                            src={setting}
                            className="setting"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/reminder_setting/${uuid}`)
                            }
                          />
                        </span>
                      </div>
                      <div
                        className="AU-Rem-1-sub-2"
                        onClick={() =>
                          history.push({
                            pathname: `/reminders/${uuid}`,
                            uuid,
                          })
                        }
                      >
                        View All
                      </div>
                    </div>
                    <div className="famDash-rembox">
                      {familyRemDetails1.length > 0 ? (
                        <>
                          <div
                            className="rem-show famdash-scroll"
                            style={{ paddingRight: "10px" }}
                          >
                            {familyRemDetails1.map((remlist, index) => (
                              <div key={index}>
                                <div className="position-relative py-2 mb-3">
                                  <div className="position-absolute py-1 px-2 dashboard-header1">
                                    {remlist.nameType}
                                  </div>
                                  <div
                                    className="dashboard-details   pt-4 px-3 pb-3 dashboard-subheader1"
                                    onClick={() => redirectionLink(remlist)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {remlist.nameType !== "To Do’s" ? (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          {remlist.nameType === "Outgoings" ||
                                          remlist.nameType === "Warranties" ? (
                                            <>
                                              <b>Category</b>
                                              <div>{remlist.Category.name}</div>
                                            </>
                                          ) : (
                                            <>
                                              <b>Type</b>
                                              <div>{remlist.cardType}</div>
                                            </>
                                          )}
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Supplier</b>
                                          <div>
                                            {remlist.nameType === "Outgoings" ||
                                            remlist.nameType === "Warranties"
                                              ? remlist.Supplier.name
                                              : remlist.GiftcardsSupplier.name}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Amount</b>
                                          <div>
                                            {remlist.nameType === "Outgoings"
                                              ? "£" + remlist.premiumAmount
                                              : remlist.nameType ===
                                                "Warranties"
                                              ? "£" + remlist.price
                                              : remlist.nameType === "Vouchers"
                                              ? "£" +
                                                parseFloat(
                                                  remlist.totalAmount
                                                ).toFixed(2)
                                              : "£" +
                                                parseFloat(
                                                  remlist.totalAmount -
                                                    remlist.usedAmount
                                                ).toFixed(2)}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>End Date</b>
                                          <div>
                                            {moment(
                                              remlist.expiredDateVal
                                            ).format("DD-MM-YYYY")}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Who</b>
                                          <div>
                                            {remlist.assigneeId.User.firstName}{" "}
                                            {remlist.assigneeId.User.lastName}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>What</b>
                                          <div>{remlist.name}</div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>When</b>
                                          <div>
                                            {moment(remlist.eta).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="no-rem">
                          {" "}
                          <img className="no-rem-1" src={notif} alt="notif" />
                          <div className="no-rem-2">
                            You currently have no reminders
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : userType === "AO + AU" ? (
                  <>
                    <div className="AU-Rem-1">
                      <div className="AU-Rem-1-sub-1">
                        <span>Reminders </span>
                        <span className="AU-Rem-1-sub-img">
                          <img
                            src={setting}
                            className="setting"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/reminder_setting/${uuid}`)
                            }
                          />
                        </span>
                      </div>
                      <div
                        className="AU-Rem-1-sub-2"
                        onClick={() =>
                          history.push({
                            pathname: `/reminders/${uuid}`,
                            uuid,
                          })
                        }
                      >
                        View All
                      </div>
                    </div>
                    <div className="famDash-rembox">
                      {familyRemDetails1.length > 0 ? (
                        <>
                          <div
                            className="rem-show famdash-scroll"
                            style={{ paddingRight: "10px" }}
                          >
                            {familyRemDetails1.map((remlist, index) => (
                              <div key={index}>
                                <div className="position-relative py-2 mb-3">
                                  <div className="position-absolute py-1 px-2 dashboard-header1">
                                    {remlist.nameType}
                                  </div>
                                  <div
                                    className="dashboard-details   pt-4 px-3 pb-3 dashboard-subheader1"
                                    onClick={() => redirectionLink(remlist)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {remlist.nameType !== "To Do’s" ? (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          {remlist.nameType === "Outgoings" ||
                                          remlist.nameType === "Warranties" ? (
                                            <>
                                              <b>Category</b>
                                              <div>{remlist.Category.name}</div>
                                            </>
                                          ) : (
                                            <>
                                              <b>Type</b>
                                              <div>{remlist.cardType}</div>
                                            </>
                                          )}
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Supplier</b>
                                          <div>
                                            {remlist.nameType === "Outgoings" ||
                                            remlist.nameType === "Warranties"
                                              ? remlist.Supplier.name
                                              : remlist.GiftcardsSupplier.name}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Amount</b>
                                          <div>
                                            {remlist.nameType === "Outgoings"
                                              ? "£" + remlist.premiumAmount
                                              : remlist.nameType ===
                                                "Warranties"
                                              ? "£" + remlist.price
                                              : remlist.nameType === "Vouchers"
                                              ? "£" +
                                                parseFloat(
                                                  remlist.totalAmount
                                                ).toFixed(2)
                                              : "£" +
                                                parseFloat(
                                                  remlist.totalAmount -
                                                    remlist.usedAmount
                                                ).toFixed(2)}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>End Date</b>
                                          <div>
                                            {moment(
                                              remlist.expiredDateVal
                                            ).format("DD-MM-YYYY")}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Who</b>
                                          <div>
                                            {remlist.assigneeId.User.firstName}{" "}
                                            {remlist.assigneeId.User.lastName}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>What</b>
                                          <div>{remlist.name}</div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>When</b>
                                          <div>
                                            {moment(remlist.eta).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="no-rem">
                          {" "}
                          <img className="no-rem-1" src={notif} alt="notif" />
                          <div className="no-rem-2">
                            You currently have no reminders
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : userType === "AO + AU + SU" ? (
                  <>
                    <div className="AU-Rem-1">
                      <div className="AU-Rem-1-sub-1">
                        <span>Reminders </span>
                        <span className="AU-Rem-1-sub-img">
                          <img
                            src={setting}
                            className="setting"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/reminder_setting/${uuid}`)
                            }
                          />
                        </span>
                      </div>
                      <div
                        className="AU-Rem-1-sub-2"
                        onClick={() =>
                          history.push({
                            pathname: `/reminders/${uuid}`,
                            uuid,
                          })
                        }
                      >
                        View All
                      </div>
                    </div>
                    <div className="famDash-rembox">
                      {familyRemDetails1.length > 0 ? (
                        <>
                          <div
                            className="rem-show famdash-scroll"
                            style={{ paddingRight: "10px" }}
                          >
                            {familyRemDetails1.map((remlist, index) => (
                              <div key={index}>
                                <div className="position-relative py-2 mb-3">
                                  <div className="position-absolute py-1 px-2 dashboard-header1">
                                    {remlist.nameType}
                                  </div>
                                  <div
                                    className="dashboard-details   pt-4 px-3 pb-3 dashboard-subheader1"
                                    onClick={() => redirectionLink(remlist)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {remlist.nameType !== "To Do’s" ? (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          {remlist.nameType === "Outgoings" ||
                                          remlist.nameType === "Warranties" ? (
                                            <>
                                              <b>Category</b>
                                              <div>{remlist.Category.name}</div>
                                            </>
                                          ) : (
                                            <>
                                              <b>Type</b>
                                              <div>{remlist.cardType}</div>
                                            </>
                                          )}
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Supplier</b>
                                          <div>
                                            {remlist.nameType === "Outgoings" ||
                                            remlist.nameType === "Warranties"
                                              ? remlist.Supplier.name
                                              : remlist.GiftcardsSupplier.name}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Amount</b>
                                          <div>
                                            {remlist.nameType === "Outgoings"
                                              ? "£" + remlist.premiumAmount
                                              : remlist.nameType ===
                                                "Warranties"
                                              ? "£" + remlist.price
                                              : remlist.nameType === "Vouchers"
                                              ? "£" +
                                                parseFloat(
                                                  remlist.totalAmount
                                                ).toFixed(2)
                                              : "£" +
                                                parseFloat(
                                                  remlist.totalAmount -
                                                    remlist.usedAmount
                                                ).toFixed(2)}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>End Date</b>
                                          <div>
                                            {moment(
                                              remlist.expiredDateVal
                                            ).format("DD-MM-YYYY")}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Who</b>
                                          <div>
                                            {remlist.assigneeId.User.firstName}{" "}
                                            {remlist.assigneeId.User.lastName}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>What</b>
                                          <div>{remlist.name}</div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>When</b>
                                          <div>
                                            {moment(remlist.eta).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="no-rem">
                          {" "}
                          <img className="no-rem-1" src={notif} alt="notif" />
                          <div className="no-rem-2">
                            You currently have no reminders
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : userType === "AU + SU" ? (
                  <>
                    <div className="AU-Rem-1">
                      <div className="AU-Rem-1-sub-1">
                        <span>Reminders </span>
                        <span className="AU-Rem-1-sub-img">
                          <img
                            src={setting}
                            className="setting"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/reminder_setting/${uuid}`)
                            }
                          />
                        </span>
                      </div>
                      <div
                        className="AU-Rem-1-sub-2"
                        onClick={() =>
                          history.push({
                            pathname: `/reminders/${uuid}`,
                            uuid,
                          })
                        }
                      >
                        View All
                      </div>
                    </div>
                    <div className="famDash-rembox">
                      {familyRemDetails1.length > 0 ? (
                        <>
                          <div
                            className="rem-show famdash-scroll"
                            style={{ paddingRight: "10px" }}
                          >
                            {familyRemDetails1.map((remlist, index) => (
                              <div key={index}>
                                <div className="position-relative py-2 mb-3">
                                  <div className="position-absolute py-1 px-2 dashboard-header1">
                                    {remlist.nameType}
                                  </div>
                                  <div
                                    className="dashboard-details   pt-4 px-3 pb-3 dashboard-subheader1"
                                    onClick={() => redirectionLink(remlist)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {remlist.nameType !== "To Do’s" ? (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          {remlist.nameType === "Outgoings" ||
                                          remlist.nameType === "Warranties" ? (
                                            <>
                                              <b>Category</b>
                                              <div>{remlist.Category.name}</div>
                                            </>
                                          ) : (
                                            <>
                                              <b>Type</b>
                                              <div>{remlist.cardType}</div>
                                            </>
                                          )}
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Supplier</b>
                                          <div>
                                            {remlist.nameType === "Outgoings" ||
                                            remlist.nameType === "Warranties"
                                              ? remlist.Supplier.name
                                              : remlist.GiftcardsSupplier.name}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Amount</b>
                                          <div>
                                            {remlist.nameType === "Outgoings"
                                              ? "£" + remlist.premiumAmount
                                              : remlist.nameType ===
                                                "Warranties"
                                              ? "£" + remlist.price
                                              : remlist.nameType === "Vouchers"
                                              ? "£" +
                                                parseFloat(
                                                  remlist.totalAmount
                                                ).toFixed(2)
                                              : "£" +
                                                parseFloat(
                                                  remlist.totalAmount -
                                                    remlist.usedAmount
                                                ).toFixed(2)}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>End Date</b>
                                          <div>
                                            {moment(
                                              remlist.expiredDateVal
                                            ).format("DD-MM-YYYY")}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Who</b>
                                          <div>
                                            {remlist.assigneeId.User.firstName}{" "}
                                            {remlist.assigneeId.User.lastName}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>What</b>
                                          <div>{remlist.name}</div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>When</b>
                                          <div>
                                            {moment(remlist.eta).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="no-rem">
                          {" "}
                          <img className="no-rem-1" src={notif} alt="notif" />
                          <div className="no-rem-2">
                            You currently have no reminders
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  // userType === "AO + SU" ? (
                  //   <>
                  //     <div
                  //       className="AO-RB-AO"
                  //       onClick={() =>
                  //         history.push({
                  //           pathname: `/reminders/${uuid}`,
                  //           uuid,
                  //         })
                  //       }
                  //     >
                  //       <span className="AO-RB-Text-AO">
                  //         <span style={{ "margin-right": "4rem" }}>
                  //           View All Reminders
                  //         </span>{" "}
                  //         <span>
                  //           <img
                  //             className=""
                  //             src={BlueArrow}
                  //             alt="Profile Avatar"
                  //           />{" "}
                  //         </span>
                  //       </span>
                  //     </div>
                  //     <div
                  //       className="AO-RB-AO"
                  //       onClick={() =>
                  //         history.push(`/family_notification/${uuid}`)
                  //       }
                  //     >
                  //       {" "}
                  //       <span className="AO-RB-Text-AO">
                  //         <span style={{ "margin-right": "4rem" }}>
                  //           View All Event Logs
                  //         </span>{" "}
                  //         <span>
                  //           <img
                  //             className=""
                  //             src={BlueArrow}
                  //             alt="Profile Avatar"
                  //           />{" "}
                  //         </span>
                  //       </span>
                  //     </div>
                  //   </>
                  // )
                  ""
                )}
              </Col>
            </Row>

            {userType === "AO + AU + SU" ? (
              <Row>
                <>
                  {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS == 1 ? (
                              <span> Main User </span>
                            ) : (
                              <span> Main Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedAuSeatCount} /
                            {planDetails.availableS == 1 ? (
                              <span>
                                {" "}
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {" "}
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        {remainingAuSeatCount <= planDetails.availableS &&
                        remainingAuSeatCount != 0 ? (
                          <span
                            className="manageuser-btn"
                            onClick={handleShowInviteAuShow}
                          >
                            Add Main User
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {familyUsersList.map((userList, index) =>
                        userList.isAu === true &&
                        userList.inviteStatus !== "Removed" &&
                        userList.inviteStatus !== "Cancelled" ? (
                          <span key={index}>
                            <div className="PU-dsn">
                              <div className="PU-dsn-sub d-flex justify-content-between align-items-center">
                                <div className="float-left mx-2">
                                  <img
                                    src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                    alt="Profile Avatar"
                                    width="50px"
                                    height="50px"
                                  />
                                </div>
                                <div className="flex-2 word-break">
                                  <div className="main-dsgn-text">
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null"
                                          ? ""
                                          : userList.User.firstName}{" "}
                                        {userList.User.lastName === "null"
                                          ? ""
                                          : userList.User.lastName}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="mmmmmmmmmmm">
                                  {newAr.length > 0 &&
                                    newAr.map((userDetails, index) =>
                                      userList.User.email ===
                                        userDetails.auEmail &&
                                      userDetails.suIdEmail ===
                                        UserDetails.email ? (
                                        <span key={index}>
                                          <Image
                                            className="icon-user"
                                            src={Group_12893}
                                            alt=""
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </div>

                                <div
                                  className="text-dsgn ms-auto d-flex justify-content-end
                                 align-items-center "
                                >
                                  <div className="me-4">
                                    {newAr.length > 0 &&
                                      newAr.map((userDetails, index) =>
                                        userList.User.email ===
                                          userDetails.auEmail &&
                                        userDetails.suIdEmail ===
                                          UserDetails.email ? (
                                          <span key={index}>
                                            <Link
                                              to={`/supporting_user_dashboard/${uuid}/${userList.User.uuid}`}
                                            >
                                              <img
                                                className="icon-user-1"
                                                src={BlueArrow}
                                                alt="Profile Avatar"
                                                onClick={clickFn}
                                              />
                                            </Link>
                                          </span>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </div>

                                  <div>
                                    {userList.inviteStatus ==
                                    "Acceptance Pending"
                                      ? "Acceptance Pending"
                                      : ""}
                                  </div>

                                  <div>
                                    <div>
                                      {userList.inviteStatus === "Onboarded" &&
                                      userList.isAo != true ? (
                                        // userDetails.auEmail ===
                                        //   userList.User.email &&
                                        // userDetails.suIdEmail ===
                                        //   UserDetails.email
                                        newArVal.includes(
                                          userList.User.email
                                        ) == true ? (
                                          <span key={index} className="ggg">
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveAuUserShow(
                                                      userList,
                                                      "Remove Main"
                                                    )
                                                  }
                                                >
                                                  Remove User
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveAuUserShow(
                                                      userList,
                                                      "Stop Supporting this"
                                                    )
                                                  }
                                                >
                                                  Stop Supporting this user
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </span>
                                        ) : (
                                          <span key={index}>
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveAuUserShow(
                                                      userList,
                                                      "Remove Main"
                                                    )
                                                  }
                                                >
                                                  Remove User
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                  onClick={() =>
                                                    assignMyselfAOAsSU(
                                                      userList.id
                                                    )
                                                  }
                                                >
                                                  Assign myself as SU
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </span>
                                          // <span key={index} className="hhhh">
                                          //   "rrrrrrrrr"
                                          // </span>
                                        )
                                      ) : userList.inviteStatus ===
                                          "Onboarded" &&
                                        userList.isAo === true ? (
                                        <span key={index} className="ggg">
                                          <Dropdown className="profile-dropdwn">
                                            <Dropdown.Toggle
                                              variant=""
                                              className="m-0 inviteTxt user-dropdown"
                                              id=""
                                            >
                                              <Image
                                                className="m-1 vertical-dot-icon"
                                                src={VerticalDots}
                                                alt="Vertical Dots"
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleShowRemoveAuUserShow(
                                                    userList,
                                                    "Remove Main"
                                                  )
                                                }
                                              >
                                                Remove User
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </span>
                                      ) : (
                                        <span key={index}>
                                          <Dropdown className="profile-dropdwn">
                                            <Dropdown.Toggle
                                              variant=""
                                              className="m-0 inviteTxt user-dropdown"
                                              id=""
                                            >
                                              <Image
                                                className="m-1 vertical-dot-icon"
                                                src={VerticalDots}
                                                alt="Vertical Dots"
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleShowRemoveAuUserShow(
                                                    userList,
                                                    "Revoke Main"
                                                  )
                                                }
                                              >
                                                Revoke Invite
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        ) : (
                          ""
                        )
                      )}
                      <div className="fhs-vh">
                        <p
                          className="mt-3 mb-2"
                          onClick={() =>
                            history.push({
                              pathname: `/family_seat_history/${uuid}`,
                              query: { tab1: "primary_users" },
                            })
                          }
                          style={{
                            color: "#0d6efd",
                            "font-weight": "500",
                            "text-decoration": "underline",
                            cursor: "pointer",
                          }}
                        >
                          Seat history
                        </p>
                        <p className="mt-3 mb-2">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12893"
                              data-name="Group 12893"
                              transform="translate(-356.4 -788.741)"
                            >
                              <path
                                id="Path_16599"
                                data-name="Path 16599"
                                d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                transform="translate(148.221 788.741)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16600"
                                data-name="Path 16600"
                                d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                transform="translate(176.371 674.265)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16603"
                                data-name="Path 16603"
                                d="M12.729,28.757,8.9,25.713a.613.613,0,0,0-.988.474v1.018a.572.572,0,0,1-.544.613H4.593A.572.572,0,0,0,4,28.392v1.6a.6.6,0,0,0,.593.593H7.372a.546.546,0,0,1,.544.583v1.018a.607.607,0,0,0,.979.465l3.815-2.965A.579.579,0,0,0,12.729,28.757Z"
                                transform="translate(364.296 763.156)"
                                fill="#007ef2"
                              />
                            </g>
                          </svg>
                          User you're supporting
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                  {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS == 1 ? (
                              <span>Support User </span>
                            ) : (
                              <span>Support Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedSuSeatCount} /{" "}
                            {planDetails.availableS == 1 ? (
                              <span>
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        <div>
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      {familyUsersList.map((userList, index) =>
                        userList.isSu === true &&
                        userList.inviteStatus !== "Removed" &&
                        userList.inviteStatus !== "Cancelled" ? (
                          <span key={index}>
                            <div className="PU-dsn">
                              <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                <div className="float-left mx-2">
                                  <img
                                    src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                    alt="Profile Avatar"
                                    width="50px"
                                    height="50px"
                                  />
                                </div>
                                <div className="flex-2 word-break">
                                  <div className="main-dsgn-text">
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  {onBoardedUserListAU.length != 0
                                    ? onBoardedUserListAU.map(
                                        (userDetails, index) => {
                                          const status = userDetails
                                            .supportingUserId.User
                                            ? userDetails.supportingUserId
                                                .inviteStatus
                                            : userDetails.Family_User
                                                .inviteStatus;
                                          return status == "Onboarded" ? (
                                            <span key={index}>
                                              {(userList.User.email ===
                                                UserDetails.email ||
                                                userDetails.Family_User.User
                                                  .id === userList.User.id) &&
                                              userList.isAo === false ? (
                                                <Image
                                                  className="icon-user"
                                                  src={Group_12891}
                                                  alt=""
                                                />
                                              ) : (
                                                " "
                                              )}
                                            </span>
                                          ) : (
                                            " "
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                                <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                  <div>
                                    {userList.inviteStatus ==
                                    "Acceptance Pending"
                                      ? "Acceptance Pending"
                                      : ""}
                                  </div>

                                  <div>
                                    {onBoardedUserListAU.length != 0 ? (
                                      onBoardedUserListAU.map(
                                        (userDetails, index) => {
                                          const status = userDetails
                                            .supportingUserId.User
                                            ? userDetails.supportingUserId
                                                .inviteStatus
                                            : userDetails.Family_User
                                                .inviteStatus;
                                          return status == "Onboarded" ? (
                                            <span key={index}>
                                              {userList.isSu === true &&
                                              userList.inviteStatus !==
                                                "Removed" &&
                                              userList.inviteStatus !==
                                                "Cancelled" ? (
                                                <Dropdown className="profile-dropdwn">
                                                  <Dropdown.Toggle
                                                    variant=""
                                                    className="m-0 inviteTxt user-dropdown"
                                                    id=""
                                                  >
                                                    <Image
                                                      className="m-1 vertical-dot-icon"
                                                      src={VerticalDots}
                                                      alt="Vertical Dots"
                                                    />
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handleShowSupportUserDetailShow(
                                                          userList.User
                                                        )
                                                      }
                                                    >
                                                      View Details
                                                    </Dropdown.Item>

                                                    {userList.inviteStatus ==
                                                    "Acceptance Pending" ? (
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveSuUserShow(
                                                            userList,
                                                            "Revoke Account"
                                                          )
                                                        }
                                                      >
                                                        Revoke Invite
                                                      </Dropdown.Item>
                                                    ) : (
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveSuUserShow(
                                                            userList,
                                                            "Remove Account"
                                                          )
                                                        }
                                                      >
                                                        Remove User
                                                      </Dropdown.Item>
                                                    )}
                                                    {(userList.User.email ===
                                                      UserDetails.email ||
                                                      userDetails.Family_User
                                                        .User.id ===
                                                        userList.User.id) &&
                                                    userList.isAo === false ? (
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveSuUserShow(
                                                            userDetails.Family_User,
                                                            "Remove this user as Support"
                                                          )
                                                        }
                                                      >
                                                        Remove this user as SU
                                                      </Dropdown.Item>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          ) : (
                                            " "
                                          );
                                        }
                                      )
                                    ) : userList.isSu === true &&
                                      userList.inviteStatus !== "Removed" &&
                                      userList.inviteStatus !== "Cancelled" ? (
                                      <Dropdown className="profile-dropdwn">
                                        <Dropdown.Toggle
                                          variant=""
                                          className="m-0 inviteTxt user-dropdown"
                                          id=""
                                        >
                                          <Image
                                            className="m-1 vertical-dot-icon"
                                            src={VerticalDots}
                                            alt="Vertical Dots"
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleShowSupportUserDetailShow(
                                                userList.User
                                              )
                                            }
                                          >
                                            View Details
                                          </Dropdown.Item>

                                          {userList.inviteStatus ==
                                          "Acceptance Pending" ? (
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveSuUserShow(
                                                  userList,
                                                  "Revoke Account"
                                                )
                                              }
                                            >
                                              Revoke Invite
                                            </Dropdown.Item>
                                          ) : (
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveSuUserShow(
                                                  userList,
                                                  "Remove Account"
                                                )
                                              }
                                            >
                                              Remove User
                                            </Dropdown.Item>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div>
                                    <div>
                                      {userList.inviteStatus === "Onboarded" ? (
                                        suppUserarr.includes(
                                          userList.User.email
                                        ) == true ? (
                                          <span key={index}>
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowSupportUserDetailShow(
                                                      userList.User,
                                                      userList
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList,
                                                      "Remove Support"
                                                    )
                                                  }
                                                >
                                                  Remove User
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList,
                                                      "Remove this user as Support"
                                                    )
                                                  }
                                                >
                                                  Remove this user as SU
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </span>
                                        ) : (
                                          <span key={index}>
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowSupportUserDetailShow(
                                                      userList.User,
                                                      userList
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList,
                                                      "Remove Support"
                                                    )
                                                  }
                                                >
                                                  Remove User
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </span>
                                        )
                                      ) : (
                                        <Dropdown className="profile-dropdwn">
                                          <Dropdown.Toggle
                                            variant=""
                                            className="m-0 inviteTxt user-dropdown"
                                            id=""
                                          >
                                            <Image
                                              className="m-1 vertical-dot-icon"
                                              src={VerticalDots}
                                              alt="Vertical Dots"
                                              View
                                              Details
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowSupportUserDetailShow(
                                                  userList.User,
                                                  userList
                                                )
                                              }
                                            >
                                              View Details
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveSuUserShow(
                                                  userList,
                                                  "Revoke Support"
                                                )
                                              }
                                            >
                                              Revoke Invite
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        ) : (
                          ""
                        )
                      )}
                      <div className="fhs-vh-1">
                        <div style={{ display: "contents" }}>
                          <span
                            className="hello-el-fd"
                            onClick={() =>
                              history.push({
                                pathname: `/family_seat_history/${uuid}`,
                                query: { tab1: "support_users" },
                              })
                            }
                            style={{
                              color: "#0d6efd",
                              "font-weight": "500",
                              "text-decoration": "underline",
                              cursor: "pointer",
                            }}
                          >
                            
                            Seat history
                          </span>
                          <span
                            className="hello-el-fd"
                            style={{
                              color: "#0d6efd",
                              "font-weight": "500",
                              "text-decoration": "underline",
                              cursor: "pointer",
                              "margin-right": "35%",
                            }}
                            onClick={() =>
                              history.push({
                                pathname: `/su_invite_history/${uuid}`,
                              })
                            }
                          >
                            Invite history
                          </span>
                        </div>

                        <p className="mt-3 mb-2 ">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12891"
                              data-name="Group 12891"
                              transform="translate(-351.4 -788.741)"
                            >
                              <g
                                id="customer"
                                transform="translate(351.4 788.741)"
                              >
                                <g
                                  id="Group_12873"
                                  data-name="Group 12873"
                                  transform="translate(1.85)"
                                >
                                  <g id="Group_12872" data-name="Group 12872">
                                    <path
                                      id="Path_16599"
                                      data-name="Path 16599"
                                      d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                      transform="translate(-210.029)"
                                      fill="#003a6f"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_12875"
                                  data-name="Group 12875"
                                  transform="translate(0 7.524)"
                                >
                                  <g id="Group_12874" data-name="Group 12874">
                                    <path
                                      id="Path_16600"
                                      data-name="Path 16600"
                                      d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                      transform="translate(-180.029 -122)"
                                      fill="#003a6f"
                                    />
                                  </g>
                                </g>
                              </g>
                              <g
                                id="straight-right-arrow"
                                transform="translate(363.296 788.741)"
                              >
                                <path
                                  id="Path_16603"
                                  data-name="Path 16603"
                                  d="M4.224,28.757l3.825-3.044a.613.613,0,0,1,.988.474v1.018a.572.572,0,0,0,.544.613H12.36a.572.572,0,0,1,.593.573v1.6a.6.6,0,0,1-.593.593H9.581a.546.546,0,0,0-.544.583v1.018a.607.607,0,0,1-.979.465L4.244,29.686A.579.579,0,0,1,4.224,28.757Z"
                                  transform="translate(-4 -25.585)"
                                  fill="#00bb96"
                                />
                              </g>
                            </g>
                          </svg>
                          User supporting you
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                </>
                <>
                  <Col lg="6">
                    <div className="position-relative py-2 ">
                      <div className="position-absolute py-1 px-4 dashboard-header">
                        {planDetails.availableS === 1 ? (
                          <span>Your Support User </span>
                        ) : (
                          <span>Your Support Users </span>
                        )}
                      </div>
                      <div className="dashboard-details my-2  p-4 dashboard-subheader">
                        {familyDetails.FamilyData.isAo === true &&
                        familyDetails.FamilyData.isAu === false &&
                        familyDetails.FamilyData.isSu === false ? (
                          ""
                        ) : familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === true &&
                          familyDetails.FamilyData.isSu === false ? (
                          occupiedSuSeatCount < planDetails.availableS &&
                          newSU.length === 0 ? (
                            <span
                              className="manageuser-btn"
                              onClick={handleShowInviteSuShow}
                            >
                              Add Support User
                            </span>
                          ) : occupiedSuSeatCount >= planDetails.availableS &&
                            newSU.length === 0 ? (
                            ""
                          ) : (
                            <Accordion>
                              <Accordion.Item
                                style={{ width: "14rem" }}
                                eventKey="0"
                              >
                                <Accordion.Header>
                                  Add Support User
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {newSU.length > 0 ? (
                                      <li onClick={handleShowChooseSuShow}>
                                        Choose existing Support Users
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {occupiedSuSeatCount >
                                      planDetails.availableS ||
                                    occupiedSuSeatCount ===
                                      planDetails.availableS ? (
                                      " "
                                    ) : (
                                      <li onClick={handleShowInviteSuShow}>
                                        Invite a new Support User
                                      </li>
                                    )}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        ) : familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === true ? (
                          ""
                        ) : familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === true &&
                          familyDetails.FamilyData.isSu === true ? (
                          occupiedSuSeatCount < planDetails.availableS &&
                          newSU.length === 0 ? (
                            <span
                              className="manageuser-btn"
                              onClick={handleShowInviteSuShow}
                            >
                              Add Support User
                            </span>
                          ) : occupiedSuSeatCount >= planDetails.availableS &&
                            newSU.length === 0 ? (
                            ""
                          ) : (
                            <Accordion>
                              <Accordion.Item
                                style={{ width: "14rem" }}
                                eventKey="0"
                              >
                                <Accordion.Header>
                                  Add Support User
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {newSU.length > 0 ? (
                                      <li onClick={handleShowChooseSuShow}>
                                        Choose existing Support Users
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {occupiedSuSeatCount >
                                      planDetails.availableS ||
                                    occupiedSuSeatCount ===
                                      planDetails.availableS ? (
                                      " "
                                    ) : (
                                      <li onClick={handleShowInviteSuShow}>
                                        Invite a new Support User
                                      </li>
                                    )}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        ) : familyDetails.FamilyData.isAo === false &&
                          familyDetails.FamilyData.isAu === true &&
                          familyDetails.FamilyData.isSu === true ? (
                          occupiedSuSeatCount < planDetails.availableS &&
                          newSU.length === 0 ? (
                            <span
                              className="manageuser-btn"
                              onClick={handleShowInviteSuShow}
                            >
                              Add Support User
                            </span>
                          ) : occupiedSuSeatCount >= planDetails.availableS &&
                            newSU.length === 0 ? (
                            ""
                          ) : (
                            <Accordion>
                              <Accordion.Item
                                style={{ width: "14rem" }}
                                eventKey="0"
                              >
                                <Accordion.Header>
                                  Add Support User
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {newSU.length > 0 ? (
                                      <li onClick={handleShowChooseSuShow}>
                                        Choose existing Support Users
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {occupiedSuSeatCount >
                                      planDetails.availableS ||
                                    occupiedSuSeatCount ===
                                      planDetails.availableS ? (
                                      " "
                                    ) : (
                                      <li onClick={handleShowInviteSuShow}>
                                        Invite a new Support User
                                      </li>
                                    )}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        ) : familyDetails.FamilyData.isAo === false &&
                          familyDetails.FamilyData.isAu === true &&
                          familyDetails.FamilyData.isSu === false ? (
                          occupiedSuSeatCount < planDetails.availableS &&
                          newSU.length === 0 ? (
                            <span
                              className="manageuser-btn"
                              onClick={handleShowInviteSuShow}
                            >
                              Add Support User
                            </span>
                          ) : occupiedSuSeatCount >= planDetails.availableS &&
                            newSU.length === 0 ? (
                            ""
                          ) : (
                            <Accordion>
                              <Accordion.Item
                                style={{ width: "14rem" }}
                                eventKey="0"
                              >
                                <Accordion.Header>
                                  Add Support User
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {newSU.length > 0 ? (
                                      <li onClick={handleShowChooseSuShow}>
                                        Choose existing Support Users
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {occupiedSuSeatCount >
                                      planDetails.availableS ||
                                    occupiedSuSeatCount ===
                                      planDetails.availableS ? (
                                      " "
                                    ) : (
                                      <li onClick={handleShowInviteSuShow}>
                                        Invite a new Support User
                                      </li>
                                    )}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        ) : familyDetails.FamilyData.isAo === false &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === true ? (
                          ""
                        ) : (
                          ""
                        )}
                        {console.log("auusersu::::::::::", auusersu)}
                        {auusersu.length > 0 ? (
                          auusersu.map((userList, index) => (
                            <span key={index}>
                              <div className="PU-dsn">
                                <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                  <div className="float-left mx-2">
                                    <img
                                      src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                      alt="Profile Avatar"
                                      width="50px"
                                      height="50px"
                                    />
                                  </div>
                                  <div className="flex-2 word-break">
                                    <div className="main-dsgn-text">
                                      <div className="PU-dsn-txt">
                                        <span>
                                          {userList.Family_User ? (
                                            userList.Family_User.User
                                              .firstName === "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.Family_User.User
                                                .firstName
                                            )
                                          ) : userList.User.firstName ===
                                            "null" ? (
                                            <span></span>
                                          ) : (
                                            userList.User.firstName
                                          )}{" "}
                                          {userList.Family_User ? (
                                            userList.Family_User.User
                                              .lastName === "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.Family_User.User.lastName
                                            )
                                          ) : userList.User.lastName ===
                                            "null" ? (
                                            <span></span>
                                          ) : (
                                            userList.User.lastName
                                          )}
                                        </span>
                                        <span>
                                          {userList.Family_User ? (
                                            userList.Family_User.User.email ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.Family_User.User.email
                                            )
                                          ) : userList.User.email === "null" ? (
                                            <span></span>
                                          ) : (
                                            userList.User.email
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                    <div>
                                      {userList.inviteStatus ===
                                      "Acceptance Pending"
                                        ? "Acceptance Pending"
                                        : ""}
                                    </div>

                                    <div>
                                      <div>
                                        {userList.inviteStatus !==
                                        "Acceptance Pending" ? (
                                          <span key={index}>
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowSupportUserDetailShow(
                                                      userList.Family_User,
                                                      userList
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList.Family_User,
                                                      "Remove Support"
                                                    )
                                                  }
                                                >
                                                  Remove User
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList.Family_User,
                                                      "Remove this user as Support"
                                                    )
                                                  }
                                                >
                                                  Remove this user as SU
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </span>
                                        ) : (
                                          <Dropdown className="profile-dropdwn">
                                            <Dropdown.Toggle
                                              variant=""
                                              className="m-0 inviteTxt user-dropdown"
                                              id=""
                                            >
                                              <Image
                                                className="m-1 vertical-dot-icon"
                                                src={VerticalDots}
                                                alt="Vertical Dots"
                                                View
                                                Details
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleShowSupportUserDetailShow(
                                                    userList,
                                                    userList
                                                  )
                                                }
                                              >
                                                View Details
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleShowRemoveSuUserShow(
                                                    userList,
                                                    "Revoke Support"
                                                  )
                                                }
                                              >
                                                Revoke Invite
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          ))
                        ) : (
                          <div className="new-Txt-Chng">
                            No Users available. Start adding by using the button
                            above
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="position-relative py-2 ">
                      <div className="position-absolute py-1 px-4 dashboard-header">
                        {planDetails.availableS === 1 ? (
                          <span> User your Supporting</span>
                        ) : (
                          <span> Users your Supporting </span>
                        )}
                      </div>
                      <div className="dashboard-details my-2  p-4 dashboard-subheader">
                        {remainingAuSeatCount <= planDetails.availableS &&
                        remainingAuSeatCount !== 0 ? (
                          <span
                            className="manageuser-btn"
                            onClick={handleShowInviteAuShow}
                            style={{ visibility: "hidden" }}
                          >
                            Add Main User
                          </span>
                        ) : (
                          ""
                        )}
                        {newAr.length > 0 ? (
                          newAr.map((userDetails, index) =>
                            familyUsersList.map(
                              (userList, index) =>
                                userList.User.email === userDetails.auEmail &&
                                userDetails.suIdEmail === UserDetails.email && (
                                  <span key={index}>
                                    <div className="PU-dsn">
                                      <div className="PU-dsn-sub d-flex justify-content-between align-items-center">
                                        <div className="float-left mx-2">
                                          <img
                                            src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                            alt="Profile Avatar"
                                            width="50px"
                                            height="50px"
                                          />
                                        </div>
                                        <div className="flex-2 word-break">
                                          <div className="main-dsgn-text">
                                            <div className="PU-dsn-txt">
                                              <span>
                                                {userList.User.firstName ===
                                                "null"
                                                  ? ""
                                                  : userList.User
                                                      .firstName}{" "}
                                                {userList.User.lastName ===
                                                "null"
                                                  ? ""
                                                  : userList.User.lastName}
                                              </span>
                                              <span>
                                                {userList.User.firstName !==
                                                "null" ? (
                                                  <div className="PU-dsn-txt-1">
                                                    {userList.User.email}
                                                  </div>
                                                ) : (
                                                  <div className="PU-dsn-txt-1">
                                                    {userList.User.email}
                                                  </div>
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="text-dsgn ms-auto d-flex justify-content-end
                                 align-items-center "
                                        >
                                          <div className="">
                                            <Link
                                              to={`/supporting_user_dashboard/${uuid}/${userList.User.uuid}`}
                                            >
                                              <div className="login-btn-famdash">
                                                {/* <img
                                                  className="icon-user-1"
                                                  src={BlueArrow}
                                                  alt="Profile Avatar"
                                                  onClick={clickFn}
                                                /> */}
                                                <div
                                                  onClick={clickFn}
                                                  style={{ color: "white" }}
                                                >
                                                  Login
                                                </div>
                                              </div>
                                            </Link>
                                          </div>

                                          {/* <div>
                                      {userList.inviteStatus ===
                                      "Acceptance Pending"
                                        ? "Acceptance Pending"
                                        : ""}
                                    </div> */}

                                          <div>
                                            <div>
                                              {userList.inviteStatus ===
                                                "Onboarded" &&
                                              userList.isAo !== true ? (
                                                newArVal.includes(
                                                  userList.User.email
                                                ) === true ? (
                                                  <span
                                                    key={index}
                                                    className="ggg"
                                                  >
                                                    <Dropdown className="profile-dropdwn">
                                                      <Dropdown.Toggle
                                                        variant=""
                                                        className="m-0 inviteTxt user-dropdown"
                                                        id=""
                                                      >
                                                        <Image
                                                          className="m-1 vertical-dot-icon"
                                                          src={VerticalDots}
                                                          alt="Vertical Dots"
                                                        />
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleShowRemoveAuUserShow(
                                                              userList,
                                                              "Remove Main"
                                                            )
                                                          }
                                                        >
                                                          Remove User
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleShowRemoveAuUserShow(
                                                              userList,
                                                              "Stop Supporting this"
                                                            )
                                                          }
                                                        >
                                                          Stop Supporting this
                                                          user
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </span>
                                                ) : (
                                                  <span key={index}>
                                                    <Dropdown className="profile-dropdwn">
                                                      <Dropdown.Toggle
                                                        variant=""
                                                        className="m-0 inviteTxt user-dropdown"
                                                        id=""
                                                      >
                                                        <Image
                                                          className="m-1 vertical-dot-icon"
                                                          src={VerticalDots}
                                                          alt="Vertical Dots"
                                                        />
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleShowRemoveAuUserShow(
                                                              userList,
                                                              "Remove Main"
                                                            )
                                                          }
                                                        >
                                                          Remove User
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            assignMyselfAOAsSU(
                                                              userList.id
                                                            )
                                                          }
                                                        >
                                                          Assign myself as SU
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </span>
                                                  // <span key={index} className="hhhh">
                                                  //   "rrrrrrrrr"
                                                  // </span>
                                                )
                                              ) : userList.inviteStatus ===
                                                  "Onboarded" &&
                                                userList.isAo === true ? (
                                                <span
                                                  key={index}
                                                  className="ggg"
                                                >
                                                  <Dropdown className="profile-dropdwn">
                                                    <Dropdown.Toggle
                                                      variant=""
                                                      className="m-0 inviteTxt user-dropdown"
                                                      id=""
                                                    >
                                                      <Image
                                                        className="m-1 vertical-dot-icon"
                                                        src={VerticalDots}
                                                        alt="Vertical Dots"
                                                      />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveAuUserShow(
                                                            userList,
                                                            "Remove Main"
                                                          )
                                                        }
                                                      >
                                                        Remove User
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </span>
                                              ) : (
                                                <span key={index}>
                                                  <Dropdown className="profile-dropdwn">
                                                    <Dropdown.Toggle
                                                      variant=""
                                                      className="m-0 inviteTxt user-dropdown"
                                                      id=""
                                                    >
                                                      <Image
                                                        className="m-1 vertical-dot-icon"
                                                        src={VerticalDots}
                                                        alt="Vertical Dots"
                                                      />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveAuUserShow(
                                                            userList,
                                                            "Revoke Main"
                                                          )
                                                        }
                                                      >
                                                        Revoke Invite
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                )
                            )
                          )
                        ) : (
                          <div className="new-Txt-Chng">
                            No Users available.
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </>
              </Row>
            ) : userType === "AO + AU" ? (
              <Row>
                <>
                  {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS == 1 ? (
                              <span> Main User </span>
                            ) : (
                              <span> Main Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedAuSeatCount} /
                            {planDetails.availableS == 1 ? (
                              <span>
                                {" "}
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {" "}
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        {remainingAuSeatCount <= planDetails.availableS &&
                        remainingAuSeatCount != 0 ? (
                          <span
                            className="manageuser-btn"
                            onClick={handleShowInviteAuShow}
                          >
                            Add Main User
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {familyUsersList.map((userList, index) =>
                        userList.isAu === true &&
                        userList.inviteStatus !== "Removed" &&
                        userList.inviteStatus !== "Cancelled" ? (
                          <span key={index}>
                            <div className="PU-dsn">
                              <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                <div className="float-left mx-2">
                                  <img
                                    src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                    alt="Profile Avatar"
                                    width="50px"
                                    height="50px"
                                  />
                                </div>
                                <div className="flex-2 word-break">
                                  <div className="main-dsgn-text">
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                    <div>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                  <div>
                                    {userList.inviteStatus ==
                                    "Acceptance Pending"
                                      ? "Acceptance Pending"
                                      : ""}
                                  </div>
                                  <div>
                                    {UserDetails.id === userList.User.id ||
                                    userList.User.id ? (
                                      <Dropdown className="profile-dropdwn">
                                        <Dropdown.Toggle
                                          variant=""
                                          className="m-0 inviteTxt user-dropdown"
                                          id=""
                                        >
                                          <Image
                                            className="m-1 vertical-dot-icon"
                                            src={VerticalDots}
                                            alt="Vertical Dots"
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {userList.inviteStatus ==
                                          "Acceptance Pending" ? (
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveAuUserShow(
                                                  userList,
                                                  "Revoke Main"
                                                )
                                              }
                                            >
                                              Revoke Invite
                                            </Dropdown.Item>
                                          ) : (
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveAuUserShow(
                                                  userList,
                                                  "Remove Main"
                                                )
                                              }
                                            >
                                              Remove User
                                            </Dropdown.Item>
                                          )}

                                          {userList.inviteStatus !==
                                            "Acceptance Pending" &&
                                          userList.isAo !== true &&
                                          occupiedSuSeatCount <
                                            planDetails.availableS ? (
                                            <>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  assignMyselfAOAsSU(
                                                    userList.id
                                                  )
                                                }
                                              >
                                                Assign myself as SU
                                              </Dropdown.Item>
                                              
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        ) : (
                          ""
                        )
                      )}
                      <div className="fhs-vh">
                        <p
                          className="mt-3 mb-2"
                          onClick={() =>
                            history.push({
                              pathname: `/family_seat_history/${uuid}`,
                              query: { tab1: "primary_users" },
                            })
                          }
                          style={{
                            color: "#0d6efd",
                            "font-weight": "500",
                            "text-decoration": "underline",
                            cursor: "pointer",
                          }}
                        >
                          Seat history
                        </p>
                        <p className="mt-3 mb-2">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12893"
                              data-name="Group 12893"
                              transform="translate(-356.4 -788.741)"
                            >
                              <path
                                id="Path_16599"
                                data-name="Path 16599"
                                d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                transform="translate(148.221 788.741)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16600"
                                data-name="Path 16600"
                                d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                transform="translate(176.371 674.265)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16603"
                                data-name="Path 16603"
                                d="M12.729,28.757,8.9,25.713a.613.613,0,0,0-.988.474v1.018a.572.572,0,0,1-.544.613H4.593A.572.572,0,0,0,4,28.392v1.6a.6.6,0,0,0,.593.593H7.372a.546.546,0,0,1,.544.583v1.018a.607.607,0,0,0,.979.465l3.815-2.965A.579.579,0,0,0,12.729,28.757Z"
                                transform="translate(364.296 763.156)"
                                fill="#007ef2"
                              />
                            </g>
                          </svg>
                          User you're supporting
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                  {/* <Col lg="6">
                    <Card className={`story-card seat-card basic-cards`}>
                      <Card.Body className="p-1">
                        <div className="su-newstyle">
                          <div>
                            <h3 className="invite-card-heading">
                              {planDetails.availableS == 1 ? (
                                <span>Support User </span>
                              ) : (
                                <span>Support Users </span>
                              )}
                            </h3>
                            <p className="">
                              {occupiedSuSeatCount} /{" "}
                              {planDetails.availableS == 1 ? (
                                <span>
                                  {planDetails.availableS} user is occupied
                                </span>
                              ) : (
                                <span>
                                  {planDetails.availableS} users are occupied
                                </span>
                              )}
                            </p>
                          </div>
                          <div>
                            {familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === false ? (
                              ""
                            ) : familyDetails.FamilyData.isAo === true &&
                              familyDetails.FamilyData.isAu === true &&
                              familyDetails.FamilyData.isSu === false ? (
                              occupiedSuSeatCount < planDetails.availableS &&
                              newSU.length === 0 ? (
                                <span
                                  className="manageuser-btn"
                                  onClick={handleShowInviteSuShow}
                                >
                                  Add Support User
                                </span>
                              ) : occupiedSuSeatCount >=
                                  planDetails.availableS &&
                                newSU.length === 0 ? (
                                ""
                              ) : (
                                <Accordion>
                                  <Accordion.Item
                                    style={{ width: "14rem" }}
                                    eventKey="0"
                                  >
                                    <Accordion.Header>
                                      Add Support User
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        {newSU.length > 0 ? (
                                          <li onClick={handleShowChooseSuShow}>
                                            Choose existing Support Users
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {occupiedSuSeatCount >
                                          planDetails.availableS ||
                                        occupiedSuSeatCount ==
                                          planDetails.availableS ? (
                                          " "
                                        ) : (
                                          <li onClick={handleShowInviteSuShow}>
                                            Invite a new Support User
                                          </li>
                                        )}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              )
                            ) : familyDetails.FamilyData.isAo === true &&
                              familyDetails.FamilyData.isAu === false &&
                              familyDetails.FamilyData.isSu === true ? (
                              ""
                            ) : familyDetails.FamilyData.isAo === true &&
                              familyDetails.FamilyData.isAu === true &&
                              familyDetails.FamilyData.isSu === true ? (
                              occupiedSuSeatCount < planDetails.availableS &&
                              newSU.length === 0 ? (
                                <span
                                  className="manageuser-btn"
                                  onClick={handleShowInviteSuShow}
                                >
                                  Add Support User
                                </span>
                              ) : occupiedSuSeatCount >=
                                  planDetails.availableS &&
                                newSU.length === 0 ? (
                                ""
                              ) : (
                                <Accordion>
                                  <Accordion.Item
                                    style={{ width: "14rem" }}
                                    eventKey="0"
                                  >
                                    <Accordion.Header>
                                      Add Support User
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        {newSU.length > 0 ? (
                                          <li onClick={handleShowChooseSuShow}>
                                            Choose existing Support Users
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {occupiedSuSeatCount >
                                          planDetails.availableS ||
                                        occupiedSuSeatCount ==
                                          planDetails.availableS ? (
                                          " "
                                        ) : (
                                          <li onClick={handleShowInviteSuShow}>
                                            Invite a new Support User
                                          </li>
                                        )}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              )
                            ) : familyDetails.FamilyData.isAo === false &&
                              familyDetails.FamilyData.isAu === true &&
                              familyDetails.FamilyData.isSu === true ? (
                              occupiedSuSeatCount < planDetails.availableS &&
                              newSU.length === 0 ? (
                                <span
                                  className="manageuser-btn"
                                  onClick={handleShowInviteSuShow}
                                >
                                  Add Support User
                                </span>
                              ) : occupiedSuSeatCount >=
                                  planDetails.availableS &&
                                newSU.length === 0 ? (
                                ""
                              ) : (
                                <Accordion>
                                  <Accordion.Item
                                    style={{ width: "14rem" }}
                                    eventKey="0"
                                  >
                                    <Accordion.Header>
                                      Add Support User
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        {newSU.length > 0 ? (
                                          <li onClick={handleShowChooseSuShow}>
                                            Choose existing Support Users
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {occupiedSuSeatCount >
                                          planDetails.availableS ||
                                        occupiedSuSeatCount ==
                                          planDetails.availableS ? (
                                          " "
                                        ) : (
                                          <li onClick={handleShowInviteSuShow}>
                                            Invite a new Support User
                                          </li>
                                        )}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              )
                            ) : familyDetails.FamilyData.isAo === false &&
                              familyDetails.FamilyData.isAu === true &&
                              familyDetails.FamilyData.isSu === false ? (
                              occupiedSuSeatCount < planDetails.availableS &&
                              newSU.length === 0 ? (
                                <span
                                  className="manageuser-btn"
                                  onClick={handleShowInviteSuShow}
                                >
                                  Add Support User
                                </span>
                              ) : occupiedSuSeatCount >=
                                  planDetails.availableS &&
                                newSU.length === 0 ? (
                                ""
                              ) : (
                                <Accordion>
                                  <Accordion.Item
                                    style={{ width: "14rem" }}
                                    eventKey="0"
                                  >
                                    <Accordion.Header>
                                      Add Support User
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        {newSU.length > 0 ? (
                                          <li onClick={handleShowChooseSuShow}>
                                            Choose existing Support Users
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {occupiedSuSeatCount >
                                          planDetails.availableS ||
                                        occupiedSuSeatCount ==
                                          planDetails.availableS ? (
                                          " "
                                        ) : (
                                          <li onClick={handleShowInviteSuShow}>
                                            Invite a new Support User
                                          </li>
                                        )}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              )
                            ) : familyDetails.FamilyData.isAo === false &&
                              familyDetails.FamilyData.isAu === false &&
                              familyDetails.FamilyData.isSu === true ? (
                              ""
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {occupiedSuSeatCount > 0 ? (
                          <div>
                            {familyUsersList.map((userList, index) =>
                              userList.isSu === true &&
                              userList.inviteStatus !== "Removed" &&
                              userList.inviteStatus !== "Cancelled" ? (
                                <span key={index}>
                                  <div className="PU-dsn">
                                    <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                      <div className="float-left mx-2">
                                        <img
                                          src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                          alt="Profile Avatar"
                                          width="50px"
                                          height="50px"
                                        />
                                      </div>
                                      <div className="flex-2 word-break">
                                        <div className="main-dsgn-text">
                                          <div className="PU-dsn-txt">
                                            <span>
                                              {userList.User.firstName ===
                                              "null" ? (
                                                <span></span>
                                              ) : (
                                                userList.User.firstName
                                              )}{" "}
                                              {userList.User.lastName ===
                                              "null" ? (
                                                <span></span>
                                              ) : (
                                                userList.User.lastName
                                              )}
                                            </span>
                                            <span>
                                              {userList.User.firstName !==
                                              "null" ? (
                                                <div className="PU-dsn-txt-1">
                                                  {userList.User.email}
                                                </div>
                                              ) : (
                                                <div className="PU-dsn-txt-1">
                                                  {userList.User.email}
                                                </div>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        {onBoardedUserListAU.length != 0
                                          ? onBoardedUserListAU.map(
                                              (userDetails, index) => {
                                                const status = userDetails
                                                  .supportingUserId.User
                                                  ? userDetails.supportingUserId
                                                      .inviteStatus
                                                  : userDetails.Family_User
                                                      .inviteStatus;
                                                return status == "Onboarded" ? (
                                                  <span key={index}>
                                                    {userDetails.Family_User
                                                      .User.id ===
                                                    userList.User.id ? (
                                                      <Image
                                                        className="icon-user"
                                                        src={Group_12891}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      " "
                                                    )}
                                                  </span>
                                                ) : (
                                                  " "
                                                );
                                              }
                                            )
                                          : ""}
                                      </div>
                                      <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                        <div>
                                          {userList.inviteStatus ==
                                          "Acceptance Pending"
                                            ? "Acceptance Pending"
                                            : ""}
                                        </div>

                                        <div>
                                          {userList.User.id ? (
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowSupportUserDetailShow(
                                                      userList.User,
                                                      userList
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Dropdown.Item>

                                                {userList.inviteStatus ==
                                                "Acceptance Pending" ? (
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleShowRemoveSuUserShow(
                                                        userList,
                                                        "Revoke Support"
                                                      )
                                                    }
                                                  >
                                                    Revoke Invite
                                                  </Dropdown.Item>
                                                ) : (
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleShowRemoveSuUserShow(
                                                        userList,
                                                        "Remove Support"
                                                      )
                                                    }
                                                  >
                                                    Remove User
                                                  </Dropdown.Item>
                                                )}

                                                {onBoardedUserListAU.length != 0
                                                  ? onBoardedUserListAU.map(
                                                      (userDetails, index) => {
                                                        const status = userDetails
                                                          .supportingUserId.User
                                                          ? userDetails
                                                              .supportingUserId
                                                              .inviteStatus
                                                          : userDetails
                                                              .Family_User
                                                              .inviteStatus;

                                                        return status ==
                                                          "Onboarded" ? (
                                                          <span key={index}>
                                                            {userDetails
                                                              .Family_User.User
                                                              .id ===
                                                            userList.User.id ? (
                                                              <Dropdown.Item
                                                                onClick={() =>
                                                                  handleShowRemoveSuUserShow(
                                                                    userDetails.Family_User,
                                                                    "Remove this user as Support"
                                                                  )
                                                                }
                                                              >
                                                                Remove this user
                                                                as SU
                                                              </Dropdown.Item>
                                                            ) : (
                                                              " "
                                                            )}
                                                          </span>
                                                        ) : (
                                                          " "
                                                        );
                                                      }
                                                    )
                                                  : ""}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              ) : (
                                ""
                              )
                            )}

                            <div className="fhs-vh-1">
                              <p
                                className="mt-3 mb-2 mr-4"
                                onClick={() =>
                                  history.push({
                                    pathname: `/family_seat_history/${uuid}`,
                                    query: { tab2: "support_users" },
                                  })
                                }
                                style={{
                                  color: "#0d6efd",
                                  "font-weight": "500",
                                  "text-decoration": "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Seat history
                              </p>
                              <p
                                className="mt-3 mb-2 "
                                style={{
                                  color: "#0d6efd",
                                  "font-weight": "500",
                                  "text-decoration": "underline",
                                  cursor: "pointer",
                                  "margin-right": "35%",
                                }}
                                onClick={() =>
                                  history.push({
                                    pathname: `/su_invite_history/${uuid}`,
                                  })
                                }
                              >
                                Invite history
                              </p>
                              <p className="mt-3 mb-2 ">
                                <svg
                                  className="mr-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20.849"
                                  height="13.074"
                                  viewBox="0 0 20.849 13.074"
                                >
                                  <g
                                    id="Group_12891"
                                    data-name="Group 12891"
                                    transform="translate(-351.4 -788.741)"
                                  >
                                    <g
                                      id="customer"
                                      transform="translate(351.4 788.741)"
                                    >
                                      <g
                                        id="Group_12873"
                                        data-name="Group 12873"
                                        transform="translate(1.85)"
                                      >
                                        <g
                                          id="Group_12872"
                                          data-name="Group 12872"
                                        >
                                          <path
                                            id="Path_16599"
                                            data-name="Path 16599"
                                            d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                            transform="translate(-210.029)"
                                            fill="#003a6f"
                                          />
                                        </g>
                                      </g>
                                      <g
                                        id="Group_12875"
                                        data-name="Group 12875"
                                        transform="translate(0 7.524)"
                                      >
                                        <g
                                          id="Group_12874"
                                          data-name="Group 12874"
                                        >
                                          <path
                                            id="Path_16600"
                                            data-name="Path 16600"
                                            d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                            transform="translate(-180.029 -122)"
                                            fill="#003a6f"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                    <g
                                      id="straight-right-arrow"
                                      transform="translate(363.296 788.741)"
                                    >
                                      <path
                                        id="Path_16603"
                                        data-name="Path 16603"
                                        d="M4.224,28.757l3.825-3.044a.613.613,0,0,1,.988.474v1.018a.572.572,0,0,0,.544.613H12.36a.572.572,0,0,1,.593.573v1.6a.6.6,0,0,1-.593.593H9.581a.546.546,0,0,0-.544.583v1.018a.607.607,0,0,1-.979.465L4.244,29.686A.579.579,0,0,1,4.224,28.757Z"
                                        transform="translate(-4 -25.585)"
                                        fill="#00bb96"
                                      />
                                    </g>
                                  </g>
                                </svg>
                                User supporting you
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="new-Txt-Chng">
                            No Users available. Start adding by using the button
                            above
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col> */}
                </>
                <>
                  <Col lg="6">
                    <div className="position-relative py-2 ">
                      <div className="position-absolute py-1 px-4 dashboard-header">
                        {planDetails.availableS === 1 ? (
                          <span>Your Support User </span>
                        ) : (
                          <span>Your Support Users </span>
                        )}
                      </div>
                      <div className="dashboard-details my-2  p-4 dashboard-subheader">
                        <div>
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}

                          {auusersu.length > 0 ? (
                            auusersu.map((userList, index) => (
                              <span key={index}>
                                <div className="PU-dsn">
                                  <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                    <div className="float-left mx-2">
                                      <img
                                        src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                        alt="Profile Avatar"
                                        width="50px"
                                        height="50px"
                                      />
                                    </div>
                                    <div className="flex-2 word-break">
                                      <div className="main-dsgn-text">
                                        <div className="PU-dsn-txt d-flex flex-column">
                                          <span>
                                            {userList.Family_User ? (
                                              userList.Family_User.User
                                                .firstName === "null" ? (
                                                <span></span>
                                              ) : (
                                                userList.Family_User.User
                                                  .firstName
                                              )
                                            ) : userList.User.firstName ===
                                              "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.firstName
                                            )}{" "}
                                            {userList.Family_User ? (
                                              userList.Family_User.User
                                                .lastName === "null" ? (
                                                <span></span>
                                              ) : (
                                                userList.Family_User.User
                                                  .lastName
                                              )
                                            ) : userList.User.lastName ===
                                              "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.lastName
                                            )}
                                          </span>
                                          <span>
                                            {userList.Family_User ? (
                                              userList.Family_User.User
                                                .email === "null" ? (
                                                <span></span>
                                              ) : (
                                                userList.Family_User.User.email
                                              )
                                            ) : userList.User.email ===
                                              "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.email
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div></div>
                                    <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                      <div>
                                        {userList.Family_User
                                          ? userList.Family_User
                                              .inviteStatus ===
                                            "Acceptance Pending"
                                            ? "Acceptance Pending"
                                            : ""
                                          : userList.inviteStatus ===
                                            "Acceptance Pending"
                                          ? "Acceptance Pending"
                                          : ""}
                                      </div>

                                      <div>
                                        {userList.Family_User ? (
                                          userList.Family_User.inviteStatus ===
                                          "Onboarded" ? (
                                            <span key={index}>
                                              <Dropdown className="profile-dropdwn">
                                                <Dropdown.Toggle
                                                  variant=""
                                                  className="m-0 inviteTxt user-dropdown"
                                                  id=""
                                                >
                                                  <Image
                                                    className="m-1 vertical-dot-icon"
                                                    src={VerticalDots}
                                                    alt="Vertical Dots"
                                                  />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleShowSupportUserDetailShow(
                                                        userList.Family_User,
                                                        userList
                                                      )
                                                    }
                                                  >
                                                    View Details
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleShowRemoveSuUserShow(
                                                        userList.Family_User,
                                                        "Remove Support"
                                                      )
                                                    }
                                                  >
                                                    Remove this user
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleShowRemoveSuUserShow(
                                                        userList.Family_User,
                                                        "Remove this user as Support"
                                                      )
                                                    }
                                                  >
                                                    Remove this user as SU
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </span>
                                          ) : (
                                            ""
                                          )
                                        ) : userList.inviteStatus ===
                                          "Acceptance Pending" ? (
                                          <span key={index}>
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList,
                                                      "Revoke Support"
                                                    )
                                                  }
                                                >
                                                  Revoke invite{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ))
                          ) : (
                            <div className="new-Txt-Chng">
                              No Users available. Start adding by using the
                              button above
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              </Row>
            ) : userType === "AO + SU" ? (
              <Row>
                <>
                  {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS === 1 ? (
                              <span> Main User </span>
                            ) : (
                              <span> Main Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedAuSeatCount} /
                            {planDetails.availableS === 1 ? (
                              <span>
                                {" "}
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {" "}
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        {remainingAuSeatCount <= planDetails.availableS &&
                        remainingAuSeatCount !== 0 ? (
                          <span
                            className="manageuser-btn"
                            onClick={handleShowInviteAuShow}
                          >
                            Add Main User
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {familyUsersList.map((userList, index) =>
                        userList.isAu === true &&
                        userList.inviteStatus !== "Removed" &&
                        userList.inviteStatus !== "Cancelled" ? (
                          <span key={index}>
                            <div className="PU-dsn">
                              <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                <div className="float-left mx-2">
                                  <img
                                    src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                    alt="Profile Avatar"
                                    width="50px"
                                    height="50px"
                                  />
                                </div>
                                <div className="flex-2 word-break">
                                  <div className="main-dsgn-text">
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="mmmmmmmmmmm">
                                  {newAr.length > 0 &&
                                    newAr.map((userDetails, index) =>
                                      userList.User.email ===
                                        userDetails.auEmail &&
                                      userDetails.suIdEmail ===
                                        UserDetails.email ? (
                                        <span key={index}>
                                          <Image
                                            className="icon-user"
                                            src={Group_12893}
                                            alt=""
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </div>

                                <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                  <div className="me-4">
                                    {newAr.length > 0 &&
                                      newAr.map((userDetails, index) =>
                                        userList.User.email ===
                                          userDetails.auEmail &&
                                        userDetails.suIdEmail ===
                                          UserDetails.email ? (
                                          <span key={index}>
                                            <Link
                                              to={`/supporting_user_dashboard/${uuid}/${userList.User.uuid}`}
                                            >
                                              <img
                                                className="icon-user-1"
                                                src={BlueArrow}
                                                alt="Profile Avatar"
                                                onClick={clickFn}
                                              />
                                            </Link>
                                          </span>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </div>

                                  {userList.inviteStatus ===
                                  "Acceptance Pending"
                                    ? "Acceptance Pending"
                                    : ""}
                                </div>
                                <div>
                                  <div>
                                    {userList.inviteStatus === "Onboarded" &&
                                    userList.isAo !== true ? (
                                      newArVal.includes(userList.User.email) ===
                                      true ? (
                                        <span key={index} className="ggg">
                                          <Dropdown className="profile-dropdwn">
                                            <Dropdown.Toggle
                                              variant=""
                                              className="m-0 inviteTxt user-dropdown"
                                              id=""
                                            >
                                              <Image
                                                className="m-1 vertical-dot-icon"
                                                src={VerticalDots}
                                                alt="Vertical Dots"
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleShowRemoveAuUserShow(
                                                    userList,
                                                    "Remove Main"
                                                  )
                                                }
                                              >
                                                Remove User
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleShowRemoveAuUserShow(
                                                    userList,
                                                    "Stop Supporting this"
                                                  )
                                                }
                                              >
                                                Stop Supporting this user
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </span>
                                      ) : (
                                        <span key={index}>
                                          <Dropdown className="profile-dropdwn">
                                            <Dropdown.Toggle
                                              variant=""
                                              className="m-0 inviteTxt user-dropdown"
                                              id=""
                                            >
                                              <Image
                                                className="m-1 vertical-dot-icon"
                                                src={VerticalDots}
                                                alt="Vertical Dots"
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleShowRemoveAuUserShow(
                                                    userList,
                                                    "Remove Main"
                                                  )
                                                }
                                              >
                                                Remove User
                                              </Dropdown.Item>

                                              <Dropdown.Item
                                                onClick={() =>
                                                  assignMyselfAOAsSU(
                                                    userList.id
                                                  )
                                                }
                                              >
                                                Assign myself as SU
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </span>
                                      )
                                    ) : userList.inviteStatus === "Onboarded" &&
                                      userList.isAo === true ? (
                                      <span key={index} className="ggg">
                                        <Dropdown className="profile-dropdwn">
                                          <Dropdown.Toggle
                                            variant=""
                                            className="m-0 inviteTxt user-dropdown"
                                            id=""
                                          >
                                            <Image
                                              className="m-1 vertical-dot-icon"
                                              src={VerticalDots}
                                              alt="Vertical Dots"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveAuUserShow(
                                                  userList,
                                                  "Remove Main"
                                                )
                                              }
                                            >
                                              Remove User
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </span>
                                    ) : (
                                      <span key={index}>
                                        <Dropdown className="profile-dropdwn">
                                          <Dropdown.Toggle
                                            variant=""
                                            className="m-0 inviteTxt user-dropdown"
                                            id=""
                                          >
                                            <Image
                                              className="m-1 vertical-dot-icon"
                                              src={VerticalDots}
                                              alt="Vertical Dots"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveAuUserShow(
                                                  userList,
                                                  "Revoke Main"
                                                )
                                              }
                                            >
                                              Revoke Invite
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        ) : (
                          ""
                        )
                      )}
                      <div className="fhs-vh">
                        <p
                          className="mt-3 mb-2"
                          onClick={() =>
                            history.push({
                              pathname: `/family_seat_history/${uuid}`,
                              query: { tab1: "primary_users" },
                            })
                          }
                          style={{
                            color: "#0d6efd",
                            "font-weight": "500",
                            "text-decoration": "underline",
                            cursor: "pointer",
                          }}
                        >
                          Seat history
                        </p>
                        <p className="mt-3 mb-2">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12893"
                              data-name="Group 12893"
                              transform="translate(-356.4 -788.741)"
                            >
                              <path
                                id="Path_16599"
                                data-name="Path 16599"
                                d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                transform="translate(148.221 788.741)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16600"
                                data-name="Path 16600"
                                d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                transform="translate(176.371 674.265)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16603"
                                data-name="Path 16603"
                                d="M12.729,28.757,8.9,25.713a.613.613,0,0,0-.988.474v1.018a.572.572,0,0,1-.544.613H4.593A.572.572,0,0,0,4,28.392v1.6a.6.6,0,0,0,.593.593H7.372a.546.546,0,0,1,.544.583v1.018a.607.607,0,0,0,.979.465l3.815-2.965A.579.579,0,0,0,12.729,28.757Z"
                                transform="translate(364.296 763.156)"
                                fill="#007ef2"
                              />
                            </g>
                          </svg>
                          User you're supporting
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS === 1 ? (
                              <span>Support User </span>
                            ) : (
                              <span>Support Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedSuSeatCount} /{" "}
                            {planDetails.availableS === 1 ? (
                              <span>
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        <div>
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {familyUsersList.map((userList, index) =>
                        userList.isSu === true &&
                        userList.inviteStatus !== "Removed" &&
                        userList.inviteStatus !== "Cancelled" ? (
                          <span key={index}>
                            <div className="PU-dsn">
                              <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                <div className="float-left mx-2">
                                  <img
                                    src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                    alt="Profile Avatar"
                                    width="50px"
                                    height="50px"
                                  />
                                </div>
                                <div className="flex-2 word-break">
                                  <div className="main-dsgn-text">
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                                <div
                                  className="text-dsgn ms-auto d-flex justify-content-end
                                 align-items-center "
                                >
                                  <div>
                                    {userList.inviteStatus ===
                                    "Acceptance Pending"
                                      ? "Acceptance Pending"
                                      : ""}
                                  </div>

                                  <div>
                                    {UserDetails.id === userList.User.id ||
                                    userList.User.id ? (
                                      <Dropdown className="profile-dropdwn">
                                        <Dropdown.Toggle
                                          variant=""
                                          className="m-0 inviteTxt user-dropdown"
                                          id=""
                                        >
                                          <Image
                                            className="m-1 vertical-dot-icon"
                                            src={VerticalDots}
                                            alt="Vertical Dots"
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleShowSupportUserDetailShow(
                                                userList.User,
                                                userList
                                              )
                                            }
                                          >
                                            View Details
                                          </Dropdown.Item>
                                          {userList.inviteStatus ===
                                          "Acceptance Pending" ? (
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveSuUserShow(
                                                  userList,
                                                  "Revoke Support"
                                                )
                                              }
                                            >
                                              Revoke Invite
                                            </Dropdown.Item>
                                          ) : (
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveSuUserShow(
                                                  userList,
                                                  "Remove Support"
                                                )
                                              }
                                            >
                                              Remove User
                                            </Dropdown.Item>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        ) : (
                          ""
                        )
                      )}
                      <div className="fhs-vh-1">
                        <p
                          className="mt-3 mb-2 mr-4"
                          onClick={() =>
                            history.push({
                              pathname: `/family_seat_history/${uuid}`,
                              query: { tab2: "support_users" },
                            })
                          }
                          style={{
                            color: "#0d6efd",
                            "font-weight": "500",
                            "text-decoration": "underline",
                            cursor: "pointer",
                          }}
                        >
                          Seat history
                        </p>
                        <p className="mt-3 mb-2 ">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12891"
                              data-name="Group 12891"
                              transform="translate(-351.4 -788.741)"
                            >
                              <g
                                id="customer"
                                transform="translate(351.4 788.741)"
                              >
                                <g
                                  id="Group_12873"
                                  data-name="Group 12873"
                                  transform="translate(1.85)"
                                >
                                  <g id="Group_12872" data-name="Group 12872">
                                    <path
                                      id="Path_16599"
                                      data-name="Path 16599"
                                      d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                      transform="translate(-210.029)"
                                      fill="#003a6f"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_12875"
                                  data-name="Group 12875"
                                  transform="translate(0 7.524)"
                                >
                                  <g id="Group_12874" data-name="Group 12874">
                                    <path
                                      id="Path_16600"
                                      data-name="Path 16600"
                                      d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                      transform="translate(-180.029 -122)"
                                      fill="#003a6f"
                                    />
                                  </g>
                                </g>
                              </g>
                              <g
                                id="straight-right-arrow"
                                transform="translate(363.296 788.741)"
                              >
                                <path
                                  id="Path_16603"
                                  data-name="Path 16603"
                                  d="M4.224,28.757l3.825-3.044a.613.613,0,0,1,.988.474v1.018a.572.572,0,0,0,.544.613H12.36a.572.572,0,0,1,.593.573v1.6a.6.6,0,0,1-.593.593H9.581a.546.546,0,0,0-.544.583v1.018a.607.607,0,0,1-.979.465L4.244,29.686A.579.579,0,0,1,4.224,28.757Z"
                                  transform="translate(-4 -25.585)"
                                  fill="#00bb96"
                                />
                              </g>
                            </g>
                          </svg>
                          User supporting you
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                </>
                <>
                  <Col xxl="6" xl="6" lg="6" md="8" className="mt-3">
                    <Card
                      className={`story-card seat-card basic-cards famdash-border`}
                    >
                      <Card.Body className="p-1">
                        <div className="">
                          {planDetails.availableS === 1 ? (
                            <span> User you are supporting</span>
                          ) : (
                            <span> Users you are supporting</span>
                          )}
                        </div>
                        {console.log("auusersu:::::::::::", auusersu)}
                        {console.log("familyUsersList:::::", familyUsersList)}
                        {console.log("newAr:::::", newAr)}

                        {newAr.length > 0 ? (
                          newAr.map((userDetails, index) =>
                            familyUsersList.map(
                              (userList, index) =>
                                userList.User.email === userDetails.auEmail &&
                                userDetails.suIdEmail === UserDetails.email && (
                                  <span key={index}>
                                    <div className="PU-dsn">
                                      <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                        <div className="float-left mx-2">
                                          <img
                                            src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                            alt="Profile Avatar"
                                            width="50px"
                                            height="50px"
                                          />
                                        </div>
                                        <div className="flex-2 word-break">
                                          <div className="main-dsgn-text">
                                            <div className="PU-dsn-txt">
                                              <span>
                                                {userList.User.firstName ===
                                                "null" ? (
                                                  <span></span>
                                                ) : (
                                                  userList.User.firstName
                                                )}{" "}
                                                {userList.User.lastName ===
                                                "null" ? (
                                                  <span></span>
                                                ) : (
                                                  userList.User.lastName
                                                )}
                                              </span>
                                              <span>
                                                {userList.User.firstName !==
                                                "null" ? (
                                                  <div className="PU-dsn-txt-1">
                                                    {userList.User.email}
                                                  </div>
                                                ) : (
                                                  <div className="PU-dsn-txt-1">
                                                    {userList.User.email}
                                                  </div>
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="mmmmmmmmmmm">
                                          {newAr.length > 0 &&
                                            newAr.map((userDetails, index) =>
                                              userList.User.email ===
                                                userDetails.auEmail &&
                                              userDetails.suIdEmail ===
                                                UserDetails.email ? (
                                                <span key={index}>
                                                  <Image
                                                    className="icon-user"
                                                    src={Group_12893}
                                                    alt=""
                                                  />
                                                </span>
                                              ) : (
                                                ""
                                              )
                                            )}
                                        </div> */}

                                        <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                          <div className="">
                                            <Link
                                              to={`/supporting_user_dashboard/${uuid}/${userList.User.uuid}`}
                                            >
                                              <div className="famDash-loginSupp">
                                                Login
                                              </div>
                                            </Link>
                                          </div>
                                        </div>
                                        <div>
                                          <div>
                                            {userList.inviteStatus ===
                                              "Onboarded" &&
                                            userList.isAo !== true ? (
                                              newArVal.includes(
                                                userList.User.email
                                              ) === true ? (
                                                <span
                                                  key={index}
                                                  className="ggg"
                                                >
                                                  <Dropdown className="profile-dropdwn">
                                                    <Dropdown.Toggle
                                                      variant=""
                                                      className="m-0 inviteTxt user-dropdown"
                                                      id=""
                                                    >
                                                      <Image
                                                        className="m-1 vertical-dot-icon"
                                                        src={VerticalDots}
                                                        alt="Vertical Dots"
                                                      />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveAuUserShow(
                                                            userList,
                                                            "Remove Main"
                                                          )
                                                        }
                                                      >
                                                        Remove User
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveAuUserShow(
                                                            userList,
                                                            "Stop Supporting this"
                                                          )
                                                        }
                                                      >
                                                        Stop Supporting this
                                                        user
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </span>
                                              ) : (
                                                <span key={index}>
                                                  <Dropdown className="profile-dropdwn">
                                                    <Dropdown.Toggle
                                                      variant=""
                                                      className="m-0 inviteTxt user-dropdown"
                                                      id=""
                                                    >
                                                      <Image
                                                        className="m-1 vertical-dot-icon"
                                                        src={VerticalDots}
                                                        alt="Vertical Dots"
                                                      />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveAuUserShow(
                                                            userList,
                                                            "Remove Main"
                                                          )
                                                        }
                                                      >
                                                        Remove User
                                                      </Dropdown.Item>

                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          assignMyselfAOAsSU(
                                                            userList.id
                                                          )
                                                        }
                                                      >
                                                        Assign myself as SU
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </span>
                                              )
                                            ) : userList.inviteStatus ===
                                                "Onboarded" &&
                                              userList.isAo === true ? (
                                              <span key={index} className="ggg">
                                                <Dropdown className="profile-dropdwn">
                                                  <Dropdown.Toggle
                                                    variant=""
                                                    className="m-0 inviteTxt user-dropdown"
                                                    id=""
                                                  >
                                                    <Image
                                                      className="m-1 vertical-dot-icon"
                                                      src={VerticalDots}
                                                      alt="Vertical Dots"
                                                    />
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handleShowRemoveAuUserShow(
                                                          userList,
                                                          "Remove Main"
                                                        )
                                                      }
                                                    >
                                                      Remove User
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </span>
                                            ) : (
                                              <span key={index}>
                                                <Dropdown className="profile-dropdwn">
                                                  <Dropdown.Toggle
                                                    variant=""
                                                    className="m-0 inviteTxt user-dropdown"
                                                    id=""
                                                  >
                                                    <Image
                                                      className="m-1 vertical-dot-icon"
                                                      src={VerticalDots}
                                                      alt="Vertical Dots"
                                                    />
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handleShowRemoveAuUserShow(
                                                          userList,
                                                          "Revoke Main"
                                                        )
                                                      }
                                                    >
                                                      Revoke Invite
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                )
                            )
                          )
                        ) : (
                          <div className="new-Txt-Chng">
                            No Users available.
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              </Row>
            ) : userType === "AO" ? (
              <Row>
                <Col xl="6" lg="6" md="12">
                  <>
                    {/* old code */}
                    {/* <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS == 1 ? (
                              <span> Main User </span>
                            ) : (
                              <span> Main Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedAuSeatCount} /
                            {planDetails.availableS == 1 ? (
                              <span>
                                {" "}
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {" "}
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        {remainingAuSeatCount <= planDetails.availableS &&
                        remainingAuSeatCount !== 0 ? (
                          <span
                            className="manageuser-btn"
                            onClick={handleShowInviteAuShow}
                          >
                            Add Main User
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {occupiedAuSeatCount > 0 ? (
                        <div>
                          {familyUsersList.map((userList, index) =>
                            userList.isAu === true &&
                            userList.inviteStatus !== "Removed" &&
                            userList.inviteStatus !== "Cancelled" ? (
                              <span key={index}>
                                <div className="PU-dsn">
                                  <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                    <div className="float-left mx-2">
                                      <img
                                        src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                        alt="Profile Avatar"
                                        width="50px"
                                        height="50px"
                                      />
                                    </div>
                                    <div className="flex-2 word-break">
                                      <div className="main-dsgn-text">
                                        <div className="PU-dsn-txt">
                                          <span>
                                            {userList.User.firstName ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.firstName
                                            )}{" "}
                                            {userList.User.lastName ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.lastName
                                            )}
                                          </span>
                                          <span>
                                            {userList.User.firstName !==
                                            "null" ? (
                                              <div className="PU-dsn-txt-1">
                                                {userList.User.email}
                                              </div>
                                            ) : (
                                              <div className="PU-dsn-txt-1">
                                                {userList.User.email}
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                        <div>
                                          {onBoardedUserListSU.length != 0
                                            ? onBoardedUserListSU.map(
                                                (userDetails, index) => {
                                                  const status = userDetails
                                                    .supportingUserId.User
                                                    ? userDetails
                                                        .supportingUserId
                                                        .inviteStatus
                                                    : userDetails.Family_User
                                                        .inviteStatus;
                                                  return status ==
                                                    "Onboarded" ? (
                                                    <span key={index}>
                                                      {userList.User.email ===
                                                      userDetails
                                                        .supportingUserId.User
                                                        .email ? (
                                                        <Image
                                                          className="icon-user"
                                                          src={Group_12893}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  ) : (
                                                    " "
                                                  );
                                                }
                                              )
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                      <div>
                                        {userList.inviteStatus ==
                                        "Acceptance Pending"
                                          ? "Acceptance Pending"
                                          : ""}
                                      </div>

                                      <div>
                                        <div className="me-4">
                                          {onBoardedUserListSU.length != 0
                                            ? onBoardedUserListSU.map(
                                                (userDetails, index) => {
                                                  const status = userDetails
                                                    .supportingUserId.User
                                                    ? userDetails
                                                        .supportingUserId
                                                        .inviteStatus
                                                    : userDetails.Family_User
                                                        .inviteStatus;
                                                  return status ==
                                                    "Onboarded" ? (
                                                    <span key={index}>
                                                      {userList.User.email ===
                                                      userDetails
                                                        .supportingUserId.User
                                                        .email ? (
                                                        <Link
                                                          to={`/supporting_user_dashboard/${uuid}/${userDetails.supportingUserId.User.uuid}`}
                                                        >
                                                          <img
                                                            className="icon-user-1"
                                                            src={BlueArrow}
                                                            alt="Profile Avatar"
                                                            onClick={clickFn}
                                                          />
                                                        </Link>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  ) : (
                                                    " "
                                                  );
                                                }
                                              )
                                            : ""}
                                        </div>
                                        <div>
                                          {onBoardedUserListSU.length != 0 ? (
                                            onBoardedUserListSU.map(
                                              (userDetails, index) => {
                                                const status = userDetails
                                                  .supportingUserId.User
                                                  ? userDetails.supportingUserId
                                                      .inviteStatus
                                                  : userDetails.Family_User
                                                      .inviteStatus;
                                                return status == "Onboarded" ? (
                                                  <span key={index}>
                                                    {userList.inviteStatus !==
                                                      "Removed" &&
                                                    userList.inviteStatus !==
                                                      "Cancelled" ? (
                                                      <Dropdown className="profile-dropdwn">
                                                        <Dropdown.Toggle
                                                          variant=""
                                                          className="m-0 inviteTxt user-dropdown"
                                                          id=""
                                                        >
                                                          <Image
                                                            className="m-1 vertical-dot-icon"
                                                            src={VerticalDots}
                                                            alt="Vertical Dots"
                                                          />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                          {userList.inviteStatus ==
                                                          "Acceptance Pending" ? (
                                                            <Dropdown.Item
                                                              onClick={() =>
                                                                handleShowRemoveAuUserShow(
                                                                  userList,
                                                                  "Revoke Main"
                                                                )
                                                              }
                                                            >
                                                              Revoke Invite
                                                            </Dropdown.Item>
                                                          ) : (
                                                            <Dropdown.Item
                                                              onClick={() =>
                                                                handleShowRemoveAuUserShow(
                                                                  userList,
                                                                  "Remove Main"
                                                                )
                                                              }
                                                            >
                                                              Remove User
                                                            </Dropdown.Item>
                                                          )}

                                                          {userList.inviteStatus !==
                                                            "Acceptance Pending" &&
                                                          userList.isAo !==
                                                            true &&
                                                          occupiedSuSeatCount <
                                                            planDetails.availableS ? (
                                                            <Dropdown.Item
                                                              onClick={() =>
                                                                assignMyselfAOAsSU(
                                                                  userList.id
                                                                )
                                                              }
                                                            >
                                                              Assign myself as
                                                              SU
                                                            </Dropdown.Item>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                ) : (
                                                  " "
                                                );
                                              }
                                            )
                                          ) : userList.inviteStatus !==
                                              "Removed" &&
                                            userList.inviteStatus !==
                                              "Cancelled" ? (
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                {userList.inviteStatus ==
                                                "Acceptance Pending" ? (
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleShowRemoveAuUserShow(
                                                        userList,
                                                        "Revoke Main"
                                                      )
                                                    }
                                                  >
                                                    Revoke Invite
                                                  </Dropdown.Item>
                                                ) : (
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleShowRemoveAuUserShow(
                                                        userList,
                                                        "Remove Main"
                                                      )
                                                    }
                                                  >
                                                    Remove User
                                                  </Dropdown.Item>
                                                )}

                                                {userList.inviteStatus !==
                                                  "Acceptance Pending" &&
                                                userList.isAo !== true &&
                                                occupiedSuSeatCount <
                                                  planDetails.availableS ? (
                                                  <>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        assignMyselfAOAsSU(
                                                          userList.id
                                                        )
                                                      }
                                                    >
                                                      Assign myself as SU
                                                    </Dropdown.Item>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              ""
                            )
                          )}

                          <div className="fhs-vh">
                            <p
                              className="mt-3 mb-2"
                              onClick={() =>
                                history.push({
                                  pathname: `/family_seat_history/${uuid}`,
                                  query: { tab1: "primary_users" },
                                })
                              }
                              style={{
                                color: "#0d6efd",
                                "font-weight": "500",
                                "text-decoration": "underline",
                                cursor: "pointer",
                              }}
                            >
                              Seat history
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="new-Txt-Chng">
                          No Users available. Start adding by using the button
                          above
                        </div>
                      )}
                    </Card.Body>
                  </Card> */}
                  </>
                  {/* new code */}
                  <div className="position-relative py-2 ">
                    <div className="position-absolute py-1 px-4 dashboard-header">
                      {planDetails.availableS === 1 ? (
                        <span> Main User </span>
                      ) : (
                        <span> Main Users </span>
                      )}
                    </div>
                    <div className="dashboard-details my-2  p-4 dashboard-subheader">
                      <div className="d-flex align-items-center">
                        {occupiedAuSeatCount} /
                        {planDetails.availableS === 1 ? (
                          <span>
                            {" "}
                            {planDetails.availableS} user is occupied
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {planDetails.availableS} users are occupied
                          </span>
                        )}
                        {remainingAuSeatCount <= planDetails.availableS &&
                        remainingAuSeatCount !== 0 ? (
                          <div
                            onClick={handleShowInviteAuShow}
                            className="d-flex justify-content-end fam-dash-box"
                          >
                            Add Main User
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {occupiedAuSeatCount > 0 ? (
                        <div>
                          {familyUsersList.map((userList, index) =>
                            userList.isAu === true &&
                            userList.inviteStatus !== "Removed" &&
                            userList.inviteStatus !== "Cancelled" ? (
                              <span key={index}>
                                <div className="PU-dsn">
                                  <div className="PU-dsn-sub">
                                    <div className=" mx-2">
                                      <img
                                        src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                        alt="Profile Avatar"
                                        style={{
                                          width: "2.5rem",
                                          height: "2.5rem",
                                        }}
                                      />
                                    </div>
                                    <div className="flex-2 word-break">
                                      <div>
                                        <div className="PU-dsn-txt">
                                          <span>
                                            {userList.User.firstName ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.firstName
                                            )}{" "}
                                            {userList.User.lastName ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.lastName
                                            )}
                                          </span>
                                          <span>
                                            {userList.User.firstName !==
                                            "null" ? (
                                              <div className="PU-dsn-txt-1">
                                                {userList.User.email}
                                              </div>
                                            ) : (
                                              <div className="PU-dsn-txt-1">
                                                {userList.User.email}
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                        {/* <div>
                                          {onBoardedUserListSU.length != 0
                                            ? onBoardedUserListSU.map(
                                                (userDetails, index) => {
                                                  const status = userDetails
                                                    .supportingUserId.User
                                                    ? userDetails
                                                        .supportingUserId
                                                        .inviteStatus
                                                    : userDetails.Family_User
                                                        .inviteStatus;
                                                  return status ==
                                                    "Onboarded" ? (
                                                    <span key={index}>
                                                      {userList.User.email ===
                                                      userDetails
                                                        .supportingUserId.User
                                                        .email ? (
                                                        <Image
                                                          className="icon-user"
                                                          src={Group_12893}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  ) : (
                                                    " "
                                                  );
                                                }
                                              )
                                            : ""}
                                        </div> */}
                                      </div>
                                    </div>
                                    <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                      <div>
                                        {userList.inviteStatus ===
                                        "Acceptance Pending"
                                          ? userList.inviteStatus
                                          : ""}
                                      </div>

                                      <div>
                                        {/*  <div className="me-4">
                                         {onBoardedUserListSU.length != 0
                                            ? onBoardedUserListSU.map(
                                                (userDetails, index) => {
                                                  const status = userDetails
                                                    .supportingUserId.User
                                                    ? userDetails
                                                        .supportingUserId
                                                        .inviteStatus
                                                    : userDetails.Family_User
                                                        .inviteStatus;
                                                  return status ==
                                                    "Onboarded" ? (
                                                    <span key={index}>
                                                      {userList.User.email ===
                                                      userDetails
                                                        .supportingUserId.User
                                                        .email ? (
                                                        <Link
                                                          to={`/supporting_user_dashboard/${uuid}/${userDetails.supportingUserId.User.uuid}`}
                                                        >
                                                          <img
                                                            className="icon-user-1"
                                                            src={BlueArrow}
                                                            alt="Profile Avatar"
                                                            onClick={clickFn}
                                                          />
                                                        </Link>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  ) : (
                                                    " "
                                                  );
                                                }
                                              )
                                            : ""} 
                                        </div>*/}
                                        <div className="famDash-editDlt">
                                          {userList.inviteStatus !==
                                            "Acceptance Pending" && (
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                                style={{ paddingLeft: "0" }}
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={EditIcon}
                                                  alt="EditIcon"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    mainUserDtls(userList)
                                                  }
                                                >
                                                  Edit user
                                                </Dropdown.Item>
                                                {userList.inviteStatus !==
                                                  "Acceptance Pending" &&
                                                userList.isAo !== true &&
                                                occupiedSuSeatCount <
                                                  planDetails.availableS ? (
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      assignMyselfAOAsSU(
                                                        userList.id
                                                      )
                                                    }
                                                  >
                                                    Assign myself as SU
                                                  </Dropdown.Item>
                                                ) : (
                                                  ""
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          )}

                                          {/* <img
                                            src={EditIcon}
                                            alt="EditIcon"
                                            onClick={() =>
                                              mainUserDtls(userList)
                                            }
                                            style={{ cursor: "pointer" }}
                                          /> */}
                                          <div>
                                            {onBoardedUserListSU.length !==
                                            0 ? (
                                              onBoardedUserListSU.map(
                                                (userDetails, index) => {
                                                  const status = userDetails
                                                    .supportingUserId.User
                                                    ? userDetails
                                                        .supportingUserId
                                                        .inviteStatus
                                                    : userDetails.Family_User
                                                        .inviteStatus;
                                                  return status ===
                                                    "Onboarded" ? (
                                                    <span key={index}>
                                                      {userList.inviteStatus !==
                                                        "Removed" &&
                                                      userList.inviteStatus !==
                                                        "Cancelled" ? (
                                                        <Dropdown className="profile-dropdwn">
                                                          <Dropdown.Toggle
                                                            variant=""
                                                            className="m-0 inviteTxt user-dropdown"
                                                            id=""
                                                            style={{
                                                              paddingLeft: "0",
                                                            }}
                                                          >
                                                            <Image
                                                              className="m-1 vertical-dot-icon"
                                                              src={DeleteIcon}
                                                              alt="DeleteIcon"
                                                            />
                                                          </Dropdown.Toggle>
                                                          <Dropdown.Menu>
                                                            {userList.inviteStatus ===
                                                            "Acceptance Pending" ? (
                                                              <Dropdown.Item
                                                                onClick={() =>
                                                                  handleShowRemoveAuUserShow(
                                                                    userList,
                                                                    "Revoke Main"
                                                                  )
                                                                }
                                                              >
                                                                Revoke Invite
                                                              </Dropdown.Item>
                                                            ) : (
                                                              <Dropdown.Item
                                                                onClick={() =>
                                                                  handleShowRemoveAuUserShow(
                                                                    userList,
                                                                    "Remove Main"
                                                                  )
                                                                }
                                                              >
                                                                Remove User
                                                              </Dropdown.Item>
                                                            )}
                                                          </Dropdown.Menu>
                                                        </Dropdown>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  ) : (
                                                    " "
                                                  );
                                                }
                                              )
                                            ) : userList.inviteStatus !==
                                                "Removed" &&
                                              userList.inviteStatus !==
                                                "Cancelled" ? (
                                              <Dropdown className="profile-dropdwn">
                                                <Dropdown.Toggle
                                                  variant=""
                                                  className="m-0 inviteTxt user-dropdown"
                                                  id=""
                                                >
                                                  <Image
                                                    className="m-1 vertical-dot-icon"
                                                    src={DeleteIcon}
                                                    alt="DeleteIcon"
                                                  />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  {userList.inviteStatus ===
                                                  "Acceptance Pending" ? (
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handleShowRemoveAuUserShow(
                                                          userList,
                                                          "Revoke Main"
                                                        )
                                                      }
                                                    >
                                                      Revoke Invite
                                                    </Dropdown.Item>
                                                  ) : (
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handleShowRemoveAuUserShow(
                                                          userList,
                                                          "Remove Main"
                                                        )
                                                      }
                                                    >
                                                      Remove User
                                                    </Dropdown.Item>
                                                  )}

                                                  {/* {userList.inviteStatus !==
                                                    "Acceptance Pending" &&
                                                  userList.isAo !== true &&
                                                  occupiedSuSeatCount <
                                                    planDetails.availableS ? (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          assignMyselfAOAsSU(
                                                            userList.id
                                                          )
                                                        }
                                                      >
                                                        Assign myself as SU
                                                      </Dropdown.Item>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )} */}
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              ""
                            )
                          )}

                          {/* <div className="fhs-vh">
                            <p
                              className="mt-3 mb-2"
                              onClick={() =>
                                history.push({
                                  pathname: `/family_seat_history/${uuid}`,
                                  query: { tab1: "primary_users" },
                                })
                              }
                              style={{
                                color: "#0d6efd",
                                "font-weight": "500",
                                "text-decoration": "underline",
                                cursor: "pointer",
                              }}
                            >
                              Seat history
                            </p>
                          </div> */}
                        </div>
                      ) : (
                        <div className="new-Txt-Chng">
                          No Users available. Start adding by using the button
                          above
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xl="6" lg="6" md="12">
                  <>
                    {/* old code */}
                    {/* <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS == 1 ? (
                              <span>Support User </span>
                            ) : (
                              <span>Support Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedSuSeatCount} /{" "}
                            {planDetails.availableS == 1 ? (
                              <span>
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        <div>
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {occupiedSuSeatCount > 0 ? (
                        <div>
                          {familyUsersList.map((userList, index) =>
                            userList.isSu === true &&
                            userList.inviteStatus !== "Removed" &&
                            userList.inviteStatus !== "Cancelled" ? (
                              <span key={index}>
                                <SeatOccupied
                                  rightImg=""
                                  leftImg=""
                                  ProfileAvatar={userList.User.avatar}
                                  isEmpty="false"
                                  memberHeading={
                                    userList.User.firstName +
                                    " " +
                                    userList.User.lastName
                                  }
                                  memberSubheading={userList.User.email}
                                  dropdownItem1="View Details"
                                  dropdownItem2={
                                    userList.inviteStatus ===
                                    "Acceptance Pending"
                                      ? "Revoke Invite"
                                      : "Remove User"
                                  }
                                  onClickItem1={() =>
                                    handleShowSupportUserDetailShow(
                                      userList.User,
                                      userList
                                    )
                                  }
                                  onClickItem2={() =>
                                    userList.inviteStatus ===
                                    "Acceptance Pending"
                                      ? handleShowRemoveSuUserShow(
                                          userList,
                                          "Revoke Support"
                                        )
                                      : handleShowRemoveSuUserShow(
                                          userList,
                                          "Remove Support"
                                        )
                                  }
                                  memberStatus={
                                    userList.inviteStatus ===
                                    "Acceptance Pending"
                                      ? userList.inviteStatus
                                      : ""
                                  }
                                />
                              </span>
                            ) : (
                              ""
                            )
                          )}
                          <div className="fhs-vh-1">
                            <p
                              className="mt-3 mb-2 mr-4"
                              onClick={() =>
                                history.push({
                                  pathname: `/family_seat_history/${uuid}`,
                                  query: { tab2: "support_users" },
                                })
                              }
                              style={{
                                color: "#0d6efd",
                                "font-weight": "500",
                                "text-decoration": "underline",
                                cursor: "pointer",
                              }}
                            >
                              Seat history
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="new-Txt-Chng">No Users available.</div>
                      )}
                    </Card.Body>
                  </Card> */}
                  </>
                  {/* new code */}
                  <div className="position-relative py-2 ">
                    <div className="position-absolute py-1 px-4 dashboard-header">
                      {planDetails.availableS === 1 ? (
                        <span>Support User </span>
                      ) : (
                        <span>Support Users </span>
                      )}
                    </div>
                    <div className="dashboard-details my-2  p-4 dashboard-subheader">
                      <div className="d-flex align-items-center">
                        {occupiedSuSeatCount} /{" "}
                        {planDetails.availableS === 1 ? (
                          <span>{planDetails.availableS} user is occupied</span>
                        ) : (
                          <span>
                            {planDetails.availableS} users are occupied
                          </span>
                        )}
                        {remainingAuSeatCount <= planDetails.availableS &&
                        remainingAuSeatCount !== 0 ? (
                          <div
                            onClick={handleShowInviteAuShow}
                            className="d-flex justify-content-end fam-dash-box"
                            style={{ visibility: "hidden" }}
                          >
                            Add Main User
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {occupiedSuSeatCount > 0 ? (
                        <div>
                          {familyUsersList.map((userList, index) =>
                            userList.isSu === true &&
                            userList.inviteStatus !== "Removed" &&
                            userList.inviteStatus !== "Cancelled" ? (
                              <span key={index}>
                                <SeatOccupied
                                  rightImg=""
                                  leftImg=""
                                  ProfileAvatar={userList.User.avatar}
                                  isEmpty="false"
                                  memberHeading={
                                    userList.User.firstName +
                                    " " +
                                    userList.User.lastName
                                  }
                                  memberSubheading={userList.User.email}
                                  // dropdownItem1="View Details"
                                  dropdownItem2={
                                    userList.inviteStatus ===
                                    "Acceptance Pending"
                                      ? "Revoke Invite"
                                      : "Remove User"
                                  }
                                  // onClickEdit={() => supportUserDtls(userList)}
                                  onClickItem1={() =>
                                    handleShowSupportUserDetailShow(
                                      userList.User,
                                      userList
                                    )
                                  }
                                  onClickItem2={() =>
                                    userList.inviteStatus ===
                                    "Acceptance Pending"
                                      ? handleShowRemoveSuUserShow(
                                          userList,
                                          "Revoke Support"
                                        )
                                      : handleShowRemoveSuUserShow(
                                          userList,
                                          "Remove Support"
                                        )
                                  }
                                  memberStatus={
                                    userList.inviteStatus ===
                                    "Acceptance Pending"
                                      ? userList.inviteStatus
                                      : ""
                                  }
                                />
                              </span>
                            ) : (
                              ""
                            )
                          )}
                          {/* <div className="fhs-vh-1">
                            <p
                              className="mt-3 mb-2 mr-4"
                              onClick={() =>
                                history.push({
                                  pathname: `/family_seat_history/${uuid}`,
                                  query: { tab2: "support_users" },
                                })
                              }
                              style={{
                                color: "#0d6efd",
                                "font-weight": "500",
                                "text-decoration": "underline",
                                cursor: "pointer",
                              }}
                            >
                              Seat history
                            </p>
                          </div> */}
                        </div>
                      ) : (
                        <div className="new-Txt-Chng">No Users available.</div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            ) : userType === "AU" ? (
              <Row>
                <>
                  {/* old code  */}
                  {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <h3 className="invite-card-heading">
                        {planDetails.availableS == 1 ? (
                          <span> Main User </span>
                        ) : (
                          <span> Main Users </span>
                        )}
                      </h3>
                      <p className="">
                        {occupiedAuSeatCount} /
                        {planDetails.availableS == 1 ? (
                          <span>
                            {" "}
                            {planDetails.availableS} user is occupied
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {planDetails.availableS} users are occupied
                          </span>
                        )}
                      </p>
                      {occupiedAuSeatCount > 0 ? (
                        <div>
                          {familyUsersList.map((userList, index) =>
                            userList.isAu === true &&
                            userList.inviteStatus !== "Removed" &&
                            userList.inviteStatus !== "Cancelled" ? (
                              <span key={index}>
                                <div className="PU-dsn">
                                  <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                    <div className="float-left mx-2">
                                      <img
                                        src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                        alt="Profile Avatar"
                                        width="50px"
                                        height="50px"
                                      />
                                    </div>
                                    <div className="flex-2 word-break">
                                      <div className="main-dsgn-text">
                                        <div className="PU-dsn-txt">
                                          <span>
                                            {userList.User.firstName ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.firstName
                                            )}{" "}
                                            {userList.User.lastName ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.lastName
                                            )}
                                          </span>
                                          <span>
                                            {userList.User.firstName !==
                                            "null" ? (
                                              <div className="PU-dsn-txt-1">
                                                {userList.User.email}
                                              </div>
                                            ) : (
                                              <div className="PU-dsn-txt-1">
                                                {userList.User.email}
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                        <div></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              ""
                            )
                          )}
                          <div className="fhs-vh-2">
                            <p className="mt-3 mb-2">
                              <svg
                                className="mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.849"
                                height="13.074"
                                viewBox="0 0 20.849 13.074"
                              >
                                <g
                                  id="Group_12893"
                                  data-name="Group 12893"
                                  transform="translate(-356.4 -788.741)"
                                >
                                  <path
                                    id="Path_16599"
                                    data-name="Path 16599"
                                    d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                    transform="translate(148.221 788.741)"
                                    fill="#003a6f"
                                  />
                                  <path
                                    id="Path_16600"
                                    data-name="Path 16600"
                                    d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                    transform="translate(176.371 674.265)"
                                    fill="#003a6f"
                                  />
                                  <path
                                    id="Path_16603"
                                    data-name="Path 16603"
                                    d="M12.729,28.757,8.9,25.713a.613.613,0,0,0-.988.474v1.018a.572.572,0,0,1-.544.613H4.593A.572.572,0,0,0,4,28.392v1.6a.6.6,0,0,0,.593.593H7.372a.546.546,0,0,1,.544.583v1.018a.607.607,0,0,0,.979.465l3.815-2.965A.579.579,0,0,0,12.729,28.757Z"
                                    transform="translate(364.296 763.156)"
                                    fill="#007ef2"
                                  />
                                </g>
                              </svg>
                              User you're supporting
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="new-Txt-Chng">No Users available.</div>
                      )}
                    </Card.Body>
                  </Card>
                </Col> */}
                  {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS === 1 ? (
                              <span>Support User </span>
                            ) : (
                              <span>Support Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedSuSeatCount} /{" "}
                            {planDetails.availableS === 1 ? (
                              <span>
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        <div>
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {suAvatarArr.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}

                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {occupiedSuSeatCount > 0 ? (
                        <div>
                          {familyUsersList.map((userList, index) =>
                            userList.isSu === true &&
                            userList.inviteStatus !== "Removed" &&
                            userList.inviteStatus !== "Cancelled" ? (
                              <span key={index}>
                                <div className="PU-dsn">
                                  <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                    <div className="float-left mx-2">
                                      <img
                                        src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                        alt="Profile Avatar"
                                        width="50px"
                                        height="50px"
                                      />
                                    </div>
                                    <div className="flex-2 word-break">
                                      <div className="main-dsgn-text">
                                        <div className="PU-dsn-txt">
                                          <span>
                                            {userList.User.firstName ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.firstName
                                            )}{" "}
                                            {userList.User.lastName ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.lastName
                                            )}
                                          </span>
                                          <span>
                                            {userList.User.firstName !==
                                            "null" ? (
                                              <div className="PU-dsn-txt-1">
                                                {userList.User.email}
                                              </div>
                                            ) : (
                                              <div className="PU-dsn-txt-1">
                                                {userList.User.email}
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      {suAUSU1.length > 0 &&
                                        suAUSU1.map((userDetails, index) =>
                                          userList.inviteStatus ===
                                            "Onboarded" &&
                                          userList.User.email ===
                                            userDetails.suIdEmail &&
                                          userDetails.auEmail ===
                                            UserDetails.email ? (
                                            <span key={index}>
                                              <Image
                                                className="icon-user"
                                                src={Group_12891}
                                                alt=""
                                              />
                                            </span>
                                          ) : (
                                            ""
                                          )
                                        )}
                                    </div>

                                    <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                      <div>
                                        {userList.inviteStatus ===
                                        "Acceptance Pending"
                                          ? "Acceptance Pending"
                                          : ""}
                                      </div>

                                      <div style={{ minWidth: "56px" }}>
                                        {onBoardedUserListAU.length != 0
                                          ? onBoardedUserListAU.map(
                                              (userDetails, index) => {
                                                const status = userDetails
                                                  .supportingUserId.User
                                                  ? userDetails.supportingUserId
                                                      .inviteStatus
                                                  : userDetails.Family_User
                                                      .inviteStatus;
                                                return status == "Onboarded" ? (
                                                  <span key={index}>
                                                    {userDetails.Family_User
                                                      .User.id ===
                                                    userList.User.id ? (
                                                      <Dropdown className="profile-dropdwn">
                                                        <Dropdown.Toggle
                                                          variant=""
                                                          className="m-0 inviteTxt user-dropdown"
                                                          id=""
                                                        >
                                                          <Image
                                                            className="m-1 vertical-dot-icon"
                                                            src={VerticalDots}
                                                            alt="Vertical Dots"
                                                          />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              handleShowRemoveSuUserShow(
                                                                userDetails.Family_User,
                                                                "Remove this user as Support"
                                                              )
                                                            }
                                                          >
                                                            Remove this user as
                                                            SU
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                ) : (
                                                  ""
                                                );
                                              }
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              ""
                            )
                          )}
                          <div className="fhs-vh-1">
                            <p
                              className="mt-3 mb-2 "
                              style={{
                                color: "#0d6efd",
                                "font-weight": "500",
                                "text-decoration": "underline",
                                cursor: "pointer",
                                "margin-right": "35%",
                              }}
                              onClick={() =>
                                history.push({
                                  pathname: `/su_invite_history/${uuid}`,
                                })
                              }
                            >
                              Invite history
                            </p>
                            <p className="mt-3 mb-2 ">
                              <svg
                                className="mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.849"
                                height="13.074"
                                viewBox="0 0 20.849 13.074"
                              >
                                <g
                                  id="Group_12891"
                                  data-name="Group 12891"
                                  transform="translate(-351.4 -788.741)"
                                >
                                  <g
                                    id="customer"
                                    transform="translate(351.4 788.741)"
                                  >
                                    <g
                                      id="Group_12873"
                                      data-name="Group 12873"
                                      transform="translate(1.85)"
                                    >
                                      <g
                                        id="Group_12872"
                                        data-name="Group 12872"
                                      >
                                        <path
                                          id="Path_16599"
                                          data-name="Path 16599"
                                          d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                          transform="translate(-210.029)"
                                          fill="#003a6f"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_12875"
                                      data-name="Group 12875"
                                      transform="translate(0 7.524)"
                                    >
                                      <g
                                        id="Group_12874"
                                        data-name="Group 12874"
                                      >
                                        <path
                                          id="Path_16600"
                                          data-name="Path 16600"
                                          d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                          transform="translate(-180.029 -122)"
                                          fill="#003a6f"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                  <g
                                    id="straight-right-arrow"
                                    transform="translate(363.296 788.741)"
                                  >
                                    <path
                                      id="Path_16603"
                                      data-name="Path 16603"
                                      d="M4.224,28.757l3.825-3.044a.613.613,0,0,1,.988.474v1.018a.572.572,0,0,0,.544.613H12.36a.572.572,0,0,1,.593.573v1.6a.6.6,0,0,1-.593.593H9.581a.546.546,0,0,0-.544.583v1.018a.607.607,0,0,1-.979.465L4.244,29.686A.579.579,0,0,1,4.224,28.757Z"
                                      transform="translate(-4 -25.585)"
                                      fill="#00bb96"
                                    />
                                  </g>
                                </g>
                              </svg>
                              User supporting you
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="new-Txt-Chng">
                          No Users available. Start adding by using the button
                          above
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col> */}
                </>
                <>
                  {/* new code  */}
                  <Col xxl="6" xl="6" lg="8">
                    <div className="position-relative py-2 ">
                      <div className="position-absolute py-1 px-4 dashboard-header">
                        {planDetails.availableS === 1 ? (
                          <span>Your Support User </span>
                        ) : (
                          <span> Your Support Users </span>
                        )}
                      </div>
                      <div className="dashboard-details my-2  p-4 dashboard-subheader">
                        <div className="d-flex align-items-center">
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {suAvatarArr.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}

                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          {console.log(auusersu)}
                          {auusersu.length > 0 ? (
                            auusersu.map((userList, index) => (
                              <span key={index}>
                                <div className="PU-dsn">
                                  <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                    <div className="float-left mx-2">
                                      <img
                                        src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                        alt="Profile Avatar"
                                        width="50px"
                                        height="50px"
                                      />
                                    </div>
                                    <div className="flex-2 word-break">
                                      <div className="main-dsgn-text">
                                        <div className="PU-dsn-txt d-flex flex-column">
                                          <span>
                                            {userList.Family_User ? (
                                              userList.Family_User.User
                                                .firstName === "null" ? (
                                                <span></span>
                                              ) : (
                                                userList.Family_User.User
                                                  .firstName
                                              )
                                            ) : userList.User.firstName ===
                                              "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.firstName
                                            )}{" "}
                                            {userList.Family_User ? (
                                              userList.Family_User.User
                                                .lastName === "null" ? (
                                                <span></span>
                                              ) : (
                                                userList.Family_User.User
                                                  .lastName
                                              )
                                            ) : userList.User.lastName ===
                                              "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.lastName
                                            )}
                                          </span>
                                          <span>
                                            {userList.Family_User ? (
                                              userList.Family_User.User
                                                .email === "null" ? (
                                                <span></span>
                                              ) : (
                                                userList.Family_User.User.email
                                              )
                                            ) : userList.User.email ===
                                              "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.User.email
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                      <div>
                                        {userList.Family_User
                                          ? userList.Family_User
                                              .inviteStatus ===
                                            "Acceptance Pending"
                                            ? "Acceptance Pending"
                                            : ""
                                          : userList.inviteStatus ===
                                            "Acceptance Pending"
                                          ? "Acceptance Pending"
                                          : ""}
                                      </div>

                                      <div>
                                        {userList.Family_User ? (
                                          userList.Family_User.inviteStatus ===
                                          "Onboarded" ? (
                                            <span key={index}>
                                              <Dropdown className="profile-dropdwn">
                                                <Dropdown.Toggle
                                                  variant=""
                                                  className="m-0 inviteTxt user-dropdown"
                                                  id=""
                                                >
                                                  <Image
                                                    className="m-1 vertical-dot-icon"
                                                    src={VerticalDots}
                                                    alt="Vertical Dots"
                                                  />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleShowRemoveSuUserShow(
                                                        userList.Family_User,
                                                        "Remove this user as Support"
                                                      )
                                                    }
                                                  >
                                                    Remove this user as SU
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </span>
                                          ) : (
                                            ""
                                          )
                                        ) : userList.inviteStatus ===
                                          "Acceptance Pending" ? (
                                          <span
                                            key={index}
                                            style={{ padding: "10px" }}
                                          >
                                            {/* <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList,
                                                      "Revoke this user as Support"
                                                    )
                                                  }
                                                >
                                                  Revoke invite{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown> */}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ))
                          ) : (
                            <div className="new-Txt-Chng">
                              No Users available. Start adding by using the
                              button above
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              </Row>
            ) : userType === "AU + SU" ? (
              <Row>
                <>
                  {/* old code  */}
                  {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <h3 className="invite-card-heading">
                        {planDetails.availableS == 1 ? (
                          <span> Main User </span>
                        ) : (
                          <span> Main Users </span>
                        )}
                      </h3>
                      <p className="">
                        {occupiedAuSeatCount} /
                        {planDetails.availableS == 1 ? (
                          <span>
                            {" "}
                            {planDetails.availableS} user is occupied
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {planDetails.availableS} users are occupied
                          </span>
                        )}
                      </p>
                      {familyUsersList.map((userList, index) =>
                        userList.isAu === true &&
                        userList.inviteStatus !== "Removed" &&
                        userList.inviteStatus !== "Cancelled" ? (
                          <span key={index}>
                            <div className="PU-dsn">
                              <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                <div className="float-left mx-2">
                                  <img
                                    src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                    alt="Profile Avatar"
                                    width="50px"
                                    height="50px"
                                  />
                                </div>
                                <div className="flex-2 word-break">
                                  <div className="main-dsgn-text">
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  {onBoardedUserListSU.length !== 0
                                    ? onBoardedUserListSU.map(
                                        (userDetails, index) => {
                                          const status = userDetails
                                            .supportingUserId.User
                                            ? userDetails.supportingUserId
                                                .inviteStatus
                                            : userDetails.Family_User
                                                .inviteStatus;
                                          return status === "Onboarded" ? (
                                            <span key={index}>
                                              {userDetails.supportingUserId.User
                                                .email ===
                                              userList.User.email ? (
                                                <Image
                                                  className="icon-user"
                                                  src={Group_12893}
                                                  alt=""
                                                  // onClick={clickFn}
                                                  // width="50px"
                                                  // height="50px"
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          ) : (
                                            " "
                                          );
                                        }
                                      )
                                    : ""}
                                </div>

                                <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                  <div className="me-4">
                                    {onBoardedUserListSU.length != 0
                                      ? onBoardedUserListSU.map(
                                          (userDetails, index) => {
                                            const status = userDetails
                                              .supportingUserId.User
                                              ? userDetails.supportingUserId
                                                  .inviteStatus
                                              : userDetails.Family_User
                                                  .inviteStatus;

                                            return status == "Onboarded" ? (
                                              <span key={index}>
                                                {userDetails.supportingUserId
                                                  .User.email ===
                                                userList.User.email ? (
                                                  <Link
                                                    to={`/supporting_user_dashboard/${uuid}/${userDetails.supportingUserId.User.uuid}`}
                                                  >
                                                    <img
                                                      className="icon-user-1"
                                                      src={BlueArrow}
                                                      alt="Profile Avatar"
                                                      onClick={clickFn}
                                                    />
                                                  </Link>
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            ) : (
                                              " "
                                            );
                                          }
                                        )
                                      : ""}
                                  </div>
                                  <div style={{ minWidth: "56px" }}>
                                    {onBoardedUserListSU.length != 0
                                      ? onBoardedUserListSU.map(
                                          (userDetails, index) => {
                                            const status = userDetails
                                              .supportingUserId.User
                                              ? userDetails.supportingUserId
                                                  .inviteStatus
                                              : userDetails.Family_User
                                                  .inviteStatus;

                                            return status == "Onboarded" ? (
                                              <span key={index}>
                                                {userDetails.supportingUserId
                                                  .User.email ===
                                                userList.User.email ? (
                                                  <Dropdown className="profile-dropdwn">
                                                    <Dropdown.Toggle
                                                      variant=""
                                                      className="m-0 inviteTxt user-dropdown"
                                                      id=""
                                                    >
                                                      <Image
                                                        className="m-1 vertical-dot-icon"
                                                        src={VerticalDots}
                                                        alt="Vertical Dots"
                                                      />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveAuUserShow(
                                                            userList,
                                                            "Stop Supporting this"
                                                          )
                                                        }
                                                      >
                                                        Stop supporting this
                                                        user
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            ) : (
                                              " "
                                            );
                                          }
                                        )
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        ) : (
                          ""
                        )
                      )}

                      <div className="fhs-vh-2">
                        <p className="mt-3 mb-2">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12893"
                              data-name="Group 12893"
                              transform="translate(-356.4 -788.741)"
                            >
                              <path
                                id="Path_16599"
                                data-name="Path 16599"
                                d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                transform="translate(148.221 788.741)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16600"
                                data-name="Path 16600"
                                d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                transform="translate(176.371 674.265)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16603"
                                data-name="Path 16603"
                                d="M12.729,28.757,8.9,25.713a.613.613,0,0,0-.988.474v1.018a.572.572,0,0,1-.544.613H4.593A.572.572,0,0,0,4,28.392v1.6a.6.6,0,0,0,.593.593H7.372a.546.546,0,0,1,.544.583v1.018a.607.607,0,0,0,.979.465l3.815-2.965A.579.579,0,0,0,12.729,28.757Z"
                                transform="translate(364.296 763.156)"
                                fill="#007ef2"
                              />
                            </g>
                          </svg>
                          User you're supporting
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                  {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS == 1 ? (
                              <span>Support User </span>
                            ) : (
                              <span>Support Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedSuSeatCount} /{" "}
                            {planDetails.availableS == 1 ? (
                              <span>
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        <div>
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {familyUsersList.map((userList, index) =>
                        userList.isSu === true &&
                        userList.inviteStatus !== "Removed" &&
                        userList.inviteStatus !== "Cancelled" ? (
                          <span key={index}>
                            <div className="PU-dsn">
                              <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                <div className="float-left mx-2">
                                  <img
                                    src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                    alt="Profile Avatar"
                                    width="50px"
                                    height="50px"
                                  />
                                </div>
                                <div className="flex-2 word-break">
                                  <div className="main-dsgn-text">
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  {onBoardedUserListAU.length != 0
                                    ? onBoardedUserListAU.map(
                                        (userDetails, index) => {
                                          const status = userDetails
                                            .supportingUserId.User
                                            ? userDetails.supportingUserId
                                                .inviteStatus
                                            : userDetails.Family_User
                                                .inviteStatus;
                                          return status == "Onboarded" ? (
                                            <span key={index}>
                                              {userDetails.Family_User.User
                                                .id === userList.User.id ? (
                                                <Image
                                                  className="icon-user"
                                                  src={Group_12891}
                                                  alt=""
                                                />
                                              ) : (
                                                " "
                                              )}
                                            </span>
                                          ) : (
                                            " "
                                          );
                                        }
                                      )
                                    : ""}
                                </div>

                                <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                  <div>
                                    {userList.inviteStatus ==
                                    "Acceptance Pending"
                                      ? "Acceptance Pending"
                                      : ""}
                                  </div>

                                  <div style={{ minWidth: "56px" }}>
                                    {onBoardedUserListAU.length != 0
                                      ? onBoardedUserListAU.map(
                                          (userDetails, index) => {
                                            const status = userDetails
                                              .supportingUserId.User
                                              ? userDetails.supportingUserId
                                                  .inviteStatus
                                              : userDetails.Family_User
                                                  .inviteStatus;
                                            return status == "Onboarded" ? (
                                              <span key={index}>
                                                {userDetails.Family_User.User
                                                  .id === userList.User.id ? (
                                                  <Dropdown className="profile-dropdwn">
                                                    <Dropdown.Toggle
                                                      variant=""
                                                      className="m-0 inviteTxt user-dropdown"
                                                      id=""
                                                    >
                                                      <Image
                                                        className="m-1 vertical-dot-icon"
                                                        src={VerticalDots}
                                                        alt="Vertical Dots"
                                                      />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowRemoveSuUserShow(
                                                            userDetails.Family_User,
                                                            "Remove this user as Support"
                                                          )
                                                        }
                                                      >
                                                        Remove this user as SU
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            ) : (
                                              ""
                                            );
                                          }
                                        )
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        ) : (
                          ""
                        )
                      )}
                      <div className="fhs-vh-1">
                        <p
                          className="mt-3 mb-2 "
                          style={{
                            color: "#0d6efd",
                            "font-weight": "500",
                            "text-decoration": "underline",
                            cursor: "pointer",
                            "margin-right": "35%",
                          }}
                          onClick={() =>
                            history.push({
                              pathname: `/su_invite_history/${uuid}`,
                            })
                          }
                        >
                          Invite history
                        </p>
                        <p className="mt-3 mb-2 ">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12891"
                              data-name="Group 12891"
                              transform="translate(-351.4 -788.741)"
                            >
                              <g
                                id="customer"
                                transform="translate(351.4 788.741)"
                              >
                                <g
                                  id="Group_12873"
                                  data-name="Group 12873"
                                  transform="translate(1.85)"
                                >
                                  <g id="Group_12872" data-name="Group 12872">
                                    <path
                                      id="Path_16599"
                                      data-name="Path 16599"
                                      d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                      transform="translate(-210.029)"
                                      fill="#003a6f"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_12875"
                                  data-name="Group 12875"
                                  transform="translate(0 7.524)"
                                >
                                  <g id="Group_12874" data-name="Group 12874">
                                    <path
                                      id="Path_16600"
                                      data-name="Path 16600"
                                      d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                      transform="translate(-180.029 -122)"
                                      fill="#003a6f"
                                    />
                                  </g>
                                </g>
                              </g>
                              <g
                                id="straight-right-arrow"
                                transform="translate(363.296 788.741)"
                              >
                                <path
                                  id="Path_16603"
                                  data-name="Path 16603"
                                  d="M4.224,28.757l3.825-3.044a.613.613,0,0,1,.988.474v1.018a.572.572,0,0,0,.544.613H12.36a.572.572,0,0,1,.593.573v1.6a.6.6,0,0,1-.593.593H9.581a.546.546,0,0,0-.544.583v1.018a.607.607,0,0,1-.979.465L4.244,29.686A.579.579,0,0,1,4.224,28.757Z"
                                  transform="translate(-4 -25.585)"
                                  fill="#00bb96"
                                />
                              </g>
                            </g>
                          </svg>
                          User supporting you
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                </>
                <>
                  {/* new code  */}
                  {/* AU + SU --- new code  */}
                  <Col lg="6">
                    <div className="position-relative py-2 ">
                      <div className="position-absolute py-1 px-4 dashboard-header">
                        {planDetails.availableS === 1 ? (
                          <span>Your Support User </span>
                        ) : (
                          <span>Your Support Users </span>
                        )}
                      </div>
                      <div className="dashboard-details my-2  p-4 dashboard-subheader">
                        <div className="d-flex justify-content-end">
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>
                        {auusersu.length > 0 ? (
                          auusersu.map((userList, index) => (
                            <span key={index}>
                              <div className="PU-dsn">
                                <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                  <div className="float-left mx-2">
                                    <img
                                      src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                      alt="Profile Avatar"
                                      width="50px"
                                      height="50px"
                                    />
                                  </div>
                                  <div className="flex-2 word-break">
                                    <div className="main-dsgn-text">
                                      <div className="PU-dsn-txt">
                                        <span>
                                          {userList.Family_User ? (
                                            userList.Family_User.User
                                              .firstName === "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.Family_User.User
                                                .firstName
                                            )
                                          ) : userList.User.firstName ===
                                            "null" ? (
                                            <span></span>
                                          ) : (
                                            userList.User.firstName
                                          )}{" "}
                                          {userList.Family_User ? (
                                            userList.Family_User.User
                                              .lastName === "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.Family_User.User.lastName
                                            )
                                          ) : userList.User.lastName ===
                                            "null" ? (
                                            <span></span>
                                          ) : (
                                            userList.User.lastName
                                          )}
                                        </span>
                                        <span>
                                          {userList.Family_User ? (
                                            userList.Family_User.User.email ===
                                            "null" ? (
                                              <span></span>
                                            ) : (
                                              userList.Family_User.User.email
                                            )
                                          ) : userList.User.email === "null" ? (
                                            <span></span>
                                          ) : (
                                            userList.User.email
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div>
                                            <span>
                                              {userDetails.Family_User.User
                                                .id === userList.User.id ? (
                                                <Image
                                                  className="icon-user"
                                                  src={Group_12891}
                                                  alt=""
                                                />
                                              ) : (
                                                " "
                                              )}
                                            </span>
                                          </div> */}

                                  <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                    {userList.inviteStatus ===
                                      "Acceptance Pending" && (
                                      <div>Acceptance Pending</div>
                                    )}

                                    <div>
                                      {userList.Family_User ? (
                                        userList.Family_User.inviteStatus ===
                                        "Onboarded" ? (
                                          <span key={index}>
                                            <Dropdown className="profile-dropdwn">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="m-0 inviteTxt user-dropdown"
                                                id=""
                                              >
                                                <Image
                                                  className="m-1 vertical-dot-icon"
                                                  src={VerticalDots}
                                                  alt="Vertical Dots"
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList.Family_User,
                                                      "Remove this user as Support"
                                                    )
                                                  }
                                                >
                                                  Remove this user as SU
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </span>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          ))
                        ) : (
                          <div className="new-Txt-Chng">
                            No Users available. Start adding by using the button
                            above
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="position-relative py-2 ">
                      <div className="position-absolute py-1 px-4 dashboard-header">
                        {planDetails.availableS === 1 ? (
                          <span>User you are Supporting </span>
                        ) : (
                          <span>Users you are Supportings </span>
                        )}
                      </div>
                      <div className="dashboard-details my-2  p-4 dashboard-subheader">
                        <span
                          className="manageuser-btn"
                          onClick={handleShowInviteSuShow}
                          style={{ visibility: "hidden" }}
                        >
                          Add Support User
                        </span>
                        {onBoardedUserListSU.length !== 0 ? (
                          onBoardedUserListSU.map((userDetails, index) =>
                            familyUsersList.map((userList, index) => {
                              const status = userDetails.supportingUserId.User
                                ? userDetails.supportingUserId.inviteStatus
                                : userDetails.Family_User.inviteStatus;
                              return (
                                status === "Onboarded" && (
                                  <span key={index}>
                                    {userDetails.supportingUserId.User.email ===
                                    userList.User.email ? (
                                      <span key={index}>
                                        <div className="PU-dsn">
                                          <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                            <div className="float-left mx-2">
                                              <img
                                                src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                                alt="Profile Avatar"
                                                width="50px"
                                                height="50px"
                                              />
                                            </div>
                                            <div className="flex-2 word-break">
                                              <div className="main-dsgn-text">
                                                <div className="PU-dsn-txt">
                                                  <span>
                                                    {userList.User.firstName ===
                                                    "null" ? (
                                                      <span></span>
                                                    ) : (
                                                      userList.User.firstName
                                                    )}{" "}
                                                    {userList.User.lastName ===
                                                    "null" ? (
                                                      <span></span>
                                                    ) : (
                                                      userList.User.lastName
                                                    )}
                                                  </span>
                                                  <span>
                                                    {userList.User.firstName !==
                                                    "null" ? (
                                                      <div className="PU-dsn-txt-1">
                                                        {userList.User.email}
                                                      </div>
                                                    ) : (
                                                      <div className="PU-dsn-txt-1">
                                                        {userList.User.email}
                                                      </div>
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>

                                            {/* <div>
                                            {onBoardedUserListSU.length !== 0
                                              ? onBoardedUserListSU.map(
                                                  (userDetails, index) => {
                                                    const status = userDetails
                                                      .supportingUserId.User
                                                      ? userDetails
                                                          .supportingUserId
                                                          .inviteStatus
                                                      : userDetails.Family_User
                                                          .inviteStatus;
                                                    return status ===
                                                      "Onboarded" ? (
                                                      <span key={index}>
                                                        {userDetails
                                                          .supportingUserId.User
                                                          .email ===
                                                        userList.User.email ? (
                                                          <Image
                                                            className="icon-user"
                                                            src={Group_12893}
                                                            alt=""
                                                            // onClick={clickFn}
                                                            // width="50px"
                                                            // height="50px"
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    ) : (
                                                      " "
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </div> */}

                                            <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
                                              <Link
                                                to={`/supporting_user_dashboard/${uuid}/${userDetails.supportingUserId.User.uuid}`}
                                              >
                                                <div className="login-btn-famdash">
                                                  {/* <img
                                                  className="icon-user-1"
                                                  src={BlueArrow}
                                                  alt="Profile Avatar"
                                                  onClick={clickFn}
                                                /> */}
                                                  <div
                                                    style={{ color: "white" }}
                                                  >
                                                    Login
                                                  </div>
                                                </div>
                                              </Link>
                                              <div>
                                                {onBoardedUserListSU.length !==
                                                0
                                                  ? onBoardedUserListSU.map(
                                                      (userDetails, index) => {
                                                        const status = userDetails
                                                          .supportingUserId.User
                                                          ? userDetails
                                                              .supportingUserId
                                                              .inviteStatus
                                                          : userDetails
                                                              .Family_User
                                                              .inviteStatus;

                                                        return status ===
                                                          "Onboarded" ? (
                                                          <span key={index}>
                                                            {userDetails
                                                              .supportingUserId
                                                              .User.email ===
                                                            userList.User
                                                              .email ? (
                                                              <Dropdown className="profile-dropdwn">
                                                                <Dropdown.Toggle
                                                                  variant=""
                                                                  className="m-0 inviteTxt user-dropdown"
                                                                  id=""
                                                                >
                                                                  <Image
                                                                    className="m-1 vertical-dot-icon"
                                                                    src={
                                                                      VerticalDots
                                                                    }
                                                                    alt="Vertical Dots"
                                                                  />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                  <Dropdown.Item
                                                                    onClick={() =>
                                                                      handleShowRemoveAuUserShow(
                                                                        userList,
                                                                        "Stop Supporting this"
                                                                      )
                                                                    }
                                                                  >
                                                                    Stop
                                                                    supporting
                                                                    this user
                                                                  </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                              </Dropdown>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </span>
                                                        ) : (
                                                          " "
                                                        );
                                                      }
                                                    )
                                                  : ""}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                )
                              );
                            })
                          )
                        ) : (
                          <div className="new-Txt-Chng">
                            No Users available.
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </>
              </Row>
            ) : userType === "SU" ? (
              <Row>
                <Col xxl="6" xl="6" lg="6" md="8" className="mt-3">
                  <Card
                    className={`story-card seat-card basic-cards famdash-border`}
                  >
                    <Card.Body className="p-1">
                      <div className="">
                        {planDetails.availableS === 1 ? (
                          <span> User you are supporting</span>
                        ) : (
                          <span> Users you are supporting</span>
                        )}
                      </div>
                      {/* SU-- Main user tab */}
                      {occupiedAuSeatCount > 0 ? (
                        <div>
                          {newAr.length > 0 ? (
                            newAr.map((userDetails, index) =>
                              familyUsersList.map(
                                (userList, index) =>
                                  userList.User.email === userDetails.auEmail &&
                                  userDetails.suIdEmail ===
                                    UserDetails.email && (
                                    <span key={index}>
                                      <div className="PU-dsn">
                                        <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                          <div className="float-left mx-2">
                                            <img
                                              src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                              alt="Profile Avatar"
                                              width="50px"
                                              height="50px"
                                            />
                                          </div>
                                          <div className="flex-2 word-break">
                                            <div className="main-dsgn-text">
                                              <div className="PU-dsn-txt">
                                                <span>
                                                  {userList.User.firstName ===
                                                  "null" ? (
                                                    <span></span>
                                                  ) : (
                                                    userList.User.firstName
                                                  )}{" "}
                                                  {userList.User.lastName ===
                                                  "null" ? (
                                                    <span></span>
                                                  ) : (
                                                    userList.User.lastName
                                                  )}
                                                </span>
                                                <span>
                                                  {userList.User.firstName !==
                                                  "null" ? (
                                                    <div className="PU-dsn-txt-1">
                                                      {userList.User.email}
                                                    </div>
                                                  ) : (
                                                    <div className="PU-dsn-txt-1">
                                                      {userList.User.email}
                                                    </div>
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div>
                                      {onBoardedUserListSU.length != 0
                                        ? onBoardedUserListSU.map(
                                            (userDetails, index) => {
                                              const status = userDetails
                                                .supportingUserId.User
                                                ? userDetails.supportingUserId
                                                    .inviteStatus
                                                : userDetails.Family_User
                                                    .inviteStatus;
                                              return status == "Onboarded" ? (
                                                <span key={index}>
                                                  {userDetails.supportingUserId
                                                    .User.email ===
                                                  userList.User.email ? (
                                                    <Image
                                                      className="icon-user"
                                                      src={Group_12893}
                                                      alt=""
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              ) : (
                                                " "
                                              );
                                            }
                                          )
                                        : ""}
                                    </div> */}
                                          {/* <div>
                                      {newAr.length > 0 &&
                                        newAr.map((userDetails, index) =>
                                          userList.User.email ===
                                            userDetails.auEmail &&
                                          userDetails.suIdEmail ===
                                            UserDetails.email ? (
                                            <span key={index}>
                                              <Image
                                                className="icon-user"
                                                src={Group_12893}
                                                alt=""
                                              />
                                            </span>
                                          ) : (
                                            ""
                                          )
                                        )}
                                    </div> */}
                                          <div className=" ms-auto d-flex justify-content-end align-items-center ">
                                            {/* <div className="me-4">
                                        {onBoardedUserListSU.length != 0
                                          ? onBoardedUserListSU.map(
                                              (userDetails, index) => {
                                                const status = userDetails
                                                  .supportingUserId.User
                                                  ? userDetails.supportingUserId
                                                      .inviteStatus
                                                  : userDetails.Family_User
                                                      .inviteStatus;
                                                return status == "Onboarded" ? (
                                                  <span key={index}>
                                                    {userDetails
                                                      .supportingUserId.User
                                                      .email ===
                                                    userList.User.email ? (
                                                      <Link
                                                        to={`/supporting_user_dashboard/${uuid}/${userDetails.supportingUserId.User.uuid}`}
                                                      >
                                                        <img
                                                          className="icon-user-1"
                                                          src={BlueArrow}
                                                          alt="Profile Avatar"
                                                          onClick={clickFn}
                                                        />
                                                      </Link>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                ) : (
                                                  " "
                                                );
                                              }
                                            )
                                          : ""}
                                      </div> */}

                                            <div>
                                              {/* {newAr.length > 0 &&
                                                newAr.map(
                                                  (userDetails, index) =>
                                                    userList.User.email ===
                                                      userDetails.auEmail &&
                                                    userDetails.suIdEmail ===
                                                      UserDetails.email ? (
                                                      <span key={index}> */}
                                              <Link
                                                to={`/supporting_user_dashboard/${uuid}/${userList.User.uuid}`}
                                              >
                                                {/* <img
                                                    className="icon-user-1"
                                                    src={BlueArrow}
                                                    alt="Profile Avatar"
                                                    onClick={clickFn}
                                                  /> */}
                                                <div className="famDash-loginSupp">
                                                  Login
                                                </div>
                                              </Link>
                                              {/* </span>
                                                    ) : (
                                                      ""
                                                    )
                                                )} */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  )
                              )
                            )
                          ) : (
                            <div className="new-Txt-Chng">
                              No Users available.
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="new-Txt-Chng">No Users available</div>
                      )}
                      {/* {remainingAuSeatCount <= planDetails.availableS &&
                      remainingAuSeatCount != 0 ? (
                        <span onClick={handleShowInviteAuShow} style={{"cursor":"pointer"}}>
                          <SeatEmpty
                            rightImg=""
                            leftImg=""
                            ProfileAvatar=""
                            isEmpty="true"
                            memberHeading="+ Invite a new user"
                            memberSubheading="via email"
                          />
                        </span>
                      ) : (
                        ""
                      )} */}
                      {/* {[...Array(remainingAuSeatCount)].map(
                        (elementInArray, index) => (
                          <span onClick={handleShowInviteAuShow} style={{"cursor":"pointer"}} key={index}>
                            <SeatEmpty
                              rightImg=""
                              leftImg=""
                              ProfileAvatar=""
                              isEmpty="true"
                              memberHeading="+ Invite a new user"
                              memberSubheading="via email"
                            />
                          </span>
                        )
                      )} */}
                      <div className="fhs-vh-2">
                        {/* <p className="mt-3 mb-2">
                          <Link
                            to={{
                              pathname: `/family_seat_history/${uuid}`,
                              query: { tab1: "primary_users" },
                            }}
                          >
                            Seat history
                          </Link>
                        </p> */}
                        {/* <p className="mt-3 mb-2">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12893"
                              data-name="Group 12893"
                              transform="translate(-356.4 -788.741)"
                            >
                              <path
                                id="Path_16599"
                                data-name="Path 16599"
                                d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                transform="translate(148.221 788.741)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16600"
                                data-name="Path 16600"
                                d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                transform="translate(176.371 674.265)"
                                fill="#003a6f"
                              />
                              <path
                                id="Path_16603"
                                data-name="Path 16603"
                                d="M12.729,28.757,8.9,25.713a.613.613,0,0,0-.988.474v1.018a.572.572,0,0,1-.544.613H4.593A.572.572,0,0,0,4,28.392v1.6a.6.6,0,0,0,.593.593H7.372a.546.546,0,0,1,.544.583v1.018a.607.607,0,0,0,.979.465l3.815-2.965A.579.579,0,0,0,12.729,28.757Z"
                                transform="translate(364.296 763.156)"
                                fill="#007ef2"
                              />
                            </g>
                          </svg>
                          User you're supporting
                        </p> */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                {/* <Col lg="6">
                  <Card className={`story-card seat-card basic-cards`}>
                    <Card.Body className="p-1">
                      <div className="su-newstyle">
                        <div>
                          <h3 className="invite-card-heading">
                            {planDetails.availableS == 1 ? (
                              <span>Support User </span>
                            ) : (
                              <span>Support Users </span>
                            )}
                          </h3>
                          <p className="">
                            {occupiedSuSeatCount} /{" "}
                            {planDetails.availableS == 1 ? (
                              <span>
                                {planDetails.availableS} user is occupied
                              </span>
                            ) : (
                              <span>
                                {planDetails.availableS} users are occupied
                              </span>
                            )}
                          </p>
                        </div>
                        <div>
                          {familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === false ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : familyDetails.FamilyData.isAo === true &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === true ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ===
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === true &&
                            familyDetails.FamilyData.isSu === false ? (
                            occupiedSuSeatCount < planDetails.availableS &&
                            newSU.length === 0 ? (
                              <span
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </span>
                            ) : occupiedSuSeatCount >= planDetails.availableS &&
                              newSU.length === 0 ? (
                              ""
                            ) : (
                              <Accordion>
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      {newSU.length > 0 ? (
                                        <li onClick={handleShowChooseSuShow}>
                                          Choose existing Support Users
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {occupiedSuSeatCount >
                                        planDetails.availableS ||
                                      occupiedSuSeatCount ==
                                        planDetails.availableS ? (
                                        " "
                                      ) : (
                                        <li onClick={handleShowInviteSuShow}>
                                          Invite a new Support User
                                        </li>
                                      )}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          ) : familyDetails.FamilyData.isAo === false &&
                            familyDetails.FamilyData.isAu === false &&
                            familyDetails.FamilyData.isSu === true ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      {familyUsersList.map((userList, index) =>
                        userList.isSu === true &&
                        userList.inviteStatus !== "Removed" &&
                        userList.inviteStatus !== "Cancelled" ? (
                          <span key={index}>
                            <div className="PU-dsn">
                              <div className="PU-dsn-sub d-flex justify-content-between align-items-center h-100">
                                <div className="float-left mx-2">
                                  <img
                                    src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                    alt="Profile Avatar"
                                    width="50px"
                                    height="50px"
                                  />
                                </div>
                                <div className="flex-2 word-break">
                                  <div className="main-dsgn-text">
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        ) : (
                          ""
                        )
                      )}
                      <div className="fhs-vh-2">
                        <p className="mt-3 mb-2 ">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.849"
                            height="13.074"
                            viewBox="0 0 20.849 13.074"
                          >
                            <g
                              id="Group_12891"
                              data-name="Group 12891"
                              transform="translate(-351.4 -788.741)"
                            >
                              <g
                                id="customer"
                                transform="translate(351.4 788.741)"
                              >
                                <g
                                  id="Group_12873"
                                  data-name="Group 12873"
                                  transform="translate(1.85)"
                                >
                                  <g id="Group_12872" data-name="Group 12872">
                                    <path
                                      id="Path_16599"
                                      data-name="Path 16599"
                                      d="M213.791,0a3.81,3.81,0,0,0-3.762,3.762,3.81,3.81,0,0,0,3.762,3.762,3.762,3.762,0,0,0,0-7.524Z"
                                      transform="translate(-210.029)"
                                      fill="#003a6f"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_12875"
                                  data-name="Group 12875"
                                  transform="translate(0 7.524)"
                                >
                                  <g id="Group_12874" data-name="Group 12874">
                                    <path
                                      id="Path_16600"
                                      data-name="Path 16600"
                                      d="M189.748,122a5.517,5.517,0,0,1-4.107,1.85,5.651,5.651,0,0,1-4.168-1.85,5.508,5.508,0,0,0-1.444,3.7v.925a.925.925,0,0,0,.925.925h9.312a.925.925,0,0,0,.925-.925V125.7A5.508,5.508,0,0,0,189.748,122Z"
                                      transform="translate(-180.029 -122)"
                                      fill="#003a6f"
                                    />
                                  </g>
                                </g>
                              </g>
                              <g
                                id="straight-right-arrow"
                                transform="translate(363.296 788.741)"
                              >
                                <path
                                  id="Path_16603"
                                  data-name="Path 16603"
                                  d="M4.224,28.757l3.825-3.044a.613.613,0,0,1,.988.474v1.018a.572.572,0,0,0,.544.613H12.36a.572.572,0,0,1,.593.573v1.6a.6.6,0,0,1-.593.593H9.581a.546.546,0,0,0-.544.583v1.018a.607.607,0,0,1-.979.465L4.244,29.686A.579.579,0,0,1,4.224,28.757Z"
                                  transform="translate(-4 -25.585)"
                                  fill="#00bb96"
                                />
                              </g>
                            </g>
                          </svg>
                          User supporting you
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
              </Row>
            ) : (
              ""
            )}
          </>
        )}
        {/* invite AU modal */}
        <Modal
          show={showInviteAu}
          onHide={handleShowInviteAuClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Invite a Main user</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-1 subtext">
              Enter the email address of the user whom you want to add as an
              Main Users to your family.
            </p>
            <form className="invite-form" onSubmit={handleSubmit}>
              <label
                className={
                  formErrors.firstname
                    ? "invalid-text form-label mt-3 mb-0"
                    : "form-label mt-3 mb-0"
                }
              >
                Firstname
              </label>
              <Small
                type="test"
                errorClass={formErrors.firstname ? "invalid-input" : ""}
                placeholder="John"
                size="medium"
                name="firstname"
                value={formValues.firstname}
                onChange={handleChange}
              />
              <label
                className={
                  formErrors.lastname
                    ? "invalid-text form-label mt-3 mb-0"
                    : "form-label mt-3 mb-0"
                }
              >
                Lastname
              </label>
              <Small
                type="test"
                errorClass={formErrors.lastname ? "invalid-input" : ""}
                placeholder="Walker"
                size="medium"
                name="lastname"
                value={formValues.lastname}
                onChange={handleChange}
              />

              <label
                className={
                  formErrors.lastname
                    ? "invalid-text form-label mt-3 mb-0"
                    : "form-label mt-3 mb-0"
                }
              >
                Email
              </label>
              <Small
                type="email"
                errorClass={formErrors.email ? "invalid-input" : ""}
                placeholder="jwalker@gmail.com"
                size="medium"
                name="email"
                value={formValues.email}
                onChange={handleChange}
              />

              <div className="text-center mt-4 mb-2">
                <Primary
                  children="Send Invite"
                  variant="primary"
                  size="mediumbtn"
                  type="submit"
                  buttonWidth="170px"
                  isLoading={formLoading ? "true" : "false"}
                  isDisabled={formLoading ? "true" : "false"}
                />
                {/* {formLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
              </div>
            </form>
            <div className="text-center mt-4 mb-2">
              <div className="separator">OR</div>
              <Info
                children="Add myself as a PU"
                variant="info"
                size="mediumbtn"
                onClick={handleInviteMyselfAsAU}
                buttonWidth="220px"
              />
            </div>
          </Modal.Body>
        </Modal>
        {/* Remove account user */}
        <Modal
          show={showRemoveAuUser}
          onHide={handleShowRemoveAuUserClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">{removeUserModelTitle} User</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <SeatOccupied
              rightImg=""
              leftImg=""
              isEmpty="true"
              ProfileAvatar={auInviteImage}
              memberHeading={auInviteName + " " + auInviteName1}
              memberSubheading={auInviteEmail}
            />
            {removeUserModelTitle === "Remove Main" ? (
              <p className="my-3 subtext">
                You are removing the above user from your Family.
              </p>
            ) : removeUserModelTitle === "Revoke Main" ? (
              <p className="my-3 subtext">
                You are revoking the above invite user from your Family.
              </p>
            ) : (
              <p className="my-3 subtext">
                You are stop supporting the above user from your Family.
              </p>
            )}
            {removeUserModelTitle === "Remove Main" ? (
              <p className="mb-4 subtext">
                <strong>
                  Once the user is removed, they will not be able to access your
                  details in this family.
                </strong>
              </p>
            ) : removeUserModelTitle === "Revoke Main" ? (
              <p className="mb-4 subtext">
                <strong>
                  Once you revoke the invite, User will not going to be
                  Onboarded in to the family.
                </strong>
              </p>
            ) : (
              <p className="mb-4 subtext">
                <strong>
                  Once you stop supporting, you will not be able to access the
                  user's details in this family.
                </strong>
              </p>
            )}
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowRemoveAuUserClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() =>
                    handleCancelAuInvite(auInviteId, removeUserModelTitle)
                  }
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* Remove account user */}
        <Modal
          show={showRemoveSuUser}
          onHide={handleShowRemoveSuUserClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">{removeUserModelTitleSu} User</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <SeatOccupied
              rightImg=""
              leftImg=""
              isEmpty="true"
              ProfileAvatar={suInviteImage}
              memberHeading={suInviteName + " " + suInviteName1}
              memberSubheading={suInviteEmail}
            />
            {removeUserModelTitleSu === "Remove Support" ? (
              <p className="my-3 subtext">
                You are removing the above user from your Family.
              </p>
            ) : removeUserModelTitleSu === "Revoke Support" ? (
              <p className="my-3 subtext">
                You are revoking the above invite user from your Family.
              </p>
            ) : (
              <p className="my-3 subtext">
                You are removing the above user as support user from the family.
              </p>
            )}
            {removeUserModelTitleSu === "Remove Support" ? (
              <p className="mb-4 subtext">
                <strong>
                  Once the user is removed, they will not be able to access your
                  details in this family.
                </strong>
              </p>
            ) : removeUserModelTitleSu === "Revoke Support" ? (
              <p className="mb-4 subtext">
                <strong>
                  Once you revoke the invite, User will not going to be
                  Onboarded in to the family.
                </strong>
              </p>
            ) : (
              <p className="mb-4 subtext">
                <strong>
                  Once you remove the user as SU,User will not be able to access
                  the user's details in this family.
                </strong>
              </p>
            )}
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowRemoveSuUserClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() =>
                    handleCancelSuInvite(suInviteId, removeUserModelTitleSu)
                  }
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* View support user details */}
        <Modal
          show={showSupportUserDetail}
          onHide={handleShowSupportUserDetailClose}
          className="view_details_modal"
        >
          <Modal.Header closeButton className="animesh">
            <Modal.Title className="mx-1">
              <div
                className={
                  suDetail.firstName === "null" && suDetail.lastName === "null"
                    ? "new-dsgn-1"
                    : "new-dsgn-2"
                }
              >
                <span className="">
                  <Image
                    className="profile-avatar"
                    src={suDetail.avatar ? suDetail.avatar : ProfileAvatar}
                    alt="Profile Avatar"
                  />
                </span>
                <span style={{ "margin-left": "1rem" }}>
                  <h3
                    className={
                      suDetail.firstName === "null" &&
                      suDetail.lastName === "null"
                        ? ""
                        : "po-1"
                    }
                  >
                    {suDetail.firstName === "null" ? "" : suDetail.firstName}{" "}
                    {suDetail.lastName === "null" ? "" : suDetail.lastName}
                  </h3>
                  <p className="">{suDetail.email}</p>
                </span>
              </div>
              <div className="pos-2">
                <small>
                  User since
                  {suDetail
                    ? moment(suDetail.createdAt).format(" MMM YYYY")
                    : " - "}
                </small>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1 mb-5">
            {loadVal ? (
              <p
                style={{
                  "margin-bottom": "6rem",
                  "margin-top": "5rem",
                }}
              >
                <Loading />
              </p>
            ) : (
              <div>
                {userListingForSuModel.length !== 0 ? (
                  <p className="mb-0">
                    {" "}
                    Main users assigned to this support user
                  </p>
                ) : userListingForSuModel.length === 0 &&
                  inviteStat === "Acceptance Pending" ? (
                  <p className="mb-0">
                    {" "}
                    Support User didn’t accept the invite yet.
                  </p>
                ) : (
                  <p className="mb-0">
                    {" "}
                    No Main Users available for this Support User yet.
                  </p>
                )}
                {userListingForSuModel.length !== 0 ? (
                  userListingForSuModel.map((suList, index) => (
                    <span key={index}>
                      {suList.supportingUserId.inviteStatus === "Onboarded" ? (
                        <SeatOccupied
                          rightImg=""
                          leftImg=""
                          isEmpty="true"
                          ProfileAvatar={suList.supportingUserId.User.avatar}
                          memberHeading={`${
                            suList.supportingUserId.User.firstName === "null"
                              ? ""
                              : suList.supportingUserId.User.firstName
                          } ${" "}${
                            suList.supportingUserId.User.lastName === "null"
                              ? ""
                              : suList.supportingUserId.User.lastName
                          }`}
                          memberSubheading={suList.supportingUserId.User.email}
                        />
                      ) : suList.supportingUserId.inviteStatus ===
                          "Onboarded" ||
                        suList.Family_User.invitedBy === null ? (
                        ""
                      ) : suList.supportingUserId.inviteStatus === "Removed" ||
                        suList.Family_User.invitedBy !== null ? (
                        ""
                      ) : suList.supportingUserId.inviteStatus === "Removed" ||
                        suList.Family_User.invitedBy === null ? (
                        ""
                      ) : (
                        ""
                      )}
                    </span>
                  ))
                ) : (
                  <div className="text-center mt-4 mb-4">
                    <Image
                      className="envelop"
                      src={SuAUEmptyImage}
                      alt="Envelop image"
                    />
                  </div>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>
        {/* choose support user */}
        <Modal
          show={showChooseSu}
          onHide={handleShowChooseSuClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Add Support Users</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-1 subtext">
              Select all the support users whom you want to have permission to
              access your details
            </p>
            <p className="my-3">Support Users in your Family</p>
            <form className="invite-form" onSubmit={handleChooseSuSubmit}>
              {newSU.length !== 0 ? (
                newSU.map((suList, index) => (
                  <AddSupportUserBasic
                    userName={
                      suList.User.firstName + " " + suList.User.lastName
                    }
                    userEmail={suList.User.email}
                    variant="basic"
                    key={index}
                    value={suList.User.id}
                    onChange={handleChooseSuChange}
                  />
                ))
              ) : (
                <div className="text-center mt-4 mb-4">
                  {" "}
                  <p>No data found</p>{" "}
                </div>
              )}

              <div className="text-center mt-4 mb-4">
                <Primary
                  children="Send Invite"
                  variant="primary"
                  size="mediumbtn"
                  type="submit"
                  buttonWidth="150px"
                  disabled={isChooseInviteDisabled ? "disabled" : ""}
                  isLoading={chooseSuFormLoading ? "true" : "false"}
                  isDisabled={chooseSuFormLoading ? "true" : "false"}
                />
                {/* {chooseSuFormLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* invite support users */}
        <Modal
          show={showInviteSu}
          onHide={handleShowInviteSuClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Invite Support User </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-1 subtext">
              Enter the email address of the user whom you want to add as a
              Support User for you
            </p>
            <form className="invite-form" onSubmit={handleSuInviteSubmit}>
              <label
                className={
                  formErrors.firstname
                    ? "invalid-text form-label mt-3 mb-0"
                    : "form-label mt-3 mb-0"
                }
              >
                Firstname
              </label>
              <Small
                type="test"
                errorClass={formErrors.firstname ? "invalid-input" : ""}
                placeholder="John"
                size="medium"
                name="firstname"
                value={formValues.firstname}
                onChange={handleSuInviteChange}
              />
              <label
                className={
                  formErrors.lastname
                    ? "invalid-text form-label mt-3 mb-0"
                    : "form-label mt-3 mb-0"
                }
              >
                Lastname
              </label>
              <Small
                type="test"
                errorClass={formErrors.lastname ? "invalid-input" : ""}
                placeholder="Walker"
                size="medium"
                name="lastname"
                value={formValues.lastname}
                onChange={handleSuInviteChange}
              />

              <label
                className={
                  formErrors.lastname
                    ? "invalid-text form-label mt-3 mb-0"
                    : "form-label mt-3 mb-0"
                }
              >
                Email
              </label>
              <Small
                type="email"
                errorClass={formErrors.email ? "invalid-input" : ""}
                placeholder="jwalker@gmail.com"
                size="medium"
                name="email"
                value={formValues.email}
                onChange={handleSuInviteChange}
              />

              <div className="text-center mt-4 mb-4">
                <Primary
                  children="Send Invite"
                  variant="primary"
                  size="mediumbtn"
                  type="submit"
                  buttonWidth="150px"
                  isLoading={formLoading ? "true" : "false"}
                  isDisabled={formLoading ? "true" : "false"}
                />
                {/* {formLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Update Family Name Modal */}
        <Modal
          show={showUpdateFmlyModal}
          onHide={handleUpdateFmlyClose}
          className="choose_plan_modal"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title><h3>Confirm Account plan</h3></Modal.Title>*/}
            <Modal.Title>
              <h3>Update Family Name</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="family-form" onSubmit={handleFamilyNameSubmit}>
              {/* <p className="mb-1 subtext">Verify the chosen plan and proceed to payment.</p>
                        <p className="subtext">Changed your mind? <Link to="/subscription" className="modal-link" onClick={handleSwitchPlan}> Choose another plan </Link></p> */}
              {Object.keys(formErrors).length !== 0 && isUpdateFmlySubmit ? (
                <p className="error-txt-msg">{formErrors.msg} </p>
              ) : (
                ""
              )}
              <Form.Group className="mb-3" controlId="">
                <Form.Label
                  className={
                    formErrors.familyName
                      ? "invalid-text mt-3 mb-0"
                      : "mt-3 mb-0"
                  }
                >
                  Family Name
                </Form.Label>
                <Medium
                  type="test"
                  errorClass={
                    updateFmlyFormErrors.family_name ? "invalid-input" : ""
                  }
                  placeholder="Enter Family Name"
                  size="medium"
                  name="family_name"
                  value={updateFmlyFormValues.family_name}
                  onChange={handleUpdateFmlyChange}
                  maxlength="40"
                />
                <p
                  className={
                    updateFmlyFormErrors.family_name
                      ? "invalid-text subtext mt-1"
                      : "subtext mt-1"
                  }
                >
                  Add a name to your family on EL platform. It should be under
                  40 characters.
                </p>
              </Form.Group>
              <div className="text-center mt-5">
                <Primary
                  children="Update"
                  variant="primary"
                  size="mediumbtn"
                  type="submit"
                  buttonWidth="230px"
                  isLoading={formLoading ? "true" : "false"}
                  isDisabled={formLoading ? "true" : "false"}
                />
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* main user new dsgn */}
        <Modal show={mainUser} onHide={mainUserClose} className="" size="xl">
          <Modal.Body className="mx-1 my-1">
            {mainLoading ? (
              <div className="mb-5">
                <Loading />
              </div>
            ) : (
              <Row>
                <Col lg="6" className="modal-body-line">
                  <div className="my-5">
                    <div className="famDash-modal1">Main User</div>
                    {console.log(mainLeft)}
                    <div className="famDash-modal1-box">
                      <div>
                        <span>
                          {mainLeft.User &&
                            (mainLeft.User.firstName === "null"
                              ? mainLeft.User.email
                              : mainLeft.User.firstName)}
                        </span>
                        <span className="mx-2">
                          {mainLeft.User &&
                            (mainLeft.User.lastName === "null"
                              ? ""
                              : mainLeft.User.lastName)}
                        </span>
                      </div>
                      <div>
                        <span>Joined:</span>{" "}
                        <span>
                          {moment(mainLeft.User && mainLeft.createdAt).format(
                            "DD MMM YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="">
                  <div className="my-5">
                    <div className="famDash-modal1">Support User</div>
                    {mainRight &&
                      mainRight.map((list, index) => (
                        <div className="famDash-modal1-box">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <span>
                                {list.Family_User &&
                                  (list.Family_User.User.firstName === "null"
                                    ? list.Family_User.User.email
                                    : list.Family_User.User.firstName)}
                              </span>
                              <span className="mx-2">
                                {list.Family_User &&
                                list.Family_User.User.lastName === "null"
                                  ? ""
                                  : list.Family_User.User.lastName}
                              </span>
                            </div>
                            <div className="delete-iconmodal">
                              <Image
                                className=""
                                src={DeleteIcon}
                                alt="DeleteIcon"
                                onClick={() =>
                                  list.Family_User.inviteStatus === "Onboarded"
                                    ? handleShowRemoveSuUserShow(
                                        list,
                                        "Remove Support"
                                      )
                                    : handleShowRemoveSuUserShow(
                                        list,
                                        "Revoke Support"
                                      )
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <span>Started Supporting from:</span>{" "}
                            <span>
                              {moment(list.User && list.createdAt).format(
                                "DD MMM YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    {/* <div className="famDash-modal2">Add Support User</div> */}
                  </div>
                  <div className="my-5">
                    {userType === "AU" ? (
                      <div>
                        {familyDetails.FamilyData.isAo === true &&
                        familyDetails.FamilyData.isAu === false &&
                        familyDetails.FamilyData.isSu === false ? (
                          ""
                        ) : familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === true &&
                          familyDetails.FamilyData.isSu === false ? (
                          occupiedSuSeatCount < planDetails.availableS &&
                          newSU.length === 0 ? (
                            <span
                              className="manageuser-btn"
                              onClick={handleShowInviteSuShow}
                            >
                              Add Support User
                            </span>
                          ) : occupiedSuSeatCount >= planDetails.availableS &&
                            newSU.length === 0 ? (
                            ""
                          ) : (
                            <Accordion>
                              <Accordion.Item
                                style={{ width: "14rem" }}
                                eventKey="0"
                              >
                                <Accordion.Header>
                                  Add Support User
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {suAvatarArr.length > 0 ? (
                                      <li onClick={handleShowChooseSuShow}>
                                        Choose existing Support Users
                                      </li>
                                    ) : (
                                      ""
                                    )}

                                    {occupiedSuSeatCount >
                                      planDetails.availableS ||
                                    occupiedSuSeatCount ===
                                      planDetails.availableS ? (
                                      " "
                                    ) : (
                                      <li onClick={handleShowInviteSuShow}>
                                        Invite a new Support User
                                      </li>
                                    )}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        ) : familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === true ? (
                          ""
                        ) : familyDetails.FamilyData.isAo === true &&
                          familyDetails.FamilyData.isAu === true &&
                          familyDetails.FamilyData.isSu === true ? (
                          occupiedSuSeatCount < planDetails.availableS &&
                          newSU.length === 0 ? (
                            <span
                              className="manageuser-btn"
                              onClick={handleShowInviteSuShow}
                            >
                              Add Support User
                            </span>
                          ) : occupiedSuSeatCount >= planDetails.availableS &&
                            newSU.length === 0 ? (
                            ""
                          ) : (
                            <Accordion>
                              <Accordion.Item
                                style={{ width: "14rem" }}
                                eventKey="0"
                              >
                                <Accordion.Header>
                                  Add Support User
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {newSU.length > 0 ? (
                                      <li onClick={handleShowChooseSuShow}>
                                        Choose existing Support Users
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {occupiedSuSeatCount >
                                      planDetails.availableS ||
                                    occupiedSuSeatCount ===
                                      planDetails.availableS ? (
                                      " "
                                    ) : (
                                      <li onClick={handleShowInviteSuShow}>
                                        Invite a new Support User
                                      </li>
                                    )}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        ) : familyDetails.FamilyData.isAo === false &&
                          familyDetails.FamilyData.isAu === true &&
                          familyDetails.FamilyData.isSu === true ? (
                          occupiedSuSeatCount < planDetails.availableS &&
                          newSU.length === 0 ? (
                            <span
                              className="manageuser-btn"
                              onClick={handleShowInviteSuShow}
                            >
                              Add Support User
                            </span>
                          ) : occupiedSuSeatCount >= planDetails.availableS &&
                            newSU.length === 0 ? (
                            ""
                          ) : (
                            <Accordion>
                              <Accordion.Item
                                style={{ width: "14rem" }}
                                eventKey="0"
                              >
                                <Accordion.Header>
                                  Add Support User
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {newSU.length > 0 ? (
                                      <li onClick={handleShowChooseSuShow}>
                                        Choose existing Support Users
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {occupiedSuSeatCount >
                                      planDetails.availableS ||
                                    occupiedSuSeatCount ===
                                      planDetails.availableS ? (
                                      " "
                                    ) : (
                                      <li onClick={handleShowInviteSuShow}>
                                        Invite a new Support User
                                      </li>
                                    )}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        ) : familyDetails.FamilyData.isAo === false &&
                          familyDetails.FamilyData.isAu === true &&
                          familyDetails.FamilyData.isSu === false ? (
                          occupiedSuSeatCount < planDetails.availableS &&
                          newSU.length === 0 ? (
                            <span
                              className="manageuser-btn"
                              onClick={handleShowInviteSuShow}
                            >
                              Add Support User
                            </span>
                          ) : occupiedSuSeatCount >= planDetails.availableS &&
                            newSU.length === 0 ? (
                            ""
                          ) : (
                            <Accordion>
                              <Accordion.Item
                                style={{ width: "14rem" }}
                                eventKey="0"
                              >
                                <Accordion.Header>
                                  Add Support User
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {newSU.length > 0 ? (
                                      <li onClick={handleShowChooseSuShow}>
                                        Choose existing Support Users
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {occupiedSuSeatCount >
                                      planDetails.availableS ||
                                    occupiedSuSeatCount ===
                                      planDetails.availableS ? (
                                      " "
                                    ) : (
                                      <li onClick={handleShowInviteSuShow}>
                                        Invite a new Support User
                                      </li>
                                    )}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        ) : familyDetails.FamilyData.isAo === false &&
                          familyDetails.FamilyData.isAu === false &&
                          familyDetails.FamilyData.isSu === true ? (
                          ""
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>

        {/* support user new dsgn */}
        <Modal
          show={supportUser}
          onHide={supportUserClose}
          className=""
          size="xl"
        >
          <Modal.Body className="mx-1 my-1">
            {suppLoading ? (
              <div className="mb-5">
                <Loading />
              </div>
            ) : (
              <Row>
                <Col lg="6" className="modal-body-line">
                  <div className="my-5">
                    <div className="famDash-modal1">Support User</div>
                    <div className="famDash-modal1-box">
                      <div>
                        <span>
                          {console.log(suppLeft)}
                          {suppLeft.User
                            ? suppLeft.User.firstName === "null"
                              ? suppLeft.User.email
                              : suppLeft.User.firstName
                            : ""}
                        </span>
                        <span className="mx-2">
                          {suppLeft.User
                            ? suppLeft.User.lastName === "null"
                              ? ""
                              : suppLeft.User.lastName
                            : ""}
                        </span>
                      </div>
                      <div>
                        <span>Joined:</span>{" "}
                        <span>
                          {moment(suppLeft.User && suppLeft.createdAt).format(
                            "DD MMM YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="my-5">
                    <div className="famDash-modal1">
                      users you are supporting
                    </div>
                    {suppRight &&
                      suppRight.map((list, index) => (
                        <div className="famDash-modal1-box">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <span>
                                {list.supportingUserId &&
                                list.supportingUserId.User.firstName === "null"
                                  ? list.supportingUserId.User.email
                                  : list.supportingUserId.User.firstName}
                              </span>
                              <span className="mx-2">
                                {list.supportingUserId &&
                                list.supportingUserId.User.lastName === "null"
                                  ? ""
                                  : list.supportingUserId.User.lastName}
                              </span>
                            </div>
                            <div className="delete-iconmodal">
                              <Image
                                className=""
                                src={DeleteIcon}
                                alt="DeleteIcon"
                                // onClick={() =>
                                //   list.Family_User.inviteStatus === "Onboarded"
                                //     ? handleShowRemoveSuUserShow(
                                //         list,
                                //         "Remove Support"
                                //       )
                                //     : handleShowRemoveSuUserShow(
                                //         list,
                                //         "Revoke Support"
                                //       )
                                // }
                              />
                            </div>
                          </div>
                          <div>
                            <span>Started Supporting from:</span>{" "}
                            <span>
                              {moment(
                                list.supportingUserId &&
                                  list.supportingUserId.createdAt
                              ).format("DD MMM YYYY")}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};
export default FamilyDashboard;
