import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import OptionsIcon from "../../assets/images/options_icon.png";

function MiniPaymentMtdCard({
  showOptions,
  border,
  paymentMethod,
  deleteCard,
}) {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const optionsIcon = useRef();
  const mtdOptionsModal = useRef();

  useEffect(() => {
    document.addEventListener("click", function (event) {
      //function to detect click inside and outside of modal

      // var OptionsModal = document.getElementsByClassName('imagepicker-modal')[0];
      // var addIcon = document.getElementsByClassName('imagepicker-addicon')[0];

      var isClickInsideModal = mtdOptionsModal?.current?.contains(event.target);
      var isClickInsideAddIcon = optionsIcon?.current?.contains(event.target);

      if (!isClickInsideModal && !isClickInsideAddIcon) {
        setOptionsVisible(false);
      } else {
        setOptionsVisible(true);
      }
    });
  }, []);

  localStorage.setItem("hello", paymentMethod.card.last4);

  return (
    <div
      className={border ? "outlined-card mb-4" : "mini-paymentmtd-card mb-4"}
    >
      <Row className="p-3 px-4 align-items-center">
        <Col className="col-auto ">
          <p className="mt-0 mb-1 title-heading">
            **** **** **** {paymentMethod.card.last4}
          </p>
          <p className="mt-0 mb-0 font-small text-black">
            {paymentMethod.billing_details.name}
          </p>
          <p className="mt-0 mb-0 font-small  text-black">
            Expires on {paymentMethod.card.exp_month}/
            {paymentMethod.card.exp_year}
          </p>
        </Col>
        <Col className="p-0 mr-3 justify-flex-end">
          <div className="visa-logo align-items-center">
            {paymentMethod.card.brand}
          </div>
        </Col>
        {showOptions && (
          <Col ref={optionsIcon} className="p-0 col-auto">
            <img className="options-icon" alt="Options" src={OptionsIcon} />
          </Col>
        )}
      </Row>
      {optionsVisible && (
        <div
          ref={mtdOptionsModal}
          className="imagepicker-modal paymentmtd-options-modal"
        >
          <span>Use this card</span>
          <span>Delete card</span>
        </div>
      )}
    </div>
  );
}

export default MiniPaymentMtdCard;
