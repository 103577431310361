import React from "react";
import SeatCard from "../components/Cards/SeatCard";

export default {
  title: "Cards/Seats",
  component: SeatCard,
  args: {
    message: "ActionAlert message",
  },
};

const Template = (args) => <SeatCard {...args} />;

export const SeatEmpty = Template.bind({});
SeatEmpty.args = {
  heading: "Main User ( PU ) in Group",
  subheading: "0 /5 users are occupied",
  rightImg: "",
  leftImg: "",
  areEmpty: true,
  memberHeading: "+ Invite a new user",
  memberSubheading: "via email",
  variant: "basic",
};

export const SeatOccupied = Template.bind({});
SeatOccupied.args = {
  heading: "Main User ( PU ) in Group",
  subheading: "0 /5 users is occupied",
  rightImg: "",
  leftImg: "",
  isEmpty: false,
  memberHeading: "Elsa Walker",
  memberSubheading: "ewalker@gmail.com",
  variant: "basic",
  dropdownItem1: "Remove User",
  dropdownItem2: "Assign myself as SU",
  memberStatus: "",
};
