import React from "react"
import TaskListCard from '../components/Cards/TaskListCard'

export default {
    title: 'Cards/List',
    component: TaskListCard,
    args: {
        message: 'ActionAlert message',

    },
}

const Template = args => <TaskListCard {...args} />

export const TaskList = Template.bind({})
TaskList.args = {
    name: "Prescriptions",
    email: "Pickup my medicines in the prescription from the pharmacy",
    date: "01/01/2021",
    profileImage: "",
    assigneeName: "Robert",
    variant: 'basic'
}