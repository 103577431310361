import React from "react";
import { Image, Card } from "react-bootstrap";
import "./Cards.scss";
import BlueArrow from "../../assets/images/right_arrow_blue.svg";
import RedArrow from "../../assets/images/right_arrow_red.svg";
import CalendarIcon from "../../assets/images/calendar-icon.svg";
import CalendarIconRed from "../../assets/images/calendar-icon-red.svg";
import RecurringIcon from "../../assets/images/recurring-icon.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function TaskListCard(props) {
  const {
    name,
    email,
    date,
    profileImage,
    variant,
    buttonClick,
    dropdownItem1,
    onClickItem1,
    detailLink,
    isRecurring,
    assigneeName,
    expired,
    isExpired,
    onClickItem,
    ...rest
  } = props;
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Reccurring Task
    </Tooltip>
  );

  return (
    <div>
      <Card
        className={`story-card supportUser-cards ${variant}-cards ${expired}`}
      >
        <Card.Body className="p-1">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {isExpired === "true" ? (
                <Image
                  className="mr-2"
                  src={CalendarIconRed}
                  alt="Vertical Dots"
                />
              ) : (
                <Image
                  className="mr-2"
                  src={CalendarIcon}
                  alt="Vertical Dots"
                />
              )}
              <span className="date-txt">{date}</span>
            </div>
            <div>
              <span className="tag-txt">{assigneeName}</span>
              <div
                className="ml-3"
                style={{ width: "25px", display: "inline-block" }}
              >
                {isRecurring === "true" ? (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <Image src={RecurringIcon} alt="Recurring" />
                  </OverlayTrigger>
                ) : (
                  " "
                )}
              </div>
            </div>
          </div>
          <span className="d-flex justify-content-between">
            <div style={{ width: "266px" }}>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    {name}
                  </Tooltip>
                }
              >
                <h3 className="card-title">{name}</h3>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    {email}
                  </Tooltip>
                }
              >
                <p className="subtitle">{email}</p>
              </OverlayTrigger>
            </div>
            <span className="side-arrow action-right">
              {/* <Link to={detailLink}> */}
              {isExpired === "true" ? (
                <Image src={RedArrow} onClick={onClickItem} />
              ) : (
                <Image src={BlueArrow} onClick={onClickItem} />
              )}
              {/* </Link> */}
            </span>

            {/* <p className="card-subtitle">Since {date}</p> */}
          </span>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TaskListCard;
