import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import HelloText from "../../assets/images/hello-text.svg";
import TaskEmpty from "../../assets/images/task-empty.png";
import moment from "moment";
import { Primary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import MagnifierIcon from "../../assets/images/magnifier.png";
import removeSearch from "../../assets/images/search_remove.png";
import { TaskList } from "../../stories/TaskListCard.stories";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../common/Loading";
import layer from "../../assets/images/layer1.svg";

const Tasks = (props) => {
  let history = useHistory();
  let { uuid, userUUID, ParentId } = useParams();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var userType = localStorage.getItem("userType");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  const filterOption = [
    { value: "all", label: "All" },
    { value: "status", label: "Status" },
    { value: "recurrence", label: "Recurring status" },
    { value: "frequency", label: "Frequency" },
  ];
  const filterOption1 = [
    { value: "Active", label: "Active" },
    { value: "Expired", label: "Expired" },
    { value: "Completed", label: "Completed" },
  ];
  const filterOption2 = [
    { value: "true", label: "Recurring" },
    { value: "false", label: "Non-Recurring" },
  ];
  const filterOption3 = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Bi-Annually", label: "Bi-Annually" },
    { value: "Yearly", label: "Yearly" },
  ];
  const sortOption = [
    //Expired, Completed and Active
    { value: "eta", label: "Activity Date" },
    { value: "createdAt", label: "Creation date" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
      height: "3.75rem",
      "padding-left": "0.5rem",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      "z-index": "2",
    }),
  };

  const [familyDetails, setFamilyDetails] = useState([]);
  const [userTaskList, setUserTaskList] = useState([]);
  const [parentUser, setParentUser] = useState();
  const [addTaskUrl, setAddTaskUrl] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [showSearchText, setShowSearchText] = useState(false);
  const [title, setTitle] = useState("My To Do's");
  const [subTitle, setSubTitle] = useState(
    "You can track all your To Do's on Elderly Life platform here."
  );
  const [backText, setBackText] = useState("Back to Group Dashboard");
  const [backUrl, setBackUrl] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [filtervalue, setfiltervalue] = useState("all");
  const [filtervalue1, setfiltervalue1] = useState();
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [familyUserId, setFamilyUserId] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const [subCategoryOption, setSubCategoryOption] = useState([]);

  const [sortBy, setSortBy] = useState("eta");
  const [orderBy, setOrderBy] = useState("DESC");
  const [freqVal, setFreqVal] = useState("");
  const [statusVal, setStatusVal] = useState("");
  const [reqVal, setReqVal] = useState("");

  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        setParentUser(response.userData);
        setAddTaskUrl("/add_user_todos/" + uuid + "/" + userUUID);
        setBackUrl("/supporting_user_dashboard/" + uuid + "/" + userUUID);

        const abc =
          pUser.firstName === "null"
            ? pUser.email
            : pUser.firstName + " " + pUser.lastName === "null"
            ? ""
            : pUser.lastName;
        setTitle(`${abc}'s To Do's`);
        setSubTitle(`You can track all To Do's of ${abc} here`);
        // setBackText(
        //   "Back to " + pUser.firstName + " " + pUser.lastName + "’s EL overview"
        // );
      });
    } else {
      setAddTaskUrl("/add_todos/" + uuid);
      getFamilyDetails(UserDetails.id);
      setBackText("Back to Group Dashboard");
      setBackUrl("/family_dashboard/" + uuid);
    }
  }, []);

  // Get Family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        let famUserId = response.FamilyData.id;
        // get task list
        let params = {
          userId: famUserId,
          familyId: response.FamilyData.Family.id,
          searchStr: searchStr,
          sort: sortBy,
          order: orderBy,
          limit: 10,
          offset: 0,
          freq: freqVal,
          statusVal: statusVal,
          req: reqVal,
        };
        UserService.getUserTaskList(params).then(
          (response) => {
            setUserTaskList(response.data);
            let nextOffset = 10;
            setDataLength(response.data.length);

            if (response.data.length === 0) {
              setIsEmpty(true);
              setLoadingspinner(false);
              setOffset(0);
              setHasMore(false);
            } else {
              setIsEmpty(false);
              setLoadingspinner(false);
              if (response.data.length >= Number(limit)) {
                setOffset(nextOffset);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            }
            setLoading(false);
          },
          (error) => {}
        );

        // get stats
        setFamilyUserId(famUserId);
      },
      (error) => {}
    );
  };
  const viewDetailsRoute = (ParentId, TaskId) => {
    localStorage.setItem("taskID", TaskId);
    if (userUUID) {
      history.push(`/view_user_todos_details/${uuid}/${TaskId}/${userUUID}`);
    } else {
      history.push(`/view_todos_details/${uuid}/${ParentId}`);
    }
  };

  // Get task List
  const getTaskList = (params) => {
    setLoading(true);
    UserService.getUserTaskList(params).then(
      (response) => {
        setUserTaskList(response.data);
        setLoading(false);
        let nextOffset = Number(Number(params.offset) + Number(params.limit));

        if (Number(response.data.length) >= Number(params.limit)) {
          setOffset(nextOffset);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      },
      (error) => {}
    );
  };

  // Get more taks List
  const moreTasks = () => {
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchStr,
      sort: sortBy,
      order: orderBy,
      limit: 10,
      offset: offset,
      freq: freqVal,
      statusVal: statusVal,
      req: reqVal,
    };
    UserService.getUserTaskList(params).then(
      (response) => {
        setUserTaskList([...userTaskList, ...response.data]);
        setDataLength(response.data.length + userTaskList.length);

        let nextOffset = Number(offset) + Number(limit);
        if (response.data.length + userTaskList.length >= nextOffset) {
          setOffset(nextOffset);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      },
      (error) => {}
    );
  };

  //onchange in filter by & filter by value--
  const taskFilter = (e) => {
    setfiltervalue(e.value);
    setfiltervalue1(e.value);
    setOffset(0);
    setLimit(10);
    var targetData = { name: "", value: "" };
    let filterVal = e.value;
    let filterParam;
    const { name, value } = targetData;
    //filter by values with help of filter by
    if (
      e.value === "status" &&
      e.value !== "all" &&
      e.value !== "recurrence" &&
      e.value !== "frequency"
    ) {
      let catId = value;
      setSubCategoryOption(filterOption1);
    }
    if (
      e.value !== "status" &&
      e.value !== "all" &&
      e.value === "recurrence" &&
      e.value !== "frequency"
    ) {
      let catId = value;
      setSubCategoryOption(filterOption2);
    }
    if (
      e.value !== "status" &&
      e.value !== "all" &&
      e.value !== "recurrence" &&
      e.value === "frequency"
    ) {
      let catId = value;
      setSubCategoryOption(filterOption3);
    }
    //api call
    setOffset(0);
    setLimit(10);
    if (filterVal === "all") {
      setFreqVal("");
      setStatusVal("");
      setReqVal("");
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchStr,
        sort: sortBy,
        order: orderBy,
        limit: 10,
        offset: 0,
        freq: "",
        statusVal: "",
        req: "",
      };
      setLoading(true);
      UserService.getUserTaskList(params).then(
        (response) => {
          setUserTaskList([...response.data]);
          setLoading(false);
          let nextOffset = 10;
          setDataLength(response.data.length);

          if (response.data.length === 0) {
            setLoadingspinner(false);
            setOffset(0);
            setHasMore(false);
          } else {
            setLoadingspinner(false);
            if (response.data.length >= Number(limit)) {
              setOffset(nextOffset);
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          }
        },
        (error) => {}
      );
    }

    if (
      filterVal === "Active" ||
      filterVal === "Completed" ||
      filterVal === "Expired"
    ) {
      setStatusVal(filterVal);

      setFreqVal("");
      setStatusVal(filterVal);
      setReqVal("");
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchStr,
        sort: sortBy,
        order: orderBy,
        limit: 10,
        offset: 0,
        freq: "",
        statusVal: filterVal,
        req: "",
      };
      setLoading(true);
      UserService.getUserTaskList(params).then(
        (response) => {
          setUserTaskList([...response.data]);
          setLoading(false);
          let nextOffset = 10;
          setDataLength(response.data.length);

          if (response.data.length === 0) {
            setLoadingspinner(false);
            setOffset(0);
            setHasMore(false);
          } else {
            setLoadingspinner(false);
            if (response.data.length >= Number(limit)) {
              setOffset(nextOffset);
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          }
        },
        (error) => {}
      );
    }

    if (filterVal === "true" || filterVal === "false") {
      setReqVal(filterVal);

      setFreqVal("");
      setStatusVal("");
      setReqVal(filterVal);
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchStr,
        sort: sortBy,
        order: orderBy,
        limit: 10,
        offset: 0,
        freq: "",
        statusVal: "",
        req: filterVal,
      };
      setLoading(true);
      UserService.getUserTaskList(params).then(
        (response) => {
          setUserTaskList([...response.data]);
          setLoading(false);
          let nextOffset = 10;
          setDataLength(response.data.length);

          if (response.data.length === 0) {
            setLoadingspinner(false);
            setOffset(0);
            setHasMore(false);
          } else {
            setLoadingspinner(false);
            if (response.data.length >= Number(limit)) {
              setOffset(nextOffset);
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          }
        },
        (error) => {}
      );
    }

    if (
      filterVal === "Daily" ||
      filterVal === "Monthly" ||
      filterVal === "Weekly" ||
      filterVal === "Quarterly" ||
      filterVal === "Bi-Annually" ||
      filterVal === "Yearly"
    ) {
      setFreqVal(filterVal);

      setFreqVal(filterVal);
      setStatusVal("");
      setReqVal("");
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchStr,
        sort: sortBy,
        order: orderBy,
        limit: 10,
        offset: 0,
        freq: filterVal,
        statusVal: "",
        req: "",
      };
      setLoading(true);
      UserService.getUserTaskList(params).then(
        (response) => {
          setUserTaskList([...response.data]);
          setLoading(false);
          let nextOffset = 10;
          setDataLength(response.data.length);

          if (response.data.length === 0) {
            setLoadingspinner(false);
            setOffset(0);
            setHasMore(false);
          } else {
            setLoadingspinner(false);
            if (response.data.length >= Number(limit)) {
              setOffset(nextOffset);
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          }
        },
        (error) => {}
      );
    }
  };
  // Sort tasks
  const taskSort = (e) => {
    let _sortBy = e.value;
    setSortBy(e.value);
    setLoading(true);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchStr,
      sort: _sortBy,
      order: "DESC",
      limit: 10,
      offset: 0,
      freq: freqVal,
      statusVal: statusVal,
      req: reqVal,
    };
    getTaskList(params);
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    setShowSearchText(false);
    setLoading(true);
    setSearchStr("");
    setOffset(0);
    setLimit(10);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: "",
      sort: sortBy,
      order: orderBy,
      limit: 10,
      offset: 0,
      freq: freqVal,
      statusVal: statusVal,
      req: reqVal,
    };
    getTaskList(params);
  };

  //search box
  useEffect(() => {
    if (familyDetails.FamilyData != undefined) {
      if (searchStr.length === 0) {
        // clearSearch();
        setShowSearchText(false);
        setLoading(true);
        setSearchStr("");
        setOffset(0);
        setLimit(10);
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: "",
          sort: sortBy,
          order: orderBy,
          limit: 10,
          offset: 0,
          freq: freqVal,
          statusVal: statusVal,
          req: reqVal,
        };
        getTaskList(params);
      }
    }
  }, [searchStr]);

  // Search task
  const taskSearch = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setShowSearchText(true);
      setLoading(true);
      let srchStr = e.target.value;
      setSearchStr(e.target.value);
      if (srchStr === "") {
        clearSearch(e);
      } else {
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: srchStr,
          sort: sortBy,
          order: orderBy,
          limit: 10,
          offset: 0,
          freq: freqVal,
          statusVal: statusVal,
          req: reqVal,
        };
        getTaskList(params);
      }
    }
  };

  var uuidDetails = localStorage.getItem("uuid");
  var userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));

  const dashboardRed = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/family_dashboard/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  return (
    <div className="page-content warranty-pg">
      <Container fluid>
        {loadingspinner ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            <div className="Reminder-backBtn" onClick={() => dashboardRed()}>
              {" "}
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              {parentUserUUId === null ? (
                <span>Back to Group Dashboard</span>
              ) : (
                <span>Back to Main User Dashboard</span>
              )}
            </div>
            <Row>
              <Col md="8" lg="8">
                <h3>{title}</h3>
                <p className="sub-title">{subTitle}</p>
              </Col>
              <Col md="4" lg="4">
                {isEmpty ? (
                  ""
                ) : (
                  <span className="align-right">
                    <Link to={addTaskUrl} className="">
                      <Primary
                        children="+ Add New To Do"
                        size="mediumbtn"
                        variant="primary"
                        type=""
                        buttonWidth="220px"
                        font_Weight="500"
                      />
                    </Link>
                  </span>
                )}
              </Col>
            </Row>
            {isEmpty ? (
              <>
                <Row>
                  <Col md="6" lg="6" className="text-left my-4">
                    <p className="sub-text mb-4">
                      You currently have no To Do's set up!
                    </p>
                    <Link to={addTaskUrl} className="">
                      <Large
                        children="+ Add New To Do"
                        size="largebtn"
                        variant="primary"
                        type=""
                        buttonWidth="220px"
                        font_Weight="500"
                      />
                    </Link>
                  </Col>
                  <Col md="6" lg="6">
                    <Image
                      className="align-right empty-src-img"
                      src={TaskEmpty}
                      alt="warranty empty icon"
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="mb-5 subSearch-row">
                  <Col md="4" lg="4">
                    <div className="input-group search-input-group">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search To Do's"
                        value={searchStr}
                        onChange={(e) => setSearchStr(e.target.value)}
                        onKeyDown={taskSearch}
                      />
                      <div className="input-group-btn">
                        {!searchStr && (
                          <button
                            className="btn btn-default search-input-btn"
                            type="submit"
                          >
                            {/* <i className="glyphicon glyphicon-search"></i> */}
                            <Image src={MagnifierIcon} alt="magnifier icon" />
                          </button>
                        )}
                        {searchStr && (
                          <button
                            className="btn btn-default clear-search"
                            type="submit"
                            onClick={clearSearch}
                          >
                            {/* <i className="glyphicon glyphicon-search"></i> */}
                            <Image src={removeSearch} alt="Clear Search" />
                          </button>
                        )}
                      </div>
                    </div>
                    {showSearchText && searchStr && (
                      <div className="search-text mt-3">
                        Search Results for : <span>{searchStr}</span>
                      </div>
                    )}
                  </Col>

                  <Col md="8" lg="8">
                    <Row style={{ "flex-wrap": "inherit" }}>
                      <Col md="4" lg="4">
                        <div className="filter-label br-round    min-height-auto w-auto d-flex justify-content-between align-items-center">
                          <div
                            className="ps-2"
                            style={{ "margin-right": "1rem" }}
                          >
                            Sort by :
                          </div>
                          <div
                            className="p-0 filter-select"
                            style={{
                              "margin-right": "1rem",
                              width: "10rem",
                              flex: 1,
                            }}
                          >
                            <Select
                              className="todos-filterdrop"
                              styles={customStyles}
                              options={sortOption}
                              defaultValue={{
                                label: "Activity Date",
                                value: "eta",
                              }}
                              onChange={taskSort}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md="4" lg="4">
                        <div className="filter-label br-round min-height-auto w-auto d-flex justify-content-between align-items-center">
                          <div
                            className="ps-2"
                            style={{ "margin-right": "1rem" }}
                          >
                            Filter by :
                          </div>
                          <div
                            className="p-0 filter-select"
                            style={{
                              "margin-right": "1rem",
                              width: "10rem",
                              flex: "1",
                            }}
                          >
                            <Select
                              styles={customStyles}
                              options={filterOption}
                              defaultValue={{ label: "All", value: "all" }}
                              onChange={taskFilter}
                            />
                          </div>
                        </div>
                      </Col>

                      {filtervalue !== "all" && (
                        <>
                          <Col md="4" lg="4">
                            <div className="filter-label min-height-auto w-auto d-flex justify-content-between align-items-center">
                              <div
                                className="ps-2"
                                style={{ "margin-right": "1rem" }}
                              >
                                Filter by Values :
                              </div>
                              <div
                                className="p-0 filter-select"
                                style={{
                                  "margin-right": "1rem",
                                  width: "7rem",
                                  flex: 1,
                                }}
                              >
                                <Select
                                  styles={customStyles}
                                  options={subCategoryOption}
                                  defaultValue={{ label: "", value: "" }}
                                  onChange={taskFilter}
                                />
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>

                {userTaskList.length > 0 && !loading && (
                  <div className="subscription-list">
                    <InfiniteScroll
                      dataLength={dataLength}
                      next={moreTasks}
                      hasMore={hasMore}
                      loader={<Loading />}
                      style={{ overflow: "inherit" }}
                    >
                      <Row>
                        {" "}
                        {userTaskList.map((taskcard, index) => (
                          <Col md="6" lg="6" key={index}>
                            <TaskList
                              name={taskcard.name}
                              email={taskcard.details}
                              date={moment(taskcard.eta).format("MM/DD/YYYY")}
                              profileImage=""
                              assigneeName={
                                taskcard.assigneeId
                                  ? taskcard.assigneeId.User.firstName ===
                                    "null"
                                    ? taskcard.assigneeId.User.email
                                    : taskcard.assigneeId.User.firstName +
                                      " " +
                                      taskcard.assigneeId.User.lastName
                                  : ""
                              }
                              isRecurring={
                                taskcard.taskRecurrence == true
                                  ? "true"
                                  : "false"
                              }
                              isExpired={
                                taskcard.status == "Expired" ? "true" : "false"
                              }
                              expired={
                                taskcard.status == "Expired"
                                  ? "expired-task"
                                  : ""
                              }
                              onClickItem={() =>
                                viewDetailsRoute(taskcard.ParentId, taskcard.id)
                              }
                              variant="basic"
                            />
                          </Col>
                        ))}
                      </Row>
                    </InfiniteScroll>
                  </div>
                )}

                {loading && <Loading />}

                {userTaskList.length === 0 && !loading && !showSearchText && (
                  <Row>
                    <Col
                      md="12"
                      lg="12"
                      className="mt-5 pt-5 small-text text-center"
                    >
                      No data found
                    </Col>
                  </Row>
                )}
                {userTaskList.length === 0 &&
                  !loading &&
                  showSearchText &&
                  searchStr && (
                    <Row>
                      <Col
                        md="12"
                        lg="12"
                        className="mt-5 pt-5 small-text text-center"
                      >
                        <div className="empty-search-text-title">
                          There are no results for the search query
                        </div>
                        <div className="empty-search-text-sub-title">
                          Try searching to do's with modified terms
                        </div>
                      </Col>
                    </Row>
                  )}
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};
export default Tasks;
