import React, { useState, useEffect, useRef } from "react";
import "./ImagePicker.scss";
import { Container } from "react-bootstrap";
import PhotoCameraSvg from "../../assets/images/photo-camera.svg";
import ProfileAvatar from "../../assets/images/profile_avatar.svg";
import Modal from "react-bootstrap/Modal";
import { Info } from "../../stories/Button.stories";
import { Primary } from "../../stories/Button.stories";
import userService from "../../services/user.service";
import ToastBlockContainer from "../../common/ToastBlockContainer";

function ImagePicker({ setProfileModalOpen, newProPicImg }) {
  const myRef = useRef(null);
  const DEFAULT_AVATAR =
    "https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png";
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [hello, setHello] = useState(false);
  // view modal
  const [showPayment, setShowPayment] = useState(false);
  const handlePaymentClose = () => setShowPayment(false);
  const handlePaymentShow = () => {
    setShowPayment(true);
  };
  const [showDelete, setShowDelete] = useState(false);
  // Delete confirmation
  const handleShowDeleteShow = (id) => {
    setShowDelete(true);
  };
  // Close delete confirmation
  const handleShowDeleteClose = () => {
    setShowDelete(false);
  };
  // Delete task
  const handleDeleteSubscription = () => {
    // setShowDelete(false);

    var data = {
      avatar: DEFAULT_AVATAR,
    };
    userService.updateUserProfile(UserDetails.uuid, data).then(
      (res) => {
        UserDetails.avatar = res.userData[1].avatar;
        setLoading(false);
        setShowDelete(false);
        setHello(true);
        localStorage.setItem("UserDetails", JSON.stringify(UserDetails));
        setShowToast(true);
        setSuccessToast(true);
        setToastMessage("file deleted successfully");
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      },
      (error) => {
        const errors = {};
        //   setFormErrors({ msg: error.response.data });
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    document.addEventListener("click", function (event) {
      //function to detect click inside and outside of modal

      var imagePickerModal = document.getElementsByClassName(
        "imagepicker-modal"
      )[0];
      var addIcon = document.getElementsByClassName("imagepicker-addicon")[0];

      var isClickInsideModal = imagePickerModal?.contains(event.target);
      var isClickInsideAddIcon = addIcon?.contains(event.target);

      if (!isClickInsideModal && !isClickInsideAddIcon) {
        setModalVisible(false);
      } else {
        setModalVisible(true);
      }
    });
  }, []);

  return (
    <>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div className="mt-4 mb-3 imagepicker">
        <div className="imagepicker-imgHolder">
          <img
            id="imagepicker-img"
            src={newProPicImg ? newProPicImg : ProfileAvatar}
          />
        </div>
        {/* {hello && <div className="toastDsgn">hello</div>} */}
        <div
          onClick={() => {
            setModalVisible(true);
          }}
          className="imagepicker-addicon"
        >
          <img src={PhotoCameraSvg} />
        </div>

        <div
          style={{ display: modalVisible ? "flex" : "none" }}
          className="imagepicker-modal"
        >
          <span
            onClick={() => {
              handlePaymentShow();
            }}
            style={{ margin: "0.50rem 0 ", height: "2.5rem" }}
          >
            View Photo
          </span>
          <span
            onClick={() => {
              setProfileModalOpen(true);
            }}
            style={{ margin: "0.50rem 0 ", height: "2.5rem" }}
          >
            Upload Photo
          </span>
          {newProPicImg === DEFAULT_AVATAR ? (
            ""
          ) : (
            <span
              onClick={() => {
                handleShowDeleteShow();
              }}
              style={{ margin: "0.50rem 0 ", height: "2.5rem" }}
            >
              Remove Photo
            </span>
          )}
        </div>
        {/* view profile image modal */}
        <Modal
          show={showPayment}
          onHide={handlePaymentClose}
          className="payment_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>View Profile Photo</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="viewphoto-modal-ImgHolder">
              <img src={newProPicImg ? newProPicImg : ProfileAvatar} />
            </div>
          </Modal.Body>
        </Modal>
        {/* remove profile image modal */}
        <Modal
          show={showDelete}
          onHide={handleShowDeleteClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1 dltWizard">
              <h3 className="mb-0">Delete Photo</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <p className="my-3 subtext">
              Photo will be deleted from the profile.
            </p>
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowDeleteClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleDeleteSubscription()}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ImagePicker;
