import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import MagnifierIcon from "../../assets/images/magnifier.png";
import removeSearch from "../../assets/images/search_remove.png";
import Dropdown from "react-bootstrap/Dropdown";
import { Tertiary } from "../../stories/Button.stories";
import { Benefits_Card } from "../../stories/BenefitsCard.stories";
import layer from "../../assets/images/layer1.svg";
import Loading from "../../common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

const Benefits = (props) => {
  let history = useHistory();
  var userType = localStorage.getItem("userType");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  const [familyDetails, setFamilyDetails] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [benefitItemList, setBenefitItemList] = useState([]);
  const [dropDownOpen, setDropddownOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [loadingval, setLoadingval] = useState(true);
  const [loading, setLoading] = useState(false);
  const [displayLength, setDisplayLength] = useState(5);
  const [searchStr, setSearchStr] = useState("");
  const [allBenefits, setAllBenefits] = useState([]);
  const [backUrl, setBackUrl] = useState("");
  const [limit, setlimit] = useState(12);
  const [offset, setoffset] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [dataLengthTop20, setDataLengthTop20] = useState(0);
  const [dataLengthCat, setDataLengthCat] = useState(0);
  const [dataLengthSearch, setDataLengthSearch] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [hasMoreTop20, setHasMoreTop20] = useState(false);
  const [hasMoreCat, setHasMoreCat] = useState(false);
  const [hasMoreSearch, setHasMoreSearch] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [searchVal, setSearchVal] = useState("");

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  let { uuid, userUUID } = useParams();

  // get benefit item listing by category
  const selectCategory = (categoryId) => {
    setLoading(true);
    setSearchStr("");
    setCategoryId(categoryId);
    let params = {
      limit: 12,
      offset: 0,
      id: categoryId,
    };
    UserService.getBenefitItemListByCategory(params).then(
      (response) => {
        setBenefitItemList(response.result);
        setLoading(true);

        setDataLengthCat(response.result.length);

        let nextOffset = 12;

        if (response.result.length === 0) {
          setIsEmpty(true);
          setLoading(false);
          setoffset(0);
          setHasMoreCat(false);
        } else {
          setIsEmpty(false);
          setLoading(false);
          if (response.result.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMoreCat(true);
          } else {
            setHasMoreCat(false);
          }
        }
      },
      (error) => {}
    );
  };

  // useEffect(() => {
  //   if (familyAUList === null || familyAUList.length === 0) {
  //
  //     history.push("/404");
  //   }
  // });

  // get top 20 benefit item listing
  const getTop20BenefitItems = () => {
    setLoading(true);
    setSearchStr("");
    setCategoryId("top-20");
    let params = {
      limit: 12,
      offset: 0,
    };
    UserService.getTop20BenefitItemList(params).then(
      (response) => {
        setBenefitItemList(response.result.rows);
        setLoading(true);
        setDataLengthTop20(response.result.rows.length);

        let nextOffset = 12;

        if (response.result.rows.length === 0) {
          setIsEmpty(true);
          setLoading(false);
          setoffset(0);
          setHasMoreTop20(false);
        } else {
          setIsEmpty(false);
          setLoading(false);
          if (response.result.rows.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMoreTop20(true);
          } else {
            setHasMoreTop20(false);
          }
        }
      },
      (error) => {}
    );
  };

  // get benefit item listing by category
  const getBenefitItems = () => {
    setLoading(true);
    setSearchStr("");
    setCategoryId("all");

    let params = {
      limit: 12,
      offset: 0,
    };
    UserService.getBenefitItemList(params).then(
      (response) => {
        setBenefitItemList(response.result);
        setAllBenefits(response.result);
        setLoading(true);

        setDataLength(response.result.length);

        let nextOffset = 12;

        if (response.result.length === 0) {
          setIsEmpty(true);
          setLoading(false);
          setoffset(0);
          setHasMore(false);
        } else {
          setIsEmpty(false);
          setLoading(false);
          if (response.result.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        }
      },
      (error) => {}
    );
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    let params = {
      limit: limit,
      offset: offset,
    };
    if (categoryId === "all" || categoryId === 0) {
      getBenefitItems();
    } else if (categoryId === "top-20") {
      getTop20BenefitItems();
    } else {
      selectCategory(categoryId);
      UserService.getBenefitItemList(params).then(
        (response) => {
          setBenefitItemList(response.result);
          setAllBenefits(response.result);
          setLoading(true);

          setDataLength(response.result.length);

          let nextOffset = 12;

          if (response.result.length === 0) {
            setIsEmpty(true);
            setLoading(false);
            setoffset(0);
            setHasMore(false);
          } else {
            setIsEmpty(false);
            setLoading(false);
            if (response.result.length >= nextOffset) {
              setoffset(nextOffset);
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          }
        },
        (error) => {}
      );
    }
  };

  //search clean
  useEffect(() => {
    let params = {
      limit: limit,
      offset: offset,
    };
    if (searchStr.length === 0) {
      setSearchVal("");
      if (categoryId === "all" || categoryId === 0) {
        getBenefitItems();
      } else if (categoryId === "top-20") {
        getTop20BenefitItems();
      } else {
        selectCategory(categoryId);
        UserService.getBenefitItemList(params).then(
          (response) => {
            setBenefitItemList(response.result);
            setAllBenefits(response.result);
            setLoading(true);

            setDataLength(response.result.length);

            let nextOffset = 12;

            if (response.result.length === 0) {
              setIsEmpty(true);
              setLoading(false);
              setoffset(0);
              setHasMore(false);
            } else {
              setIsEmpty(false);
              setLoading(false);
              if (response.result.length >= nextOffset) {
                setoffset(nextOffset);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            }
          },
          (error) => {}
        );
      }
    }
  }, [searchStr]);

  // Search benefits
  const searchBenefits = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setLoading(true);
      let val = e.target.value;
      setSearchStr(e.target.value);
      if (val === "") {
        setSearchVal("");
        let params = {
          limit: 12,
          offset: 0,
        };
        clearSearch(e);
        UserService.getBenefitItemList(params).then(
          (response) => {
            setBenefitItemList(response.result);
            setAllBenefits(response.result);
            setLoading(true);
            setDataLength(response.result.length);

            let nextOffset = 12;

            if (response.result.length === 0) {
              setIsEmpty(true);
              setLoading(false);
              setoffset(0);
              setHasMore(false);
            } else {
              setIsEmpty(false);
              setLoading(false);
              if (response.result.length >= nextOffset) {
                setoffset(nextOffset);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            }
          },
          (error) => {}
        );
      } else {
        let params = {
          limit: 12,
          offset: 0,
          searchTxt: val,
        };
        setSearchVal("abc");
        UserService.getBenefitItemSearchList(params).then((response) => {
          if (response.data.count > 0) {
            if (categoryId === "all" || categoryId === 0) {
              setBenefitItemList(response.data.rows);
              setAllBenefits(response.data.rows);
              setLoading(true);

              setDataLengthSearch(response.data.rows.length);

              let nextOffset = 12;

              if (response.data.rows.length === 0) {
                setIsEmpty(true);
                setLoading(false);
                setoffset(0);
                setHasMoreSearch(false);
              } else {
                setIsEmpty(false);
                setLoading(false);
                if (response.data.rows.length >= nextOffset) {
                  setoffset(nextOffset);
                  setHasMoreSearch(true);
                } else {
                  setHasMoreSearch(false);
                }
              }
            } else if (categoryId === "top-20") {
              let newItems = [];
              setBenefitItemList([]);
              for (var i = 0; i < response.data.rows.length; i++) {
                if (response.data.rows[i].top20) {
                  newItems.push(response.data.rows[i]);

                  if (i + 1 === response.data.rows.length) {
                    setBenefitItemList(newItems);
                    setAllBenefits(response.data.rows);
                    setLoading(true);

                    setDataLengthSearch(response.data.rows.length);

                    let nextOffset = 6;

                    if (response.data.rows.length === 0) {
                      setIsEmpty(true);
                      setLoading(false);
                      setoffset(0);
                      setHasMoreSearch(false);
                    } else {
                      setIsEmpty(false);
                      setLoading(false);
                      if (response.data.rows.length >= nextOffset) {
                        setoffset(nextOffset);
                        setHasMoreSearch(true);
                      } else {
                        setHasMoreSearch(false);
                      }
                    }
                  }
                }
              }
            } else {
              let newItems = [];
              setBenefitItemList([]);
              for (var i = 0; i < response.data.rows.length; i++) {
                newItems.push(response.data.rows[i]);
                if (i + 1 === response.data.rows.length) {
                  setBenefitItemList(newItems);
                  setAllBenefits(response.data.rows);
                  setLoading(true);

                  setDataLengthSearch(response.data.rows.length);

                  let nextOffset = 6;

                  if (response.data.rows.length === 0) {
                    setIsEmpty(true);
                    setLoading(false);
                    setoffset(0);
                    setHasMoreSearch(false);
                  } else {
                    setIsEmpty(false);
                    setLoading(false);
                    if (response.data.rows.length >= nextOffset) {
                      setoffset(nextOffset);
                      setHasMoreSearch(true);
                    } else {
                      setHasMoreSearch(false);
                    }
                  }
                }
              }
            }
          } else {
            setBenefitItemList(response.data.rows);
          }
          setLoading(false);
        });
      }
    }
  };

  // get family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (userUUID) {
      setBackUrl("/supporting_user_dashboard/" + uuid + "/" + userUUID);
    } else {
      setBackUrl("/family_dashboard/" + uuid);
    }
    getFamilyDetails(UserDetails.id);

    // get benefit category listing
    UserService.getBenefitCategoryList().then(
      (response) => {
        setCategoryList(response.result);
      },
      (error) => {}
    );
    let params = {
      limit: limit,
      offset: offset,
    };
    // get benefit item listing
    UserService.getBenefitItemList(params).then(
      (response) => {
        setBenefitItemList(response.result);
        setAllBenefits(response.result);
        setLoadingval(true);

        setDataLength(response.result.length);

        let nextOffset = 12;

        if (response.result.length === 0) {
          setIsEmpty(true);
          setLoadingval(false);
          setoffset(0);
          setHasMore(false);
        } else {
          setIsEmpty(false);
          setLoadingval(false);
          if (response.result.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        }
      },
      (error) => {}
    );

    if (window.screen.width > 1366) {
      setDisplayLength(6);
    }
    if (window.screen.width === 1366) {
      setDisplayLength(5);
    }
    if (window.screen.width === 768) {
      setDisplayLength(2);
    }
    if (window.screen.width === 1024) {
      setDisplayLength(4);
    }
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Tertiary
      children={`More`}
      size="smallbtn"
      variant={!dropDownOpen ? "tertiary" : "primary"}
      type=""
      style={{ margin: "10px 5px 0 0" }}
      icon={!dropDownOpen ? "arrowdown" : "arrowup"}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    />
  ));

  const moreDataBenifits = () => {
    let params = {
      limit: limit,
      offset: offset,
    };
    setTimeout(() => {
      UserService.getBenefitItemList(params).then(
        (response) => {
          setBenefitItemList([...benefitItemList, ...response.result]);
          setLoading(false);
          setDataLength(response.result.length + benefitItemList.length);
          let nextOffset = Number(offset) + Number(limit);

          if (response.result.length + benefitItemList.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }, 500);
  };

  const moreDataBenifitsTop20 = () => {
    let params = {
      limit: limit,
      offset: offset,
    };
    setTimeout(() => {
      UserService.getTop20BenefitItemList(params).then(
        (response) => {
          setBenefitItemList([...benefitItemList, ...response.result.rows]);
          setLoading(false);
          setDataLengthTop20(
            response.result.rows.length + benefitItemList.length
          );
          let nextOffset = Number(offset) + Number(limit);

          if (
            response.result.rows.length + benefitItemList.length >=
            nextOffset
          ) {
            setoffset(nextOffset);
            setHasMoreTop20(true);
          } else {
            setHasMoreTop20(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }, 500);
  };

  const moreDataBenifitsCat = () => {
    let params = {
      limit: limit,
      offset: offset,
      id: categoryId,
    };
    setTimeout(() => {
      UserService.getBenefitItemListByCategory(params).then(
        (response) => {
          setBenefitItemList([...benefitItemList, ...response.result]);
          setLoading(false);
          setDataLengthCat(response.result.length + benefitItemList.length);
          let nextOffset = Number(offset) + Number(limit);

          if (response.result.length + benefitItemList.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMoreCat(true);
          } else {
            setHasMoreCat(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }, 500);
  };

  const moreDataBenifitsSearch = () => {
    let params = {
      limit: limit,
      offset: offset,
      searchTxt: searchStr,
    };
    setTimeout(() => {
      UserService.getBenefitItemSearchList(params).then(
        (response) => {
          setBenefitItemList([...benefitItemList, ...response.data.rows]);
          setLoading(false);
          setDataLengthSearch(
            response.data.rows.length + benefitItemList.length
          );
          let nextOffset = Number(offset) + Number(limit);

          if (
            response.data.rows.length + benefitItemList.length >=
            nextOffset
          ) {
            setoffset(nextOffset);
            setHasMoreSearch(true);
          } else {
            setHasMoreSearch(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }, 500);
  };

  var uuidDetails = localStorage.getItem("uuid");
  var userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));

  const dashboardRed = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/family_dashboard/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  return (
    <div className="page-content warranty-pg">
      <Container fluid>
        {/* <h3 className="title">          
          {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}
        </h3> */}
        {/* <span className="nav-back">
          <Link to={backUrl}>
            <p className="mb-3">
              <img src={leftArrow} alt="arrow" className="mr-2" />
                Back to Group Dashboard
            </p>
          </Link>
        </span> */}
        {loadingval ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            <div
              className="Reminder-backBtn"
              onClick={() =>
                // history.push({
                //   pathname: `/family_dashboard/${data.state.state.tab}`,
                dashboardRed()
              }
            >
              {" "}
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              {parentUserUUId === null ? (
                <span>Back to Group Dashboard</span>
              ) : (
                <span>Back to Main User Dashboard</span>
              )}
            </div>
            <Row className="pt-4">
              <Col md="8" lg="8">
                <h3>My Discounts</h3>
                <p className="sub-title">
                  You can claim and utilize wide range of discounts from
                  different categories on Elderly Life.
                </p>
              </Col>
              <Col md="4" lg="4" className="text-right ">
                <div className="searchInput align-items-center space-between">
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search Discounts"
                    value={searchStr}
                    onChange={(e) => setSearchStr(e.target.value)}
                    onKeyDown={searchBenefits}
                  />
                  {!searchStr && (
                    <button
                      className="btn btn-default search-input-btn"
                      type="submit"
                    >
                      <Image src={MagnifierIcon} alt="magnifier icon" />
                    </button>
                  )}
                  {searchStr && (
                    <button
                      className="btn btn-default clear-search"
                      type="submit"
                      onClick={clearSearch}
                    >
                      <Image src={removeSearch} alt="Clear Search" />
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="10" lg="10">
                <Tertiary
                  children="Top 20"
                  size="smallbtn"
                  variant={categoryId !== "top-20" ? "tertiary" : "primary"}
                  type=""
                  style={{ margin: "10px 15px 0 0" }}
                  onClick={() => getTop20BenefitItems()}
                />
                <Tertiary
                  children="All"
                  size="smallbtn"
                  variant={categoryId !== "all" ? "tertiary" : "primary"}
                  type=""
                  style={{ margin: "10px 15px 0 0" }}
                  onClick={() => getBenefitItems()}
                />
                {categoryList.slice(0, displayLength).map(({ name, id }) => {
                  return (
                    <Tertiary
                      key={id}
                      children={`${name}`}
                      size="smallbtn"
                      variant={categoryId !== id ? "tertiary" : "primary"}
                      type=""
                      style={{ margin: "10px 15px 0 0" }}
                      onClick={() => {
                        selectCategory(id);
                      }}
                    />
                  );
                })}
              </Col>
              <Col className="text-right">
                {categoryList.length > displayLength && (
                  <Dropdown
                    onToggle={(isOpen) => {
                      setDropddownOpen(isOpen);
                    }}
                    className="profile-dropdwn"
                  >
                    <Dropdown.Toggle
                      as={CustomToggle}
                      comp
                      variant="Warning"
                      id="dropdown-basic"
                    ></Dropdown.Toggle>

                    <Dropdown.Menu
                      className="primary-drop-down"
                      style={{ height: "auto" }}
                    >
                      <div>
                        {categoryList
                          .slice(displayLength)
                          .map((item, index) => (
                            <Dropdown.Item
                              className={
                                categoryId === item.id ? "tertiary" : ""
                              }
                              onClick={() => {
                                selectCategory(item.id);
                              }}
                              key={index}
                            >
                              {item.name}
                            </Dropdown.Item>
                          ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Col>
            </Row>
            {benefitItemList.length > 0 && !loading && (
              <Row>
                <Col md="12" lg="12" className="mt-5 flex-wrap flex-gap">
                  {(categoryId === 0 || categoryId === "all") &&
                  searchVal === "" ? (
                    <InfiniteScroll
                      dataLength={dataLength}
                      next={moreDataBenifits}
                      hasMore={hasMore}
                      loader={
                        <Row style={{ width: "100%" }}>
                          <Col
                            md="12"
                            lg="12"
                            className="mt-4 pt-4 text-center"
                          >
                            <button className="btn loading-btn" type="button">
                              <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                              ></span>
                              Loading . . .
                            </button>
                          </Col>
                        </Row>
                      }
                      style={{
                        display: "flex",
                        "flex-wrap": "wrap",
                        gap: "1rem",
                      }}
                    >
                      {benefitItemList?.map(
                        ({
                          benefitText,
                          id,
                          benefitUrl,
                          logoImg,
                          bannerImg,
                          ctaText,
                        }) => {
                          return (
                            <Benefits_Card
                              key={id}
                              variant="basic"
                              benefitTitle={`${benefitText}`}
                              logo={`${logoImg}`}
                              banner={`${bannerImg}`}
                              link={`${benefitUrl}`}
                              linkText={`${ctaText}`}
                            />
                          );
                        }
                      )}
                    </InfiniteScroll>
                  ) : categoryId === "top-20" && searchVal === "" ? (
                    <InfiniteScroll
                      dataLength={dataLengthTop20}
                      next={moreDataBenifitsTop20}
                      hasMore={hasMoreTop20}
                      loader={
                        <Row style={{ width: "100%" }}>
                          <Col
                            md="12"
                            lg="12"
                            className="mt-4 pt-4 text-center"
                          >
                            <button className="btn loading-btn" type="button">
                              <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                              ></span>
                              Loading . . .
                            </button>
                          </Col>
                        </Row>
                      }
                      style={{
                        display: "flex",
                        "flex-wrap": "wrap",
                        gap: "1rem",
                      }}
                    >
                      {benefitItemList?.map(
                        ({
                          benefitText,
                          id,
                          benefitUrl,
                          logoImg,
                          bannerImg,
                          ctaText,
                        }) => {
                          return (
                            <Benefits_Card
                              key={id}
                              variant="basic"
                              benefitTitle={`${benefitText}`}
                              logo={`${logoImg}`}
                              banner={`${bannerImg}`}
                              link={`${benefitUrl}`}
                              linkText={`${ctaText}`}
                            />
                          );
                        }
                      )}
                    </InfiniteScroll>
                  ) : categoryId != 0 &&
                    categoryId != "all" &&
                    categoryId != "top-20" &&
                    searchVal === "" ? (
                    <InfiniteScroll
                      dataLength={dataLengthCat}
                      next={moreDataBenifitsCat}
                      hasMore={hasMoreCat}
                      loader={
                        <Row style={{ width: "100%" }}>
                          <Col
                            md="12"
                            lg="12"
                            className="mt-4 pt-4 text-center"
                          >
                            <button className="btn loading-btn" type="button">
                              <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                              ></span>
                              Loading . . .
                            </button>
                          </Col>
                        </Row>
                      }
                      style={{
                        display: "flex",
                        "flex-wrap": "wrap",
                        gap: "1rem",
                      }}
                    >
                      {benefitItemList?.map(
                        ({
                          benefitText,
                          id,
                          benefitUrl,
                          logoImg,
                          bannerImg,
                          ctaText,
                        }) => {
                          return (
                            <Benefits_Card
                              key={id}
                              variant="basic"
                              benefitTitle={`${benefitText}`}
                              logo={`${logoImg}`}
                              banner={`${bannerImg}`}
                              link={`${benefitUrl}`}
                              linkText={`${ctaText}`}
                            />
                          );
                        }
                      )}
                    </InfiniteScroll>
                  ) : (
                    <InfiniteScroll
                      dataLength={dataLengthSearch}
                      next={moreDataBenifitsSearch}
                      hasMore={hasMoreSearch}
                      loader={
                        <Row style={{ width: "100%" }}>
                          <Col
                            md="12"
                            lg="12"
                            className="mt-4 pt-4 text-center"
                          >
                            <button className="btn loading-btn" type="button">
                              <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                              ></span>
                              Loading . . .
                            </button>
                          </Col>
                        </Row>
                      }
                      style={{
                        display: "flex",
                        "flex-wrap": "wrap",
                        gap: "1rem",
                      }}
                    >
                      {benefitItemList?.map(
                        ({
                          benefitText,
                          id,
                          benefitUrl,
                          logoImg,
                          bannerImg,
                          ctaText,
                        }) => {
                          return (
                            <Benefits_Card
                              key={id}
                              variant="basic"
                              benefitTitle={`${benefitText}`}
                              logo={`${logoImg}`}
                              banner={`${bannerImg}`}
                              link={`${benefitUrl}`}
                              linkText={`${ctaText}`}
                            />
                          );
                        }
                      )}
                    </InfiniteScroll>
                  )}
                </Col>
              </Row>
            )}
            {benefitItemList.length === 0 && !loading && (
              <Row>
                <Col
                  md="12"
                  lg="12"
                  className="mt-5 pt-5 small-text text-center"
                >
                  No data found
                </Col>
              </Row>
            )}
            {loading && (
              <Row>
                <Col md="12" lg="12" className="mt-4 pt-4 text-center">
                  <button className="btn loading-btn mt-5" type="button">
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                    ></span>
                    Loading . . .
                  </button>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </div>
  );
};
export default Benefits;
