import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import loginImg from "../../assets/images/login-img.png";
import openHand from "../../assets/images/open-hand.svg";
import { Medium } from "../../stories/Input.stories";
import { Large } from "../../stories/Button.stories";
import { Danger } from "../../stories/Alert.stories";
import ReactCodeInput from "react-verification-code-input";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import AuthService from "../../services/auth.service";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const ResetPassword = () => {
  let history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);

  const initialValues = { email: "" };
  const [formFirstValues, setFormFirstValues] = useState(initialValues);
  const [formFirstErrors, setFormFirstErrors] = useState({});

  const initialSecondValues = { code: "" };
  const [formSecondValues, setFormSecondValues] = useState(initialSecondValues);
  const [formSecondErrors, setFormSecondErrors] = useState({});

  const initialThirdValues = { password: "", cmpassword: "" };
  const [formThirdValues, setFormThirdValues] = useState(initialThirdValues);
  const [formThirdErrors, setFormThirdErrors] = useState({});

  // const [isEmailSubmit, setIsEmailSubmit] = useState(false);
  // const [isCodeSubmit, setIsCodeSubmit] = useState(false);
  // const [isPwdSubmit, setIsPwdSubmit] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [cmPasswordShown, setCmPasswordShown] = useState(false);
  const [showFirstForm, setShowFirstForm] = useState("block");
  const [showSecondForm, setShowSecondForm] = useState("none");
  const [showThirdForm, setShowThirdForm] = useState("none");
  const [loading, setLoading] = useState(false);
  const [parentUser, setParentUser] = useState("");

  // redirect to dashboard if already logged in
  let items = localStorage.getItem("UserDetails");
  if (items) {
    var stringify = JSON.parse(items);
    if (stringify) {
      //redirect to Login
      history.push("/dashboard");
    }
  }
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleCmPassword = () => {
    setCmPasswordShown(!cmPasswordShown);
  };
  const handleVerifyEmailChange = (e) => {
    const { name, value } = e.target;
    setFormFirstValues({ ...formFirstValues, [name]: value });
  };
  const handleVerifyEmailSubmit = (e) => {
    e.preventDefault();
    validateVerifyEmail(formFirstValues);
  };
  const handleIncorrectEmail = () => {
    //e.preventDefault();
    setShowSecondForm("none");
    setShowThirdForm("none");
    setShowFirstForm("blobk");
  };
  const handleCodeChange = (e) => {
    let codeValue = {
      code: e,
    };
    setFormSecondValues(codeValue);
  };
  const handleCodeSubmit = (e) => {
    e.preventDefault();
    validateVerifyCode(formSecondValues);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setFormThirdValues({ ...formThirdValues, [name]: value });
  };
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    validateVerifyPwd(formThirdValues);
  };

  // validate first form
  const validateVerifyEmail = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let isValid = true;

    if (!values.email) {
      errors.msg = "Please enter the required fields";
      errors.email = "Yes";
      isValid = false;
    } else if (!regex.test(values.email)) {
      errors.msg = "This is not a valid email format!";
      errors.email = "Yes";
      isValid = false;
    }
    setFormFirstErrors(errors);

    if (isValid) {
      checkEmailForgetPwd();
    }
  };
  // first form api :
  const checkEmailForgetPwd = (e) => {
    setLoading(true);
    let formData = {};
    let isEdited = false;

    if (initialValues.email !== formFirstValues.email) {
      formData.email = formFirstValues.email;
      isEdited = true;
    }

    if (isEdited) {
      // api call
      AuthService.checkEmail(formData).then(
        (res) => {
          if (res.status === 400) {
            setLoading(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
          } else {
            setParentUser(res.result[1]);
            setLoading(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Unique code has been send to your email");
            setShowFirstForm("none");
            setShowSecondForm("block");
            setShowThirdForm("none");
          }
        },
        (error) => {
          const errors = {};
          if (error.response === undefined) {
            errors.msg = "Connection timed out. Please try again later";
            setFormFirstErrors(errors);
          } else {
            errors.msg = error.response.data.error;
            if (errors.msg) {
              setFormFirstErrors(errors);
            }
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      setFormFirstErrors({ msg: "Please edit the profile details" });
    }
  };
  // const validateVerifyEmail = (values) => {
  //   const errors = {};
  //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  //   if (!values.email) {
  //     errors.msg = "Please enter a valid email address";
  //     errors.email = "Yes";
  //   } else if (!regex.test(values.email)) {
  //     errors.msg = "This is not a valid email format !";
  //     errors.email = "Yes";
  //   }

  //   return errors;
  // };
  // validate second form
  const validateVerifyCode = (values) => {
    let isValid = true;
    const c_errors = {};
    if (values.code.length != "6") {
      c_errors.msg = "Please enter valid verification code";
      c_errors.code = "Yes";
      isValid = false;
    }
    setFormSecondErrors(c_errors);
    if (isValid) {
      verifyCodeForgetPwd();
    }
  };
  // second form submit
  const verifyCodeForgetPwd = (e) => {
    setLoading(true);
    let formData = {};
    let isEdited = false;

    if (initialSecondValues.code !== formSecondValues.code) {
      formData.code = formSecondValues.code;
      isEdited = true;
    }

    if (isEdited) {
      // api call
      AuthService.verifySixDigitCode(formData, parentUser.uuid).then(
        (res) => {
          if (res.status === 400) {
            setLoading(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
          } else {
            setLoading(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Code verified successfully");
            setShowFirstForm("none");
            setShowSecondForm("none");
            setShowThirdForm("block");
          }
        },
        (error) => {
          const errors = {};
          if (error.response === undefined) {
            errors.msg = "Connection timed out. Please try again later";
            setFormSecondErrors(errors);
          } else {
            errors.msg = error.response.data.error;
            if (errors.msg) {
              setFormSecondErrors(errors);
            }
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      setFormSecondErrors({ msg: "Please edit the profile details" });
    }
  };
  //verify pwd
  const validateVerifyPwd = (values) => {
    const errors = {};
    let isValid = true;

    if (!values.password) {
      errors.msg = "Please enter the required fields";
      errors.password = "Yes";
      isValid = false;
    } else {
      var alphanumerics = /^.{8,40}$/;
      if (!values.password.match(alphanumerics)) {
        errors.msg = "Please enter a valid password";
        errors.password = "Yes";
        isValid = false;
      }
    }
    if (!errors.password) {
      if (values.cmpassword != values.password) {
        errors.msg = "Please enter the same password you've entered before";
        errors.cmpassword = "Yes";
        isValid = false;
      }
    }
    setFormThirdErrors(errors);

    if (isValid) {
      updatePasswordForgetPwd();
    }
  };
  // third form submit
  const updatePasswordForgetPwd = (e) => {
    setLoading(true);
    let formData = {};
    let isEdited = false;

    if (initialThirdValues.password !== formThirdValues.password) {
      formData.newPassword = formThirdValues.password;
      isEdited = true;
    }
    if (initialThirdValues.cmpassword !== formThirdValues.cmpassword) {
      formData.confNewPassword = formThirdValues.cmpassword;
      isEdited = true;
    }

    if (isEdited) {
      // api call
      AuthService.updateUserPassword(formData, parentUser.uuid).then(
        (res) => {
          if (res.status === 400) {
            setLoading(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.error);
          } else {
            setLoading(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Password updated successfully");
            setTimeout(() => {
              history.push("/");
            }, 2000);
          }
        },
        (error) => {
          const errors = {};
          if (error.response === undefined) {
            errors.msg = "Connection timed out. Please try again later";
            setFormThirdErrors(errors);
          } else {
            errors.msg = error.response.data.error;
            if (errors.msg) {
              setFormThirdErrors(errors);
            }
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      setFormThirdErrors({ msg: "Please edit the profile details" });
    }
  };
  return (
    <div className="main-view">
      <div className="unAuth-container">
        <Row>
          <Col
            md="4"
            className="pe-5"
            lg="6"
            style={{ height: "calc(100vh-55px)" }}
          >
            <div className="w-100 h-100 image-login">
              {/* <Image className="login-img" src={loginImg} alt="El dashboard" /> */}
            </div>
            {/* <div className="bg"></div> */}
            <ToastBlockContainer
              successToast={successToast}
              showToast={showToast}
              setShowToast={setShowToast}
              toastMessage={toastMessage}
            />
          </Col>
          <Col
            md="8"
            lg="6"
            className="d-flex align-items-center"
            style={{ height: "calc(100vh - 55px)" }}
          >
            <div className="w-100">
              <Image className="slide-right" src={openHand} alt="El openHand" />
              <Card className="prelogin-card signin">
                <Card.Body className="p-4">
                  {/* form 1 */}
                  <Form
                    className="reset-form"
                    style={{ display: showFirstForm }}
                  >
                    <p className="mb-1 title-heading">Reset your password</p>
                    <span className="mb-2 login-boarder"></span>
                    <p className="mb-4 text-small">
                      Enter your registered email address to receive password
                      reset verification code.
                    </p>
                    <Form.Group
                      className="reset-email"
                      controlId="formBasicEmail"
                    >
                      <Form.Label
                        className={formFirstErrors.email ? "invalid-text" : ""}
                      >
                        Email
                      </Form.Label>
                      <Medium
                        type="email"
                        errorClass={
                          formFirstErrors.email ? "invalid-input" : ""
                        }
                        placeholder="John@gmail.com"
                        size="medium"
                        name="email"
                        value={formFirstValues.email}
                        onChange={handleVerifyEmailChange}
                      />
                    </Form.Group>
                    {Object.keys(formFirstErrors).length !== 0 ? (
                      <Danger variant="danger" message={formFirstErrors.msg} />
                    ) : (
                      ""
                    )}
                    <div className="align-center resetpassword-btn">
                      <Large
                        children="Request Unique code"
                        size="mediumbtn"
                        variant="primary"
                        buttonWidth="60%"
                        onClick={handleVerifyEmailSubmit}
                        isLoading={loading ? "true" : "false"}
                        isDisabled={loading ? "true" : "false"}
                      />
                      <p className="pt-4">
                        <Link to="/" className="link-brd">
                          <span className="centre-link">Back to login</span>
                        </Link>
                      </p>
                    </div>
                  </Form>
                  {/* form 2 */}
                  <Form
                    className="reset-form"
                    style={{ display: showSecondForm }}
                  >
                    <p className="mb-1 title-heading">Reset your password</p>
                    <span className="mb-2 login-boarder"></span>
                    <p className="mb-4 text-small">
                      A Unique code has been sent to{" "}
                      <u className="highlight-txt">{formFirstValues.email}</u>.
                      Not your email?
                      <u
                        className="highlight-txt ml-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => window.location.reload()}
                      >
                        Change here
                      </u>
                    </p>
                    <Form.Group
                      className="reset-email"
                      controlId="formBasicEmail"
                    >
                      <Form.Label
                        className={formSecondErrors.code ? "invalid-text" : ""}
                      >
                        Unique Code
                      </Form.Label>
                      <div className="outer-layer">
                        <ReactCodeInput
                          value=""
                          name="code"
                          fields={6}
                          className={
                            formSecondErrors.code ? "invalid-text" : ""
                          }
                          onChange={handleCodeChange}
                        />
                      </div>
                      <Form.Text>
                        Enter the code received on your email address
                      </Form.Text>
                    </Form.Group>
                    {Object.keys(formSecondErrors).length !== 0 ? (
                      <Danger variant="danger" message={formSecondErrors.msg} />
                    ) : (
                      ""
                    )}
                    <div className="align-center resetpassword-btn">
                      <Large
                        children="Submit Code"
                        size="mediumbtn"
                        variant="primary"
                        buttonWidth="60%"
                        onClick={handleCodeSubmit}
                        isLoading={loading ? "true" : "false"}
                        isDisabled={loading ? "true" : "false"}
                      />
                      <p className="pt-4">
                        <Link to="/" className="link-brd">
                          <span className="centre-link">Back to login</span>
                        </Link>
                      </p>
                    </div>
                  </Form>
                  {/* form 3 */}
                  <Form
                    className="reset-form"
                    onSubmit={handlePasswordSubmit}
                    style={{ display: showThirdForm }}
                  >
                    <p className="mb-1 title-heading">Set new password</p>
                    <span className="mb-2 login-boarder"></span>
                    <p className="mb-4 text-small"></p>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label
                        className={
                          formThirdErrors.password ? "invalid-text" : ""
                        }
                      >
                        Create Password
                      </Form.Label>
                      <InputGroup className="mb-0">
                        <FormControl
                          type={passwordShown ? "text" : "password"}
                          className={
                            formThirdErrors.password ? "invalid-input" : ""
                          }
                          placeholder="Password"
                          size="medium"
                          name="password"
                          value={formThirdValues.password}
                          onChange={handlePasswordChange}
                        />
                        <InputGroup.Text
                          className={
                            formThirdErrors.password
                              ? "invalid-input"
                              : "pwd-icon"
                          }
                        >
                          <i
                            className={`fa ${
                              passwordShown ? "fa-eye" : "fa-eye-slash"
                            } password-icon`}
                            onClick={togglePassword}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <Form.Text
                        className={
                          formThirdErrors.password ? "invalid-text" : " "
                        }
                      >
                        Your password should be of minimum 8 characters and can
                        contain alphanumerics
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label
                        className={
                          formThirdErrors.cmpassword ? "invalid-text" : ""
                        }
                      >
                        Confirm Password
                      </Form.Label>
                      <InputGroup className="mb-0">
                        <FormControl
                          type={cmPasswordShown ? "text" : "password"}
                          className={
                            formThirdErrors.cmpassword ? "invalid-input" : ""
                          }
                          placeholder="Password"
                          size="medium"
                          name="cmpassword"
                          value={formThirdValues.cmpassword}
                          onChange={handlePasswordChange}
                        />
                        <InputGroup.Text
                          className={
                            formThirdErrors.cmpassword
                              ? "invalid-input"
                              : "pwd-icon"
                          }
                        >
                          <i
                            className={`fa ${
                              cmPasswordShown ? "fa-eye" : "fa-eye-slash"
                            } password-icon`}
                            onClick={toggleCmPassword}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      {/* <button onClick={togglePassword}>Show Password</button> */}

                      <Form.Text
                        className={
                          formThirdErrors.cmpassword ? "invalid-text" : " "
                        }
                      >
                        Please retype the same password you've entered before
                      </Form.Text>
                    </Form.Group>
                    {Object.keys(formThirdErrors).length !== 0 ? (
                      <Danger variant="danger" message={formThirdErrors.msg} />
                    ) : (
                      ""
                    )}
                    <div className="align-center mt-5">
                      <Large
                        children="Reset Password"
                        size="mediumbtn"
                        variant="primary"
                        buttonWidth="60%"
                        isLoading={loading ? "true" : "false"}
                        isDisabled={loading ? "true" : "false"}
                      />
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResetPassword;
