import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import { Primary } from "../../stories/Button.stories";
import { Small } from "../../stories/Input.stories";
import { Info } from "../../stories/Button.stories";
import layer from "../../assets/images/layer1.svg";
import { SeatOccupied } from "../../stories/SeatCard.stories";
import ProfileAvatar from "../../assets/images/profile_avatar.svg";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import leftArrow from "../../assets/images/left.svg";
import moment from "moment";
import { Tertiary } from "../../stories/Button.stories";
import Select from "react-select";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";

const InviteHistory = (props) => {
  var contentClass = props.isOpen ? "content open" : "content";
  const executeScroll = () => myRef.current.scrollIntoView();
  const [loader, setLoader] = useState(true);
  let history = useHistory();
  const myRef = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [familyDetails, setFamilyDetails] = useState([]);
  const [familyUsersList, setFamilyUsersList] = useState([]);
  const [totalSuCount, setTotalSuCount] = useState(0);
  const [totalAuCount, setTotalAuCount] = useState(0);
  // Get login user details from localStorage
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // remove user
  const [loading, setLoading] = useState(false);
  const [showRemoveAuUser, setShowRemoveAuUser] = useState(false);
  const [auInviteName, setAuInviteName] = useState("");
  const [auInviteEmail, setAuInviteEmail] = useState("");
  const [auInviteId, setAuInviteId] = useState("");
  const [auInviteImage, setAuInviteImage] = useState("");
  const handleShowRemoveAuUserClose = () => setShowRemoveAuUser(false);
  const handleShowRemoveAuUserShow = (userList) => {
    setShowRemoveAuUser(true);
    setAuInviteName(userList.User.firstName + " " + userList.User.lastName);
    setAuInviteEmail(userList.User.email);
    setAuInviteImage(userList.User.avatar);
    setAuInviteId(userList.id);
  };
  let { uuid } = useParams();
  const handleCancelAuInvite = (inviteId) => {
    setShowRemoveAuUser(false);

    let updateData = {
      //status: "Removed",
      fuuid: uuid,
      id: inviteId,
      pu: true,
    };
    // revoke family invite
    //UserService.updateFamilyInviteStatus(inviteId, updateData).then(
    UserService.revokeFamilyInviteStatus(updateData).then(
      (response) => {
        UserService.getFamilyInviteList(uuid, "").then((res1) => {
          setFamilyUsersList(res1.result.rows);
        });
      },
      (error) => {
        setLoading(false);
      }
    );
  };
  // resend invite
  const [showResendAuUser, setShowResendAuUser] = useState(false);
  const handleShowResendAuUserClose = () => setShowResendAuUser(false);
  const handleShowResendAuUserShow = (userList) => {
    setShowResendAuUser(true);
    setAuInviteName(userList.User.firstName + " " + userList.User.lastName);
    setAuInviteEmail(userList.User.email);
    setAuInviteImage(userList.User.avatar);
    setAuInviteId(userList.id);
  };
  const handleCancelAuResendInvite = (inviteId) => {
    setShowResendAuUser(false);

    let updateData = {
      inviteStatus: "Acceptance Pending",
    };
    // revoke family invite
    UserService.resendFamilyInvite(updateData, inviteId).then(
      (response) => {
        if (response.status === 400) {
          //executeScroll(); window.scrollTo(0, 0);
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(response.data.error);
        } else {
          //executeScroll(); window.scrollTo(0, 0);
          setSuccessToast(true);
          setShowToast(true);
          setToastMessage("Invitation has been resend successfully");

          UserService.getFamilyInviteList(uuid, "").then((res1) => {
            setFamilyUsersList(res1.result.rows);
          });
        }
      },
      (error) => {
        setLoading(false);
      }
    );
  };
  // filter
  const filterOption = [
    { value: "", label: "All" },
    { value: "Acceptance Pending", label: "Acceptance Pending" },
    { value: "Onboarded", label: "Onboarded" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Removed", label: "Removed" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
      height: "3.75rem",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };
  // Filter subscriptions
  const historyFilter = (e) => {
    let filterVal = e.value;

    setLoading(true);
    UserService.getFamilyInviteList(uuid, filterVal).then((res1) => {
      setFamilyUsersList(res1.result.rows);
      if (res1.result.rows) {
        const SuCount = res1.result.rows.reduce(
          (counter, obj) => (obj.isSu == true ? (counter += 1) : counter),
          0
        );

        setTotalSuCount(SuCount);
        const AuCount = res1.result.rows.reduce(
          (counter, obj) => (obj.isAu == true ? (counter += 1) : counter),
          0
        );

        setTotalAuCount(AuCount);
      }
    });
  };
  useEffect(() => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
        UserService.getFamilyInviteList(uuid, "").then((res1) => {
          setFamilyUsersList(res1.result.rows);
          if (res1.result.rows) {
            const SuCount = res1.result.rows.reduce(
              (counter, obj) => (obj.isSu == true ? (counter += 1) : counter),
              0
            );

            setTotalSuCount(SuCount);
            const AuCount = res1.result.rows.reduce(
              (counter, obj) => (obj.isAu == true ? (counter += 1) : counter),
              0
            );

            setTotalAuCount(AuCount);
          }
          setLoader(false);
        });
      },
      (error) => {}
    );
  }, []);
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const data = useLocation();

  var uuidDetails = localStorage.getItem("uuid");
  const dashboardRed = () => {
    history.push({
      pathname: `/family_dashboard/${uuidDetails}`,
    });
  };

  return (
    <div className="page-content invite-history warranty-pg tab-btn">
      <Container fluid>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {/* <h3 className="title">
          {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}{" "}
        </h3> */}
        {/* <span className="nav-back">
          <Link to={`/family_dashboard/${uuid}`}>
            <p className="mb-3">
              <img src={leftArrow} alt="arrow" className="mr-2" />
              Back to Group Dashboard
            </p>
          </Link>
        </span> */}
        <div className="Reminder-backBtn" onClick={() => dashboardRed()}>
          <img
            className=""
            src={layer}
            alt="Profile Avatar"
            style={{ "margin-right": "15px" }}
          />{" "}
          <span>Back to Group Dashboard</span>
        </div>
        <Row>
          <Col md="8" lg="9">
            <h3>Seat History</h3>
            <p className="sub-title">
              You can view the seat history of all the users in your family on
              Elderly Life Platform
            </p>
          </Col>
          <Col md="3" lg="3">
            <div className="d-flex justify-content-between">
              <div className="filter-label pl-2 border-radius-rignt-none">
                Filter by :
              </div>
              <div className="p-0 filter-select flex-1">
                <Select
                  styles={customStyles}
                  options={filterOption}
                  defaultValue={{ label: "All", value: "all" }}
                  onChange={historyFilter}
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* <Dropdown className="filter-dropdwn">
            <Dropdown.Toggle variant="" className="m-0 user-dropdown" id="">
              Filter by : All
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>All</Dropdown.Item>
              <Dropdown.Item>item2</Dropdown.Item>
              <Dropdown.Item>item3</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

        {loader ? (
          <div className="loadind-page">
            <Loading />
          </div>
        ) : (
          <Tabs
            defaultActiveKey={
              data.query && data.query.tab1 != undefined
                ? "primary_users"
                : "support_users"
            }
            id="uncontrolled-tab-example"
            className="tab-container"
          >
            <Tab
              eventKey="primary_users"
              title="Main Users"
              className="tab-primary-users"
            >
              {familyUsersList.map((userList, index) =>
                userList.isAu == true ? (
                  <Card className="mb-3 card-list" key={index}>
                    <Card.Body>
                      <Row>
                        <Col md="3" lg="3">
                          <small className="mb-0">Email ID</small>
                          <p className="mb-0 tab-txt">{userList.User.email}</p>
                        </Col>
                        <Col md="3" lg="3">
                          <small className="mb-0">Invited on</small>
                          <p className="mb-0 tab-txt">
                            {moment(userList.createdAt).format("MMM Do, YYYY")}
                          </p>
                        </Col>
                        <Col md="2" lg="2">
                          <small className="mb-0">Status</small>
                          <p className="mb-0 tab-txt status">
                            {userList.inviteStatus}
                          </p>
                        </Col>
                        <Col md="2" lg="2">
                          {userList.inviteStatus == "Acceptance Pending" ? (
                            // <span className="list-btn-revoke my-1">
                            //   <p className="mb-3">Revoke Invite</p>
                            // </span>
                            <Tertiary
                              children="Revoke Invite"
                              size="smallbtn"
                              variant="tertiary"
                              type=""
                              style={{ margin: "5px 15px 0 0" }}
                              onClick={() =>
                                handleShowRemoveAuUserShow(userList)
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col md="2" lg="2">
                          {userList.inviteStatus == "Acceptance Pending" ||
                          userList.inviteStatus == "Cancelled" ? (
                            // <span className="list-btn-removed my-1">
                            //   <p className="mb-3">Resend Invite</p>
                            // </span>
                            <Tertiary
                              children="Resend Invite"
                              size="smallbtn"
                              variant="tertiary"
                              type=""
                              style={{ margin: "5px 15px 0 0" }}
                              onClick={() =>
                                handleShowResendAuUserShow(userList)
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  ""
                )
              )}
              {totalAuCount == 0 ? (
                <Row>
                  <Col
                    md="12"
                    lg="12"
                    className="mt-5 pt-5 small-text text-center"
                  >
                    No Main user found
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Tab>
            <Tab
              eventKey="support_users"
              title="Support Users"
              className="tab-support-users"
            >
              {familyUsersList.map((userList, i) =>
                userList.isSu == true ? (
                  <Card className="mb-3" key={i}>
                    <Card.Body>
                      <Row>
                        <Col md="3" lg="3">
                          <small className="mb-0">Email ID</small>
                          <p className="mb-0 tab-txt">{userList.User.email}</p>
                        </Col>
                        <Col md="3" lg="3">
                          <small className="mb-0">Invited on</small>
                          <p className="mb-0 tab-txt">
                            {moment(userList.createdAt).format("MMM Do, YYYY")}
                          </p>
                        </Col>
                        <Col md="3" lg="3">
                          <small className="mb-0">Invited by</small>
                          <p className="mb-0 tab-txt">
                            {userList.invitedBy == null
                              ? "self"
                              : Capitalize(userList.invitedBy.firstName) +
                                " " +
                                Capitalize(userList.invitedBy.lastName)}
                            {/* {(userList.invitedBy !== null) ? Capitalize(userList.invitedBy.firstName): " " + " " + (userList.invitedBy !== null) ? Capitalize(userList.invitedBy.lastName) : " "} */}
                          </p>
                        </Col>
                        <Col md="3" lg="3">
                          <small className="mb-0">Status</small>
                          <p className="mb-0 tab-txt status">
                            {userList.inviteStatus}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  ""
                )
              )}
              {totalSuCount == 0 ? (
                <Row>
                  <Col
                    md="12"
                    lg="12"
                    className="mt-5 pt-5 small-text text-center"
                  >
                    No Support user found
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Tab>
          </Tabs>
        )}
        {/* Remove account user */}
        <Modal
          show={showRemoveAuUser}
          onHide={handleShowRemoveAuUserClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">Revoke Account User</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <SeatOccupied
              rightImg=""
              leftImg=""
              isEmpty="true"
              ProfileAvatar={auInviteImage}
              memberHeading={auInviteName}
              memberSubheading={auInviteEmail}
            />
            <p className="my-3 subtext">
              You are removing the above user from your Family.
            </p>
            <p className="mb-4 subtext">
              <strong>
                Once a user is removed, they cannot access their details on
                Elderly Life.
              </strong>
            </p>
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowRemoveAuUserClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleCancelAuInvite(auInviteId)}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* Resnd invite to account user */}
        <Modal
          show={showResendAuUser}
          onHide={handleShowResendAuUserClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">Resend Invite to User</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <SeatOccupied
              rightImg=""
              leftImg=""
              isEmpty="true"
              ProfileAvatar={auInviteImage}
              memberHeading={auInviteName}
              memberSubheading={auInviteEmail}
            />
            <p className="my-3 subtext">
              You are resending the invite to above user from your Family.
            </p>
            <p className="mb-4 subtext">
              {/* <strong>
                Once a user is removed, they cannot access their details on
                Elderly Life.
              </strong> */}
            </p>
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowResendAuUserClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleCancelAuResendInvite(auInviteId)}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};
export default InviteHistory;
