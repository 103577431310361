import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Primary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import DocIcon from "../../assets/images/doc_icon.png";
import Modal from "react-bootstrap/Modal";
import PDFViewer from "pdf-viewer-reactjs";
import FileViewer from "react-file-viewer";
import Loading from "../../common/Loading";

const ViewSubscriptionDetails = (props) => {
  var contentClass = props.isOpen ? "content open" : "content";
  let history = useHistory();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [isPdf, setIsPdf] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isDocument, setIsDocument] = useState(false);

  const [familyDetails, setFamilyDetails] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [isEmpty, setIsEmpty] = useState(false);
  const [subscriptionsUrl, setSubscriptionsUrl] = useState("");
  const [subscriptionEditUrl, setSubscriptionEditUrl] = useState("");
  const [backText, setBackText] = useState("Back to My Outgoings");
  let { uuid, subId, userUUID } = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showDocSub, setShowDocSub] = useState(false);
  const [iframeLink, setIframeLink] = useState("");
  const [docLink, setDocLink] = useState("");
  const [loader, setLoader] = useState(true);
  const [dateEnd, setDateEnd] = useState(false);

  var idValue = localStorage.getItem("id");
  var PrimaryIdval = localStorage.getItem("PrimaryIdval");

  useEffect(() => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
      },
      (error) => {}
    );

    if (userUUID) {
      setSubscriptionsUrl("/user_myoutgoings/" + uuid + "/" + userUUID);
      UserService.userProfile(userUUID).then((response) => {
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      setSubscriptionsUrl("/myoutgoings/" + uuid);
    }

    // get single subscription details by ID
    UserService.getSubscriptionDetailsById(subId).then(
      (response) => {
        setSubscriptionDetails(response.data);

        const date = new Date(response.data.rows[0].endDate);
        const year = date.getFullYear();

        if (year > 2100) {
          setDateEnd(true);
        } else {
          setDateEnd(false);
        }

        if (response.data.rows) {
          setIsSwitchOn(response.data.rows[0].reminder);
          if (userUUID) {
            setSubscriptionEditUrl(
              "/edit_user_outgoing/" +
                uuid +
                "/" +
                response.data.rows[0].id +
                "/" +
                userUUID
            );
          } else {
            setSubscriptionEditUrl(
              "/edit_outgoing/" + uuid + "/" + response.data.rows[0].id
            );
          }
        } else {
          setSubscriptionEditUrl("#");
        }
        setLoader(false);
      },
      (error) => {}
    );
  }, []);
  // show doc model
  const handleShowDocSubShow = (docUrl) => {
    setShowDocSub(true);
    const file_ext = get_url_extension(docUrl);
    setDocLink(docUrl);
    if (file_ext === "pdf") {
      setIsPdf(true);
      setIsImage(false);
      setIsDocument(false);
    } else if (
      file_ext === "png" ||
      file_ext === "jpg" ||
      file_ext === "jpeg"
    ) {
      setIsPdf(false);
      setIsImage(true);
      setIsDocument(false);
    } else if (file_ext === "doc" || file_ext === "docx") {
      setIsPdf(false);
      setIsImage(false);
      setIsDocument(true);
      const docs = [{ uri: docUrl }];
      setIframeLink(docs);
    }
  };
  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };
  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };
  return (
    <div className="page-content view-warranty-pg">
      <Container fluid>
        {/* <h3 className="title">
          {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}
        </h3> */}

        {loader ? (
          <div style={{ "margin-top": "12.4rem" }}>
            <Loading />
          </div>
        ) : (
          <>
            <span className="nav-back">
              <p className="mb-3">
                <Link to={subscriptionsUrl}>
                  <img src={leftArrow} alt="arrow" className="mr-2" />
                  {userUUID ? `Back to ${backText}’s Outgoings` : `${backText}`}
                </Link>
              </p>
            </span>
            <Row className="mt-4">
              <Col md="8" lg="8">
                <Row className="details-content mt-3">
                  <Col md="4" lg="4">
                    <h4>Outgoing details</h4>
                    <small>Category</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows
                        ? subscriptionDetails.rows[0].Category.name
                        : ""}
                    </p>
                    <small>Product Name</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows
                        ? subscriptionDetails.rows[0].productName
                        : ""}
                    </p>
                    <small>Product Type</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows
                        ? subscriptionDetails.rows[0].SubCategory.name
                        : ""}
                    </p>
                    <small>Supplier</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows
                        ? subscriptionDetails.rows[0].Supplier.name
                        : ""}
                    </p>
                    <small>Additional Details</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows &&
                      subscriptionDetails.rows[0].productDescription !== ""
                        ? subscriptionDetails.rows[0].productDescription
                        : "--"}
                    </p>
                    <small>Reminder on</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows &&
                      subscriptionDetails.rows[0].reminder
                        ? "Yes"
                        : "No"}
                    </p>
                  </Col>
                  <Col md="3" lg="3">
                    <h4>Payments details</h4>
                    <small>Amount</small>
                    <p className="view-txt">
                      £{" "}
                      {subscriptionDetails.rows
                        ? subscriptionDetails.rows[0].premiumAmount
                        : ""}
                    </p>
                    <small>Billing</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows
                        ? subscriptionDetails.rows[0].renewalFrequency
                        : ""}
                    </p>
                    <small>Contract Start Date</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows
                        ? moment(subscriptionDetails.rows[0].startDate).format(
                            "MMM Do, YYYY"
                          )
                        : "-"}
                    </p>
                    <small>Contract End Date</small>
                    <p className="view-txt">
                      {subscriptionDetails.rows &&
                      subscriptionDetails.rows[0].endDate &&
                      !dateEnd
                        ? moment(subscriptionDetails.rows[0].endDate).format(
                            "MMM Do, YYYY"
                          )
                        : "--"}
                    </p>

                    <small>Document</small>
                    {subscriptionDetails.rows &&
                    subscriptionDetails.rows[0].documentUrl ? (
                      // <Link
                      //   to={{
                      //     pathname: subscriptionDetails.rows
                      //       ? subscriptionDetails.rows[0].documentUrl
                      //       : "#",
                      //   }}
                      //   target="_blank"
                      // >
                      <p
                        className="mb-3 view-invoice-txt d-inline-block"
                        onClick={() =>
                          handleShowDocSubShow(
                            subscriptionDetails.rows[0].documentUrl
                          )
                        }
                      >
                        <img
                          src={DocIcon}
                          alt="document icon"
                          className="mx-2"
                          width="12px"
                        />
                        Contract Document
                      </p>
                    ) : (
                      // </Link>
                      <p className="view-txt">No document</p>
                    )}
                  </Col>
                </Row>
              </Col>
              {
                <Col md="4" lg="4">
                  <span className="align-right px-5 mt-3">
                    <Link to={subscriptionEditUrl} className="">
                      <Primary
                        children="Edit Outgoing"
                        size="mediumbtn"
                        variant="primary"
                        type=""
                        buttonWidth="220px"
                        font_Weight="500"
                      />
                    </Link>
                  </span>
                </Col>
              }
            </Row>
            <Modal
              show={showDocSub}
              onHide={handleShowDocSubClose}
              className="invite_au_modal doc_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mx-1">
                  <h3 className="mb-0">View Document</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mx-1">
                {isPdf ? (
                  <PDFViewer
                    document={{
                      url: docLink,
                    }}
                  />
                ) : (
                  // <>
                  //   <Document file={docLink} onLoadSuccess={onDocumentLoadSuccess}>
                  //     <Page pageNumber={pageNumber} />
                  //   </Document>
                  //   <p>
                  //     Page {pageNumber} of {numPages}
                  //   </p>
                  // </>
                  ""
                )}
                {isImage ? (
                  <img
                    src={docLink}
                    alt="Contract Document"
                    className="image-document-size"
                  />
                ) : (
                  ""
                )}
                {isDocument ? (
                  <FileViewer fileType="docx" filePath={docLink} />
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer>
                <Link
                  to={{
                    pathname: docLink,
                  }}
                  target="_blank"
                >
                  Download
                </Link>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Container>
    </div>
  );
};
export default ViewSubscriptionDetails;
