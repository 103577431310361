// common Alert block
import React from "react";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import "./Alert.scss";

function Alert(props) {
  const { message, variant, ...rest } = props;
  return (
    <div {...rest}>
      <Card className={`alert-card ${variant}-alert mb-4`}>
        <Card.Body className="p-1">{message}</Card.Body>
      </Card>
    </div>
  );
}

export default Alert;
