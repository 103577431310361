import React from 'react';
import Footer from "../Footer";

const PublicLayout = (props) => {
    return (
        <>
            {props.children}
            <Footer />
        </>
    );
}

export default PublicLayout;  