import React, { useState, useEffect } from "react";
import SideBar from "../templates/SideBar";
import AuthHeader from "../templates/AuthHeader";
import Footer from "../Footer/newFooter";

const AuthLayout = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleViewSidebar = () => setSidebarOpen(!sidebarOpen);

  var contentClass = sidebarOpen ? "open" : "";

  var splitUrl = window.location.href.split("/");

  return (
    <>
      <SideBar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />
      {/* to pass data in props children we use React.cloneElement */}
      <div
        className={
          splitUrl[3] !== "confirmation" && splitUrl[3] !== "onboardUser"
            ? `content ${contentClass}`
            : ``
        }
      >
        <AuthHeader />
        {React.cloneElement(props.children, { isOpen: sidebarOpen })}
        <Footer />
      </div>
    </>
  );
};
export default AuthLayout;
