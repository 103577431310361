import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { ChoosePlanCard } from "../../stories/Subscription.stories";
import { Primary } from "../../stories/Button.stories";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import NewCardForm from "./NewCardForm";
import UserService from "../../services/user.service";
import { Outline } from "../../stories/Button.stories";
import CardListCheckout from "./CardListCheckout";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const AccountSubscriptions = (props) => {
  let { uuid } = useParams();
  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [custId, setCustId] = useState();
  const [priceDetails, setPriceDetails] = useState();
  const [paymentList, setPaymentList] = useState([]);
  const [show, setShow] = useState(false);
  const [planId, setPlanId] = useState();
  const [planName, setPlanName] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [priceSymbol, setPriceSymbol] = useState();
  const [planAuSeat, setPlanAuSeat] = useState();
  const [planSuSeat, setPlanSuSeat] = useState();
  const [planFrequency, setPlanFrequency] = useState();
  const [planImageIcon, setPlanImageIcon] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const initialValues = { familyName: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [showChoosePayment, setShowChoosePayment] = useState(false);
  const [showCancelAccount, setShowCancelAccount] = useState(false);
  const [planDetails, setPlanDetails] = useState();
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const myRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = (planData) => {
    setShow(true);
    setFormValues({ familyName: familyDetails.FamilyData.Family.name });
    setFormErrors({});
    setIsSubmit(false);
    setPlanId(planData.id);
    setPlanName(planData.name);
    setPlanPrice(planData.price);
    setPriceSymbol(planData.Country.symbol);
    setPriceDetails(planData.stripePriceId);
    setPlanAuSeat(planData.availableSeats);
    setPlanSuSeat(planData.availableSupportSeats);
    setPlanFrequency(planData.renewalType);
    setPlanImageIcon(planData.image);
  };
  const handleSwitchPlan = () => setShow(false);
  const handlePaymentClose = () => setShowPayment(false);
  const handlePaymentShow = () => {
    setShowPayment(true);
  };

  // handle confirm submit
  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    if (planId > planDetails.id) {
      handleChoosePaymentShow();
      handleChoosePaymentClose();
    } else {
      let newPlan = {
        id: planId,
        name: planName,
        priceId: priceDetails,
        auSeats: planAuSeat,
        suSeats: planSuSeat,
      };
      localStorage.setItem("planSel", JSON.stringify(newPlan));
      history.push({
        pathname: "/downgradeSubscription/" + uuid,
        state: "ddd",
      });
    }
    setLoading(false);
  };

  const toggleCancelAccount = () => {
    setShowCancelAccount(!showCancelAccount);
  };

  const handleCancelAccount = () => {
    setCancelLoading(true);
    let subscriptionId =
      familyDetails.FamilyData.Family.Family_Subscription.stripeSubscriptionId;
    UserService.cancelSubscriptionStripe(subscriptionId).then((res) => {
      UserService.cancelSubscription(familyDetails.FamilyData.Family.uuid).then(
        (response) => {
          setCancelLoading(false);
          setShowCancelAccount(!showCancelAccount);
          history.push({
            pathname: "/dashboard",
          });
        }
      );
    });
  };

  // choose payment method modal
  const handleChoosePaymentClose = () => setShowChoosePayment(false);
  const handleChoosePaymentShow = () => {
    // get list of customer payment
    let cusdata = { type: "card" };
    UserService.getCustomerPaymentMethodsList(cusdata, custId).then((res) => {
      setPaymentList(res.data);
      setShowChoosePayment(true);
    });
  };

  // Get Family details
  const getFamilyDetails = () => {
    setLoading(true);
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
        setPlanDetails(
          response.FamilyData.Family.Family_Subscription
            .Account_Subscription_Plan
        );
        setCustId(response.FamilyData.Family.stripeCustomerId);
        getPlanList();
      }
    );
  };

  const getPlanList = () => {
    UserService.planList().then(
      (response) => {
        setSubscriptionPlans(response);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const setNewCard = (cardData) => {
    setShowPayment(false);
    let pytMethods = paymentList;
    pytMethods.push(cardData);
    setPaymentList(pytMethods);
    setShowToast(true);
    setSuccessToast(true);
    setToastMessage("New Card has been added successfully");
  };

  useEffect(() => {
    getFamilyDetails();
  }, []);

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  return (
    <div className="page-content subscription-pg tab-btn">
      <Container fluid>
        {/* toast */}
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {/* <div className="toast-div" ref={myRef}>
                    <Toast
                        onClose={() => setShowToast(false)}
                        show={showToast}
                        delay={3000}
                        autohide
                    >
                        <Toast.Body>
                        <Row>
                            <Col md="2">
                            <img src={SuccessCheck} className="rounded me-2" alt="" />
                            </Col>
                            <Col md="10">{toastMessage}</Col>
                        </Row>
                        </Toast.Body>
                    </Toast>
                </div> */}

        {/* <h3 className="title">
                    {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}
                </h3> */}
        {/* <span className="nav-back">
                    <p className="mb-3">
                    <Link to={`/family_dashboard/${uuid}`}>
                        <img src={leftArrow} alt="arrow" className="mr-2" />
                        Back to Group Dashboard
                    </Link>
                    </p>
                </span> */}

        <Row>
          <h3>Elderly Life Account Plans</h3>
          <p className="sub-title">
            You can always choose a suitable plan for your Family account on
            Elderly Life Platform
          </p>
        </Row>

        {loading && (
          <Row>
            <Col md="12" lg="12" className="mt-4 pt-4 text-center">
              <button className="btn loading-btn" type="button">
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
                Loading . . .
              </button>
            </Col>
          </Row>
        )}

        {/* {details} */}
        {!loading && subscriptionPlans.length > 0 && (
          <div>
            <Tabs
              defaultActiveKey={
                planDetails ? planDetails.renewalType : "monthly"
              }
              id="uncontrolled-tab-example"
              className="tab-container"
            >
              <Tab eventKey="monthly" title="Monthly" className="tab-monthly">
                <Row className="subscriptionPlan-card">
                  <p className="content-txt">
                    All the below plans will have access to all the modules of
                    the Elderly Life Platform
                  </p>

                  {subscriptionPlans.map((plan, index) =>
                    plan.renewalType == "monthly" ? (
                      <Col md="4" lg="3" key={index}>
                        <ChoosePlanCard
                          planName={plan.name}
                          planPrice={plan.price}
                          priceSymbol={plan.Country.symbol}
                          planDuration={plan.renewalType}
                          benefits={plan.availableSeats}
                          planImage={plan.image}
                          currentPlan={
                            plan.id === planDetails.id ? true : false
                          }
                          buttonClick={() => handleShow(plan)}
                        />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </Row>
              </Tab>
              <Tab eventKey="annual" title="Annual" className="tab-annual">
                <Row className="subscriptionPlan-card">
                  <p className="content-txt">
                    All the below plans will have access to all the modules of
                    the Elderly Life Platform
                  </p>
                  {subscriptionPlans.map((plan, index) =>
                    plan.renewalType == "yearly" ? (
                      <Col md="4" lg="3" key={index}>
                        <ChoosePlanCard
                          planName={plan.name}
                          planPrice={plan.price}
                          priceSymbol={plan.Country.symbol}
                          planDuration={plan.renewalType}
                          benefits={plan.availableSeats}
                          planImage={plan.image}
                          currentPlan={
                            plan.id === planDetails.id ? true : false
                          }
                          buttonClick={() => handleShow(plan)}
                        />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </Row>
              </Tab>
            </Tabs>

            <div className="cancel-act" onClick={toggleCancelAccount}>
              Cancel Account Plan1
            </div>
            <div className="cancel-act-txt">
              We as a business, sincerely apologise if you had some problems in
              your Elderly Life journey. Please let us know if we can help you
              by solving your problem.
            </div>
          </div>
        )}

        {/* Choose Plan Modal */}
        <Modal show={show} onHide={handleClose} className="choose_plan_modal">
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>{formValues.familyName}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="family-form" onSubmit={handleConfirmSubmit}>
              {Object.keys(formErrors).length !== 0 && isSubmit ? (
                <p className="error-txt-msg">{formErrors.msg} </p>
              ) : (
                ""
              )}
              <input
                type="hidden"
                name="familyName"
                value={formValues.familyName}
              />
              <p className="mb-0 mt-4">Confirm Account Plan</p>
              <Card className="mt-2 mb-2">
                <Card.Body className="p-3">
                  <span className="action-right card-img">
                    <Image src={planImageIcon} />
                  </span>
                  <span>
                    <h3 className="mb-0">{planName ? planName : ""}</h3>
                    <p className="card-subtext">
                      {priceSymbol} {planPrice}/{planFrequency} plan{" "}
                    </p>
                    <p className="mb-1 card-text">
                      {planAuSeat ? planAuSeat : "0"} Main Users &nbsp; &nbsp;
                      &nbsp; {planSuSeat ? planSuSeat : "0"} Support Seats
                    </p>
                  </span>
                </Card.Body>
              </Card>
              <p className="subtext">
                Changed your mind?{" "}
                <Link
                  to={`/accountSubscriptions/${uuid}`}
                  className="modal-link"
                  onClick={handleSwitchPlan}
                >
                  {" "}
                  Choose another plan{" "}
                </Link>
              </p>
              <div className="text-center mt-5">
                <Primary
                  children="Proceed to Payment"
                  variant="primary"
                  size="mediumbtn"
                  type="submit"
                  buttonWidth="230px"
                />
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Choose a payment method */}
        <Modal
          show={showChoosePayment}
          onHide={handleChoosePaymentClose}
          className="choose_paymentMethod_modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Choose a payment method</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2">
              <Outline
                children="+ Add New card"
                variant="outline"
                size="mediumbtn"
                onClick={() => {
                  handlePaymentShow();
                }}
                buttonWidth="100%"
              />
            </div>

            {paymentList.length != 0 ? (
              <CardListCheckout
                customerId={custId}
                priceId={priceDetails}
                planId={planId}
                paymentList={paymentList}
                subscription={
                  familyDetails.FamilyData.Family.Family_Subscription
                    .stripeSubscriptionId
                }
                familyName={formValues.familyName}
                userUUID={uuid}
                familyUUID={familyDetails.FamilyData.Family.uuid}
              />
            ) : (
              <>
                <p className="mt-4">Saved cards ( 0 )</p>
                <div className="text-center saved-card-empty">
                  No saved cards
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>

        {/* Payment modal */}
        <Modal
          show={showPayment}
          onHide={handlePaymentClose}
          className="payment_modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Add New Card</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-3 subtext">
              Add the details below to save a new card
            </p>
            <NewCardForm
              customerId={custId}
              priceId={priceDetails}
              planId={planId}
              familyName={formValues.familyName ? formValues.familyName : ""}
              setNewCard={setNewCard}
            />
          </Modal.Body>
        </Modal>

        {/* Cancel Payment modal */}
        <Modal
          show={showCancelAccount}
          onHide={toggleCancelAccount}
          className="choose_paymentMethod_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Cancel Account Plan</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="cancel-plan-txt">
              You have opted to cancel your Family account plan on Elderly Life.
            </div>
            {cancelLoading && (
              <div className="loader">
                <span className="spinner-border spinner-border-md" />
              </div>
            )}
            <div className="cancel-plan-content">
              Members of your Family will loose access to the data that they
              have created on Elderly Life.
            </div>
            <p>Do you want to continue?</p>
            <div className="cancel-account-btns align-center">
              <Primary
                children="Cancel"
                size="mediumbtn"
                variant="info"
                type=""
                buttonWidth="120px"
                font_Weight="500"
                onClick={toggleCancelAccount}
              />
              <Primary
                children="Yes"
                size="mediumbtn"
                variant="primary"
                type=""
                buttonWidth="120px"
                font_Weight="700"
                onClick={handleCancelAccount}
              />
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};
export default AccountSubscriptions;
