import React from "react";
import InvitationCards from "../components/Cards/InvitationCards";

export default {
  title: "Cards/Family Invitation",
  component: InvitationCards,
  args: {
    message: "ActionAlert message",
  },
};

const Template = (args) => <InvitationCards {...args} />;

export const Invite = Template.bind({});
Invite.args = {
  familyName: "Walker's group",
  userRole: "Account User",
  variant: "invite",
  invitedBy: "Hilmary Walker",
};
