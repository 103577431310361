import React from 'react'
import { Card } from "react-bootstrap";
import './Cards.scss'

function CountCard (props) {
    const { variant, heading, value, ...rest } = props
    return (
        <div {...rest}>
            <Card className={`listing-cards ${variant}-cards`}>
              <Card.Body className="p-1">
                {/* <span className="action-text">{message}</span> */}
                <p className="count-heading">{heading}</p>
                <p className="number-txt">{value}</p>
                
              </Card.Body>
            </Card>
           
        </div>
    )
}

export default CountCard