import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import './Cards.scss'
function BenefitsCard(props) {

    const { benefitTitle, logo, banner, link, linkText, ...rest } = props
    return (
        <div>
            <Card className={`benefits-card`}>
                <a href={link} style={{ textDecoration: `none` }} target='_blank' alt={link}>
                    <Card.Body>
                        <div className='benefits-card-top' style={{ backgroundImage: `url("${banner}")` }} />
                        <div className='benefits-card-circle d-flex justify-content-center align-items-center'><img style={{ width: "75px", height: "75px", borderRadius: "50px" }} src={logo} /></div>
                        <div className='mt-5'>
                            <span className="medium-text bold benefits-card-title" title={benefitTitle} alt={benefitTitle}>{benefitTitle}</span>
                            <p className="sub-title link text-blue mt-1">
                                {/* <a href={link} style={{ textDecoration: `none` }} target='_blank' alt={link}>{linkText}</a> */}

                                <small>{linkText}</small>
                            </p>
                        </div>
                    </Card.Body>
                </a>
            </Card>
        </div>
    )
}

export default BenefitsCard
