import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Primary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import DocIcon from "../../assets/images/doc_icon.png";
import moment from "moment";
import DocViewer from "react-doc-viewer";
import Modal from "react-bootstrap/Modal";
import PDFViewer from "pdf-viewer-reactjs";
import FileViewer from "react-file-viewer";
import Loading from "../../common/Loading";

const ViewWarrantyDetails = (props) => {
  var contentClass = props.isOpen ? "content open" : "content";
  let history = useHistory();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [isPdf, setIsPdf] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isDocument, setIsDocument] = useState(false);

  const [familyDetails, setFamilyDetails] = useState([]);
  const [warrantyDetails, setWarrantyDetails] = useState([]);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var idValue = localStorage.getItem("id");
  var PrimaryIdval = localStorage.getItem("PrimaryIdval");
  const [isEmpty, setIsEmpty] = useState(false);
  let { uuid, warId, userUUID } = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [warrantiesUrl, setWarrantiesUrl] = useState("");
  const [warrantyEditUrl, setWarrantyEditUrl] = useState("");
  const [backText, setBackText] = useState("Back to My Warranties");
  const [showDocSub, setShowDocSub] = useState(false);
  const [iframeLink, setIframeLink] = useState("");
  const [docLink, setDocLink] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
      },
      (error) => {}
    );

    if (userUUID) {
      setWarrantiesUrl("/user_warranties/" + uuid + "/" + userUUID);
      UserService.userProfile(userUUID).then((response) => {
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      setWarrantiesUrl("/warranties/" + uuid);
    }

    // get single warranty details by ID
    UserService.getWarrantyDetailsById(warId).then(
      (response) => {
        setWarrantyDetails(response.data);
        if (response.data.rows) {
          setIsSwitchOn(response.data.rows[0].reminder);
          if (userUUID) {
            setWarrantyEditUrl(
              "/edit_user_warranty/" +
                uuid +
                "/" +
                response.data.rows[0].id +
                "/" +
                userUUID
            );
          } else {
            setWarrantyEditUrl(
              "/edit_warranty/" + uuid + "/" + response.data.rows[0].id
            );
          }
        } else {
          setWarrantyEditUrl("#");
        }
        setLoader(false);
      },
      (error) => {}
    );
  }, []);
  const handleFrequency = (freq) => {
    switch (freq) {
      case "6M":
        return "6 Months";
      case "12M":
        return "12 Months";
      case "24M":
        return "24 Months";
      case "36M":
        return "36 Months";
      case "48M":
        return "48 Months";
      default:
        return "";
    }
  };
  // show doc model
  const handleShowDocSubShow = (docUrl) => {
    setShowDocSub(true);
    const file_ext = get_url_extension(docUrl);
    setDocLink(docUrl);
    if (file_ext === "pdf") {
      setIsPdf(true);
      setIsImage(false);
      setIsDocument(false);
    } else if (
      file_ext === "png" ||
      file_ext === "jpg" ||
      file_ext === "jpeg"
    ) {
      setIsPdf(false);
      setIsImage(true);
      setIsDocument(false);
    } else if (file_ext === "doc" || file_ext === "docx") {
      setIsPdf(false);
      setIsImage(false);
      setIsDocument(true);
      const docs = [{ uri: docUrl }];
      setIframeLink(docs);
    }
  };
  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };
  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };

  return (
    <div className="page-content view-warranty-pg">
      <Container fluid>
        {/* <h3 className="title">
          {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}
        </h3> */}

        {loader ? (
          <div style={{ "margin-top": "12.4rem" }}>
            <Loading />
          </div>
        ) : (
          <>
            <span className="nav-back">
              <p className="mb-3">
                <Link to={warrantiesUrl}>
                  <img src={leftArrow} alt="arrow" className="mr-2" />
                  {userUUID
                    ? `Back to ${backText}’s Warranties`
                    : `${backText}`}
                </Link>
              </p>
            </span>
            <Row className="mt-4">
              <Col md="8" lg="8">
                <Row className="mt-3 details-content">
                  <Col md="6" lg="6">
                    <h4>Basic details</h4>
                    <small>Category</small>
                    <p className="view-txt">
                      {warrantyDetails.rows
                        ? warrantyDetails.rows[0].Category.name
                        : ""}
                    </p>
                    <small>Product Type</small>
                    <p className="view-txt">
                      {warrantyDetails.rows
                        ? warrantyDetails.rows[0].SubCategory.name
                        : ""}
                    </p>
                    <small>Supplier</small>
                    <p className="view-txt">
                      {warrantyDetails.rows
                        ? warrantyDetails.rows[0].Supplier.name
                        : ""}
                    </p>
                    <small>Additional Details</small>
                    <p className="view-txt">
                      {warrantyDetails.rows &&
                      warrantyDetails.rows[0].additionalDetails !== ""
                        ? warrantyDetails.rows[0].additionalDetails
                        : "--"}
                    </p>
                    <small>Reminder on</small>
                    <p className="view-txt">
                      {warrantyDetails.rows && warrantyDetails.rows[0].reminder
                        ? "Yes"
                        : "No"}
                    </p>
                  </Col>
                  <Col md="6" lg="6">
                    <h4>Warranty details</h4>
                    <small>Price of the product</small>
                    <p className="view-txt">
                      £{" "}
                      {warrantyDetails.rows
                        ? warrantyDetails.rows[0].price
                        : ""}
                    </p>
                    <small>Purchase Date</small>
                    <p className="view-txt">
                      {warrantyDetails.rows
                        ? moment(warrantyDetails.rows[0].purchaseDate).format(
                            "MMM Do, YYYY"
                          )
                        : ""}
                    </p>
                    <small>Warranty Term</small>
                    <p className="view-txt">
                      {warrantyDetails.rows
                        ? handleFrequency(
                            warrantyDetails.rows[0].warrantyTenure
                          )
                        : ""}
                    </p>
                    <small>Item Code</small>
                    <p className="view-txt">
                      {warrantyDetails.rows &&
                      warrantyDetails.rows[0].itemCode !== ""
                        ? warrantyDetails.rows[0].itemCode
                        : "--"}
                    </p>
                    <small>Serial Number</small>
                    <p className="view-txt">
                      {warrantyDetails.rows &&
                      warrantyDetails.rows[0].serialNumber !== ""
                        ? warrantyDetails.rows[0].serialNumber
                        : "--"}
                    </p>
                    <small>Document</small>
                    {warrantyDetails.rows &&
                    warrantyDetails.rows[0].invoiceDocument ? (
                      // <Link
                      //   to={{
                      //     pathname: warrantyDetails.rows
                      //       ? warrantyDetails.rows[0].invoiceDocument
                      //       : "#",
                      //   }}
                      //   target="_blank"
                      // >
                      <p
                        className="mb-3 view-invoice-txt"
                        style={{ width: "fit-content" }}
                        onClick={() =>
                          handleShowDocSubShow(
                            warrantyDetails.rows[0].invoiceDocument
                          )
                        }
                      >
                        <img
                          src={DocIcon}
                          alt="document icon"
                          className="mx-2"
                        />
                        Invoice Document
                      </p>
                    ) : (
                      // </Link>
                      <p className="view-txt">No document</p>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col md="4" lg="4">
                <span className="align-right px-5">
                  <Link to={warrantyEditUrl} className="">
                    <Primary
                      children="Edit Warranty"
                      size="mediumbtn"
                      variant="primary"
                      type=""
                      buttonWidth="120%"
                      font_Weight="500"
                    />
                  </Link>
                </span>
              </Col>
            </Row>
            <Modal
              show={showDocSub}
              onHide={handleShowDocSubClose}
              className="invite_au_modal doc_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mx-1">
                  <h3 className="mb-0">View Document</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mx-1">
                {isPdf ? (
                  <PDFViewer
                    document={{
                      url: docLink,
                    }}
                  />
                ) : (
                  // <>
                  //   <Document file={docLink} onLoadSuccess={onDocumentLoadSuccess}>
                  //     <Page pageNumber={pageNumber} />
                  //   </Document>
                  //   <p>
                  //     Page {pageNumber} of {numPages}
                  //   </p>
                  // </>
                  ""
                )}
                {isImage ? (
                  <img
                    src={docLink}
                    alt="Contract Document"
                    className="image-document-size"
                  />
                ) : (
                  ""
                )}
                {isDocument ? (
                  <FileViewer fileType="docx" filePath={docLink} />
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer>
                <Link
                  to={{
                    pathname: docLink,
                  }}
                  target="_blank"
                >
                  Download
                </Link>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Container>
    </div>
  );
};
export default ViewWarrantyDetails;
