import React from "react";
import { Row, Col } from "react-bootstrap";

function PaymentMtdCard({
  setShowPaymentMtd,
  paymentMethodLoading,
  activePaymentMethod,
}) {
  return (
    <div className="account-plan-card mb-4 ">
      {!paymentMethodLoading && (
        <Row className="p-4 pt-3 space-between">
          <Col className="col-auto">
            <p className="mb-0 medium-text">
              {activePaymentMethod.card.brand} ending in
            </p>
            <p className="mt-1 mb-2 title-heading">
              **** **** **** {activePaymentMethod.card.last4}
            </p>
            <p className="mt-0 mb-0 sub-text">
              Expiry date : {activePaymentMethod.card.exp_month}/
              {activePaymentMethod.card.exp_year}
            </p>
          </Col>
          <Col className="p-0 mr-2 col-auto">
            <button
              onClick={() => {
                setShowPaymentMtd(true);
              }}
              className="mt-4 change-btn"
            >
              <span className="font-large text-blue">Change Card</span>
            </button>
          </Col>
        </Row>
      )}
      {paymentMethodLoading && (
        <Row>
          <Col className="text-center">
            <button className="btn loading-btn" type="button">
              <span
                className="spinner-border spinner-border-sm mt-5 mr-2"
                role="status"
              ></span>
              Loading Payment method . . .
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default PaymentMtdCard;
