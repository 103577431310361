import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Primary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import { Medium } from "../../stories/Input.stories";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import docIcon from "../../assets/images/doc_icon.png";
import closeIcon from "../../assets/images/close_icon.png";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const WarrantyAdd = (props) => {
  let history = useHistory();
  const myRef = useRef(null);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [browserLoading, setBrowserLoading] = useState(false);
  const [browserMsg, setBrowserMsg] = useState("");
  const [browserErrMsg, setBrowserErrMsg] = useState("");
  const [uploadedDocUrl, setUploadedDocUrl] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [warrantiesUrl, setWarrantiesUrl] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [backText, setBackText] = useState("Back to My Warranties");
  const executeScroll = () => myRef.current.scrollIntoView();
  const [addUpdate, setaddUpdate] = useState(false);
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const initialValues = {
    category: null,
    product_type: "",
    supplier: "",
    additional_details: "",
    price: "",
    date: "",
    warranty_tenure: "",
    item_code: "",
    serial_number: "",
    invoice_doc: "",
    uploaded_doc: "",
  };
  const [formValues, setFormValues] = useState(initialValues);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "0.8rem",
      borderRadius: "5px",
      borderStyle: "none",
      cursor: "pointer",
      padding: "6px 0",
      border: "1px solid #5985ac !important",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };
  const initialCategoryOption = [{ value: "", label: "select a category" }];
  const [categoryOption, setCategoryOption] = useState(initialCategoryOption);
  const initialSubCategoryOption = [
    { value: "", label: "select a product type" },
  ];
  const [subCategoryOption, setSubCategoryOption] = useState(
    initialSubCategoryOption
  );
  const initialSupplierOption = [{ value: "", label: "select a supplier" }];
  const [supplierOption, setSupplierOption] = useState(initialSupplierOption);
  const initialWarrantyOption = [
    { value: "", label: "Warranty Term" },
    { value: "6M", label: "6 months" },
    { value: "12M", label: "12 months" },
    { value: "24M", label: "24 months" },
    { value: "36M", label: "36 months" },
    { value: "48M", label: "48 months" },
  ];
  const [warrantyOption, setWarrantyOption] = useState(initialWarrantyOption);
  const [showInvoiceFile, setShowInvoiceFile] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedTenure, setSelectedTenure] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  let { uuid, userUUID } = useParams();
  const handleChange = (e, action) => {
    if (action) {
      var targetData = {
        name: action.name,
        value: e.value,
      };
      if (action.name === "category") {
        setSelectedCategory(e);
        setFormErrors({
          category: "",
        });
      }
      if (action.name === "product_type") {
        setSelectedProduct(e);
        setFormErrors({
          product_type: "",
        });
      }
      if (action.name === "supplier") {
        setSelectedSupplier(e);
        setFormErrors({
          supplier: "",
        });
      }
      if (action.name === "warranty_tenure") {
        setSelectedTenure(e);
        setFormErrors({
          warranty_tenure: "",
        });
      }
    } else if (e.target.name === "price") {
      var targetData = {
        name: e.target.name,
        value:
          e.target.value.indexOf(".") >= 0
            ? e.target.value.substr(0, e.target.value.indexOf(".")) +
              e.target.value.substr(e.target.value.indexOf("."), 3)
            : e.target.value,
      };
    } else {
      var targetData = {
        name: e.target.name,
        value: e.target.value,
      };
    }
    const { name, value } = targetData;
    setFormValues({ ...formValues, [name]: value });

    if (name === "category") {
      // get subcategory listing
      let catId = value;
      UserService.getSubcategoryByCat(catId).then(
        (response) => {
          var sub_arr2 = response.data.map((v) => ({
            value: v.id,
            label: v.name,
          }));
          setSubCategoryOption(sub_arr2);
          setSelectedProduct({ value: "", label: "Select product type" });
          setSelectedSupplier({ value: "", label: "Select a supplier" });
        },
        (error) => {}
      );
    }
    if (name === "product_type") {
      // get supplier listing
      let subcatId = value;
      UserService.getSuppliersBySubCategory(subcatId).then(
        (response) => {
          var sup_arr2 = response.data.map((v) => ({
            value: v.id,
            label: v.name,
          }));
          setSupplierOption(sup_arr2);
          setSelectedSupplier({ value: "", label: "Select a supplier" });
        },
        (error) => {}
      );
    }
    if (name === "uploaded_doc") {
      setaddUpdate(true);
      setBrowserLoading(true);
      setBrowserErrMsg("");
      // upload file
      var documentFile = e.target.files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var MAX_FILE_SIZE = 2; // 2MB
        if (
          [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "application/pdf",
            // "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(documentFile.type)
        ) {
          let fileSize = documentFile.size / 1024 / 1024;
          if (fileSize > MAX_FILE_SIZE) {
            setBrowserErrMsg("OOPS! File size is above 2MB.");
            setBrowserLoading(false);
          } else {
            uploadFile(documentFile);
          }
        } else {
          setBrowserErrMsg("OOPS! The file format doesn't support");
          setBrowserLoading(false);
        }
      };
      if (documentFile != undefined) {
        reader.readAsDataURL(documentFile);
      } else {
        setBrowserLoading(false);
      }
    }
    if (name === "price") {
      setFormErrors({
        price: "",
      });
    }
    if (name === "date") {
      setFormErrors({
        date: "",
      });
    }
  };
  // file upload
  const uploadFile = (data) => {
    setBrowserErrMsg("");
    const formData = new FormData();
    formData.append("file", data, data.name.replace(/ /g, ""));
    UserService.uploadUserDocuments(formData).then(
      (response) => {
        setShowInvoiceFile(data.name.substr(0, 65));
        setBrowserLoading(false);
        setBrowserMsg("Successfully uploaded");
        setUploadedDocUrl(response.link);
        setaddUpdate(false);
      },
      (error) => {
        setBrowserLoading(false);
        setBrowserErrMsg("Something went error");
        setaddUpdate(false);
      }
    );
  };

  const removeUploadedFile = () => {
    setShowInvoiceFile("");
    setUploadedDocUrl("");
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };
  // validation
  const validate = (values) => {
    const errors = {};
    setLoading(false);
    if (!values.warranty_tenure) {
      errors.msg = "Please enter the warranty tenure";
      errors.warranty_tenure = "Yes";
    }
    if (!values.price || values.price == 0 || values.price < 0) {
      errors.msg = "Please enter the amount";
      errors.price = "Yes";
    }
    if (!values.category) {
      errors.msg = "Please enter the category";
      errors.category = "Yes";
    }
    if (!values.product_type) {
      errors.msg = "Please enter the product type";
      errors.product_type = "Yes";
    }
    if (!values.supplier) {
      errors.msg = "Please enter the supplier";
      errors.supplier = "Yes";
    }
    if (!values.date) {
      errors.msg = "Please enter the start date";
      errors.date = "Yes";
    }
    return errors;
  };

  const getFamilyDetails = (userId) => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);
        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            setUserCreateUpdate(response.FamilyData.id);
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };

  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdate(response.FamilyData.id);
        setUserCreateUpdateFor(response.FamilyData.id);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setWarrantiesUrl("/user_warranties/" + uuid + "/" + userUUID);
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setWarrantiesUrl("/warranties/" + uuid);
    }

    // get category listing
    let moduleDataId = "2";
    UserService.getCategoryByModule(moduleDataId).then(
      (response) => {
        var arr2 = response.data.map((v) => ({ value: v.id, label: v.name }));
        setCategoryOption(arr2);
      },
      (error) => {}
    );
    // add subscription
    if (
      Object.keys(formErrors).length === 0 &&
      formValues.price > 0 &&
      isSubmit
    ) {
      setLoading(true);
      if (selectedProduct.value === "" && selectedSupplier.value === "") {
        setFormErrors({
          product_type: "product_type",
          supplier: "supplier",
        });
      } else if (selectedProduct.value != "" && selectedSupplier.value === "") {
        setFormErrors({
          product_type: "",
          supplier: "supplier",
        });
      } else if (selectedProduct.value === "" && selectedSupplier.value != "") {
        setFormErrors({
          product_type: "product_type",
          supplier: "",
        });
      } else {
        var tenureMonth = "";
        if (formValues.warranty_tenure === "6M") {
          tenureMonth = "6";
        } else if (formValues.warranty_tenure === "12M") {
          tenureMonth = "12";
        } else if (formValues.warranty_tenure === "24M") {
          tenureMonth = "24";
        } else if (formValues.warranty_tenure === "36M") {
          tenureMonth = "36";
        } else if (formValues.warranty_tenure === "48M") {
          tenureMonth = "48";
        }
        let data = {
          CategoryId: formValues.category,
          SubCategoryId: formValues.product_type,
          SupplierId: formValues.supplier,
          additionalDetails: formValues.additional_details,
          price: formValues.price,
          purchaseDate: formValues.date,
          warrantyTenure: formValues.warranty_tenure,
          itemCode: formValues.item_code,
          serialNumber: formValues.serial_number,
          invoiceDocument: uploadedDocUrl,
          reminder: isSwitchOn ? 1 : 0,
          FamilyId: familyDetails.FamilyData.FamilyId,
          expiryDate: addMonth(formValues.date, tenureMonth),
          FamilyUserId: userCreateUpdate,
          updatedBy: userCreateUpdate,
          createdUpdatedFor: userCreateUpdateFor,
        };
        const date = new Date();
        if (moment(date).format("YYYY-MM-DD") >= data.expiryDate) {
          setFormErrors({
            warranty_tenure: "warranty_tenure",
          });
        } else {
          UserService.createWarranty(data).then(
            (response) => {
              setLoading(false);
              setSuccessToast(true);
              setShowToast(true);
              executeScroll();
              window.scrollTo(0, 0);
              setToastMessage("Warranty created successfully");
              setTimeout(() => {
                history.push(warrantiesUrl);
                setFormValues(initialValues);
              }, 2000);
            },
            (error) => {}
          );

          // end api call
          setCategoryOption(initialCategoryOption);
          setSubCategoryOption(initialSubCategoryOption);
          setSupplierOption(initialSupplierOption);
          setWarrantyOption(initialWarrantyOption);
          setShowInvoiceFile("");
          setIsSwitchOn(true);
        }
      }
    } else {
      setLoading(false);
    }
  }, [formErrors]);

  // d - is a moment() call
  const addMonth = (d, n) => {
    let fm = moment(d).add(n, "M");
    return fm.format("YYYY-MM-DD");
  };
  return (
    <div className="page-content add-warranty-pg">
      <Container fluid ref={myRef}>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row className="fixed-form-header">
            <span className="nav-back ">
              <p className="mb-3">
                <Link to={warrantiesUrl}>
                  <img src={leftArrow} alt="arrow" className="mr-2" />
                  {userUUID
                    ? `Back to ${backText}’s Warranties`
                    : `${backText}`}
                </Link>
              </p>
            </span>
            <Col md="8" lg="8" className="mt-2">
              <h3>Add a New Warranty</h3>
              <p className="sub-title">Add the below required details</p>
            </Col>
            <Col md="4" lg="4" className="mt-2">
              <span className="align-right px-5">
                {/* {loading && (
                  <span className="mx-3 spinner-border spinner-border-sm"></span>
                )} */}
                {/* <Primary
                  children="Add Warranty"
                  size="mediumbtn"
                  variant="primary"
                  type=""
                  buttonWidth="220px"
                  font_Weight="500"
                  isLoading= {loading ? "true": "false"}
                  isDisabled = {loading ? "true": "false"}
                /> */}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" className="fixed-form-content">
              <h4>1.Basic details</h4>
              {/* row 1 */}
              <Row>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Category</Form.Label>
                    <Select
                      styles={customStyles}
                      options={categoryOption}
                      defaultValue={{ label: "select a category", value: "" }}
                      value={selectedCategory}
                      onChange={handleChange}
                      name="category"
                    />
                    <Form.Text
                      className={formErrors.category ? "invalid-text" : " "}
                    >
                      Select a specific category that is applicable to the
                      warranty.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Product Type</Form.Label>
                    <Select
                      styles={customStyles}
                      options={subCategoryOption}
                      defaultValue={{
                        label: "select a product type",
                        value: "",
                      }}
                      value={selectedProduct}
                      onChange={handleChange}
                      name="product_type"
                    />
                    <Form.Text
                      className={formErrors.product_type ? "invalid-text" : " "}
                    >
                      Select a specific product type that is applicable to the
                      warranty.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              {/* Row 2 */}
              <Row>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Supplier</Form.Label>
                    <Select
                      styles={customStyles}
                      options={supplierOption}
                      defaultValue={{ label: "select a supplier", value: "" }}
                      value={selectedSupplier}
                      onChange={handleChange}
                      name="supplier"
                    />
                    <Form.Text
                      className={formErrors.supplier ? "invalid-text" : " "}
                    >
                      Select the supplier of the warranty. Select others if the
                      supplier isn't listed.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md="5" lg="4"></Col>
              </Row>
              {/* row 3 */}
              <Row>
                <Col md="10" lg="8">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">
                      Additional Details ( Optional )
                    </Form.Label>
                    <textarea
                      rows={3}
                      cols={5}
                      name="additional_details"
                      value={formValues.additional_details}
                      onChange={handleChange}
                      placeholder="some important details"
                      maxLength={150}
                    ></textarea>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md="12" lg="12" className="mt-4">
              <h4>2.Warranty details</h4>
              {/* row 1 */}
              <Row>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Price of the product</Form.Label>
                    <InputGroup className="mb-0">
                      <FormControl
                        type="number"
                        className=""
                        placeholder="Ex : £999"
                        size="medium"
                        onWheel={() => document.activeElement.blur()}
                        name="price"
                        value={
                          formValues.price < 0
                            ? ""
                            : formValues.price == 0
                            ? formValues.price
                            : formValues.price == 0.0
                            ? formValues.price
                            : formValues.price > 0.0
                            ? formValues.price
                            : formValues.price
                        }
                        onChange={handleChange}
                      />
                      <InputGroup.Text className="pwd-icon">
                        <span className="pound-input">£</span>
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Text
                      className={formErrors.price ? "invalid-text" : " "}
                    >
                      Enter the price value for which you have purchased the
                      product
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md="5" lg="4"></Col>
              </Row>
              {/* row 2 */}
              <Row>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Purchase Date</Form.Label>
                    <Medium
                      type="date"
                      placeholder="Ex 20/10/2022"
                      size="medium"
                      name="date"
                      value={formValues.date}
                      onChange={handleChange}
                    />
                    <Form.Text
                      className={formErrors.date ? "invalid-text" : " "}
                    >
                      Select the date which is mentioned in the invoice
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Warranty Term</Form.Label>
                    <Select
                      styles={customStyles}
                      options={warrantyOption}
                      defaultValue={{
                        label: "select warranty end date",
                        value: "",
                      }}
                      onChange={handleChange}
                      name="warranty_tenure"
                    />
                    {/* <select value={formValues.warrant_tenure} name="warrant_tenure" onChange={handleChange} className="form-select">
                    <option value="">select warranty period</option>
                    <option value="6 months">6 months</option>
                    <option value="12 months">12 months</option>
                    <option value="24 months">24 months</option>
                    <option value="36 months">36 months</option>
                  </select> */}
                    {/* <Form.Text
                      className={
                        formErrors.warranty_tenure ? "invalid-text" : " "
                      }
                    >
                      Select the warranty period mentioned by the product
                      supplier
                    </Form.Text> */}
                    {formErrors.warranty_tenure ? (
                      <Form.Text className={"invalid-text"}>
                        Expiry date must be greater than Today's date
                      </Form.Text>
                    ) : (
                      <Form.Text className={" "}>
                        Select the warranty period mentioned by the product
                        supplier
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {/* row 3 */}
              <Row>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Item Code ( Optional )</Form.Label>
                    <Medium
                      type="text"
                      placeholder="Ex : PHILUK2005A"
                      size="medium"
                      name="item_code"
                      value={formValues.item_code}
                      onChange={handleChange}
                    />
                    <Form.Text className=" ">
                      Add the unique item code
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">
                      Serial Number ( Optional )
                    </Form.Label>
                    <Medium
                      type="text"
                      placeholder="Ex : 15223515355 23552"
                      size="medium"
                      name="serial_number"
                      value={formValues.serial_number}
                      onChange={handleChange}
                    />
                    <Form.Text className=" ">
                      Enter the serial number of the above product
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              {/* row 4 */}
              <Row>
                <Col md="6" lg="6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">
                      Invoice Document ( Optional )
                      <span className="mx-3 align-right invalid-text">
                        {browserErrMsg ? browserErrMsg : ""}
                      </span>
                    </Form.Label>
                    <Medium
                      type="text"
                      placeholder=""
                      size="medium"
                      name="invoice_doc"
                      onChange={handleChange}
                      multiple
                      readOnly
                    />
                    {showInvoiceFile && (
                      <div className="doc-file">
                        <span className="icon">
                          <img src={docIcon} alt="Document" />
                        </span>
                        <span className="file">{showInvoiceFile}</span>
                        <span className="remove" onClick={removeUploadedFile}>
                          <img src={closeIcon} alt="Document" />
                        </span>
                      </div>
                    )}
                    <Form.Text className=" ">
                      Upload the invoice document in png, jpg, jpeg, pdf, doc,
                      docx format. ( Max size 2MB )
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col
                  md="4"
                  lg="2"
                  className={
                    showInvoiceFile
                      ? "disable-upload-invoice-btn"
                      : "upload-invoice-btn"
                  }
                >
                  <label>
                    <input
                      type="file"
                      id="upload"
                      name="uploaded_doc"
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      value={formValues.uploaded_doc}
                      onChange={handleChange}
                      accept="image/png, image/jpeg, application/pdf,  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    Browse
                    {browserLoading && (
                      <span className="mx-3 spinner-border spinner-border-sm"></span>
                    )}
                  </label>
                  {/* <button className="upload-invoice-btn">Browse</button> */}
                </Col>
              </Row>
              <Row>
                <Col md="3" lg="5">
                  <Form.Group className="my-3" controlId="formBasicEmail">
                    <Form.Switch
                      onChange={onSwitchAction}
                      id="custom-switch"
                      label=""
                      checked={isSwitchOn}
                      className="align-right sub-form-switch"
                    />
                    <p
                      className={
                        isSwitchOn
                          ? "reminder-switch-on"
                          : "reminder-switch-off"
                      }
                    >
                      Reminder on
                    </p>

                    <Form.Text>
                      Standard reminder settings will be applied to this
                      warranty. You can change the settings in the reminder
                      settings on your profile.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={addUpdate ? "my-3 update-add-btn" : "my-3"}>
            <Primary
              children="Add Warranty"
              size="mediumbtn"
              variant="primary"
              type=""
              buttonWidth="220px"
              font_Weight="500"
              isLoading={loading ? "true" : "false"}
              isDisabled={loading ? "true" : "false"}
            />
          </Row>
        </Form>
      </Container>
    </div>
  );
};
export default WarrantyAdd;
