import React from "react";
import { Card } from "react-bootstrap";
import "./Cards.scss";

function NotificationDetailsCard(props) {
  const {
    categoryName,
    description,
    variant,
    title,
    date,
    expired,
    isExpired,
    viewPage,
    ...rest
  } = props;
  return (
    <div>
      <Card
        className={`story-card notification-cards detailed-noti-card ${variant}-cards ${expired}`}
      >
        <Card.Body className="p-1 d-flex flex-column">
          <span>
            <span className="cat-txt m-0">{categoryName}</span>

            <h3 className="card-title my-2">{title}</h3>
            {/* <span className="side-arrow action-right">
              
            </span> */}
            <p className="subtitle mb-3">{description}</p>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="card-subtitle mt-auto">Event log on {date}</p>
              <p
                className="card-subtitle mt-auto"
                style={{ cursor: "pointer" }}
                onClick={viewPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                >
                  <path
                    id="right_arrow"
                    d="M982,2282.633l4.973,5.367L982,2293.366l1.514,1.634,6.486-7-6.486-7Z"
                    transform="translate(-982 -2281)"
                    fill="#003a6f"
                  />
                </svg>
              </p>
            </div> */}
            <p className="card-subtitle mt-auto">Event log on {date}</p>
          </span>
        </Card.Body>
      </Card>
    </div>
  );
}

export default NotificationDetailsCard;
