import React from "react"
import Button from '../components/Button/Button'

export default {
    title: 'UI/Button',
    component: Button,
    args: {
        children: 'Button',

    },
    argTypes: {
        onClick: { action: 'clicked' }
    }
}

const Template = args => <Button {...args} />

export const Primary = Template.bind({})
Primary.args = {
    variant: 'primary',
    size: 'mediumbtn'
}

export const Secondary = Template.bind({})
Secondary.args = {
    variant: 'secondary',
    size: 'mediumbtn'
}
export const Tertiary = Template.bind({})
Tertiary.args = {
    variant: 'tertiary',
    size: 'smallbtn'

}

export const Outline = Template.bind({})
Outline.args = {
    variant: 'outline',
    size: 'outlineMedium'
}

export const Large = Template.bind({})
Large.args = {
    variant: 'primary',
    size: 'largebtn'
}
export const Info = Template.bind({})
Info.args = {
    variant: 'info',
    size: 'mediumbtn'
}
export const BlueTertiary = Template.bind({})
BlueTertiary.args = {
    variant: 'blue_tertiary',
    size: 'smallbtn'

}
export const Danger = Template.bind({})
Danger.args = {
    variant: 'danger',
    size: 'smallbtn'

}