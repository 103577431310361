import React, { useState, useEffect } from "react";
import {
  useStripe,
  CardElement,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";
import UserService from "../../services/user.service";
import { useHistory } from "react-router-dom";
import { Small } from "../../stories/Input.stories";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const initialValues = {
    name: UserDetails.firstname + " " + UserDetails.lastname,
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    //email: UserDetails.email
  };
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);

    // const cardElement = {
    //   name: CardNumberElement,
    //   exp_month: '07',
    //   exp_year: '2035',
    //   cvc: CardCvcElement,
    // }
    //const cardElement = elements.getElement('card');
    // create payment method

    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: formValues.name,
          address: {
            city: formValues.city,
            country: formValues.country,
            line1: formValues.addressLine1,
            line2: formValues.addressLine2,
            state: formValues.state,
          },
          //email:formValues.email,
        },
      })
      .then(function (result) {
        // Handle result.error or result.paymentMethod
        // attach payment method
        let attachData = {
          customer: props.customerId,
        };
        UserService.attachPaymentMethod(
          attachData,
          result.paymentMethod.id
        ).then(
          (response) => {
            setLoading(false);
            refreshPage();
          },
          (error) => {
            const errors = {};
            setLoading(false);
          }
        );
      });
  };

  function refreshPage() {
    window.location.reload();
  }

  return (
    <form className="payment-form" onSubmit={handleSubmit}>
      <label className="form-label mb-0">Card Holder Name</label>
      {/* {props.customerId} */}
      <Small
        type="test"
        placeholder="John Mike"
        size="small"
        name="name"
        value={formValues.name}
        onChange={handleChange}
      />
      {/* <label className="form-label mt-3 mb-0">Email</label>
      <Small
        type="email"
        placeholder="John@gmail.com"
        size="small"
        name="email"
        value={formValues.email}
        onChange={handleChange}
        readOnly
      /> */}
      <label className="form-label  mt-3  mb-0">Card Details</label>
      <CardElement />

      <label className="form-label mb-0">Billing Address</label>
      {/* {props.customerId} */}
      <Small
        type="test"
        placeholder="Line 1"
        size="small"
        name="addressLine1"
        value={formValues.addressLine1}
        onChange={handleChange}
      />
      <div className="my-3">
        <Small
          type="test"
          placeholder="Line 2"
          size="small"
          name="addressLine2"
          value={formValues.addressLine2}
          onChange={handleChange}
        />
      </div>
      <Row>
        <Col>
          <Small
            type="test"
            placeholder="City"
            size="small"
            name="city"
            value={formValues.city}
            onChange={handleChange}
          />
        </Col>
        <Col>
          <Small
            type="test"
            placeholder="State"
            size="small"
            name="state"
            value={formValues.state}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <div className="mt-3 mb-5">
        {/*  <Small
            type="test"
            placeholder="Country (ex: 'US', 'EG', or 'GB')"
            size="small"
            name="country"
            value={formValues.country}
            onChange={handleChange}
          /> */}
        <select
          value={formValues.country}
          name="country"
          onChange={handleChange}
        >
          <option value="" disabled selected>
            Select country
          </option>
          <option value="US">United States (US)</option>
          <option value="EG">Egypt (EG)</option>
          <option value="GB">United Kingdom of Great Britain (GB)</option>
        </select>
      </div>
      {/* <label className="form-label mb-0">Card Number</label>
      <CardNumberElement
        options={{}}
        //onChange={handleChange}
      />

      <Row>
        <Col md="6">
        <label className="form-label mb-0">Expiry</label>
      <CardExpiryElement
        options={{}}
        //onChange={handleChange}
      />
        </Col>
        <Col md="6">
        <label className="form-label mb-0">Cvc</label>
      <CardCvcElement
        options={{}}
        //onChange={handleChange}
      />
        </Col>
      </Row>           */}
      <div className="text-center">
        <Primary
          children="Add Card"
          variant="primary"
          size="mediumbtn"
          type=""
          buttonWidth="55%"
          disabled={!stripe && !loading}
        />
        {loading && <span className="spinner-border spinner-border-sm"></span>}
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </div>
    </form>
  );
};

export default CheckoutForm;
