import React from "react";
import { Row, Col } from "react-bootstrap";

const FooterVal = () => {
  return (
    <footer className="main-footer-1">
      <Row className="mx-3">
        <Col md="8" sm="8" xs="8">
          <p className="mb-0 py-2">
            ©{new Date().getFullYear()} Elderly Life Ltd. All rights reserved
          </p>
        </Col>
        <Col md="4" sm="4" xs="4" className="footer-menu">
          <a
            href="https://elderlylife.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            <div>Terms and conditions</div>
          </a>
          <a
            href="https://elderlylife.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <div>Privacy policy</div>
          </a>
        </Col>
      </Row>
    </footer>
  );
};

export default FooterVal;
