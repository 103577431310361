import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Primary } from "../../stories/Button.stories";
import { ElAccountPlanCrd } from "../../stories/ElAccountPlanCard.stories";
import { FamilyList } from "../../stories/FamilyListCard.stories";
import UserService from "../../services/user.service";
import Loading from "../../common/Loading";

const ElAccountPlan = (props) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [accountPlanList, setAccountPlanList] = useState([]);
  useEffect(() => {
    // api call
    setLoading(true);
    UserService.getUserAccountPlan(UserDetails.uuid).then(
      (response) => {
        setAccountPlanList(response.usersFamilyData);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // redirect link
  const manageSubscriptionRoute = (fuuid) => {
    history.push(`/manageSubscription/${fuuid}`);
  };
  return (
    <div className="page-content subscription-pg tab-btn">
      <Container>
        {/* {details} */}
        <Row>
          <Col md="8" lg="8">
            <h3>Elderly Life Account Plans</h3>
            <p className="sub-title">
              Find all the Families here for which are the Account Owner. You
              are being charged for all the Elderly Life accounts that are
              listed below
            </p>
          </Col>
          <Col md="4" lg="4">
            <span className="align-right">
              <Link to="/subscription" className="">
                <Primary
                  children="+ Create New Family"
                  size="mediumbtn"
                  variant="primary"
                  type=""
                  buttonWidth="220px"
                  font_Weight="500"
                />
              </Link>
            </span>
          </Col>
        </Row>
        <Row className="mt-3 elAccountDash-card">
          {loading ? (
            <div className="loadind-page">
              <Loading />
            </div>
          ) : accountPlanList.length > 0 ? (
            <>
              {accountPlanList.map((planDetail, index) => (
                <Col md="4" lg="4" key={index}>
                  <ElAccountPlanCrd
                    planName={
                      planDetail.Family.Family_Subscription
                        .Account_Subscription_Plan.name
                    }
                    planPrice=""
                    planDuration={
                      planDetail.Family.Family_Subscription
                        .Account_Subscription_Plan.renewalTyp
                    }
                    auSeats={
                      planDetail.Family.Family_Subscription
                        .Account_Subscription_Plan.availableS
                    }
                    suSeats={
                      planDetail.Family.Family_Subscription
                        .Account_Subscription_Plan.availableS
                    }
                    variant="basic"
                    familyName={planDetail.Family.name}
                    familyId={planDetail.Family.displayId}
                    firstLetter={Capitalize(planDetail.Family.name).charAt(0)}
                    isAo={planDetail.isAo ? "true" : "false"}
                    buttonClick={() =>
                      manageSubscriptionRoute(planDetail.Family.uuid)
                    }
                  />
                </Col>
              ))}
            </>
          ) : (
            <>
              <p className="sub-text">no data found</p>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};
export default ElAccountPlan;
