import React, { useState, useEffect } from "react";
import {
  useStripe,
  CardElement,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";
import UserService from "../../services/user.service";
import { useHistory } from "react-router-dom";
import { Small } from "../../stories/Input.stories";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import ErrorCheck from "../../assets/images/errorCheck.svg";

const CheckoutForm = (props) => {
  var splitUrl = window.location.href.split("/");
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  let history = useHistory();
  var isCardComplete = false;
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [exist, setexist] = useState(false);
  const [DetailsSave, setDetailsSave] = useState({});
  const [cardNo, setCardNo] = useState([]);
  const [err, seterr] = useState(false);
  const [planDet, setplanDet] = useState();
  useEffect(() => {
    var newData = [];
    for (var i = 0; i < props.paymentList.length; i++) {
      newData.push(props.paymentList[i].card.last4);
    }

    setCardNo(newData);
  }, [props.paymentList]);

  const [trialCheck, settrialCheck] = useState([]);
  const [trialCheck1, settrialCheck1] = useState([]);
  useEffect(() => {
    if (splitUrl[3] !== "subscription") {
      var dateCheck = new Date().getFullYear();
      let st = new Date("01-01-" + dateCheck).getTime();
      // get family details
      UserService.getUserSingleFamilyDetails(splitUrl[4], UserDetails.id).then(
        (response) => {
          UserService.getSubsciptionStripe(
            response.FamilyData.Family.Family_Subscription.stripeSubscriptionId
          ).then((res) => {
            settrialCheck(res);
            settrialCheck1(res.items.data[0]);
          });
        },
        (error) => {}
      );
    }
  }, []);

  if (splitUrl[3] !== "downgradeSubscription") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      var newArr = [];
      for (var i = 0; i < props.planType.length; i++) {
        if (props.planType[i].isAo === true) {
          newArr.push(props.planType[i]);
        }
      }
      setplanDet(newArr);
    }, [props.planType]);
  }
  const initialValues = {
    name:
      UserDetails.firstname === "null"
        ? ""
        : UserDetails.firstname + " " + UserDetails.lastname,
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "GB",
    isCardValid: false,
    last4digit: "",
    //email: UserDetails.email
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  //const [isCardComplete, setIsCardComplete] = useState(false);
  const handleCardChange = (e) => {
    if (e.complete) {
      isCardComplete = true;
      setFormValues({ ...formValues, isCardValid: isCardComplete });
    } else {
      setLoading(false);
      // setIsDisabled(true);
      setFormValues({ ...formValues, isCardValid: false });
    }

    if (isCardComplete || formValues.isCardValid) {
      if (
        formValues.name &&
        formValues.addressLine1 &&
        formValues.addressLine2 &&
        formValues.city &&
        formValues.state
      ) {
        // setIsDisabled(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (isCardComplete || formValues.isCardValid) {
      if (
        formValues.name &&
        formValues.addressLine1 &&
        formValues.addressLine2 &&
        formValues.city &&
        formValues.state
      ) {
        // setIsDisabled(false);
      }
    }
  };

  const handleSubmit = (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.msg = "Please enter family name";
      errors.name = "Yes";
    }
    if (!values.addressLine1) {
      errors.msg = "Please enter address linw 1";
      errors.addressLine1 = "Yes";
    }
    if (!values.addressLine2) {
      errors.msg = "Please enter address linw 2";
      errors.addressLine2 = "Yes";
    }
    if (!values.city) {
      errors.msg = "Please enter city";
      errors.city = "Yes";
    }
    if (!values.state) {
      errors.msg = "Please enter state";
      errors.state = "Yes";
    }
    setLoading(false);
    return errors;
  };

  // 7 days from today
  var date = new Date();
  date.setDate(date.getDate() + 7);
  const unixTimestamp = Math.floor(date.getTime() / 1000);

  //15 minutes from today
  // var date = new Date();
  // date.setDate(date.getDate());
  // const unixTimeVal = Math.floor(date.getTime() / 1000);
  // const unixTimestamp = unixTimeVal + 900;
  //

  useEffect(() => {
    // handle confirm submit
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // setIsDisabled(false);

      stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: formValues.name,
            address: {
              city: formValues.city,
              country: formValues.country,
              line1: formValues.addressLine1,
              line2: formValues.addressLine2,
              state: formValues.state,
            },
            //email:formValues.email,
          },
        })
        .then(function (result) {
          // Handle result.error or result.paymentMethod

          // attach payment method

          let attachData = {
            customer: props.customerId,
          };
          if (result.paymentMethod === undefined) {
            seterr(true);
            setLoading(false);
          } else {
            seterr(false);
            if (cardNo.includes(result.paymentMethod.card.last4)) {
              setexist(true);

              setTimeout(() => {
                setexist(false);
                setLoading(false);
              }, 3000);
            } else {
              setexist(false);

              UserService.attachPaymentMethod(
                attachData,
                result.paymentMethod.id
              ).then(
                (response) => {
                  let updateData = {
                    "invoice_settings[default_payment_method]":
                      result.paymentMethod.id,
                  };
                  UserService.updateCustomerStripe(
                    updateData,
                    props.customerId
                  ).then(
                    (response) => {
                      if (splitUrl[3] === "downgradeSubscription") {
                        if (props.planId === 1 || props.planId === 4) {
                          if (
                            props.planDetails.availableS === 2 ||
                            props.planDetails.availableS === 5
                          ) {
                            history.push({
                              pathname: `/downgrade_Subscription/${splitUrl[4]}`,
                              state: {
                                planId: props,
                                stripeCusID: props.SubscriptionId,
                              },
                            });
                          } else {
                            UserService.upgradeSubscription(
                              splitUrl[4],
                              props.planId,
                              props.SubscriptionId
                            ).then((response) => {
                              let subsciptionData = {
                                trial_end: "now",
                                proration_behavior: "always_invoice",
                                billing_cycle_anchor: "now",
                                "items[0][plan]": props.priceId,
                                "items[0][id]": trialCheck1.id,
                              };
                              UserService.updateSubsciptionStripe(
                                subsciptionData,
                                trialCheck.id
                              ).then((response) => {
                                if (
                                  response.status !== 400 &&
                                  response.status !== 404
                                ) {
                                  history.push({
                                    pathname: `/family_dashboard/${splitUrl[4]}`,
                                  });
                                }

                                // let data = {
                                //   price: props.priceDetails,
                                // };
                                // UserService.updateSubItemStripe(
                                //   data,
                                //   respo.items.data[0].id
                                // ).then((resp) => {
                                //
                                //
                                //   UserService.updateInvoiceStripe(
                                //     respo.latest_invoice
                                //   ).then((resp) => {
                                //
                                //
                                //     if (
                                //       resp.status !== 400 &&
                                //       resp.status !== 404
                                //     ) {
                                //       history.push({
                                //         pathname: `/family_dashboard/${splitUrl[4]}`,
                                //       });
                                //     }
                                //   });
                                // });
                              });
                            });
                          }
                        } else if (props.planId === 2 || props.planId === 5) {
                          if (props.planDetails.availableS === 5) {
                            history.push({
                              pathname: `/downgrade_Subscription/${splitUrl[4]}`,
                              state: {
                                planId: props,
                                stripeCusID: props.SubscriptionId,
                              },
                            });
                          } else {
                            UserService.upgradeSubscription(
                              splitUrl[4],
                              props.planId,
                              props.SubscriptionId
                            ).then((response) => {
                              let subsciptionData = {
                                trial_end: "now",
                                proration_behavior: "always_invoice",
                                billing_cycle_anchor: "now",
                                "items[0][plan]": props.priceId,
                                "items[0][id]": trialCheck1.id,
                              };
                              UserService.updateSubsciptionStripe(
                                subsciptionData,
                                trialCheck.id
                              ).then((response) => {
                                if (
                                  response.status !== 400 &&
                                  response.status !== 404
                                ) {
                                  history.push({
                                    pathname: `/family_dashboard/${splitUrl[4]}`,
                                  });
                                }

                                // let data = {
                                //   price: props.priceDetails,
                                // };
                                // UserService.updateSubItemStripe(
                                //   data,
                                //   respo.items.data[0].id
                                // ).then((resp) => {
                                //
                                //
                                //   UserService.updateInvoiceStripe(
                                //     respo.latest_invoice
                                //   ).then((resp) => {
                                //
                                //
                                //     if (
                                //       resp.status !== 400 &&
                                //       resp.status !== 404
                                //     ) {
                                //       history.push({
                                //         pathname: `/family_dashboard/${splitUrl[4]}`,
                                //       });
                                //     }
                                //   });
                                // });
                              });
                            });
                          }
                        } else {
                          UserService.upgradeSubscription(
                            splitUrl[4],
                            props.planId,
                            props.SubscriptionId
                          ).then((response) => {
                            let subsciptionData = {
                              trial_end: "now",
                              proration_behavior: "always_invoice",
                              billing_cycle_anchor: "now",
                              "items[0][plan]": props.priceId,
                              "items[0][id]": trialCheck1.id,
                            };
                            UserService.updateSubsciptionStripe(
                              subsciptionData,
                              trialCheck.id
                            ).then((response) => {
                              if (
                                response.status !== 400 &&
                                response.status !== 404
                              ) {
                                history.push({
                                  pathname: `/family_dashboard/${splitUrl[4]}`,
                                });
                              }

                              // let data = {
                              //   price: props.priceDetails,
                              // };
                              // UserService.updateSubItemStripe(
                              //   data,
                              //   respo.items.data[0].id
                              // ).then((resp) => {
                              //
                              //
                              //   UserService.updateInvoiceStripe(
                              //     respo.latest_invoice
                              //   ).then((resp) => {
                              //
                              //
                              //     if (
                              //       resp.status !== 400 &&
                              //       resp.status !== 404
                              //     ) {
                              //       history.push({
                              //         pathname: `/family_dashboard/${splitUrl[4]}`,
                              //       });
                              //     }
                              //   });
                              // });
                            });
                          });
                        }
                      } else {
                        // create subscription
                        let subsciptionData = {
                          customer: props.customerId,
                          "items[0][price]": props.priceId,
                        };
                        if (planDet.length === 0) {
                          subsciptionData.trial_end = unixTimestamp;
                        }
                        UserService.createSubsciptionStripe(
                          subsciptionData
                        ).then(
                          (response) => {
                            localStorage.setItem(
                              "cardDetails",
                              result.paymentMethod.card.last4
                            );
                            if (response.status == "active") {
                              //history.push('/confirmation');
                              // call family creation api
                              let createFamilyData = {
                                stripePaymentId: response.latest_invoice,
                                stripeSubscriptionId: response.id,
                                stripeCustomerId: response.customer,
                                familyName: props.familyName, //UserDetails.lastname,
                                planId: props.planId, // response.plan.product,
                                aoUser: UserDetails.uuid,
                              };

                              localStorage.setItem(
                                "UserA",
                                JSON.stringify(createFamilyData.planId)
                              );
                              UserService.createFamily(createFamilyData).then(
                                (res) => {
                                  setDetailsSave(res);
                                  // history.push({
                                  //   pathname: "/confirmation",
                                  //   familyUUID: res.FamilyData.uuid,
                                  //   fromPaymentSuccsess: true,
                                  // });

                                  window.history.pushState(
                                    null,
                                    "",
                                    history.push({
                                      pathname: "/confirmation",
                                      familyUUID: res.FamilyData.uuid,
                                      fromPaymentSuccsess: true,
                                    })
                                  );
                                  window.onpopstate = function () {
                                    window.history.pushState(
                                      null,
                                      "",
                                      history.push({
                                        pathname: "/confirmation",
                                        familyUUID: res.FamilyData.uuid,
                                        fromPaymentSuccsess: true,
                                      })
                                    );
                                  };

                                  localStorage.setItem(
                                    "UserB",
                                    JSON.stringify(res.FamilyData.uuid)
                                  );
                                }
                              );
                            } else if (response.status == "trialing") {
                              //history.push('/confirmation');
                              // call family creation api
                              let createFamilyData = {
                                stripePaymentId: response.latest_invoice,
                                stripeSubscriptionId: response.id,
                                stripeCustomerId: response.customer,
                                familyName: props.familyName, //UserDetails.lastname,
                                planId: props.planId, // response.plan.product,
                                aoUser: UserDetails.uuid,
                              };

                              localStorage.setItem(
                                "UserA",
                                JSON.stringify(createFamilyData.planId)
                              );
                              UserService.createFamily(createFamilyData).then(
                                (res) => {
                                  setDetailsSave(res);
                                  // history.push({
                                  //   pathname: "/confirmation",
                                  //   familyUUID: res.FamilyData.uuid,
                                  //   fromPaymentSuccsess: true,
                                  // });

                                  window.history.pushState(
                                    null,
                                    "",
                                    history.push({
                                      pathname: "/confirmation",
                                      familyUUID: res.FamilyData.uuid,
                                      fromPaymentSuccsess: true,
                                    })
                                  );
                                  window.onpopstate = function () {
                                    window.history.pushState(
                                      null,
                                      "",
                                      history.push({
                                        pathname: "/confirmation",
                                        familyUUID: res.FamilyData.uuid,
                                        fromPaymentSuccsess: true,
                                      })
                                    );
                                  };

                                  localStorage.setItem(
                                    "UserB",
                                    JSON.stringify(res.FamilyData.uuid)
                                  );
                                }
                              );
                            }
                            //setLoading(false);
                          },
                          (error) => {
                            setLoading(false);
                          }
                        );
                      }
                    },
                    (error) => {
                      const errors = {};

                      setLoading(false);
                    }
                  );
                },
                (error) => {
                  const errors = {};

                  setLoading(false);
                }
              );
            }
          }
        });
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [formErrors]);

  localStorage.setItem("UserDA", JSON.stringify(DetailsSave));

  return (
    <>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <form className="payment-form" onSubmit={handleSubmit}>
        <label
          className={
            formErrors.name ? "invalid-text form-label mb-0" : "form-label mb-0"
          }
        >
          Card Holder Name
        </label>
        {/* {props.customerId} */}
        <Small
          type="test"
          errorClass={formErrors.name ? "invalid-input" : ""}
          placeholder="John Mike"
          size="small"
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />
        <label
          className={
            err ? "invalid-text form-label mt-3 mb-0" : "form-label mt-3 mb-0"
          }
        >
          Card Details
        </label>
        <CardElement onChange={handleCardChange} />
        {exist && (
          <p className="card-wrong">
            <Row style={{ display: "flex", margin: "1rem auto" }}>
              <Col md="2">
                <img src={ErrorCheck} className="rounded me-2" alt="" />
              </Col>
              <Col md="10" className="" style={{ "padding-left": "1.5rem" }}>
                Card number already exist. Please use different card
              </Col>
            </Row>
          </p>
        )}
        <label
          className={
            formErrors.addressLine1 &&
            formErrors.addressLine2 &&
            formErrors.city &&
            formErrors.state
              ? "invalid-text form-label mb-0"
              : "form-label mb-0"
          }
        >
          Billing Address
        </label>
        {/* {props.customerId} */}
        <Small
          type="test"
          errorClass={formErrors.addressLine1 ? "invalid-input" : ""}
          placeholder="Line 1"
          size="small"
          name="addressLine1"
          value={formValues.addressLine1}
          onChange={handleChange}
        />
        <div className="my-3">
          <Small
            type="test"
            errorClass={formErrors.addressLine2 ? "invalid-input" : ""}
            placeholder="Line 2"
            size="small"
            name="addressLine2"
            value={formValues.addressLine2}
            onChange={handleChange}
          />
        </div>
        <Row>
          <Col>
            <Small
              type="test"
              errorClass={formErrors.city ? "invalid-input" : ""}
              placeholder="City"
              size="small"
              name="city"
              value={formValues.city}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Small
              type="test"
              errorClass={formErrors.state ? "invalid-input" : ""}
              placeholder="State"
              size="small"
              name="state"
              value={formValues.state}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <div className="mt-3 mb-5">
          <Small
            type="test"
            //errorClass={formErrors.state ? "invalid-input" : ""}
            placeholder="United Kingdom of Great Britain (GB)"
            size="small"
            name="country"
            value="GB"
            disabled
          />
          {/* <select
          value={formValues.country}
          name="country"
          onChange={handleChange}
        >
          <option value="" disabled>
            Select country
          </option>
          <option value="GB">United Kingdom of Great Britain (GB)</option>
        </select> */}
        </div>
        <div className="text-center">
          <Primary
            children="Proceed to Payment"
            variant="primary"
            size="mediumbtn"
            type="submit"
            buttonWidth="55%"
            // disabled={isDisabled ? "disabled" : ""}
            isLoading={loading ? "true" : "false"}
            // isDisabled={loading ? "true" : "false"}
          />
          {/* {loading && <span className="spinner-border spinner-border-sm"></span>} */}
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </div>
      </form>
    </>
  );
};

export default CheckoutForm;
