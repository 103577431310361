import React from "react"
import Input from '../components/Input/Input'

export default {
    title: 'UI/Input',
    component: Input,
    // args: {
    //     children: 'Button',
       
    // },
}


const Template = args => <Input {...args} />

export const Small = Template.bind({})
Small.args = {
    placeholder: 'Small size',
    size: 'small'
}

export const Medium = Template.bind({})
Medium.args = {
    placeholder: 'Medium size',
    size: 'medium'
}

export const Large = Template.bind({})
Large.args = {
    placeholder: 'Large size',
    size: 'large'
}