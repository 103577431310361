import React from 'react'
import './ProfileNav.scss'
import Button from '../Button/Button'

function ProfileNav({profLocation,setProfLocation}) {

    
    return (
        <div className='mt-4 mb-4 profilenav'>
            <button onClick={()=>{setProfLocation('basic')}} className={`profilenav-btn ${profLocation==='basic'&&'btn-active'}`}>
                Basic Profile
            </button>
            {/* <button onClick={()=>{setProfLocation('paymentMtd')}} className={`profilenav-btn ${profLocation==='paymentMtd'&&'btn-active'}`}>
                Payment Methods
            </button> */}
            <button onClick={()=>{setProfLocation('resetPass')}} className={`profilenav-btn ${profLocation==='resetPass'&&'btn-active'}`}>
                Reset Password
            </button>
        </div>
    )
}

export default ProfileNav
