import React from "react";
import { Row, Col } from "react-bootstrap";

const Loading = () => {
  return (
    <Row>
      <Col md="12" lg="12" className="mt-4 pt-4 text-center">
        <button className="btn loading-btn" type="button">
          <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
          Loading . . .
        </button>
      </Col>
    </Row>
  )
};

export default Loading;
