import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import HelloText from "../../assets/images/hello-text.svg";
import WarrantiesEmpty from "../../assets/images/warranties-empty.svg";
import Modal from "react-bootstrap/Modal";
import layer from "../../assets/images/layer1.svg";
import { Primary } from "../../stories/Button.stories";
import { Info } from "../../stories/Button.stories";
import Toast from "react-bootstrap/Toast";
import leftArrow from "../../assets/images/left.svg";
import { NumberCard } from "../../stories/CountCard.stories";
import DocIcon from "../../assets/images/doc_icon.png";
import VerticalDots from "../../assets/images/three-dots-vertical.svg";
import editIcon from "../../assets/images/Edit.svg";
import MagnifierIcon from "../../assets/images/magnifier.png";
import removeSearch from "../../assets/images/search_remove.png";
import Dropdown from "react-bootstrap/Dropdown";
import Select, { components } from "react-select";
import moment from "moment";
import Delete from "../../assets/images/Delete.svg";
import SuccessCheck from "../../assets/images/successCheck.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../common/Loading";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import FileViewer from "react-file-viewer";
import PDFViewer from "pdf-viewer-reactjs";
import { action } from "@storybook/addon-actions";

const Warranties = (props) => {
  const myRef = useRef(null);
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  var userType = localStorage.getItem("userType");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  const [numPages, setNumPages] = useState(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [isPdf, setIsPdf] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [filtervalue, setfiltervalue] = useState("all");
  const [filtervalue1, setfiltervalue1] = useState();
  const [filtervalueMonths, setfiltervalueMonths] = useState();
  const [filtervalueWarr, setfiltervalueWarr] = useState();
  const [isDocument, setIsDocument] = useState(false);
  const [subCategoryOption, setSubCategoryOption] = useState([]);

  // const [defVal, setDefVal] = useState([]);

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // const valDefault = [{ value: "", label: "" }];
  const filterOption = [
    // { value: "all", label: "All" },
    // { value: "6M", label: "6 months" },
    // { value: "12M", label: "12 months" },
    // { value: "24M", label: "24 months" },
    // { value: "36M", label: "36 months" },
    // { value: "48M", label: "48 months" },

    { value: "all", label: "All" },
    { value: "warrantyTenure", label: "Expiration time" },
    { value: "status", label: "Warranty status" },
    // { value: "48M", label: "48 months" },
  ];
  //const [filterOption, setFilterOption] = useState(initialFilterOption);
  const sortOption = [
    // { value: "createdAt", label: "Created" },
    // { value: "updatedAt", label: "Updated" },
    // { value: "expiryDate", label: "Expiry Date" },
    // { value: "price", label: "Price" },
    { value: "In Warranty", label: "In Warranty" },
    { value: "Out Warranty", label: "Out of Warranty" },
  ];
  const sortOption1 = [
    { value: "6M", label: "6 months" },
    { value: "12M", label: "12 months" },
    { value: "24M", label: "24 months" },
    { value: "36M", label: "36 months" },
    { value: "48M", label: "48 months" },
  ];
  const sortOption2 = [];

  //const [sortOption, setSortOption] = useState(initialSortOption);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
      height: "3.75rem",
      "padding-left": "0.5rem",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };

  const [familyDetails, setFamilyDetails] = useState([]);
  const [successToast, setSuccessToast] = useState(false);
  const [showCancelReminder, setShowCancelReminder] = useState(false);
  const [showDeleteWar, setShowDeleteWar] = useState(false);
  const [warId, setWarId] = useState(false);
  const [GStats, setGStats] = useState([]);
  const [userWarrantyList, setUserWarrantyList] = useState([]);
  const [totalWarrantyCount, setTotalWarrantyCount] = useState("0");
  const [totalWarrantyCost, setTotalWarrantyCost] = useState("£ 0");
  const [InWarrantyCount, setInWarrantyCount] = useState("0");
  const [InWarrantyTotalPrice, setInWarrantyTotalPrice] = useState("£ 0");
  const [OutWarrantyCount, setOutWarrantyCount] = useState("0");
  const [OutWarrantyTotalPrice, setOutWarrantyTotalPrice] = useState("£ 0");
  const [searchStr, setSearchStr] = useState("");
  const [showSearchText, setShowSearchText] = useState(false);
  const [addWarrantyUrl, setAddWarrantyUrl] = useState("");
  const [title, setTitle] = useState("My Warranties");
  const [backText, setBackText] = useState("Back to Group Dashboard");
  const [backUrl, setBackUrl] = useState("");
  const [parentUser, setParentUser] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showDocSub, setShowDocSub] = useState(false);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("createdAt");
  const [orderBy, setOrderBy] = useState("DESC");
  const [dataLength, setDataLength] = useState(0);
  const [familyUserId, setFamilyUserId] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [iframeLink, setIframeLink] = useState("");
  const [docLink, setDocLink] = useState("");

  const [selectedFilter, setselectedFilter] = useState({
    value: "all",
    label: "All",
  });
  const [selectedFilterVal, setselectedFilterVal] = useState({
    value: "",
    label: "Select a filter value ",
  });

  const viewDetailsRoute = (warId) => {
    if (userUUID) {
      history.push(`/view_user_warranty_details/${uuid}/${warId}/${userUUID}`);
    } else {
      history.push(`/view_warranty_details/${uuid}/${warId}`);
    }
  };
  const editWarrantyRoute = (warId) => {
    if (userUUID) {
      history.push(`/edit_user_warranty/${uuid}/${warId}/${userUUID}`);
    } else {
      history.push(`/edit_warranty/${uuid}/${warId}`);
    }
  };
  // Cancel reminder confirmation
  const handleShowCancelReminderShow = (id) => {
    setShowCancelReminder(true);
    //setSubProductName(name);
    setWarId(id);
  };
  // Close cancel reminder
  const handleShowCancelReminderClose = () => {
    setShowCancelReminder(false);
    setLoadingspinner(true);
    setTimeout(() => {
      setLoadingspinner(false);
    }, 500);
  };

  // Cancel reminder subscription
  const handleCancelReminder = (warId) => {
    setShowCancelReminder(false);
    UserService.cancelReminderWarranty(warId, false).then(
      (response) => {
        let warList = userWarrantyList;
        const selectedWarIndex = warList.findIndex(
          (x) => Number(x.id) === Number(warId)
        );
        warList[selectedWarIndex].reminder = false;
        setUserWarrantyList(warList);
        setSuccessToast(true);
        setShowToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setToastMessage("Warranty reminder turned off");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  // true reminder subscription
  const handleCancelReminder1 = (warId) => {
    UserService.cancelReminderWarranty(warId, true).then(
      (response) => {
        let warList = userWarrantyList;
        const selectedWarIndex = warList.findIndex(
          (x) => Number(x.id) === Number(warId)
        );
        warList[selectedWarIndex].reminder = true;
        setUserWarrantyList(warList);
        setSuccessToast(true);
        setShowToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setToastMessage("Warranty reminder turned on");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };
  // Delete confirmation
  const handleShowDeleteWarShow = (id) => {
    setShowDeleteWar(true);
    //setSubProductName(name);
    setWarId(id);
  };

  // Close delete confirmation
  const handleShowDeleteWarClose = () => {
    setShowDeleteWar(false);
  };

  // Delete subscription
  const handleDeleteWarranty = (warId) => {
    setShowDeleteWar(false);
    UserService.deleteWarranty(warId).then(
      (response) => {
        let warList = userWarrantyList;
        const selectedSubIndex = warList.findIndex(
          (x) => Number(x.id) === Number(warId)
        );
        warList.splice(selectedSubIndex, 1);
        setUserWarrantyList(warList);
        if (warList.length === 0) {
          setIsEmpty(true);
          setLoadingspinner(false);
        } else {
          setIsEmpty(false);
          setLoadingspinner(false);
          getStats(familyDetails.FamilyData.Family.id, familyUserId);
          getStatsInOut(familyDetails.FamilyData.Family.id, familyUserId);
        }
        setShowToast(true);
        setSuccessToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setToastMessage("Warranty deleted successfully");
      },
      (error) => {}
    );
  };
  // show doc model
  const handleShowDocSubShow = (docUrl) => {
    setShowDocSub(true);
    const file_ext = get_url_extension(docUrl);
    setDocLink(docUrl);

    if (file_ext === "pdf") {
      setIsPdf(true);
      setIsImage(false);
      setIsDocument(false);
    } else if (
      file_ext === "png" ||
      file_ext === "jpg" ||
      file_ext === "jpeg"
    ) {
      setIsPdf(false);
      setIsImage(true);
      setIsDocument(false);
    } else if (file_ext === "doc" || file_ext === "docx") {
      setIsPdf(false);
      setIsImage(false);
      setIsDocument(true);
      const docs = [{ uri: docUrl }];
      setIframeLink(docs);
    }
  };
  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };
  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };

  const [filtValue, setfiltValue] = useState("all"); // filter by
  const [expValue, setexpValue] = useState(""); // status by
  const [searchValue, setsearchValue] = useState(""); // search by
  const [sortValue, setsortValue] = useState("createdAt"); //sort by
  const [orderValue, setorderValue] = useState("DESC"); //order by

  const [subTitle, setSubTitle] = useState(
    "You can track all your Warranties on Elderly Life platform here."
  );

  useEffect(() => {
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        setParentUser(response.userData);
        setAddWarrantyUrl("/add_user_warranty/" + uuid + "/" + userUUID);
        setBackUrl("/supporting_user_dashboard/" + uuid + "/" + userUUID);
        const abc =
          pUser.firstName === "null"
            ? pUser.email
            : pUser.firstName + " " + pUser.lastName === "null"
            ? ""
            : pUser.lastName;
        setTitle(`${abc}'s Warranties`);
        setSubTitle(`You can track all Warranties of ${abc} here`);
      });
    } else {
      setAddWarrantyUrl("/add_warranty/" + uuid);
      getFamilyDetails(UserDetails.id);
      setBackText("Back to Group Dashboard");
      setBackUrl("/family_dashboard/" + uuid);
    }
  }, []);
  const validateDate = (date) => {
    //get start of day using moment.js
    const now = new Date(); //moment().startOf('day').toDate();
    var pickDate = new Date(date);
    if (pickDate < now) {
      return true; //date is before today's date
    } else {
      return false; //date is today or some day forward
    }
  };

  const getFamilyDetails = (userId) => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        let famUserId = response.FamilyData.id;

        let params = {
          userId: famUserId,
          familyId: response.FamilyData.Family.id,
          searchStr: searchValue,
          sort: sortValue,
          order: orderValue,
          limit: 10,
          offset: 0,
          filtBy: filtValue,
          status: expValue,
        };

        // get warranty list
        UserService.getUserWarrantyListNew(params).then(
          (inner_response) => {
            setUserWarrantyList(inner_response.data.rows);
            setDataLength(inner_response.data.count);
            let nextOffset = 10;
            if (inner_response.data.count === 0) {
              setIsEmpty(true);
              setLoadingspinner(false);
              setOffset(0);
              setHasMore(false);
            } else {
              setIsEmpty(false);
              setLoadingspinner(false);
              if (inner_response.data.count > nextOffset) {
                setOffset(nextOffset);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            }
            setLoading(false);
          },
          (error) => {}
        );

        getStats(response.FamilyData.Family.id, famUserId);
        setFamilyUserId(famUserId);
        getStatsInOut(response.FamilyData.Family.id, famUserId);
        setFamilyUserId(famUserId);
      },
      (error) => {}
    );
  };

  // Get warranty List
  const getWarrantyList = (params) => {
    setLoading(true);
    UserService.getUserWarrantyListNew(params).then(
      (response) => {
        setUserWarrantyList(response.data.rows);
        setLoading(false);

        let nextOffset = Number(Number(params.offset) + Number(params.limit));

        if (Number(response.data.count) > nextOffset) {
          setOffset(nextOffset);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      },
      (error) => {}
    );
  };

  // Get more subscription List
  const moreWarranties = () => {
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limit,
      offset: offset,
      filtBy: filtValue,
      status: expValue,
    };

    UserService.getUserWarrantyListNew(params).then(
      (response) => {
        setUserWarrantyList([...userWarrantyList, ...response.data.rows]);
        setDataLength(userWarrantyList.length + response.data.rows.length);
        let nextOffset = Number(offset) + Number(limit);
        if (response.data.count > nextOffset) {
          setOffset(nextOffset);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      },
      (error) => {}
    );
  };

  // Get Stats
  const getStats = (familyId, userId) => {
    UserService.getUserStats(familyId, userId).then(
      (response) => {
        setGStats(response);
        if (response.data.count > 0) {
          let res = response.data.rows[0];
          setTotalWarrantyCount(res.totalWarranties);
          setTotalWarrantyCost("£ " + res.totalWarrantyItems);
        }
      },
      (error) => {}
    );
  };

  const getStatsInOut = (familyId, userId) => {
    UserService.getUserStatsInOut(familyId, userId).then(
      (response) => {
        setInWarrantyCount(response.InWarrantyCount);
        // setInWarrantyTotalPrice("£ " + response.InWarrantyTotalPrice);
        setOutWarrantyCount(response.OutWarrantyCount);
        // setOutWarrantyTotalPrice("£ " + response.OutWarrantyTotalPrice);
        // setInOutWarrantyCost("£ " + res.totalWarrantyItems);

        var numWithZeroes = response.OutWarrantyTotalPrice.toLocaleString(
          "en",
          {
            useGrouping: false,
            minimumFractionDigits: 2,
          }
        );

        setOutWarrantyTotalPrice("£ " + numWithZeroes);

        var numWithZeroes1 = response.InWarrantyTotalPrice.toLocaleString(
          "en",
          {
            useGrouping: false,
            minimumFractionDigits: 2,
          }
        );

        setInWarrantyTotalPrice("£ " + numWithZeroes1);
      },
      (error) => {}
    );
  };

  // var OutWarrantyTotalPrice = 5.1;
  // var numWithZeroes = OutWarrantyTotalPrice.toLocaleString("en", {
  //   useGrouping: false,
  //   minimumFractionDigits: 2,
  // });
  //

  const handleFrequency = (freq) => {
    switch (freq) {
      case "6M":
        return "6 Months";
      case "12M":
        return "12 Months";
      case "24M":
        return "24 Months";
      case "36M":
        return "36 Months";
      case "48M":
        return "48 Months";
      default:
        return "";
    }
  };

  // Filter warranties

  // Sort warranty

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    setShowSearchText(false);
    setLoading(true);
    setSearchStr("");
    setsearchValue("");
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: "",
      sort: sortValue,
      order: orderValue,
      limit: 10,
      offset: 0,
      filtBy: filtValue,
      status: expValue,
    };
    getWarrantyList(params);
  };

  //search box
  useEffect(() => {
    if (familyDetails.FamilyData != undefined) {
      if (searchStr.length === 0) {
        // clearSearch();
        setShowSearchText(false);
        setLoading(true);
        setSearchStr("");
        setsearchValue("");
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: "",
          sort: sortValue,
          order: orderValue,
          limit: 10,
          offset: 0,
          filtBy: filtValue,
          status: expValue,
        };
        getWarrantyList(params);
      }
    }
  }, [searchStr]);

  // Search warranties
  const warrantySearch = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setShowSearchText(true);
      setLoading(true);
      let srchStr = e.target.value;
      setSearchStr(e.target.value);
      setsearchValue(srchStr);
      if (srchStr === "") {
        clearSearch(e);
      } else {
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: srchStr,
          sort: sortValue,
          order: orderValue,
          limit: 10,
          offset: 0,
          filtBy: filtValue,
          status: expValue,
        };
        getWarrantyList(params);
      }
    }
  };

  //onchange in filter by & filter by value--
  const handleChange = (e) => {
    setfiltervalue(e.value);
    setfiltervalue1(e.value);
    setselectedFilter(e);

    if (e.value === "warrantyTenure") {
      setselectedFilterVal({
        value: "",
        label: "Select a filter value ",
      });
      setSubCategoryOption(sortOption1);
    } else if (e.value === "status") {
      setselectedFilterVal({
        value: "",
        label: "Select a filter value ",
      });
      setSubCategoryOption(sortOption);
    } else if (e.value === "all") {
      setselectedFilterVal({
        value: "",
        label: "Select a filter value ",
      });
      setfiltValue(e.value);
      setexpValue("");
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchValue,
        sort: sortValue,
        order: orderValue,
        limit: 10,
        offset: 0,
        filtBy: e.value,
        status: "",
      };
      getWarrantyList(params);
    }
  };

  //onchange values
  const handleChangeValues = (e) => {
    setselectedFilterVal(e);
    setLoading(true);
    if (
      e.value === "6M" ||
      e.value === "12M" ||
      e.value === "24M" ||
      e.value === "36M" ||
      e.value === "48M" ||
      e.value === "all"
    ) {
      setfiltValue(e.value);
      setexpValue("");
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchValue,
        sort: sortValue,
        order: orderValue,
        limit: 10,
        offset: 0,
        filtBy: e.value,
        status: "",
      };
      UserService.getUserWarrantyListNew(params).then(
        (inner_response) => {
          setUserWarrantyList(inner_response.data.rows);
          setDataLength(inner_response.data.count);
          let nextOffset = 10;
          setLoading(false);
          if (inner_response.data.count === 0) {
            setLoadingspinner(false);
            setOffset(0);
            setHasMore(false);
          } else {
            setLoadingspinner(false);
            if (inner_response.data.count > nextOffset) {
              setOffset(nextOffset);
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          }
        },
        (error) => {}
      );
    } else if (e.value === "In Warranty" || e.value === "Out Warranty") {
      setexpValue(e.value);
      setfiltValue("all");
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchValue,
        sort: sortValue,
        order: orderValue,
        limit: 10,
        offset: 0,
        filtBy: "all",
        status: e.value,
      };
      UserService.getUserWarrantyListNew(params).then(
        (inner_response) => {
          setUserWarrantyList(inner_response.data.rows);
          setDataLength(inner_response.data.count);
          let nextOffset = 10;
          setLoading(false);
          if (inner_response.data.count === 0) {
            setLoadingspinner(false);
            setOffset(0);
            setHasMore(false);
          } else {
            setLoadingspinner(false);
            if (inner_response.data.count > nextOffset) {
              setOffset(nextOffset);
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          }
        },
        () => {}
      );
    }
  };

  var uuidDetails = localStorage.getItem("uuid");
  var userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));

  const dashboardRed = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/family_dashboard/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  return (
    <div className="page-content warranty-pg pb-5">
      <Container fluid ref={myRef}>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {loadingspinner ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            <div className="Reminder-backBtn" onClick={() => dashboardRed()}>
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              {parentUserUUId === null ? (
                <span>Back to Group Dashboard</span>
              ) : (
                <span>Back to Main User Dashboard</span>
              )}
            </div>
            <Row>
              <Col md="8" lg="8">
                <h3>{title}</h3>
                <p className="sub-title">{subTitle}</p>
              </Col>
              <Col md="4" lg="4">
                {isEmpty ? (
                  ""
                ) : (
                  <span className="align-right">
                    <Link to={addWarrantyUrl} className="">
                      <Primary
                        children="+ Add New Warranty"
                        size="mediumbtn"
                        variant="primary"
                        type=""
                        buttonWidth="220px"
                        font_Weight="500"
                      />
                    </Link>
                  </span>
                )}
              </Col>
            </Row>
            {isEmpty ? (
              <>
                <Row>
                  <Col md="6" lg="6" className="text-left my-4">
                    <p className="sub-text mb-4">
                      You currently have no Warranties set up!
                    </p>
                    <Link to={addWarrantyUrl} className="">
                      <Large
                        children="+ Add New Warranty"
                        size="largebtn"
                        variant="primary"
                        type=""
                        buttonWidth="220px"
                        font_Weight="500"
                      />
                    </Link>
                  </Col>
                  <Col md="6" lg="6">
                    <Image
                      className="align-right empty-src-img"
                      src={WarrantiesEmpty}
                      alt="warranty empty icon"
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row
                  className="subStats-card"
                  style={{ "margin-bottom": "25px" }}
                >
                  <Col md="4" lg="2" xl="4" xxl="2">
                    <div className="numbercard-div-style">
                      <div
                        style={{
                          "font-size": "0.7rem",
                          // "margin-bottom": "10px",
                          "margin-top": "5px",
                          "font-weight": "900",
                          textAlign: "center",
                        }}
                      >
                        In Warranty
                      </div>
                      <div className="numbercard-div-style-1">
                        <div>
                          <div style={{ "font-size": "12px" }}>Total Items</div>
                          <div
                            style={{
                              "font-weight": "900",
                              color: "#040008",
                              margin: "0.25rem 0",
                              "font-size": "1.1rem",
                              "margin-left": "40%",
                            }}
                          >
                            {InWarrantyCount}
                          </div>
                        </div>
                        <div>
                          <div>
                            <div style={{ "font-size": "12px" }}>
                              Items value
                            </div>
                            <div
                              style={{
                                "margin-left": "30%",
                                "font-weight": "900",
                                color: "#040008",
                                margin: "0.25rem 0",
                                "font-size": "1.1rem",
                              }}
                            >
                              {InWarrantyTotalPrice}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md="4" lg="2" xl="4" xxl="2">
                    <div className="numbercard-div-style">
                      <div
                        style={{
                          "font-size": "0.7rem",
                          // "margin-bottom": "10px",
                          "margin-top": "5px",
                          "font-weight": "900",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Out of Warranty
                      </div>
                      <div className="numbercard-div-style-1">
                        <div>
                          <div style={{ "font-size": "12px" }}>Total Items</div>
                          <div
                            style={{
                              "font-weight": "900",
                              color: "#040008",
                              margin: "0.25rem 0",
                              "font-size": "1.1rem",
                              "margin-left": "40%",
                            }}
                          >
                            {OutWarrantyCount}
                          </div>
                        </div>
                        <div>
                          <div>
                            <div style={{ "font-size": "12px" }}>
                              Items value
                            </div>
                            <div
                              style={{
                                "margin-left": "30%",
                                "font-weight": "900",
                                color: "#040008",
                                margin: "0.25rem 0",
                                "font-size": "1.1rem",
                              }}
                            >
                              {OutWarrantyTotalPrice}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="4" lg="2" xl="4" xxl="2">
                    <div className="numbercard-div-style">
                      <div
                        style={{
                          "font-size": "0.7rem",
                          // "margin-bottom": "10px",
                          "margin-top": "5px",
                          "font-weight": "900",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Total
                      </div>
                      <div className="numbercard-div-style-1">
                        <div>
                          <div style={{ "font-size": "12px" }}>Total Items</div>
                          <div
                            style={{
                              "font-weight": "900",
                              color: "#040008",
                              margin: "0.25rem 0",
                              "font-size": "1.1rem",
                              "margin-left": "40%",
                            }}
                          >
                            {totalWarrantyCount}
                          </div>
                        </div>
                        <div>
                          <div>
                            <div style={{ "font-size": "12px" }}>
                              Items value
                            </div>
                            <div
                              style={{
                                "font-weight": "900",
                                color: "#040008",
                                margin: "0.25rem 0",
                                "font-size": "1.1rem",
                              }}
                            >
                              {totalWarrantyCost}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-5 subSearch-row">
                  <Col md="6" lg="6">
                    <div className="input-group search-input-group">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search Warranties"
                        value={searchStr}
                        onChange={(e) => setSearchStr(e.target.value)}
                        onKeyDown={warrantySearch}
                      />
                      <div className="input-group-btn">
                        {!searchStr && (
                          <button
                            className="btn btn-default search-input-btn"
                            type="submit"
                          >
                            <Image src={MagnifierIcon} alt="magnifier icon" />
                          </button>
                        )}
                        {searchStr && (
                          <button
                            className="btn btn-default clear-search"
                            type="submit"
                            onClick={clearSearch}
                          >
                            <Image src={removeSearch} alt="Clear Search" />
                          </button>
                        )}
                      </div>
                    </div>
                    {showSearchText && searchStr && (
                      <div className="search-text mt-3">
                        Search Results for : <span>{searchStr}</span>
                      </div>
                    )}
                  </Col>
                  <Col md="3" lg="3">
                    <div className="d-flex justify-content-between filter-bg">
                      <div
                        className="filter-label pl-2 border-radius-rignt-none no-flex"
                        id="abc"
                      >
                        Filter by :
                      </div>
                      <div
                        className="p-0 filter-select flex-1"
                        style={{ minWidth: "150px" }}
                        id="abc"
                      >
                        <Select
                          styles={customStyles}
                          options={filterOption}
                          value={selectedFilter}
                          // onChange={warrantyFilter}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md="3" lg="3">
                    <div>
                      <div
                        className={`${
                          filtervalue !== "all" ? "showfilter" : "hidefilter"
                        } d-flex justify-content-between `}
                      >
                        <div
                          className="filter-label ml-2 pl-2 border-radius-rignt-none"
                          id="abc2"
                          style={{ width: "fit-content" }}
                        >
                          Filter by values :
                        </div>
                        <div
                          className="p-0 filter-select flex-1"
                          // style={{ minWidth: "150px" }}
                          id="abc2"
                        >
                          <Select
                            styles={customStyles}
                            options={subCategoryOption}
                            value={selectedFilterVal}
                            // onChange={warrantySort}
                            onChange={handleChangeValues}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {userWarrantyList.length > 0 && !loading && (
                  <div className="warranty-list">
                    <InfiniteScroll
                      dataLength={dataLength}
                      next={moreWarranties}
                      hasMore={hasMore}
                      loader={<Loading />}
                      style={{ overflow: "inherit" }}
                    >
                      {userWarrantyList.map((warData, index) => (
                        <Card
                          className="mb-2 mt-4 warrantList-cards"
                          key={index}
                        >
                          <Card.Body>
                            <span className="category-btn">
                              <p>{warData.Category.name}</p>
                            </span>

                            <div className="d-flex align-elements align-items-start test">
                              <div
                                onClick={() => viewDetailsRoute(warData.id)}
                                style={{ cursor: "pointer" }}
                                className="logo-box"
                              >
                                <img
                                  src={
                                    warData.Supplier.logo !== ""
                                      ? warData.Supplier.logo
                                      : "https://el-publicassets.s3.eu-west-2.amazonaws.com/images/Supplier+default+logo.png"
                                  }
                                  alt="Product logo"
                                  className="category-logo"
                                />
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(warData.id)}
                                style={{ cursor: "pointer" }}
                                className="Product-type"
                              >
                                <small
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 expired"
                                      : "mb-0"
                                  }
                                >
                                  Product type
                                </small>
                                <p
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 tab-txt expired"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  {warData.SubCategory.name}
                                </p>
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(warData.id)}
                                style={{ cursor: "pointer" }}
                                className="supplier-box"
                              >
                                <small
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 expired"
                                      : "mb-0"
                                  }
                                >
                                  Supplier
                                </small>
                                <p
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 tab-txt expired"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  {warData.Supplier
                                    ? warData.Supplier.name
                                    : ""}
                                </p>
                              </div>
                              {/* <div
                                  onClick={() => viewDetailsRoute(warData.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <small
                                    className={
                                      validateDate(warData.expiryDate)
                                        ? "mb-0 expired"
                                        : "mb-0"
                                    }
                                    style={{
                                      "margin-left": "-1rem",
                                      "margin-right": "1rem",
                                    }}
                                  >
                                    Warrant term
                                  </small>
                                  <p
                                    className={
                                      validateDate(warData.expiryDate)
                                        ? "mb-0 tab-txt expired"
                                        : "mb-0 tab-txt"
                                    }
                                    style={{
                                      "margin-left": "-1rem",
                                      "margin-right": "1rem",
                                    }}
                                  >
                                    {handleFrequency(warData.warrantyTenure)}
                                  </p>
                                </div> */}
                              <div
                                onClick={() => viewDetailsRoute(warData.id)}
                                style={{ cursor: "pointer" }}
                                className="price-box"
                              >
                                <small
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 expired"
                                      : "mb-0"
                                  }
                                >
                                  Price
                                </small>
                                <p
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 tab-txt expired"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  £ {warData.price}
                                </p>
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(warData.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <small
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 expired-red"
                                      : "mb-0"
                                  }
                                >
                                  Expires on
                                </small>
                                <p
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 tab-txt expired-red"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  {moment(warData.expiryDate).format(
                                    "MMM Do, YYYY"
                                  )}
                                </p>
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(warData.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <small
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 expired-red"
                                      : "mb-0"
                                  }
                                >
                                  Warranty status
                                </small>
                                <p
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 tab-txt expired-red"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  {warData.status}
                                </p>
                              </div>
                              <div>
                                <small
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 expired-red"
                                      : "mb-0"
                                  }
                                >
                                  Reminder
                                </small>
                                <p
                                  className={
                                    validateDate(warData.expiryDate)
                                      ? "mb-0 tab-txt expired-red d-flex"
                                      : "mb-0 tab-txt d-flex"
                                  }
                                >
                                  <label class="switch m-0">
                                    <input
                                      type="checkbox"
                                      id="togBtn"
                                      defaultChecked={
                                        warData.reminder === true ? true : false
                                      }
                                      onClick={(e) =>
                                        e.target.checked === false
                                          ? handleShowCancelReminderShow(
                                              warData.id,
                                              warData.productName
                                            )
                                          : handleCancelReminder1(
                                              warData.id,
                                              warData.productName
                                            )
                                      }
                                    />
                                    <div class="slider round"></div>
                                  </label>
                                </p>
                              </div>

                              <div className="call2actionbtns">
                                <span className="list-btn my-1 d-flex justify-content-between">
                                  <div className="doc-box">
                                    {warData.invoiceDocument ? (
                                      <svg
                                        id="surface1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() =>
                                          handleShowDocSubShow(
                                            warData.invoiceDocument
                                          )
                                        }
                                        viewBox="0 0 20.383 22.405"
                                        style={{
                                          cursor: "pointer",
                                          width: "1.5rem",
                                        }}
                                      >
                                        <path
                                          id="Path_16605"
                                          data-name="Path 16605"
                                          d="M15.27,9.7V5.459a.491.491,0,0,0-.135-.322L10.381.145A.474.474,0,0,0,10.04,0H2.5A2.511,2.511,0,0,0,0,2.526V16.778a2.5,2.5,0,0,0,2.5,2.508H8.452A6.422,6.422,0,1,0,15.27,9.7Zm-4.763-8.06L13.7,5H11.627a1.128,1.128,0,0,1-1.121-1.125Zm-8,16.712A1.564,1.564,0,0,1,.934,16.778V2.526A1.577,1.577,0,0,1,2.5.934h7.07V3.871A2.059,2.059,0,0,0,11.627,5.93h2.708V9.577c-.14,0-.252-.019-.374-.019A6.516,6.516,0,0,0,9.7,11.165H3.773a.467.467,0,1,0,0,.934H8.835a7.43,7.43,0,0,0-.822,1.448H3.773a.467.467,0,0,0,0,.934H7.71a6.409,6.409,0,0,0,.28,3.876H2.5Zm11.455,3.124a5.487,5.487,0,1,1,5.487-5.487A5.493,5.493,0,0,1,13.958,21.476Zm0,0"
                                          fill="#003a6f"
                                        />
                                        <path
                                          id="Path_16606"
                                          data-name="Path 16606"
                                          d="M76.018,202.618h4.73a.467.467,0,1,0,0-.934h-4.73a.467.467,0,1,0,0,.934Zm0,0"
                                          transform="translate(-72.245 -192.858)"
                                          fill="#003a6f"
                                        />
                                        <path
                                          id="Path_16607"
                                          data-name="Path 16607"
                                          d="M251.264,286.709l-1.919,2.069v-5.1a.467.467,0,1,0-.934,0v5.1l-1.933-2.069a.471.471,0,0,0-.663-.023.465.465,0,0,0-.023.658l2.732,2.937a.463.463,0,0,0,.682,0l2.736-2.937a.464.464,0,1,0-.677-.635Zm0,0"
                                          transform="translate(-234.916 -270.818)"
                                          fill="#003a6f"
                                        />
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <Image
                                    className="m-1 edit-icon mx-5"
                                    src={editIcon}
                                    alt="Vertical Dots"
                                    onClick={() =>
                                      editWarrantyRoute(warData.id)
                                    }
                                    // width="8%"
                                    style={{
                                      cursor: "pointer",
                                      // left: "5.5rem",
                                      top: " 0.8rem",
                                      width: "1.5rem",
                                    }}
                                  />

                                  <Image
                                    className="m-1 edit-icon"
                                    src={Delete}
                                    alt="Delete"
                                    onClick={() =>
                                      handleShowDeleteWarShow(warData.id)
                                    }
                                    // width="8%"
                                    style={{
                                      cursor: "pointer",
                                      left: "4rem",
                                      top: " 0.8rem",
                                      width: "1.5rem",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>

                            {/* <Row>
                            <Col
                              md="1"
                              lg="1"
                              onClick={() => viewDetailsRoute(warData.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={warData.Supplier.logo}
                                alt="Product logo"
                                className="category-logo"
                              />
                            </Col>
                            <Col
                              md="2"
                              lg="2"
                              onClick={() => viewDetailsRoute(warData.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <small
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 expired"
                                    : "mb-0"
                                }
                              >
                                Product type
                              </small>
                              <p
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 tab-txt expired"
                                    : "mb-0 tab-txt"
                                }
                              >
                                {warData.SubCategory.name}
                              </p>
                            </Col>
                            <Col
                              md="1"
                              lg="1"
                              onClick={() => viewDetailsRoute(warData.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <small
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 expired"
                                    : "mb-0"
                                }
                              >
                                Supplier 
                              </small>
                              <p
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 tab-txt expired"
                                    : "mb-0 tab-txt"
                                }
                              >
                                {warData.Supplier.name}
                              </p>
                            </Col>
                            <Col
                              md="1"
                              lg="1"
                              onClick={() => viewDetailsRoute(warData.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <small
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 expired"
                                    : "mb-0"
                                }
                                style={{
                                  "margin-left": "-1rem",
                                  "margin-right": "1rem",
                                }}
                              >
                                Warrant term
                              </small>
                              <p
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 tab-txt expired"
                                    : "mb-0 tab-txt"
                                }
                                style={{
                                  "margin-left": "-1rem",
                                  "margin-right": "1rem",
                                }}
                              >
                                {handleFrequency(warData.warrantyTenure)}
                              </p>
                            </Col>

                            <Col
                              md="1"
                              lg="1"
                              onClick={() => viewDetailsRoute(warData.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <small
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 expired"
                                    : "mb-0"
                                }
                              >
                                Price
                              </small>
                              <p
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 tab-txt expired"
                                    : "mb-0 tab-txt"
                                }
                              >
                                £ {warData.price}
                              </p>
                            </Col>
                            <Col
                              md="1"
                              lg="1"
                              onClick={() => viewDetailsRoute(warData.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <small
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 expired-red"
                                    : "mb-0"
                                }
                              >
                                Expires on
                              </small>
                              <p
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 tab-txt expired-red"
                                    : "mb-0 tab-txt"
                                }
                              >
                                {moment(warData.expiryDate).format(
                                  "MMM Do, YYYY"
                                )}
                              </p>
                            </Col>
                            <Col
                              md="2"
                              lg="2"
                              onClick={() => viewDetailsRoute(warData.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <small
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 expired-red"
                                    : "mb-0"
                                }
                              >
                                Warranty status
                              </small>
                              <p
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 tab-txt expired-red"
                                    : "mb-0 tab-txt"
                                }
                              >
                                {warData.status}
                              </p>
                            </Col>
                            <Col md="1" lg="1">
                              <small
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 expired-red"
                                    : "mb-0"
                                }
                              >
                                Reminder
                              </small>
                              <p
                                className={
                                  validateDate(warData.expiryDate)
                                    ? "mb-0 tab-txt expired-red"
                                    : "mb-0 tab-txt"
                                }
                              >
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="togBtn"
                                    defaultChecked={
                                      warData.reminder === true ? true : false
                                    }
                                    onClick={(e) =>
                                      e.target.checked === false
                                        ? handleShowCancelReminderShow(
                                          warData.id,
                                          warData.productName
                                        )
                                        : handleCancelReminder1(
                                          warData.id,
                                          warData.productName
                                        )
                                    }
                                  />
                                  <div class="slider round"></div>
                                </label>
                              </p>
                            </Col>
                            <Col md="1" lg="1">
                              {warData.invoiceDocument ? (
  

                                <svg
                                  id="surface1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() =>
                                    handleShowDocSubShow(
                                      warData.invoiceDocument
                                    )
                                  }
                                  viewBox="0 0 20.383 22.405"
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    left: "3.5rem",
                                    top: " 1.1rem",
                                    width: "1.5rem",
                                  }}
                                >
                                  <path
                                    id="Path_16605"
                                    data-name="Path 16605"
                                    d="M15.27,9.7V5.459a.491.491,0,0,0-.135-.322L10.381.145A.474.474,0,0,0,10.04,0H2.5A2.511,2.511,0,0,0,0,2.526V16.778a2.5,2.5,0,0,0,2.5,2.508H8.452A6.422,6.422,0,1,0,15.27,9.7Zm-4.763-8.06L13.7,5H11.627a1.128,1.128,0,0,1-1.121-1.125Zm-8,16.712A1.564,1.564,0,0,1,.934,16.778V2.526A1.577,1.577,0,0,1,2.5.934h7.07V3.871A2.059,2.059,0,0,0,11.627,5.93h2.708V9.577c-.14,0-.252-.019-.374-.019A6.516,6.516,0,0,0,9.7,11.165H3.773a.467.467,0,1,0,0,.934H8.835a7.43,7.43,0,0,0-.822,1.448H3.773a.467.467,0,0,0,0,.934H7.71a6.409,6.409,0,0,0,.28,3.876H2.5Zm11.455,3.124a5.487,5.487,0,1,1,5.487-5.487A5.493,5.493,0,0,1,13.958,21.476Zm0,0"
                                    fill="#003a6f"
                                  />
                                  <path
                                    id="Path_16606"
                                    data-name="Path 16606"
                                    d="M76.018,202.618h4.73a.467.467,0,1,0,0-.934h-4.73a.467.467,0,1,0,0,.934Zm0,0"
                                    transform="translate(-72.245 -192.858)"
                                    fill="#003a6f"
                                  />
                                  <path
                                    id="Path_16607"
                                    data-name="Path 16607"
                                    d="M251.264,286.709l-1.919,2.069v-5.1a.467.467,0,1,0-.934,0v5.1l-1.933-2.069a.471.471,0,0,0-.663-.023.465.465,0,0,0-.023.658l2.732,2.937a.463.463,0,0,0,.682,0l2.736-2.937a.464.464,0,1,0-.677-.635Zm0,0"
                                    transform="translate(-234.916 -270.818)"
                                    fill="#003a6f"
                                  />
                                </svg>
                              ) : (
                                //</Link>
                                ""
                              )}
                            </Col>
                            <Col md="1" lg="1">
                              <span className="list-btn my-1">
                                <Image
                                  className="m-1 edit-icon"
                                  src={editIcon}
                                  alt="Vertical Dots"
                                  onClick={() => editWarrantyRoute(warData.id)}
                                  // width="8%"
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: " 0.8rem",
                                    width: "1.5rem",
                                  }}
                                />

                                <Image
                                  className="m-1 edit-icon"
                                  src={Delete}
                                  alt="Delete"
                                  onClick={() =>
                                    handleShowDeleteWarShow(warData.id)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    left: "4rem",
                                    top: " 0.8rem",
                                    width: "1.5rem",
                                  }}
                                />
                              </span>
                            </Col>
                          </Row> */}
                          </Card.Body>
                        </Card>
                      ))}
                    </InfiniteScroll>
                  </div>
                )}

                {loading && (
                  <>
                    <Loading />
                  </>
                )}
                {(userWarrantyList.length === 0 ||
                  userWarrantyList.length === undefined) &&
                  !loading &&
                  !showSearchText && (
                    <Row>
                      <Col
                        md="12"
                        lg="12"
                        className="mt-5 pt-5 small-text text-center"
                      >
                        No data found
                      </Col>
                    </Row>
                  )}
                {userWarrantyList.length === 0 &&
                  !loading &&
                  showSearchText &&
                  searchStr && (
                    <Row>
                      <Col
                        md="12"
                        lg="12"
                        className="mt-5 pt-5 small-text text-center"
                      >
                        <div className="empty-search-text-title">
                          There are no results for the search query
                        </div>
                        <div className="empty-search-text-sub-title">
                          Try searching warranties with modified terms
                        </div>
                      </Col>
                    </Row>
                  )}
              </>
            )}
          </>
        )}

        {/* // cancel subscription reminder */}
        <Modal
          show={showCancelReminder}
          onHide={handleShowCancelReminderClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">Cancel Reminder</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <p className="my-3 subtext">Warranty reminder will be turned off</p>
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowCancelReminderClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleCancelReminder(warId)}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* // Delete subscription */}
        <Modal
          show={showDeleteWar}
          onHide={handleShowDeleteWarClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">Delete Warranty</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <p className="my-3 subtext">
              All the warranty related details will be deleted from the family
            </p>
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowDeleteWarClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleDeleteWarranty(warId)}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* View document model */}
        <Modal
          show={showDocSub}
          onHide={handleShowDocSubClose}
          size={isDocument ? "xl" : "lg"}
          scrollable={true}
          className="invite_au_modal doc_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">View Document</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            {isPdf ? (
              <PDFViewer
                document={{
                  url: docLink,
                }}
              />
            ) : (
              //   <>
              //     <Document file={docLink} onLoadSuccess={onDocumentLoadSuccess}>
              //       <Page pageNumber={pageNumber} />
              //     </Document>
              //     <p>
              //       Page {pageNumber} of {numPages}
              //     </p>
              //   </>
              ""
            )}
            {isImage ? (
              <img
                src={docLink}
                alt="Contract Document"
                className="image-document-size"
              />
            ) : (
              ""
            )}
            {isDocument ? (
              <FileViewer fileType="docx" filePath={docLink} />
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            {/* <Link
              to={{
                pathname: docLink,
              }}
              target="_blank"
            >
              Download
            </Link> */}
            <a href={docLink} download>
              Download
            </a>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};
export default Warranties;
