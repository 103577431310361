import React, { useState, useEffect } from "react";
import { Row, Col, Image, Card, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import openHand from "../../assets/images/open-hand.svg";
import { Large } from "../../stories/Button.stories";
import { Medium } from "../../stories/Input.stories";
import { Danger } from "../../stories/Alert.stories";
import AuthService from "../../services/auth.service";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import UserService from "../../services/user.service";

const SignIn = () => {
  let history = useHistory();
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inviteExist, setinviteExist] = useState([]);
  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };

  useEffect(() => {
    // redirect to dashboard if already logged in
    let items = localStorage.getItem("UserDetails");
    if (items) {
      var stringify = JSON.parse(items);
      if (stringify) {
        //redirect to Login
        history.push("/dashboard");
      }
    }

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      let data = {
        email: formValues.email,
        password: formValues.password,
      };
      // api call
      AuthService.login(data).then(
        (res) => {
          // profile api call
          UserService.userProfile(res.data.uuid).then(
            (response) => {
              console.log(response);
              var entry = {
                avatar: response.userData.avatar,
                countryCode: response.userData.countryCode,
                contactNumber: response.userData.contactNumber,
                createdAt: response.userData.createdAt,
                email: response.userData.email,
                firstname: response.userData.firstName,
                id: response.userData.id,
                isActive: response.userData.isActive,
                isContactVerified: response.userData.isContactVerified,
                isEmailVerified: response.userData.isEmailVerified,
                lastname: response.userData.lastName,
                updatedAt: response.userData.updatedAt,
                uuid: res.data.uuid,
                displayId: response.userData.displayId,
                //'accessToken': UserDetails.accessToken,
              };

              localStorage.setItem("UserDetails", JSON.stringify(entry));

              var array = [];
              UserService.getUserFamilyInvite(res.data.uuid).then(
                (response) => {
                  setinviteExist(response.InviteData);
                  array.push(...response.InviteData);
                }
              );

              UserService.getUserFamily(res.data.uuid).then((response) => {
                let activeFam = [];
                for (let i = 0; i < response.usersFamilyData.length; i++) {
                  if (
                    response.usersFamilyData[i].inviteStatus === "Onboarded" &&
                    response.usersFamilyData[i].status === "Active"
                  ) {
                    activeFam.push(response.usersFamilyData[i]);
                  }
                }

                let acceptencePendingFam = [];
                for (let i = 0; i < response.usersFamilyData.length; i++) {
                  if (
                    response.usersFamilyData[i].inviteStatus ===
                      "Acceptance Pending" &&
                    response.usersFamilyData[i].status === "Active"
                  ) {
                    acceptencePendingFam.push(response.usersFamilyData[i]);
                  }
                }

                if (
                  activeFam.length === 0 &&
                  acceptencePendingFam.length === 0
                ) {
                  history.push({
                    pathname: "/subscription",
                    familyList: "",
                    inviteList: "",
                  });
                } else if (activeFam.length === 1) {
                  history.push({
                    pathname:
                      "/family_dashboard/" +
                      response.usersFamilyData[0].Family.uuid,
                    familyList: "",
                    inviteList: "",
                  });
                } else if (activeFam.length > 1) {
                  history.push({
                    pathname: "/dashboard",
                    familyList: "",
                    inviteList: "",
                  });
                } else if (
                  activeFam.length === 0 &&
                  acceptencePendingFam.length > 0
                ) {
                  history.push({
                    pathname: `/myInvites/${response.usersFamilyData[0].Family.uuid}`,
                    familyList: "",
                    inviteList: "",
                  });
                }
              });
            },
            () => {}
          );
        },
        (error) => {
          const errors = {};
          if (error.response === undefined) {
            errors.msg = "Connection timed out. Please try again later";
            setFormErrors(errors);
          } else {
            errors.msg = error.response.data.error;
            if (errors.msg) {
              setFormErrors(errors);
            }
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email && !values.password) {
      errors.msg = "Please enter a valid email address and password";
      errors.email = "Yes";
      errors.password = "Yes";
    } else {
      if (!values.email) {
        errors.msg = "Please enter a valid email address";
        errors.email = "Yes";
      } else if (!regex.test(values.email)) {
        errors.msg = "This is not a valid email format !";
        errors.email = "Yes";
      }

      if (!values.password) {
        errors.msg = "Please enter a valid password";
        errors.password = "Yes";
      } else {
        var alphanumerics = /^.{8,40}$/; // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (!values.password.match(alphanumerics)) {
          errors.msg = "Please enter a valid password";
          errors.password = "Yes";
        }
      }
    }
    return errors;
  };

  const signUp = () => {
    history.push("/signup");
  };

  return (
    <div className="main-view">
      <div className="unAuth-container">
        <Row>
          <Col
            md="4"
            className="pe-5"
            lg="6"
            style={{ height: "calc(100vh - 55x)" }}
          >
            <div className="w-100 h-100 image-login">
              {/* <Image className="login-img" src={loginImg} alt="El dashboard" /> */}
            </div>
          </Col>
          <Col
            md="8"
            lg="6"
            className="d-flex align-items-center"
            style={{ height: "calc(100vh - 55px)" }}
          >
            <div className="w-100">
              <Image className="slide-right" src={openHand} alt="El openHand" />
              <Card className="prelogin-card signin">
                <Card.Body className="p-4">
                  <Form className="login-form" onSubmit={handleSubmit}>
                    <p className="mb-1 title-heading">
                      Sign into Elderly Life Account
                    </p>
                    <span className="mb-4 login-boarder"></span>

                    {Object.keys(formErrors).length !== 0 && isSubmit ? (
                      <Danger variant="danger" message={formErrors.msg} />
                    ) : (
                      ""
                    )}

                    <Form.Group className="mb-3" controlId="">
                      <Form.Label
                        className={formErrors.email ? "invalid-text" : ""}
                      >
                        Email
                      </Form.Label>
                      <Medium
                        type="email"
                        errorClass={formErrors.email ? "invalid-input" : ""}
                        placeholder="John@gmail.com"
                        size="medium"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                      <Form.Text
                        className={formErrors.email ? "invalid-text" : ""}
                      >
                        Enter your registered email address
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                      <Form.Label
                        className={formErrors.password ? "invalid-text" : ""}
                      >
                        Password
                      </Form.Label>
                      <InputGroup className="mb-0">
                        <FormControl
                          type={passwordShown ? "text" : "password"}
                          className={formErrors.password ? "invalid-input" : ""}
                          placeholder="Password"
                          size="medium"
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                        />
                        <InputGroup.Text
                          className={
                            formErrors.password ? "invalid-input" : "pwd-icon"
                          }
                        >
                          <i
                            className={`fa ${
                              passwordShown ? "fa-eye" : "fa-eye-slash"
                            } password-icon`}
                            onClick={togglePassword}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      {/* <button onClick={togglePassword}>Show Password</button> */}

                      <Form.Text
                        className={formErrors.password ? "invalid-text" : " "}
                      >
                        Enter your password
                      </Form.Text>
                    </Form.Group>

                    <div className="align-center py-2">
                      <Large
                        children="Sign In"
                        size="mediumbtn"
                        variant="primary"
                        type="submit"
                        buttonWidth="50%"
                        isLoading={loading ? "true" : "false"}
                        isDisabled={loading ? "true" : "false"}
                      />
                      {/* {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )} */}

                      <p className="pt-4">
                        <Link to="/forgetPassword" className="link-brd">
                          <span
                            className="centre-link"
                            // onClick={() => history.push("/forgetPassword")}
                            style={{
                              "border-bottom": "1px solid #0d6efd",
                              cursor: "pointer",
                            }}
                          >
                            Forgot Password?
                          </span>
                        </Link>
                      </p>

                      <p className="pt-4 small-text">
                        {" "}
                        Don’t have an Account yet?
                        <Link to="/signup" className="link-brd">
                          <span
                            className="centre-link"
                            style={{
                              "border-bottom": "1px solid #0d6efd",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            Sign Up
                          </span>
                        </Link>
                      </p>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SignIn;
