import React from "react";
import { Card } from "react-bootstrap";
import "./Cards.scss";

function FamilyListCard(props) {
  const {
    firstLetter,
    familyId,
    title,
    membershipDate,
    role,
    planname,
    planDetail,
    buttonClick,
    buttonClick1,
    aoDetails,
    Family,
    uuid,
    variant,
    familyName,
    planName,
    planPrice,
    planDuration,
    planImage,
    auSeats,
    suSeats,
    isAo,
    ...rest
  } = props;

  return (
    <div {...rest}>
      {/* old dsgn */}
      {/* <Card className={`listing-cards ${variant}-cards`}>
        <Card.Body
          className="p-1"
          // onClick={buttonClick1}
        >
          <div style={{color: ""}}>
            <div className="d-flex flex-row mt-4 mt-xxl-0">
              <div>
                <span className="card-left">{firstLetter}</span>
                <div>
                  <p className="card-title card-right">{title}</p>
                  <p className="card-subtitle card-right">
                    member since {membershipDate}
                  </p>
                </div>
              </div>
              <div className="DisplayId-FamilyListCard">
                <div className="familyDisplayName">Group ID:</div>
                <div className="familyDisplayId">{familyId}</div>
              </div>
            </div>
            <p className="card-rollText card-right">Your role in this group</p>
            <p className="User-Role-In-Family">{role}</p>
            <div></div>
            <div className="Plan-details-row">
              <p>
                <p className="Plan-details-row-txt">Current plan</p>
                <p className="Plan-details-row-txt-value">{planName}</p>
              </p>
              <p>
                <p className="Plan-details-row-txt">Auto-Renewal</p>
                <p className="Plan-details-row-txt-value">{planDuration}</p>
              </p>
              <p>
                <p className="Plan-details-row-txt">Users</p>
                <p className="Plan-details-row-txt-value">
                  {auSeats === 1 ? (
                    <span>{auSeats} Main User</span>
                  ) : (
                    <span>{auSeats} Main Users</span>
                  )}{" "}
                  |{" "}
                  {suSeats === 1 ? (
                    <span>{suSeats} Support User</span>
                  ) : (
                    <span>{suSeats} Support Users</span>
                  )}
                </p>
              </p>
            </div>
          </div>
        </Card.Body>
      </Card> */}

      {/* new dsgn */}
      <div className="position-relative p-2 ">
        <div className="position-absolute py-1 px-4 dashboard-header">
          {title}
        </div>
        <div className="dashboard-details my-2 mx-2 p-4 dashboard-subheader">
          <div className="d-flex justify-content-between pb-3">
            <div className="font-weight-bold py-1">
              Member since: <span className="mx-1">{membershipDate}</span>
            </div>
            <div
              className=" font-weight-bold py-1 px-2"
              style={{
                fontSize: "12.5px",
                border: "2px solid #36f1cc",
                borderRadius: "5px",
              }}
            >
              Group ID:<span className="mx-1">{familyId}</span>
            </div>
          </div>

          <div className="d-flex flex-column pb-3">
            <div className="font-weight-normal py-1">
              Your role in this group
            </div>
            <div className="font-weight-bold ">{role}</div>
          </div>

          <div className="d-flex flex-column pb-1">
            <div className="font-weight-normal pt-1">Admin for this Group</div>
            <div className="d-flex justify-content-between">
              <div
                className="font-weight-bold "
                style={{ padding: "0.5rem 0" }}
              >
                {aoDetails.User.firstName !== "null"
                  ? aoDetails.User.firstName
                  : aoDetails.User.email}{" "}
                {aoDetails.User.lastName !== "null" && aoDetails.User.lastName}
              </div>
              <div className="dashboard-login" onClick={buttonClick1}>
                Login
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FamilyListCard;
