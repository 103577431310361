import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { ChoosePlanCard } from "../../stories/Subscription.stories";
import { Primary } from "../../stories/Button.stories";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import CheckoutForm from "./CheckoutForm";
import UserService from "../../services/user.service";
import { Outline } from "../../stories/Button.stories";
import SavedCardCheckoutUpdateForm from "./SavedCardCheckoutForm";
import leftArrow from "../../assets/images/left.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const AccountDowngradeSubscription = (props) => {
  var splitUrl = window.location.href.split("/");
  let history = useHistory();
  let { uuid } = useParams();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [trialCheck, settrialCheck] = useState([]);
  const [trialCheck1, settrialCheck1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [cusDetails, setCusDetails] = useState();
  const [priceDetails, setPriceDetails] = useState();
  const [paymentList, setPaymentList] = useState([]);
  const [show, setShow] = useState(false);
  const [planId, setPlanId] = useState();
  const [stripeProductId, setStripeProductId] = useState();
  const [planName, setPlanName] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [priceSymbol, setPriceSymbol] = useState();
  const [planAuSeat, setPlanAuSeat] = useState();
  const [planSuSeat, setPlanSuSeat] = useState();
  const [planFrequency, setPlanFrequency] = useState();
  const [planImageIcon, setPlanImageIcon] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const initialValues = { familyName: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [showChoosePayment, setShowChoosePayment] = useState(false);
  const [showCancelAccount, setShowCancelAccount] = useState(false);
  const [planDetails, setPlanDetails] = useState();
  const [planNameVal, setPlanNameVal] = useState("");
  const [planIdVal, setPlanIdVal] = useState("");
  const [allFamilyUsers, setAllFamilyUsers] = useState([]);
  const [newArrayAU, setnewArrayAU] = useState([]);
  const [newArraySU, setnewArraySU] = useState([]);
  const [allValAU, setallValAU] = useState([]);
  const [allValSU, setallValSU] = useState([]);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [loader, setloader] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (planData) => {
    setShow(true);
    setFormValues({ familyName: familyDetails.FamilyData.Family.name });
    setFormErrors({});
    setIsSubmit(false);
    setPlanId(planData.id);
    setStripeProductId(planData.stripeProductId);
    setPlanName(planData.name);
    setPlanPrice(planData.price);
    setPriceSymbol(planData.Country.symbol);
    setPriceDetails(planData.stripePriceId);
    setPlanAuSeat(planData.availableSeats);
    setPlanSuSeat(planData.availableSupportSeats);
    setPlanFrequency(planData.renewalType);
    setPlanImageIcon(planData.image);
  };
  const handleSwitchPlan = () => setShow(false);
  const handlePaymentClose = () => setShowPayment(false);
  const handlePaymentShow = () => {
    setShowPayment(true);
  };

  // handle confirm submit
  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    handleChoosePaymentShow();
    handleClose();
    setLoading(false);
  };

  const handleCancelAccount = (e) => {
    setShowCancelAccount(true);
  };

  const handleCloseCancelAccount = (e) => {
    setShowCancelAccount(false);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.familyName) {
      errors.msg = "Please enter family name";
      errors.familyName = "Yes";
    } else {
      if (values.familyName.length > 40) {
        errors.msg = "Family name too long";
        errors.familyName = "Yes";
      }
    }
    return errors;
  };

  // choose payment method modal
  const handleChoosePaymentClose = () => setShowChoosePayment(false);
  const handleChoosePaymentShow = () => {
    // get list of customer payment
    let cusdata = { type: "card" };
    UserService.getCustomerPaymentMethodsList(cusdata, cusDetails).then(
      (res) => {
        setPaymentList(res.data);
        setShowChoosePayment(true);
      }
    );
  };

  // Get Family details
  const getFamilyDetails = () => {
    setLoading(true);
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
        setPlanDetails(
          response.FamilyData.Family.Family_Subscription
            .Account_Subscription_Plan
        );
        setPlanNameVal(
          response.FamilyData.Family.Family_Subscription
            .Account_Subscription_Plan.name
        );
        setPlanIdVal(
          response.FamilyData.Family.Family_Subscription
            .Account_Subscription_Plan.availableS
        );
        setCusDetails(response.FamilyData.Family.stripeCustomerId);
        getPlanList();
      }
    );
  };

  const getPlanList = () => {
    UserService.planList().then(
      (response) => {
        setSubscriptionPlans(response);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    UserService.updateSUAURel(uuid).then((response) => {
      setAllFamilyUsers(response.data);
      var newArrAU = [];
      for (var i = 0; i < response.data.length; i++) {
        if (
          response.data[i].isAu === true &&
          response.data[i].status === "Active"
        ) {
          newArrAU.push(response.data[i]);
        }
      }
      setallValAU(newArrAU);

      var newArrSU = [];
      for (var j = 0; j < response.data.length; j++) {
        if (
          response.data[j].isSu === true &&
          response.data[j].status === "Active"
        ) {
          newArrSU.push(response.data[j]);
        }
      }
      setallValSU(newArrSU);

      //logic for btn show/not
      if (
        props.location.state.planId.planId === 1 ||
        props.location.state.planId.planId === 4
      ) {
        if (newArrAU.length <= 1 && newArrSU.length <= 1) {
          setbtnDisabled(true);
        } else {
          setbtnDisabled(false);
        }
      } else if (
        props.location.state.planId.planId === 2 ||
        props.location.state.planId.planId === 5
      ) {
        if (newArrAU.length <= 2 && newArrSU.length <= 2) {
          setbtnDisabled(true);
        } else {
          setbtnDisabled(false);
        }
      }
    });
  }, [uuid]);

  useEffect(() => {
    getFamilyDetails();
  }, []);

  const checkedBoxAU = (event) => {
    var newArr = [];
    var newArrValue = [];
    if (event.target.checked) {
      newArr.push(...newArrayAU, {
        index: event.target.value,
        id: event.target.id,
      });
      setnewArrayAU(newArr);
    } else {
      for (var i = 0; i < newArrayAU.length; i++) {
        if (newArrayAU[i].index === event.target.value) {
          newArrayAU.splice(i, 1);
          newArrValue.push(...newArrayAU);
          setnewArrayAU(newArrValue);
        }
      }
    }
  };

  const checkedBoxSU = (event) => {
    var newArr = [];
    var newArrValue = [];
    if (event.target.checked) {
      newArr.push(...newArraySU, {
        index: event.target.value,
        id: event.target.id,
      });
      setnewArraySU(newArr);
    } else {
      for (var i = 0; i < newArraySU.length; i++) {
        if (newArraySU[i].index === event.target.value) {
          newArraySU.splice(i, 1);
          newArrValue.push(...newArraySU);
          setnewArraySU(newArrValue);
        }
      }
    }
  };

  useEffect(() => {
    var dateCheck = new Date().getFullYear();
    let st = new Date("01-01-" + dateCheck).getTime();
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        UserService.getSubsciptionStripe(
          response.FamilyData.Family.Family_Subscription.stripeSubscriptionId
        ).then((res) => {
          settrialCheck(res);
          settrialCheck1(res.items.data[0]);
        });
      },
      (error) => {}
    );
  }, []);

  const newArrCreation = () => {
    setloader(true);
    let arrayAU = [];
    let arraySU = [];
    for (let i = 0; i < newArrayAU.length; i++) {
      arrayAU.push(parseInt(newArrayAU[i].id));
    }
    for (let j = 0; j < newArraySU.length; j++) {
      arraySU.push(parseInt(newArraySU[j].id));
    }

    //bulk user remove api call
    UserService.bulkRemoval(uuid, arrayAU, arraySU).then((res) => {
      UserService.downgradeSubscription(
        uuid,
        props.location.state.planId.planId,
        props.location.state.stripeCusID
      ).then((response) => {
        let subsciptionData = {
          trial_end: "now",
          proration_behavior: "always_invoice",
          billing_cycle_anchor: "now",
          "items[0][plan]": props.location.state.planId.priceId,
          "items[0][id]": trialCheck1.id,
        };
        UserService.updateSubsciptionStripe(
          subsciptionData,
          trialCheck.id
        ).then((response) => {
          if (response.status !== 400 && response.status !== 404) {
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Subscription Downgraded successfully");
            setTimeout(() => {
              history.push({
                pathname: `/family_dashboard/${uuid}`,
              });
            }, 3000);
          }
        });
      });
    });
  };

  useEffect(() => {
    if (allFamilyUsers.length > 0) {
      if (
        props.location.state.planId.planId === 1 ||
        props.location.state.planId.planId === 4
      ) {
        if (
          allValAU.length - newArrayAU.length <= 1 &&
          allValSU.length - newArraySU.length <= 1
        ) {
          setbtnDisabled(true);
        } else {
          setbtnDisabled(false);
        }
      } else if (
        props.location.state.planId.planId === 2 ||
        props.location.state.planId.planId === 5
      ) {
        if (
          allValAU.length - newArrayAU.length <= 2 &&
          allValSU.length - newArraySU.length <= 2
        ) {
          setbtnDisabled(true);
        } else {
          setbtnDisabled(false);
        }
      }
    }
  }, [newArrayAU]);

  useEffect(() => {
    if (allFamilyUsers.length > 0) {
      if (
        props.location.state.planId.planId === 1 ||
        props.location.state.planId.planId === 4
      ) {
        if (
          allValAU.length - newArrayAU.length <= 1 &&
          allValSU.length - newArraySU.length <= 1
        ) {
          setbtnDisabled(true);
        } else {
          setbtnDisabled(false);
        }
      } else if (
        props.location.state.planId.planId === 2 ||
        props.location.state.planId.planId === 5
      ) {
        if (
          allValAU.length - newArrayAU.length <= 2 &&
          allValSU.length - newArraySU.length <= 2
        ) {
          setbtnDisabled(true);
        } else {
          setbtnDisabled(false);
        }
      }
    }
  }, [newArraySU]);

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  return (
    <>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div className="page-content subscription-pg tab-btn">
        <Container>
          {loading ? (
            <Row style={{ marginTop: "17rem" }}>
              <Col md="12" lg="12" className="text-center">
                <button className="btn loading-btn" type="button">
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                  ></span>
                  Loading . . .
                </button>
              </Col>
            </Row>
          ) : (
            <>
              <h3 className="title">
                {familyDetails.FamilyData
                  ? familyDetails.FamilyData.Family.name
                  : ""}
              </h3>
              <span className="nav-back">
                <div className="Reminder-backBtn">
                  <Link to={`/downgradeSubscription/${uuid}`}>
                    <img src={leftArrow} alt="arrow" className="mr-2" />
                    Back to Elderly Life Account Plans
                  </Link>
                </div>
              </span>
              <Row className="mt-3">
                <Col md="8">
                  <Row>
                    <Col md="12">
                      <h3>Change Account Plan</h3>
                      <p className="sub-title">
                        In order to move to a lower plan than earlier you need
                        to remove few users from your Family.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <p className="left-Col-text-1-downSub">
                        Main Users ( AU ) in Family
                      </p>
                      <div>
                        {allFamilyUsers.map((userList, index) =>
                          userList.isAu === true &&
                          userList.status === "Active" ? (
                            <span key={index}>
                              <div className="AU-SU-downSub">
                                <span className="checkbox-downSub">
                                  <input
                                    type="checkbox"
                                    id={userList.id}
                                    onClick={(event) => checkedBoxAU(event)}
                                    value={index}
                                  ></input>
                                </span>
                                <span className="AU-SU-Text-downSub">
                                  {userList.User.firstName === "null" ? (
                                    <span></span>
                                  ) : (
                                    userList.User.firstName
                                  )}{" "}
                                  {userList.User.lastName === "null" ? (
                                    <span></span>
                                  ) : (
                                    userList.User.lastName
                                  )}
                                </span>
                                <span className="AU-SU-TextEmail-downSub">
                                  {userList.User.firstName !== "null" ? (
                                    <div className="AU-SU-TextEmail-downSub">
                                      {userList.User.email}
                                    </div>
                                  ) : (
                                    <div className="AU-SU-TextEmail-downSub">
                                      {userList.User.email}
                                    </div>
                                  )}
                                </span>
                              </div>
                            </span>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                    <Col md="6">
                      <p className="left-Col-text-1-downSub">
                        Support Users ( SU ) in Family
                      </p>
                      <div>
                        {allFamilyUsers.map((userList, index) =>
                          userList.isSu === true &&
                          userList.status === "Active" ? (
                            <span key={index}>
                              <div className="AU-SU-downSub">
                                <span>
                                  <input
                                    type="checkbox"
                                    onClick={(event) => checkedBoxSU(event)}
                                    value={index}
                                    id={userList.id}
                                  ></input>
                                </span>
                                <span className="AU-SU-Text-downSub">
                                  {userList.User.firstName === "null" ? (
                                    <span></span>
                                  ) : (
                                    userList.User.firstName
                                  )}{" "}
                                  {userList.User.lastName === "null" ? (
                                    <span></span>
                                  ) : (
                                    userList.User.lastName
                                  )}
                                </span>
                                <span>
                                  {userList.User.firstName !== "null" ? (
                                    <div className="AU-SU-TextEmail-downSub">
                                      {userList.User.email}
                                    </div>
                                  ) : (
                                    <div className="AU-SU-TextEmail-downSub">
                                      {userList.User.email}
                                    </div>
                                  )}
                                </span>
                              </div>
                            </span>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Row>
                    <Col md="12">
                      <div className="main-box-downSub">
                        <p
                          className="d-flex"
                          style={{ "justify-content": "space-between" }}
                        >
                          <span className="text-1-downSub">{planNameVal}</span>
                          <span className="text-2-downSub">Present Plan</span>
                        </p>
                        <p>
                          <span>0{planIdVal} Account Users</span>
                          <span className="line-downSub"> | </span>
                          <span>0{planIdVal} Support Users</span>
                        </p>
                      </div>
                      <div className="main-box-downSub">
                        <p
                          className="d-flex"
                          style={{ "justify-content": "space-between" }}
                        >
                          <span className="text-1-downSub">
                            {props.location.state.planId.planId === 1 ||
                            props.location.state.planId.planId === 4
                              ? "Single"
                              : props.location.state.planId.planId === 2 ||
                                props.location.state.planId.planId === 5
                              ? "Couple"
                              : "Family"}
                          </span>
                          <span className="text-2-downSub">Selected Plan</span>
                        </p>
                        <p>
                          <span>
                            0
                            {props.location.state.planId.planId === 1 ||
                            props.location.state.planId.planId === 4 ? (
                              <span>1 Account User </span>
                            ) : props.location.state.planId.planId === 2 ||
                              props.location.state.planId.planId === 5 ? (
                              <span>2 Account Users </span>
                            ) : (
                              <span>5 Account Users </span>
                            )}{" "}
                          </span>
                          <span className="line-downSub"> | </span>
                          <span>
                            0
                            {props.location.state.planId.planId === 1 ||
                            props.location.state.planId.planId === 4 ? (
                              <span>1 Support User</span>
                            ) : props.location.state.planId.planId === 2 ||
                              props.location.state.planId.planId === 5 ? (
                              <span>2 Support Users</span>
                            ) : (
                              <span>5 Support Users</span>
                            )}{" "}
                          </span>
                        </p>
                      </div>
                      <div className="text-box-downSub">
                        The plan you have selected only allows{" "}
                        {props.location.state.planId.planId === 1 ||
                        props.location.state.planId.planId === 4 ? (
                          <span>1 Main user &</span>
                        ) : props.location.state.planId.planId === 2 ||
                          props.location.state.planId.planId === 5 ? (
                          <span>2 Main users &</span>
                        ) : (
                          <span>5 Main users &</span>
                        )}{" "}
                        {props.location.state.planId.planId === 1 ||
                        props.location.state.planId.planId === 4 ? (
                          <span>1 Support user</span>
                        ) : props.location.state.planId.planId === 2 ||
                          props.location.state.planId.planId === 5 ? (
                          <span>2 Support users</span>
                        ) : (
                          <span>5 Support users</span>
                        )}{" "}
                      </div>
                      {
                        <div
                          className={
                            btnDisabled
                              ? "button-box-downSub"
                              : "button-box-downSub-disabled"
                          }
                          onClick={newArrCreation}
                        >
                          {loader ? (
                            <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                            ></span>
                          ) : (
                            <span>Remove selected users and continue</span>
                          )}
                        </div>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </>
  );
};
export default AccountDowngradeSubscription;
