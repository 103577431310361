import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Image } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Medium } from "../../stories/Input.stories";
import ProfileNav from "../ProfileNav/ProfileNav";
import ImagePicker from "../ImagePicker/ImagePicker";
import ProfileModal from "../ProfileModal/ProfileModal";
// import Toast from "react-bootstrap/Toast";
import VerifyEmailCheck from "../../assets/images/verify-email-check.svg";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import AddPayMtdModal from "../ProfileModal/AddPayMtdModal";
import Modal from "react-bootstrap/Modal";
import { Link, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import AuthService from "../../services/auth.service";
import copy from "copy-to-clipboard";
import Loading from "../../common/Loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Profile = (props) => {
  const [loader, setloader] = useState(true);
  var uuid = localStorage.getItem("uuid");
  var contentClass = props.isOpen ? "content open" : "content";
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var userIDVal = localStorage.getItem("userIDVal");

  useEffect(() => {
    UserService.userProfile(UserDetails.uuid).then((response) => {
      setFormValues(response.userData);
      setiniVal(response.userData);
    });
  }, []);
  const [iniVal, setiniVal] = useState({});
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [successToast, setSuccessToast] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChangePhone = (value, data) => {
    console.log(value);
    console.log(data);
    setPhoneNum(value);

    const dialCode = data.dialCode;
    const format = "+";
    const result = format.concat(dialCode);
    setCountrycode(result);

    const phone = value.replace(data.dialCode, "");
    console.log(result);
    console.log(phone);
    setPhoneNumber(phone);

    setFormValues({ ...formValues, countryCode: result, contactNumber: phone });
  };

  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [payMtdModalOpen, setPayMtdModalOpen] = useState(false);
  const [profLocation, setProfLocation] = useState("basic");
  const [payMtds, setPayMtds] = useState([]);

  const [planId, setPlanId] = useState();
  const [loading, setLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [cusDetails, setCusDetails] = useState();
  const [priceDetails, setPriceDetails] = useState();

  const [paymentList, setPaymentList] = useState([]);

  const [newProPicImg, setNewProPicImg] = useState("");

  const [isProfileSubmit, setIsProfileSubmit] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // payment modal
  const [showPayment, setShowPayment] = useState(false);
  const handlePaymentClose = () => setShowPayment(false);
  const handlePaymentShow = () => {
    setShowPayment(true);
  };

  // close update photo modal
  const handleUploadClose = () => setProfileModalOpen(false);

  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const [copyDsgn, setCopyDsgn] = useState(false);
  const [copyDsgn1, setCopyDsgn1] = useState(false);

  // Allow numbers only for Contact Number
  const AllowNumbersOnly = (e) => {
    if ((e.which != 8 && e.which != 0 && e.which < 48) || e.which > 57) {
      e.preventDefault();
    }
  };
  const copyToClipboard = () => {
    copy(UserDetails.displayId);
    // alert(`You have copied "${UserDetails.displayId}"`);
    setCopyDsgn(true);
    setTimeout(() => {
      setCopyDsgn(false);
    }, 2500);
  };

  const copyToClipboard1 = () => {
    copy(displayIdShow);
    // alert(`You have copied "${UserDetails.displayId}"`);
    setCopyDsgn1(true);
    setTimeout(() => {
      setCopyDsgn1(false);
    }, 2500);
  };
  const handleProfileUpdateSubmit = (e) => {
    e.preventDefault();
    validate(formValues);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let isValid = true;

    if (!values.firstName) {
      errors.msg = "Please enter the required fields";
      errors.firstName = "Yes";
      isValid = false;
    }

    if (!values.lastName) {
      errors.msg = "Please enter the required fields";
      errors.lastName = "Yes";
      isValid = false;
    }
    if (!values.email) {
      errors.msg = "Please enter the required fields";
      errors.email = "Yes";
      isValid = false;
    } else if (!regex.test(values.email)) {
      errors.msg = "This is not a valid email format!";
      errors.email = "Yes";
      isValid = false;
    }
    if (values.contactNumber.length !== 10 || !values.contactNumber) {
      errors.msg = "Please enter 10 digit Contact Number";
      errors.contactNumber = "Yes";
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      updateProfile();
    }
  };
  // handle reset password
  const initialResetValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [formResetValues, setFormResetValues] = useState(initialResetValues);
  const [formResetErrors, setFormResetErrors] = useState({});
  const [cmPasswordShown, setCmPasswordShown] = useState(false);
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [isResetSubmit, setIsResetSubmit] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const toggleCmPassword = () => {
    setCmPasswordShown(!cmPasswordShown);
  };
  const toggleOldPassword = () => {
    setOldPasswordShown(!oldPasswordShown);
  };
  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setFormResetValues({ ...formResetValues, [name]: value });
    //setFormCodeValues(codeValue);
  };
  const handleResetSubmit = (e) => {
    e.preventDefault();
    setFormResetErrors(validateReset(formResetValues));
    setIsResetSubmit(true);
    setResetLoading(true);
  };
  const validateReset = (values) => {
    const errors = {};
    if (!values.oldPassword) {
      errors.msg = "Please enter the required fields";
      errors.oldPassword = "Yes";
    }
    if (!values.confirmPassword) {
      errors.msg = "Please enter the required fields";
      errors.confirmPassword = "Yes";
    }
    if (!values.newPassword) {
      errors.msg = "Please enter the required fields";
      errors.password = "Yes";
    } else {
      var alphanumerics = /^.{8,40}$/;
      if (!values.newPassword.match(alphanumerics)) {
        errors.msg = "Please enter a valid password";
        errors.password = "Yes";
        setSuccessToast(false);
        executeScroll();
        window.scrollTo(0, 0);
        setShowToast(true);
        setToastMessage(
          "Please enter a valid password with min 8 characters and max 40 characters"
        );
      }
    }

    if (!errors.password) {
      if (values.confirmPassword != values.newPassword) {
        errors.msg = "Please enter the same password you've entered before";
        errors.confirmPassword = "Yes";
        setSuccessToast(false);
        executeScroll();
        window.scrollTo(0, 0);
        setShowToast(true);
        setToastMessage("Please enter the same password you've entered before");
      }
    }
    return errors;
  };
  console.log(formValues);
  const updateProfile = (e) => {
    let formData = {};
    let isEdited = false;
    console.log(formValues);
    console.log(iniVal);

    if (iniVal.firstName !== formValues.firstName) {
      formData.firstName = formValues.firstName;
      UserDetails.firstName = formValues.firstName;
      isEdited = true;
    }
    if (iniVal.lastName !== formValues.lastName) {
      formData.lastName = formValues.lastName;
      UserDetails.lastName = formValues.lastName;
      isEdited = true;
    }
    if (iniVal.email !== formValues.email) {
      formData.email = formValues.email;
      UserDetails.email = formValues.email;
      UserDetails.isEmailVerified = false;
      isEdited = true;
    }
    if (iniVal.contactNumber !== formValues.contactNumber) {
      formData.contactNumber = formValues.contactNumber;
      UserDetails.contactNumber = formValues.contactNumber;
      isEdited = true;
    }
    if (iniVal.countryCode !== formValues.countryCode) {
      formData.countryCode = formValues.countryCode;
      UserDetails.countryCode = formValues.countryCode;
      isEdited = true;
    }
    if (UserDetails.avatar !== newProPicImg) {
      formData.avatar = newProPicImg;
      UserDetails.avatar = newProPicImg;
      isEdited = true;
    }
    console.log(formData);
    if (isEdited) {
      // api call
      setLoading(true);

      UserService.updateUserProfile(formValues.uuid, formData).then((res) => {
        if (res.status === 400 || res.status === 401) {
          window.scrollTo(0, 0);
          setSuccessToast(false);
          setShowToast(true);
          setLoading(false);
          setToastMessage(res.data.message);
        } else {
          window.scrollTo(0, 0);
          setSuccessToast(true);
          setShowToast(true);
          setLoading(false);
          setToastMessage("Profile updated successfully");
          localStorage.setItem("UserDetails", JSON.stringify(UserDetails));
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      });
    } else {
      setLoading(false);
      setFormErrors({ msg: "Please edit the profile details" });
    }
  };

  let history = useHistory();
  let items = localStorage.getItem("UserDetails");
  if (!items) {
    //redirect to Login
    history.push("/");
  }

  useEffect(() => {
    var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
    setNewProPicImg(UserDetails.avatar);
    // reset password submit
    if (Object.keys(formResetErrors).length === 0 && isResetSubmit) {
      let data = {
        password: formResetValues.oldPassword,
        newPassword: formResetValues.newPassword,
        confNewPassword: formResetValues.confirmPassword,
      };
      UserService.resetUserProfilePassword(UserDetails.uuid, data).then(
        (res) => {
          if (res.status === 401) {
            setLoading(false);
            setResetLoading(false);
            setSuccessToast(false);
            executeScroll();
            window.scrollTo(0, 0);
            setShowToast(true);
            setToastMessage(res.data.error);
          } else {
            setLoading(false);
            setResetLoading(false);
            setSuccessToast(true);
            executeScroll();
            window.scrollTo(0, 0);
            setShowToast(true);
            setToastMessage("Password updated successfully");
            // logout
            setTimeout(() => {
              AuthService.logout();
              history.push("/");
            }, 2000);
          }
        },
        (error) => {
          const errors = {};
          if (error.response === undefined) {
            errors.msg = "Something went wrong. Please try again later";
            formResetErrors(errors);
          } else {
            errors.msg = error.response.data.error;
            if (errors.msg) {
              formResetErrors(errors);
            }
          }
          setResetLoading(false);
        }
      );
    } else {
      setResetLoading(false);
    }
  }, [formResetErrors]);
  const [displayIdShow, setdisplayIdShow] = useState("");
  useEffect(() => {
    console.log(UserDetails);
    const result =
      UserDetails.countryCode | UserDetails.contactNumber
        ? UserDetails.countryCode.concat(UserDetails.contactNumber)
        : "";
    setPhoneNum(result);
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        console.log(response);
        setdisplayIdShow(response.FamilyData.Family.displayId);
        setTimeout(() => {
          setloader(false);
        }, 250);
      }
    );
  }, []);

  const countrySelectProps = {
    disabled: true, // Disable the country input
  };

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <div className="page-content subscription-pg">
      <Container fluid ref={myRef}>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {loader ? (
          <div className="loadind-page">
            <Loading />
          </div>
        ) : (
          <>
            <Modal
              show={profileModalOpen}
              onHide={handleUploadClose}
              className="profile_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Update Profile Photo</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProfileModal
                  modalOpen={profileModalOpen}
                  setModalOpen={setProfileModalOpen}
                  newProPicImg={newProPicImg}
                  setNewProPicImg={setNewProPicImg}
                />
              </Modal.Body>
            </Modal>

            <Modal
              show={showPayment}
              onHide={handlePaymentClose}
              className="payment_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Add New Card</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="mb-3 subtext">
                  Add the details below to save a new card
                </p>
                <AddPayMtdModal customerId={cusDetails} />
              </Modal.Body>
            </Modal>
            <Row>
              <Row>
                <Col className="text-left">
                  <p className="mb-1 title-heading">Profile</p>
                  <p className="sub-text mb-0">
                    Manage your Profile on Elderly Life Platform.
                  </p>
                </Col>
              </Row>
              <Row>
                <ProfileNav
                  profLocation={profLocation}
                  setProfLocation={setProfLocation}
                />
              </Row>
              {profLocation === "basic" ? (
                <Row>
                  <Col md="8" lg="8" className="text-left">
                    <p className="mb-1 title-heading">Basic Profile</p>

                    {Object.keys(formErrors).length !== 0 && isProfileSubmit ? (
                      <p className="error-txt-msg">{formErrors.msg} </p>
                    ) : (
                      ""
                    )}
                    <ImagePicker
                      newProPicImg={newProPicImg}
                      setProfileModalOpen={setProfileModalOpen}
                    />

                    <p className="sub-text">
                      Profile photo can be of max size 500kb and in JPEG/PNG
                      formats.
                    </p>
                    <Row>
                      <Col lg="6">
                        <div className="familyDisplayId-FamilyDashboard-profile px-2 py-1">
                          <div className="familyDisplayName">User ID: </div>
                          {UserDetails.displayId != null ||
                          UserDetails.displayId != undefined ? (
                            <div className="familyDisplayId">
                              {UserDetails.displayId}
                            </div>
                          ) : (
                            <div className="familyDisplayId">N/A</div>
                          )}
                          <button
                            className="button-CopyToClipboard1"
                            onClick={copyToClipboard}
                          >
                            <svg
                              id="copy"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.574"
                              height="16.99"
                              viewBox="0 0 15.574 16.99"
                            >
                              <path
                                id="Path_16596"
                                data-name="Path 16596"
                                d="M6.194,116.933a3.013,3.013,0,0,1-3.009-3.009v-7.256H1.947A1.948,1.948,0,0,0,0,108.615v9.557a1.948,1.948,0,0,0,1.947,1.947H10.8a1.948,1.948,0,0,0,1.947-1.947v-1.239Zm0,0"
                                transform="translate(0 -103.128)"
                                fill="#9ac2e5"
                              />
                              <path
                                id="Path_16597"
                                data-name="Path 16597"
                                d="M139.327,1.947A1.947,1.947,0,0,0,137.38,0h-7.433A1.947,1.947,0,0,0,128,1.947V10.8a1.947,1.947,0,0,0,1.947,1.947h7.433a1.947,1.947,0,0,0,1.947-1.947Zm0,0"
                                transform="translate(-123.752)"
                                fill="#9ac2e5"
                              />
                            </svg>
                          </button>
                        </div>
                        {copyDsgn ? (
                          <div className="copy-dsgn">Copied</div>
                        ) : (
                          <div
                            className="copy-dsgn"
                            style={{ visibility: "hidden" }}
                          >
                            Copied
                          </div>
                        )}
                      </Col>
                      <Col lg="6">
                        <div className="familyDisplayId-FamilyDashboard-profile px-2 py-1">
                          <div className="familyDisplayName">
                            Current Group ID:{" "}
                          </div>
                          {displayIdShow != null ||
                          displayIdShow !== undefined ? (
                            <div className="familyDisplayId">
                              {displayIdShow}
                            </div>
                          ) : (
                            <div className="familyDisplayId">N/A</div>
                          )}
                          <button
                            className="button-CopyToClipboard1"
                            onClick={copyToClipboard1}
                          >
                            <svg
                              id="copy"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.574"
                              height="16.99"
                              viewBox="0 0 15.574 16.99"
                            >
                              <path
                                id="Path_16596"
                                data-name="Path 16596"
                                d="M6.194,116.933a3.013,3.013,0,0,1-3.009-3.009v-7.256H1.947A1.948,1.948,0,0,0,0,108.615v9.557a1.948,1.948,0,0,0,1.947,1.947H10.8a1.948,1.948,0,0,0,1.947-1.947v-1.239Zm0,0"
                                transform="translate(0 -103.128)"
                                fill="#9ac2e5"
                              />
                              <path
                                id="Path_16597"
                                data-name="Path 16597"
                                d="M139.327,1.947A1.947,1.947,0,0,0,137.38,0h-7.433A1.947,1.947,0,0,0,128,1.947V10.8a1.947,1.947,0,0,0,1.947,1.947h7.433a1.947,1.947,0,0,0,1.947-1.947Zm0,0"
                                transform="translate(-123.752)"
                                fill="#9ac2e5"
                              />
                            </svg>
                          </button>
                        </div>
                        {copyDsgn1 ? (
                          <div className="copy-dsgn">Copied</div>
                        ) : (
                          <div
                            className="copy-dsgn"
                            style={{ visibility: "hidden" }}
                          >
                            Copied
                          </div>
                        )}
                      </Col>
                    </Row>

                    {/* <Col  md="5" lg="5" className="text-left"> */}
                    <Form>
                      <Row>
                        <Col lg="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label
                              className={
                                formErrors.firstName ? "invalid-text" : "mb-1"
                              }
                            >
                              First name
                            </Form.Label>
                            <Medium
                              type="text"
                              errorClass={
                                formErrors.firstName ? "invalid-input" : ""
                              }
                              placeholder="Tiffany"
                              size="medium"
                              name="firstName"
                              value={formValues.firstName}
                              onChange={handleChange}
                              //(event) => this.handleUserInput(event)
                            />
                          </Form.Group>
                        </Col>

                        <Col lg="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label
                              className={
                                formErrors.lastName ? "invalid-text" : "mb-1"
                              }
                            >
                              Last name
                            </Form.Label>
                            <Medium
                              type="text"
                              errorClass={
                                formErrors.lastName ? "invalid-input" : ""
                              }
                              placeholder="Walker"
                              size="medium"
                              name="lastName"
                              value={formValues.lastName}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Form.Group className="mb-3" controlId="">
                            <Form.Label
                              className={
                                formErrors.email
                                  ? "invalid-text d-flex justify-content-between"
                                  : "mb-1 d-flex justify-content-between"
                              }
                            >
                              Email
                              {formValues.isEmailVerified ? (
                                <div className="d-flex gap-1 align-items-center">
                                  <img
                                    src={VerifyEmailCheck}
                                    alt="VerifyEmailCheck"
                                  />
                                  <span>
                                    <small>Verified</small>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </Form.Label>
                            {/* <Form.Control type="email" placeholder="John@gmail.com" /> */}
                            <Medium
                              type="email"
                              errorClass={
                                formErrors.email ? "invalid-input" : ""
                              }
                              placeholder="tiffanyw@gmail.com"
                              size="medium"
                              name="email"
                              value={formValues.email}
                              onChange={handleChange}
                            />
                            {formErrors.email && (
                              <p className="invalid-text subtext mt-1">
                                Invalid Email
                              </p>
                            )}
                          </Form.Group>
                        </Col>

                        <Col lg="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label
                              className={
                                formErrors.contactNumber
                                  ? "invalid-text d-flex justify-content-between"
                                  : "mb-1 d-flex justify-content-between"
                              }
                            >
                              Contact Number
                              {formValues.isContactVerified ? (
                                <div className="d-flex gap-1 align-items-center">
                                  <img
                                    src={VerifyEmailCheck}
                                    alt="VerifyEmailCheck"
                                  />
                                  <span>
                                    <small>Verified</small>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </Form.Label>
                            <div className="profileContact-row">
                              {/* <Col md="3">
                                <Medium
                                  type="select"
                                  errorClass={
                                    formErrors.countryCode
                                      ? "invalid-input"
                                      : ""
                                  }
                                  placeholder=""
                                  size="medium"
                                  name="countryCode"
                                  id="countryCode"
                                  value="+44"
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md="9" className="p-0">
                                <Medium
                                  type="text"
                                  errorClass={
                                    formErrors.contactNumber
                                      ? "invalid-input"
                                      : ""
                                  }
                                  placeholder="Contact Number"
                                  size="medium"
                                  maxLength="10"
                                  name="contactNumber"
                                  value={formValues.contactNumber}
                                  onChange={handleChange}
                                  onKeyPress={AllowNumbersOnly}
                                />
                              </Col> */}
                              <PhoneInput
                                country="gb"
                                value={phoneNum}
                                onChange={handleChangePhone}
                                countryCodeEditable={false}
                              />
                            </div>
                            {formErrors.contactNumber && (
                              <p className="invalid-text subtext mt-1">
                                Please add your 10 digit contactNumber
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col md="4" lg="4" className="p-0 text-right">
                    <Large
                      children="Update and Save"
                      size="mediumbtn"
                      variant="primary"
                      type=""
                      onClick={handleProfileUpdateSubmit}
                      // buttonWidth="259px"
                      isLoading={loading ? "true" : "false"}
                      isDisabled={loading ? "true" : "false"}
                    />
                    {/* {loading && (
                  <span className="mr-4 spinner-border spinner-border-sm"></span>
                )} */}
                  </Col>
                </Row>
              ) : profLocation === "paymentMtd" ? (
                <Row style={{ paddingRight: 0 }}>
                  <Row className="mb-3">
                    <Col md="6" lg="6" className="text-left">
                      <p className="mb-1 title-heading">Payment Methods</p>
                      <p className="mb-3">
                        Find all your payment methods on Elderly Life Platform
                      </p>
                    </Col>
                    <Col
                      style={{ marginRight: -12 }}
                      className="p-0 text-right"
                    >
                      <Large
                        children="+Add New Payment"
                        size="mediumbtn"
                        variant="primary"
                        type=""
                        // buttonWidth="259px"
                        //  onClick={()=>{setPayMtdModalOpen(true)}}
                        onClick={() => {
                          handlePaymentShow();
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="flex-row">
                    {/*   <Card  className="paymentMtd-card" >
                  <Card.Body className="p-4">
                    <Row>
                        <Col className="text-left">
                          <p className="mb-0 medium-text">Mastercard ending in</p>
                          <p className="mt-1  title-heading">**** **** **** 2212</p>
                          <p className="sub-text">Expiration date : 07/2023</p>
                        </Col>
                        <Col className='text-right'>
                            <button className='greenBtn'>
                              <p className="medium-text m-0">Default Payment</p>
                            </button>
                          </Col>
                      </Row>  
                  </Card.Body>
                </Card>
                <Card  className="paymentMtd-card" >
                  <Card.Body className="p-4">
                    <Row>
                        <Col className="text-left">
                          <p className="mb-0 medium-text">Mastercard ending in</p>
                          <p className="mt-1  title-heading">**** **** **** 6656</p>
                          <p className="sub-text">Expiration date : 07/2025</p>
                        </Col>
                        <Col className='text-right '>
                          <button className='blueBtn'>
                            <p className="medium-text m-0">Make Default</p>
                          </button>
                        </Col>
                      </Row>  
                  </Card.Body>
                </Card> */}

                    {payMtds.length != 0 ? (
                      payMtds?.map((list, index) => {
                        let key = { index };
                        return (
                          <Card key={key} className="paymentMtd-card">
                            <Card.Body className="p-4">
                              <Row>
                                <Col className="text-left">
                                  <p className="mb-0 medium-text">
                                    Mastercard ending in
                                  </p>
                                  <p className="mt-1  title-heading">
                                    **** **** **** {list.card.last4}
                                  </p>
                                  <p className="sub-text">
                                    Expiration date : {list.card.exp_month}/
                                    {list.card.exp_year}
                                  </p>
                                </Col>
                                <Col className="text-right">
                                  <button className="greenBtn">
                                    <p className="medium-text m-0">
                                      Make Default
                                    </p>
                                  </button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        );
                      })
                    ) : (
                      <>
                        <div className="text-center saved-card-empty">
                          No saved cards
                        </div>
                      </>
                    )}

                    {/*  {payMtds?.map(({list,index})=>{
                  let key={index}
                  return(
                    <Card key={key} className="paymentMtd-card">
                      <Card.Body className="p-4">
                        <Row>
                          <Col className="text-left">
                            <p className="mb-0 medium-text">Mastercard ending in</p>
                            <p className="mt-1  title-heading">**** **** **** {list.card.last4}</p>
                            <p className="sub-text">Expiration date : {list.card.exp_year}</p>
                          </Col>
                          <Col className='text-right'>
                            <button className='greenBtn'>
                              <p className="medium-text m-0">Default Payment</p>
                            </button>
                          </Col>
                        </Row>  
                      </Card.Body>
                    </Card>
                  )
                })} */}
                  </Row>
                </Row>
              ) : (
                <Row>
                  <Col md="6" lg="6" className="text-left">
                    <p className="mb-4 title-heading">
                      Change your Elderly Life Password
                    </p>
                  </Col>

                  <Form onSubmit={handleResetSubmit}>
                    <Row>
                      <Col md="3" lg="4" className="text-left">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label
                            className={
                              formResetErrors.oldPassword
                                ? "invalid-text"
                                : "mb-1"
                            }
                          >
                            Current Password
                          </Form.Label>

                          <InputGroup className="mb-0">
                            <FormControl
                              type={oldPasswordShown ? "text" : "password"}
                              className={
                                formResetErrors.oldPassword
                                  ? "invalid-input"
                                  : ""
                              }
                              size="medium"
                              name="oldPassword"
                              value={formResetValues.oldPassword}
                              onChange={handleResetChange}
                            />
                            <InputGroup.Text
                              className={
                                formResetErrors.oldPassword
                                  ? "invalid-input"
                                  : "pwd-icon"
                              }
                            >
                              <i
                                className={`fa ${
                                  oldPasswordShown ? "fa-eye" : "fa-eye-slash"
                                } password-icon`}
                                onClick={toggleOldPassword}
                              />
                            </InputGroup.Text>
                          </InputGroup>

                          <small className="form-text">
                            {" "}
                            Enter your previous password.
                          </small>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label
                            className={
                              formResetErrors.password ? "invalid-text" : "mb-1"
                            }
                          >
                            New Password
                          </Form.Label>

                          <InputGroup className="mb-0">
                            <FormControl
                              type={passwordShown ? "text" : "password"}
                              className={
                                formResetErrors.password ? "invalid-input" : ""
                              }
                              size="medium"
                              name="newPassword"
                              value={formResetValues.newPassword}
                              onChange={handleResetChange}
                            />
                            <InputGroup.Text
                              className={
                                formResetErrors.password
                                  ? "invalid-input"
                                  : "pwd-icon"
                              }
                            >
                              <i
                                className={`fa ${
                                  passwordShown ? "fa-eye" : "fa-eye-slash"
                                } password-icon`}
                                onClick={togglePassword}
                              />
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md="3" lg="4" className="text-left align-bottom">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label
                            className={
                              formResetErrors.confirmPassword
                                ? "invalid-text"
                                : "mb-1"
                            }
                          >
                            Re-enter Password
                          </Form.Label>

                          <InputGroup className="mb-0">
                            <FormControl
                              type={cmPasswordShown ? "text" : "password"}
                              className={
                                formResetErrors.confirmPassword
                                  ? "invalid-input"
                                  : ""
                              }
                              size="medium"
                              name="confirmPassword"
                              value={formResetValues.confirmPassword}
                              onChange={handleResetChange}
                            />
                            <InputGroup.Text
                              className={
                                formResetErrors.confirmPassword
                                  ? "invalid-input"
                                  : "pwd-icon"
                              }
                            >
                              <i
                                className={`fa ${
                                  cmPasswordShown ? "fa-eye" : "fa-eye-slash"
                                } password-icon`}
                                onClick={toggleCmPassword}
                              />
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-left mt-3">
                        <Large
                          children="Update and Save"
                          size="mediumbtn"
                          variant="primary"
                          type="submit"
                          // buttonWidth="259px"
                          isLoading={resetLoading ? "true" : "false"}
                          isDisabled={resetLoading ? "true" : "false"}
                        />
                        {/* {resetLoading && (
                      <span className="mr-4 spinner-border spinner-border-sm"></span>
                    )} */}
                      </Col>
                    </Row>
                  </Form>
                </Row>
              )}
            </Row>
            {/* </Container> */}
          </>
        )}
      </Container>
    </div>
  );
};
export default Profile;
