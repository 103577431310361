import React, { useState, useEffect } from "react";
import {
  useStripe,
  CardElement,
  useElements,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { Row, Col } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";
import UserService from "../../services/user.service";
import { useHistory } from "react-router-dom";
import { Small } from "../../stories/Input.stories";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const NewCardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  let history = useHistory();
  var isCardComplete = false;
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // var cardVal = JSON.parse(localStorage.getItem("hi"));
  var savedCardNo = localStorage.getItem("hello");

  const initialValues = {
    name: UserDetails.firstname + " " + UserDetails.lastname,
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "GB",
    isCardValid: false,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const handleCardChange = (e) => {
    if (e.complete) {
      isCardComplete = true;
      setFormValues({ ...formValues, isCardValid: isCardComplete });
    } else {
      setLoading(false);
      // setIsDisabled(false);
      setFormValues({ ...formValues, isCardValid: false });
    }

    if (isCardComplete || formValues.isCardValid) {
      if (
        formValues.name &&
        formValues.addressLine1 &&
        formValues.addressLine2 &&
        formValues.city &&
        formValues.state
      ) {
        // setIsDisabled(false);
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (isCardComplete || formValues.isCardValid) {
      if (
        formValues.name &&
        formValues.addressLine1 &&
        formValues.addressLine2 &&
        formValues.city &&
        formValues.state
      ) {
        // setIsDisabled(false);
      }
    }
  };

  const handleSubmit = (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setFormErrors(validate(formValues));

    if (Object.keys(formErrors).length > 0) {
    }
    // handle confirm submit
    if (Object.keys(formErrors).length === 0) {
      // setIsDisabled(false);
      setLoading(true);
      // create payment method
      if (
        formValues.country === "" ||
        formValues.city === "" ||
        formValues.addressLine1 === "" ||
        formValues.addressLine2 === "" ||
        formValues.state === "" ||
        formValues.name === ""
      ) {
        setLoading(true);
        setShowToast(true);
        setSuccessToast(false);
        setToastMessage("Please fill all the blank places");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        stripe
          .createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
              name: formValues.name,
              address: {
                city: formValues.city,
                country: formValues.country,
                line1: formValues.addressLine1,
                line2: formValues.addressLine2,
                state: formValues.state,
              },
            },
          })
          .then(function (result) {
            // attach payment method
            let attachData = { customer: props.customerId };
            let pushArr = [];
            pushArr.push(props.cardsaved);
            let itemscard = [];

            if (result.paymentMethod != undefined) {
              if (pushArr[0].includes(result.paymentMethod.card.last4)) {
                setLoading(false);
                setShowToast(true);
                setSuccessToast(false);
                setToastMessage("Please add different card no");
              } else {
                UserService.attachPaymentMethod(
                  attachData,
                  result.paymentMethod.id
                ).then((response) => {
                  setLoading(false);
                  setShowToast(true);
                  setSuccessToast(true);
                  setToastMessage("Your card successfully added");
                  props.setNewCard(response);
                  props.cardsaved.push(result.paymentMethod.card.last4);
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 2000);
                });
              }
            } else {
              setLoading(true);
              setShowToast(true);
              setSuccessToast(false);
              setToastMessage("Please fill all the blank places");
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }
          });
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    if (
      !values.name &&
      !values.addressLine1 &&
      !values.addressLine2 &&
      !values.city &&
      !values.state &&
      !values.country
    ) {
      errors.msg = "Please enter family name";
      errors.name = "Yes";
      errors.addressLine1 = "Yes";
      errors.addressLine2 = "Yes";
      errors.city = "Yes";
      errors.state = "Yes";
      errors.country = "Yes";
    } else {
    }
    return errors;
  };

  return (
    <>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <form className="payment-form" onSubmit={handleSubmit}>
        <label
          className={
            formErrors.name ? "invalid-text form-label mb-0" : "form-label mb-0"
          }
        >
          Card Holder Name
        </label>
        <Small
          type="test"
          errorClass={formErrors.name ? "invalid-input" : ""}
          placeholder="John Mike"
          size="small"
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />
        <label className="form-label  mt-3  mb-0">Card Details</label>
        <CardElement onChange={handleCardChange} />
        <label
          className={
            formErrors.addressLine1 &&
            formErrors.addressLine2 &&
            formErrors.city &&
            formErrors.state
              ? "invalid-text form-label mb-0"
              : "form-label mb-0"
          }
        >
          Billing Address
        </label>
        <Small
          type="test"
          errorClass={formErrors.addressLine1 ? "invalid-input" : ""}
          placeholder="Line 1"
          size="small"
          name="addressLine1"
          value={formValues.addressLine1}
          onChange={handleChange}
        />
        <div className="my-3">
          <Small
            type="test"
            errorClass={formErrors.addressLine2 ? "invalid-input" : ""}
            placeholder="Line 2"
            size="small"
            name="addressLine2"
            value={formValues.addressLine2}
            onChange={handleChange}
          />
        </div>
        <Row>
          <Col>
            <Small
              type="test"
              errorClass={formErrors.city ? "invalid-input" : ""}
              placeholder="City"
              size="small"
              name="city"
              value={formValues.city}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Small
              type="test"
              errorClass={formErrors.state ? "invalid-input" : ""}
              placeholder="State"
              size="small"
              name="state"
              value={formValues.state}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <div className="mt-3 mb-5">
          <Small
            type="test"
            //errorClass={formErrors.state ? "invalid-input" : ""}
            placeholder="United Kingdom of Great Britain (GB)"
            size="small"
            name="country"
            value="GB"
            disabled
          />
          {/* <select
      value={formValues.country}
      name="country"
      onChange={handleChange}
    >
      <option value="" disabled>
        Select country
      </option>
      <option value="GB">United Kingdom of Great Britain (GB)</option>
    </select> */}
        </div>
        <div className="text-center">
          <Primary
            children="Add Card"
            variant="primary"
            size="mediumbtn"
            type="submit"
            buttonWidth="55%"
            disabled={isDisabled ? "disabled" : ""}
            isLoading={loading ? "true" : "false"}
            isDisabled={loading ? "true" : "false"}
          />
          {/* {loading && <span className="spinner-border spinner-border-sm"></span>} */}
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </div>
      </form>
    </>
  );
};

export default NewCardForm;
