import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  const abc = localStorage.getItem("UserDetails");
  return (
    <footer className="main-footer">
      <Row className="mx-3">
        <Col md="6" sm="6" xs="6">
          <p className="mb-0 py-2">
            ©{new Date().getFullYear()} Elderly Life Ltd. All rights reserved
          </p>
        </Col>
        <Col md="6" sm="6" xs="6" className="footer-menu">
          <a
            href="https://elderlylife.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            <devicePixelRatio>Terms and conditions</devicePixelRatio>
          </a>
          <a
            href="https://elderlylife.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <div>Privacy policy</div>
          </a>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
