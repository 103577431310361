import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function AccountPlanCard({ uuid, cardDetails, currentPlanLoader }) {
  return (
    <div className="account-plan-card mb-4 ">
      {!currentPlanLoader && (
        <Row className="p-4 space-between">
          <Col className="col-auto">
            <Row>
              <Col className="col-auto">
                <div className="default-plan-img">
                  <img src={cardDetails.image} alt="plan" />
                </div>
              </Col>
              <Col className="col-auto">
                <p className="mb-0  title-heading">{cardDetails.name}</p>
                <p className="mb-0   text-horizonblue font-medium">
                  {cardDetails.renewalTyp} Plan
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="col-auto">
                <p className="sub-text mb-0">
                  {cardDetails.availableS == 1 ? (
                    <span>{cardDetails.availableS} Main user</span>
                  ) : (
                    <span>{cardDetails.availableS} Main users</span>
                  )}
                </p>
              </Col>
              <Col
                className="col-auto"
                style={{ borderLeft: "1px solid #C0DCF5" }}
              >
                <p className="sub-text mb-0">
                  {cardDetails.availableS == 1 ? (
                    <span>{cardDetails.availableS} support user</span>
                  ) : (
                    <span>{cardDetails.availableS} support users</span>
                  )}
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="p-0 mr-2 col-auto">
            <Link to={`/downgradeSubscription/${uuid}`}>
              <button className="mt-3 change-btn">
                <span className="font-large text-blue">Change Plan</span>
              </button>
            </Link>
          </Col>
        </Row>
      )}
      {currentPlanLoader && (
        <Row>
          <Col className="text-center">
            <button className="btn loading-btn" type="button">
              <span
                className="spinner-border spinner-border-sm mt-5 mr-2"
                role="status"
              ></span>
              Loading Account plan . . .
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default AccountPlanCard;
