import React from "react";
import { Card } from "react-bootstrap";
import "./Cards.scss";

function InvitationCards(props) {
  const {
    userRole,
    variant,
    familyName,
    invitedBy,
    acceptClick,
    rejectClick,
    ...rest
  } = props;
  return (
    <div {...rest}>
      <Card className={`listing-cards ${variant}-cards`}>
        <Card.Body className="p-1">
          <div className="d-flex justify-content-between">
            <div>
              <p className="card-title">{familyName}</p>
              <p className="card-subtitle">as a {userRole}</p>
              <p className="card-smallText">
                Invited by :{" "}
                {invitedBy === "null null" ? "Dear User" : invitedBy}
              </p>
            </div>

            <div>
              <span className="action-right">
                <button
                  className="invite-btn accept-btn"
                  name="accept"
                  onClick={acceptClick}
                  style={{ width: "9rem" }}
                >
                  Accept
                </button>
              </span>
              <span className="action-right">
                <button
                  className="invite-btn reject-btn"
                  name="reject"
                  onClick={rejectClick}
                  style={{ width: "9rem" }}
                >
                  Reject
                </button>
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default InvitationCards;
