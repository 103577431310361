import React from "react"
import BenefitsCard from '../components/Cards/BenefitsCard'

export default {
    title: 'Cards/Benefits',
    component: BenefitsCard,
    args: {
        message: 'ActionAlert message',

    },
}

const Template = args => <BenefitsCard {...args} />

export const Benefits_Card = Template.bind({})
Benefits_Card.args = {
    variant: 'basic',
}