import React from "react"
import Alert from '../components/Alert/Alert'

export default {
    title: 'Notification/Alert',
    component: Alert,
    args: {
        message: 'Alert message',
       
    },
}

const Template = args => <Alert {...args} />

export const Success = Template.bind({})
Success.args = {
    message: 'Success message alert',
    variant: 'success'
}

export const Danger = Template.bind({})
Danger.args = {
    message: 'Danger message alert',
    variant: 'danger'
}