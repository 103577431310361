import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Primary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import { Medium } from "../../stories/Input.stories";
import Select from "react-select";
import Loading from "../../common/Loading";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import docIcon from "../../assets/images/doc_icon.png";
import closeIcon from "../../assets/images/close_icon.png";

const EditGiftCard = (props) => {
  let history = useHistory();
  let { uuid, giftId, userUUID } = useParams();
  const [familyDetails, setFamilyDetails] = useState([]);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const initialValues = {
    cardType: "",
    supplier: "",
    total_amt: "0",
    used_amt: "0",
    expiry_date: "",
    gift_card_doc: "",
  };
  const filterOption = [
    { value: "Voucher", label: "Voucher" },
    { value: "Gift Card", label: "Gift Card" },
  ];
  const myRef = useRef(null);
  const [addUpdate, setaddUpdate] = useState(false);
  const initialSupplierOption = [{ value: "", label: "select a supplier" }];
  const [supplierOption, setSupplierOption] = useState(initialSupplierOption);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [giftCardDetails, setGiftCardDetails] = useState([]);
  const [showGcFile, setShowGcFile] = useState("");
  const [Uploadedfile, setUploadedfile] = useState("");

  const [uploadedDocUrl, setUploadedDocUrl] = useState("");
  const [Loadingdata, setLoadingdata] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [browserLoading, setBrowserLoading] = useState(false);
  const [browserMsg, setBrowserMsg] = useState("");
  const [browserErrMsg, setBrowserErrMsg] = useState("");
  const [filtervalue, setfiltervalue] = useState();
  const [giftcardsUrl, setGiftcardsUrl] = useState("");
  const [backText, setBackText] = useState("Back to My Vouchers & Gift Cards");
  const executeScroll = () => myRef.current.scrollIntoView();
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedSender, setSelectedSender] = useState();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "0.8rem",
      borderRadius: "5px",
      borderStyle: "none",
      cursor: "pointer",
      padding: "6px 0",
      border: "1px solid #5985ac !important",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  const [valueChk, setValueChk] = useState("");
  const handleChange = (e, action) => {
    setValueChk("ValueChk");
    if (action) {
      var targetData = {
        name: action.name,
        value: e.value,
      };

      if (action.name === "supplier") {
        setSelectedSupplier(e);
      }
      if (action.name === "cardType") {
        setSelectedSender(e);
        setfiltervalue(e.value);
        setSelectedSupplier({ value: "", label: "select a supplier" });
      }
    } else if (e.target.name === "total_amt") {
      var targetData = {
        name: e.target.name,
        value:
          e.target.value.indexOf(".") >= 0
            ? e.target.value.substr(0, e.target.value.indexOf(".")) +
              e.target.value.substr(e.target.value.indexOf("."), 3)
            : e.target.value,
        //value: parseFloat(e.target.value)
      };
    } else if (e.target.name === "used_amt") {
      var targetData = {
        name: e.target.name,
        value:
          e.target.value.indexOf(".") >= 0
            ? e.target.value.substr(0, e.target.value.indexOf(".")) +
              e.target.value.substr(e.target.value.indexOf("."), 3)
            : e.target.value,
        //value: parseFloat(e.target.value)
      };
    } else {
      var targetData = {
        name: e.target.name,
        value: e.target.value,
      };
    }
    const { name, value } = targetData;
    setFormValues({ ...formValues, [name]: value });

    // upload img
    if (name === "gift_card_doc") {
      setaddUpdate(true);
      setBrowserLoading(true);
      setBrowserErrMsg("");
      // upload file
      var documentFile = e.target.files[0];

      var reader = new FileReader();
      reader.onload = function (e) {
        var MAX_FILE_SIZE = 2; // 2MB

        if (
          [
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(documentFile.type)
        ) {
          let fileSize = documentFile.size / 1024 / 1024;
          if (fileSize > MAX_FILE_SIZE) {
            setBrowserErrMsg("OOPS! File size is above 2MB.");
            setBrowserLoading(false);
          } else {
            uploadFile(documentFile);
          }
        } else {
          setBrowserErrMsg("OOPS! The file format doesn't support");
          setBrowserLoading(false);
        }
      };
      if (documentFile != undefined) {
        reader.readAsDataURL(documentFile);
      } else {
        setBrowserLoading(false);
      }
    }
    // if (name === "used_amt") {
    //   setFormErrors({ ...formErrors, used_amt: "" });
    // }
  };

  const getFamilyDetails = (userId) => {
    // AU Family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);

        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            setUserCreateUpdate(response.FamilyData.id);
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };

  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdate(response.FamilyData.id);
        setUserCreateUpdateFor(response.FamilyData.id);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setGiftcardsUrl("/user_vouchers_&_gift_cards/" + uuid + "/" + userUUID);
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setGiftcardsUrl("/vouchers_&_gift_cards/" + uuid);
    }

    // get giftcard details by ID
    UserService.getGiftcardDetailsById(giftId).then(
      (response) => {
        setLoadingdata(true);
        setGiftCardDetails(response.data);
        const initValues = {
          cardType: response.data.cardType,
          supplier: response.data.GiftcardsSupplierId,
          total_amt: response.data.totalAmount,
          used_amt: response.data.usedAmount,
          expiry_date: moment(response.data.expiryDate).format("YYYY-MM-DD"),
          gift_card_doc: response.data.documentLink,
        };
        setFormValues(initValues);
        let docParams = response.data.documentLink.split("/");
        setShowGcFile(docParams[docParams.length - 1]);
        setUploadedDocUrl(response.data.documentLink);
        setIsSwitchOn(response.data.reminder);
        if (valueChk === "") {
          setSelectedSupplier({
            value: response.data.GiftcardsSupplier.id,
            label: response.data.GiftcardsSupplier.name,
          });
          setSelectedSender({
            value: response.data.cardType,
            label: response.data.cardType,
          });
        }
        setfiltervalue(response.data.cardType);
        setLoadingdata(false);
      },
      (error) => {}
    );

    // get supplier listing
    UserService.getGiftcardSuppliersList().then(
      (response) => {
        var sup_arr2 = response.result.map((v) => ({
          value: v.id,
          label: v.name,
        }));

        setSupplierOption(sup_arr2);
      },
      (error) => {}
    );

    // add gift card
    if (
      Object.keys(formErrors).length === 0 &&
      formValues.total_amt > 0 &&
      isSubmit
    ) {
      setLoading(true);

      if (
        formValues.used_amt == "" ||
        formValues.used_amt < 0 ||
        formValues.cardType == "Voucher"
      ) {
        formValues.used_amt = 0.0;
      }
      if (formValues.cardType == "") {
        formValues.cardType = "Voucher";
      }

      let data = {
        cardType: formValues.cardType,
        GiftcardsSupplierId: formValues.supplier,
        totalAmount: formValues.total_amt,
        usedAmount: formValues.used_amt,
        expiryDate: formValues.expiry_date,
        documentLink: uploadedDocUrl,
        reminder: isSwitchOn ? 1 : 0,
        updatedBy: userCreateUpdate,
        createdUpdatedFor: userCreateUpdateFor,
        FamilyId: familyDetails.FamilyData.FamilyId,
        FamilyUserId: userCreateUpdate,
      };

      // api call
      UserService.updateGiftcard(data, giftId).then(
        (response) => {
          setLoading(false);
          setShowToast(true);
          setSuccessToast(true);
          executeScroll();
          window.scrollTo(0, 0);
          // setToastMessage("Voucher & Gift Card updated successfully");
          setToastMessage(
            data.cardType === "Gift Card"
              ? "Gift Card updated successfully"
              : "Voucher updated successfully"
          );
          setTimeout(() => {
            history.push(giftcardsUrl);
            setFormValues(initialValues);
            // setShowGcFile("");
          }, 1000);
        },
        (error) => {}
      );

      // end api call

      setIsSwitchOn(true);
    }
    // else if (formValues.used_amt == 0 && formValues.used_amt != "") {
    //   setShowToast(true);
    //   setSuccessToast(false);
    //   executeScroll(); window.scrollTo(0, 0);
    //   setToastMessage("don't give used amount value 0");
    //   setLoading(false);
    // } else if (formValues.total_amt == 0 && formValues.total_amt != "") {
    //   setShowToast(true);
    //   setSuccessToast(false);
    //   executeScroll(); window.scrollTo(0, 0);
    //   setToastMessage("don't give total amount value 0");
    //   setLoading(false);
    // } else if (formValues.used_amt == "" || formValues.used_amt < 0) {
    //   setShowToast(true);
    //   setSuccessToast(false);
    //   executeScroll(); window.scrollTo(0, 0);
    //   setToastMessage("don't make used amount field blank");
    //   setLoading(false);
    // } else if (formValues.total_amt == "" || formValues.total_amt < 0) {
    //   setShowToast(true);
    //   setSuccessToast(false);
    //   executeScroll(); window.scrollTo(0, 0);
    //   setToastMessage("don't make total amount field blank");
    //   setLoading(false);
    // }
    else {
      setLoading(false);
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    // setIsSubmit(true);
    // setLoading(true);
    setValueChk("ValueChk");
    if (selectedSupplier.value === "" && formValues.total_amt === "") {
      setFormErrors({
        supplier: "supplier",
        total_amt: "total_amt",
      });
      setIsSubmit(false);
      setLoading(false);
    } else if (selectedSupplier.value != "" && formValues.total_amt === "") {
      setFormErrors({
        supplier: "",
        total_amt: "total_amt",
      });
      setIsSubmit(false);
      setLoading(false);
    } else if (selectedSupplier.value === "" && formValues.total_amt != "") {
      setFormErrors({
        supplier: "supplier",
        total_amt: "",
      });
      setIsSubmit(false);
      setLoading(false);
    } else {
      setIsSubmit(true);
      setLoading(true);
    }
  };

  const validate = (values) => {
    const errors = {};
    // if (!values.cardType) {
    //   errors.msg = "Please enter the sender name";
    //   errors.cardType = "Yes";
    // }
    if (!values.supplier) {
      errors.msg = "Please select the supplier";
      errors.supplier = "Yes";
    }
    if (!values.total_amt || values.total_amt == 0 || values.total_amt < 0) {
      errors.msg = "Please enter the total amount";
      errors.total_amt = "Yes";
    }
    if (!values.expiry_date) {
      errors.msg = "Please enter the expiry date";
      errors.expiry_date = "Yes";
    }
    if (values.total_amt) {
      if (
        parseInt(values.used_amt) > parseInt(values.total_amt)
        // values.used_amt < 0 ||
        // values.used_amt == 0
      ) {
        errors.used_amt_msg = "Please enter Used amount less then total amount";
        errors.used_amt = "Yes";
      }
    }
    return errors;
  };

  // file upload
  const uploadFile = (data) => {
    setBrowserErrMsg("");

    const formData = new FormData();
    formData.append("file", data, data.name.replace(/ /g, ""));

    UserService.uploadUserDocuments(formData).then(
      (response) => {
        setShowGcFile(data.name.substr(0, 65));
        setUploadedfile(data.name.substr(0, 65));
        setBrowserLoading(false);
        setBrowserMsg("Successfully uploaded");
        setUploadedDocUrl(response.link);
        setaddUpdate(false);
      },
      (error) => {
        setBrowserLoading(false);
        setBrowserErrMsg("Something went error");
        setaddUpdate(false);
      }
    );
  };

  const removeUploadedFile = () => {
    setShowGcFile("");
    setUploadedDocUrl("");
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <div className="page-content add-warranty-pg">
      <Container fluid ref={myRef}>
        {/* toast */}
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {/* <h3 className="title">
          {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}
        </h3> */}
        {/*  */}

        {Loadingdata ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <Row className="fixed-form-header">
                <span className="nav-back">
                  <Link to={giftcardsUrl}>
                    <p className="mb-3">
                      <img src={leftArrow} alt="arrow" className="mr-2" />
                      {userUUID
                        ? `Back to ${backText}’s Vouchers & Gift Cards`
                        : `${backText}`}
                    </p>
                  </Link>
                </span>
                <Col md="8" lg="8" className="mt-2">
                  <h3>
                    {/* Edit Voucher & Gift Card */}
                    {filtervalue === "Voucher" ? (
                      <span> Edit Voucher</span>
                    ) : (
                      <span>Edit Gift Card</span>
                    )}
                  </h3>
                  <p className="sub-title">Edit the below details</p>
                </Col>
                <Col md="4" lg="4" className="mt-2">
                  <span className="align-right px-5">
                    {/* {loading && (
                  <span className="mx-3 spinner-border spinner-border-sm"></span>
                )} */}
                    {/* <Primary
                  children="Update Gift Card"
                  size="mediumbtn"
                  variant="primary"
                  type=""
                  buttonWidth="220px"
                  font_Weight="500"
                  isLoading={loading ? "true" : "false"}
                  isDisabled={loading ? "true" : "false"}
                /> */}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col md="12" lg="12" className="fixed-form-content">
                  {/* row 1 */}
                  <Row>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Type</Form.Label>
                        <Select
                          styles={customStyles}
                          options={filterOption}
                          defaultValue={{
                            label: "Voucher",
                            value: "",
                          }}
                          value={selectedSender}
                          onChange={handleChange}
                          name="cardType"
                          //(event) => this.handleUserInput(event)
                        />
                        <Form.Text
                          className={formErrors.cardType ? "invalid-text" : " "}
                        >
                          Select Voucher/Gift Card Type
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Supplier</Form.Label>
                        <Select
                          styles={customStyles}
                          options={supplierOption}
                          defaultValue={{
                            label: "select a supplier",
                            value: "",
                          }}
                          value={selectedSupplier}
                          onChange={handleChange}
                          name="supplier"
                        />
                        <Form.Text
                          className={formErrors.supplier ? "invalid-text" : " "}
                        >
                          {/* Select the Voucher & Gift Card supplier name */}
                          {filtervalue === "Voucher" ? (
                            <span> Select the Voucher supplier name</span>
                          ) : (
                            <span>Select the Gift Card supplier name</span>
                          )}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Row 2 */}
                  <Row>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="">Total Amount</Form.Label>
                        <InputGroup>
                          <FormControl
                            type="number"
                            className=""
                            placeholder="£100.00"
                            size="medium"
                            name="total_amt"
                            onWheel={() => document.activeElement.blur()}
                            step="any"
                            // value={formValues.total_amt}
                            value={
                              formValues.total_amt < 0
                                ? ""
                                : formValues.total_amt == 0
                                ? formValues.total_amt
                                : formValues.total_amt == 0.0
                                ? formValues.total_amt
                                : formValues.total_amt > 0.0
                                ? formValues.total_amt
                                : formValues.total_amt
                            }
                            onChange={handleChange}
                          />
                          <InputGroup.Text className="pwd-icon">
                            <span className="pound-input">£</span>
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Text
                          className={
                            formErrors.total_amt ? "invalid-text" : " "
                          }
                        >
                          {/* Enter the total price value of the Voucher & Gift Card */}
                          {filtervalue === "Voucher" ? (
                            <span>
                              {" "}
                              Enter the total price value of the Voucher{" "}
                            </span>
                          ) : (
                            <span>
                              Enter the total price value of the Gift Card
                            </span>
                          )}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Used Amount ( optional )</Form.Label>
                        <InputGroup className="mb-0">
                          <FormControl
                            type="number"
                            className=""
                            placeholder="£100.00"
                            size="medium"
                            name="used_amt"
                            step="any"
                            readOnly={filtervalue === "Voucher" ? true : false}
                            onWheel={() => document.activeElement.blur()}
                            // value={formValues.used_amt}
                            // value={
                            //   formValues.used_amt < 0
                            //     ? ""
                            //     : formValues.used_amt == 0
                            //     ? formValues.used_amt
                            //     : formValues.used_amt == 0.0
                            //     ? formValues.used_amt
                            //     : formValues.used_amt > 0.0
                            //     ? formValues.used_amt
                            //     : formValues.used_amt
                            // }
                            value={
                              filtervalue === "Voucher"
                                ? "0.00"
                                : formValues.used_amt < 0
                                ? ""
                                : formValues.used_amt == 0
                                ? formValues.used_amt
                                : formValues.used_amt == 0.0
                                ? formValues.used_amt
                                : formValues.used_amt > 0.0
                                ? formValues.used_amt
                                : formValues.used_amt
                            }
                            onChange={handleChange}
                          />
                          <InputGroup.Text className="pwd-icon">
                            <span className="pound-input">£</span>
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Text
                          className={formErrors.used_amt ? "invalid-text" : " "}
                        >
                          {/* {formErrors.used_amt_msg
                        ? formErrors.used_amt_msg
                        : "Enter the used amount from the Voucher & Gift Card."} */}
                          {filtervalue === "Voucher" ? (
                            <span></span>
                          ) : formErrors.used_amt_msg ? (
                            formErrors.used_amt_msg
                          ) : (
                            "Enter the used amount from the Gift Card."
                          )}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* row 3 */}
                  <Row>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Expiry Date</Form.Label>
                        <Medium
                          type="date"
                          placeholder="Ex 20/10/2022"
                          size="medium"
                          name="expiry_date"
                          min={disablePastDate()}
                          value={formValues.expiry_date}
                          onChange={handleChange}
                          //(event) => this.handleUserInput(event)
                        />
                        <Form.Text
                          className={
                            formErrors.expiry_date ? "invalid-text" : " "
                          }
                        >
                          {/* Select the date on which your Voucher & Gift Card would
                      expire */}
                          {filtervalue === "Voucher" ? (
                            <span>
                              {" "}
                              Select the date on which your Voucher would expire{" "}
                            </span>
                          ) : (
                            <span>
                              Select the date on which your Gift Card would
                              expire
                            </span>
                          )}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* row 4 */}
                  <Row>
                    <Col md="6" lg="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="">
                          {/* Voucher & Gift Card Document ( Optional ) */}
                          {filtervalue === "Voucher" ? (
                            <span> Voucher Document ( Optional ) </span>
                          ) : (
                            <span>Gift Card Document ( Optional )</span>
                          )}
                          <span className="mx-3 align-right invalid-text">
                            {browserErrMsg ? browserErrMsg : ""}
                          </span>
                        </Form.Label>
                        <Medium
                          type="text"
                          placeholder=""
                          size="medium"
                          name="gc_doc"
                          readOnly
                        />
                        {showGcFile && (
                          <div className="doc-file">
                            <span className="icon">
                              <img src={docIcon} alt="Document" />
                            </span>
                            <span className="file">
                              {Uploadedfile === "" ? showGcFile : Uploadedfile}
                            </span>
                            <span
                              className="remove"
                              onClick={removeUploadedFile}
                            >
                              <img src={closeIcon} alt="Document" />
                            </span>
                          </div>
                        )}
                        <Form.Text className=" ">
                          {/* Upload the Voucher & Gift Card invoice in pdf or word
                      document format. ( Max size 2MB ) */}
                          {filtervalue === "Voucher" ? (
                            <span>
                              {" "}
                              Upload the Voucher invoice in pdf or word document
                              format. ( Max size 2MB ){" "}
                            </span>
                          ) : (
                            <span>
                              Upload the Gift Card invoice in pdf or word
                              document format. ( Max size 2MB )
                            </span>
                          )}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col
                      md="4"
                      lg="2"
                      className={
                        showGcFile
                          ? "disable-upload-invoice-btn"
                          : "upload-invoice-btn"
                      }
                    >
                      <label>
                        <input
                          type="file"
                          id="upload"
                          name="gift_card_doc"
                          onChange={handleChange}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                          accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                        Browse
                        {browserLoading && (
                          <span className="mx-3 spinner-border spinner-border-sm"></span>
                        )}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" lg="5">
                      <Form.Group className="my-3" controlId="formBasicEmail">
                        <Form.Switch
                          onChange={onSwitchAction}
                          id="custom-switch"
                          label=""
                          checked={isSwitchOn}
                          className="align-right sub-form-switch"
                        />
                        <p
                          className={
                            isSwitchOn
                              ? "reminder-switch-on"
                              : "reminder-switch-off"
                          }
                        >
                          Reminder on
                        </p>

                        <Form.Text>
                          {/* Standard reminder settings will be applied to this Voucher
                      & Gift Card. You can change the settings in the reminder
                      settings on your profile. */}
                          {filtervalue === "Voucher" ? (
                            <span>
                              {" "}
                              Standard reminder settings will be applied to this
                              Voucher. You can change the settings in the
                              reminder settings on your profile.{" "}
                            </span>
                          ) : (
                            <span>
                              Standard reminder settings will be applied to this
                              Giftcard. You can change the settings in the
                              reminder settings on your profile.
                            </span>
                          )}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={addUpdate ? "my-3 update-add-btn" : "my-3"}>
                <Primary
                  // children="Update Voucher & Gift Card"
                  children={
                    filtervalue === "Voucher" ? (
                      <span> Update Voucher </span>
                    ) : (
                      <span>Update Gift Card</span>
                    )
                  }
                  size="mediumbtn"
                  variant="primary"
                  type=""
                  buttonWidth="240px"
                  font_Weight="500"
                  isLoading={loading ? "true" : "false"}
                  isDisabled={loading ? "true" : "false"}
                />
              </Row>
            </Form>
          </>
        )}
      </Container>
    </div>
  );
};
export default EditGiftCard;
