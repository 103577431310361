import React from "react"
import SavedDebitCard from '../components/Cards/SavedDebitCard'

export default {
    title: 'Cards/SavedDebitCard',
    component: SavedDebitCard,
    args: {
        message: 'ActionAlert message',
       
    },
}

const Template = args => <SavedDebitCard {...args} />

export const SavedDebitCardBasic = Template.bind({})
SavedDebitCardBasic.args = {
    last4digit: '4242',
    userName: 'John Walker',
    exp_month: '05',
    exp_year: '2030',
    brand: 'visa',
    variant: "basic"
}