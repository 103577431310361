import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import LogoIcon from "../../assets/images/logo_icon.svg";
import SubscriptionsIcon from "../../assets/images/subscriptions.svg";
import WarantiesIcon from "../../assets/images/waranties.svg";
import BenefitsIcon from "../../assets/images/benefits.svg";
import CompareSaveIcon from "../../assets/images/compare-and-save.svg";
import GiftCardsIcon from "../../assets/images/gift_cards.svg";
import TasksIcon from "../../assets/images/tasks.svg";
import UserService from "../../services/user.service";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SideBar = (props) => {
  console.log(props);
  var splitUrl = window.location.href.split("/");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  console.log(splitUrl);
  var sidebarClass = props.isOpen ? "open" : "";
  let history = useHistory();
  const [showFamilyNav, setShowFamilyNav] = useState(false);
  let userType = localStorage.getItem("userType");
  const [familyName, setFamilyName] = useState("");
  var RedFamDash = localStorage.getItem("RedFamDash");
  const [familyDetails, setFamilyDetails] = useState({
    FamilyId: "",
    UserId: "",
    createdAt: "",
    id: "",
    inviteStatus: "",
    invitedBy: "",
    isAo: "",
    isAu: userType ? userType.includes("AU") : false,
    isSu: true,
    settings1: "",
    settings2: "",
    settings3: "",
    status: "",
    updatedAt: "",
  });
  const [isWarrantyActive, setIsWarrantyActive] = useState("");
  const [isCompareActive, setIsCompareActive] = useState("");
  const [isSubsActive, setIsSubsActive] = useState("");
  const [isGiftCardsActive, setIsGiftCardsActive] = useState("");
  const [isTasksActive, setIsTasksActive] = useState("");
  const [isBenefitsActive, setIsBenefitsActive] = useState("");

  let items = localStorage.getItem("UserDetails");
  let uuidDetails = localStorage.getItem("uuid");
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var familyLength = localStorage.getItem("familyAccLength");

  const [uservalue, setUserValue] = useState({});
  const [familyNameValue, setfamilyNameValue] = useState("");
  const [famLength, setfamLength] = useState();
  const [familyLengthVal, setfamilyLengthVal] = useState([]);

  useEffect(() => {
    UserService.getUserAccountPlan(UserDetails.uuid).then((resp) => {
      console.log(resp);
      let newData = [];
      for (let i = 0; i < resp.usersFamilyData.length; i++) {
        if (resp.usersFamilyData[i].Family.status !== "InActive") {
          newData.push(resp.usersFamilyData[i]);
        }
      }
      console.log(newData);
      setfamilyLengthVal(newData);
    });
    UserService.getUserSingleFamilyDetails(splitUrl[4], UserDetails.id).then(
      (response) => {
        console.log(response);
        if (response.FamilyData) {
          setUserValue(response.FamilyData);
          setfamilyNameValue(response.FamilyData.Family.name);
        }
      }
    );

    UserService.getUserAccountPlan(UserDetails.uuid).then((response) => {
      var newData = [];
      if (response.usersFamilyData) {
        for (var i = 0; i < response.usersFamilyData.length; i++) {
          if (response.usersFamilyData[i].status === "Active") {
            newData.push(response.usersFamilyData[i]);
          }
        }
      }
      newData.forEach((row, ind) => (row.id = ind));
      setfamLength(newData.length);
    });
  }, []);

  if (!items) {
    history.push("/");
  }
  let { uuid } = useParams();
  const [first, setfirst] = useState(true);
  var splitUrl = window.location.href.split("/");
  useEffect(() => {
    console.log(splitUrl);
    setfirst(true);
    setTimeout(() => {
      setfirst(false);
    }, 1000);
    if (uuid) {
      if (uuid.includes("elf-")) {
        setShowFamilyNav(true);
        // get family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            if (response.message && response.message === "Family not Found !") {
              window.location.href = "/404";
            } else if (response.FamilyData === null) {
              window.location.href = "/404";
            } else {
              console.log(response.FamilyData.Family.name);
              setFamilyDetails(response.FamilyData);
              setFamilyName(response.FamilyData.Family.name);
            }
          },
          (error) => {
            window.location.href = "/404";
          }
        );
      }
    }
    if (window.location.href.indexOf("warranties") > -1) {
      setIsWarrantyActive("warranties");
    } else if (window.location.href.indexOf("view_warranty_details") > -1) {
      setIsWarrantyActive("warranties");
    } else if (window.location.href.indexOf("add_warranty") > -1) {
      setIsWarrantyActive("warranties");
    } else if (window.location.href.indexOf("edit_warranty") > -1) {
      setIsWarrantyActive("warranties");
    }

    if (window.location.href.indexOf("discounts") > -1) {
      setIsBenefitsActive("discounts");
    }

    if (window.location.href.indexOf("compare_and_save") > -1) {
      setIsCompareActive("CompareAndSave");
    }

    if (window.location.href.indexOf("myoutgoings") > -1) {
      setIsSubsActive("myoutgoings");
    } else if (window.location.href.indexOf("view_outgoing_details") > -1) {
      setIsSubsActive("myoutgoings");
    } else if (window.location.href.indexOf("add_outgoing") > -1) {
      setIsSubsActive("myoutgoings");
    } else if (window.location.href.indexOf("edit_outgoing") > -1) {
      setIsSubsActive("myoutgoings");
    }
    // Gift cards
    if (window.location.href.indexOf("gift_cards") > -1) {
      setIsGiftCardsActive("GiftCards");
    }
    if (window.location.href.indexOf("add_gift_card") > -1) {
      setIsGiftCardsActive("GiftCards");
    }
    if (window.location.href.indexOf("edit_gift_card") > -1) {
      setIsGiftCardsActive("GiftCards");
    }
    if (window.location.href.indexOf("view_gift_card") > -1) {
      setIsGiftCardsActive("GiftCards");
    }
    // Task
    if (window.location.href.indexOf("todos") > -1) {
      setIsTasksActive("To Do's");
    } else if (window.location.href.indexOf("view_todos_details") > -1) {
      setIsTasksActive("To Do's");
    } else if (window.location.href.indexOf("add_todos") > -1) {
      setIsTasksActive("To Do's");
    }
  }, [UserDetails.id, showFamilyNav, uuid]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    console.log("ffffff");
  }, []);

  if (splitUrl[3] !== "confirmation" && splitUrl[3] !== "onboardUser") {
    return (
      <div className={`sidebar pt-3 ${sidebarClass}`}>
        {RedFamDash === "RedFamDash" && (
          <span className="p-3">
            {parentUserUUId === null ? (
              <Link to={`/family_dashboard/${splitUrl[4]}`}>
                <img src={LogoIcon} alt="hang logo" width="65px" />
              </Link>
            ) : (
              <Link
                to={`/supporting_user_dashboard/${splitUrl[4]}/${parentUserUUId}`}
              >
                <img src={LogoIcon} alt="hang logo" width="65px" />
              </Link>
            )}
          </span>
        )}

        {RedFamDash === null && familyLengthVal.length === 0 && (
          <span className="p-3">
            <Link to={`/subscription`}>
              <img src={LogoIcon} alt="hang logo" width="65px" />
            </Link>
          </span>
        )}
        {RedFamDash === null && familyLengthVal.length !== 0 && (
          <span className="p-3">
            <img src={LogoIcon} alt="hang logo" width="65px" />
          </span>
        )}
        {showFamilyNav ? (
          <div className="fmly-navbar">
            {/* {first ? (
              <div
                class="loader-sidebar d-flex"
                style={{ margin: "10rem auto" }}
              ></div>
            ) : ( */}
            <>
              <div className={`${sidebarClass}`}>
                <Link
                  className="d-flex align-items-center"
                  to={uuid ? `/family_dashboard/${uuid}` : "#"}
                >
                  <button
                    className="sidebar-bluebtn"
                    style={{ cursor: "pointer" }}
                  >
                    <span>
                      {familyNameValue && Capitalize(familyNameValue).charAt(0)}
                    </span>
                  </button>
                  {
                    <span className="list-item family-name">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {familyNameValue}
                          </Tooltip>
                        }
                      >
                        <strong>{familyNameValue}</strong>
                      </OverlayTrigger>
                    </span>
                  }
                </Link>
              </div>
              <ul className="mt-0">
                {((uservalue.isAo && uservalue.isAu && uservalue.isSu) ||
                  (uservalue.isAo && uservalue.isAu && !uservalue.isSu) ||
                  (!uservalue.isAo && uservalue.isAu && uservalue.isSu) ||
                  (!uservalue.isAo && uservalue.isAu && !uservalue.isSu) ||
                  splitUrl[3] === "supporting_user_dashboard" ||
                  parentUserUUId) && (
                  <>
                    <li>
                      {!parentUserUUId ? (
                        <Link to={uuid ? `/myoutgoings/${uuid}` : "#"}>
                          <img
                            src={
                              // isSubsActive ? ActiveSubscriptionsIcon :
                              SubscriptionsIcon
                            }
                            alt="arrow"
                            className={isSubsActive ? "newImg" : ""}
                          />
                          {
                            <span className="list-item">
                              {isSubsActive ? <b>Outgoings</b> : "Outgoings"}
                            </span>
                          }
                        </Link>
                      ) : (
                        <Link
                          to={
                            uuid
                              ? `/user_myoutgoings/${uuid}/${parentUserUUId}`
                              : "#"
                          }
                        >
                          <img
                            src={
                              // isSubsActive ? ActiveSubscriptionsIcon :
                              SubscriptionsIcon
                            }
                            alt="arrow"
                            className={isSubsActive ? "newImg" : ""}
                          />
                          {
                            <span className="list-item">
                              {isSubsActive ? <b>Outgoings</b> : "Outgoings"}
                            </span>
                          }
                        </Link>
                      )}
                    </li>
                    <li>
                      {!parentUserUUId ? (
                        <Link to={uuid ? `/warranties/${uuid}` : "#"}>
                          <img
                            src={WarantiesIcon}
                            alt="arrow"
                            className={isWarrantyActive ? "newImg" : ""}
                          />
                          {
                            <span className="list-item">
                              {isWarrantyActive ? (
                                <b>Warranties</b>
                              ) : (
                                "Warranties"
                              )}
                            </span>
                          }
                        </Link>
                      ) : (
                        <Link
                          to={
                            uuid
                              ? `/user_warranties/${uuid}/${parentUserUUId}`
                              : "#"
                          }
                        >
                          <img
                            src={WarantiesIcon}
                            alt="arrow"
                            className={isWarrantyActive ? "newImg" : ""}
                          />
                          {
                            <span className="list-item">
                              {isWarrantyActive ? (
                                <b>Warranties</b>
                              ) : (
                                "Warranties"
                              )}
                            </span>
                          }
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to={uuid ? `/discounts/${uuid}` : "#"}>
                        <img
                          src={BenefitsIcon}
                          alt="arrow"
                          className={isBenefitsActive ? "newImg" : ""}
                        />
                        {
                          <span className="list-item">
                            {isBenefitsActive ? <b>Discounts</b> : "Discounts"}
                          </span>
                        }
                      </Link>
                    </li>

                    <li>
                      {!parentUserUUId ? (
                        <Link
                          to={uuid ? `/vouchers_&_gift_cards/${uuid}` : "#"}
                        >
                          <div className="sidebar-sizing-VouGift">
                            <img
                              src={GiftCardsIcon}
                              alt="arrow"
                              className={isGiftCardsActive ? "newImg" : ""}
                            />

                            <div>
                              {
                                <span
                                  className="list-item"
                                  style={{
                                    position: "absolute",
                                    "margin-top": "0",
                                  }}
                                >
                                  {isGiftCardsActive ? (
                                    <b>Vouchers & Gift cards</b>
                                  ) : (
                                    "Vouchers & Gift cards"
                                  )}
                                </span>
                              }
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <Link
                          to={
                            uuid
                              ? `/user_vouchers_&_gift_cards/${uuid}/${parentUserUUId}`
                              : "#"
                          }
                        >
                          <div className="sidebar-sizing-VouGift">
                            <img
                              src={GiftCardsIcon}
                              alt="arrow"
                              className={isGiftCardsActive ? "newImg" : ""}
                            />

                            <div>
                              {
                                <span
                                  className="list-item"
                                  style={{
                                    position: "absolute",
                                    "margin-top": "0",
                                  }}
                                >
                                  {isGiftCardsActive ? (
                                    <b>Vouchers & Gift cards</b>
                                  ) : (
                                    "Vouchers & Gift cards"
                                  )}
                                </span>
                              }
                            </div>
                          </div>
                        </Link>
                      )}
                    </li>

                    <li>
                      {!parentUserUUId ? (
                        <Link to={uuid ? `/todos/${uuid}` : "#"}>
                          <img
                            src={TasksIcon}
                            alt="arrow"
                            className={isTasksActive ? "newImg" : ""}
                          />
                          {
                            <span className="list-item">
                              {isTasksActive ? <b>To Do's</b> : "To Do's"}
                            </span>
                          }
                        </Link>
                      ) : (
                        <Link
                          to={
                            uuid ? `/user_todos/${uuid}/${parentUserUUId}` : "#"
                          }
                        >
                          <img
                            src={TasksIcon}
                            alt="arrow"
                            className={isTasksActive ? "newImg" : ""}
                          />
                          {
                            <span className="list-item">
                              {isTasksActive ? <b>To Do's</b> : "To Do's"}
                            </span>
                          }
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to={uuid ? `/compare_and_save/${uuid}` : "#"}>
                        <div className="compare-save">
                          <img
                            src={CompareSaveIcon}
                            alt="arrow"
                            className={isCompareActive ? "newImg" : ""}
                          />
                          {
                            <span className="list-item">
                              {isCompareActive ? (
                                <b>Compare & Save</b>
                              ) : (
                                "Compare & Save"
                              )}
                            </span>
                          }
                        </div>
                      </Link>
                    </li>
                  </>
                )}
                {((uservalue.isAo && !uservalue.isAu && !uservalue.isSu) ||
                  (!uservalue.isAo && !uservalue.isAu && uservalue.isSu) ||
                  (uservalue.isAo && !uservalue.isAu && uservalue.isSu)) &&
                  splitUrl[3] !== "supporting_user_dashboard" &&
                  !parentUserUUId && (
                    <>
                      <li>
                        <Link to={uuid ? `/discounts/${uuid}` : "#"}>
                          <img
                            src={BenefitsIcon}
                            alt="arrow"
                            className={isBenefitsActive ? "newImg" : ""}
                          />
                          {
                            <span className="list-item">
                              {isBenefitsActive ? (
                                <b>Discounts</b>
                              ) : (
                                "Discounts"
                              )}
                            </span>
                          }
                        </Link>
                      </li>
                      <li>
                        <Link to={uuid ? `/compare_and_save/${uuid}` : "#"}>
                          <div className="compare-save">
                            <img
                              src={CompareSaveIcon}
                              alt="arrow"
                              className={isCompareActive ? "newImg" : ""}
                            />
                            {
                              <span className="list-item">
                                {isCompareActive ? (
                                  <b>Compare & Save</b>
                                ) : (
                                  "Compare & Save"
                                )}
                              </span>
                            }
                          </div>
                        </Link>
                      </li>
                    </>
                  )}
              </ul>
            </>
            {/* )} */}
          </div>
        ) : (
          ""
        )}
        {parentUserUUId && (
          <span className="p-3">
            <Link to={`/family_dashboard/${uuidDetails}`}>
              <div className="switch-family">Main Dashboard</div>
            </Link>
          </span>
        )}
        {splitUrl[3] !== "dashboard" ? (
          RedFamDash === "RedFamDash" &&
          familyLengthVal.length > 1 && (
            <span className="p-3">
              <Link to={`/dashboard`}>
                <div className="switch-family-1">Switch Group</div>
              </Link>
            </span>
          )
        ) : (
          <span></span>
        )}
      </div>
    );
  } else {
    return "";
  }
};

export default SideBar;
