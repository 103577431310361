import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import { Primary } from "../../stories/Button.stories";
import { Info } from "../../stories/Button.stories";
import { SeatOccupied } from "../../stories/SeatCard.stories";
import leftArrow from "../../assets/images/left.svg";
import Select from "react-select";
import moment from "moment";
import layer from "../../assets/images/layer1.svg";
import { Tertiary } from "../../stories/Button.stories";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";

const InviteHistorySu = (props) => {
  var contentClass = props.isOpen ? "content open" : "content";
  let history = useHistory();
  const myRef = useRef(null);
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [familyDetails, setFamilyDetails] = useState([]);
  const [familyUsersList, setFamilyUsersList] = useState([]);
  const [totalSuCount, setTotalSuCount] = useState(0);
  const [totalAuCount, setTotalAuCount] = useState(0);
  // Get login user details from localStorage
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // remove user
  const [loading, setLoading] = useState(false);
  const [showRemoveAuUser, setShowRemoveAuUser] = useState(false);
  const [auInviteName, setAuInviteName] = useState("");
  const [auInviteEmail, setAuInviteEmail] = useState("");
  const [auInviteId, setAuInviteId] = useState("");
  const [auInviteImage, setAuInviteImage] = useState("");
  const handleShowRemoveAuUserClose = () => setShowRemoveAuUser(false);
  const handleShowRemoveAuUserShow = (userList) => {
    setShowRemoveAuUser(true);
    setAuInviteName(userList.User.firstName + " " + userList.User.lastName);
    setAuInviteEmail(userList.User.email);
    setAuInviteImage(userList.User.avatar);
    setAuInviteId(userList.id);
  };
  // resend invite
  const [showResendAuUser, setShowResendAuUser] = useState(false);
  const handleShowResendAuUserClose = () => setShowResendAuUser(false);
  const handleShowResendAuUserShow = (userList) => {
    setShowResendAuUser(true);
    setAuInviteName(userList.User.firstName + " " + userList.User.lastName);
    setAuInviteEmail(userList.User.email);
    setAuInviteImage(userList.User.avatar);
    setAuInviteId(userList.id);
  };
  const handleCancelAuResendInvite = (inviteId) => {
    setShowResendAuUser(false);

    let updateData = {
      inviteStatus: "Acceptance Pending",
    };
    // revoke family invite
    UserService.resendFamilyInvite(updateData, inviteId).then(
      (response) => {
        if (response.status === 400) {
          //executeScroll();
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(response.data.error);
        } else {
          //executeScroll();
          setSuccessToast(true);
          setShowToast(true);
          setToastMessage("Invitation has been resend successfully");

          getFamilyInviteHistoryList(uuid, UserDetails.id, "");
        }
      },
      (error) => {
        // setLoading(false);
        setLoadingspinner(false);
      }
    );
  };
  var userType = localStorage.getItem("userType");
  const filterOption = [
    { value: "", label: "All" },
    { value: "Acceptance Pending", label: "Acceptance Pending" },
    { value: "Onboarded", label: "Onboarded" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Removed", label: "Removed" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
      height: "3.75rem",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };
  let { uuid } = useParams();
  const handleCancelAuInvite = (inviteId) => {
    setShowRemoveAuUser(false);
    if (userType === "AU" || userType === "AU + SU") {
      let updateData = {
        //status: "Removed",
        fuuid: uuid,
        id: inviteId,
      };
      // revoke family invite
      //UserService.updateFamilyInviteStatus(inviteId, updateData).then(
      UserService.revokeFamilyInviteStatusForPU(updateData).then(
        (response) => {
          getFamilyInviteHistoryList(uuid, UserDetails.id, "");
          setSuccessToast(true);
          setShowToast(true);

          setToastMessage(response.message);
        },
        (error) => {
          // setLoading(false);
          setLoadingspinner(false);
        }
      );
    } else {
      let updateData = {
        //status: "Removed",
        fuuid: uuid,
        id: inviteId,
        pu: false,
      };
      // revoke family invite
      //UserService.updateFamilyInviteStatus(inviteId, updateData).then(
      UserService.revokeFamilyInviteStatus(updateData).then(
        (response) => {
          getFamilyInviteHistoryList(uuid, UserDetails.id, "");
          setSuccessToast(true);
          setShowToast(true);

          setToastMessage(response.message);
        },
        (error) => {
          // setLoading(false);
          setLoadingspinner(false);
        }
      );
    }
  };
  // Filter subscriptions
  const [filVal, setFilVal] = useState("");
  const taskFilter = (e) => {
    let filterVal = e.value;
    setFilVal(filterVal);

    setLoading(true);
    // setLoadingspinner(true);
    getFamilyInviteHistoryList(uuid, UserDetails.id, filterVal);
    // UserService.getFamilyInviteList(uuid,filterVal).then((res1) => {
    //
    //   setFamilyUsersList(res1.result.rows);
    //   if (res1.result.rows) {
    //     const SuCount = res1.result.rows.reduce(
    //       (counter, obj) => (obj.isSu == true ? (counter += 1) : counter),
    //       0
    //     );
    //
    //     setTotalSuCount(SuCount);
    //     const AuCount = res1.result.rows.reduce(
    //       (counter, obj) => (obj.isAu == true ? (counter += 1) : counter),
    //       0
    //     );
    //
    //     setTotalAuCount(AuCount);
    //   }
    // });
  };
  useEffect(() => {
    // get family details
    getFamilyInviteHistoryList(uuid, UserDetails.id, "");
  }, []);
  const getFamilyInviteHistoryList = (uuid, userid, filterData) => {
    UserService.getUserSingleFamilyDetails(uuid, userid).then(
      (response) => {
        setLoading(true);
        // setLoadingspinner(true);

        setFamilyDetails(response);
        UserService.getInviteHistoryOfSU(
          uuid,
          response.FamilyData.id,
          filterData
        ).then((res1) => {
          setLoading(false);
          setLoadingspinner(false);
          setFamilyUsersList(res1.result);
          // if (res1.result.length != 0) {
          //
          //   setFamilyUsersList(res1.result);
          // }
          if (res1.result) {
            const SuCount = res1.result.reduce(
              (counter, obj) => (obj.isSu == true ? (counter += 1) : counter),
              0
            );

            setTotalSuCount(SuCount);
          }
        });
      },
      (error) => {}
    );
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  var uuidDetails = localStorage.getItem("uuid");
  const dashboardRed = () => {
    history.push({
      pathname: `/family_dashboard/${uuidDetails}`,
    });
  };
  return (
    <div className="page-content invite-history warranty-pg tab-btn">
      <Container fluid>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {/* <h3 className="title">
          {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}{" "}
        </h3> */}
        {/* <span className="nav-back">
          <Link to={`/family_dashboard/${uuid}`}>
            <p className="mb-3">
              <img src={leftArrow} alt="arrow" className="mr-2" />
              Back to Group Dashboard
            </p>
          </Link>
        </span> */}
        {loadingspinner ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            <div className="Reminder-backBtn" onClick={() => dashboardRed()}>
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              <span>Back to Group Dashboard</span>
            </div>
            <Row>
              <Col md="8" lg="9">
                <h3>Support Users Invite History</h3>
                <p className="sub-title">
                  You can view the invite history of all the users in your
                  family on Elderly Life Platform
                </p>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-between">
                  <div className="filter-label pl-2 border-radius-rignt-none">
                    Filter by :
                  </div>
                  <div className="p-0 filter-select flex-1">
                    <Select
                      styles={customStyles}
                      options={filterOption}
                      defaultValue={{ label: "All", value: "" }}
                      onChange={taskFilter}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Col md="12" lg="12"></Col>

            {loading ? (
              <>
                <div className="loadind-page-table">
                  <Loading />
                </div>
              </>
            ) : (
              <>
                {familyUsersList.length != 0 &&
                  familyUsersList.map((userList, index) => (
                    <Card className="mb-3 card-list" key={index}>
                      <Card.Body>
                        <Row>
                          <Col md="3" lg="3">
                            <small className="mb-0">Email ID</small>
                            <p className="mb-0 tab-txt">
                              {userList.User.email}
                            </p>
                          </Col>
                          <Col md="3" lg="3">
                            <small className="mb-0">Invited on</small>
                            <p className="mb-0 tab-txt">
                              {moment(userList.createdAt).format(
                                "MMM Do, YYYY"
                              )}
                            </p>
                          </Col>
                          <Col md="2" lg="2">
                            <small className="mb-0">Status</small>
                            <p className="mb-0 tab-txt status">
                              {userList.inviteStatus}
                            </p>
                          </Col>
                          <Col md="2" lg="2">
                            {userList.inviteStatus == "Acceptance Pending" ? (
                              // <span className="list-btn-revoke my-1">
                              //   <p className="mb-3">Revoke Invite</p>
                              // </span>
                              <Tertiary
                                children="Revoke Invite"
                                size="smallbtn"
                                variant="tertiary"
                                type=""
                                style={{ margin: "5px 15px 0 0" }}
                                onClick={() =>
                                  handleShowRemoveAuUserShow(userList)
                                }
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md="2" lg="2">
                            {userList.inviteStatus == "Acceptance Pending" ||
                            userList.inviteStatus == "Cancelled" ? (
                              // <span className="list-btn-removed my-1">
                              //   <p className="mb-3">Resend Invite</p>
                              // </span>
                              <Tertiary
                                children="Resend Invite"
                                size="smallbtn"
                                variant="tertiary"
                                type=""
                                style={{ margin: "5px 15px 0 0" }}
                                onClick={() =>
                                  handleShowResendAuUserShow(userList)
                                }
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
                {totalSuCount == 0 ? (
                  <Row>
                    <Col
                      md="12"
                      lg="12"
                      className="mt-5 pt-5 small-text text-center"
                    >
                      No Support user found
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>
            )}

            {/* Remove account user */}
            <Modal
              show={showRemoveAuUser}
              onHide={handleShowRemoveAuUserClose}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mx-1">
                  <h3 className="mb-0">Revoke Support User</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mx-1">
                <SeatOccupied
                  rightImg=""
                  leftImg=""
                  isEmpty="true"
                  ProfileAvatar={auInviteImage}
                  memberHeading={auInviteName}
                  memberSubheading={auInviteEmail}
                />
                <p className="my-3 subtext">
                  You are removing the above user from your Family.
                </p>
                <p className="mb-4 subtext">
                  <strong>
                    Once a user is removed, they cannot access their details on
                    Elderly Life.
                  </strong>
                </p>
                <p>Do you want to continue?</p>
                <div className="mb-2">
                  <span>
                    <Info
                      children="Cancel"
                      variant="info"
                      size="mediumbtn"
                      type=""
                      buttonWidth="120px"
                      onClick={handleShowRemoveAuUserClose}
                    />
                  </span>
                  <span className="ml-4">
                    <Primary
                      children="Yes"
                      variant="primary"
                      size="mediumbtn"
                      type=""
                      buttonWidth="120px"
                      onClick={() => handleCancelAuInvite(auInviteId)}
                    />
                  </span>
                </div>
              </Modal.Body>
            </Modal>
            {/* Resnd invite to account user */}
            <Modal
              show={showResendAuUser}
              onHide={handleShowResendAuUserClose}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mx-1">
                  <h3 className="mb-0">Resend Invite to User</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mx-1">
                <SeatOccupied
                  rightImg=""
                  leftImg=""
                  isEmpty="true"
                  ProfileAvatar={auInviteImage}
                  memberHeading={auInviteName}
                  memberSubheading={auInviteEmail}
                />
                <p className="my-3 subtext">
                  You are resending the invite to above user from your Family.
                </p>
                <p className="mb-4 subtext">
                  {/* <strong>
                Once a user is removed, they cannot access their details on
                Elderly Life.
              </strong> */}
                </p>
                <p>Do you want to continue?</p>
                <div className="mb-2">
                  <span>
                    <Info
                      children="Cancel"
                      variant="info"
                      size="mediumbtn"
                      type=""
                      buttonWidth="120px"
                      onClick={handleShowResendAuUserClose}
                    />
                  </span>
                  <span className="ml-4">
                    <Primary
                      children="Yes"
                      variant="primary"
                      size="mediumbtn"
                      type=""
                      buttonWidth="120px"
                      onClick={() => handleCancelAuResendInvite(auInviteId)}
                    />
                  </span>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </div>
  );
};
export default InviteHistorySu;
