import React from 'react'
import './Input.scss'

function Input (props) {
    const { size = 'medium',errorClass, ...rest } = props
    return (
        <input className={`input ${size} ${errorClass}`} {...rest} />
    )
}

export default Input