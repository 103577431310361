import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Primary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import DocIcon from "../../assets/images/doc_icon.png";
import moment from "moment";
import FileViewer from "react-file-viewer";
import PDFViewer from "pdf-viewer-reactjs";
import Modal from "react-bootstrap/Modal";
import Loading from "../../common/Loading";

const ViewGiftCardsDetails = (props) => {
  var idValue = localStorage.getItem("id");
  var PrimaryIdval = localStorage.getItem("PrimaryIdval");
  var contentClass = props.isOpen ? "content open" : "content";
  const [familyDetails, setFamilyDetails] = useState([]);
  const [giftCardDetails, setGiftCardDetails] = useState([]);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [isEmpty, setIsEmpty] = useState(false);
  const [giftcardsUrl, setGiftcardsUrl] = useState("");
  const [giftcardEditUrl, setGiftcardEditUrl] = useState("");
  const [backText, setBackText] = useState("Back to My Vouchers & Gift Cards");
  let { uuid, giftId, userUUID } = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showDocSub, setShowDocSub] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [isPdf, setIsPdf] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const [iframeLink, setIframeLink] = useState("");
  const [loader, setLoader] = useState(true);
  let history = useHistory();

  useEffect(() => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
      },
      (error) => {}
    );

    if (userUUID) {
      setGiftcardsUrl("/user_vouchers_&_gift_cards/" + uuid + "/" + userUUID);
      UserService.userProfile(userUUID).then((response) => {
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      setGiftcardsUrl("/vouchers_&_gift_cards/" + uuid);
    }

    // get single subscription details by ID
    UserService.getGiftcardDetailsById(giftId).then(
      (response) => {
        setGiftCardDetails(response.data);
        if (response.data) {
          setIsSwitchOn(response.data.reminder);
          if (userUUID) {
            setGiftcardEditUrl(
              "/edit_user_vouchers_&_gift_cards/" +
                uuid +
                "/" +
                response.data.id +
                "/" +
                userUUID
            );
          } else {
            setGiftcardEditUrl(
              "/edit_vouchers_&_gift_cards/" + uuid + "/" + response.data.id
            );
          }
        } else {
          setGiftcardEditUrl("#");
        }
        setLoader(false);
      },
      (error) => {}
    );
  }, []);

  // show doc model
  const handleShowDocSubShow = (docUrl) => {
    setShowDocSub(true);
    const file_ext = get_url_extension(docUrl);
    setDocLink(docUrl);
    if (file_ext === "pdf") {
      setIsPdf(true);
      setIsImage(false);
      setIsDocument(false);
    } else if (
      file_ext === "png" ||
      file_ext === "jpg" ||
      file_ext === "jpeg"
    ) {
      setIsPdf(false);
      setIsImage(true);
      setIsDocument(false);
    } else if (file_ext === "doc" || file_ext === "docx") {
      setIsPdf(false);
      setIsImage(false);
      setIsDocument(true);
      const docs = [{ uri: docUrl }];
      setIframeLink(docs);
    }
  };

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };
  return (
    <div className="page-content view-warranty-pg">
      <Container fluid>
        {/* <h3 className="title">
          {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}
        </h3> */}

        {loader ? (
          <div style={{ "margin-top": "12.4rem" }}>
            <Loading />
          </div>
        ) : (
          <>
            <span className="nav-back">
              <p className="mb-3">
                <Link to={giftcardsUrl}>
                  <img src={leftArrow} alt="arrow" className="mr-2" />
                  {userUUID ? `Back to ${backText}’s GiftCards` : `${backText}`}
                </Link>
              </p>
            </span>
            <Row className="mt-4">
              <Col md="8" lg="8">
                <h3 className="mt-3">
                  {giftCardDetails.GiftcardsSupplier
                    ? giftCardDetails.GiftcardsSupplier.name
                    : ""}
                </h3>
                <p className="sub-title">
                  {/* Vouchers & Gift Cards */}
                  {giftCardDetails.cardType === "Voucher" ? (
                    <span>Voucher </span>
                  ) : (
                    <span>Gift Card </span>
                  )}
                </p>
              </Col>
              {
                <Col md="4" lg="4">
                  <span className="align-right px-5">
                    <Link to={giftcardEditUrl} className="">
                      <Primary
                        // children="Edit Voucher & Gift Card"
                        children={
                          giftCardDetails.cardType === "Voucher" ? (
                            <span>Edit Voucher</span>
                          ) : (
                            <span>Edit Gift Card</span>
                          )
                        }
                        size="mediumbtn"
                        variant="primary"
                        type=""
                        buttonWidth="220px"
                        font_Weight="500"
                      />
                    </Link>
                  </span>
                </Col>
              }
            </Row>
            <Row className="mt-4 details-content">
              <Col md="4" lg="4">
                <h4>
                  {/* Voucher & Gift Card details */}
                  {giftCardDetails.cardType === "Voucher" ? (
                    <span>Voucher details</span>
                  ) : (
                    <span>Gift Card details</span>
                  )}
                </h4>
                <small>Type</small>
                <p className="view-txt">
                  {giftCardDetails.cardType ? giftCardDetails.cardType : "-"}
                </p>
                <small>Supplier Name</small>
                <p className="view-txt">
                  {giftCardDetails.GiftcardsSupplier
                    ? giftCardDetails.GiftcardsSupplier.name
                    : ""}
                </p>
                <small>
                  {/* Voucher & Gift Card Document */}
                  {giftCardDetails.cardType === "Voucher" ? (
                    <span>Voucher Document</span>
                  ) : (
                    <span>Gift Card Document</span>
                  )}
                </small>
                {giftCardDetails.documentLink && (
                  // <Link
                  //   to={{
                  //     pathname: giftCardDetails.documentLink
                  //       ? giftCardDetails.documentLink
                  //       : "#",
                  //   }}
                  // target="_blank"
                  // >
                  <p
                    className="mb-3 view-invoice-txt w-50"
                    onClick={() =>
                      handleShowDocSubShow(giftCardDetails.documentLink)
                    }
                  >
                    <img
                      src={DocIcon}
                      alt="document icon"
                      className="mx-2"
                      width="5%"
                    />
                    Document
                  </p>
                  // </Link>
                )}
                {!giftCardDetails.documentLink && (
                  <p className="view-txt">No document</p>
                )}
                <small>Reminder on</small>
                <p className="view-txt">
                  {giftCardDetails.reminder ? "Yes" : "No"}
                </p>
              </Col>
              <Col md="3" lg="3">
                <h4 className="my-4"></h4>
                <small>Total Amount</small>
                <p className="view-txt">£ {giftCardDetails.totalAmount}</p>
                <small>Used Amount</small>
                <p className="view-txt">
                  {giftCardDetails.cardType === "Voucher"
                    ? "--"
                    : "£ " + giftCardDetails.usedAmount}
                </p>
                <small>Remaining Amount</small>
                <p className="view-txt">
                  {/* {giftCardDetails.usedAmount
      ? "£ " +
        parseFloat(
          giftCardDetails.totalAmount - giftCardDetails.usedAmount
        ).toFixed(2)
      : giftCardDetails.totalAmount} */}
                  {giftCardDetails.senderName === "Voucher"
                    ? "£" + parseFloat(giftCardDetails.totalAmount).toFixed(2)
                    : "£" +
                      parseFloat(
                        giftCardDetails.totalAmount - giftCardDetails.usedAmount
                      ).toFixed(2)}
                </p>
                <small>Expiry Date</small>
                <p className="view-txt">
                  {moment(giftCardDetails.expiryDate).format("MMM Do, YYYY")}
                </p>
              </Col>

              {/* View document model */}
              <Modal
                show={showDocSub}
                onHide={handleShowDocSubClose}
                className="invite_au_modal doc_modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title className="mx-1">
                    <h3 className="mb-0">View Document</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-1">
                  {isPdf ? (
                    <PDFViewer
                      document={{
                        url: docLink,
                      }}
                    />
                  ) : (
                    // <>
                    //   <Document file={docLink} onLoadSuccess={onDocumentLoadSuccess}>
                    //     <Page pageNumber={pageNumber} />
                    //   </Document>
                    //   <p>
                    //     Page {pageNumber} of {numPages}
                    //   </p>
                    // </>
                    ""
                  )}
                  {isImage ? (
                    <img
                      src={docLink}
                      alt="Contract Document"
                      className="image-document-size"
                    />
                  ) : (
                    ""
                  )}
                  {isDocument ? (
                    <FileViewer fileType="docx" filePath={docLink} />
                  ) : (
                    ""
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Link
                    to={{
                      pathname: docLink,
                    }}
                    target="_blank"
                  >
                    Download
                  </Link>
                </Modal.Footer>
              </Modal>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};
export default ViewGiftCardsDetails;
