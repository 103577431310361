import React from 'react'
import { Card } from "react-bootstrap";
import './Cards.scss'

function SavedDebitCard (props) {
    const { last4digit,userName,exp_month,exp_year,brand, variant,inputValue,buttonClick, ...rest } = props
    return (
        <div>
            <Card className={`story-card debit-card ${variant}-cards`}>
              <Card.Body className="p-1">                  
                
                <label>
                    <span className="float-left"><input type="radio" name="paymentMethod_id"  {...rest} className="card-input-element" /></span>
                    <span className="float-right">{brand}</span>
                    <span className="panel panel-default card-input">
                        <p className="panel-heading card-right">**** **** **** {last4digit}</p>
                        <p className="panel-body card-right">{userName}</p>
                        <p className="panel-body card-right">Expires on {exp_month}/{exp_year}</p>
                    </span>
                </label>                    
               
              </Card.Body>
            </Card>           
        </div>
    )
}
export default SavedDebitCard