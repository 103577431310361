// common toast messages block
import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import SuccessCheck from "../assets/images/successCheck.svg";
import ErrorCheck from "../assets/images/errorCheck.svg";

const ToastBlockContainer = ({
  successToast,
  showToast,
  setShowToast,
  toastMessage,
}) => {
  const myRef = useRef(null);
  return (
    <div className={successToast ? "toast-div" : "toast-div error"} ref={myRef}>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Body>
          <Row>
            <Col md="2">
              <img
                src={successToast ? SuccessCheck : ErrorCheck}
                className="rounded me-2"
                alt=""
              />
            </Col>
            <Col md="10" className="mt-2" style={{ "padding-left": "1.5rem" }}>
              {toastMessage}
            </Col>
          </Row>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ToastBlockContainer;
