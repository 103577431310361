import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import moment from "moment";
import { Large } from "../../stories/Button.stories";
import { Link, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import { FamilyList } from "../../stories/FamilyListCard.stories";
import CheckGreen from "../../assets/images/check-green.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";

const Dashboard = (props) => {
  localStorage.removeItem("parentUser.uuid");
  localStorage.removeItem("userType");
  localStorage.removeItem("RedFamDash");
  let history = useHistory();
  var initialFamilyList = props.location.familyList
    ? props.location.familyList
    : [];
  const [familyList, setFamilyList] = useState(initialFamilyList);
  var initialFamilyInviteList = props.location.inviteList
    ? props.location.inviteList
    : [];
  const [, setInviteList] = useState(initialFamilyInviteList);

  const [loading, setLoading] = useState(false);
  const [familyDashLink] = useState("");
  // Get login user details from localStorage
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // invite acceptance model
  const [showInviteModel, setShowInviteModel] = useState(false);
  const [successToast] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage] = useState("");
  const [, setAccountPlanList] = useState([]);

  const handleShowInviteModelClose = () => setShowInviteModel(false);
  useEffect(() => {
    // api call
    setLoading(true);
    UserService.getUserFamily(UserDetails.uuid).then(
      (response) => {
        let newArr = [];
        for (let i = 0; i < response.usersFamilyData.length; i++) {
          if (response.usersFamilyData[i].status === "Active") {
            newArr.push(response.usersFamilyData[i]);
          }
        }
        setFamilyList(newArr);
        console.log(newArr);
      },
      (error) => {
        setLoading(false);
      }
    );
    // invite list
    UserService.getUserFamilyInvite(UserDetails.uuid).then(
      (response) => {
        setInviteList(response.InviteData);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    // api call
    setLoading(true);
    UserService.getUserAccountPlan(UserDetails.uuid).then(
      (response) => {
        var newData = [];
        for (var i = 0; i < response.usersFamilyData.length; i++) {
          if (response.usersFamilyData[i].Family.status !== "InActive") {
            newData.push(response.usersFamilyData[i]);
          }
        }
        // newData.forEach((row, ind) => (row.id = ind));
        setAccountPlanList(newData);
        if (newData.length === 0) {
          history.push(`/subscription`);
        }
        localStorage.setItem("familyAccLength", newData.length);
        // setAccountPlanList(response.usersFamilyData);

        setTimeout(() => {
          setLoading(false);
        }, 250);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const manageSubscriptionRoute = (fuuid) => {
    history.push(`/manageSubscription/${fuuid}`);
  };
  const manageDashboardRoute = (fuuid) => {
    history.push(`/family_dashboard/${fuuid}`);
    localStorage.setItem("userIDVal", fuuid);
  };

  const Capitalize = (str) => {
    console.log(str);
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderRole = (param) => {
    if (param.isAo && param.isAu && param.isSu) {
      return "Admin + Main user + Support user";
    } else if (param.isAo && param.isAu) {
      return "Admin + Main user";
    } else if (param.isAo && param.isSu) {
      return "Admin + Support user";
    } else if (param.isAu && param.isSu) {
      return "Main user + Support user";
    } else if (param.isAo) {
      return "Admin";
    } else if (param.isAu) {
      return "Main user";
    } else if (param.isSu) {
      return "Support user";
    }
  };

  const renderRole1 = (param) => {
    if (param.isAo && param.isAu && param.isSu) {
      return "AO + AU + SU";
    } else if (param.isAo && param.isAu) {
      return "AO + AU";
    } else if (param.isAo && param.isSu) {
      return "AO + SU";
    } else if (param.isAu && param.isSu) {
      return "AU + SU";
    } else if (param.isAo) {
      return "AO";
    } else if (param.isAu) {
      return "AU";
    } else if (param.isSu) {
      return "SU";
    }
  };

  // let str =
  //   "You cannot migrate a subscription that is already attached to a schedule: `sub_sched_1NBCEjHPjiF3hUjmfUokIVWt`.";
  // let array = str.split(" ");
  //

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  console.log(UserDetails);
  return (
    <div className="page-content">
      <Container fluid>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        {loading ? (
          <div className="loadind-page">
            <Loading />
          </div>
        ) : (
          <Row>
            <div className="text-left">
              <div className="family-invitation-leftSection">
                <p className="title-heading">
                  {UserDetails.firstname === "null" ||
                  UserDetails.lastname === "null"
                    ? ""
                    : Capitalize(UserDetails.firstname) +
                      " " +
                      Capitalize(UserDetails.lastname)}
                </p>
                {familyList.length > 0 ? (
                  <>
                    {familyList.length > 1 ? (
                      <p
                        className="sub-text"
                        style={{ "margin-bottom": "2rem" }}
                      >
                        {/* You are part of the following Families on Elderly Life. */}
                        You have multiple groups for your EL account. Pick your
                        group to view details.
                      </p>
                    ) : (
                      <p
                        className="sub-text"
                        style={{ "margin-bottom": "2rem" }}
                      >
                        {/* You are part of the following Families on Elderly Life. */}
                        You have single group for your EL account. Pick your
                        group to view details.
                      </p>
                    )}

                    <Col
                      md="12"
                      className="pl-0 pr-4 fmly-div card-scroll-element mb-4"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {console.log("familyList::::::::::::;", familyList)}
                      {familyList.map(
                        (planDetail, index) =>
                          planDetail.inviteStatus === "Onboarded" &&
                          planDetail.Family.status === "Active" && (
                            <Col xl="6" lg="6" md="12" key={index}>
                              <FamilyList
                                role={renderRole(planDetail)}
                                title={Capitalize(planDetail.Family.name)}
                                membershipDate={moment(
                                  planDetail.Family.createdAt
                                ).format("MMM Do, YYYY")}
                                planName={
                                  planDetail.Family.Family_Subscription
                                    .Account_Subscription_Plan.name
                                }
                                planPrice=""
                                planDuration={
                                  planDetail.Family.Family_Subscription
                                    .Account_Subscription_Plan.renewalTyp
                                }
                                auSeats={
                                  planDetail.Family.Family_Subscription
                                    .Account_Subscription_Plan.availableS
                                }
                                suSeats={
                                  planDetail.Family.Family_Subscription
                                    .Account_Subscription_Plan.availableS
                                }
                                variant="basic"
                                familyName={planDetail.Family.name}
                                familyId={planDetail.Family.displayId}
                                firstLetter={Capitalize(
                                  planDetail.Family.name
                                ).charAt(0)}
                                isAo={planDetail.isAo ? "true" : "false"}
                                buttonClick={() => {
                                  manageSubscriptionRoute(
                                    planDetail.Family.uuid
                                  );
                                }}
                                buttonClick1={() => {
                                  manageDashboardRoute(planDetail.Family.uuid);
                                  localStorage.setItem(
                                    "familyName",
                                    planDetail.Family.name
                                  );
                                  localStorage.setItem(
                                    "userType",
                                    renderRole1(planDetail)
                                  );
                                }}
                                aoDetails={planDetail.Family.aoDetails}
                              />
                            </Col>
                          )
                      )}
                    </Col>
                  </>
                ) : (
                  <>
                    <p className="sub-text">
                      Thank You! for choosing Elderly Life.
                    </p>
                    <p className="sub-text mb-4" style={{ minHeight: "226px" }}>
                      You are not associated with any of the families yet. You
                      can either wait for someone to invite you in a Group or
                      create a new Group on Elderly Life.
                    </p>
                  </>
                )}
              </div>
            </div>

            {/* invite acceptance modal */}
            <Modal
              show={showInviteModel}
              onHide={handleShowInviteModelClose}
              className="invite_accpt_modal"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="text-center">
                <h3>Congratulations !!</h3>
                <Image
                  className="hello-text-img my-4"
                  src={CheckGreen}
                  alt="Check mark"
                  width="18%"
                />
                <p>You are Successfully Onboarded into the Group</p>

                <Link to={familyDashLink}>
                  <Large
                    children="Visit your Group page"
                    size="largebtn"
                    variant="primary"
                    type=""
                    buttonWidth="260px"
                    font_Weight="600"
                  />
                </Link>
              </Modal.Body>
            </Modal>
          </Row>
        )}
      </Container>
    </div>
  );
};
export default Dashboard;
