import React from "react";
import FamilyListCard from "../components/Cards/FamilyListCard";

export default {
  title: "Cards/List",
  component: FamilyListCard,
  args: {
    message: "ActionAlert message",
  },
};

const Template = (args) => <FamilyListCard {...args} />;

export const FamilyList = Template.bind({});
FamilyList.args = {
  firstLetter: "W",
  title: `Walker's Group`,
  membershipDate: "May 21,2018",
  role: "AU + AO + SU",
  variant: "list",
};
