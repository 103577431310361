/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import leftArrow from "../../assets/images/left.svg";
import { Category } from "../../stories/CategoryCard.stories";
import Loading from "../../common/Loading";
import layer from "../../assets/images/layer1.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../../components/Footer/index";

const CompareAndSave = (props) => {
  var contentClass = props.isOpen ? "content open" : "content";
  const [familyDetails, setFamilyDetails] = useState([]);
  const [loadSpin, setloadspin] = useState(true);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [saveCompareList, setSaveCompareList] = useState([]);
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  let history = useHistory();
  var userType = localStorage.getItem("userType");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  let { uuid } = useParams();
  const [limit, setlimit] = useState(18);
  const [offset, setoffset] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [shown, setShown] = useState(true);
  const [srcLink, setsrcLink] = useState("");

  useEffect(() => {
    // get family details

    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
      },
      (error) => {}
    );
    // get compare and save listing
    let params = {
      limit: limit,
      offset: offset,
    };
    UserService.getSaveCompareListNew(params).then(
      (response) => {
        setSaveCompareList(response.result);
        setLoadingspinner(false);
        setDataLength(response.result.length);

        let nextOffset = 18;

        if (response.result.length === 0) {
          setIsEmpty(true);
          setLoadingspinner(false);
          setoffset(0);
          setHasMore(false);
        } else {
          setIsEmpty(false);
          setLoadingspinner(false);
          if (response.result.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        }
      },
      (error) => {
        setLoadingspinner(false);
      }
    );
  }, []);

  const moreData = () => {
    let params = {
      limit: limit,
      offset: offset,
    };
    setTimeout(() => {
      UserService.getSaveCompareListNew(params).then(
        (response) => {
          // let array = saveCompareList.push(...response.result);
          setSaveCompareList([...saveCompareList, ...response.result]);
          setLoadingspinner(false);
          setDataLength(response.result.length + saveCompareList.length);
          let nextOffset = Number(offset) + Number(limit);

          if (response.result.length + saveCompareList.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        },
        (error) => {
          setLoadingspinner(false);
        }
      );
    }, 500);
  };

  var uuidDetails = localStorage.getItem("uuid");
  var userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));

  const dashboardRed = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/family_dashboard/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  const VideoModal = (value) => {
    window.scrollTo(0, 0);
    setsrcLink(value.partnerLinks[0]);
    setShown(!shown);
    setloadspin(true);
    // setTimeout(() => {
    //   setloadspin(false);
    // }, 500);
  };

  const reloadbtn = () => {
    window.location.reload();
  };

  const hideSpinner = () => {
    setloadspin(false);
  };

  return (
    <>
      <div className="page-content save-compare-pg">
        <Container fluid>
          {loadingspinner ? (
            <>
              <div className="loadind-page">
                <Loading />
              </div>
            </>
          ) : shown ? (
            <>
              <div
                className="Reminder-backBtn"
                onClick={() =>
                  // history.push({
                  //   pathname: `/family_dashboard/${data.state.state.tab}`,
                  dashboardRed()
                }
              >
                {" "}
                <img
                  className=""
                  src={layer}
                  alt="Profile Avatar"
                  style={{ "margin-right": "15px" }}
                />{" "}
                {parentUserUUId === null ? (
                  <span>Back to Group Dashboard</span>
                ) : (
                  <span>Back to Main User Dashboard</span>
                )}
              </div>
              <Row className="pt-4">
                <Col md="8" lg="8">
                  <h3>Compare and Save</h3>
                  <p className="sub-title">
                    You can claim and utilise wide range of benefits from
                    different categories on Elderly Life
                  </p>
                </Col>
                <Col md="4" lg="4"></Col>
              </Row>
              {saveCompareList && saveCompareList.length > 0 ? (
                <Row className="mt-4 compareAndSave-card">
                  <InfiniteScroll
                    dataLength={dataLength}
                    next={moreData}
                    hasMore={hasMore}
                    loader={
                      <Row style={{ width: "100%" }}>
                        <Col md="12" lg="12" className="mt-4 pt-4 text-center">
                          <button className="btn loading-btn" type="button">
                            <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                            ></span>
                            Loading . . .
                          </button>
                        </Col>
                      </Row>
                    }
                    style={{ display: "flex", "flex-wrap": "wrap" }}
                  >
                    {saveCompareList.map((compareData, index) =>
                      compareData.name != null ? (
                        <Col
                          md="3"
                          lg="2"
                          key={index}
                          onClick={() => VideoModal(compareData)}
                          style={{ cursor: "pointer" }}
                        >
                          <Category
                            heading={compareData.name ? compareData.name : ""}
                            value=""
                            variant="category"
                            cardImage={compareData.icon ? compareData.icon : ""}
                          />
                        </Col>
                      ) : (
                        ""
                      )
                    )}
                  </InfiniteScroll>
                </Row>
              ) : (
                <Row className="text-center">
                  <p className="p-5 m-5">No data found</p>
                </Row>
              )}
            </>
          ) : (
            <>
              <div className="Reminder-backBtn" onClick={() => reloadbtn()}>
                {" "}
                <img
                  className=""
                  src={layer}
                  alt="Profile Avatar"
                  style={{ "margin-right": "15px" }}
                />{" "}
                {<span>Back to Compare and Save</span>}
              </div>
              {loadSpin ? (
                <div className="loadind-page">
                  <Loading />
                </div>
              ) : null}
              <div>
                <iframe
                  src={srcLink}
                  width="100%"
                  height="450"
                  onLoad={hideSpinner}
                ></iframe>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};
export default CompareAndSave;
