import Cookies from 'universal-cookie';

export default function authHeader() {
  //const user = JSON.parse(localStorage.getItem('UserDetails'));  
  // get accessToken from cookies 
  const cookies = new Cookies();
  const userToken = cookies.get('accessToken')
  if (userToken) {
    return { 'x-access-token': userToken };
  } else {
    return {};
  }
}