import React from "react";
import { useState, useEffect, useRef } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";
import { useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import CheckGreen from "../../assets/images/check-green.svg";
import EL_logo from "../../assets/images/EL_logo-big.svg";

import Modal from "react-bootstrap/Modal";
import { Small } from "../../stories/Input.stories";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Final from "./Final";
import Layer from "../../assets/images/Layer 22.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const FamilyCreationConfirmation = (props) => {
  const executeScroll = () => myRef.current.scrollIntoView();
  let history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const myRef = useRef(null);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [step, setstep] = useState(1);
  const [style, setStyle] = useState("wizard-div-sub1-2");
  const [style2, setStyle2] = useState("wizard-div-sub1-2");
  const [style4, setStyle4] = useState("wizard-div-sub1-2");
  const [style7, setStyle7] = useState("wizard-div-sub1-2");
  const [style8, setStyle8] = useState("wizard-div-sub1-2");
  const [style1, setStyle1] = useState("wizard-div-sub1-3");
  const [style3, setStyle3] = useState("wizard-div-sub1-3");
  const [style5, setstyle5] = useState("wizard-div-sub1-3");
  const [style6, setstyle6] = useState("wizard-div-sub1-3");
  const [style10, setstyle10] = useState("wizard-div-sub1-3");
  const [MainValue, setMainValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [showInviteModel, setShowInviteModel] = useState(false);
  const handleShowInviteModelClose = () => {
    setShowInviteModel(false);

    if (list.length === 0) {
      setStyle2("wizard-div-sub1-2");
      document.getElementById("welcomeNext1").style.display = "none";
      setNewVal("");
    }
    if (list.length > 0) {
      setStyle7("wizard-div-sub1-2");
      // document.getElementById("welcomeNext1").style.display = "none";
    }

    if (mainFn === "yes" && mainFn4 === "22" && mainFn6 === "44") {
      setMainValue(true);
    }

    // setNewVal("");
    setFormValues(initialValues);

    // if (mainFn6 === "") {
    //   setMainValue(false)

    //   ;
    // }

    if (mainList.is_pu === "true" && mainFn3 === "11" && mainFn4 === "") {
      setMainValue(false);
    }

    if (mainList.is_pu === "true" && list.length === 0) {
      setMainValue(false);
    }

    if (
      NewRes4 !== 3 &&
      mainList.is_pu === "true" &&
      mainFn5 === "33" &&
      mainFn4 === "22" &&
      mainFn === "yes"
    ) {
      setMainValue(true);
    }

    if (
      NewRes4 !== 3 &&
      NewRes4 !== 6 &&
      mainList.is_pu === "true" &&
      mainFn5 === "33" &&
      mainFn4 === "22" &&
      mainFn === "yes"
    ) {
      setMainValue(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(false);
    }

    // if (
    //   (NewRes4 === 2 || NewRes4 === 5) &&
    //   mainList.is_pu === "true" &&
    //   list.length > 0 &&
    //   mainFn3 === "" &&
    //   mainFn4 === ""
    // ) {
    //   setMainValue(false);
    //   ;
    // }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      list.length > 0 &&
      mainFn5 === "33" &&
      mainFn === "yes" &&
      mainFn3 === "" &&
      mainFn4 === "" &&
      MainValue === true
    ) {
      setMainValue(true);
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      list.length > 0 &&
      mainFn5 === "33" &&
      mainFn === "yes" &&
      mainFn3 === "" &&
      mainFn4 === "" &&
      MainValue === false
    ) {
      setMainValue(false);
    }

    // setStyle7("wizard-div-sub1-2");
    // setStyle3("wizard-div-sub1-3");
    // setMainFn5("");
    // setMainFn6("");
  };

  const [showInviteModel1, setShowInviteModel1] = useState(false);
  const handleShowInviteModel1Close = () => {
    setShowInviteModel1(false);
    if (list1.length === 0) {
      setStyle4("wizard-div-sub1-2");
      setNewVal2("");
    }
    if (list1.length > 0) {
      setStyle8("wizard-div-sub1-2");
    }
    if (mainFn4 === "22") {
      setMainValue(true);
    }
    // setNewVal2("");
    // setstyle5("wizard-div-sub1-3");
    // setstyle6("wizard-div-sub1-3-after");
    // if (mainList.is_pu === "false" && mainFn1 == "No" && mainFn4 != "22") {
    //   setstyle6("wizard-div-sub1-3-after");
    //   ;
    // }
    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "false" &&
      mainFn === "yes" &&
      mainFn1 === "" &&
      MainValue === false
    ) {
      setstyle6("wizard-div-sub1-3");
    }

    if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "false" &&
      mainFn === "yes" &&
      mainFn1 === "" &&
      MainValue === true
    ) {
      setstyle6("wizard-div-sub1-3-after");
    }
    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "false" &&
      mainFn === "yes" &&
      mainFn1 === "" &&
      MainValue === true
    ) {
      setstyle6("wizard-div-sub1-3-after");
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "false" &&
      mainFn === "yes" &&
      mainFn1 === ""
    ) {
      setstyle6("wizard-div-sub1-3-after");
    }

    if (
      mainList.is_pu === "false" &&
      mainFn === "yes" &&
      mainFn1 === "" &&
      mainFn3 === "11"
    ) {
      setstyle6("wizard-div-sub1-3");
    }

    // if (
    //   mainList.is_pu === "false" &&
    //   (mainFn4 == "22" || mainFn4 == "") &&
    //   list1.length > 0
    // ) {
    //   setstyle6("wizard-div-sub1-3");
    // }

    if (mainList.is_pu === "true" && mainFn1 == "No") {
      setstyle6("wizard-div-sub1-3-after");
    }

    if (mainList.is_pu === "true" && mainFn1 == "No" && list1.length >= 1) {
      setstyle6("wizard-div-sub1-3");
    }

    if (
      mainList.is_pu === "true" &&
      mainFn6 === "9999" &&
      list1.length === 0 &&
      MainValue === true
    ) {
      setstyle6("wizard-div-sub1-3-after");
    }

    if (
      mainList.is_pu === "true" &&
      mainFn6 === "9999" &&
      list1.length === 0 &&
      MainValue === false
    ) {
    }

    if (mainList.is_pu === "true" && mainFn6 === "9999" && list1.length > 0) {
      setstyle6("wizard-div-sub1-3");
    }

    if (
      mainList.is_pu === "true" &&
      mainFn5 === "33" &&
      mainFn === "yes" &&
      MainValue === true
    ) {
      setstyle6("wizard-div-sub1-3-after");
    }

    if (
      mainList.is_pu === "true" &&
      mainFn5 === "33" &&
      mainFn === "yes" &&
      MainValue === false
    ) {
      setstyle6("wizard-div-sub1-3");
    }

    if (
      NewRes4 !== 3 &&
      NewRes4 !== 6 &&
      mainList.is_pu === "true" &&
      mainFn6 === "44" &&
      mainFn === "yes" &&
      MainValue === true
    ) {
      setstyle6("wizard-div-sub1-3-after");
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      mainFn6 === "44" &&
      mainFn === "yes" &&
      MainValue === true
    ) {
      setstyle6("wizard-div-sub1-3");
    }

    if (
      mainList.is_pu === "true" &&
      mainFn6 === "44" &&
      mainFn === "yes" &&
      MainValue === false
    ) {
      setstyle6("wizard-div-sub1-3");
    }

    if (
      mainList.is_pu === "true" &&
      mainFn5 === "33" &&
      list1.length > 0 &&
      mainFn === "yes"
    ) {
      setstyle6("wizard-div-sub1-3");
    }
    setFormValues1(initialValues1);
    // setMainFn3("");
    // setMainFn4("");
  };
  const [showInviteModel2, setShowInviteModel2] = useState(false);
  const handleShowInviteModel2Close = () => setShowInviteModel2(false);
  const [NewRes, setNewRes] = useState({});
  const [newRes1, setNewRes1] = useState("");
  const [NewRes2, setNewRes2] = useState({});
  const [NewRes3, setNewRes3] = useState({});
  const [NewRes4, setNewRes4] = useState();
  const [CountDetails, setCountDetails] = useState({});
  const [familyUsersList, setFamilyUsersList] = useState([]);
  const [div, setdiv] = useState(false);
  var contentClass = props.isOpen ? "content open" : "content";
  const routeChange = () => {
    history.push({
      pathname: `/family_dashboard/${props.location.familyUUID}`,
    });
  };

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // var UserAll = JSON.parse(localStorage.getItem("UserAll"));

  var UserDA = JSON.parse(localStorage.getItem("UserDA"));

  const [newVal, setNewVal] = useState();
  const [newVal1, setNewVal1] = useState();
  const [newVal2, setNewVal2] = useState();
  // const [newVal3, setNewVal3] = useState();

  // useEffect(() => {
  //   UserService.getUserSingleFamilyDetails(
  //     UserDA.FamilyData.uuid,
  //     UserDetails.id
  //   ).then((response) => {
  //
  //
  //     //
  //     //   response.FamilyData.Family.Family_Subscription.Account_Subscription_Plan
  //     //     .name
  //     // );
  //     setNewRes(response.FamilyData);
  //     setNewRes1(response.FamilyData.Family);
  //     setNewRes2(response.FamilyData.Family.Family_Subscription);
  //     setNewRes3(
  //       response.FamilyData.Family.Family_Subscription.Account_Subscription_Plan
  //     );
  //     setNewRes4(
  //       response.FamilyData.Family.Family_Subscription.Account_Subscription_Plan
  //         .name
  //     );

  //
  //
  //
  //   });
  // }, []);
  //
  //
  //
  //
  //

  // useEffect(() => {
  //   UserService.getFamilyInviteList(UserDA.FamilyData.uuid, "").then((res1) => {
  //
  //     setCountDetails(res1);
  //     setFamilyUsersList(res1.result.rows);
  //   });
  // }, []);
  //
  //
  //

  var UserA = JSON.parse(localStorage.getItem("UserA"));
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  useEffect(() => {
    setNewRes4(UserA);
  }, []);

  var UserB = JSON.parse(localStorage.getItem("UserB"));
  // useEffect(() => {
  //   setNewRes1(UserB);
  // }, []);

  //

  const initialValues2 = {
    is_pu: "",
  };
  const [mainList, setMainList] = useState(initialValues2);

  const OpenWizard = (event, key) => {
    // var targetData = {
    //   value: event.target.value,
    // };
    // const { value } = targetData;
    if (event.target.value === "true") {
      setMainList({ ...initialValues2, is_pu: event.target.value });
    }
    setNewVal1("yes1");
    setNewVal("");
    setNewVal2("");

    setStyle("wizard-div-sub1-2-after");
    setStyle1("wizard-div-sub1-3");
    setStyle2("wizard-div-sub1-2");
    setStyle4("wizard-div-sub1-2");
    setstyle10("wizard-div-sub1-3");
    setstyle6("wizard-div-sub1-3");
    document.getElementById("welcomeDiv").style.display = "block";
    document.getElementById("welcomeNext1").style.display = "none";
    document.getElementById("welcomeNext").style.display = "none";
    document.getElementById("welcomeNext2").style.display = "none";
    setMainValue(false);
    setList([]);
    setList1([]);
    document.getElementById("welcomeModel1").style.display = "none";
    document.getElementById("welcomeModel").style.display = "none";
  };

  const OpenWizard1 = (event, key) => {
    setStyle1("wizard-div-sub1-3-after");

    if (event.target.value === "false") {
      setMainList({ ...initialValues2, is_pu: event.target.value });
    }
    setStyle("wizard-div-sub1-2");
    setStyle2("wizard-div-sub1-2");
    setStyle4("wizard-div-sub1-2");
    setstyle6("wizard-div-sub1-3");
    setNewVal1("No1");
    setNewVal("");
    setNewVal2("");
    document.getElementById("welcomeDiv").style.display = "none";
    document.getElementById("welcomeNext").style.display = "none";
    document.getElementById("welcomeNext1").style.display = "block";
    document.getElementById("welcomeNext2").style.display = "none";
    document.getElementById("welcomeModel").style.display = "none";
    document.getElementById("welcomeModel1").style.display = "none";
    // if (list1.length > 0) {
    //   document.getElementById("welcomeNext2").style.display = "block";
    //   setstyle5("wizard-div-sub1-3");
    // } else {
    //   document.getElementById("welcomeNext2").style.display = "none";
    // }
    // document.getElementById("welcomeNext2").style.display = "none";
    setMainValue(false);
    setList([]);
    setList1([]);
  };

  const OpenWizard3 = (event) => {
    if (event.target.value === "44") {
      setMainFn6(event.target.value);
    }
    setMainFn5("");

    setStyle3("wizard-div-sub1-3-after");
    setNewVal2("");
    // setNewVal3("No3");
    // setStyle("wizard-div-sub1-2");

    if (
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(true);
    }

    if (mainFn4 === "22") {
      if (mainFn6 === "") {
        setMainValue(true);
      } else if (mainFn6 === "") {
        setMainValue(false);
      }
    }
    if (
      NewRes4 !== 3 &&
      NewRes4 !== 6 &&
      mainFn4 === "" &&
      (mainFn1 === "No" || mainFn === "yes")
    ) {
      setMainValue(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainFn === "yes" &&
      mainFn4 === "" &&
      mainFn3 === ""
    ) {
      setMainValue(false);
    }

    if ((NewRes4 === 2 || NewRes4 === 5) && mainFn4 === "" && mainFn1 === "") {
      setMainValue(false);
    }
    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainFn4 === "" &&
      mainFn1 === "" &&
      list.length > 0 &&
      list1.length > 0
    ) {
      setMainValue(true);
    }

    if (
      mainFn === "yes" &&
      mainFn3 === "11" &&
      NewRes4 !== 3 &&
      NewRes4 !== 6
    ) {
      if (mainFn6 === "44") {
        setMainValue(true);
      }
      if (mainFn5 === "33") {
        setMainValue(true);
      }
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      style4 === "wizard-div-sub1-2" &&
      style6 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      style4 === "wizard-div-sub1-2-after"
    ) {
      setMainValue(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      style6 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style6 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(true);
    }

    if (
      mainList.is_pu === "true" &&
      mainFn === "yes" &&
      mainFn3 === "11" &&
      list1.length === 4 &&
      list.length > 0
    ) {
      setMainValue(true);
    }
    // if (mainFn3 === "11" || mainFn4 === "22") {
    //   setMainValue(true)
    //   ;
    // }

    setStyle7("wizard-div-sub1-2");
    setStyle8("wizard-div-sub1-2");
    // if (list1.length === 0) {
    //   setStyle4("wizard-div-sub1-2");
    //   setstyle6("wizard-div-sub1-3");
    // }
    document.getElementById("welcomeNext1").style.display = "block";
    // setMainValue(false)
  };

  const OpenWizard5 = (event) => {
    if (event.target.value === "22") {
      setMainFn4(event.target.value);
    }
    setMainFn3("");
    if (NewRes4 === 1 || NewRes4 === 4) {
      if (mainFn6 === "44" || list1.length == 1 || list1.length > 0) {
        setMainValue(true);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
      }
    }
    if (NewRes4 === 2 || NewRes4 === 5) {
      if (mainFn4 === "22" || list1.length == 2 || list1.length > 0) {
        setMainValue(true);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
      }
    }
    if (NewRes4 === 3 || NewRes4 === 6) {
      if (mainFn4 === "22" || list1.length == 5 || list1.length > 0) {
        setMainValue(true);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
      }
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2" &&
      style10 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
    }

    setstyle5("wizard-div-sub1-3-after");
    setStyle8("wizard-div-sub1-2");

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style4 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length < 5 &&
      list1.length > 0 &&
      list1.length < 4
    ) {
      setMainValue(false);
    }
  };

  // const OpenWizardYes = ()

  const modelOpen = () => {
    if (list.length >= 1) {
      setShowInviteModel(false);
    } else {
      setShowInviteModel(true);
    }
    setNewVal("yes");
    setNewVal2("");
    setStyle4("wizard-div-sub1-2");
    setstyle6("wizard-div-sub1-3");
    setStyle2("wizard-div-sub1-2-after");
    setStyle7("wizard-div-sub1-2");
    setstyle10("wizard-div-sub1-3");
    setMainFn("");
    setMainFn1("");
    setList([]);
    setList1([]);
    document.getElementById("welcomeModel1").style.display = "none";
    document.getElementById("welcomeNext1").style.display = "none";
    document.getElementById("welcomeNext2").style.display = "none";
    setMainValue(false);
  };
  const [mainFn, setMainFn] = useState();
  const [mainFn1, setMainFn1] = useState();
  const [mainFn3, setMainFn3] = useState();
  const [mainFn4, setMainFn4] = useState();
  const [mainFn5, setMainFn5] = useState();
  const [mainFn6, setMainFn6] = useState();
  const modelOpen1 = (event) => {
    if (event.target.value === "yes") {
      setMainFn(event.target.value);
    }
    setMainFn1("");

    setNewVal2("yes2");
    // setNewVal3("yes3");
    if (list1.length >= 1) {
      setShowInviteModel1(false);
    } else {
      setShowInviteModel1(true);
      setNewVal2("");
    }
    setStyle4("wizard-div-sub1-2-after");
    setstyle6("wizard-div-sub1-3");
    // setMainValue(false)
  };

  const modelOpen11 = () => {
    setstyle10("wizard-div-sub1-3-after");
    setNewVal("No");
    setNewVal2("");
    if ((NewRes4 === 1 || NewRes4 === 4) && mainList.is_pu === "true") {
      document.getElementById("welcomeNext1").style.display = "none";
      setMainValue(true);
    } else {
      document.getElementById("welcomeNext1").style.display = "block";
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(false);
    }
    setStyle2("wizard-div-sub1-2");
    setList([]);
    setList1([]);
    setStyle4("wizard-div-sub1-2");
    setstyle6("wizard-div-sub1-3");
    document.getElementById("welcomeNext").style.display = "none";
    document.getElementById("welcomeModel").style.display = "none";
    document.getElementById("welcomeModel1").style.display = "none";
    setMainFn("");
    setMainFn1("");
    setMainFn3("");
    setMainFn4("");
    setMainFn6("9999");
  };

  const initialValues = {
    SU_firstname: "",
    SU_lastname: "",
    SU_email: "",
  };

  const initialValues1 = {
    PU_firstname: "",
    PU_lastname: "",
    PU_email: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formValues1, setFormValues1] = useState(initialValues1);
  const [list, setList] = useState([]);
  const [list1, setList1] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setSUValues(formValues);
    setShowInviteModel(false);
    setStyle7("wizard-div-sub1-2");
    setList((ls) => [...ls, formValues]);
    document.getElementById("welcomeModel").style.display = "block";

    // document.getElementById("welcomeNext").style.display = "block";
    if ((NewRes4 === 2 || NewRes4 === 5) && list.length >= 1) {
      document.getElementById("welcomeNext").style.display = "none";
      document.getElementById("welcomeNext1").style.display = "block";
      // setStyle4("wizard-div-sub1-2");
      // setstyle6("wizard-div-sub1-3");
      // setMainValue(true)
      if (mainFn1 === "No" && list1.length === 0) {
        setMainValue(true);
      } else if (mainFn === "yes" && list1.length > 0) {
        setMainValue(true);
      } else {
        setMainValue(false);
      }
    } else if ((NewRes4 === 1 || NewRes4 === 4) && list.length >= 0) {
      document.getElementById("welcomeNext").style.display = "none";
      document.getElementById("welcomeNext1").style.display = "none";
      setMainValue(true);
    } else if ((NewRes4 === 3 || NewRes4 === 6) && list.length >= 4) {
      document.getElementById("welcomeNext").style.display = "none";
      document.getElementById("welcomeNext1").style.display = "block";
      if (
        mainFn === "yes" &&
        mainFn4 === "22" &&
        mainFn5 === "33" &&
        list1.length > 0
      ) {
        setMainValue(true);
      }
      // setStyle4("wizard-div-sub1-2");
      // setstyle6("wizard-div-sub1-3");
      if (style6 === "wizard-div-sub1-3-after") {
        setMainValue(true);
      }
    } else {
      document.getElementById("welcomeNext").style.display = "block";
      // document.getElementById("welcomeNext1").style.display = "none";
    }

    // if (mainList.is_pu === "true" && mainFn4 === "" && mainFn6 === "") {
    //   setMainValue(false);
    //   ;
    // }

    // if (mainList.is_pu === "true" && mainFn4 === "22" && mainFn6 === "") {
    //   setMainValue(false);
    //   ;
    // }
    // if (mainList.is_pu === "true" && mainFn4 === "" && mainFn6 === "44") {
    //   setMainValue(false);
    //   ;
    // }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style8 === "wizard-div-sub1-2" &&
      style5 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style6 === "wizard-div-sub1-3-after" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
    }

    if ((NewRes4 === 2 || NewRes4 === 5) && list.length >= 1) {
      if (mainFn === "yes" && mainFn3 === "11") {
        if (mainFn6 === "44") {
          setMainValue(true);
        }
        if (mainFn5 === "33") {
          setMainValue(true);
        }
      }
    } else if ((NewRes4 === 1 || NewRes4 === 4) && list.length >= 0) {
      if (mainFn === "yes" && mainFn3 === "11") {
        if (mainFn6 === "44") {
          setMainValue(true);
        }
        if (mainFn5 === "33") {
          setMainValue(true);
        }
      }
    } else if ((NewRes4 === 3 || NewRes4 === 6) && list.length >= 4) {
      if (mainFn === "yes" && mainFn3 === "11") {
        if (mainFn6 === "44") {
          setMainValue(true);
        }
        if (mainFn5 === "33" && list1.length < 4) {
          setMainValue(false);
        }
        if (mainFn5 === "33" && list1.length >= 4) {
          setMainValue(true);
        }
      }
    } else {
      document.getElementById("welcomeNext").style.display = "block";
      // document.getElementById("welcomeNext1").style.display = "none";
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      list.length > 0 &&
      list.length < 4 &&
      list1.length > 0 &&
      list1.length < 3 &&
      MainValue === true
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      list.length > 0 &&
      list.length < 4 &&
      list1.length > 0 &&
      list1.length < 3 &&
      MainValue === false
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style6 === "wizard-div-sub1-3-after" &&
      list.length > 0 &&
      list.length === 4
    ) {
      setMainValue(true);
    }

    setStyle3("wizard-div-sub1-3");
    setFormValues(initialValues);
  };
  const [SUValues, setSUValues] = useState({});

  const handleSubmit1 = (e) => {
    e.preventDefault();

    setSUValues1(formValues1);
    setShowInviteModel1(false);
    setList1((ls) => [...ls, formValues1]);
    setMainFn4("");
    document.getElementById("welcomeModel1").style.display = "block";
    setStyle8("wizard-div-sub1-2");
    // document.getElementById("welcomeNext2").style.display = "block";

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      list1.length === 0
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(true);
    } else if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "false" &&
      list1.length === 1
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(true);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "true" &&
      list1.length === -1
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(true);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "false" &&
      list1.length === 0
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(true);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length === 3 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style10 === "wizard-div-sub1-3" &&
      list.length < 5
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(false);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length === 3 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style10 === "wizard-div-sub1-3-after" &&
      list.length < 5
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(true);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length === 3 &&
      (style7 === "wizard-div-sub1-2-after" ||
        style3 === "wizard-div-sub1-3-after") &&
      list.length < 5
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(true);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length === 3 &&
      list.length === 5
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(true);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "false" &&
      list1.length === 4
    ) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(true);
    } else {
      document.getElementById("welcomeNext2").style.display = "block";
      setMainValue(false);
    }

    setstyle5("wizard-div-sub1-3");
    setFormValues1(initialValues1);
  };
  const [SUValues1, setSUValues1] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormValues1({ ...formValues1, [name]: value });
  };

  const deleteAll1 = () => {
    setSUValues1(initialValues1);
    document.getElementById("welcomeModel1").style.display = "none";
    setStyle4("wizard-div-sub1-2");
    document.getElementById("welcomeNext2").style.display = "none";
  };

  // useEffect(() => {
  //   var UserB = JSON.parse(localStorage.getItem("UserB"));
  //   ;
  //   if (UserB) {
  //     setNewRes1(UserB);
  //   }
  //   ;
  // }, []);

  const FinalValue = () => {
    setLoading(true);
    let data = {
      is_pu: mainList.is_pu,
      aoData: UserDetails,
      primary: list1,
      support: list,
      family_uuid: UserB,
    };
    {
      mainList.is_pu === "true" ? (data.is_pu = true) : (data.is_pu = false);
    }
    UserService.createWizard(data).then(
      (response) => {
        if (response.status === 400 || response.status === 404) {
          setLoading(false);
          executeScroll();
          window.scrollTo(0, 0);
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(
            response.data.error ? response.data.error : response.data.message
          );
        } else {
          setLoading(true);
          setSuccessToast(true);
          setShowToast(true);
          executeScroll();
          window.scrollTo(0, 0);
          setToastMessage("Successfully added Users to the Family");

          window.history.pushState(
            null,
            "",
            history.push(`/family_dashboard/${UserB}`)
          );
          window.onpopstate = function () {
            window.history.pushState(
              null,
              "",
              history.push(`/family_dashboard/${UserB}`)
            );
          };
        }
      },
      (error) => {
        setLoading(false);
      }
    );
    // setShowInviteModel2(true);
  };

  const FHS = (event) => {
    if (event.target.value === "No") {
      setMainFn1(event.target.value);
    }
    setMainFn("");
    setNewVal2("No2");
    // setNewVal3("No3");
    setstyle6("wizard-div-sub1-3-after");
    setStyle4("wizard-div-sub1-2");
    setMainValue(true);
    setList1([]);
    document.getElementById("welcomeNext2").style.display = "none";
    document.getElementById("welcomeModel1").style.display = "none";
    setMainFn3("");
    setMainFn4("");
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length < 5
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length < 2
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length === 2
    ) {
      setMainValue(true);
    }
  };

  const [newList, setNewList] = useState([]);
  const deleteAll = (event, key) => {
    const newList = [...list];
    newList.splice(key, 1);
    setList(newList);
    if ((NewRes4 === 2 || NewRes4 === 5) && newList.length < 2) {
      document.getElementById("welcomeNext").style.display = "block";
      setMainValue(false);
      setStyle7("wizard-div-sub1-2");
      setStyle3("wizard-div-sub1-3");
    } else if ((NewRes4 === 1 || NewRes4 === 4) && newList.length < 1) {
      document.getElementById("welcomeNext").style.display = "block";
      setMainValue(false);
      setStyle7("wizard-div-sub1-2");
      setStyle3("wizard-div-sub1-3");
    } else if ((NewRes4 === 3 || NewRes4 === 6) && newList.length < 5) {
      document.getElementById("welcomeNext").style.display = "block";
      setMainValue(false);
      setStyle7("wizard-div-sub1-2");
      setStyle3("wizard-div-sub1-3");
    } else if ((NewRes4 !== 1 || NewRes4 !== 4) && newList.length === 0) {
      document.getElementById("welcomeNext").style.display = "none";
      document.getElementById("welcomeNext1").style.display = "block";
      setMainValue(false);
      setStyle2("wizard-div-sub1-2");
    } else if ((NewRes4 === 1 || NewRes4 === 4) && newList.length === 0) {
      document.getElementById("welcomeNext").style.display = "none";
      document.getElementById("welcomeNext1").style.display = "none";
      setMainValue(false);
      setStyle2("wizard-div-sub1-2");
    } else {
      document.getElementById("welcomeNext").style.display = "none";
    }

    if ((NewRes4 !== 1 || NewRes4 !== 4) && newList.length === 0) {
      document.getElementById("welcomeNext").style.display = "none";
      setNewVal("");
      document.getElementById("welcomeNext1").style.display = "block";
      document.getElementById("welcomeModel").style.display = "none";
      setStyle2("wizard-div-sub1-2");

      setMainValue(false);
    }

    if ((NewRes4 === 1 || NewRes4 === 4) && newList.length === 0) {
      document.getElementById("welcomeNext").style.display = "none";
      setNewVal("");
      document.getElementById("welcomeNext1").style.display = "none";
      document.getElementById("welcomeModel").style.display = "none";
      setStyle2("wizard-div-sub1-2");

      setMainValue(false);
    }
  };

  const [newList1, setNewList1] = useState([]);
  const deleteAll2 = (event, key) => {
    const newList1 = [...list1];
    newList1.splice(key, 1);
    setList1(newList1);

    if ((NewRes4 === 2 || NewRes4 === 5) && newList1.length < 1) {
      document.getElementById("welcomeNext2").style.display = "block";
      setMainValue(false);
      // setMainValue(false)
      setStyle8("wizard-div-sub1-2");
      setstyle5("wizard-div-sub1-3");
    } else if ((NewRes4 === 2 || NewRes4 === 5) && newList1.length === 1) {
      document.getElementById("welcomeNext2").style.display = "block";
      setMainValue(false);
      setStyle8("wizard-div-sub1-2");
      setstyle5("wizard-div-sub1-3");
    } else if ((NewRes4 === 1 || NewRes4 === 4) && newList1.length < 0) {
      document.getElementById("welcomeNext2").style.display = "block";
      setMainValue(false);
      // setMainValue(false)
      setStyle8("wizard-div-sub1-2");
      setstyle5("wizard-div-sub1-3");
    } else if ((NewRes4 === 1 || NewRes4 === 4) && newList1.length === 0) {
      document.getElementById("welcomeNext2").style.display = "block";
      setMainValue(false);
      setStyle8("wizard-div-sub1-2");
      setstyle5("wizard-div-sub1-3");
    } else if ((NewRes4 === 3 || NewRes4 === 6) && newList1.length < 4) {
      document.getElementById("welcomeNext2").style.display = "block";
      setMainValue(false);
      setStyle8("wizard-div-sub1-2");
      setstyle5("wizard-div-sub1-3");
    } else if ((NewRes4 === 3 || NewRes4 === 6) && newList1.length === 4) {
      document.getElementById("welcomeNext2").style.display = "block";
      setMainValue(false);
      setStyle8("wizard-div-sub1-2");
      setstyle5("wizard-div-sub1-3");
    } else if (newList1.length === 0) {
      document.getElementById("welcomeNext2").style.display = "none";
      setMainValue(false);
      setStyle4("wizard-div-sub1-2");
    } else {
      setMainValue(true);
      document.getElementById("welcomeNext2").style.display = "none";
    }

    if (newList1.length === 0) {
      document.getElementById("welcomeNext2").style.display = "none";
      document.getElementById("welcomeModel1").style.display = "none";

      setStyle4("wizard-div-sub1-2");
      setMainValue(false);
      setNewVal2("");
    }
  };

  const OpenWizardYes = (event) => {
    if (event.target.value === "33") {
      setMainFn5(event.target.value);
    }
    setMainFn6("");

    setStyle7("wizard-div-sub1-2-after");

    if (mainFn === "yes" || mainFn1 === "No") {
      setMainValue(false);
    }

    if (mainFn6 === "44") {
      setMainValue(true);
    }

    setNewVal2("");
    // setNewVal3("yes3");
    // setMainValue(false)
    // setShowInviteModel(true);
    if ((NewRes4 === 2 || NewRes4 === 5) && list.length >= 2) {
      setShowInviteModel(false);
    } else if ((NewRes4 === 1 || NewRes4 === 4) && list.length >= 1) {
      setShowInviteModel(false);
    } else if ((NewRes4 === 3 || NewRes4 === 6) && list.length >= 5) {
      setShowInviteModel(false);
    } else {
      setShowInviteModel(true);
    }
  };

  const [FinalVal, setFinalVal] = useState();
  useEffect(() => {
    if (mainList.is_pu === "true") {
      setFinalVal({
        is_pu: mainList.is_pu,
        primary: list1,
        support: list,
        family_uuid: UserB,
      });
    } else if (mainList.is_pu === "false") {
      setFinalVal({
        is_pu: mainList.is_pu,
        primary: list1,
        support: list,
        family_uuid: UserB,
      });
    } else {
      setFinalVal({
        is_pu: mainList.is_pu,
        primary: list1,
        support: list,
        family_uuid: UserB,
      });
    }
  }, []);

  const OpenWizard10 = (event) => {
    if (event.target.value === "11") {
      setMainFn3(event.target.value);
    }
    setMainFn4("");
    setStyle8("wizard-div-sub1-2-after");
    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      list1.length >= 1
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "true" &&
      list1.length >= 0
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length >= 4
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "false" &&
      list1.length >= 5
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "false" &&
      list1.length >= 1
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
    } else if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "false" &&
      list1.length >= 2
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
    } else {
      setShowInviteModel1(true);
      // setMainValue(false)
    }
  };

  const initialValues3 = {
    is_pu: mainList.is_pu,
    primary: list1,
    support: list,
    family_uuid: UserB,
  };

  // var pluginArrayArg = [];
  // pluginArrayArg.push(list);
  // pluginArrayArg.push(list1);
  // pluginArrayArg.push(NewRes1);
  // if (mainList.is_pu === "true") {
  //   pluginArrayArg.push(true);
  // } else {
  //   pluginArrayArg.push(false);
  // }

  // var pluginArrayArg = {
  //   is_pu: mainList.is_pu,
  //   aoData: UserDetails,
  //   primary: list1,
  //   support: list,
  //   family_uuid: UserB,
  // };

  // var jsonArray = JSON.parse(JSON.stringify(pluginArrayArg));

  // const helloBtn = () => {
  //   window.history.pushState(
  //     null,
  //     "",
  //     history.push(`/family_dashboard/${UserDA.FamilyData.uuid}`)
  //   );
  //   window.onpopstate = function () {
  //     window.history.pushState(
  //       null,
  //       "",
  //       history.push(`/family_dashboard/${UserDA.FamilyData.uuid}`)
  //     );
  //   };
  // };

  // useEffect(() => {
  //   // window.history.pushState(
  //   //   null,
  //   //   null,
  //   //   history.push(`/family_dashboard/${uuid}`)
  //   // );
  //   // ;
  //   // window.onpopstate = function () {
  //   //   window.history.go(0);
  //   //   ;
  //   // };

  //   window.history.forward();
  //   // history.pushState(null, null, history.push(`/family_dashboard/${uuid}`));
  //   // window.onpopstate = function () {
  //   //   history.go(1);
  //   // };
  //   ;
  // }, []);

  function openWizardBtn() {
    history.push("/onboardUser");
    history.push({
      pathname: "/onboardUser",
      fromWizardtSuccsess: true,
    });
  }

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  if (props.location.key) {
    return (
      <div className="page-content confirmation-pg">
        <Container ref={myRef}>
          <ToastBlockContainer
            successToast={successToast}
            showToast={showToast}
            setShowToast={setShowToast}
            toastMessage={toastMessage}
          />
          <div className="text-center confrm-div">
            <Image
              className="hello-text-img new-img"
              src={EL_logo}
              alt="Check mark"
            />
            {/* <h3 style={{ "font-size": "initial" }}>Congratulations !!</h3> */}
            <p style={{ "font-size": "initial" }}>
              Welcome to Elderly Life. We will now ask you a series of questions
              to get you and all the other users set up. 
            </p>
            <p style={{ "font-size": "initial" }}>
              Remember, we are always here for you; you can click the chat icon
              at the bottom of each screen and drop us a message whenever you
              need support. 
            </p>
            {/* <p style={{ "font-size": "initial" }}>Elderly Life</p> */}
            {/* <h3 style={{ "font-size": "initial", "padding-bottom": "1.5rem" }}>
              Makes Life Simple
            </h3> */}
            {/* <Primary
              children="Visit your Family page"
              size="mediumbtn"
              type=""
              variant="primary"
              buttonWidth="45%"
              onClick={routeChange}
            /> */}

            <button className="btnWizard" onClick={openWizardBtn}>
              <b>Let’s begin</b>
            </button>
          </div>

          {/* wizard model */}
          {/* <Modal
            show={showInviteModel}
            onHide={handleShowInviteModelClose}
            className="wizard-auth-dsgn"
            data-keyboard="false"
            data-backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3> Invite Support User (SU)</h3>
              </Modal.Title>
            </Modal.Header>
  
            <Modal.Body>
              <p className="mb-1 subtext">
                Enter the email address of the user whom you want to add as a
                Support User for you
              </p>
              <form className="invite-form" onSubmit={handleSubmit}>
                <label
                // className={
                //   formErrors.firstname
                //     ? "invalid-text form-label mt-3 mb-0"
                //     : "form-label mt-3 mb-0"
                // }
                >
                  Firstname
                </label>
                <Small
                  type="test"
                  // errorClass={formErrors.firstname ? "invalid-input" : ""}
                  placeholder="John"
                  size="medium"
                  name="SU_firstname"
                  required
                  value={formValues.SU_firstname}
                  onChange={handleChange}
                />
                <label
                // className={
                //   formErrors.lastname
                //     ? "invalid-text form-label mt-3 mb-0"
                //     : "form-label mt-3 mb-0"
                // }
                >
                  Lastname
                </label>
                <Small
                  type="test"
                  // errorClass={formErrors.lastname ? "invalid-input" : ""}
                  placeholder="Walker"
                  size="medium"
                  name="SU_lastname"
                  required
                  value={formValues.SU_lastname}
                  onChange={handleChange}
                />
  
                <label
                // className={
                //   formErrors.lastname
                //     ? "invalid-text form-label mt-3 mb-0"
                //     : "form-label mt-3 mb-0"
                // }
                >
                  Email
                </label>
                <Small
                  type="email"
                  // errorClass={formErrors.email ? "invalid-input" : ""}
                  placeholder="jwalker@gmail.com"
                  size="medium"
                  name="SU_email"
                  value={formValues.SU_email}
                  onChange={handleChange}
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                  title="This is not a valid email format ! "
                />
  
                <button className="button-wizard" variant="primary" type="submit">
                  Add User
                </button>
              </form>
            </Modal.Body>
          </Modal> */}

          {/* <Modal
            show={showInviteModel1}
            onHide={handleShowInviteModel1Close}
            className="wizard-auth-dsgn"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3> Invite Main User (PU)</h3>
              </Modal.Title>
            </Modal.Header>
  
            <Modal.Body>
              <p className="mb-1 subtext">
                Enter the email address of the user whom you want to add as a
                Main User for you
              </p>
              <form className="invite-form" onSubmit={handleSubmit1}>
                <label
                // className={
                //   formErrors.firstname
                //     ? "invalid-text form-label mt-3 mb-0"
                //     : "form-label mt-3 mb-0"
                // }
                >
                  Firstname
                </label>
                <Small
                  type="test"
                  // errorClass={formErrors.firstname ? "invalid-input" : ""}
                  required
                  placeholder="John"
                  size="medium"
                  name="PU_firstname"
                  value={formValues1.PU_firstname}
                  onChange={handleChange1}
                />
                <label
                // className={
                //   formErrors.lastname
                //     ? "invalid-text form-label mt-3 mb-0"
                //     : "form-label mt-3 mb-0"
                // }
                >
                  Lastname
                </label>
                <Small
                  type="test"
                  // errorClass={formErrors.lastname ? "invalid-input" : ""}
                  placeholder="Walker"
                  size="medium"
                  name="PU_lastname"
                  required
                  value={formValues1.PU_lastname}
                  onChange={handleChange1}
                />
  
                <label
                // className={
                //   formErrors.lastname
                //     ? "invalid-text form-label mt-3 mb-0"
                //     : "form-label mt-3 mb-0"
                // }
                >
                  Email
                </label>
                <Small
                  type="email"
                  // errorClass={formErrors.email ? "invalid-input" : ""}
                  placeholder="jwalker@gmail.com"
                  size="medium"
                  name="PU_email"
                  value={formValues1.PU_email}
                  required
                  onChange={handleChange1}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                  title="This is not a valid email format ! "
                />
  
                <button className="button-wizard" variant="primary" type="submit">
                  Add User
                </button>
              </form>
            </Modal.Body>
          </Modal> */}

          {/* show the final json response */}
        </Container>
      </div>
    );
  } else {
    window.location.href = "/404";
  }
};

export default FamilyCreationConfirmation;
