import React from "react"
import SubscriptionCard from '../components/Cards/SubscriptionCard'

export default {
    title: 'Cards/Subscription',
    component: SubscriptionCard,
    args: {
        message: 'ActionAlert message'
    }
}

const Template = args => <SubscriptionCard {...args} />

export const ChoosePlanCard = Template.bind({})
ChoosePlanCard.args = {
    planName: "Group",
    planPrice: "449",
    planDuration: "monthly",
    benefits: "05",
    planImage: "",
    currentPlan: false,
    variant: 'basic'
}
