import React from "react";
import { Image, Card } from "react-bootstrap";
import "./Cards.scss";
import { Primary, Outline } from "../../stories/Button.stories";

function SubscriptionCard(props) {
  const {
    planName,
    planPrice,
    planDuration,
    planImage,
    benefits,
    priceSymbol,
    currentPlan,
    variant,
    buttonClick,
    planType,
    ...rest
  } = props;
  return (
    <div>
      <Card className={`story-card subscription-cards ${variant}-cards`}>
        <Card.Body className="p-1">
          <div className="d-flex justify-content-between">
            <span className="">
              <p className="subscription-txt">{planName}</p>
              <p className="subscription-txt">
                <strong>
                  {priceSymbol} {planPrice}
                </strong>
                <small>/{planDuration}</small>
              </p>
              {props.planDuration === "yearly" && props.planName === "Single" && (
                <>
                  <p style={{ margin: "5px 0" }}>
                    <del>
                      {" "}
                      <strong>£47.88</strong>
                    </del>
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    Pay yearly and save <strong>£17.89</strong>{" "}
                  </p>
                </>
              )}
              {props.planDuration === "yearly" && props.planName === "Couple" && (
                <>
                  <p style={{ margin: "5px 0" }}>
                    <del>
                      {" "}
                      <strong>£71.88</strong>
                    </del>
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    Pay yearly and save <strong>£21.89</strong>{" "}
                  </p>
                </>
              )}
              {props.planDuration === "yearly" && props.planName === "Family" && (
                <>
                  {" "}
                  <p style={{ margin: "5px 0" }}>
                    <del>
                      {" "}
                      <strong>£95.88</strong>
                    </del>
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    Pay yearly and save <strong>£25.89</strong>{" "}
                  </p>
                </>
              )}
              {/* {props.planDuration === "monthly" && (
                <p style={{ margin: "10px 0", visibility: "hidden" }}>
                  Pay yearly and save £25.89{" "}
                </p>
              )} */}
              <p className="card-rollText mx-0">Benefits</p>
              <p className="subscription-txt benefits">
                {benefits === 1 ? (
                  <span>{benefits} Main User</span>
                ) : (
                  <span>{benefits} Main Users</span>
                )}
              </p>
              <p className="subscription-txt benefits">
                {benefits === 1 ? (
                  <span>{benefits} Support User</span>
                ) : (
                  <span>{benefits} Support Users</span>
                )}
              </p>
            </span>
            <span className="action-right subscription-img">
              <Image src={planImage} />
            </span>
          </div>
          {currentPlan && (
            <span className="subscription-btn">
              <Primary
                children="Current Plan"
                variant="info"
                size="outlineMedium"
                font_Weight="600"
              />
            </span>
          )}
          {!currentPlan && (
            <span className="subscription-btn">
              {/* <Outline
                children={
                  planType
                    ? planType.length > 0
                      ? "Choose Plan"
                      : "Try free for 7 days"
                    : "Choose Plan"
                }
                variant="outline"
                size="outlineMedium"
                font_Weight="600"
                onClick={buttonClick}
              /> */}
              {planType ? (
                planType.length > 0 ? (
                  <div className="button-style" onClick={buttonClick}>
                    Choose Plan
                  </div>
                ) : (
                  <div className="button-style" onClick={buttonClick}>
                    Try free for 7 days
                  </div>
                )
              ) : (
                <div className="button-style" onClick={buttonClick}>
                  Choose Plan
                </div>
              )}
            </span>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default SubscriptionCard;
