import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import UserService from "../../services/user.service";
import moment from "moment";
import Loading from "../../common/Loading";
import layer from "../../assets/images/layer1.svg";
import Select from "react-select";
import { Grid } from "ag-grid-community";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";

const Reminders = (props) => {
  let { uuid } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [familyRemDetails, setFamilyRemDetails] = useState([]);
  const [familyRemDetails1, setFamilyRemDetails1] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [rowData1, setRowData1] = useState([]);
  const [filData, setFilData] = useState([]);
  const [filData1, setFilData1] = useState([]);
  const [filterVal, setFilterVal] = useState("all");
  const [filterVal1, setFilterVal1] = useState("all");

  // custom setting for filters
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };

  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const userType = localStorage.getItem("userType");
  const uuidDetails = localStorage.getItem("uuid");
  const userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));
  const idDetails = localStorage.getItem("id");
  const FamilyIdDetails = localStorage.getItem("FamilyId");
  const parentUserUUId = localStorage.getItem("parentUser.uuid");
  const PrimaryIdval = localStorage.getItem("PrimaryIdval");
  const [famName, setFamName] = useState("");

  useEffect(() => {
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        console.log(response);
        setFamName(response.FamilyData.Family.name);
        setTimeout(() => {
          setLoading(false);
        }, 250);
      }
    );

    const userArr = [
      { value: "all", label: "All" },
      { value: "expired", label: "Expired" },
    ];
    const primaryArr = [];
    UserService.getFamilyAllAu(uuid).then(
      (resp) => {
        console.log(resp);
        for (let i = 0; i < resp.data.length; i++) {
          userArr.push({
            value: resp.data[i].id,
            label: resp.data[i].User.email,
          });

          if (parseInt(PrimaryIdval) === resp.data[i].id) {
            primaryArr.push({
              value: resp.data[i].id,
              label: resp.data[i].User.email,
            });
            primaryArr.push({
              value: "expired",
              label: "Expired",
            });
          }
        }
      },
      (error) => {}
    );
    setFilData(userArr);
    setFilData1(primaryArr);

    if (
      parentUserUUId === null &&
      userType !== "AU" &&
      userType !== "AU + SU"
    ) {
      let params = {
        fid: FamilyIdDetails,
        fuserId: idDetails,
        valClick: filterVal,
        // offset: offset,
      };
      UserService.allReminderDetails(params).then(
        (res) => {
          console.log(res.data);
          setFamilyRemDetails(res.data);
          const array1 = [];
          for (let i = 0; i < res.data.giftcardsReminders.length; i++) {
            res.data.giftcardsReminders[i].nameType = "Gift Card";
            array1.push(res.data.giftcardsReminders[i]);
          }
          for (let i = 0; i < res.data.subscriptionReminders.length; i++) {
            res.data.subscriptionReminders[i].nameType = "Outgoings";
            array1.push(res.data.subscriptionReminders[i]);
          }
          for (let i = 0; i < res.data.todosReminders.length; i++) {
            res.data.todosReminders[i].nameType = "To Do’s";
            array1.push(res.data.todosReminders[i]);
          }
          for (let i = 0; i < res.data.vouchersReminders.length; i++) {
            res.data.vouchersReminders[i].nameType = "Voucher";
            array1.push(res.data.vouchersReminders[i]);
          }
          for (let i = 0; i < res.data.warrantiesReminders.length; i++) {
            res.data.warrantiesReminders[i].nameType = "Warranties";
            array1.push(res.data.warrantiesReminders[i]);
          }
          console.log(array1);
          const array2 = [];
          for (let i = 0; i < array1.length; i++) {
            if (array1[i].nameType !== "To Do’s") {
              array2.push({
                Type: array1[i].nameType,
                Category: array1[i].Category ? array1[i].Category.name : "--",
                Supplier: array1[i].Supplier
                  ? array1[i].Supplier.name
                  : array1[i].GiftcardsSupplier.name,
                Amount:
                  array1[i].nameType === "Outgoings"
                    ? array1[i].premiumAmount
                    : array1[i].nameType === "Warranties"
                    ? array1[i].price
                    : array1[i].totalAmount,
                DueDate:
                  array1[i].nameType === "Outgoings"
                    ? moment(array1[i].endDate).format("DD-MM-YYYY")
                    : array1[i].nameType === "Warranties"
                    ? moment(array1[i].expiryDate).format("DD-MM-YYYY")
                    : moment(array1[i].expiryDate).format("DD-MM-YYYY"),
              });
            }
          }
          console.log(array2);
          setRowData(array2);

          const array3 = [];
          for (let i = 0; i < array1.length; i++) {
            if (array1[i].nameType === "To Do’s") {
              array3.push({
                Who:
                  array1[i].Family_User.User.firstName +
                  array1[i].Family_User.User.lastName,
                What: array1[i].name,
                When: moment(array1[i].eta).format("DD-MM-YYYY"),
              });
            }
          }
          console.log(array3);
          setRowData1(array3);
        },
        (error) => {}
      );
    } else {
      let params = {
        fid: FamilyIdDetails,
        fuserId: idDetails,
        valClick: PrimaryIdval,
        // offset: offset,
      };
      UserService.allReminderDetails(params).then(
        (res) => {
          console.log(res.data);
          setFamilyRemDetails(res.data);
          const array1 = [];
          for (let i = 0; i < res.data.giftcardsReminders.length; i++) {
            res.data.giftcardsReminders[i].nameType = "Gift Card";
            array1.push(res.data.giftcardsReminders[i]);
          }
          for (let i = 0; i < res.data.subscriptionReminders.length; i++) {
            res.data.subscriptionReminders[i].nameType = "Outgoings";
            array1.push(res.data.subscriptionReminders[i]);
          }
          for (let i = 0; i < res.data.todosReminders.length; i++) {
            res.data.todosReminders[i].nameType = "To Do’s";
            array1.push(res.data.todosReminders[i]);
          }
          for (let i = 0; i < res.data.vouchersReminders.length; i++) {
            res.data.vouchersReminders[i].nameType = "Voucher";
            array1.push(res.data.vouchersReminders[i]);
          }
          for (let i = 0; i < res.data.warrantiesReminders.length; i++) {
            res.data.warrantiesReminders[i].nameType = "Warranties";
            array1.push(res.data.warrantiesReminders[i]);
          }
          console.log(array1);
          const array2 = [];
          for (let i = 0; i < array1.length; i++) {
            if (array1[i].nameType !== "To Do’s") {
              array2.push({
                Type: array1[i].nameType,
                Category: array1[i].Category ? array1[i].Category.name : "--",
                Supplier: array1[i].Supplier
                  ? array1[i].Supplier.name
                  : array1[i].GiftcardsSupplier.name,
                Amount:
                  array1[i].nameType === "Outgoings"
                    ? array1[i].premiumAmount
                    : array1[i].nameType === "Warranties"
                    ? array1[i].price
                    : array1[i].totalAmount,
                DueDate:
                  array1[i].nameType === "Outgoings"
                    ? moment(array1[i].endDate).format("DD-MM-YYYY")
                    : array1[i].nameType === "Warranties"
                    ? moment(array1[i].expiryDate).format("DD-MM-YYYY")
                    : moment(array1[i].expiryDate).format("DD-MM-YYYY"),
              });
            }
          }
          console.log(array2);
          setRowData(array2);

          const array3 = [];
          for (let i = 0; i < array1.length; i++) {
            if (array1[i].nameType === "To Do’s") {
              const abc = array1[i].Family_User.User
                ? array1[i].Family_User.User.firstName
                : array1[i].assigneeId.User.firstName;
              const def = array1[i].Family_User.User
                ? array1[i].Family_User.User.lastName
                : array1[i].assigneeId.User.lastName;
              array3.push({
                Who: abc + def,
                What: array1[i].name,
                When: moment(array1[i].eta).format("DD-MM-YYYY"),
              });
            }
          }
          console.log(array1);
          console.log(array3);
          setRowData1(array3);
        },
        (error) => {}
      );
    }
  }, []);

  //onchange filter for Product & services
  const subscriptionFilter = (e) => {
    setFilterVal(e.value);
    let params = {
      fid: FamilyIdDetails,
      fuserId: idDetails,
      valClick: e.value,
    };

    UserService.allReminderDetails(params).then(
      (res) => {
        setFamilyRemDetails(res.data);
        const array1 = [];
        for (let i = 0; i < res.data.giftcardsReminders.length; i++) {
          res.data.giftcardsReminders[i].nameType = "Gift Card";
          array1.push(res.data.giftcardsReminders[i]);
        }
        for (let i = 0; i < res.data.subscriptionReminders.length; i++) {
          res.data.subscriptionReminders[i].nameType = "Outgoings";
          array1.push(res.data.subscriptionReminders[i]);
        }
        for (let i = 0; i < res.data.todosReminders.length; i++) {
          res.data.todosReminders[i].nameType = "To Do’s";
          array1.push(res.data.todosReminders[i]);
        }
        for (let i = 0; i < res.data.vouchersReminders.length; i++) {
          res.data.vouchersReminders[i].nameType = "Voucher";
          array1.push(res.data.vouchersReminders[i]);
        }
        for (let i = 0; i < res.data.warrantiesReminders.length; i++) {
          res.data.warrantiesReminders[i].nameType = "Warranties";
          array1.push(res.data.warrantiesReminders[i]);
        }
        console.log(array1);
        const array2 = [];
        for (let i = 0; i < array1.length; i++) {
          if (array1[i].nameType !== "To Do’s") {
            array2.push({
              Type: array1[i].nameType,
              Category: array1[i].Category ? array1[i].Category.name : "--",
              Supplier: array1[i].Supplier
                ? array1[i].Supplier.name
                : array1[i].GiftcardsSupplier.name,
              Amount:
                array1[i].nameType === "Outgoings"
                  ? array1[i].premiumAmount
                  : array1[i].nameType === "Warranties"
                  ? array1[i].price
                  : array1[i].totalAmount,
              DueDate:
                array1[i].nameType === "Outgoings"
                  ? moment(array1[i].endDate).format("DD-MM-YYYY")
                  : array1[i].nameType === "Warranties"
                  ? moment(array1[i].expiryDate).format("DD-MM-YYYY")
                  : moment(array1[i].expiryDate).format("DD-MM-YYYY"),
            });
          }
        }
        console.log(array2);
        setRowData(array2);
      },
      (error) => {}
    );
  };

  //onchange filter for todos
  const subscriptionFilter1 = (e) => {
    setFilterVal1(e.value);
    let params = {
      fid: FamilyIdDetails,
      fuserId: idDetails,
      valClick: e.value,
    };

    UserService.allReminderDetails(params).then(
      (res) => {
        setFamilyRemDetails1(res.data);
        if (e.value === "all") {
          const array1 = [];
          for (let i = 0; i < res.data.todosReminders.length; i++) {
            res.data.todosReminders[i].nameType = "To Do’s";
            array1.push(res.data.todosReminders[i]);
          }
          console.log(array1);
          const array2 = [];
          for (let i = 0; i < array1.length; i++) {
            if (array1[i].nameType === "To Do’s") {
              array2.push({
                Who:
                  array1[i].Family_User.User.firstName +
                  array1[i].Family_User.User.lastName,
                What: array1[i].name,
                When: moment(array1[i].eta).format("DD-MM-YYYY"),
              });
            }
          }
          console.log(array2);
          setRowData1(array2);
        } else {
          const array1 = [];
          if (res.data.todosReminders.suData) {
            for (let i = 0; i < res.data.todosReminders.suData.length; i++) {
              res.data.todosReminders.suData[i].nameType = "To Do’s";
              array1.push(res.data.todosReminders.suData[i]);
            }
          }
          if (res.data.todosReminders.user) {
            for (let k = 0; k < res.data.todosReminders.user.length; k++) {
              res.data.todosReminders.user[k].nameType = "To Do’s";
              array1.push(res.data.todosReminders.user[k]);
            }
          }
          if (res.data.todosReminders) {
            for (let i = 0; i < res.data.todosReminders.length; i++) {
              res.data.todosReminders[i].nameType = "To Do’s";
              array1.push(res.data.todosReminders[i]);
            }
          }
          console.log(array1);
          const array2 = [];
          for (let i = 0; i < array1.length; i++) {
            if (array1[i].nameType === "To Do’s") {
              const abc = array1[i].Family_User.User
                ? array1[i].Family_User.User.firstName
                : array1[i].assigneeId.User.firstName;
              const def = array1[i].Family_User.User
                ? array1[i].Family_User.User.lastName
                : array1[i].assigneeId.User.lastName;
              array2.push({
                Who: abc + def,
                What: array1[i].name,
                When: moment(array1[i].eta).format("DD-MM-YYYY"),
              });
            }
          }
          console.log(array2);
          setRowData1(array2);
        }
      },
      (error) => {}
    );
  };

  console.log(familyRemDetails);
  console.log(familyRemDetails1);
  console.log(filterVal);
  console.log(filterVal1);

  //ag-grid - column
  const [columnDefs] = useState([
    {
      field: "Type",
      width: 150,
    },
    { field: "Category", width: 150 },
    { field: "Supplier", width: 150 },
    { field: "Amount", width: 150 },
    { field: "DueDate", width: 150, resizable: false },
  ]);

  const [columnDefs1] = useState([
    { field: "Who", width: 269.3 },
    { field: "What", width: 269.3 },
    { field: "When", width: 269.3, resizable: false },
  ]);

  const hello = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/reminder_setting/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/user_reminder_setting/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  //redirect to famDashboard
  const dashboardRed = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/family_dashboard/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  //intercom- settings-------------start--------------------
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  //intercom- settings-------------end--------------------

  return (
    <div className="page-content reminder-pg tab-btn">
      <Container fluid>
        {/* {details} */}
        {loading ? (
          <div className="loadind-page">
            <Loading />
          </div>
        ) : (
          <>
            <div className="Reminder-familyName"> {famName} group</div>
            <div
              className="Reminder-backBtn"
              onClick={() =>
                // history.push({
                //   pathname: `/family_dashboard/${data.state.state.tab}`,
                dashboardRed()
              }
            >
              {" "}
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              {parentUserUUId === null ? (
                <span>Back to Group Dashboard</span>
              ) : (
                <span>Back to Main User Dashboard</span>
              )}
            </div>
            <div className="Reminder-AllRem-Main">
              <div style={{ width: "35rem" }}>
                <div className="Reminder-AllRem">All Reminders</div>
                <div className="Reminder-AllRem-Text">
                  Track & manage all your reminders on Elderly Life platform
                  here.
                </div>
              </div>
              {parentUserUUId === null &&
              userType !== "SU" &&
              userType !== "AO" &&
              userType !== "AO + SU" ? (
                <div className="Reminder-AllRem-Btn" onClick={() => hello()}>
                  <span className="Reminder-AllRem-Btn-Txt">
                    Change reminder settings
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="Reminder-AllRem-Main-1 mb-4 d-flex align-items-center">
              <div className="Reminder-Table-1 me-3">Products & Services</div>
              {userType !== "AU" &&
              userType !== "AU + SU" &&
              userType !== "SU" &&
              !parentUserUUId ? (
                <div className="Filter-rem d-flex align-items-center">
                  <div className="pe-2">Filter by :</div>
                  <div className="flex-1">
                    <Select
                      styles={customStyles}
                      defaultValue={{ label: "All", value: "all" }}
                      options={filData}
                      onChange={subscriptionFilter}
                    />
                  </div>
                </div>
              ) : (
                <div className="Filter-rem d-flex align-items-center">
                  <div className="pe-2">Filter by :</div>
                  {console.log(filData1)}
                  <div className="flex-1">
                    <Select
                      styles={customStyles}
                      defaultValue={{
                        label: filData1[0].label,
                        value: filData1[0].value,
                      }}
                      options={filData1}
                      onChange={subscriptionFilter}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: "309px", width: "50.6rem" }}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={5}
                overlayNoRowsTemplate={
                  '<span style="padding: 10px;">You currently have no reminders</span>'
                }
                defaultColDef={{
                  editable: true,
                  enableRowGroup: true,
                  enablePivot: true,
                  enableValue: true,
                  sortable: true,
                  resizable: true,
                }}
              ></AgGridReact>
            </div>

            <div className="Reminder-AllRem-Main-1 mb-4 mt-5 d-flex align-items-center">
              <div className="Reminder-Table-1 me-3">TO DO's</div>
              {userType !== "AU" &&
              userType !== "AU + SU" &&
              userType !== "SU" &&
              !parentUserUUId ? (
                <div className="Filter-rem-2 d-flex align-items-center">
                  <div className="pe-2">Filter by :</div>
                  <div className="flex-1">
                    <Select
                      styles={customStyles}
                      defaultValue={{ label: "All", value: "all" }}
                      options={filData}
                      onChange={subscriptionFilter1}
                    />
                  </div>
                </div>
              ) : (
                <div className="Filter-rem-2 d-flex align-items-center">
                  <div className="pe-2">Filter by :</div>
                  <div className="flex-1">
                    <Select
                      styles={customStyles}
                      defaultValue={{
                        label: filData1[0].label,
                        value: filData1[0].value,
                      }}
                      options={filData1}
                      onChange={subscriptionFilter1}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: "309px", width: "50.6rem" }}
            >
              <AgGridReact
                rowData={rowData1}
                columnDefs={columnDefs1}
                pagination={true}
                paginationPageSize={5}
                overlayNoRowsTemplate={
                  '<span style="padding: 10px;">You currently have no reminders</span>'
                }
                defaultColDef={{
                  editable: true,
                  enableRowGroup: true,
                  enablePivot: true,
                  enableValue: true,
                  sortable: true,
                  resizable: true,
                }}
              >
                {/* <AgGridColumn field="Who" />
                <AgGridColumn field="What" />
                <AgGridColumn field="When" /> */}
              </AgGridReact>
            </div>
            <div style={{ "margin-top": "5rem", visibility: "hidden" }}>
              aaaaaaaaa
            </div>
          </>
        )}
      </Container>
    </div>
  );
};
export default Reminders;
