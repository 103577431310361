import React from "react";
import NotificationDetailsCard from "../components/Cards/NotificationDetailsCard";

export default {
  title: "Cards/NotificationCard",
  component: NotificationDetailsCard,
  args: {
    message: "ActionAlert message",
  },
};

const Template = (args) => <NotificationDetailsCard {...args} />;

export const NotificationDetailsCardBasic = Template.bind({});
NotificationDetailsCardBasic.args = {
  title: "Prescriptions",
  description: "Pickup my medicines in the prescription from the pharmacy",
  categoryName: "Warranties",
  date: "Aug 24, 2021",
  variant: "basic",
};
