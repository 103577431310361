import React from "react";
import ElAccountPlanCard from "../components/Cards/ElAccountPlanCard";

export default {
  title: "Cards/Subscription",
  component: ElAccountPlanCard,
  args: {
    message: "ActionAlert message",
  },
};

const Template = (args) => <ElAccountPlanCard {...args} />;

export const ElAccountPlanCrd = Template.bind({});
ElAccountPlanCrd.args = {
  planName: "Group",
  planPrice: "449",
  planDuration: "monthly",
  auSeats: "05",
  suSeats: "05",
  planImage: "",
  variant: "basic",
  familyName: "Walker's Group",
  firstLetter: "W",
};
