//(Data service)
import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import Cookies from "universal-cookie";

const cookies = new Cookies();
// const API_URL = "https://dev-api.elderlylife.co.uk/";
const API_URL = "https://devapi.elderlylife.co.uk/";
// const API_URL = "http://13.41.196.217:4000/";
// const API_URL = "http://localhost:4000/";
// const API_URL =
// "https://316d-2401-4900-1c0f-e842-cee4-99ae-dcd2-e329.in.ngrok.io/";
const STRIPE_APISecret =
  "sk_test_51JtZX7HPjiF3hUjmP2pqbDVpLtPlbBfyQdHn7DbppYUgoPTVqqF1X3zYxXvk5sMDwQFHISeZIP5nUHODaLiyGZlJ00uab37x9J";
const STRIPE_API_URL = "https://api.stripe.com/v1/";

class UserService {
  /*************************************************************************************
   * User Profile API
   ************************************************************************************/
  // get user profile
  userProfile(uuid) {
    //
    return axios
      .get(API_URL + `profile/${uuid}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  userAU(uuid) {
    //
    return axios
      .get(API_URL + `family/listau/elf-8aaaf576-ffa8-460b-9428-37a068f0dba2`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  userSU(uuid) {
    //
    return axios
      .get(API_URL + `family/listsu/${uuid}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // // True/false in the time of login
  // userProfile1(uuid) {
  //   //
  //   return axios
  //     .get(API_URL + `family/find/${uuid}`, { headers: authHeader() })
  //     .then((response) => {
  //
  //       // return response.data;
  //     });
  //   // .catch((error) => {
  //   //   let errResponse = error.response;
  //   //
  //   //   if (
  //   //     errResponse.status === 401 &&
  //   //     errResponse.data.message === "Unauthorized or Token Expired"
  //   //   ) {
  //   //     AuthService.logout(); // Token expires
  //   //   } else if (
  //   //     errResponse.status === 403 &&
  //   //     errResponse.data.message === "User not found"
  //   //   ) {
  //   //     AuthService.logout(); // Token expires
  //   //   } else if (errResponse.data.message === "No token provided") {
  //   //     AuthService.logout(); // Token expires
  //   //   } else {
  //   //     return errResponse;
  //   //   }
  //   // });
  // }
  // resend user email verification code
  resendUserEmailCode(user_uuid) {
    //
    return axios
      .get(API_URL + `resend/verify/email/${user_uuid}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // resend user sms verification code
  resendUserSMSCode(user_uuid, data) {
    //
    return axios
      .post(API_URL + `user/sendContactVerifyCode/${user_uuid}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;
        console.log(errResponse);

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // resend user sms verification code
  smscheck(user_uuid, data) {
    //
    return axios
      .post(API_URL + `user/verifyContactNumber/${user_uuid}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;
        console.log(errResponse);

        // if (
        //   errResponse.status === 401 &&
        //   errResponse.data.message === "Unauthorized or Token Expired"
        // ) {
        //   AuthService.logout(); // Token expires
        // } else if (
        //   errResponse.status === 403 &&
        //   errResponse.data.message === "User not found"
        // ) {
        //   AuthService.logout(); // Token expires
        // } else if (errResponse.data.message === "No token provided") {
        //   AuthService.logout(); // Token expires
        // } else {
        //   return errResponse;
        // }
      });
  }
  // update user profile
  updateUserProfile(uuid, postData) {
    //
    let options = { headers: authHeader() };
    let url = API_URL + `profile/update/${uuid}`;
    let body = postData;
    return axios
      .patch(url, body, options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse &&
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse &&
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse &&
          errResponse.data.message === "No token provided"
        ) {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // reset user profile
  resetUserProfilePassword(User_UUID, data) {
    return axios
      .put(API_URL + `profile/changePassword/${User_UUID}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // reset user reminder setting
  updateReminderSetting(fuuid, userUUID, data) {
    return axios
      .put(API_URL + `family/reminder/${fuuid}/${userUUID}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // check su === au relation {{localUrl}}/family/SuForAu/:FamilyUserId/:FamilyId
  updateSUAURel(fuuid) {
    return axios
      .get(API_URL + `relations/${fuuid}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //su for au data
  updateSUAUData(FamUID, FamID) {
    return axios
      .get(API_URL + `family/SuForAu/${FamUID}/${FamID}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //su for au data
  relationData(famUUId) {
    return axios
      .get(API_URL + `familyRelations/${famUUId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //{{localUrl}}/family/reminder/:familyid/:familyUserId?limit=1&offset=1
  //family/ao/reminder/1/all

  //reminder api call
  allReminderDetails(params) {
    const fid = params.fid;
    const valClick = params.valClick;
    return axios
      .get(API_URL + `family/ao/reminder/${fid}/${valClick}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //reminder api for (!ao)
  //family/reminder/user/1/2
  ReminderDetailsAuSu(params) {
    const fid = params.fid;
    const fuserId = params.fuserId;
    return axios
      .get(API_URL + `family/reminder/user/${fid}/${fuserId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  /*************************************************************************************
   * Subscription API
   ************************************************************************************/
  // get list of subscription plans
  planList() {
    return axios
      .get(API_URL + "accountPlan/list", { headers: authHeader() })
      .then((response) => {
        return response.data.AccountPlanData;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get single plan details
  singlePlanDetails(id) {
    return axios
      .get(API_URL + `product/${id}`, { headers: authHeader() })
      .then((response) => {
        return response.data.productData;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  /*************************************************************************************
   * Stripe API
   ************************************************************************************/
  // get all customer list from stripe
  getCustomerListStripe() {
    return axios({
      method: "GET",
      url: STRIPE_API_URL + "customers",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    }).then((response) => {
      return response.data.data;
    });
  }
  // get customer details on stripe
  getCustomerDetailStripe(id) {
    return axios({
      method: "GET",
      url: STRIPE_API_URL + `customers/${id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    }).then((response) => {
      return response.data;
    });
  }
  // add customer on stripe
  createCustomerStripe(data) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");
    return axios({
      method: "POST",
      url: STRIPE_API_URL + "customers",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    }).then((response) => {
      return response.data;
    });
  }
  // attach payment method to customer on stripe
  attachPaymentMethod(data, id) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `payment_methods/${id}/attach`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    }).then((response) => {
      return response.data;
    });
  }
  // attach payment method to customer on stripe
  detachPaymentMethod(paymentMethodId) {
    return axios({
      method: "POST",
      url: STRIPE_API_URL + `payment_methods/${paymentMethodId}/detach`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: {},
    }).then((response) => {
      return response.data;
    });
  }
  // update customer on stripe
  updateCustomerStripe(data, id) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `customers/${id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    }).then((response) => {
      return response.data;
    });
  }
  // create customer subscription on stripe
  createSubsciptionStripe(data) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + "subscriptions",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    }).then((response) => {
      return response.data;
    });
  }

  //// get customer subscription on stripe
  getSubsciptionStripe(subscriptionId) {
    return axios({
      method: "GET",
      url: STRIPE_API_URL + `subscriptions/${subscriptionId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  // update customer subscription on stripe
  updateSubsciptionStripe(data, subscriptionId) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `subscriptions/${subscriptionId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  // create subscription schedules on stripe
  createSubsciptionScheduleStripe(data) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `subscription_schedules`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  // update subscription schedules on stripe
  updateSubsciptionScheduleStripe(data, subscriptionId) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `subscription_schedules/${subscriptionId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  // get subscription schedules on stripe
  getSubsciptionScheduleStripe(subscriptionId) {
    return axios({
      method: "GET",
      url: STRIPE_API_URL + `subscription_schedules/${subscriptionId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  //update subscription Items
  updateSubItemStripe(data, PriceId) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `subscription_items/${PriceId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  //get invoice line Items
  createInvoiceLines(data) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");
    return axios({
      method: "POST",
      url: STRIPE_API_URL + `invoices`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  //update invoice line Items
  updateInvoiceLines(data, invoiceId) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `invoiceitems/${invoiceId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  //get Invoice item
  getInvoiceStripe(invoiceId) {
    return axios({
      method: "GET",
      url: STRIPE_API_URL + `invoices/${invoiceId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  //update Invoice item
  updateInvoiceStripe(invoiceId, data) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `invoices/${invoiceId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  // Cancel Subscription
  cancelSubscriptionStripe(subscriptionId, data) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");
    return axios({
      method: "DELETE",
      url: STRIPE_API_URL + `subscriptions/${subscriptionId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  // Create Refund Subscription
  createRefundStripe(data) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");

    return axios({
      method: "POST",
      url: STRIPE_API_URL + `refunds `,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
      data: dataBody,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  //list of prices
  getProductPrices() {
    return axios({
      method: "GET",
      url: STRIPE_API_URL + "prices",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    }).then((response) => {
      return response.data.data;
    });
  }
  // list of customer invoices
  getCustomerInvoiceList(custId, subscription, startDate) {
    return axios({
      method: "GET",
      url:
        STRIPE_API_URL +
        `invoices?customer=${custId}&subscription=${subscription}&created.gte${startDate}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    }).then((response) => {
      return response.data.data;
    });
  }
  // list of customer invoices
  getCustomerSubscriptionsList(custId, startDate) {
    return axios({
      method: "GET",
      url:
        STRIPE_API_URL +
        `subscriptions?customer=${custId}&created.gte${startDate}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    }).then((response) => {
      return response.data.data;
    });
  }
  //List of customer's payment methods
  getCustomerPaymentMethodsList(data, cusId) {
    var dataBody = [];
    for (var customer in data) {
      var encodedKey = encodeURIComponent(customer);
      var encodedValue = encodeURIComponent(data[customer]);
      dataBody.push(encodedKey + "=" + encodedValue);
    }
    dataBody = dataBody.join("&");
    return axios({
      method: "GET",
      url: STRIPE_API_URL + `customers/${cusId}/payment_methods?${dataBody}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    }).then((response) => {
      return response.data;
    });
  }
  //customer's default payment method
  getCustomerDefaultPaymentMethod(paymentMethodId) {
    return axios({
      method: "GET",
      url: STRIPE_API_URL + `payment_methods/${paymentMethodId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_APISecret}`,
      },
    }).then((response) => {
      return response.data;
    });
  }
  /*************************************************************************************
   * Family API
   ************************************************************************************/
  //Get family list using logged in user UUID
  getUserFamily(uuid) {
    return axios
      .get(API_URL + `family/findByUser/${uuid}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  getUserFamily1(uuid) {
    return axios
      .get(API_URL + `family/listFamilyUsers`, { headers: authHeader() })
      .then((response) => {})
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //bulk removal
  bulkRemoval(uuid, auArray, suArray) {
    let data = { au_ids: auArray, su_ids: suArray };

    return axios
      .put(API_URL + `family/remove/bulkUser/${uuid}`, data, {
        headers: authHeader(),
      })
      .then((response) => {})
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // create family
  createFamily(data) {
    return axios
      .post(API_URL + "family/create", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get single family details using family UUID
  getUserSingleFamilyDetails(uuid, userId) {
    return axios
      .get(API_URL + `family/${uuid}/${userId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // invite Au user
  sendFamilyInvite(data) {
    return axios
      .post(API_URL + "family/invite", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // invite Su user
  sendFamilySuInvite(data) {
    return axios
      .post(API_URL + "family/inviteSU", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // update family name
  updateFamilyName(fid, data) {
    return axios
      .put(API_URL + `family/update/${fid}`, data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // invite Ao as Au user
  sendFamilyInviteToAoAsAu(uuid, data) {
    return axios
      .post(API_URL + `family/inviteaoasau/${uuid}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // invite Ao as Su user
  sendFamilyInviteToAoAsSu(uuid, userId, data) {
    return axios
      .post(API_URL + `family/inviteaoassu/${uuid}/${userId}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // invite Su from family
  sendFamilyInviteToSufromfamily(data) {
    return axios
      .post(API_URL + `family/invitesufromfamily`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  //get list of invites logged in user(AO) send to others.
  getFamilyInviteList(uuid, sortby) {
    return axios
      .get(API_URL + `family/familyinvites/${uuid}?sortBy=${sortby}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  //get list of Su in family.
  getFamilyAllSu(uuid) {
    return axios
      .get(API_URL + `family/listsu/${uuid}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  //get list of Au in family.
  getFamilyAllAu(uuid) {
    return axios
      .get(API_URL + `family/listau/${uuid}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  //get list of invites Logged in user got from others.
  getUserFamilyInvite(uuid) {
    return axios
      .get(API_URL + `family/invites/${uuid}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // update invite status - AU
  updateFamilyInviteStatus(id, data) {
    return axios
      .put(API_URL + `family/invite/${id}`, data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // revoke invite status - AU
  revokeFamilyInviteStatus(data) {
    return axios
      .put(API_URL + `family/familyinvite`, data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // revoke invite status in PU
  revokeFamilyInviteStatusForPU(data) {
    return axios
      .put(API_URL + `family/familyinvite/su`, data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // update invite status - SU
  update_SU_FamilyInviteStatus(id, data) {
    return axios
      .put(API_URL + `family/invitesu/${id}`, data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // resend invite - AU
  resendFamilyInvite(data, fuuid) {
    return axios
      .put(API_URL + `family/resendInvite/${fuuid}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get list of AU or SU in a particular family
  getOnBoardedFamilyUserList(uuid, userId, data) {
    return axios
      .get(API_URL + `family/userdata/${uuid}/${userId}?userType=${data}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get list of invite history of SU
  getInviteHistoryOfSU(uuid, userId, data) {
    return axios
      .get(API_URL + `family/sulist/${uuid}/${userId}?filter=${data}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // upgrade Family Subscription
  upgradeSubscription(familyUUID, planId, SubscriptionId) {
    let data = { planId: planId, stripeSubscriptionId: SubscriptionId };
    return axios
      .put(API_URL + `family/upgrade/${familyUUID}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // downgrade Family Subscription
  downgradeSubscription(familyUUID, planId, SubscriptionId) {
    let data = { planId: planId, stripeSubscriptionId: SubscriptionId };
    return axios
      .put(API_URL + `family/downgrade/${familyUUID}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // Cancel Family Subscription
  cancelSubscription(familyUUID) {
    return axios
      .put(
        API_URL + `family/cancel/${familyUUID}`,
        {},
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // remove users from Family - AO
  removeFamilyUser(familyUUID, auUserList, suUserList) {
    let data = { auUserIds: auUserList, suUserIds: suUserList };
    return axios
      .put(API_URL + `family/user/remove/${familyUUID}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // remove Su users from AU family
  removeSUFromAuFamily(familyUUID, puUserId, suUserId) {
    let data = { puid: puUserId, suid: suUserId };
    return axios
      .put(
        API_URL +
          `family/removeSu/fromAu/${familyUUID}/${puUserId}/${suUserId}`,
        data,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  //Get family list using logged in user UUID
  getUserAccountPlan(uuid) {
    return axios
      .get(API_URL + `family/plan/find/${uuid}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  //Get family notification list using logged in user UUID
  getUserFamilyNotification(fuuid, userId) {
    return axios
      .get(API_URL + `family/notifications/${fuuid}/${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //   ALL :    {{localUrl}}/family/notifications/elf-d2c9f2fc-3f60-464d-bcee-f44e4b9f8305/2?limit=3&offset=0&filter=
  // ANY FILTER :    {{localUrl}}/family/notifications/elf-d2c9f2fc-3f60-464d-bcee-f44e4b9f8305/2?limit=3&offset=0&filter=Elderly Life (edited)
  //Get family notification list using logged in user UUID
  //all
  getUserFamilyNotification1(params) {
    const fuuid = params.fuuid;
    const userId = params.userId;
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(
        API_URL +
          `family/notifications/${fuuid}/${userId}?limit=${limit}&offset=${offset}&filter=`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //filter by all
  getUserFamilyNotification2(params) {
    const fuuid = params.fuuid;
    const userId = params.userId;
    const filterVal = params.filterVal;
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(
        API_URL +
          `family/notifications/${fuuid}/${userId}?limit=${limit}&offset=${offset}&filter=${filterVal}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //sort filter
  getUserFamilyNotification3(params) {
    const fuuid = params.fuuid;
    const userId = params.userId;
    const filterVal = params.filterVal;
    const sortVal = params.sortVal;
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(
        API_URL +
          `family/notifications/${fuuid}/${userId}?limit=${limit}&offset=${offset}&filter=${filterVal}&sort=${sortVal}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  /*************************************************************************************
   * Category API  : 1- subscription, 2- warranties, 3-Giftcard
   ************************************************************************************/
  // get category listing by module(subscription/wrrantiese etc)
  getCategoryByModule(id) {
    return axios
      .get(API_URL + `categoryByModule/${id}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get subcategory listing by category (subscription/wrrantiese etc)
  getSubcategoryByCat(id) {
    return axios
      .get(API_URL + `subcategoryByCat/${id}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get supplier listing by subcategory (subscription/wrrantiese etc)
  getSuppliersBySubCategory(id) {
    return axios
      .get(API_URL + `suppliersBySubCategory/${id}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get all supplier listing
  getSuppliersList() {
    return axios
      .get(API_URL + `suppliers`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  /*************************************************************************************
   * Compare and Save API
   ************************************************************************************/
  // get listing of save and compare
  getSaveCompareList() {
    return axios
      .get(API_URL + `save-compare`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  getSaveCompareListNew(params) {
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(API_URL + `save-compare?limit=${limit}&offset=${offset}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  /*************************************************************************************
   * Benefits
   ************************************************************************************/
  // get listing of benefit categories
  getBenefitCategoryList() {
    return axios
      .get(API_URL + `benefit-category`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get listing of benefit items
  getBenefitItemList(params) {
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(API_URL + `benefit-items?limit=${limit}&offset=${offset}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get listing of benefit items by category
  getBenefitItemListByCategory(params) {
    const limit = params.limit;
    const offset = params.offset;
    const id = params.id;
    return axios
      .get(
        API_URL +
          `benefit-items/byCategory/${id}?limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // search benefit items
  getBenefitItemSearchList(params) {
    const limit = params.limit;
    const offset = params.offset;
    const searchTxt = params.searchTxt;
    return axios
      .get(
        API_URL +
          `benefits/itemsearch/?search=${searchTxt}&limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get top 20 listing of benefit items
  getTop20BenefitItemList(params) {
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(
        API_URL + `benefit-items/top20/list?limit=${limit}&offset=${offset}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  /*************************************************************************************
   * Upload user document on S3 bucket
   ************************************************************************************/
  uploadUserDocuments(formData) {
    const userTokenVal = cookies.get("accessToken");
    let headers = {
      "x-access-token": userTokenVal,
      "Content-Type": "multipart/form-data",
    };
    return axios({
      method: "POST",
      url: API_URL + "user/fileupload",
      headers: headers,
      data: formData,
    }).then((response) => {
      return response.data;
    });
  }

  /*************************************************************************************
   * Get stats of a user
   ************************************************************************************/
  getUserStats(fmlyId, userId) {
    return axios
      .get(API_URL + `stats/family/${fmlyId}/${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  getUserStatsInOut(fmlyId, userId) {
    return axios
      .get(API_URL + `warranties/status/count/${userId}/${fmlyId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  /*************************************************************************************
   * SUBSCRIPTION
   ************************************************************************************/
  // create family subscription
  createSubscritpion(data) {
    return axios
      .post(API_URL + "subscriptions", data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // update family subscription
  updateSubscritpion(data, subId) {
    return axios
      .put(API_URL + `subscriptions/${subId}`, data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //subscriptions/filter/3/3/Monthly?search=D&limit=10&offset=0&sortBy=&orderBy=

  // get subscription list of a user
  getUserSubscriptionList(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const searchStr = params.searchStr;
    const sortBy = params.sort;
    const orderBy = params.order;
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(
        API_URL +
          `subscriptions/${userId}/${FamilyId}/?search=${searchStr}&sortBy=${sortBy}&orderBy=${orderBy}&limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get subscription list of a user
  getUserSubscriptionListNew(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const searchStr = params.searchStr;
    const sortBy = params.sort;
    const orderBy = params.order;
    const limit = params.limit;
    const offset = params.offset;
    const filtBy = params.filtBy;
    return axios
      .get(
        API_URL +
          `subscriptions/filter/${userId}/${FamilyId}/${filtBy}?search=${searchStr}&limit=${limit}&offset=${offset}&sortBy=${sortBy}&orderBy=${orderBy}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //  // get subscription list count
  getUStats1(userId, fmlyId) {
    return axios
      .get(API_URL + `subscriptions/countFrequency/${userId}/${fmlyId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get subscription details by id
  getSubscriptionDetailsById(sub_id) {
    return axios
      .get(API_URL + `subscriptions/user/${sub_id}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get filter subscription list of a user
  getFilterUserSubscriptionList(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const filter = params.filter;
    return axios
      .get(API_URL + `subscriptions/filter/${userId}/${FamilyId}/${filter}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // delete subscription of a user
  deleteSubscription(subscriptionId) {
    return axios
      .delete(API_URL + `subscriptions/${subscriptionId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // cancel subscription reminder of a user
  cancelReminderSubscription(subscriptionId, reminder) {
    let params = { reminder: reminder };
    return axios
      .put(API_URL + `subscriptions/reminder/${subscriptionId}`, params, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  /*************************************************************************************
   * WARRANTY
   ************************************************************************************/
  // create family warranties
  createWarranty(data) {
    return axios
      .post(API_URL + "warranties", data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // update family subscription
  updateWarranty(data, war_id) {
    return axios
      .put(API_URL + `warranties/${war_id}`, data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get warranties list of a user
  getUserWarrantyList(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const searchStr = params.searchStr;
    const sortBy = params.sort;
    const orderBy = params.order;
    const limit = params.limit;
    const offset = params.offset;

    return axios
      .get(
        API_URL +
          `warranties/${userId}/${FamilyId}/?search=${searchStr}&sortBy=${sortBy}&orderBy=${orderBy}&limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // /warranties/filter/2/1/all?search=&limit=&offset=&sortBy=&orderBy=
  getUserWarrantyListNew(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const searchStr = params.searchStr;
    const sortBy = params.sort;
    const orderBy = params.order;
    const limit = params.limit;
    const offset = params.offset;
    const filtBy = params.filtBy;
    const status = params.status;

    return axios
      .get(
        API_URL +
          `warranties/filter/${userId}/${FamilyId}/${filtBy}?search=${searchStr}&limit=${limit}&offset=${offset}&sortBy=${sortBy}&orderBy=${orderBy}&status=${status}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get warranty details by id
  getWarrantyDetailsById(war_id) {
    return axios
      .get(API_URL + `warranties/${war_id}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get filter warranty list of a user
  getFilterUserWarrantyList(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const filter = params.filter;
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(
        API_URL +
          `warranties/filter/${userId}/${FamilyId}/${filter}?limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // In warrenty & out warrenty api call
  // get filter warranty list of a user
  getFilterUserWarrantyList1(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const limit = params.limit;
    const offset = params.offset;
    const WarrantyVal = params.WarrantyVal;

    return axios
      .get(
        API_URL +
          `warranties/filterbyvalue/${userId}/${FamilyId}/?status=${WarrantyVal}&limit=${limit}&offset=${offset}`,
        //`warranties/filterbyvalue/${userId}/${FamilyId}/?status=In Warranty&limit=10&offset=0`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  getFilterUserWarrantyList2(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const limit = params.limit;
    const offset = params.offset;

    return axios
      .get(
        API_URL +
          `warranties/filterbyvalue/${userId}/${FamilyId}/?status=Out Warranty&limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // cancel warranty reminder of a user
  cancelReminderWarranty(warrantyId, reminder) {
    let params = { reminder: reminder };
    return axios
      .put(API_URL + `warranties/reminder/${warrantyId}`, params, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // delete subscription of a user
  deleteWarranty(warrantyId) {
    return axios
      .delete(API_URL + `warranties/${warrantyId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //giftcards/filter/3/3/active?search=&sortBy=&orderBy=&limit=10&offset=0

  //gift card new PI
  getUserGiftcardListNew(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const searchStr = params.searchStr;
    const sortBy = params.sort;
    const orderBy = params.order;
    const limit = params.limit;
    const offset = params.offset;
    const filBy = params.filBy;
    return axios
      .get(
        API_URL +
          `giftcards/filter/${userId}/${FamilyId}/${filBy}?search=${searchStr}&sortBy=${sortBy}&orderBy=${orderBy}&limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  /************************************************************************************
   * GIFTCARD
   ************************************************************************************/
  // get giftcard list of a user
  getUserGiftcardList(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const searchStr = params.searchStr;
    const sortBy = params.sort;
    const orderBy = params.order;
    const limit = params.limit;
    const offset = params.offset;
    return axios
      .get(
        API_URL +
          `giftcards/${userId}/${FamilyId}/?search=${searchStr}&sortBy=${sortBy}&orderBy=${orderBy}&limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //moreGiftcard
  getUserGiftcardListMore(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const searchStr = params.searchStr;
    const sortBy = params.sort;
    const orderBy = params.order;
    const limit = params.limit;
    const offset = params.offset;
    const filterVal = params.filterVal;
    return axios
      .get(
        API_URL +
          `giftcards/filter/${userId}/${FamilyId}/${filterVal}/?search=${searchStr}&sortBy=${sortBy}&orderBy=${orderBy}&limit=${limit}&offset=${offset}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // count in giftcard
  getUserGiftcardCount(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;

    return axios
      .get(API_URL + `giftcards/types/count/${userId}/${FamilyId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get gift card supplier listing
  getGiftcardSuppliersList() {
    return axios
      .get(API_URL + `giftcardsuppliers`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // create gift card
  createGiftcard(data) {
    return axios
      .post(API_URL + "giftcards", data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // update family subscription
  updateGiftcard(data, giftId) {
    return axios
      .put(API_URL + `giftcards/${giftId}`, data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get giftcard details by id
  getGiftcardDetailsById(giftId) {
    return axios
      .get(API_URL + `giftcards/${giftId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get filter giftcard list of a user
  getFilterUserGiftcardList(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const filter = params.filter;
    return axios
      .get(API_URL + `giftcards/filter/${userId}/${FamilyId}/${filter}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // delete giftcard of a user
  deleteGiftcard(giftCardId) {
    return axios
      .delete(API_URL + `giftcards/${giftCardId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // cancel giftcard reminder of a user
  cancelReminderGiftcard(giftCardId, reminder) {
    let params = { reminder: reminder };
    return axios
      .put(API_URL + `giftcards/reminder/${giftCardId}`, params, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  /*************************************************************************************
   * Task
   ************************************************************************************/
  // create family task
  createTask(data) {
    return axios
      .post(API_URL + "tasks", data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get task list of a user
  getUserTaskList(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const searchStr = params.searchStr;
    const sortBy = params.sort;
    const orderBy = params.order;
    const limit = params.limit;
    const offset = params.offset;
    const freq = params.freq;
    const statusVal = params.statusVal;
    const req = params.req;
    return (
      axios
        //tasks/searchSorting/:uid/:fid/?search=mango&sortBy=Expired&orderBy=DESC&limit=1&offset=0
        //search=${searchStr}&sortBy=${sortBy}&orderBy=${orderBy}&limit=${limit}&offset=${offset}
        .get(
          API_URL +
            `tasks/byUser/${FamilyId}/${userId}?limit=${limit}&offset=${offset}&sortBy=${sortBy}&orderBy=${orderBy}&search=${searchStr}&frequency=${freq}&status=${statusVal}&recurrence=${req}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          let errResponse = error.response;

          if (
            errResponse.status === 401 &&
            errResponse.data.message === "Unauthorized or Token Expired"
          ) {
            AuthService.logout(); // Token expires
          } else if (
            errResponse.status === 403 &&
            errResponse.data.message === "User not found"
          ) {
            AuthService.logout(); // Token expires
          } else if (errResponse.data.message === "No token provided") {
            AuthService.logout(); // Token expires
          } else {
            return errResponse;
          }
        })
    );
  }

  // get only all task list of a user
  getUserTaskList1(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const limit = params.limit;
    const offset = params.offset;
    const sort = params.sort;
    const order = params.order;
    return (
      axios
        //tasks/searchSorting/:uid/:fid/?search=mango&sortBy=Expired&orderBy=DESC&limit=1&offset=0
        //search=${searchStr}&sortBy=${sortBy}&orderBy=${orderBy}&limit=${limit}&offset=${offset}
        .get(
          API_URL +
            `tasks/byUser/${FamilyId}/${userId}?limit=${limit}&offset=${offset}&sortBy=${sort}&orderBy=${order}&search=`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          let errResponse = error.response;

          if (
            errResponse.status === 401 &&
            errResponse.data.message === "Unauthorized or Token Expired"
          ) {
            AuthService.logout(); // Token expires
          } else if (
            errResponse.status === 403 &&
            errResponse.data.message === "User not found"
          ) {
            AuthService.logout(); // Token expires
          } else if (errResponse.data.message === "No token provided") {
            AuthService.logout(); // Token expires
          } else {
            return errResponse;
          }
        })
    );
  }

  // delete task of a user
  deleteTask(taskId) {
    return axios
      .delete(API_URL + `tasks/${taskId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // get task details by id
  getTaskDetailsById(task_id) {
    return axios
      .get(API_URL + `tasks/${task_id}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  //new task
  getTaskDetailsByParentId(pid, fuid, fid) {
    return axios
      .get(API_URL + `tasks/child/${pid}/${fuid}/${fid}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // update family task
  updateTask(data, taskId) {
    return axios
      .put(API_URL + `tasks/${taskId}`, data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
  // mark task as complete
  updateTaskAsComplete(data, taskId) {
    return axios
      .put(API_URL + `tasks/status/${taskId}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // get filter task list of a user
  getFilterUserTaskList(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;
    const filterPrm = params.filterParam;
    const filterVal = params.filterVal;
    const limit = params.limit;
    const offset = params.offset;
    return (
      axios
        // API_URL + `tasks/filter/${userId}/${FamilyId}/${filter}`
        .get(
          API_URL +
            `tasks/withFilters/${userId}/${FamilyId}/?${filterPrm}=${filterVal}&limit=${limit}&offset=${offset}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          let errResponse = error.response;

          if (
            errResponse.status === 401 &&
            errResponse.data.message === "Unauthorized or Token Expired"
          ) {
            AuthService.logout(); // Token expires
          } else if (
            errResponse.status === 403 &&
            errResponse.data.message === "User not found"
          ) {
            AuthService.logout(); // Token expires
          } else if (errResponse.data.message === "No token provided") {
            AuthService.logout(); // Token expires
          } else {
            return errResponse;
          }
        })
    );
  }
  // get status in task
  getFilterUserTaskList1(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;

    const filterVal = params.filterVal;
    const limit = params.limit;
    const offset = params.offset;
    return (
      axios
        // API_URL + `tasks/filter/${userId}/${FamilyId}/${filter}`
        .get(
          API_URL +
            `tasks/withFilters/${userId}/${FamilyId}/?status=${filterVal}&limit=${limit}&offset=${offset}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          let errResponse = error.response;

          if (
            errResponse.status === 401 &&
            errResponse.data.message === "Unauthorized or Token Expired"
          ) {
            AuthService.logout(); // Token expires
          } else if (
            errResponse.status === 403 &&
            errResponse.data.message === "User not found"
          ) {
            AuthService.logout(); // Token expires
          } else if (errResponse.data.message === "No token provided") {
            AuthService.logout(); // Token expires
          } else {
            return errResponse;
          }
        })
    );
  }
  // get recurrance  in task
  getFilterUserTaskList2(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;

    const filterVal = params.filterVal;
    const limit = params.limit;
    const offset = params.offset;
    return (
      axios
        // API_URL + `tasks/filter/${userId}/${FamilyId}/${filter}`
        .get(
          API_URL +
            `tasks/withFilters/${userId}/${FamilyId}/?recurrence=${filterVal}&limit=${limit}&offset=${offset}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          let errResponse = error.response;

          if (
            errResponse.status === 401 &&
            errResponse.data.message === "Unauthorized or Token Expired"
          ) {
            AuthService.logout(); // Token expires
          } else if (
            errResponse.status === 403 &&
            errResponse.data.message === "User not found"
          ) {
            AuthService.logout(); // Token expires
          } else if (errResponse.data.message === "No token provided") {
            AuthService.logout(); // Token expires
          } else {
            return errResponse;
          }
        })
    );
  }
  // get frequency  in task
  getFilterUserTaskList3(params) {
    const userId = params.userId;
    const FamilyId = params.familyId;

    const filterVal = params.filterVal;
    const limit = params.limit;
    const offset = params.offset;
    return (
      axios
        // API_URL + `tasks/filter/${userId}/${FamilyId}/${filter}`
        .get(
          API_URL +
            `tasks/withFilters/${userId}/${FamilyId}/?frequency=${filterVal}&limit=${limit}&offset=${offset}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          let errResponse = error.response;

          if (
            errResponse.status === 401 &&
            errResponse.data.message === "Unauthorized or Token Expired"
          ) {
            AuthService.logout(); // Token expires
          } else if (
            errResponse.status === 403 &&
            errResponse.data.message === "User not found"
          ) {
            AuthService.logout(); // Token expires
          } else if (errResponse.data.message === "No token provided") {
            AuthService.logout(); // Token expires
          } else {
            return errResponse;
          }
        })
    );
  }

  //wizard api call
  createWizard(data) {
    return axios
      .post(API_URL + "family/wizard/new", data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  cancelFamilyPlan(uuid) {
    return axios
      .put(
        API_URL + `family/cancel/${uuid}`,
        { credentials: "include" },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // let errResponse = error.response;
        // if (
        //   errResponse.status === 401 &&
        //   errResponse.data.message === "Unauthorized or Token Expired"
        // ) {
        //   AuthService.logout(); // Token expires
        // } else if (
        //   errResponse.status === 403 &&
        //   errResponse.data.message === "User not found"
        // ) {
        //   AuthService.logout(); // Token expires
        // } else if (errResponse.data.message === "No token provided") {
        //   AuthService.logout(); // Token expires
        // } else {
        //   return errResponse;
        // }
      });
  }

  // view invoice
  getInvoiceDoc(invoiceurl) {
    return axios
      .get(invoiceurl, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // reason cancellation family
  reasonCancelFamily(uuid, data) {
    return axios
      .put(API_URL + `family/update/${uuid}`, data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // main user all onboarded & invited
  onboardedUserAU(uuid, userID, type) {
    return axios
      .get(API_URL + `family/user/alldata/${uuid}/${userID}?userType=${type}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }

  // check main for support user
  checkMainUser(uuid, userID) {
    return axios
      .get(API_URL + `family/nonsupport/${uuid}/${userID}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errResponse = error.response;

        if (
          errResponse.status === 401 &&
          errResponse.data.message === "Unauthorized or Token Expired"
        ) {
          AuthService.logout(); // Token expires
        } else if (
          errResponse.status === 403 &&
          errResponse.data.message === "User not found"
        ) {
          AuthService.logout(); // Token expires
        } else if (errResponse.data.message === "No token provided") {
          AuthService.logout(); // Token expires
        } else {
          return errResponse;
        }
      });
  }
}

export default new UserService();
