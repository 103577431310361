import React from "react";
import ActionAlert from "../components/Alert/ActionAlert";

export default {
  title: "Notification/Alert with button",
  component: ActionAlert,
  args: {
    message: "ActionAlert message",
  },
};

const Template = (args) => <ActionAlert {...args} />;

export const Info = Template.bind({});
Info.args = {
  message:
    "Your email is not verified yet. Please verify your email to continue using our services",
  variant: "info",
};

export const Sms = Template.bind({});
Sms.args = {
  message:
    "Your contact number is not verified yet. Please verify your contact number to continue using our services",
  variant: "sms",
};
