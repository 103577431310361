import React from "react"
import CountCard from '../components/Cards/CountCard'

export default {
    title: 'Cards/Count',
    component: CountCard,
    args: {
        message: 'ActionAlert message',
       
    },
}

const Template = args => <CountCard {...args} />

export const NumberCard = Template.bind({})
NumberCard.args = {
    heading: 'Total Items',
    value: '10',
    variant: 'count'
}
