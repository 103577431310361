import React from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";
import notFoundIcon from "../../assets/images/404.png";

const NotFound = () => {
  return (
    <div className="page-content view-warranty-pg">
      <Container>
        <div className="not-found">
          <Image src={notFoundIcon} alt="Not found" className="text-center" />
          <div className="m-4 text-center">
            Oops! something went wrong. Please return to dashboard
          </div>
          <Link to="/dashboard">
            <Primary
              children="Go to Dashboard"
              size="largebtn"
              variant="primary"
              type=""
              buttonWidth="300px"
              font_Weight="500"
            />
          </Link>
        </div>
      </Container>
    </div>
  );
};
export default NotFound;
