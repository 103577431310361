import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";
import { Link, useHistory } from "react-router-dom";
import CheckGreen from "../../assets/images/check-green.svg";

const FamilyUpdateConfirmation = (props) => {
  let history = useHistory();
  const routeChange = () => {
    history.push({
      pathname: `/family_dashboard/${props.location.familyUUID}`,
    });
  };
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <div className="page-content confirmation-pg">
      <Container>
        <div className="text-center confrm-div">
          <h3>Congratulations !!</h3>
          <Image
            className="hello-text-img my-4"
            src={CheckGreen}
            alt="Check mark"
            width="18%"
          />
          <p>
            Your Family has been updated successfully with the selected
            subscription
          </p>
          <Primary
            children="Visit your Family page"
            size="mediumbtn"
            type=""
            variant="primary"
            buttonWidth="45%"
            onClick={routeChange}
          />
        </div>
      </Container>
    </div>
  );
};
export default FamilyUpdateConfirmation;
