import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import { Category } from "../../stories/CategoryCard.stories";
import Toast from "react-bootstrap/Toast";
import SuccessCheck from "../../assets/images/successCheck.svg";
import SubscriptionsRoundIcon from "../../assets/images/subscriptions_round_icon.svg";
import TasksRoundIcon from "../../assets/images/Tasks_round_icon.svg";
import WarrantiesRoundIcon from "../../assets/images/Warranties_round_icon.svg";
import Loading from "../../common/Loading";
import GiftcardsRoundIcon from "../../assets/images/Giftcards_round_icon.svg";
import moment from "moment";
import notif from "../../assets/images/notification.svg";
import setting from "../../assets/images/settings (4).svg";

const SupportingUserDashboard = (props) => {
  let history = useHistory();
  const splitUrl = window.location.href.split("/");
  const myRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [familyRemDetails1, setFamilyRemDetails1] = useState([]);
  var initialFamilyDetails = props.location.singleFamilyDetails
    ? props.location.singleFamilyDetails
    : [];
  const [, setFamilyDetails] = useState(initialFamilyDetails);
  const [, setFamilyNotificationList] = useState([]);
  let { uuid, userUUID } = useParams();
  console.log(uuid);
  console.log(userUUID);
  const [showToast, setShowToast] = useState(false);
  const [subscriptionsCount, setSubscriptionsCount] = useState("0");
  const [warrantiesCount, setWarrantiesCount] = useState("0");
  const [giftCardsCount, setGiftCardsCount] = useState("0");
  const [tasksCount, setTasksCount] = useState("0");
  const [parentUser, setParentUser] = useState("");
  const [userDetails, setuserDetails] = useState({});
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  localStorage.setItem("parentUser.uuid", parentUser.uuid);

  console.log(splitUrl);
  // Get Stats
  const getStats = (data) => {
    UserService.getUserStats(
      data.FamilyData.Family.id,
      data.FamilyData.id
    ).then(
      (response) => {
        if (response.data.count > 0) {
          let res = response.data.rows[0];
          setSubscriptionsCount(res.totalSubscriptions);
          setWarrantiesCount(res.totalWarranties);
          setGiftCardsCount(res.totalGiftCards);
          setTasksCount(res.totalTasks);
        }
      },
      (error) => {}
    );
  };
  useEffect(() => {
    setLoading(true);
    // get user profile details
    UserService.userProfile(userUUID).then(
      (res) => {
        console.log(res);
        setParentUser(res.userData);
        localStorage.setItem("userUUID", JSON.stringify(res.userData.uuid));

        // get family details
        UserService.getUserSingleFamilyDetails(uuid, res.userData.id).then(
          (response) => {
            console.log(response);
            localStorage.setItem(
              "userUUIDIdVal",
              JSON.stringify(response.FamilyData.id)
            );

            localStorage.setItem("PrimaryIdval", response.FamilyData.id);
            setFamilyDetails(response);
            getStats(response);
            getNotifications(response.FamilyData);

            // get notification reminder api
            let params = {
              fid: response.FamilyData.FamilyId,
              valClick: response.FamilyData.id,
            };
            UserService.allReminderDetails(params).then(
              (res) => {
                console.log(res.data);
                const array2 = [];
                for (let i = 0; i < res.data.giftcardsReminders.length; i++) {
                  res.data.giftcardsReminders[i].nameType = "Gift Cards";
                  res.data.giftcardsReminders[i].expiredDateVal = new Date(
                    res.data.giftcardsReminders[i].expiryDate
                  );
                  array2.push(res.data.giftcardsReminders[i]);
                }
                for (
                  let i = 0;
                  i < res.data.subscriptionReminders.length;
                  i++
                ) {
                  res.data.subscriptionReminders[i].nameType = "Outgoings";
                  res.data.subscriptionReminders[i].expiredDateVal = new Date(
                    res.data.subscriptionReminders[i].endDate
                  );
                  array2.push(res.data.subscriptionReminders[i]);
                }
                for (let i = 0; i < res.data.todosReminders.length; i++) {
                  res.data.todosReminders[i].nameType = "To Do’s";
                  res.data.todosReminders[i].expiredDateVal = new Date(
                    res.data.todosReminders[i].eta
                  );
                  array2.push(res.data.todosReminders[i]);
                }
                for (let i = 0; i < res.data.vouchersReminders.length; i++) {
                  res.data.vouchersReminders[i].nameType = "Vouchers";
                  res.data.vouchersReminders[i].expiredDateVal = new Date(
                    res.data.vouchersReminders[i].expiryDate
                  );
                  array2.push(res.data.vouchersReminders[i]);
                }
                for (let i = 0; i < res.data.warrantiesReminders.length; i++) {
                  res.data.warrantiesReminders[i].nameType = "Warranties";
                  res.data.warrantiesReminders[i].expiredDateVal = new Date(
                    res.data.warrantiesReminders[i].expiryDate
                  );
                  array2.push(res.data.warrantiesReminders[i]);
                }

                array2.sort((a, b) => a.expiredDateVal - b.expiredDateVal);
                console.log(array2);
                const newArray2 = array2.slice(0, 10);
                setFamilyRemDetails1(newArray2);
              },
              (error) => {}
            );

            // get user details
            UserService.updateSUAURel(splitUrl[4]).then(
              (respond) => {
                console.log(respond);
                for (let i = 0; i < respond.data.length; i++) {
                  if (respond.data[i].User.email === UserDetails.email) {
                    setuserDetails(respond.data[i].User);
                    console.log(respond.data[i]);
                  }
                }
              }
              // setTimeout(() => {
              //   setLoading(false);
              // }, 250)
            );
          }
        );
        setTimeout(() => {
          setLoading(false);
        }, 250);
      },

      (error) => {
        setTimeout(() => {
          setLoading(false);
        }, 250);
      }
    );
  }, []);

  const getNotifications = (familyData) => {
    UserService.getUserFamilyNotification(
      familyData.Family.uuid,
      familyData.id
    ).then(
      (response) => {
        if (response.status === 400) {
        } else {
          if (response.data.length !== 0) {
            const list = response.data;
            const size = 3;
            const items = list.slice(0, size);
            setFamilyNotificationList(items);
          }
        }
      },
      (error) => {}
    );
  };

  const redirectionLink = (value) => {
    console.log(value);
    if (value.nameType === "Outgoings") {
      history.push(
        `/view_user_outgoing_details/${uuid}/${value.id}/${userUUID}`
      );
    }
    if (value.nameType === "Warranties") {
      history.push(
        `/view_user_warranty_details/${uuid}/${value.id}/${userUUID}`
      );
    }
    if (value.nameType === "Vouchers") {
      history.push(
        `/view_user_vouchers_&_gift_cards/${uuid}/${value.id}/${userUUID}`
      );
    }
    if (value.nameType === "Gift Cards") {
      history.push(
        `/view_user_vouchers_&_gift_cards/${uuid}/${value.id}/${userUUID}`
      );
    }
    if (value.nameType === "To Do’s") {
      history.push(`/view_user_todos_details/${uuid}/${value.id}/${userUUID}`);
    }
  };

  return (
    <div className="page-content supportingUser-dashbrd">
      <Container fluid>
        {loading ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            {/* <div
              className="Reminder-backBtn-1"
              onClick={() =>
                dashboardRed()
              }
            >
              {" "}
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              Back to Group Dashboard
            </div> */}
            {/* <div>
              <span className="float-left mr-2">
                <Image
                  className="profile-avatar"
                  src={
                    parentUser.avatar ? parentUser.avatar : DefaultProfileAvatar
                  }
                  alt="Profile Avatar"
                  width="50px"
                  height="50px"
                />
              </span>

              <span className="">
                <h3 className=" mb-0">
                  {parentUser.firstName != "null" ||
                  parentUser.lastName != "null"
                    ? Capitalize(
                        parentUser.firstName + " " + parentUser.lastName
                      )
                    : ""}
                </h3>
                <div className="SUDashboard-email">{parentUser.email}</div>
              </span>
            </div> */}

            <div>
              <Row>
                {/* toast */}
                <div className="toast-div" ref={myRef}>
                  {" "}
                  {/* delay={3000} autohide */}
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                  >
                    <Toast.Body>
                      <img src={SuccessCheck} className="rounded me-2" alt="" />
                      {/* {toastMessage} */}
                      User have been successfully invited
                    </Toast.Body>
                  </Toast>
                </div>
              </Row>
              <Row>
                <Col xxl="7" xl="6">
                  <div
                    className="mb-3"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    Hi{" "}
                    <b>
                      {userDetails.firstName} {userDetails.lastName}
                    </b>
                    , you are now in{" "}
                    <b>
                      {parentUser.firstName !== "null"
                        ? parentUser.firstName
                        : parentUser.email}{" "}
                      {parentUser.lastName !== "null" && parentUser.lastName}
                    </b>
                    ’s User screen{" "}
                  </div>
                  <Row className="">
                    <Col md="6" lg="6">
                      <Link to={`/user_myoutgoings/${uuid}/${parentUser.uuid}`}>
                        <Category
                          heading="Outgoings"
                          value={subscriptionsCount ? subscriptionsCount : "0"}
                          variant="category"
                          cardImage={
                            SubscriptionsRoundIcon ? SubscriptionsRoundIcon : ""
                          }
                        />
                      </Link>
                    </Col>
                    <Col md="6" lg="6">
                      <Link to={`/user_warranties/${uuid}/${parentUser.uuid}`}>
                        <Category
                          heading="Warranties"
                          value={warrantiesCount ? warrantiesCount : "0"}
                          variant="category"
                          cardImage={
                            WarrantiesRoundIcon ? WarrantiesRoundIcon : ""
                          }
                        />
                      </Link>
                    </Col>
                    <Col md="6" lg="6">
                      <Link
                        to={`/user_vouchers_&_gift_cards/${uuid}/${parentUser.uuid}`}
                      >
                        <Category
                          heading="Vouchers & Gift Cards"
                          value={giftCardsCount ? giftCardsCount : "0"}
                          variant="category"
                          cardImage={
                            GiftcardsRoundIcon ? GiftcardsRoundIcon : ""
                          }
                        />
                      </Link>
                    </Col>
                    <Col md="6" lg="6">
                      <Link to={`/user_todos/${uuid}/${parentUser.uuid}`}>
                        <Category
                          heading="To Do's"
                          value={tasksCount ? tasksCount : "0"}
                          variant="category"
                          cardImage={TasksRoundIcon ? TasksRoundIcon : ""}
                        />
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col xxl="5" xl="6" className="mt-1">
                  <>
                    {/* old code  */}
                    {/* <div
                      className="AO-RB-1"
                      onClick={() =>
                        history.push({
                          pathname: `/reminders/${uuid}`,
                          to: "aaaa",
                          uuid,
                          state: {
                            userUUID,
                          },
                        })
                      }
                    >
                      <span className="AO-RB-Text">
                        {parentUser.firstName !== "null" ||
                        parentUser.lastName !== "null" ? (
                          <>
                            <span style={{ "margin-right": "4rem" }}>
                              View All Reminders
                            </span>
                            <span>
                              <img
                                className=""
                                src={BlueArrow}
                                alt="Profile Avatar"
                              />{" "}
                            </span>{" "}
                          </>
                        ) : (
                          <>
                            <span style={{ "margin-right": "3rem" }}>
                              View All Reminders
                            </span>{" "}
                            <span>
                              <img
                                className=""
                                src={BlueArrow}
                                alt="Profile Avatar"
                              />{" "}
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                    <div
                      className="AO-RB-1"
                      onClick={() =>
                        history.push(
                          `/user_notification/${uuid}/${parentUser.uuid}`
                        )
                      }
                    >
                      {" "}
                      <span className="AO-RB-Text">
                        {parentUser.firstName !== "null" ||
                        parentUser.lastName !== "null" ? (
                          <>
                            <span style={{ "margin-right": "4rem" }}>
                              View All Event Logs
                            </span>{" "}
                            <span>
                              <img
                                className=""
                                src={BlueArrow}
                                alt="Profile Avatar"
                              />{" "}
                            </span>
                          </>
                        ) : (
                          <>
                            <span style={{ "margin-right": "2rem" }}>
                              View All Event Logs
                            </span>{" "}
                            <span>
                              <img
                                className=""
                                src={BlueArrow}
                                alt="Profile Avatar"
                              />{" "}
                            </span>
                          </>
                        )}
                      </span>
                    </div> */}

                    {/* new code */}
                    <div className="AU-Rem-1 mb-2">
                      <div className="AU-Rem-1-sub-1">
                        <span>Reminders </span>
                        <span className="AU-Rem-1-sub-img">
                          <img
                            src={setting}
                            className="setting"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/reminder_setting/${uuid}`)
                            }
                          />
                        </span>
                      </div>
                      <div
                        className="AU-Rem-1-sub-2"
                        onClick={() =>
                          history.push({
                            pathname: `/reminders/${uuid}`,
                            uuid,
                          })
                        }
                      >
                        View All
                      </div>
                    </div>
                    <div className="famDash-rembox">
                      {familyRemDetails1.length > 0 ? (
                        <>
                          <div
                            className="rem-showval famdash-scroll"
                            style={{ paddingRight: "10px" }}
                          >
                            {familyRemDetails1.map((remlist, index) => (
                              <div key={index}>
                                <div className="position-relative py-2 mb-3">
                                  <div className="position-absolute py-1 px-2 dashboard-header1">
                                    {remlist.nameType}
                                  </div>
                                  <div
                                    className="dashboard-details   pt-4 px-3 pb-3 dashboard-subheader1"
                                    onClick={() => redirectionLink(remlist)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {remlist.nameType !== "To Do’s" ? (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          {remlist.nameType === "Outgoings" ||
                                          remlist.nameType === "Warranties" ? (
                                            <>
                                              <b>Category</b>
                                              <div>{remlist.Category.name}</div>
                                            </>
                                          ) : (
                                            <>
                                              <b>Type</b>
                                              <div>{remlist.cardType}</div>
                                            </>
                                          )}
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Supplier</b>
                                          <div>
                                            {remlist.nameType === "Outgoings" ||
                                            remlist.nameType === "Warranties"
                                              ? remlist.Supplier.name
                                              : remlist.GiftcardsSupplier.name}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Amount</b>
                                          <div>
                                            {remlist.nameType === "Outgoings"
                                              ? "£" + remlist.premiumAmount
                                              : remlist.nameType ===
                                                "Warranties"
                                              ? "£" + remlist.price
                                              : remlist.nameType === "Vouchers"
                                              ? "£" +
                                                parseFloat(
                                                  remlist.totalAmount
                                                ).toFixed(2)
                                              : "£" +
                                                parseFloat(
                                                  remlist.totalAmount -
                                                    remlist.usedAmount
                                                ).toFixed(2)}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>End Date</b>
                                          <div>
                                            {moment(
                                              remlist.expiredDateVal
                                            ).format("DD-MM-YYYY")}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center flex-column">
                                          <b>Who</b>
                                          <div>
                                            {remlist.Family_User.User
                                              ? remlist.Family_User.User
                                                  .firstName
                                              : remlist.assigneeId.User
                                                  .firstName}{" "}
                                            {remlist.Family_User.User
                                              ? remlist.Family_User.User
                                                  .lastName
                                              : remlist.assigneeId.User
                                                  .lastName}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>What</b>
                                          <div>{remlist.name}</div>
                                        </div>
                                        <div className="d-flex align-items-center flex-column">
                                          <b>When</b>
                                          <div>
                                            {moment(remlist.eta).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="no-remVal">
                          {" "}
                          <img className="no-rem-1" src={notif} alt="notif" />
                          <div className="no-rem-2">
                            You currently have no reminders
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};
export default SupportingUserDashboard;
