import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import openHand from "../../assets/images/open-hand.svg";
import { Large } from "../../stories/Button.stories";
import { Secondary } from "../../stories/Button.stories";
import { Info } from "../../stories/Button.stories";
import { Medium } from "../../stories/Input.stories";
import { Danger } from "../../stories/Alert.stories";
import AuthService from "../../services/auth.service";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import ReactCodeInput from "react-verification-code-input";
import Modal from "react-bootstrap/Modal";
import SuccessCheck from "../../assets/images/successCheck.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import UserService from "../../services/user.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SignUp = () => {
  let history = useHistory();
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    contactNumber: "",
    countryCode: "",
  };
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  const [cmPasswordShown, setCmPasswordShown] = useState(false);
  // email verifcation
  const [formCodeValues, setFormCodeValues] = useState("");
  const [regUserUUID, setRegUserUUID] = useState("");
  const [formCodeErrors, setFormCodeErrors] = useState({});
  const [isCodeSubmit, setIsCodeSubmit] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [successToast, setSuccessToast] = useState(false);

  // code verification model
  const [showVerificationModel, setShowVerificationModel] = useState(false);
  const handleShowVerificationModelClose = () =>
    setShowVerificationModel(false);
  const handleShowVerificationAuShow = () => {
    setShowVerificationModel(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleCmPassword = () => {
    setCmPasswordShown(!cmPasswordShown);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };

  const handleVerificationChange = (e) => {
    let codeValue = e;
    setFormCodeValues(codeValue);
  };
  const handleVerificationSubmit = (e) => {
    e.preventDefault();
    setFormCodeErrors(validateEmailCode(formCodeValues));
    setIsCodeSubmit(true);
    setLoadingCode(true);
  };

  const [phoneNum, setPhoneNum] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChangePhone = (value, data) => {
    console.log(value);
    console.log(data);
    setPhoneNum(value);

    const dialCode = data.dialCode;
    const format = "+";
    const result = format.concat(dialCode);
    setCountrycode(result);

    const phone = value.replace(data.dialCode, "");
    console.log(result);
    console.log(phone);
    setPhoneNumber(phone);

    setFormValues({ ...formValues, countryCode: result, contactNumber: phone });
  };

  const validateEmailCode = (values) => {
    setLoadingCode(false);
    const c_errors = {};
    if (values.length !== "6") {
      c_errors.msg = "Please enter valid verification code";
      c_errors.code = "Yes";
      setSuccessToast(false);
      setShowToast(true);
      setToastMessage(c_errors.msg);
    }
    return c_errors;
  };
  console.log(formValues);
  useEffect(() => {
    // redirect to dashboard if already logged in
    let items = localStorage.getItem("UserDetails");
    if (items) {
      var stringify = JSON.parse(items);
      if (stringify) {
        //redirect to Login
        history.push("/dashboard");
      }
    }
    // reg. form submit
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      let data = {
        firstName: formValues.firstname,
        lastName: formValues.lastname,
        email: formValues.email,
        password: formValues.password,
        confPassword: formValues.confirmPassword,
        contactNumber: formValues.contactNumber,
        countryCode: formValues.countryCode,
      };

      // api call
      AuthService.register(data).then(
        (res) => {
          if (res.status === 400) {
            const errors = {};
            errors.msg = res.data.error;
            setFormErrors(errors);
          } else {
            setRegUserUUID(res.user_id);
            setIsSubmit(false);

            //direct login after user successfully registered
            let dataVal = {
              email: formValues.email,
              password: formValues.password,
            };
            AuthService.login(dataVal).then(
              (res) => {
                // profile api call
                UserService.userProfile(res.data.uuid).then(
                  (response) => {
                    var entry = {
                      avatar: response.userData.avatar,
                      countryCode: response.userData.countryCode,
                      contactNumber: response.userData.contactNumber,
                      createdAt: response.userData.createdAt,
                      email: response.userData.email,
                      firstname: response.userData.firstName,
                      id: response.userData.id,
                      isActive: response.userData.isActive,
                      isContactVerified: response.userData.isContactVerified,
                      isEmailVerified: response.userData.isEmailVerified,
                      lastname: response.userData.lastName,
                      updatedAt: response.userData.updatedAt,
                      uuid: res.data.uuid,
                      displayId: response.userData.displayId,
                      //'accessToken': UserDetails.accessToken,
                    };

                    localStorage.setItem("UserDetails", JSON.stringify(entry));
                    UserService.getUserFamily(res.data.uuid).then(
                      (response) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          response.usersFamilyData.length === 0
                            ? history.push({
                                pathname: "/subscription",
                                familyList: "",
                                inviteList: "",
                              })
                            : response.usersFamilyData.length === 1 &&
                              response.usersFamilyData[0].inviteStatus ===
                                "Onboarded"
                            ? history.push({
                                pathname:
                                  "/family_dashboard/" +
                                  response.usersFamilyData[0].Family.uuid,
                                familyList: "",
                                inviteList: "",
                              })
                            : response.usersFamilyData[0].inviteStatus ===
                              "Acceptance Pending"
                            ? history.push({
                                pathname: "/dashboard",
                                familyList: "",
                                inviteList: "",
                              })
                            : history.push({
                                pathname: "/dashboard",
                                familyList: "",
                                inviteList: "",
                              });
                        }
                        setLoading(false);
                      }
                    );
                  },
                  () => {}
                );
              },
              (error) => {
                const errors = {};
                if (error.response === undefined) {
                  errors.msg = "Connection timed out. Please try again later";
                  setFormErrors(errors);
                } else {
                  errors.msg = error.response.data.error;
                  if (errors.msg) {
                    setFormErrors(errors);
                  }
                }
                setLoading(false);
              }
            );
          }
        },
        (error) => {
          const errors = {};
          errors.msg = error.response;
          if (errors.msg) {
            setFormErrors(errors.msg);
          }
          setLoading(false);
        }
      );
      // end api call
      //setFormValues(initialValues);
    } else {
      setLoading(false);
    }
    // email verification form submit
    if (Object.keys(formCodeErrors).length === 0 && isCodeSubmit) {
      let data = {
        code: formCodeValues,
      };
      // api call
      AuthService.verifyUserEmail(data, regUserUUID).then(
        (res) => {
          if (res.status === 400) {
            setLoadingCode(false);
            setShowVerificationModel(true);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.error);
          } else {
            setLoadingCode(false);
            setShowVerificationModel(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Email verified successfully");
            setTimeout(() => {
              history.push("/");
            }, 2000);
          }
        },
        (error) => {
          const errors = {};
          errors.msg = error.response;
          if (errors.msg) {
            setFormErrors(errors);
          }
          setLoadingCode(false);
        }
      );
    } else {
      setLoadingCode(false);
    }
  }, [formErrors, formCodeErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    console.log(values);
    if (!values.firstname) {
      errors.msg = "Please enter the required fields";
      errors.firstname = "Yes";
    }
    if (!values.lastname) {
      errors.msg = "Please enter the required fields";
      errors.lastname = "Yes";
    }
    if (!values.contactNumber) {
      errors.msg = "Please enter the required fields";
      errors.contactNumber = "Yes";
    }
    if (values.contactNumber && values.contactNumber.length < 10) {
      errors.msg = "contact number must be 10 digits";
      errors.contactNumber = "Yes";
    }
    if (!values.email) {
      errors.msg = "Please enter the required fields";
      errors.email = "Yes";
    } else if (!regex.test(values.email)) {
      errors.msg = "This is not a valid email format !";
      errors.email = "Yes";
    }

    if (!values.password) {
      errors.msg = "Please enter the required fields";
      errors.password = "Yes";
    } else {
      var alphanumerics = /^.{8,40}$/;
      // // regex for mandatory letter and number
      // /^(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{8,20})$/;
      // // regex for mandatory letter, one uppercase , number and special character
      // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
      if (!values.password.match(alphanumerics)) {
        errors.msg = "Please enter a valid password";
        errors.password = "Yes";
      }
    }

    if (!errors.password) {
      if (values.confirmPassword != values.password) {
        errors.msg = "Please enter the same password you've entered before";
        errors.confirmPassword = "Yes";
      }
    }
    return errors;
  };

  const AllowNumbersOnly = (e) => {
    if ((e.which !== 8 && e.which !== 0 && e.which < 48) || e.which > 57) {
      e.preventDefault();
    }
  };

  return (
    <div className="main-view">
      <div className="unAuth-container">
        <Row>
          <Col md="12" lg="12">
            <Image className="slide-right" src={openHand} alt="El openHand" />
            {/* toast */}
            <ToastBlockContainer
              successToast={successToast}
              showToast={showToast}
              setShowToast={setShowToast}
              toastMessage={toastMessage}
            />
            {/* <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={3000}
              autohide
            >
              <Toast.Body>
                <img src={SuccessCheck} className="rounded me-2" alt="" />
                {toastMessage}
              </Toast.Body>
            </Toast> */}
            {/* /.end */}
            <Card className="prelogin-card signup">
              <Card.Body className="p-4">
                <Form className="login-form" onSubmit={handleSubmit}>
                  {/* 1st row */}
                  <Row className="mb-0">
                    <Col md="6" lg="6">
                      <p className="mb-1 title-heading">
                        Create an account on Elderly Life
                      </p>
                      <span className="mb-4 login-boarder"></span>
                    </Col>
                    <Col md="6" lg="6">
                      <p className="align-right">
                        <Link to="/" className="link-brd">
                          <span className="centre-link">
                            Already have an account? Login here
                          </span>
                        </Link>
                      </p>
                    </Col>
                  </Row>

                  {Object.keys(formErrors).length !== 0 && isSubmit ? (
                    <Danger variant="danger" message={formErrors.msg} />
                  ) : (
                    ""
                  )}
                  {/* 2nd row */}
                  <Row>
                    <Col md="6" lg="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          className={formErrors.firstname ? "invalid-text" : ""}
                        >
                          First name
                        </Form.Label>
                        <Medium
                          type="text"
                          errorClass={
                            formErrors.firstname ? "invalid-input" : ""
                          }
                          placeholder="John"
                          size="medium"
                          name="firstname"
                          value={formValues.firstname}
                          onChange={handleChange}
                          //(event) => this.handleUserInput(event)
                        />
                        <Form.Text
                          className={
                            formErrors.firstname ? "invalid-text" : " "
                          }
                        >
                          This will be used to address you on Elderly Life
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          className={formErrors.lastname ? "invalid-text" : ""}
                        >
                          Last name
                        </Form.Label>
                        <Medium
                          type="text"
                          errorClass={
                            formErrors.lastname ? "invalid-input" : ""
                          }
                          placeholder="Walker"
                          size="medium"
                          name="lastname"
                          value={formValues.lastname}
                          onChange={handleChange}
                        />
                        <Form.Text
                          className={formErrors.lastname ? "invalid-text" : " "}
                        >
                          This will be used to name your family
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 3rd row  */}
                  <Row>
                    <Col md="6" lg="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          className={formErrors.email ? "invalid-text" : ""}
                        >
                          Email
                        </Form.Label>
                        {/* <Form.Control type="email" placeholder="John@gmail.com" /> */}
                        <Medium
                          type="email"
                          errorClass={formErrors.email ? "invalid-input" : ""}
                          placeholder="John@gmail.com"
                          size="medium"
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                        />
                        {/* <Form.Text
                          className={formErrors.email ? "invalid-text" : " "}
                        >
                          You have been invited to a family with this email.
                        </Form.Text> */}
                      </Form.Group>
                    </Col>

                    <Col md="6" lg="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          className={
                            formErrors.contactNumber ? "invalid-text" : ""
                          }
                        >
                          Contact Number
                        </Form.Label>
                        {/* <Form.Control type="email" placeholder="John@gmail.com" /> */}
                        {/* <Medium
                          type="text"
                          errorClass={
                            formErrors.contactNumber ? "invalid-input" : ""
                          }
                          placeholder="e.g. 020 7123 4567"
                          size="medium"
                          name="contactNumber"
                          value={formValues.contactNumber}
                          onChange={handleChange}
                          maxLength="11"
                          onKeyPress={AllowNumbersOnly}
                        /> */}
                        <PhoneInput
                          country="gb"
                          value={phoneNum}
                          onChange={handleChangePhone}
                          countryCodeEditable={false}
                        />
                        <Form.Text
                          className={
                            formErrors.contactNumber ? "invalid-text" : " "
                          }
                        >
                          If you are a main or support user, reminders will be
                          sent to this number.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 4th row */}
                  <Row>
                    <Col md="6" lg="6">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label
                          className={formErrors.password ? "invalid-text" : ""}
                        >
                          Create Password
                        </Form.Label>
                        {/* <Medium
                          type={passwordShown ? "text" : "password"}
                          errorClass={
                            formErrors.password ? "invalid-input" : ""
                          }
                          placeholder="Password"
                          size="medium"
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                        /> */}
                        <InputGroup className="">
                          <FormControl
                            type={passwordShown ? "text" : "password"}
                            className={
                              formErrors.password ? "invalid-input" : ""
                            }
                            placeholder="Password"
                            size="medium"
                            name="password"
                            value={formValues.password}
                            onChange={handleChange}
                          />
                          <InputGroup.Text
                            className={
                              formErrors.password ? "invalid-input" : "pwd-icon"
                            }
                          >
                            <i
                              className={`fa ${
                                passwordShown ? "fa-eye" : "fa-eye-slash"
                              } password-icon`}
                              onClick={togglePassword}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Text
                          className={formErrors.password ? "invalid-text" : " "}
                        >
                          Your password should be of minimum 8 characters and
                          can contain alphanumerics
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label
                          className={
                            formErrors.confirmPassword ? "invalid-text" : ""
                          }
                        >
                          Confirm Password
                        </Form.Label>
                        <InputGroup className="">
                          <FormControl
                            type={cmPasswordShown ? "text" : "password"}
                            className={
                              formErrors.confirmPassword ? "invalid-input" : ""
                            }
                            placeholder="Confirm Password"
                            size="medium"
                            name="confirmPassword"
                            value={formValues.confirmPassword}
                            onChange={handleChange}
                          />
                          <InputGroup.Text
                            className={
                              formErrors.confirmPassword
                                ? "invalid-input"
                                : "pwd-icon"
                            }
                          >
                            <i
                              className={`fa ${
                                cmPasswordShown ? "fa-eye" : "fa-eye-slash"
                              } password-icon`}
                              onClick={toggleCmPassword}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Text
                          className={
                            formErrors.confirmPassword
                              ? "invalid-text"
                              : "text-muted"
                          }
                        >
                          Please retype the same password you've entered before
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 5th Row */}
                  <Row>
                    <Col md="6" lg="8" className="mt-2">
                      <p className="text-small">
                        By proceeding further you accept our
                        <a
                          href="https://elderlylife.com/terms-and-conditions"
                          target="_blank"
                          style={{ margin: "0 0.25rem" }}
                          rel="noreferrer"
                        >
                          Terms and conditions
                        </a>
                        and
                        <a
                          href="https://elderlylife.com/privacy-policy"
                          target="_blank"
                          style={{ margin: "0 0.25rem" }}
                          rel="noreferrer"
                        >
                          Privacy policy
                        </a>
                      </p>
                    </Col>
                    <Col md="6" lg="4">
                      <Large
                        children="Sign Up"
                        size="largebtn"
                        variant="primary"
                        type=""
                        buttonWidth="80%"
                        font_Weight="600"
                        isLoading={loading ? "true" : "false"}
                        isDisabled={loading ? "true" : "false"}
                      />
                      {/* {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )} */}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          {/* verify email model */}
          <Modal
            show={showVerificationModel}
            onHide={handleShowVerificationModelClose}
            className="verification_modal"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3></h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="verifictaion-success-msg">
                <Row>
                  <Col md="1" lg="1">
                    <Image
                      className="hello-text-img my-0"
                      src={SuccessCheck}
                      alt="Check mark"
                      width="40px"
                    />
                  </Col>
                  <Col md="10" lg="10" className="ml-3">
                    <span>
                      Hurray!! Your Elderly Life account has been successfully
                      created
                    </span>
                  </Col>
                </Row>
              </div>
              <p className="my-4">
                <strong>
                  We have sent a 6 digit verification code to your email.
                </strong>
              </p>
              <Form
                className="verification-form"
                onSubmit={handleVerificationSubmit}
              >
                <p className="mb-2">Verification Code</p>
                <ReactCodeInput
                  value={formCodeValues}
                  name="code"
                  fields={6}
                  onChange={handleVerificationChange}
                  className={formCodeErrors.code ? "invalid-text" : ""}
                />
                <input type="hidden" name="regUser" value={regUserUUID} />

                <div className="align-center pt-4">
                  <Secondary
                    children="Submit Code"
                    size="mediumbtn"
                    variant="secondary"
                    type=""
                    buttonWidth="260px"
                    font_Weight="600"
                    isLoading={loadingCode ? "true" : "false"}
                    isDisabled={loadingCode ? "true" : "false"}
                  />
                  {/* {loadingCode && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )} */}
                </div>
              </Form>
              <div className="align-center py-3">
                <Link to="/">
                  <Info
                    children="Skip and Login"
                    size="mediumbtn"
                    variant="info"
                    type=""
                    buttonWidth="260px"
                    font_Weight="600"
                  />
                </Link>
              </div>
            </Modal.Body>
          </Modal>
        </Row>
      </div>
    </div>
  );
};

export default SignUp;
