import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  Overlay,
  Popover,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import RenewalHistoryCard from "../../components/Cards/RenewalHistoryCard.js";
import AccountPlanCard from "../Cards/AccountPlanCard.js";
import PaymentMtdCard from "../Cards/PaymentMtdCard.js";
import ArrowDown from "../../assets/images/arrow_down.svg";
import Modal from "react-bootstrap/Modal";
import MiniPaymentMtdCard from "../Cards/MiniPaymentMtdCard.js";
import UserService from "../../services/user.service";
import NewCardForm from "./NewCardForm";
import moment from "moment";
import OptionsIcon from "../../assets/images/options_icon.png";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const ManageSubscription = (props) => {
  const startingYear = 2022;
  const currentYear = moment(new Date()).format("YYYY");
  const [showPaymentMtd, setShowPaymentMtd] = useState(false);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [currentPlanDetails, setCurrentPlanDetails] = useState([]);
  const [custId, setCustId] = useState();
  const [paymentList, setPaymentList] = useState([]);
  const [activePaymentMethod, setActivePaymentMethod] = useState({ card: "" });
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(true);
  const [currentPlanLoader, setCurrentPlanLoader] = useState(true);
  const [invoiceListingLoader, setInvoiceListingLoader] = useState(true);
  const [showAddNewPaymentMethod, setShowAddNewPaymentMethod] = useState(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [yearsList, setYearsList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [newYear, setNewYear] = useState(currentYear);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [delTarget, setDelTarget] = useState();
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [checkYear, setcheckYear] = useState("");

  const [trialCheck, settrialCheck] = useState([]);

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  let { uuid } = useParams();

  const [optionsVisible, setOptionsVisible] = useState(false);
  const optionsIcon = useRef();
  const mtdOptionsModal = useRef();
  const delRef = useRef(null);
  const myRef = useRef(null);
  const abc = useRef(null);

  let savedCardNo = localStorage.getItem("hello");

  useEffect(() => {
    window.addEventListener("mouseup", function (event) {
      var modalCardVal = document.getElementById("modalCardVal");
      if (
        event.target != modalCardVal &&
        event.target.parentNode != modalCardVal
      ) {
        setValueClick("");
      }
    });
  });

  useEffect(() => {
    setUpYearsList();

    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
        setCustId(response.FamilyData.Family.stripeCustomerId);
        setCurrentPlanDetails(
          response.FamilyData.Family.Family_Subscription
            .Account_Subscription_Plan
        );

        // Get Customer stripe details
        getCustomerStripeDetails(response.FamilyData.Family.stripeCustomerId);
        let st = new Date("01-01-" + currentYear).getTime();

        getCustomerStripeInvoiceList(
          response.FamilyData.Family.stripeCustomerId,
          response.FamilyData.Family.Family_Subscription.stripeSubscriptionId,
          st
        );
        setCurrentPlanLoader(false);
      },
      (error) => {}
    );
  }, []);

  // Get Customer stripe details
  const getCustomerStripeDetails = (custId) => {
    UserService.getCustomerDetailStripe(custId).then((response) => {
      let custPaymentMethodId =
        response.invoice_settings.default_payment_method;
      UserService.getCustomerDefaultPaymentMethod(custPaymentMethodId).then(
        (res) => {
          setActivePaymentMethod(res);
          setPaymentMethodLoading(false);

          // Get Customer stripe payment methods
          UserService.getCustomerPaymentMethodsList(
            { type: "card" },
            custId
          ).then((result) => {
            let ptMethods = result.data;
            let methodItems = [];

            for (var i = 0; i < ptMethods.length; i++) {
              if (ptMethods[i].id !== custPaymentMethodId) {
                methodItems.push(ptMethods[i]);
              }

              if (Number(i) + 1 === ptMethods.length) {
                setPaymentList(methodItems);
              }
            }
          });
        }
      );
    });
  };

  const getCustomerStripeInvoiceList = (custId, subscription, startDate) => {
    UserService.getCustomerInvoiceList(custId, subscription, startDate).then(
      (res) => {
        var items = [];
        settrialCheck(res);
        if (res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            items.push({
              payment_date: moment.unix(res[i].created).format("MMMM Do, YYYY"),
              currency: res[i].currency === "gbp" ? "£" : res[i].currency,
              amount:
                String(res[i].amount_paid).slice(0, -2) +
                "." +
                String(res[i].amount_paid).slice(-2),
              inv_number: res[i].number,
              billing_reason: res[i].billing_reason,
              file: res[i].invoice_pdf,
              currYear: moment.unix(res[i].created).format("YYYY"),
              hostURL: res[i].hosted_invoice_url,
            });

            if (Number(i) + 1 === res.length) {
              setInvoiceList(items);
              setInvoiceListingLoader(false);
            }
          }
        } else {
          setInvoiceList([]);
          setInvoiceListingLoader(false);
        }
      }
    );
  };

  const setUpYearsList = () => {
    let items = [];
    for (let i = startingYear; i <= currentYear; i++) {
      items.push({ value: i });
      if (Number(i) === Number(currentYear)) {
        setYearsList(items);
      }
    }
  };

  const handleYearSelect = (e) => {
    setNewYear(e);
    if (e !== selectedYear) {
      setInvoiceListingLoader(true);
      setInvoiceList([]);
      setSelectedYear(e);
      let st = new Date("01-01-" + e).getTime();
      getCustomerStripeInvoiceList(
        custId,
        familyDetails.FamilyData.Family.Family_Subscription
          .stripeSubscriptionId,
        st
      );
    }
  };

  const updateCustomerCard = (e, pMethodId) => {
    e.preventDefault();
    let updateData = {
      "invoice_settings[default_payment_method]": pMethodId,
    };
    UserService.updateCustomerStripe(updateData, custId).then(
      (response) => {
        setOptionsVisible(false);
        let pytMethods = paymentList;
        pytMethods.push(activePaymentMethod);
        setPaymentList([]);
        const selectedIndex = pytMethods.findIndex(
          (x) => String(x.id) === String(pMethodId)
        );
        setActivePaymentMethod(pytMethods[selectedIndex]);
        pytMethods.splice(selectedIndex, 1);
        setPaymentList(pytMethods);
        setShowToast(true);
        setSuccessToast(true);
        setToastMessage("Card has been updated as your default payment card");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };
  const deleteCard = (e, pMethod) => {
    e.preventDefault();
    setDelTarget(e.target);

    setConfirmDialogOpen(!confirmDialogOpen);
  };

  const [confirmVal, setConfirmVal] = useState("");
  const [confirmValNo, setConfirmValNo] = useState("");
  const [confirmValId, setConfirmValId] = useState("");
  const ConfirmMsg = (e, pMethod) => {
    setConfirmVal(pMethod.card.brand);
    setConfirmValNo(pMethod.card.last4);
    setConfirmValId(pMethod.id);
    setShowWarningMsg(true);
    setShowPaymentMtd(false);
    setValueClick("");
  };

  const ConfirmMsgCancel = (e) => {
    setShowWarningMsg(false);
    setValueClick("");
    setShowPaymentMtd(true);
  };
  const confirmDeleteCard = (e, pMethod) => {
    e.preventDefault();

    setOptionsVisible(false);
    let pytMethods = paymentList;
    UserService.detachPaymentMethod(pMethod).then((response) => {
      const selectedIndex = pytMethods.findIndex(
        (x) => String(x.id) === String(pMethod)
      );
      pytMethods.splice(selectedIndex, 1);
      setPaymentList(pytMethods);
      setShowToast(true);
      setSuccessToast(true);
      setToastMessage("Card has been deleted successfully");
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    });
  };

  const setNewCard = (cardData) => {
    setShowAddNewPaymentMethod(false);
    let pytMethods = paymentList;
    pytMethods.push(cardData);
    setPaymentList(pytMethods);
    setShowToast(true);
    setSuccessToast(true);
    setToastMessage("New Card has been added successfully");
    // setTimeout(function () {
    //   window.location.reload();
    // }, 3000);
  };

  const [cardNo, setCardNo] = useState([]);

  //
  useEffect(() => {
    var newData = [savedCardNo];
    for (var i = 0; i < paymentList.length; i++) {
      // newData.push({
      //   cardNo: paymentList[i].card.last4,
      // });
      newData.push(paymentList[i].card.last4);
    }

    // newData.forEach((row, ind) => (row.id = ind));
    setCardNo(newData);
    // localStorage.setItem("hi", JSON.stringify(newData));
  }, [paymentList]);

  const [valueClick, setValueClick] = useState("");
  const ValOpen = (e, pMethodId) => {
    e.preventDefault();

    setValueClick(pMethodId);
  };
  //
  //

  useEffect(() => {
    for (let i = 0; i < invoiceList.length; i++) {
      if (invoiceList[i].currYear != newYear) {
        setcheckYear("checkYear");
      } else {
        setcheckYear("");
      }
    }
  }, [handleYearSelect]);

  return (
    <>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div className="page-content">
        <Modal
          show={showPaymentMtd}
          onHide={() => {
            setShowPaymentMtd(false);
            setValueClick("");
          }}
          className="payment_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Payment Methods</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="sub-text mb-3">Current Card Being Used</p>
            {activePaymentMethod && (
              <MiniPaymentMtdCard
                paymentMethod={activePaymentMethod}
                showOptions={false}
                border={false}
              />
            )}
            <div>
              <div className="space-between align-items-center">
                <p className="sub-text mb-3">
                  {paymentList.length > 0 && (
                    <span>Saved cards ( {paymentList.length} )</span>
                  )}
                </p>
                <button
                  className="add-new-card-btn mb-4"
                  onClick={() => {
                    setShowAddNewPaymentMethod(true);
                    setShowPaymentMtd(false);
                  }}
                >
                  <span className="medium-text font-medium">+ Add new</span>
                </button>
              </div>

              <div>
                {paymentList.map((payment, index) => (
                  <div className="outlined-card mb-4" key={index}>
                    <Row className="p-3 px-4 align-items-center">
                      <Col className="col-auto ">
                        <p className="mt-0 mb-1 title-heading">
                          **** **** **** {payment.card.last4}
                        </p>
                        <p className="mt-0 mb-0 font-small text-black">
                          {payment.billing_details.name}
                        </p>
                        <p className="mt-0 mb-0 font-small  text-black">
                          Expires on {payment.card.exp_month}/
                          {payment.card.exp_year}
                        </p>
                      </Col>
                      <Col className="p-0 mr-3 justify-flex-end">
                        <div className="visa-logo align-items-center">
                          {payment.card.brand}
                        </div>
                      </Col>
                      <Col ref={optionsIcon} className="p-0 col-auto">
                        <img
                          className="options-icon"
                          src={OptionsIcon}
                          onClick={(e) => ValOpen(e, payment.id)}
                        />
                      </Col>
                    </Row>
                    {valueClick === payment.id && (
                      <div
                        // ref={mtdOptionsModal}
                        id="modalCardVal"
                        className="imagepicker-modal paymentmtd-options-modal"
                      >
                        <span
                          id={"use-" + payment.id}
                          onClick={(e) => updateCustomerCard(e, payment.id)}
                        >
                          Use this card
                        </span>
                        <span
                          id={"del-" + payment.id}
                          onClick={(e) => ConfirmMsg(e, payment)}
                        >
                          Delete card
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Payment modal */}
        <Modal
          show={showAddNewPaymentMethod}
          onHide={() => {
            setShowAddNewPaymentMethod(false);
            setShowPaymentMtd(true);
          }}
          className="payment_modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Add New Card</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-3 subtext">
              Add the details below to save a new card
            </p>
            <NewCardForm
              customerId={custId}
              priceId={currentPlanDetails.stripePric}
              planId={currentPlanDetails.id}
              familyName={
                familyDetails.FamilyData
                  ? familyDetails.FamilyData.Family.name
                  : ""
              }
              setNewCard={setNewCard}
              cardsaved={cardNo}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showWarningMsg}
          onHide={() => {
            setShowWarningMsg(false);
            setShowPaymentMtd(true);
          }}
          className="payment_modal"
          // size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Are you sure you want to delete this card ?</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Removing it means you won't be able to use this card unless you
              adding it again!
            </p>
            <p className="card-del-2">
              <p className="card-del-2-1">{confirmVal}</p>
              <p className="card-del-2-2"> **** **** **** {confirmValNo}</p>
            </p>

            <h3 className="card-del-3">
              <h3
                onClick={(e) => ConfirmMsgCancel(e)}
                // style={{ "margin-right": "2rem" }}
                className="card-del-3-1"
              >
                CANCEL
              </h3>
              <h3
                onClick={(e) => confirmDeleteCard(e, confirmValId)}
                className="card-del-3-2"
              >
                REMOVE
              </h3>
            </h3>
          </Modal.Body>
        </Modal>

        <Container fluid>
          {/* toast */}
          <ToastBlockContainer
            successToast={successToast}
            showToast={showToast}
            setShowToast={setShowToast}
            toastMessage={toastMessage}
          />
          {/* <div className="toast-div" ref={myRef}>
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Body>
              <Row>
                <Col md="2">
                  <img src={SuccessCheck} className="rounded me-2" alt="" />
                </Col>
                <Col md="10">{toastMessage}</Col>
              </Row>
            </Toast.Body>
          </Toast>
        </div> */}

          <Row>
            <Row className="p-0">
              <Col className="text-left">
                <p className="mt-3 mb-3 title-heading">Manage Subscription</p>
              </Col>
            </Row>

            {/* <Row className="p-0 mb-3">
            <Col className="text-left">
              <Link
                to={`/family_dashboard/${uuid}`}
                className="back-dashboard-btn space-evenly align-items-center"
              >
                <img src={ArrowDown} />
                <p className="sub-text mb-0 text-black">
                  Back to Group Dashboard
                </p>
              </Link>
            </Col>
          </Row> */}

            <Row className="p-0">
              <Col md="6" lg="6">
                <div>
                  <p className="mb-2 mt-2 medium-text bold">
                    Current Account Plan
                  </p>
                  <AccountPlanCard
                    uuid={uuid}
                    cardDetails={currentPlanDetails}
                    currentPlanLoader={currentPlanLoader}
                    trialCheck={trialCheck}
                  />
                </div>
              </Col>
              <Col md="6" lg="6">
                <div>
                  <p className="mb-2 mt-2 medium-text bold">Payment Method</p>
                  <PaymentMtdCard
                    setShowPaymentMtd={setShowPaymentMtd}
                    paymentMethodLoading={paymentMethodLoading}
                    activePaymentMethod={activePaymentMethod}
                  />
                </div>
              </Col>
            </Row>

            <Row className="p-0">
              <Col className="text-left">
                <p className="mt-3 mb-1 title-heading">Renewal History</p>
                <p className="sub-text mb-3">
                  You can view your account plan renewal history.
                </p>
              </Col>
            </Row>

            <Row className="p-0">
              <Col>
                <Dropdown
                  onSelect={handleYearSelect}
                  className="profile-dropdwn"
                >
                  <Dropdown.Toggle
                    id="year-by"
                    style={{ "padding-right": "10px" }}
                  >
                    {selectedYear}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {yearsList.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.value}>
                        {item.value}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            {/* {invoiceList.length > 0 && !invoiceListingLoader && ( */}
            <Row className="p-0">
              <Col className="text-left">
                <p className="mt-3 mb-0 medium-text  bold">
                  You are viewing the renewal history for the year{" "}
                  {selectedYear}
                </p>
              </Col>
            </Row>
            {/* )} */}

            {invoiceListingLoader && (
              <Row>
                <Col md="12" lg="12" className="mt-4 pt-4 text-center">
                  <button className="btn loading-btn" type="button">
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                    ></span>
                    Loading Invoices . . .
                  </button>
                </Col>
              </Row>
            )}
            {invoiceList.length > 0 && !invoiceListingLoader && (
              <Row>
                {invoiceList.map(
                  (invoice, index) =>
                    invoice.currYear === newYear && (
                      <Col md="12" className="p-0" key={index}>
                        <RenewalHistoryCard invoice={invoice} />
                      </Col>
                    )
                )}
              </Row>
            )}
            {checkYear === "checkYear" && !invoiceListingLoader && (
              <Row>
                <div className="renewal-history-card mb-4 mt-4">
                  <div className="noData">No Data Found...</div>
                </div>
              </Row>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ManageSubscription;
