// common Alert block with any action button
import React from "react";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import "./Alert.scss";
import EmailVerificationIcon from "../../assets/images/verfication_envelop.svg";
import smartphn from "../../assets/images/smartphone.svg";

function ActionAlert(props) {
  const { message, variant, onButtonClick, ...rest } = props;
  console.log(props);
  return (
    <div {...rest}>
      {/* <Card className={`action-alert-card ${variant}-alert mb-4`}> */}
      <Card.Body className="p-1 d-flex align-items-center">
        <span className="action-left">
          {variant === "info" ? (
            <img src={EmailVerificationIcon} alt="Email verification icon" />
          ) : (
            <div className="p-2 rounded-circle lb-background">
              <img src={smartphn} alt="smartphn" style={{ width: "20px" }} />
            </div>
          )}
        </span>
        <span className="action-right" style={{ marginRight: "1rem" }}>
          <button className="resend-btn" onClick={onButtonClick}>
            Send verification Code
          </button>
        </span>
        <span className="action-text">{message}</span>
      </Card.Body>
      {/* </Card> */}
    </div>
  );
}

export default ActionAlert;
