import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ChoosePlanCard } from "../../stories/Subscription.stories";
import { Primary } from "../../stories/Button.stories";
import { Medium } from "../../stories/Input.stories";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import CheckoutForm from "./CheckoutForm";
import UserService from "../../services/user.service";
import { Outline } from "../../stories/Button.stories";
import SavedCardCheckoutForm from "./SavedCardCheckoutForm";
import Loading from "../../common/Loading";

const Subscription = (props) => {
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [cusDetails, setCusDetails] = useState();
  const [priceDetails, setPriceDetails] = useState();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [paymentList, setPaymentList] = useState([]);
  const [show, setShow] = useState(false);
  const [planId, setPlanId] = useState();
  const [stripeProductId, setStripeProductId] = useState();
  const [planName, setPlanName] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [priceSymbol, setPriceSymbol] = useState();
  const [planAuSeat, setPlanAuSeat] = useState();
  const [planSuSeat, setPlanSuSeat] = useState();
  const [planFrequency, setPlanFrequency] = useState();
  const [planImageIcon, setPlanImageIcon] = useState();
  const executeScroll = () => myRef.current.scrollIntoView();
  const myRef = useRef(null);
  const initialValues = { familyName: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [accountPlanList, setAccountPlanList] = useState([]);

  useEffect(() => {
    UserService.getUserAccountPlan(UserDetails.uuid).then(
      (response) => {
        var newData = [];
        for (var i = 0; i < response.usersFamilyData.length; i++) {
          if (response.usersFamilyData[i].isAo === true) {
            newData.push(response.usersFamilyData[i]);
          }
        }
        setAccountPlanList(newData);
      },
      (error) => {}
    );
  }, []);
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const handleClose = () => setShow(false);
  const handleShow = (planData) => {
    if (UserDetails.lastname === "null") {
      let result = "";
      const charactersLength = characters.length;

      for (let i = 0; i < charactersLength; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      let stringData = result.slice(0, 8);

      setFormValues({ ...formValues, familyName: stringData });
      setIsSubmit(true);
    } else {
      setFormValues({ ...formValues, familyName: UserDetails.lastname });
      setIsSubmit(true);
    }
    // setShow(true);
    setFormErrors({});
    setPlanId(planData.id);
    setStripeProductId(planData.stripeProductId);
    setPlanName(planData.name);
    setPlanPrice(planData.price);
    setPriceSymbol(planData.Country.symbol);
    setPriceDetails(planData.stripePriceId);
    setPlanAuSeat(planData.availableSeats);
    setPlanSuSeat(planData.availableSupportSeats);
    setPlanFrequency(planData.renewalType);
    setPlanImageIcon(planData.image);
  };
  const handleSwitchPlan = () => setShow(false);
  // payment modal
  const [showPayment, setShowPayment] = useState(false);
  const handlePaymentClose = () => setShowPayment(false);
  const handlePaymentShow = () => {
    setShowPayment(true);
    UserService.getCustomerListStripe().then((response) => {
      var result = in_array(response, UserDetails.email);
      if (result) {
        setCusDetails(result);
      } else {
        // create customer on stripe
        let userData = {
          name: UserDetails.firstname + " " + UserDetails.lastname,
          email: UserDetails.email,
        };

        UserService.createCustomerStripe(userData).then(
          (response) => {
            //setShowPayment(true);
            setCusDetails(response.id);
            // get list of customer payment
          },
          (error) => {
            const errors = {};
          }
        );
        // end
      }
    });
  };
  function in_array(array, email) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].email == email) {
        return array[i].id;
      }
      //return (array[i].email == email)
    }
    return false;
  }
  function in_price_array(array, id) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].product == id) {
        return array[i].id;
      }
      //return (array[i].email == email)
    }
    return false;
  }
  const handleConfirmChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // handle confirm submit
  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.familyName) {
      errors.msg = "Please enter group name";
      errors.familyName = "Yes";
    } else {
      if (values.familyName.length > 40) {
        errors.msg = "Group name too long";
        errors.familyName = "Yes";
      }
    }
    return errors;
  };
  // choose payment method modal
  const [showChoosePayment, setShowChoosePayment] = useState(false);
  const handleChoosePaymentClose = () => setShowChoosePayment(false);
  const handleChoosePaymentShow = () => {
    //setShowChoosePayment(true);
    // call to create customer on stripe
    UserService.getCustomerListStripe().then(
      (response) => {
        var result = in_array(response, UserDetails.email);

        if (result) {
          setCusDetails(result);

          // get list of customer payment
          let cusdata = {
            type: "card",
          };
          UserService.getCustomerPaymentMethodsList(cusdata, result).then(
            (res) => {
              setPaymentList(res.data);
              setShowChoosePayment(true);
            }
          );
        } else {
          // create customer on stripe
          let userData = {
            name: UserDetails.firstname + " " + UserDetails.lastname,
            email: UserDetails.email,
          };

          UserService.createCustomerStripe(userData).then(
            (response) => {
              //setShowPayment(true);
              setCusDetails(response.id);
              // get list of customer payment
              let cusdata = {
                type: "card",
              };
              UserService.getCustomerPaymentMethodsList(
                cusdata,
                response.id
              ).then((res) => {
                setPaymentList(res.data);
                setShowChoosePayment(true);
              });
            },
            (error) => {
              const errors = {};
            }
          );
          // end
        }
      },
      (error) => {
        const errors = {};
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    // handle confirm submit
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      handleClose();
      UserService.getCustomerListStripe().then(
        (response) => {
          var result = in_array(response, UserDetails.email);

          if (result) {
            setCusDetails(result);

            // get list of customer payment
            let cusdata = {
              type: "card",
            };
            UserService.getCustomerPaymentMethodsList(cusdata, result).then(
              (res) => {
                setPaymentList(res.data);

                if (res.data.length !== 0) {
                  handleChoosePaymentShow();
                } else {
                  handlePaymentShow();
                }
              }
            );
          } else {
            // create customer on stripe
            let userData = {
              name: UserDetails.firstname + " " + UserDetails.lastname,
              email: UserDetails.email,
            };

            UserService.createCustomerStripe(userData).then(
              (response) => {
                //setShowPayment(true);
                setCusDetails(response.id);
                // get list of customer payment
                let cusdata = {
                  type: "card",
                };
                UserService.getCustomerPaymentMethodsList(
                  cusdata,
                  response.id
                ).then((res) => {
                  setPaymentList(res.data);

                  if (res.data.length != 0) {
                    handleChoosePaymentShow();
                  } else {
                    handlePaymentShow();
                  }
                  // UserService.getCustomerDetailStripe(result).then((res) => {
                  //
                  //   if (res.id) {
                  //     handleChoosePaymentShow();
                  //   } else {
                  //     handlePaymentShow();
                  //   }
                  //
                  // });
                });
              },
              (error) => {
                const errors = {};
              }
            );
            // end
          }
        },
        (error) => {
          const errors = {};
        }
      );

      setLoading(false);
    }

    // api call
    UserService.planList().then(
      (response) => {
        setSubscriptionPlans(response);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, [formErrors]);

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  return (
    <div className="page-content subscription-pg tab-btn">
      <Container fluid ref={myRef}>
        {/* {details} */}
        {loading ? (
          <div className="loadind-page">
            <Loading />
          </div>
        ) : (
          <>
            <Row>
              <h3>Elderly Life Account Plans</h3>
              <p className="sub-title">
                Choose from the available account plans to create your Group.
                You can always change the plan later.
              </p>
            </Row>

            <Tabs
              defaultActiveKey="annual"
              id="uncontrolled-tab-example"
              className="tab-container"
            >
              <Tab eventKey="annual" title="Annual" className="tab-annual">
                <Row className="subscriptionPlan-card">
                  <p className="content-txt">
                    All the below plans will have access to all the modules of
                    the Elderly Life Platform
                  </p>
                  {subscriptionPlans.map((plan, index) =>
                    plan.renewalType === "yearly" ? (
                      <Col md="4" lg="3" key={index}>
                        <ChoosePlanCard
                          planName={plan.name}
                          planPrice={plan.price}
                          priceSymbol={plan.Country.symbol}
                          planDuration={plan.renewalType}
                          benefits={plan.availableSeats}
                          planImage={plan.image}
                          buttonClick={() => handleShow(plan)}
                          planType={accountPlanList}
                        />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </Row>
                {/* <Row>
                  <p className="sub-title">
                    Having trouble in choosing a account plan?
                  </p>
                  <Primary
                    children="Contact Us"
                    variant="primary"
                    size="mediumbtn"
                    type=""
                    buttonWidth="190px"
                  />
                </Row> */}
              </Tab>
              <Tab eventKey="monthly" title="Monthly" className="tab-monthly">
                <Row className="subscriptionPlan-card">
                  <p className="content-txt">
                    All the below plans will have access to all the modules of
                    the Elderly Life Platform
                  </p>

                  {loading && (
                    <div className="page-loader">
                      <span className="spinner-border spinner-border-sm"></span>
                    </div>
                  )}
                  {subscriptionPlans.map((plan, index) =>
                    plan.renewalType === "monthly" ? (
                      <Col md="4" lg="3" key={index}>
                        <ChoosePlanCard
                          planName={plan.name}
                          planPrice={plan.price}
                          priceSymbol={plan.Country.symbol}
                          planDuration={plan.renewalType}
                          benefits={plan.availableSeats}
                          planImage={plan.image}
                          buttonClick={() => handleShow(plan)}
                          planType={accountPlanList}
                        />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </Row>
                {/* <Row>
                  <p className="sub-title">
                    Having trouble in choosing a account plan?
                  </p>
                  <Primary
                    children="Contact Us"
                    variant="primary"
                    size="mediumbtn"
                    type=""
                    buttonWidth="190px"
                  />
                </Row> */}
              </Tab>
            </Tabs>
            {/* Choose Plan Modal */}
            <Modal
              show={show}
              onHide={handleClose}
              className="choose_plan_modal"
              centered
            >
              <Modal.Header closeButton>
                {/* <Modal.Title><h3>Confirm Account plan</h3></Modal.Title>*/}
                <Modal.Title>
                  <h3>New Group</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form className="family-form" onSubmit={handleConfirmSubmit}>
                  {/* <p className="mb-1 subtext">Verify the chosen plan and proceed to payment.</p>
                        <p className="subtext">Changed your mind? <Link to="/subscription" className="modal-link" onClick={handleSwitchPlan}> Choose another plan </Link></p> */}
                  {Object.keys(formErrors).length !== 0 && isSubmit ? (
                    <p className="error-txt-msg">{formErrors.msg} </p>
                  ) : (
                    ""
                  )}
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label
                      className={
                        formErrors.familyName
                          ? "invalid-text mt-3 mb-0"
                          : "mt-3 mb-0"
                      }
                    >
                      Group Name
                    </Form.Label>
                    <Medium
                      type="test"
                      errorClass={formErrors.familyName ? "invalid-input" : ""}
                      placeholder="Enter Group Name"
                      size="medium"
                      name="familyName"
                      value={formValues.familyName}
                      onChange={handleConfirmChange}
                      maxlength="40"
                    />
                    <p
                      className={
                        formErrors.familyName
                          ? "invalid-text subtext mt-1"
                          : "subtext mt-1"
                      }
                    >
                      Add a name to your group on EL platform. It should be
                      under 40 characters.
                    </p>
                  </Form.Group>
                  <p className="mb-0">Confirm Account Plan</p>
                  <Card className="mt-2 mb-2">
                    <Card.Body className="p-3">
                      <div className="d-flex justify-content-between">
                        <span>
                          <h3 className="mb-0">{planName ? planName : ""}</h3>
                          <p className="card-subtext">
                            {priceSymbol} {planPrice}/{planFrequency} plan{" "}
                          </p>
                          <p className="mb-1 card-text">
                            {planAuSeat == 1 ? (
                              <span>{planAuSeat} Main User</span>
                            ) : (
                              <span>{planAuSeat} Main Users</span>
                            )}{" "}
                            &nbsp; &nbsp; &nbsp;{" "}
                            {planSuSeat == 1 ? (
                              <span>{planSuSeat} Support User</span>
                            ) : (
                              <span>{planSuSeat} Support Users</span>
                            )}{" "}
                          </p>
                        </span>
                        <span className="ms-auto action-right card-img">
                          <Image src={planImageIcon} />
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                  <p className="subtext">
                    Changed your mind?{" "}
                    <Link
                      to="/subscription"
                      className="modal-link"
                      onClick={handleSwitchPlan}
                    >
                      {" "}
                      Choose another plan{" "}
                    </Link>
                  </p>
                  <div className="text-center mt-5">
                    <Primary
                      children="Proceed to Payment"
                      variant="primary"
                      size="mediumbtn"
                      type="submit"
                      buttonWidth="230px"
                      //onClick={()=>{ handleClose(); handleChoosePaymentShow() }}
                      //onClick={routeChange}
                    />
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            {/* Choose a payment method */}
            <Modal
              show={showChoosePayment}
              onHide={handleChoosePaymentClose}
              className="choose_paymentMethod_modal"
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Choose a payment method</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mt-2">
                  <Outline
                    children="+ Add New card"
                    variant="outline"
                    size="mediumbtn"
                    onClick={() => {
                      handleChoosePaymentClose();
                      handlePaymentShow();
                    }}
                    buttonWidth="100%"
                  />
                </div>
                {paymentList.length != 0 ? (
                  <SavedCardCheckoutForm
                    customerId={cusDetails}
                    priceId={priceDetails}
                    planId={planId}
                    paymentList={paymentList}
                    familyName={formValues.familyName}
                    planType={accountPlanList}
                  />
                ) : (
                  <>
                    <p className="mt-4">Saved cards ( 0 )</p>
                    <div className="text-center saved-card-empty">
                      No saved cards
                    </div>
                  </>
                )}
              </Modal.Body>
            </Modal>
            {/* Payment modal */}
            <Modal
              show={showPayment}
              onHide={handlePaymentClose}
              className="payment_modal"
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Add New Card</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="mb-3 subtext">
                  Add the details below to save a new card
                </p>
                <CheckoutForm
                  customerId={cusDetails}
                  priceId={priceDetails}
                  planId={planId}
                  familyName={formValues.familyName}
                  paymentList={paymentList}
                  planType={accountPlanList}
                />
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </div>
  );
};
export default Subscription;
