import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Primary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import { Medium } from "../../stories/Input.stories";
import Select from "react-select";
import moment from "moment";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const TasksAdd = (props) => {
  let history = useHistory();
  const myRef = useRef(null);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedDocUrl, setUploadedDocUrl] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [familyAUUserName, setFamilyAUUserName] = useState();
  const [familyUserId, setFamilyUserId] = useState();
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [selectedTenure, setSelectedTenure] = useState();
  const [taskUrl, setTaskUrl] = useState("");
  const [backText, setBackText] = useState("Back to My To Do's");
  const executeScroll = () => myRef.current.scrollIntoView();
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // var SupportUser = JSON.parse(localStorage.getItem("supportUser"));
  // var PrimaryUser = JSON.parse(localStorage.getItem("MainUser"));

  const initialValues = {
    task_name: "",
    task_details: "",
    eta_date: "",
    task_frequency: "",
    assignee: "",
    noOfOccurrence: "",
  };
  const [formValues, setFormValues] = useState(initialValues);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "0.8rem",
      borderRadius: "5px",
      borderStyle: "none",
      cursor: "pointer",
      padding: "6px 0",
      border: "1px solid #5985ac !important",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };
  const initialFrequencyOption = [
    { value: "", label: "select activity frequency" },

    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Bi-Annually", label: "Bi-Annually" },
    { value: "Yearly", label: "Yearly" },
  ];
  const [frequencyOption, setFrequencyOption] = useState(
    initialFrequencyOption
  );

  const initialNoOccurrence = [
    { value: "", label: "select activity frequency" },

    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];
  const [noOfOccurrence, setNoOfOccurrence] = useState(initialNoOccurrence);

  const initialAssigneeOption = [{ value: "", label: "select a assignee" }];
  const [assigneeOption, setAssigneeOption] = useState(initialAssigneeOption);

  const [isEmpty, setIsEmpty] = useState(false);
  let { uuid, userUUID } = useParams();

  var userType = localStorage.getItem("userType");

  const handleChange = (e, action) => {
    //setFormErrors({});

    if (action) {
      var targetData = {
        name: action.name,
        value: e.value,
      };
      if (action.name === "task_frequency") {
        setSelectedTenure(e);
      }
    } else {
      var targetData = {
        name: e.target.name,
        value: e.target.value,
      };
    }
    const { name, value } = targetData;
    setFormValues({ ...formValues, [name]: value });
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues);
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length != 0) {
      console.log(formValues);
    }
    setIsSubmit(true);
    setLoading(true);
  };
  // validation
  const validate = (values) => {
    const errors = {};
    if (!values.task_name) {
      errors.msg = "Please enter the to do name";
      errors.task_name = "Yes";
    }
    if (!values.eta_date) {
      errors.msg = "Please enter the date";
      errors.eta_date = "Yes";
    }
    if (!values.assignee) {
      errors.msg = "Please enter the assignee";
      errors.assignee = "Yes";
    }
    if (isSwitchOn && !values.task_frequency) {
      errors.msg = "Please enter the activity frequency";
      errors.task_frequency = "Yes";
    }
    if (isSwitchOn && !values.noOfOccurrence) {
      errors.msg = "Please enter the number of occurrences";
      errors.noOfOccurrence = "Yes";
    }
    return errors;
  };

  const getFamilyDetails = (userId) => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        console.log(response);
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);

        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (suResponse) => {
            console.log(suResponse);
            setUserCreateUpdate(suResponse.FamilyData.id);
            // get list of Su user
            UserService.getOnBoardedFamilyUserList(uuid, userId, "AU").then(
              (userlistres) => {
                console.log(userlistres);
                if (
                  userType === "AO + AU" ||
                  userType === "AO + AU + SU" ||
                  userType === "AU" ||
                  userType === "AU + SU"
                ) {
                  const optionArr = [
                    { value: suResponse.FamilyData.id, label: "Self" },
                  ];
                  console.log("response", response);
                  console.log("suResponse", suResponse);
                  console.log("userlistres.usersData", userlistres.usersData);
                  console.log("UserDetails", UserDetails);
                  if (userlistres.usersData.length !== 0) {
                    for (var i = 0; i < userlistres.usersData.length; i++) {
                      if (userlistres.usersData[i].Family_User.isAu === true) {
                        optionArr.push({
                          value: response.FamilyData.id,
                          label: "To Main user",
                        });
                      }
                    }
                    console.log(optionArr);
                    setAssigneeOption(optionArr);
                  } else {
                    console.log(optionArr);
                    setAssigneeOption(optionArr);
                  }
                } else if (userType === "SU" || userType === "AO + SU") {
                  console.log(response.FamilyData.id);
                  console.log(suResponse.FamilyData.id);
                  const optionArr = [
                    { value: suResponse.FamilyData.id, label: "Self" },
                    { value: response.FamilyData.id, label: "To Main user" },
                  ];
                  setAssigneeOption(optionArr);
                }
              }
            );
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };
  console.log(assigneeOption);
  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdate(response.FamilyData.id);
        setUserCreateUpdateFor(response.FamilyData.id);
        console.log(userType);
        // get list of Su user
        UserService.getOnBoardedFamilyUserList(uuid, userId, "AU").then(
          (userlistres) => {
            if (
              userType === "AO + AU" ||
              userType === "AO + AU + SU" ||
              userType === "AU" ||
              userType === "AU + SU"
            ) {
              var arr1 = [{ value: response.FamilyData.id, label: "Self" }];
              var arr2 = [];
              console.log(userlistres.usersData);
              if (userlistres.usersData.length != 0) {
                userlistres.usersData.map((v, index) =>
                  v.Family_User.inviteStatus != "Removed"
                    ? arr2.push({
                        value: v.FamilyUserId,
                        label:
                          v.Family_User.User.firstName === "null"
                            ? v.Family_User.User.email
                            : v.Family_User.User.firstName +
                              " " +
                              v.Family_User.User.lastName,
                      })
                    : ""
                );
                var optionArr = [...arr1, ...arr2];
                console.log(optionArr);
                setAssigneeOption(optionArr);
              } else {
                setAssigneeOption(arr1);
              }
            }
          }
        );
      },
      (error) => {}
    );
  };

  useEffect(() => {
    console.log(userUUID);
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setTaskUrl("/user_todos/" + uuid + "/" + userUUID);
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
        setFamilyAUUserName(
          response.userData.firstName + " " + response.userData.lastName
        );
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setTaskUrl("/todos/" + uuid);
    }

    // add task
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setLoading(true);

      let data = {
        name: formValues.task_name,
        details: formValues.task_details,
        eta: formValues.eta_date,
        taskRecurrence: isSwitchOn ? true : false,
        taskFrequency: formValues.task_frequency
          ? formValues.task_frequency
          : null,
        noOfOccurrence: formValues.noOfOccurrence
          ? formValues.noOfOccurrence
          : null,
        assigneeId: formValues.assignee, //FamilyUser's Id give
        createdBy: userCreateUpdate, //FamilyUser's Id give
        updatedBy: userCreateUpdate, //FamilyUser's Id give
        FamilyUserId: userCreateUpdateFor, //FamilyUser's Id give (this is createdupdatedfor)
        FamilyId: familyDetails.FamilyData.FamilyId,
      };
      console.log(data);
      if (
        isSwitchOn === true &&
        (formValues.task_frequency === "" || formValues.noOfOccurrence === "")
      ) {
        setLoading(false);
        setSuccessToast(false);
        setShowToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setToastMessage("Please fill Activity Reccurency field");
      } else {
        // api call
        UserService.createTask(data).then(
          (response) => {
            setLoading(false);
            setSuccessToast(true);
            setShowToast(true);
            executeScroll();
            window.scrollTo(0, 0);
            setToastMessage("To Do created successfully");
            setTimeout(() => {
              history.push(taskUrl);
            }, 2000);
          },
          (error) => {}
        );

        // end api call
        setFrequencyOption(initialFrequencyOption);
        // setIsSwitchOn(true);
      }
    } else {
      setLoading(false);
    }
  }, [formErrors]);

  // d - is a moment() call
  const addMonth = (d, n) => {
    let fm = moment(d).add(n, "M");
    return fm.format("YYYY-MM-DD");
  };

  return (
    <div className="page-content add-warranty-pg">
      <Container fluid ref={myRef}>
        {/* toast */}
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {/* <h3 className="title">
          {familyDetails.FamilyData ? familyDetails.FamilyData.Family.name : ""}
        </h3> */}
        {/*  */}
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row className="fixed-form-header">
            <span className="nav-back">
              <Link to={taskUrl}>
                <p className="mb-3">
                  <img src={leftArrow} alt="arrow" className="mr-2" />
                  {userUUID ? `Back to ${backText}'s To Do's` : backText}
                </p>
              </Link>
            </span>
            <Col md="8" lg="8" className="mt-2">
              <h3>Add a New To Do</h3>
              <p className="sub-title">Add the below required details</p>
            </Col>
            <Col md="4" lg="4" className="mt-2">
              <span className="align-right px-5">
                {/* {loading && (
                  <span className="mx-3 spinner-border spinner-border-sm"></span>
                )} */}
                {/* <Primary
                  children="Add Task"
                  size="mediumbtn"
                  variant="primary"
                  type="submit"
                  buttonWidth="170px"
                  font_Weight="500"
                  isLoading={loading ? "true" : "false"}
                  isDisabled={loading ? "true" : "false"}
                /> */}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" className="fixed-form-content">
              {/* Row 1 */}
              <Row>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Medium
                      type="text"
                      placeholder="Ex : Car insurance"
                      size="medium"
                      name="task_name"
                      value={formValues.task_name}
                      onChange={handleChange}
                    />
                    <Form.Text
                      className={formErrors.task_name ? "invalid-text" : " "}
                    >
                      Enter Name relevant to the activity
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              {/* row 2 */}
              <Row>
                <Col md="10" lg="8">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Details ( Optional )</Form.Label>
                    <textarea
                      rows={3}
                      cols={5}
                      name="task_details"
                      value={formValues.task_details}
                      onChange={handleChange}
                      placeholder=""
                      maxLength={150}
                    ></textarea>
                    <Form.Text
                      className={formErrors.task_details ? "invalid-text" : " "}
                    >
                      Add a description of what you intend to do or what you
                      need a support user to do for you
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md="12" lg="12" className="mt-4">
              {/* row 1 */}
              <Row>
                <Col md="6" lg="5">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Activity Recurrence</Form.Label>
                    <Form.Switch
                      onChange={onSwitchAction}
                      label=""
                      checked={isSwitchOn}
                      className="align-right sub-form-switch task-custom-switch"
                      // id="task-custom-switch"
                    />
                    <p
                      className={
                        isSwitchOn
                          ? "reminder-switch-on"
                          : "reminder-switch-off"
                      }
                    >
                      Activity Recurrence
                    </p>
                    <Form.Text
                      className={formErrors.date ? "invalid-text mt-2" : "mt-2"}
                    >
                      Is the activity reoccurring?
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="">Activity Date</Form.Label>
                    <Medium
                      type="date"
                      placeholder="Ex 20/10/2022"
                      size="medium"
                      name="eta_date"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      value={formValues.eta_date}
                      onChange={handleChange}
                      //(event) => this.handleUserInput(event)
                    />
                    <Form.Text
                      className={formErrors.eta_date ? "invalid-text" : " "}
                      style={{ "margin-bottom": "2.55rem" }}
                    >
                      Select the date on which you would like a reminder
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md="4" lg="4">
                  {isSwitchOn ? (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="">Activity Frequency</Form.Label>
                      <Select
                        styles={customStyles}
                        options={frequencyOption}
                        defaultValue={{
                          label: "select activity frequency",
                          value: "",
                        }}
                        onChange={handleChange}
                        name="task_frequency"
                      />
                      <Form.Text
                        className={
                          formErrors.task_frequency ? "invalid-text" : " "
                        }
                      >
                        Select the frequency at which you want to be reminded
                        for the to do
                      </Form.Text>
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md="4" lg="4">
                  {isSwitchOn ? (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="">Number of Occurrence</Form.Label>
                      <Select
                        styles={customStyles}
                        options={noOfOccurrence}
                        defaultValue={{
                          label: "select number of Occurrence",
                          value: "",
                        }}
                        onChange={handleChange}
                        name="noOfOccurrence"
                      />
                      <Form.Text
                        className={
                          formErrors.noOfOccurrence ? "invalid-text" : " "
                        }
                      >
                        Select the number of times the activity has to be
                        recurred
                      </Form.Text>
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              {/* row 2 */}

              {/* row 3 */}
              <Row>
                <Col md="5" lg="4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Assignee</Form.Label>
                    <Select
                      styles={customStyles}
                      options={assigneeOption}
                      defaultValue={{ label: "select a assignee", value: "" }}
                      onChange={handleChange}
                      name="assignee"
                    />
                    <Form.Text
                      className={formErrors.assignee ? "invalid-text" : " "}
                    >
                      Select the user who will be assigned this activity.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-3">
            <div
              style={{
                "margin-top": "1rem",
                "margin-bottom": "2rem",
              }}
            >
              <Primary
                children="Add To Do"
                size="mediumbtn"
                variant="primary"
                type="submit"
                buttonWidth="170px"
                font_Weight="500"
                isLoading={loading ? "true" : "false"}
                isDisabled={loading ? "true" : "false"}
              />
            </div>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
export default TasksAdd;
