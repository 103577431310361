import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Form, Card } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import ProfileAvatar from "../../assets/images/profile_avatar.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { Info, Sms } from "../../stories/ActionAlert.stories";
import UserService from "../../services/user.service";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import { Secondary } from "../../stories/Button.stories";
import Modal from "react-bootstrap/Modal";
import ReactCodeInput from "react-verification-code-input";
import { Medium } from "../../stories/Input.stories";
import { Primary } from "../../stories/Button.stories";

const AuthHeader = (props) => {
  const RedFamDash = localStorage.getItem("RedFamDash");
  const [userType, setuserType] = useState("");
  const splitUrl = window.location.href.split("/");
  console.log(splitUrl);
  let { uuid } = useParams();
  let history = useHistory();
  let items = JSON.parse(localStorage.getItem("UserDetails"));
  const userIDVal = localStorage.getItem("userIDVal");
  const [shown, setshown] = useState(false);
  const [shown1, setshown1] = useState(false);
  const [shown2, setshown2] = useState(false);
  const [shown3, setshown3] = useState(false);
  const [shown4, setshown4] = useState(false);
  const [shown5, setshown5] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [formCodeValues, setFormCodeValues] = useState("");
  const [formCodeErrors, setFormCodeErrors] = useState({});
  const [smsErr, setSmsErr] = useState({});
  const [isCodeSubmit, setIsCodeSubmit] = useState(false);
  const [isSMSSubmit, setIsSMSSubmit] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [showMModel, setShowMModel] = useState(false);
  const [FamilyDataList, setFamilyDataList] = useState();
  const [showUpdateFmlyModal, setShowUpdateFmlyModal] = useState(false);
  const handleUpdateFmlyClose = () => setShowUpdateFmlyModal(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [updateFmlyFormErrors, setUpdateFmlyFormErrors] = useState("");
  const [isUpdateFmlySubmit, setIsUpdateFmlySubmit] = useState(false);
  const initialFmlyNameValues = {
    family_name: "",
  };
  const [updateFmlyFormValues, setUpdateFmlyFormValues] = useState(
    initialFmlyNameValues
  );

  const handleUpdateFmlyChange = (e) => {
    const { name, value } = e.target;
    setUpdateFmlyFormValues({ ...updateFmlyFormValues, [name]: value });
  };

  const [checkFam, setCheckfam] = useState(false);
  const [familyLengthVal, setfamilyLengthVal] = useState([]);

  useEffect(() => {
    UserService.getUserSingleFamilyDetails(splitUrl[4], UserDetails.id).then(
      (response) => {
        console.log(response);
        const initialFmlyNameValues = {
          family_name:
            response.FamilyData != null ? response.FamilyData.Family.name : "",
        };
        setUpdateFmlyFormValues(initialFmlyNameValues);
        if (response.FamilyData) {
          if (
            response.FamilyData.isAo &&
            response.FamilyData.isAu &&
            response.FamilyData.isSu
          ) {
            setuserType("AO + AU + SU");
          } else if (response.FamilyData.isAo && response.FamilyData.isAu) {
            setuserType("AO + AU");
          } else if (response.FamilyData.isAo && response.FamilyData.isSu) {
            setuserType("AO + SU");
          } else if (response.FamilyData.isAu && response.FamilyData.isSu) {
            setuserType("AU + SU");
          } else if (response.FamilyData.isAo) {
            setuserType("AO");
          } else if (response.FamilyData.isAu) {
            setuserType("AU");
          } else if (response.FamilyData.isSu) {
            setuserType("SU");
          }
        }
        setCheckfam(true);
      }
    );

    UserService.getUserAccountPlan(UserDetails.uuid).then(
      (response) => {
        var newData = [];
        for (var i = 0; i < response.usersFamilyData.length; i++) {
          if (response.usersFamilyData[i].Family.status !== "InActive") {
            newData.push(response.usersFamilyData[i]);
          }
        }
        console.log(newData);
        setfamilyLengthVal(newData);
      },
      (error) => {}
    );
    console.log(UserDetails);
  }, []);

  // code verification model
  const [showVerificationModel, setShowVerificationModel] = useState(false);
  const [smsmodal, setSmsModal] = useState(false);

  const handleShowVerificationModelClose = () => {
    setShowVerificationModel(false);
  };
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  if (!items) {
    history.push("/");
  }
  const handleLogout = () => {
    AuthService.logout();
    history.push("/");
  };
  const noImgError = (e) => {
    e.target.setAttribute("src", ProfileAvatar);
  };
  const handleVerificationChange = (e) => {
    let codeValue = e;
    setFormCodeValues(codeValue);
  };
  const [first, setfirst] = useState("");
  const handleVerificationSubmit = (e) => {
    setfirst("");
    e.preventDefault();
    setFormCodeErrors(validateEmailCode(formCodeValues));
    setIsCodeSubmit(true);
    setLoadingCode(true);
  };
  const validateEmailCode = (values) => {
    setLoadingCode(false);
    const c_errors = {};
    console.log(values, values.length);
    if (values.length !== 6) {
      c_errors.msg = "Please enter valid verification code";
      c_errors.code = "Yes";
      setLoadingCode(false);
      if (first === "") {
        setSuccessToast(false);
        setShowToast(true);
        setToastMessage(c_errors.msg);
      }
    }
    return c_errors;
  };
  const handleResendEmailCode = (userUUID) => {
    setfirst("first");
    setLoadingCode(true);
    UserService.resendUserEmailCode(userUUID).then(
      (response) => {
        setSuccessToast(true);
        setShowToast(true);
        setToastMessage("Verification code sent successfully");
        setFormCodeValues("");
        setLoadingCode(false);
        setShowMModel(false);
        setTimeout(() => {
          setShowVerificationModel(true);
        }, 2000);
      },
      (error) => {}
    );
  };

  const handleResendEmailCodeFirst = (userUUID) => {
    setfirst("");
    setLoadingCode(true);
    UserService.resendUserEmailCode(userUUID).then(
      (response) => {
        setSuccessToast(true);
        setShowToast(true);
        setToastMessage("Verification code sent successfully");
        setFormCodeValues("");
        setLoadingCode(false);
        setShowMModel(false);
        setTimeout(() => {
          setShowVerificationModel(true);
        }, 3000);
      },
      (error) => {}
    );
  };

  const handleSMS = (userUUID) => {
    setfirst("");
    // setLoadingCode(true);
    UserService.resendUserSMSCode(userUUID, {}).then(
      (response) => {
        if (response.status === 200) {
          setSuccessToast(true);
          setShowToast(true);
          setToastMessage("Verification code sent via SMS successfully");
          setFormCodeValues("");
          console.log(response);
          setTimeout(() => {
            setSmsModal(true);
          }, 3000);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleSMSAgain = (userUUID) => {
    setfirst("");
    // setLoadingCode(true);
    UserService.resendUserSMSCode(userUUID, {}).then(
      (response) => {
        if (response.status === 200) {
          setSuccessToast(true);
          setShowToast(true);
          setToastMessage("Verification code sent via SMS successfully");
          console.log(response);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleVerificationSubmitSMS = (e) => {
    setfirst("");
    e.preventDefault();
    setSmsErr(validateSmsCode(formCodeValues));
    setIsSMSSubmit(true);
  };

  const validateSmsCode = (values) => {
    const c_errors = {};
    setLoadingCode(true);
    console.log(values, values.length);
    if (values.length !== 6) {
      c_errors.msg = "Please enter valid verification code";
      c_errors.code = "Yes";
      setLoadingCode(false);
      setSuccessToast(false);
      setShowToast(true);
      setToastMessage(c_errors.msg);
    } else {
      let data = {
        code: formCodeValues,
      };

      // api call
      UserService.smscheck(items.uuid, data).then(
        (response) => {
          console.log(response);
          if (response && response.status === 200) {
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Contact number verified");
            const newObj = {
              ...items,
              isContactVerified: true,
            };
            localStorage.setItem("UserDetails", JSON.stringify(newObj));
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            setLoadingCode(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage("Invalid verifiaction code !");
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status === 400) {
            setLoadingCode(false);
          }
        }
      );
    }
    return c_errors;
  };

  useEffect(() => {
    // email verification form submit
    if (Object.keys(formCodeErrors).length === 0 && isCodeSubmit) {
      let data = {
        code: formCodeValues,
      };
      setLoadingCode(true);
      // api call
      AuthService.verifyUserEmail(data, items.uuid).then(
        (res) => {
          if (res.status === 400) {
            setFormCodeValues("");
            setLoadingCode(false);
            setShowVerificationModel(true);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.error);
          } else {
            setLoadingCode(false);
            setShowVerificationModel(false);
            items["isEmailVerified"] = true;
            localStorage.setItem("UserDetails", JSON.stringify(items));
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Email verified successfully");
          }
        },
        (error) => {
          const errors = {};

          errors.msg = error.response;
          if (errors.msg) {
            setFormCodeErrors(errors);
          }
          setLoadingCode(false);
        }
      );
    } else {
      setLoadingCode(false);
    }
  }, [formCodeErrors]);

  // useEffect(() => {
  //   // email verification form submit
  //   if (Object.keys(smsErr).length === 0 && isSMSSubmit) {
  //     let data = {
  //       code: formCodeValues,
  //     };
  //     setLoadingCode(true);
  //     // api call
  //     UserService.smscheck(items.uuid, data).then(
  //       (response) => {
  //         console.log(response);
  //         if (response && response.status === 200) {
  //           setSuccessToast(true);
  //           setShowToast(true);
  //           setToastMessage("Contact number verified");
  //           const newObj = {
  //             ...items,
  //             isContactVerified: true,
  //           };
  //           localStorage.setItem("UserDetails", JSON.stringify(newObj));
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 3000);
  //         } else {
  //           setLoadingCode(false);
  //           setSuccessToast(false);
  //           setShowToast(true);
  //           setToastMessage("Invalid verifiaction code !");
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //         if (error.response.status === 400) {
  //           setLoadingCode(false);
  //         }
  //       }
  //     );
  //   } else {
  //     setLoadingCode(false);
  //   }
  // }, [smsErr]);

  useEffect(() => {
    UserService.userProfile(UserDetails.uuid).then(
      (response) => {
        setFamilyDataList(response.userData.createdAt);
      },
      (error) => {}
    );
  });

  var myData = JSON.parse(localStorage.getItem("UserDetails"));
  if (myData === null || items === null) {
    window.location.href = "/";
  }
  var CreationDate = FamilyDataList;
  var CD = new Date(Date.parse(CreationDate) + 6 * 24 * 60 * 60 * 1000);
  var dd1 = String(CD.getDate()).padStart(2, "0");
  var mm1 = String(CD.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy1 = CD.getFullYear();
  CD = dd1 + "/" + mm1 + "/" + yyyy1;
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + "/" + mm + "/" + yyyy;
  const date = new Date(Date.parse(CreationDate));
  date.setDate(date.getDate() + 6);

  useEffect(() => {
    if (date2 > date1) {
      if (items.isEmailVerified === false && items.isActive === true) {
        if (showVerificationModel === true) {
          setShowMModel(false);
        } else {
          setShowMModel(true);
        }
      }
      if (items.isEmailVerified === true && items.isActive === true) {
        if (showVerificationModel === true) {
          setShowMModel(false);
        } else {
          setShowMModel(true);
        }
      }
    } else {
      setShowMModel(false);
    }
  }, [items]);

  console.log("userType::::::::::::::::::::", userType);

  useEffect(() => {
    if (familyLengthVal.length > 0) {
      if (userType) {
        console.log(userType);
        console.log(familyLengthVal);
        if (
          userType === "AO + AU" ||
          userType === "AO + AU + SU" ||
          userType === "AO" ||
          userType === "AO + SU"
        ) {
          setshown(true);
          setshown1(false);
          setshown2(false);
          setshown3(false);
          setshown4(false);
          setshown5(false);
        } else {
          setshown1(true);
          setshown(false);
          setshown2(false);
          setshown3(false);
          setshown4(false);
          setshown5(false);
        }
      } else {
        console.log(userType);
        console.log(familyLengthVal);
        if (splitUrl[3] === "dashboard") {
          setshown(false);
          setshown1(false);
          setshown2(true);
          setshown3(false);
          setshown4(false);
          setshown5(false);
        } else if (splitUrl[3] === "subscription") {
          setshown(false);
          setshown1(false);
          setshown2(false);
          setshown3(true);
          setshown4(false);
          setshown5(false);
        }
      }
    } else {
      if (splitUrl[3] === "subscription") {
        setshown(false);
        setshown1(false);
        setshown2(false);
        setshown3(false);
        setshown4(true);
        setshown5(false);
      }
      if (splitUrl[3] === "myInvites") {
        setshown(false);
        setshown1(false);
        setshown2(false);
        setshown3(false);
        setshown4(false);
        setshown5(true);
      }
    }
  }, [RedFamDash, UserDetails, splitUrl, userType, uuid, checkFam]);

  var date1 = date;
  var date2 = new Date();

  if (date1 < date2) {
  } else {
  }

  const handleeventlog = () => {
    history.push(userIDVal ? `/family_notification/${splitUrl[4]}` : "#");
  };

  const handleInvites = () => {
    console.log(splitUrl);
    console.log(UserDetails);
    if (splitUrl[4]) {
      history.push(`/myInvites/${splitUrl[4]}`);
    } else {
      history.push(`/myInvites/not-found`);
    }
  };

  const handleProfile = () => {
    history.push(`/profile/${splitUrl[4]}`);
  };

  const handleChangeGroupName = () => {
    setShowUpdateFmlyModal(true);
  };

  const handleSubscription = () => {
    if (userType) {
      history.push(`/subscription/${splitUrl[4]}`);
    } else {
      history.push(`/subscription`);
    }
  };

  const handleFamilyNameSubmit = (e) => {
    e.preventDefault();
    setUpdateFmlyFormErrors(validateFamilyName(updateFmlyFormValues));
    setIsUpdateFmlySubmit(true);
  };

  const validateFamilyName = (values) => {
    const errors = {};
    if (!values.family_name) {
      errors.msg = "Please enter group name";
      errors.family_name = "Yes";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(updateFmlyFormErrors).length === 0 && isUpdateFmlySubmit) {
      setFormLoading(true);
      let data = {
        name: updateFmlyFormValues.family_name,
      };
      // api call
      UserService.updateFamilyName(uuid, data).then(
        (res) => {
          if (res.status === 400) {
            setFormLoading(false);
            setShowUpdateFmlyModal(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
          } else {
            setFormLoading(false);
            setShowUpdateFmlyModal(false);
            //executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Group Name have been successfully updated");
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          setFormLoading(false);
        }
      );
    }
  }, [updateFmlyFormErrors]);
  const handleChangePlan = () => {
    if (splitUrl[4] !== undefined) {
      history.push(`/manageSubscription/${splitUrl[4]}`);
    } else {
      history.push(`/manageSubscription/${userIDVal}`);
    }
  };

  if (splitUrl[3] !== "confirmation" && splitUrl[3] !== "onboardUser") {
    return (
      <div className="header">
        <Container fluid>
          <ToastBlockContainer
            successToast={successToast}
            showToast={showToast}
            setShowToast={setShowToast}
            toastMessage={toastMessage}
          />
          <Row>
            <Col md="10" lg="9">
              {splitUrl[3] === "profile" ? (
                items.isEmailVerified && items.isContactVerified ? (
                  ""
                ) : (
                  <Card className={`action-alert-card info-alert mb-4 gap-3`}>
                    {items.isEmailVerified === false &&
                      items.isActive === true && (
                        <Info
                          variant="info"
                          message="Your email is not verified yet. Please verify your email to continue using our services"
                          onButtonClick={() =>
                            handleResendEmailCodeFirst(items.uuid)
                          }
                        />
                      )}
                    {items.isContactVerified === false &&
                      items.isActive === true && (
                        <Sms
                          variant="sms"
                          message="Your contact number is not verified yet. Please verify your number to continue using our services"
                          onButtonClick={() => handleSMS(items.uuid)}
                        />
                      )}
                  </Card>
                )
              ) : (
                ""
              )}
            </Col>
            <Col md="2" lg="3">
              <div className="align-right">
                <Dropdown className="profile-dropdwn">
                  <Dropdown.Toggle variant="Warning" id="dropdown-basic">
                    <Image
                      className="profile-avatar"
                      src={items.avatar}
                      onError={noImgError}
                      alt="Profile Avatar"
                      id="dropdown-basic"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="pt-0">
                    {shown && (
                      <>
                        <>
                          <p style={{ "margin-bottom": "7px" }}></p>
                          <Dropdown.Item onClick={handleSubscription}>
                            Create New Group
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item onClick={handleProfile}>
                            My Profile
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item onClick={handleChangeGroupName}>
                            Change Group Name
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item onClick={handleChangePlan}>
                            Manage Account Plan
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item
                            onClick={() =>
                              history.push(`/manage_users/${splitUrl[4]}`)
                            }
                          >
                            {userType === "AO" ||
                            userType === "AU" ||
                            userType === "AU + SU" ||
                            userType === "SU"
                              ? "All Users"
                              : "Manage Users"}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <p style={{ "margin-bottom": "7px" }}></p>
                          <Dropdown.Item onClick={handleInvites}>
                            My Invites
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item onClick={handleeventlog}>
                            Event Logs
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item href="#" onClick={handleLogout}>
                            Log off
                          </Dropdown.Item>{" "}
                        </>
                      </>
                    )}
                    {shown1 && (
                      <>
                        <>
                          <p style={{ "margin-bottom": "7px" }}></p>
                          <Dropdown.Item onClick={handleSubscription}>
                            Create New Group
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item onClick={handleProfile}>
                            My Profile
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item
                            onClick={() =>
                              history.push(`/manage_users/${splitUrl[4]}`)
                            }
                          >
                            {userType === "AO" ||
                            userType === "AU" ||
                            userType === "AU + SU" ||
                            userType === "SU"
                              ? "All Users"
                              : "Manage Users"}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <p style={{ "margin-bottom": "7px" }}></p>
                          <Dropdown.Item onClick={handleInvites}>
                            My Invites
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item href="#" onClick={handleLogout}>
                            Log off
                          </Dropdown.Item>{" "}
                        </>
                      </>
                    )}
                    {shown2 && (
                      <>
                        <p style={{ "margin-bottom": "7px" }}></p>
                        <Dropdown.Item href="#" onClick={handleLogout}>
                          Log off
                        </Dropdown.Item>{" "}
                      </>
                    )}
                    {shown3 && (
                      <>
                        <>
                          <p style={{ "margin-bottom": "7px" }}></p>
                          <Dropdown.Item onClick={handleInvites}>
                            My Invites
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item
                            onClick={() =>
                              history.push(`/manage_users/${splitUrl[4]}`)
                            }
                          >
                            {userType === "AO" ||
                            userType === "AU" ||
                            userType === "AU + SU" ||
                            userType === "SU"
                              ? "All Users"
                              : "Manage Users"}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item href="#" onClick={handleLogout}>
                            Log off
                          </Dropdown.Item>{" "}
                        </>
                      </>
                    )}

                    {shown4 && (
                      <>
                        <>
                          <p style={{ "margin-bottom": "7px" }}></p>
                          <Dropdown.Item onClick={handleInvites}>
                            My Invites
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>

                        <>
                          <Dropdown.Item href="#" onClick={handleLogout}>
                            Log off
                          </Dropdown.Item>{" "}
                        </>
                      </>
                    )}

                    {shown5 && (
                      <>
                        <>
                          <p style={{ "margin-bottom": "7px" }}></p>
                          <Dropdown.Item onClick={handleSubscription}>
                            Create New Group
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                        <>
                          <Dropdown.Item href="#" onClick={handleLogout}>
                            Log off
                          </Dropdown.Item>{" "}
                        </>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            {/* verify email model */}
            {/* {items.isEmailVerified === false &&
            items.isActive === true &&
            (date2 < date1 || date2 === date1) ? (
              <Modal
                show={showVerificationModel}
                onHide={handleShowVerificationModelClose}
                className="verification_modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3>Verify Email</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="mb-4">
                    We have sent a 6 digit verification code to your email{" "}
                    <strong>{items.email}</strong>.
                  </p>
                  <Form
                    className="verification-form"
                    onSubmit={handleVerificationSubmit}
                  >
                    <p className="mb-2">Verification Code</p>
                    <ReactCodeInput
                      value={formCodeValues}
                      name="code"
                      fields={6}
                      loading={loadingCode}
                      onChange={handleVerificationChange}
                      className={formCodeErrors.code ? "invalid-text" : ""}
                    />
                    <input type="hidden" name="regUser" value={items.uuid} />

                    <div className="align-center py-4">
                      <Secondary
                        children="Submit Code"
                        size="mediumbtn"
                        variant="secondary"
                        type=""
                        buttonWidth="260px"
                        font_Weight="600"
                        isLoading={loadingCode ? "true" : "false"}
                        isDisabled={loadingCode ? "true" : "false"}
                      />
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            ) : items.isEmailVerified === false &&
              items.isActive === true &&
              date2 > date1 ? (
              <Modal
                show={showVerificationModel}
                onHide={handleShowVerificationModelClose}
                className="verification_modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3>Verify Email</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="mb-4">
                    We have sent a 6 digit verification code to your email{" "}
                    <strong>{items.email}</strong>.
                  </p>
                  <Form
                    className="verification-form"
                    onSubmit={handleVerificationSubmit}
                  >
                    <p className="mb-2">Verification Code</p>
                    <ReactCodeInput
                      value={formCodeValues}
                      name="code"
                      fields={6}
                      onChange={handleVerificationChange}
                      className={formCodeErrors.code ? "invalid-text" : ""}
                    />
                    <input type="hidden" name="regUser" value={items.uuid} />

                    <div className="align-center py-4">
                      <Secondary
                        children="Verify email"
                        size="mediumbtn"
                        variant="secondary"
                        type=""
                        buttonWidth="260px"
                        font_Weight="600"
                        loading={loadingCode}
                        isLoading={loadingCode ? "true" : "false"}
                        isDisabled={loadingCode ? "true" : "false"}
                      />
                    </div>
                    <div className="align-center pb-4">
                      <button
                        onClick={() => handleResendEmailCode(items.uuid)}
                        className="right-auth-dsgn-1"
                      >
                        {" "}
                        Resend the Code
                      </button>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            ) : (
              ""
            )} */}
            {items.isEmailVerified === false && items.isActive === true && (
              <Modal
                show={showVerificationModel}
                onHide={handleShowVerificationModelClose}
                className="verification_modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3>Verify Email</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="mb-4">
                    We have sent a 6 digit verification code to your email{" "}
                    <strong>{items.email}</strong>.
                  </p>
                  <Form
                    className="verification-form"
                    onSubmit={handleVerificationSubmit}
                  >
                    <p className="mb-2">Verification Code</p>
                    <ReactCodeInput
                      value={formCodeValues}
                      name="code"
                      fields={6}
                      onChange={handleVerificationChange}
                      className={formCodeErrors.code ? "invalid-text" : ""}
                    />
                    <input type="hidden" name="regUser" value={items.uuid} />

                    <div className="align-center py-4">
                      <Secondary
                        children="Verify email"
                        size="mediumbtn"
                        variant="secondary"
                        type=""
                        buttonWidth="260px"
                        font_Weight="600"
                        loading={loadingCode}
                        isLoading={loadingCode ? "true" : "false"}
                        isDisabled={loadingCode ? "true" : "false"}
                      />
                      {/* {loadingCode && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )} */}
                    </div>
                    <div className="align-center pb-4">
                      {/* <Secondary
                        children="Resend Code"
                        size="mediumbtn"
                        variant="secondary"
                        type=""
                        buttonWidth="260px"
                        font_Weight="600"
                        isLoading={loadingCode ? "true" : "false"}
                        isDisabled={loadingCode ? "true" : "false"}
                      /> */}
                      {/* {loadingCode && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )} */}
                      <button
                        onClick={() => handleResendEmailCode(items.uuid)}
                        className="right-auth-dsgn-1"
                      >
                        {" "}
                        Resend the Code
                      </button>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            )}
          </Row>
          <Modal
            show={showUpdateFmlyModal}
            onHide={handleUpdateFmlyClose}
            className="choose_plan_modal"
          >
            <Modal.Header closeButton>
              {/* <Modal.Title><h3>Confirm Account plan</h3></Modal.Title>*/}
              <Modal.Title>
                <h3>Update Group Name</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="family-form" onSubmit={handleFamilyNameSubmit}>
                {/* <p className="mb-1 subtext">Verify the chosen plan and proceed to payment.</p>
                        <p className="subtext">Changed your mind? <Link to="/subscription" className="modal-link" onClick={handleSwitchPlan}> Choose another plan </Link></p> */}
                {Object.keys(formErrors).length !== 0 && isUpdateFmlySubmit ? (
                  <p className="error-txt-msg">{formErrors.msg} </p>
                ) : (
                  ""
                )}
                <Form.Group className="mb-3" controlId="">
                  <Form.Label
                    className={
                      formErrors.familyName
                        ? "invalid-text mt-3 mb-0"
                        : "mt-3 mb-0"
                    }
                  >
                    Group Name
                  </Form.Label>
                  <Medium
                    type="test"
                    errorClass={
                      updateFmlyFormErrors.family_name ? "invalid-input" : ""
                    }
                    placeholder="Enter Group Name"
                    size="medium"
                    name="family_name"
                    value={updateFmlyFormValues.family_name}
                    onChange={handleUpdateFmlyChange}
                    maxlength="40"
                  />
                  <p
                    className={
                      updateFmlyFormErrors.family_name
                        ? "invalid-text subtext mt-1"
                        : "subtext mt-1"
                    }
                  >
                    Add a name to your group on EL platform. It should be under
                    40 characters.
                  </p>
                </Form.Group>
                <div className="text-center mt-5">
                  <Primary
                    children="Update"
                    variant="primary"
                    size="mediumbtn"
                    type="submit"
                    buttonWidth="230px"
                    isLoading={formLoading ? "true" : "false"}
                    isDisabled={formLoading ? "true" : "false"}
                  />
                  {/* {formLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={smsmodal}
            onHide={() => setSmsModal(false)}
            className="choose_plan_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Verify Contact Number</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mb-4">
                We have sent a 6 digit verification code to verify the contact
                number mentioned against your Elderly Life account .
              </p>
              <Form
                className="verification-form"
                onSubmit={handleVerificationSubmitSMS}
              >
                <p className="mb-2">Verification Code</p>
                <ReactCodeInput
                  value={formCodeValues}
                  name="code"
                  fields={6}
                  onChange={handleVerificationChange}
                  className={formCodeErrors.code ? "invalid-text" : ""}
                />
                <input type="hidden" name="regUser" value={items.uuid} />

                <div className="align-center py-4">
                  <Secondary
                    children="Verify contact number"
                    size="mediumbtn"
                    variant="secondary"
                    type=""
                    buttonWidth="260px"
                    font_Weight="600"
                    loading={loadingCode}
                    isLoading={loadingCode ? "true" : "false"}
                    isDisabled={loadingCode ? "true" : "false"}
                  />
                </div>
                <div className="align-center pb-4">
                  <button
                    onClick={() => handleSMSAgain(items.uuid)}
                    className="right-auth-dsgn-1"
                  >
                    {" "}
                    Resend the Code
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    );
  } else {
    return "";
  }
};
export default AuthHeader;
