/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import UserService from "../../services/user.service";
import { Primary } from "../../stories/Button.stories";
import Loading from "../../common/Loading";
import leftArrow from "../../assets/images/left.svg";
import { Medium } from "../../stories/Input.stories";
import Select from "react-select";
import docIcon from "../../assets/images/doc_icon.png";
import closeIcon from "../../assets/images/close_icon.png";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Cookies from "universal-cookie";
const EditSubscription = (props) => {
  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const myRef = useRef(null);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [Loadingdata, setLoadingdata] = useState(true);
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [subscriptionsUrl, setSubscriptionsUrl] = useState("");
  const [backText, setBackText] = useState("Back to My Outgoings");
  const [minEndDate, setMinEndDate] = useState("");
  const [mEDate, setMEDate] = useState("");
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [browserLoading, setBrowserLoading] = useState(false);
  const [browserMsg, setBrowserMsg] = useState("");
  const [browserErrMsg, setBrowserErrMsg] = useState("");
  const [uploadedDocUrl, setUploadedDocUrl] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [response1, setResponse1] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [addUpdate, setaddUpdate] = useState(false);
  // to store subacription default details
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  let { uuid, subId, userUUID } = useParams();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "0.8rem",
      borderRadius: "5px",
      borderStyle: "none",
      cursor: "pointer",
      padding: "6px 0",
      border: "1px solid #5985ac !important",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedFrequency, setSelectedFrequency] = useState();
  const [selectedChecked, setSelectedChecked] = useState();

  const newBtnWZ = (event) => {
    if (event.target.checked === true) {
      setValNew("hello");
    } else {
      setValNew("");
    }
  };

  //new state add
  const [selectedNewFrequency, setSelectedNewFrequency] = useState();
  const [valNew, setValNew] = useState("");
  const [valNew1, setValNew1] = useState("");
  const [valNew2, setValNew2] = useState("");

  const [categoryOption, setCategoryOption] = useState([]);
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [supplierOption, setSupplierOption] = useState([]);
  const [showInvoiceFile, setShowInvoiceFile] = useState("");
  const [value1, setvalue1] = useState("");

  const initialFrequencyOption = [
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Bi-Annually", label: "Bi Annually" },
    { value: "Yearly", label: "Yearly" },
  ];
  const [frequencyOption, setFrequencyOption] = useState(
    initialFrequencyOption
  );
  const [AmountVal, setAmountVal] = useState();

  const handleChange = (e, action) => {
    setValueChk("ValueChk");

    if (action) {
      var targetData = {
        name: action.name,
        value: e.value,
      };
      if (action.name === "category") {
        setSelectedCategory(e);
        setSelectedProduct({ value: "", label: "Select product type" });
        setSelectedSupplier({ value: "", label: "Select a supplier" });
        setFormErrors({
          category: "",
        });
      }
      if (action.name === "product_type") {
        setSelectedProduct(e);
        setSelectedSupplier({ value: "", label: "Select a supplier" });
        setFormErrors({
          product_type: "",
        });
      }
      if (action.name === "supplier") {
        setSelectedSupplier(e);
        setFormErrors({
          supplier: "",
        });
      }
      if (action.name === "frequency") {
        setSelectedFrequency(e);
      }
    } else if (e.target.name === "amount") {
      var targetData = {
        name: e.target.name,
        value:
          e.target.value.indexOf(".") >= 0
            ? e.target.value.substr(0, e.target.value.indexOf(".")) +
              e.target.value.substr(e.target.value.indexOf("."), 3)
            : e.target.value,
      };

      setAmountVal(targetData.value);
      setSelectedFrequency({
        value: response1,
        label: response1,
      });
    } else {
      var targetData = {
        name: e.target.name,
        value: e.target.value,
      };
    }
    var myNumber = formValues.amount;

    var myNumberWithTwoDecimalPlaces = parseFloat(myNumber).toFixed(2);

    const { name, value } = targetData;

    setFormValues({ ...formValues, [name]: value });

    if (name === "category") {
      // get subcategory listing
      let catId = value;
      UserService.getSubcategoryByCat(catId).then(
        (response) => {
          //setSubCategoryList(response.data);
          var sub_arr2 = response.data.map((v) => ({
            value: v.id,
            label: v.name,
          }));

          setSubCategoryOption(sub_arr2);
          setSelectedProduct({ value: "", label: "Select product type" });
          setSelectedSupplier({ value: "", label: "Select a supplier" });
        },
        (error) => {}
      );
    }

    if (name === "product_type") {
      // get supplier listing
      let subcatId = value;
      UserService.getSuppliersBySubCategory(subcatId).then(
        (response) => {
          //setSupplierList(response.data);
          var sup_arr2 = response.data.map((v) => ({
            value: v.id,
            label: v.name,
          }));

          setSupplierOption(sup_arr2);
          setSelectedSupplier({ value: "", label: "Select a supplier" });
        },
        (error) => {}
      );
    }
    if (name === "start_date") {
      var freqMonth = "";
      if (formValues.frequency == "Monthly") {
        freqMonth = "1";
      } else if (formValues.frequency == "Quarterly") {
        freqMonth = "3";
      } else if (formValues.frequency == "Bi-Annually") {
        freqMonth = "6";
      } else if (formValues.frequency == "Yearly") {
        freqMonth = "12";
      }
      setMinEndDate(addMonth(value, freqMonth));
    }
    if (name === "frequency") {
      var freqMonth = "";
      if (value == "Monthly") {
        freqMonth = "1";
      } else if (value == "Quarterly") {
        freqMonth = "3";
      } else if (value == "Bi-Annually") {
        freqMonth = "6";
      } else if (value == "Yearly") {
        freqMonth = "12";
      }
      setMinEndDate(addMonth(formValues.start_date, freqMonth));
    }
    // upload img
    if (name == "uploaded_doc") {
      //
      // let filename = value.split("\\");
      setaddUpdate(true);
      setBrowserLoading(true);
      setBrowserErrMsg("");

      // upload file
      var documentFile = e.target.files[0];

      var reader = new FileReader();
      reader.onload = function (e) {
        var MAX_FILE_SIZE = 2; // 2MB

        if (
          [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "application/pdf",
            // "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(documentFile.type)
        ) {
          let fileSize = documentFile.size / 1024 / 1024;
          if (fileSize > MAX_FILE_SIZE) {
            setBrowserErrMsg("OOPS! File size is above 2MB.");
            setBrowserLoading(false);
          } else {
            uploadFile(documentFile);
          }
        } else {
          setBrowserErrMsg("OOPS! The file format doesn't support");
          setBrowserLoading(false);
        }
      };

      // reader.readAsDataURL(documentFile);
      if (documentFile != undefined) {
        reader.readAsDataURL(documentFile);
      } else {
        setBrowserLoading(false);
      }
    }
  };
  const cookies = new Cookies();
  const userToken = cookies.get("accessToken");
  // file upload
  const uploadFile = (data) => {
    setBrowserErrMsg("");

    const formData = new FormData();
    formData.append("file", data, data.name.replace(/ /g, ""));

    UserService.uploadUserDocuments(formData).then(
      (response) => {
        setShowInvoiceFile(data.name.substr(0, 65));
        setBrowserLoading(false);
        setBrowserMsg("Successfully uploaded");
        setUploadedDocUrl(response.link);
        setaddUpdate(false);
      },
      (error) => {
        setBrowserLoading(false);
        setBrowserErrMsg("Something went error");
        setaddUpdate(false);
      }
    );
  };

  const removeUploadedFile = () => {
    setShowInvoiceFile("");
    setUploadedDocUrl("");
  };
  // end
  const [valueChk, setValueChk] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setValueChk("ValueChk");

    if (selectedProduct.value === "" && selectedSupplier.value != "") {
      setIsSubmit(false);
      if (AmountVal === 0 || AmountVal === "") {
        setFormErrors({
          amount: "amount",
          product_type: "product_type",
          category: "",
          supplier: "",
        });
      } else {
        setFormErrors({
          amount: "",
          product_type: "product_type",
          category: "",
          supplier: "",
        });
      }
      // setFormErrors({
      //   product_type: "product_type",
      //   category: "",
      //   supplier: "",
      // });
      setLoading(true);
      executeScroll();
      window.scrollTo(0, 0);
      // setSuccessToast(false);
      // setShowToast(true);
      // executeScroll();
      // setToastMessage("Please fill all the place");
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
    } else if (selectedProduct.value != "" && selectedSupplier.value === "") {
      setIsSubmit(false);
      if (AmountVal === 0 || AmountVal === "") {
        setFormErrors({
          amount: "amount",
          product_type: "",
          category: "",
          supplier: "supplier",
        });
      } else {
        setFormErrors({
          amount: "",
          product_type: "",
          category: "",
          supplier: "supplier",
        });
      }
      // setFormErrors({
      //   product_type: "",
      //   category: "",
      //   supplier: "supplier",
      // });
      setLoading(true);
      executeScroll();
      window.scrollTo(0, 0);
      // setSuccessToast(false);
      // setShowToast(true);
      // executeScroll();
      // setToastMessage("Please fill all the place");
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
    } else if (selectedProduct.value === "" && selectedSupplier.value === "") {
      setIsSubmit(false);
      if (AmountVal === 0 || AmountVal === "") {
        setFormErrors({
          amount: "amount",
          product_type: "product_type",
          category: "",
          supplier: "supplier",
        });
      } else {
        setFormErrors({
          amount: "",
          product_type: "product_type",
          category: "",
          supplier: "supplier",
        });
      }
      // setFormErrors({

      // });
      setLoading(true);
      executeScroll();
      window.scrollTo(0, 0);
      // setSuccessToast(false);
      // setShowToast(true);
      // executeScroll();
      // setToastMessage("Please fill all the place");
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
    } else {
      setIsSubmit(true);
      setLoading(true);
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.product_name) {
      errors.msg = "Please enter the product name";
      errors.product_name = "Yes";
    }
    if (!values.amount || values.amount == 0 || values.amount < 0) {
      errors.msg = "Please enter the amount";
      errors.amount = "Yes";
    }
    if (!values.category) {
      errors.msg = "Please enter the category";
      errors.category = "Yes";
    }
    if (!values.product_type) {
      errors.msg = "Please enter the product type";
      errors.product_type = "Yes";
    }
    if (!values.supplier) {
      errors.msg = "Please enter the supplier";
      errors.supplier = "Yes";
    }
    if (!values.frequency) {
      errors.msg = "Please enter the frequency";
      errors.frequency = "Yes";
      setIsSubmit(false);
    }
    if (!values.start_date) {
      errors.msg = "Please enter the start date";
      errors.start_date = "Yes";
    }
    if (!values.end_date) {
      if (valNew === "" && formValues.end_date === "dd-mm-hh") {
        errors.msg = "Please enter the end date";
        errors.end_date = "Yes";
        setIsSubmit(false);
      } else {
        setIsSubmit(true);
      }
    }
    return errors;
  };
  // d - is a moment() call
  const addMonth = (d, n) => {
    let fm = moment(d).add(n, "M");
    return fm.format("YYYY-MM-DD");
  };
  const getFamilyDetails = (userId) => {
    // AU Family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);

        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            setUserCreateUpdate(response.FamilyData.id);
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };

  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdate(response.FamilyData.id);
        setUserCreateUpdateFor(response.FamilyData.id);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    // get single subscription details by ID
    UserService.getSubscriptionDetailsById(subId).then(
      (response) => {
        setLoadingdata(true);

        setSubscriptionDetails(response.data);
        const initialValues = {
          category: response.data.rows[0].CategoryId,
          product_type: response.data.rows[0].SubCategoryId,
          product_name: response.data.rows[0].productName,
          supplier: response.data.rows[0].SupplierId,
          additional_details: response.data.rows[0].productDescription,
          amount: !formErrors.amount
            ? response.data.rows[0].premiumAmount
            : AmountVal,
          frequency: response.data.rows[0].renewalFrequency,
          start_date: moment(response.data.rows[0].startDate).format(
            "YYYY-MM-DD"
          ),

          end_date: response.data.rows[0].endDate
            ? new Date(response.data.rows[0].endDate).getFullYear() < 2100
              ? moment(response.data.rows[0].endDate).format("YYYY-MM-DD")
              : "dd-mm-hh"
            : null,

          contract_doc: "",
          uploaded_doc: response.data.rows[0].documentUrl,
        };
        setFormValues(initialValues);
        let docParams = response.data.rows[0].documentUrl
          ? response.data.rows[0].documentUrl.split("/")
          : "";
        setShowInvoiceFile(docParams[docParams.length - 1]);
        setUploadedDocUrl(response.data.rows[0].documentUrl);
        setIsSwitchOn(response.data.rows[0].reminder);

        if (valueChk === "") {
          // Products
          UserService.getSubcategoryByCat(
            response.data.rows[0].CategoryId
          ).then(
            (response) => {
              var sub_arr2 = response.data.map((v) => ({
                value: v.id,
                label: v.name,
              }));
              setSubCategoryOption(sub_arr2);
            },
            (error) => {}
          );

          // Suppliers
          UserService.getSuppliersBySubCategory(
            response.data.rows[0].SubCategoryId
          ).then(
            (response) => {
              var sup_arr2 = response.data.map((v) => ({
                value: v.id,
                label: v.name,
              }));
              setSupplierOption(sup_arr2);
            },
            (error) => {}
          );
          setSelectedCategory({
            value: response.data.rows[0].CategoryId,
            label: response.data.rows[0].Category
              ? response.data.rows[0].Category.name
              : "",
          });
          setSelectedProduct({
            value: response.data.rows[0].SubCategoryId,
            label: response.data.rows[0].SubCategory
              ? response.data.rows[0].SubCategory.name
              : "",
          });
          setSelectedSupplier({
            value: response.data.rows[0].Supplier
              ? response.data.rows[0].Supplier.id
              : "",
            label: response.data.rows[0].Supplier
              ? response.data.rows[0].Supplier.name
              : "",
          });

          setResponse1(response.data.rows[0].renewalFrequency);
          setSelectedFrequency({
            value: response.data.rows[0].renewalFrequency,
            label: response.data.rows[0].renewalFrequency,
          });
          setSelectedNewFrequency(response.data.rows[0].renewalFrequency);

          new Date(response.data.rows[0].endDate).getFullYear() > 2100
            ? setValNew("hello")
            : setValNew("");

          new Date(response.data.rows[0].endDate).getFullYear() > 2100
            ? setValNew1("")
            : setValNew1("");
        }

        var freqMonth = "";
        if (response.data.rows[0].renewalFrequency === "Monthly") {
          freqMonth = "1";
        } else if (response.data.rows[0].renewalFrequency === "Quarterly") {
          freqMonth = "3";
        } else if (response.data.rows[0].renewalFrequency === "Bi-Annually") {
          freqMonth = "6";
        } else if (response.data.rows[0].renewalFrequency === "Yearly") {
          freqMonth = "12";
        }
        setMEDate(addMonth(response.data.rows[0].startDate, freqMonth));
        setLoadingdata(false);
      },
      (error) => {
        setLoadingdata(false);
      }
    );

    // get category listing
    let moduleDataId = "1";
    UserService.getCategoryByModule(moduleDataId).then(
      (response) => {
        var arr2 = [];
        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].name != "ElderlyLife Subscription") {
            arr2.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
            setCategoryOption(arr2);
          }
        }
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setSubscriptionsUrl("/user_myoutgoings/" + uuid + "/" + userUUID);
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setSubscriptionsUrl("/myoutgoings/" + uuid);
    }

    // add subscription
    if (
      (valNew === "" && formValues.end_date === "dd-mm-hh") ||
      selectedProduct === undefined ||
      selectedSupplier === undefined
    ) {
      setValNew1("bye");
      setValNew2("true");
      setLoading(false);
    }
    if (
      (valNew === "hello" || formValues.end_date) &&
      selectedProduct &&
      selectedSupplier
    ) {
      if (
        Object.keys(formErrors).length === 0 &&
        formValues.amount > 0 &&
        isSubmit
      ) {
        setValNew1("");

        setLoading(true);

        let data = {
          CategoryId: formValues.category,
          SubCategoryId: formValues.product_type,
          SupplierId: formValues.supplier,
          productName: formValues.product_name,
          premiumAmount: formValues.amount,
          renewalFrequency: formValues.frequency,
          startDate: formValues.start_date,
          endDate: formValues.end_date,
          documentUrl: uploadedDocUrl,
          productDescription: formValues.additional_details,
          country: 1,
          currency: 1,
          reminder: isSwitchOn ? 1 : 0,
          familyId: familyDetails.FamilyData.FamilyId,
          updatedBy: userCreateUpdate,
          createdUpdatedFor: userCreateUpdateFor,
          createdBy: userCreateUpdate,
        };

        {
          valNew === "hello"
            ? (data.endDate = cde)
            : (data.endDate = formValues.end_date);
        }

        // api call
        UserService.updateSubscritpion(data, subId).then(
          (response) => {
            setLoading(false);
            setSuccessToast(true);
            setShowToast(true);
            executeScroll();
            window.scrollTo(0, 0);
            setToastMessage("Outgoing updated successfully");
            setTimeout(() => {
              history.push(subscriptionsUrl);
            }, 2000);
          },
          (error) => {}
        );
      } else {
        setLoading(false);
      }
    }
  }, [formErrors]);

  var abc = new Date(formValues.start_date).setFullYear(
    new Date(formValues.start_date).getFullYear() + 99
  );

  var cde = new Date(abc);

  function addMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() + numOfMonths);

    return date;
  }

  return (
    <div className="page-content add-warranty-pg">
      <Container fluid ref={myRef}>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {Loadingdata ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <Row className="fixed-form-header">
                <span className="nav-back">
                  <Link to={subscriptionsUrl}>
                    <p className="mb-3">
                      <img src={leftArrow} alt="arrow" className="mr-2" />
                      {userUUID
                        ? `Back to ${backText}’s Outgoings`
                        : `${backText}`}
                    </p>
                  </Link>
                </span>
                <Col md="8" lg="8">
                  <h3>Edit Outgoing</h3>
                  <p className="sub-title">Edit the below details</p>
                </Col>

                <Col md="4" lg="4">
                  <span className="align-right px-5">
                    {/* {loading && (
                  <span className="mx-3 spinner-border spinner-border-sm"></span>
                )} */}
                    {/* <Primary
                  children="Update Subscription"
                  size="mediumbtn"
                  variant="primary"
                  type="submit"
                  buttonWidth="240px"
                  font_Weight="500"
                  isLoading= {loading ? "true": "false"}
                  isDisabled = {loading ? "true": "false"}
                /> */}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md="12" lg="12" className="fixed-form-content">
                  <h4>1. Outgoing details</h4>
                  {/* row 1 */}
                  <Row>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Category</Form.Label>
                        <Select
                          styles={customStyles}
                          options={categoryOption}
                          defaultValue={{
                            label: "select a category",
                            value: "",
                          }}
                          value={selectedCategory}
                          onChange={handleChange}
                          name="category"
                        />
                        <Form.Text
                          className={formErrors.category ? "invalid-text" : ""}
                        >
                          Select a specific category that is applicable to the
                          Outgoing.
                        </Form.Text>
                      </Form.Group>
                    </Col>

                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Product Type</Form.Label>
                        <Select
                          styles={customStyles}
                          options={subCategoryOption}
                          defaultValue={{
                            label: "select a product type",
                            value: "",
                          }}
                          value={selectedProduct}
                          onChange={handleChange}
                          name="product_type"
                        />
                        <Form.Text
                          className={
                            formErrors.product_type ? "invalid-text" : " "
                          }
                        >
                          Select a specific product type that is applicable to
                          the Outgoing.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Row 2 */}
                  <Row>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Product Name</Form.Label>
                        <Medium
                          type="text"
                          placeholder="Ex : Car insurance"
                          size="medium"
                          name="product_name"
                          value={formValues.product_name}
                          onChange={handleChange}
                        />
                        <Form.Text
                          className={
                            formErrors.product_name ? "invalid-text" : " "
                          }
                        >
                          Select a product name that is applicable to the
                          Outgoing.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Supplier</Form.Label>
                        <Select
                          styles={customStyles}
                          options={supplierOption}
                          defaultValue={{
                            label: "select a supplier",
                            value: "",
                          }}
                          value={selectedSupplier}
                          onChange={handleChange}
                          name="supplier"
                        />
                        <Form.Text
                          className={formErrors.supplier ? "invalid-text" : " "}
                        >
                          Select the supplier of the warranty. Select others if
                          the supplier isn't listed.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* row 3 */}
                  <Row>
                    <Col md="10" lg="8">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="">
                          Additional Details ( Optional )
                        </Form.Label>
                        <textarea
                          rows={3}
                          cols={5}
                          name="additional_details"
                          value={formValues.additional_details}
                          onChange={handleChange}
                          placeholder=""
                          maxLength={150}
                        ></textarea>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md="12" lg="12" className="mt-4">
                  <h4>2.Payment details</h4>
                  {/* row 1 */}
                  <Row>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Amount</Form.Label>
                        <InputGroup className="mb-0">
                          <FormControl
                            type="number"
                            className=""
                            placeholder="Ex : £999"
                            size="medium"
                            name="amount"
                            onWheel={(event) => event.currentTarget.blur()}
                            step="any"
                            value={
                              formValues.amount < 0
                                ? ""
                                : formValues.amount == 0
                                ? formValues.amount
                                : formValues.amount == 0.0
                                ? formValues.amount
                                : formValues.amount > 0.0
                                ? formValues.amount
                                : formValues.amount
                            }
                            onChange={handleChange}
                          />
                          <InputGroup.Text className="pwd-icon">
                            <span className="pound-input">£</span>
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Text
                          className={formErrors.amount ? "invalid-text" : ""}
                        >
                          Enter the price value for which you have purchased the
                          product
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Billing</Form.Label>

                        <Select
                          styles={customStyles}
                          options={frequencyOption}
                          defaultValue={{
                            label: "select billing",
                            value: "",
                          }}
                          value={selectedFrequency}
                          onChange={handleChange}
                          name="frequency"
                        />
                        <Form.Text
                          className={formErrors.frequency ? "invalid-text" : ""}
                        >
                          Select the billing period
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* row 3 */}
                  <Row>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Start Date</Form.Label>
                        <Medium
                          type="date"
                          placeholder="Ex 20/10/2021"
                          size="medium"
                          name="start_date"
                          value={formValues.start_date}
                          onChange={handleChange}
                        />
                        <Form.Text
                          className={
                            formErrors.start_date ? "invalid-text" : " "
                          }
                        >
                          Select the start date
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md="5" lg="4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>End Date</Form.Label>
                        <Medium
                          type="date"
                          placeholder="Ex 20/10/2022"
                          size="medium"
                          name="end_date"
                          min={minEndDate ? minEndDate : mEDate}
                          value={formValues.end_date}
                          onChange={handleChange}
                          step="any"
                          disabled={valNew === "hello" && true}
                        />

                        <div className="d-flex justify-content-between">
                          <Form.Text
                            className={valNew1 === "bye" ? "invalid-text" : ""}
                          >
                            Select the end date
                          </Form.Text>
                          <Form.Label
                            style={{
                              "text-align": "right",
                              display: "block",
                              "margin-top": "14px",
                              "margin-right": "30px",
                            }}
                          >
                            No End Date{" "}
                            <span>
                              <input
                                style={{ position: "absolute", top: "6rem" }}
                                type="checkbox"
                                className="checkBoxVal"
                                id="keyValue"
                                onClick={(event) => newBtnWZ(event)}
                                checked={valNew === "hello" ? true : false}
                              ></input>
                            </span>
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* row 4 */}
                  <Row>
                    <Col md="6" lg="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="">
                          Document ( Optional )
                          <span className="mx-3 align-right invalid-text">
                            {browserErrMsg ? browserErrMsg : ""}
                          </span>
                        </Form.Label>
                        <Medium
                          type="text"
                          placeholder=""
                          // disabled="true"
                          size="medium"
                          name="contract_doc"
                          onChange={handleChange}
                          multiple
                          readOnly
                        />
                        {showInvoiceFile && (
                          <div className="doc-file">
                            <span className="icon">
                              <img src={docIcon} alt="Document" />
                            </span>
                            <span className="file">{showInvoiceFile}</span>
                            <span
                              className="remove"
                              onClick={removeUploadedFile}
                            >
                              <img src={closeIcon} alt="Document" />
                            </span>
                          </div>
                        )}
                        <Form.Text className=" ">
                          Upload the contract document in png, jpg, jpeg, pdf,
                          docx format. ( Max size 2MB )
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col
                      md="4"
                      lg="2"
                      className={
                        showInvoiceFile
                          ? "disable-upload-invoice-btn"
                          : "upload-invoice-btn"
                      }
                    >
                      <label>
                        <input
                          type="file"
                          id="upload"
                          name="uploaded_doc"
                          onChange={handleChange}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                          accept="image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                        Browse
                        {browserLoading && (
                          <span className="mx-3 spinner-border spinner-border-sm"></span>
                        )}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" lg="5">
                      <Form.Group className="my-3" controlId="formBasicEmail">
                        <Form.Switch
                          onChange={onSwitchAction}
                          id="custom-switch"
                          label=""
                          checked={isSwitchOn}
                          className="align-right sub-form-switch"
                        />
                        <p
                          className={
                            isSwitchOn
                              ? "reminder-switch-on"
                              : "reminder-switch-off"
                          }
                        >
                          Reminder on
                        </p>

                        <Form.Text>
                          Standard reminder settings will be applied to this
                          Outgoing. You can change the settings in the reminder
                          settings on your profile.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {
                <Row className={addUpdate ? "my-3 update-add-btn" : "my-3"}>
                  <Primary
                    children="Update Outgoing"
                    size="mediumbtn"
                    variant="primary"
                    type="submit"
                    buttonWidth="240px"
                    font_Weight="500"
                    isLoading={loading ? "true" : "false"}
                    isDisabled={loading ? "true" : "false"}
                  />
                </Row>
              }
            </Form>
          </>
        )}
      </Container>
    </div>
  );
};
export default EditSubscription;
