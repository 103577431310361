import React from "react";
import { Row, Col, Image, Card } from "react-bootstrap";
import copy from "copy-to-clipboard";
import "./Cards.scss";
import { Outline } from "../../stories/Button.stories";
import ElAccountPlan from "../templates/ElAccountPlan";

function ElAccountPlanCard(props) {
  const {
    planName,
    planPrice,
    planDuration,
    planImage,
    auSeats,
    suSeats,
    variant,
    buttonClick,
    familyName,
    firstLetter,
    familyId,
    isAo,
    ...rest
  } = props;
  const copyToClipboard = () => {
    copy(familyId);
    // alert(`You have copied "${familyId}"`);
  };
  return (
    <div>
      <Card
        className={`story-card el-acc changeSubscription-cards ${variant}-cards`}
      >
        <Card.Body className="p-1">
          <Row className="mb-3">
            <Col md="2" lg="2">
              <div className="m-0 mb-3">
                <span className="family-abbr">{firstLetter}</span>
              </div>
            </Col>
            <Col md="10" lg="10">
              <h3 className="mt-2">{familyName}</h3>
              <div className="family-details-main-familyid">
                <div className="familyDisplayName">Group ID:</div>
                <div className="familyDisplayId">{familyId}</div>
                <button
                  className="button-CopyToClipboard"
                  onClick={copyToClipboard}
                >
                  <svg
                    id="copy"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.574"
                    height="16.99"
                    viewBox="0 0 15.574 16.99"
                  >
                    <path
                      id="Path_16596"
                      data-name="Path 16596"
                      d="M6.194,116.933a3.013,3.013,0,0,1-3.009-3.009v-7.256H1.947A1.948,1.948,0,0,0,0,108.615v9.557a1.948,1.948,0,0,0,1.947,1.947H10.8a1.948,1.948,0,0,0,1.947-1.947v-1.239Zm0,0"
                      transform="translate(0 -103.128)"
                      fill="#9ac2e5"
                    />
                    <path
                      id="Path_16597"
                      data-name="Path 16597"
                      d="M139.327,1.947A1.947,1.947,0,0,0,137.38,0h-7.433A1.947,1.947,0,0,0,128,1.947V10.8a1.947,1.947,0,0,0,1.947,1.947h7.433a1.947,1.947,0,0,0,1.947-1.947Zm0,0"
                      transform="translate(-123.752)"
                      fill="#9ac2e5"
                    />
                  </svg>
                </button>
              </div>
              {/* <p className="mt-1 subtitle">{planDuration} plan</p> */}
            </Col>
          </Row>

          <span>
            <h3 className="mt-1">{planName}</h3>
            <p className="mt-1 subtitle">{planDuration} plan</p>
            <p className="my-2">
              {auSeats == 1 ? (
                <span>{auSeats} Main User</span>
              ) : (
                <span>{auSeats} Main Users</span>
              )}{" "}
              |{" "}
              {suSeats == 1 ? (
                <span>{suSeats} Support User</span>
              ) : (
                <span>{suSeats} Support Users</span>
              )}
            </p>
            <p className="my-1">
              Your account plan will be automatically renewed {planDuration}
            </p>
            {isAo == "true" ? (
              <Outline
                children="Manage Account Plan"
                variant="outline"
                size="outlineMedium"
                onClick={buttonClick}
              />
            ) : (
              ""
            )}
          </span>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ElAccountPlanCard;
