import React from "react";
import { Image, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Cards.scss";

function CategoryCard(props) {
  const { variant, heading, value, cardImage, ...rest } = props;
  return (
    <div {...rest}>
      <Card className={`story-card ${variant}-cards`}>
        <Card.Body className="p-1">
          <div className="d-flex justify-content-center justify-content-xxl-between flex-column flex-xxl-row">
            <span className="default-plan-img">
              <Image src={cardImage} />
            </span>
            {value ? <p className="action-right  number-txt ">{value}</p> : ""}
          </div>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip" {...props}>
                {heading}
              </Tooltip>
            }
          >
            <p className="mt-3 mb-0 count-heading" style={{ height: "25px" }}>
              {heading}
            </p>
          </OverlayTrigger>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CategoryCard;
