import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import layer from "../../assets/images/layer1.svg";
import HelloText from "../../assets/images/hello-text.svg";
import SubscriptionsEmpty from "../../assets/images/subscriptionempty.svg";
import { Primary } from "../../stories/Button.stories";
import leftArrow from "../../assets/images/left.svg";
import { NumberCard } from "../../stories/CountCard.stories";
import Modal from "react-bootstrap/Modal";
import DocIcon from "../../assets/images/doc_icon.png";
import VerticalDots from "../../assets/images/three-dots-vertical.svg";
import editIcon from "../../assets/images/Edit.svg";
import DocClose from "../../assets/images/Doc-close.svg";
import ToggleActive from "../../assets/images/Toggle-active.svg";
import ToggleInActive from "../../assets/images/Toggle-inactive.svg";
import Delete from "../../assets/images/Delete.svg";
import MagnifierIcon from "../../assets/images/magnifier.png";
import removeSearch from "../../assets/images/search_remove.png";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { Info } from "../../stories/Button.stories";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Envelop from "../../assets/images/envelop.svg";
import FileViewer from "react-file-viewer";
import FilePreviewer from "react-file-previewer";
import PDFViewer from "pdf-viewer-reactjs";
import DocViewer, { MSDocRenderer, DocViewerRenderers } from "react-doc-viewer";

import "../../assets/images/style.css";
const MySubscriptions = (props) => {
  const myRef = useRef(null);
  let history = useHistory();
  let { uuid, userUUID } = useParams();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [isPdf, setIsPdf] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const [isDoc, setIsDoc] = useState(false);
  var userType = localStorage.getItem("userType");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const filterOption = [
    { value: "all", label: "All" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Bi-Annually", label: "Bi Annually" },
    { value: "Yearly", label: "Yearly" },
    { value: "noEndDate", label: "No End-Date" },
  ];
  const sortOption = [
    { value: "createdAt", label: "Created" },
    { value: "updatedAt", label: "Updated" },
    { value: "endDate", label: "Validity" },
    { value: "premiumAmount", label: "Price" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
      height: "3.75rem",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
      height: "2rem",
    }),
  };
  const [familyDetails, setFamilyDetails] = useState([]);
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [searchStr, setSearchStr] = useState("");

  const [CountList, setCountList] = useState([]);
  const [showSearchText, setShowSearchText] = useState(false);
  const [parentUser, setParentUser] = useState();
  const [addSubscriptionUrl, setAddSubscriptionUrl] = useState("");
  const [title, setTitle] = useState("My Outgoings");
  const [subTitle, setSubTitle] = useState(
    "You can track all your Warranties on Elderly Life platform here."
  );
  const [backText, setBackText] = useState("Back to Group Dashboard");
  const [backUrl, setBackUrl] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [totalSubscriptionCount, setTotalSubscriptionCount] = useState("0");
  const [monthlyCost, setMonthlyCost] = useState("£ 0");
  const [yearlyCost, setYearlyCost] = useState("£ 0");

  const [quarterlyCost, setQuarterlyCost] = useState("£ 0");
  const [QuarterlyPriceCost, setQuarterlyPriceCost] = useState("");
  const [biAnnuallyCost, setBiAnnuallyCost] = useState("£ 0");
  const [committmentCost, setCommittmentCost] = useState("£ 0");

  const [MonthlyCostPrice, setMonthlyCostPrice] = useState("");
  const [YearlyCostPrice, setYearlyCostPrice] = useState("£ 0");
  const [QuarterlyCostPrice, setQuarterlyCostPrice] = useState("");
  const [BiAnnuallyCostPrice, setBiAnnuallyCostPrice] = useState("");
  const [CommittmentCostPrice, setCommittmentCostPrice] = useState("");
  const [TotalMonthlyCost, setTotalMonthlyCost] = useState("£ 0");
  const [TotalYearlyCost, setTotalYearlyCost] = useState("£ 0");

  const [MonthlyItems, setMonthlyItems] = useState("");
  const [YearlyItems, setYearlyItems] = useState("");
  const [QuarterlyItems, setQuarterlyItems] = useState("");
  const [BiAnnuallyItems, setBiAnnuallyItems] = useState("");

  const [subProductName, setSubProductName] = useState(false);
  const [subId, setSubId] = useState(false);
  const [showDeleteSub, setShowDeleteSub] = useState(false);
  const [showCancelReminder, setShowCancelReminder] = useState(false);
  const [showDocSub, setShowDocSub] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("createdAt");
  const [orderBy, setOrderBy] = useState("DESC");
  const [familyId, setFamilyId] = useState();
  const [familyUserId, setFamilyUserId] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [iframeLink, setIframeLink] = useState("");
  const [docLink, setDocLink] = useState("");
  const executeScroll = () => myRef.current.scrollIntoView();
  const viewDetailsRoute = (subId) => {
    if (userUUID) {
      history.push(`/view_user_outgoing_details/${uuid}/${subId}/${userUUID}`);
    } else {
      history.push(`/view_outgoing_details/${uuid}/${subId}`);
    }
  };
  const editSubscriptionRoute = (subId) => {
    if (userUUID) {
      history.push(`/edit_user_outgoing/${uuid}/${subId}/${userUUID}`);
    } else {
      history.push(`/edit_outgoing/${uuid}/${subId}`);
    }
  };
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const [filterValue, setfilterValue] = useState("all");
  const [searchValue, setsearchValue] = useState("");
  const [sortValue, setsortValue] = useState("createdAt");
  const [orderValue, setorderValue] = useState("DESC");

  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        console.log(pUser);
        setParentUser(response.userData);
        setAddSubscriptionUrl("/add_user_outgoing/" + uuid + "/" + userUUID);
        setBackUrl("/supporting_user_dashboard/" + uuid + "/" + userUUID);
        console.log(pUser);
        const abc =
          pUser.firstName === "null"
            ? pUser.email
            : pUser.firstName + " " + pUser.lastName === "null"
            ? ""
            : pUser.lastName;
        setTitle(`${abc}'s Outgoings`);

        setSubTitle(`You can track all Outgoings of ${abc} here`);

        setBackText("Back to " + pUser.firstName + "’s EL overview");
      });
    } else {
      setAddSubscriptionUrl("/add_outgoing/" + uuid);
      getFamilyDetails(UserDetails.id);
      setBackText("Back to Group Dashboard");
      setBackUrl("/family_dashboard/" + uuid);
    }
  }, []);

  //function validateDate(date)
  const validateDate = (date) => {
    //get start of day using moment.js
    const now = new Date(); //moment().startOf('day').toDate();
    var pickDate = new Date(date);
    if (pickDate < now) {
      return true; //date is before today's date
    } else {
      return false; //date is today or some day forward
    }
  };

  // Get Family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        let familyUserId = response.FamilyData.id;
        let familyId = response.FamilyData.Family.id;

        let params = {
          userId: familyUserId,
          familyId: familyId,
          searchStr: searchValue,
          sort: sortValue,
          order: orderValue,
          limit: 10,
          offset: 0,
          filtBy: filterValue,
        };

        setLoading(true);
        UserService.getUserSubscriptionListNew(params).then(
          (response) => {
            setUserSubscriptionList(response.data);
            setLoading(false);
            setDataLength(response.data.length);

            let nextOffset = 10;

            if (response.data.length === 0) {
              setIsEmpty(true);
              setLoadingspinner(false);
              setOffset(0);
              setHasMore(false);
            } else {
              setIsEmpty(false);
              setLoadingspinner(false);
              if (response.data.length >= nextOffset) {
                setOffset(nextOffset);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            }
          },
          (error) => {}
        );

        // get stats
        getStats(response.FamilyData.Family.id, familyUserId);
        getStats1(familyUserId, response.FamilyData.Family.id);
        setFamilyId(familyId);
        setFamilyUserId(familyUserId);
      },
      (error) => {}
    );
  };

  // Get Subscription List
  const getSubscriptions = (params) => {
    setLoading(true);
    UserService.getUserSubscriptionListNew(params).then(
      (response) => {
        setUserSubscriptionList(response.data);
        setLoading(false);

        let nextOffset = Number(Number(params.offset) + Number(params.limit));

        if (Number(response.data.length) >= nextOffset) {
          setOffset(nextOffset);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      },
      (error) => {}
    );
  };

  // Get Subscription count

  // Get more subscription List
  const moreSubscriptions = () => {
    let params = {
      userId: familyUserId,
      familyId: familyId,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limit,
      offset: offset,
      filtBy: filterValue,
    };

    setTimeout(() => {
      UserService.getUserSubscriptionListNew(params).then(
        (response) => {
          setUserSubscriptionList([...userSubscriptionList, ...response.data]);
          setDataLength(response.data.length + userSubscriptionList.length);
          let nextOffset = Number(offset) + Number(limit);

          if (
            response.data.length + userSubscriptionList.length >=
            nextOffset
          ) {
            setOffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        },
        (error) => {}
      );
    }, 250);
  };

  // get subscription list count
  const getStats1 = (userId, familyId) => {
    UserService.getUStats1(userId, familyId).then(
      (response) => {
        setMonthlyItems(response.monthlyCount);
        setYearlyItems(response.yearlyCount);
        setQuarterlyItems(response.quaterlyCount);
        setBiAnnuallyItems(response.bianualCount);
      },
      (error) => {}
    );
  };
  // Get Stats
  const getStats = (familyId, userId) => {
    UserService.getUserStats(familyId, userId).then(
      (response) => {
        if (response.data.count > 0) {
          let res = response.data.rows[0];

          setTotalSubscriptionCount(res.totalSubscriptions);
          setMonthlyCost("£ " + res.totalAmountMonthly);
          setYearlyCost("£ " + res.totalAmountYearly);
          setQuarterlyCost("£ " + res.totalAmountQuarterly);
          setBiAnnuallyCost("£ " + res.totalAmountBiAnually);
          setCommittmentCost("£ " + res.totalCommittment);

          setMonthlyCostPrice(res.totalAmountMonthly);
          setYearlyCostPrice(res.totalAmountYearly);
          setQuarterlyCostPrice(res.totalAmountQuarterly);
          setBiAnnuallyCostPrice(res.totalAmountBiAnually);
          setCommittmentCostPrice(res.totalCommittment);

          setTotalMonthlyCost(
            "£ " +
              parseFloat(
                parseFloat(res.totalAmountMonthly) +
                  parseFloat(res.totalAmountQuarterly / 3) +
                  parseFloat(res.totalAmountBiAnually / 6) +
                  parseFloat(res.totalAmountYearly / 12)
              ).toFixed(2)
          );

          setTotalYearlyCost(
            "£ " +
              parseFloat(
                (parseFloat(res.totalAmountMonthly) +
                  parseFloat(res.totalAmountQuarterly / 3) +
                  parseFloat(res.totalAmountBiAnually / 6) +
                  parseFloat(res.totalAmountYearly / 12)) *
                  12
              ).toFixed(2)
          );
        }
      },
      (error) => {}
    );
  };

  // Filter subscriptions
  const subscriptionFilter = (e) => {
    let filterVal = e.value;
    setfilterValue(e.value);
    setLoading(true);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: 10,
      offset: 0,
      filtBy: filterVal,
    };

    UserService.getUserSubscriptionListNew(params).then(
      (response) => {
        setUserSubscriptionList(response.data);
        setLoading(false);
        setDataLength(response.data.length);

        let nextOffset = 10;

        if (response.data.length === 0) {
          setLoadingspinner(false);
          setOffset(0);
          setHasMore(false);
        } else {
          setLoadingspinner(false);
          if (response.data.length >= nextOffset) {
            setOffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        }
      },
      (error) => {}
    );
  };

  // Sort subscriptions
  const subscriptionSort = (e) => {
    setLoading(true);
    let sort = e.value;
    setsortValue(sort);
    let params = {
      userId: familyUserId,
      familyId: familyId,
      searchStr: searchValue,
      sort: sort,
      order: orderValue,
      limit: 10,
      offset: 0,
      filtBy: filterValue,
    };
    getSubscriptions(params);
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    setShowSearchText(false);
    setLoading(true);
    setSearchStr("");
    setsearchValue("");
    let params = {
      userId: familyUserId,
      familyId: familyId,
      searchStr: "",
      sort: sortValue,
      order: orderValue,
      limit: 10,
      offset: 0,
      filtBy: filterValue,
    };
    getSubscriptions(params);
  };

  // Search subscriptions
  const subscriptionSearch = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setShowSearchText(true);
      setLoading(true);
      let srchStr = e.target.value;
      setsearchValue(e.target.value);
      if (srchStr === "") {
        clearSearch(e);
      } else {
        let params = {
          userId: familyUserId,
          familyId: familyId,
          searchStr: srchStr,
          sort: sortValue,
          order: orderValue,
          limit: 10,
          offset: 0,
          filtBy: filterValue,
        };
        getSubscriptions(params);
      }
    }
  };

  //search box
  useEffect(() => {
    if (searchStr.length === 0) {
      // clearSearch();
      setShowSearchText(false);
      setLoading(true);
      setSearchStr("");
      setsearchValue("");
      let params = {
        userId: familyUserId,
        familyId: familyId,
        searchStr: "",
        sort: sortValue,
        order: orderValue,
        limit: 10,
        offset: 0,
        filtBy: filterValue,
      };
      getSubscriptions(params);
    }
  }, [searchStr]);

  // if (searchStr.length === 0) {
  //   clearSearch(e);
  // }

  // Delete confirmation
  const handleShowDeleteSubShow = (id, name) => {
    setShowDeleteSub(true);
    setSubProductName(name);
    setSubId(id);
  };

  // Close delete confirmation
  const handleShowDeleteSubClose = () => {
    setShowDeleteSub(false);
  };

  // Delete subscription
  const handleDeleteSubscription = (subId) => {
    setShowDeleteSub(false);
    UserService.deleteSubscription(subId).then(
      (response) => {
        let subList = userSubscriptionList;
        const selectedSubIndex = subList.findIndex(
          (x) => Number(x.id) === Number(subId)
        );
        subList.splice(selectedSubIndex, 1);
        setUserSubscriptionList(subList);
        if (subList.length === 0) {
          setIsEmpty(true);
          setLoadingspinner(false);
          // offset = 0;
        } else {
          setIsEmpty(false);
          setLoadingspinner(false);
          getStats(familyDetails.FamilyData.Family.id, familyUserId);
        }
        setSuccessToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setShowToast(true);

        setToastMessage("Outgoing deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  // show doc model

  const handleShowDocSubShow = (docUrl) => {
    setShowDocSub(true);
    const file_ext = get_url_extension(docUrl);
    setDocLink(docUrl);

    if (file_ext === "pdf") {
      setIsPdf(true);
      setIsImage(false);
      setIsDocument(false);
      setIsDoc(false);
    } else if (
      file_ext === "png" ||
      file_ext === "jpg" ||
      file_ext === "jpeg"
    ) {
      setIsPdf(false);
      setIsImage(true);
      setIsDocument(false);
      setIsDoc(false);
    } else if (file_ext === "docx") {
      setIsPdf(false);
      setIsImage(false);
      setIsDocument(true);
      setIsDoc(false);
      const docs = [{ uri: docUrl }];
      setIframeLink(docs);
    } else if (file_ext === "doc") {
      setIsPdf(false);
      setIsImage(false);
      setIsDocument(false);
      setIsDoc(true);
      const docs = [{ uri: docUrl }];
      setIframeLink(docs);
    }
  };

  const docsPrev = docLink;

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };
  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };

  // Cancel reminder confirmation
  const handleShowCancelReminderShow = (id, name) => {
    setShowCancelReminder(true);
    setSubProductName(name);
    setSubId(id);
  };

  // Cancel reminder confirmation
  const handleShowCancelReminderShow1 = (id, name) => {
    setSubProductName(name);
    setSubId(id);
  };

  // Close cancel reminder
  const handleShowCancelReminderClose = () => {
    setShowCancelReminder(false);
    setLoadingspinner(true);
    setTimeout(() => {
      setLoadingspinner(false);
    }, 500);
  };

  // Cancel reminder subscription
  const handleCancelReminder = (subId) => {
    setShowCancelReminder(false);
    UserService.cancelReminderSubscription(subId, false).then(
      (response) => {
        let subList = userSubscriptionList;
        const selectedSubIndex = subList.findIndex(
          (x) => Number(x.id) === Number(subId)
        );
        subList[selectedSubIndex].reminder = false;

        setUserSubscriptionList(subList);
        setSuccessToast(true);
        setShowToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setToastMessage("Outgoing reminder turned off");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  // true reminder subscription
  const handleCancelReminder1 = (subId) => {
    UserService.cancelReminderSubscription(subId, true).then(
      (response) => {
        let subList = userSubscriptionList;
        const selectedSubIndex = subList.findIndex(
          (x) => Number(x.id) === Number(subId)
        );
        subList[selectedSubIndex].reminder = true;

        setUserSubscriptionList(subList);
        setSuccessToast(true);
        setShowToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setToastMessage("Outgoing reminder turned on");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  const onSwitchAction = (event) => {
    if (event.target.checked === false) {
      {
        userSubscriptionList.map((subData, index) =>
          handleCancelReminder(subData.id, subData.productName)
        );
      }
    } else {
      {
        userSubscriptionList.map((subData, index) =>
          handleCancelReminder1(subData.id, subData.productName)
        );
      }
    }
  };

  var uuidDetails = localStorage.getItem("uuid");
  var userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));

  const dashboardRed = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/family_dashboard/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  return (
    <div className="page-content warranty-pg">
      <Container fluid ref={myRef}>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        {loadingspinner ? (
          <>
            <div className="loadind-page">
              <Loading />
            </div>
          </>
        ) : (
          <>
            <div
              className="Reminder-backBtn"
              onClick={() =>
                // history.push({
                //   pathname: `/family_dashboard/${data.state.state.tab}`,
                dashboardRed()
              }
            >
              {" "}
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              {parentUserUUId === null ? (
                <span>Back to Group Dashboard</span>
              ) : (
                <span>Back to Main User Dashboard</span>
              )}
            </div>
            <Row>
              <Col md="8" lg="8">
                <h3>{title}</h3>
                <p className="sub-title">{subTitle}</p>
              </Col>
              <Col md="4" lg="4">
                {loadingspinner && (
                  <Row>
                    <Col md="12" lg="12" className="mt-4 pt-4 text-center">
                      <button className="btn loading-btn" type="button">
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        ></span>
                        Loading . . .
                      </button>
                    </Col>
                  </Row>
                )}
                {isEmpty ? (
                  ""
                ) : (
                  <span className="align-right">
                    <Link to={addSubscriptionUrl} className="">
                      <Primary
                        children="+ Add New Outgoings"
                        size="mediumbtn"
                        variant="primary"
                        type=""
                        buttonWidth="220px"
                        font_Weight="500"
                      />
                    </Link>
                  </span>
                )}
              </Col>
            </Row>
            {isEmpty ? (
              <>
                <Row>
                  <Col md="6" lg="6" className="text-left my-4">
                    <p className="sub-text mb-4">
                      You currently have no Outgoings set up!
                    </p>
                    <Link to={addSubscriptionUrl} className="">
                      <Large
                        children="+ Add New Outgoings"
                        size="largebtn"
                        variant="primary"
                        type=""
                        buttonWidth="240px"
                        font_Weight="500"
                      />
                    </Link>
                  </Col>
                  <Col md="6" lg="6">
                    <Image
                      className="align-right empty-src-img"
                      src={SubscriptionsEmpty}
                      alt="subscription empty icon"
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row
                  className="subStats-card mt-4"
                  style={{ "margin-bottom": "1rem" }}
                >
                  <Col md="8">
                    <div className="cardd">
                      <div className="border-tp">
                        <div className="card-des">
                          <span>
                            <h3 className="mb-0 px-4">
                              Costs by billing frequency
                            </h3>
                          </span>
                        </div>
                      </div>
                    </div>

                    <Row className="inner-cards">
                      <Col md="6" xxl="3" className="mt-3 mt-xxl-0">
                        <div className="main-div-dsn">
                          <div className="text-dsn-outgoing-kpi"> Monthly </div>
                          <div className="sub-div-dsn">
                            <div>
                              <div className="text-dsn-outgoing-kpi-section pe-1">
                                Total Items
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {" "}
                                {MonthlyItems}{" "}
                              </div>
                            </div>
                            <div>
                              <div className="text-dsn-outgoing-kpi-section ps-1">
                                Total Cost
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {monthlyCost ? monthlyCost : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md="6" xxl="3" className="mt-3 mt-xxl-0">
                        <div className="main-div-dsn">
                          <div className="text-dsn-outgoing-kpi">
                            {" "}
                            Quarterly{" "}
                          </div>
                          <div className="sub-div-dsn">
                            <div>
                              <div className="text-dsn-outgoing-kpi-section pe-1">
                                Total Items
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {" "}
                                {QuarterlyItems}
                              </div>
                            </div>
                            <div>
                              <div className="text-dsn-outgoing-kpi-section ps-1">
                                Total Cost
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {quarterlyCost ? quarterlyCost : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md="6" xxl="3" className="mt-3 mt-xxl-0">
                        <div className="main-div-dsn">
                          <div className="text-dsn-outgoing-kpi">
                            {" "}
                            Bi-Annually{" "}
                          </div>
                          <div className="sub-div-dsn">
                            <div>
                              <div className="text-dsn-outgoing-kpi-section pe-2">
                                Total Items
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {BiAnnuallyItems}
                              </div>
                            </div>
                            <div>
                              <div className="text-dsn-outgoing-kpi-section ps-2">
                                Total Cost
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {biAnnuallyCost ? biAnnuallyCost : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="6" xxl="3" className="mt-3 mt-xxl-0">
                        <div className="main-div-dsn">
                          <div className="text-dsn-outgoing-kpi"> Yearly </div>
                          <div className="sub-div-dsn">
                            <div>
                              <div className="text-dsn-outgoing-kpi-section">
                                Total Items
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {YearlyItems}
                              </div>
                            </div>
                            <div>
                              <div className="text-dsn-outgoing-kpi-section">
                                Total Cost
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {yearlyCost ? yearlyCost : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <div className="cardd">
                      <div className="border-tp right">
                        <span>
                          <h3 className="mb-0 px-4">Total cost breakdown</h3>
                        </span>

                        <div className="card-des"></div>
                      </div>
                    </div>

                    <Row className="inner-cards">
                      <Col md="12" xxl="6" className="mt-3 mt-xxl-0">
                        <div className="main-div-dsn-1">
                          <div className="text-dsn-outgoing-kpi">
                            {" "}
                            Total Monthly{" "}
                          </div>

                          <div className="sub-div-dsn">
                            <div>
                              <div className="text-dsn-outgoing-kpi-section">
                                Total Cost
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {TotalMonthlyCost}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="12" xxl="6" className="mt-3 mt-xxl-0">
                        <div className="main-div-dsn-1">
                          <div className="text-dsn-outgoing-kpi">
                            {" "}
                            Total Yearly{" "}
                          </div>
                          <div className="sub-div-dsn">
                            <div>
                              <div className="text-dsn-outgoing-kpi-section">
                                Total Cost
                              </div>
                              <div className="cost-dsn-outgoing-kpi">
                                {TotalYearlyCost}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-5 subSearch-row">
                  <Col lg="6" xxl="4">
                    <div className="input-group search-input-group">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search Outgoings"
                        value={searchStr}
                        onChange={(e) => setSearchStr(e.target.value)}
                        onKeyDown={subscriptionSearch}
                      />
                      <div className="input-group-btn">
                        {!searchStr && (
                          <button
                            className="btn btn-default search-input-btn"
                            type="submit"
                          >
                            {/* <i className="glyphicon glyphicon-search"></i> */}
                            <Image src={MagnifierIcon} alt="magnifier icon" />
                          </button>
                        )}
                        {searchStr && (
                          <button
                            className="btn btn-default clear-search"
                            type="submit"
                            onClick={clearSearch}
                          >
                            {/* <i className="glyphicon glyphicon-search"></i> */}
                            <Image src={removeSearch} alt="Clear Search" />
                          </button>
                        )}
                      </div>
                    </div>
                    {showSearchText && searchStr && (
                      <div className="search-text mt-3">
                        Search Results for : <span>{searchStr}</span>
                      </div>
                    )}
                  </Col>
                  <Col lg="6" xxl="4" className="d-none d-xxl-block"></Col>
                  <Col lg="6" xxl="4">
                    <div className="d-flex justify-content-between">
                      <div
                        className="equal-box filter-box filter-by"
                        style={{ display: "flex" }}
                      >
                        <div className="filter-label d-inline pl-2">
                          Filter by :
                        </div>
                        <div
                          style={{
                            minWidth: "100px",
                            flex: 1,
                            cursor: "pointer",
                          }}
                        >
                          <Select
                            styles={customStyles}
                            options={filterOption}
                            defaultValue={{ label: "All", value: "all" }}
                            onChange={subscriptionFilter}
                          />
                        </div>
                      </div>

                      <div
                        className="equal-box filter-box filter-value"
                        style={{ display: "flex" }}
                      >
                        <div className="filter-label d-inline ml-2 pl-2">
                          Sort by :
                        </div>
                        <div className="" style={{ flex: 1 }}>
                          <Select
                            styles={customStyles}
                            options={sortOption}
                            defaultValue={{
                              label: "Created",
                              value: "created",
                            }}
                            onChange={subscriptionSort}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {userSubscriptionList.length > 0 && !loading && (
                  <div className="subscription-list">
                    <InfiniteScroll
                      dataLength={dataLength}
                      next={moreSubscriptions}
                      hasMore={hasMore}
                      loader={<Loading />}
                      style={{ overflow: "inherit" }}
                    >
                      {userSubscriptionList.map((subData, index) => (
                        <Card
                          className="mb-2 mt-4 card-list warrantList-cards"
                          key={index}
                        >
                          <Card.Body>
                            <span className="category-btn">
                              <p>
                                {subData.Category === null ||
                                subData.Category === undefined
                                  ? ""
                                  : subData.Category.name}
                              </p>
                            </span>
                            <div className="d-flex align-elements align-items-start">
                              <div
                                onClick={() => viewDetailsRoute(subData.id)}
                                style={{ cursor: "pointer" }}
                                className="logo-box"
                              >
                                <img
                                  src={
                                    subData.Supplier === null
                                      ? "https://el-publicassets.s3.eu-west-2.amazonaws.com/images/Supplier+default+logo.png"
                                      : subData.Supplier.logo
                                  }
                                  alt=" logo"
                                  className="category-logo"
                                />
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(subData.id)}
                                style={{ cursor: "pointer" }}
                                className="Product-type"
                              >
                                <small
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 expired"
                                      : "mb-0"
                                  }
                                >
                                  Product Name
                                </small>
                                <p
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 tab-txt expired"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  {subData.productName}
                                </p>
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(subData.id)}
                                style={{ cursor: "pointer" }}
                                className="supplier-box"
                              >
                                <small
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 expired"
                                      : "mb-0"
                                  }
                                >
                                  Supplier name
                                </small>
                                <p
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 tab-txt expired"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  {subData.Supplier
                                    ? subData.Supplier.name
                                    : "--"}
                                </p>
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(subData.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <small
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 expired"
                                      : "mb-0"
                                  }
                                >
                                  Billing
                                </small>
                                <p
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 tab-txt expired"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  {subData.renewalFrequency}
                                </p>
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(subData.id)}
                                style={{ cursor: "pointer" }}
                                className="price-box"
                              >
                                <small
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 expired"
                                      : "mb-0"
                                  }
                                >
                                  Cost
                                </small>
                                <p
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 tab-txt expired"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  £ {subData.premiumAmount}
                                </p>
                              </div>
                              <div
                                onClick={() => viewDetailsRoute(subData.id)}
                                style={{ cursor: "pointer" }}
                                className="enddate-box"
                              >
                                <small
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 expired-red"
                                      : "mb-0"
                                  }
                                >
                                  End Date
                                </small>
                                <p
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 tab-txt expired-red"
                                      : "mb-0 tab-txt"
                                  }
                                >
                                  {subData.endDate &&
                                  new Date(subData.endDate).getFullYear() < 2100
                                    ? moment(subData.endDate).format(
                                        "MMM Do, YYYY"
                                      )
                                    : "--"}
                                </p>
                              </div>
                              <div
                                className="flex-05"
                                // onClick={() => viewDetailsRoute(subData.id)}
                                // style={{ cursor: "pointer" }}
                              >
                                <small
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 expired"
                                      : "mb-0"
                                  }
                                >
                                  Reminder
                                </small>
                                <p
                                  className={
                                    validateDate(subData.endDate)
                                      ? "mb-0 tab-txt expired d-flex"
                                      : "mb-0 tab-txt d-flex"
                                  }
                                >
                                  <label class="switch m-0">
                                    <input
                                      type="checkbox"
                                      id="togBtn"
                                      defaultChecked={
                                        subData.reminder === true ? true : false
                                      }
                                      onClick={(e) =>
                                        e.target.checked === false
                                          ? handleShowCancelReminderShow(
                                              subData.id,
                                              subData.productName
                                            )
                                          : handleCancelReminder1(
                                              subData.id,
                                              subData.productName
                                            )
                                      }
                                      // onChange={onSwitchAction}
                                    />
                                    <div class="slider round"></div>
                                  </label>
                                </p>
                              </div>

                              <div className="call2actionbtns">
                                <span className="list-btn my-1 d-flex justify-content-between">
                                  <div
                                    className="doc-box"
                                    style={{ "margin-top": "6px" }}
                                  >
                                    {subData.documentUrl ? (
                                      <svg
                                        id="surface1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        // width="20.383"
                                        // height="22.405"
                                        viewBox="0 0 20.383 22.405"
                                        onClick={() =>
                                          handleShowDocSubShow(
                                            subData.documentUrl
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          width: "1.5rem",
                                        }}
                                      >
                                        <path
                                          id="Path_16605"
                                          data-name="Path 16605"
                                          d="M15.27,9.7V5.459a.491.491,0,0,0-.135-.322L10.381.145A.474.474,0,0,0,10.04,0H2.5A2.511,2.511,0,0,0,0,2.526V16.778a2.5,2.5,0,0,0,2.5,2.508H8.452A6.422,6.422,0,1,0,15.27,9.7Zm-4.763-8.06L13.7,5H11.627a1.128,1.128,0,0,1-1.121-1.125Zm-8,16.712A1.564,1.564,0,0,1,.934,16.778V2.526A1.577,1.577,0,0,1,2.5.934h7.07V3.871A2.059,2.059,0,0,0,11.627,5.93h2.708V9.577c-.14,0-.252-.019-.374-.019A6.516,6.516,0,0,0,9.7,11.165H3.773a.467.467,0,1,0,0,.934H8.835a7.43,7.43,0,0,0-.822,1.448H3.773a.467.467,0,0,0,0,.934H7.71a6.409,6.409,0,0,0,.28,3.876H2.5Zm11.455,3.124a5.487,5.487,0,1,1,5.487-5.487A5.493,5.493,0,0,1,13.958,21.476Zm0,0"
                                          fill="#003a6f"
                                        />
                                        <path
                                          id="Path_16606"
                                          data-name="Path 16606"
                                          d="M76.018,202.618h4.73a.467.467,0,1,0,0-.934h-4.73a.467.467,0,1,0,0,.934Zm0,0"
                                          transform="translate(-72.245 -192.858)"
                                          fill="#003a6f"
                                        />
                                        <path
                                          id="Path_16607"
                                          data-name="Path 16607"
                                          d="M251.264,286.709l-1.919,2.069v-5.1a.467.467,0,1,0-.934,0v5.1l-1.933-2.069a.471.471,0,0,0-.663-.023.465.465,0,0,0-.023.658l2.732,2.937a.463.463,0,0,0,.682,0l2.736-2.937a.464.464,0,1,0-.677-.635Zm0,0"
                                          transform="translate(-234.916 -270.818)"
                                          fill="#003a6f"
                                        />
                                      </svg>
                                    ) : (
                                      // </p>
                                      //</Link>
                                      ""
                                    )}
                                  </div>
                                  <Image
                                    className="m-1 edit-icon mx-5"
                                    src={editIcon}
                                    alt="editIcon"
                                    // width="8%"
                                    onClick={() =>
                                      editSubscriptionRoute(subData.id)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      top: " 1.2rem",
                                      width: "1.5rem",
                                    }}
                                  />
                                  <Image
                                    className="m-1 edit-icon"
                                    src={Delete}
                                    alt="Delete"
                                    // width="8%"
                                    onClick={() =>
                                      handleShowDeleteSubShow(
                                        subData.id,
                                        subData.productName
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      top: " 1.2rem",
                                      width: "1.5rem",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            {/* {subData.reminder ? (
                          ""
                        ) : (
                          <span className="align-right reminder-flag">
                            <small>
                              Reminder off:
                              <i className="fa fa-bell-slash ml-2"></i>
                            </small>
                          </span>
                        )} */}
                          </Card.Body>
                        </Card>
                      ))}
                    </InfiniteScroll>
                  </div>
                )}

                {loading && <Loading />}

                {userSubscriptionList.length === 0 &&
                  !loading &&
                  !showSearchText && (
                    <Row>
                      <Col
                        md="12"
                        lg="12"
                        className="mt-5 pt-5 small-text text-center"
                      >
                        No data found
                      </Col>
                    </Row>
                  )}
                {userSubscriptionList.length === 0 &&
                  !loading &&
                  showSearchText &&
                  searchStr && (
                    <Row>
                      <Col
                        md="12"
                        lg="12"
                        className="mt-5 pt-5 small-text text-center"
                      >
                        <div className="empty-search-text-title">
                          There are no results for the search query
                        </div>
                        <div className="empty-search-text-sub-title">
                          Try searching Outgoings with modified terms
                        </div>
                      </Col>
                    </Row>
                  )}
              </>
            )}
          </>
        )}

        {/* // cancel subscription reminder */}
        <Modal
          show={showCancelReminder}
          onHide={handleShowCancelReminderClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">Cancel Reminder</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <p className="my-3 subtext">Outgoing reminder will be turned off</p>
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowCancelReminderClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleCancelReminder(subId)}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* // Delete subscription */}
        <Modal
          show={showDeleteSub}
          onHide={handleShowDeleteSubClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">Delete Outgoing</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            <p className="my-3 subtext">
              All the Outgoing related details will be deleted from the family
            </p>
            <p>Do you want to continue?</p>
            <div className="mb-2">
              <span>
                <Info
                  children="Cancel"
                  variant="info"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={handleShowDeleteSubClose}
                />
              </span>
              <span className="ml-4">
                <Primary
                  children="Yes"
                  variant="primary"
                  size="mediumbtn"
                  type=""
                  buttonWidth="120px"
                  onClick={() => handleDeleteSubscription(subId)}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* View document model */}
        <Modal
          show={showDocSub}
          onHide={handleShowDocSubClose}
          centered={true}
          size={isDocument ? "xl" : "lg"}
          scrollable={true}
          className="invite_au_modal doc_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mx-1">
              <h3 className="mb-0">View Document</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-1">
            {isPdf ? (
              <PDFViewer
                document={{
                  url: docLink,
                }}
              />
            ) : (
              // <>
              //   <Document file={docLink} onLoadSuccess={onDocumentLoadSuccess}>
              //     <Page pageNumber={pageNumber} />
              //   </Document>
              //   <p>
              //     Page {pageNumber} of {numPages}
              //   </p>
              // </>
              ""
            )}
            {isImage ? (
              <img
                src={docLink}
                alt="Contract Document"
                className="image-document-size"
              />
            ) : (
              ""
            )}
            {isDocument ? (
              <FileViewer fileType="docx" filePath={docLink} />
            ) : (
              ""
            )}

            {isDoc ? (
              // <DocViewer
              //   documents={docsPrev}
              //   pluginRenderers={[MSDocRenderer]}
              // />
              <FileViewer fileType="doc" filePath={docLink} />
            ) : (
              // <iframe
              //   className=""
              //   width="100%"
              //   height="600"
              //   frameborder="0"
              //   src={docLink}
              // ></iframe>
              // <iframe src={docLink}></iframe>
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            {/* <Link
              to={{
                pathname: docLink,
              }}
              target="_blank"
            >
              Download
            </Link> */}
            <a href={docLink} download>
              Download
            </a>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};
export default MySubscriptions;
