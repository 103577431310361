import React, { useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.css";
import routes from "./router";
import AuthService from "../src/services/auth.service";
import AuthVerify from "./common/auth-verify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51JtZX7HPjiF3hUjm1aOz86cvckp5G25TAMYVNd7PhllJNmATW3YOFgaqWXFlozmosvwoynpajxsZwihjgYPqH2T700d1Z4vn2x"
);
const clientSecret =
  "sk_test_51JtZX7HPjiF3hUjmP2pqbDVpLtPlbBfyQdHn7DbppYUgoPTVqqF1X3zYxXvk5sMDwQFHISeZIP5nUHODaLiyGZlJ00uab37x9J";

function App() {
  let history = useHistory();
  const appearance = {
    theme: "stripe",
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    appearance,
  };
  const logOut = () => {
    AuthService.logout();
    history.push("/");
  };
  useEffect(() => {
    window.Intercom("boot", {
      app_id: "gy8ucqgt",
      //Website visitor so may not have any user related info
    });
  }, []);
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Router>
        <Elements stripe={stripePromise}>
          <Switch>
            {routes.map((route) => (
              <Route
                exact
                key={route.path}
                path={route.path}
                render={(props) => (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                )}
              />
            ))}
          </Switch>
        </Elements>
        <AuthVerify logOut={logOut} />
      </Router>
    </>
  );
}

export default App;
