import React from "react";
import CategoryCard from "../components/Cards/CategoryCard";

export default {
  title: "Cards/Count",
  component: CategoryCard,
  args: {
    message: "ActionAlert message",
  },
};

const Template = (args) => <CategoryCard {...args} />;

export const Category = Template.bind({});
Category.args = {
  heading: "Subscriptions",
  value: "23",
  variant: "category",
};
